const orderForm = {
  CreateOrder: [
    {
      type: 'Input',
      label: 'PO Number',
      class: 'col-md-3',
      placeHolder: '',
      name: 'PONumber',
      id: 'PONumber',
      style : {},
      required : true
    },
    {
      type: 'Datetime',
      label: 'PO Creation Date',
      name: 'POCreateDate',
      class: 'col-md-3',
      id: 'POCreateDate',
      style : {},
      required : true
    },
    {
      type: 'Datetime',
      label: 'Requested Ex Works Date',
      name: 'RequestedExWorksDate',
      class: 'col-md-3',
      id: 'RequestedExWorksDate',
      style : {},
      required : true
    },
    {
      type: 'Datetime',
      label: 'Required at Destination',
      name: 'RequiredDestination',
      class: 'col-md-3',
      id: 'RequiredDestination',
      style : {},
      required : true
    },
    {
      type: 'Select',
      options: 'COUNTRIES',
      label: 'PO Origin Country',
      name: 'OriginCountryId',
      class: 'col-md-3',
      id: 'OriginCountryId',
      style : {},
      required : true
    },
    {
      type: 'Select',
      options: 'PRODUCTS',
      class: 'col-md-3',
      label: 'Product Type',
      name: 'ProductTypeId',
      id: 'ProductTypeId',
      style : {}
    },
    {
      type: 'Select',
      options: 'CUSTOMERS_DIVISIONS',
      label: 'PO Division',
      name: 'DivisionId',
      class: 'col-md-3',
      id: 'DivisionId',
      style : {},
      required : true
    },
    {
      type: 'Select',
      options: 'CRUISES',
      label: 'Cruise',
      name: 'ProjectId',
      class: 'col-md-3',
      id: 'ProjectId',
      style : {}

    },
    {
      type: 'Select',
      options: 'TRANSPORTS',
      label: 'Transport Mode',
      class: 'col-md-3',
      name: 'TransportModeId',
      id: 'TransportModeId',
      style : {},
      required : true
    },
    {
      type: 'Select',
      options: 'INCOTERMS',
      label: 'INCO Term',
      name: 'Incoterm',
      class: 'col-md-3',
      id: 'Incoterm',
      style : {}
    },
    {
      type: 'Input',
      label: 'Order Value',
      class: 'col-md-3',
      placeHolder: '',
      name: 'OrderValue',
      id: 'OrderValue',
      style : {},

    },
    {
      type: 'Select',
      options: 'CURRENCIES',
      label: 'Order Currency',
      name: 'OrderCurrencyId',
      class: 'col-md-3',
      id: 'OrderCurrencyId',
      style : {}

    },
    {
      type: 'textarea',
      label: 'PO Description',
      name: 'PODescription',
      class: 'col-md-6',
      id: 'PODescription',
      style : {}

            
    },
    {
      type: 'textarea',
      label: 'Additional PO Terms',
      name: 'AdditionalPOTerms',
      class: 'col-md-6',
      id: 'AdditionalPOTerms',
      style : {}
            
    },
  ],
  CreatePOLine: [
    {
      type: 'Input',
      maxlength : '25',
      label: 'Item Number',
      placeHolder: '',
      class: 'col-md-2',
      name: 'ItemNumber',
      id: 'ItemNumber',
      style : {}
    },
    {
      type: 'textarea',
      label: 'Item Description',
      placeHolder: '',
      class: 'col-md-2',
      name: 'ItemDescription',
      id: 'ItemDescription',
      style : {}
    },
    {
      type: 'Input',
      label: 'Qty Ordered',
      placeHolder: '',
      datatype : 'Number',
      class: 'col-md-2',
      name: 'QtyOrdered',
      id: 'QtyOrdered',
      style : {}
    },
    {
      type: 'Select',
      options: 'UNITS-MEASURE',
      label: 'Unit Measure',
      class: 'col-md-2',
      name: 'UnitMeasure',
      id: 'UnitMeasure',
      style : {}

    },
    {
      type: 'Select',
      options: 'COUNTRIES',
      class: 'col-md-2',
      label: 'Origin CountryId',
      name: 'OriginCountryId',
      id: 'OriginCountryId',
      style : {}
    },
    {
      type: 'Input',
      maxlength : '25',
      label: 'Vendor Code',
      placeHolder: '',
      class: 'col-md-2',
      name: 'VendorCode',
      id: 'VendorCode',
      style : {}      
    },
    {
      type: 'Input',
      maxlength : '25',
      label: 'Collection',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Collection',
      id: 'Collection',
      style : {}
    },
    {
      type: 'Input',
      maxlength : '25',
      label: 'Style',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Style',
      id: 'Style',
      style : {}
    },
    {
      type: 'Input',
      maxlength : '25',
      label: 'Color',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Color',
      id: 'Color',
      style : {}
    },
    {
      type: 'Input',
      maxlength : '25',
      label: 'Size',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Size',
      id: 'Size',
      style : {}
    }
  ],
  CreateDocument: [
    {
      type: 'Input',
      label: 'Doc Name',
      placeHolder: '',
      class: 'col-md-5',
      name: 'Name',
      id: 'Name',
      style : {}


    },
    {
      type: 'Select',
      options: 'DOC-CATEGORIES',
      class: 'col-md-5',
      label: 'Doc Category',
      name: 'DocCategoryId',
      id: 'DocCategoryId',
      style : {}
    },
  ],
  CreateBooking: [

    {
      type: 'Input',
      label: 'Booking No',
      placeHolder: '',
      class: 'col-md-4',
      name: 'BookingNumber',
      id: 'BookingNumber',
      minlength : '1',
      maxlength : '20',
      datatype : 'Text',
      required : true
    },
    {
      type: 'Datetime',
      label: 'Booking Date',
      name: 'BookingDate',
      class: 'col-md-4',
      id: 'BookingDate',
      datatype : 'date',
            

    },
    {
      type: 'Select',
      options: 'TRANSPORTS-MODE',
      label: 'Transport Mode',
      class: 'col-md-4',
      name: 'TransportModeId',
      id: 'TransportModeId',
      datatype : 'select',
      required : true


    },
    {
      type: 'Select',
      options: 'FREIGHT-TERMS',
      label: 'Freight Terms',
      class: 'col-md-4',
      name: 'FreightTermId',
      id: 'FreightTermId',
      datatype : 'select',
      required : true

    },
    {
      type: 'Datetime',
      label: 'Cargo Ready Date',
      name: 'CargoReadyDate',
      class: 'col-md-4',
      id: 'CargoReadyDate',
      datatype : 'date'


    },

    {
      type: 'Select',
      options: 'PORTS-AIR',
      label: 'Origin Port',
      class: 'col-md-4',
      name: 'OriginPort',
      id: 'OriginPort',
      datatype : 'select',
      required : true


    },

    {
      type: 'Select',
      options: 'PORTS-AIR',
      label: 'Destination Port',
      class: 'col-md-4',
      name: 'DestinationPort',
      id: 'DestinationPort',
      datatype : 'select',
      required : true


    },
    {
      type: 'Input',
      label: 'Final Delivery',
      placeHolder: '',
      class: 'col-md-4',
      name: 'FinalDeliveryDesc',
      id: 'FinalDeliveryDesc',
      required : true,
      minlength : '50',
      maxlength : '1',
      datatype : 'Text',

    },


    {
      type: 'Select',
      options: 'INCO-TERMS',
      label: 'Incoterm',
      class: 'col-md-4',
      name: 'IncotermCode',
      id: 'IncotermCode',
      datatype : 'select'

    },



    {
      type: 'Select',
      options: 'CARGO-TYPES',
      label: 'Cargo Type',
      class: 'col-md-4',
      name: 'CargoTypeId',
      id: 'CargoTypeId',
      datatype : 'select',
      required : true,


    },


    {
      type: 'Select',
      options: 'THERE-PLS',
      label: '3PL',
      class: 'col-md-4',
      name: 'ThreePLId',
      id: 'ThreePLId',
      datatype : 'select',
      required : true,


    },


    {
      type: 'Input',
      label: '3PL Booking Reference',
      placeHolder: '',
      class: 'col-md-4',
      name: 'ThreePLBookingRef',
      id: 'ThreePLBookingRef',
      datatype : 'Text',


    },

    {
      type: 'Input',
      label: 'Customer Reference 1',
      placeHolder: '',
      class: 'col-md-4',
      name: 'CustomerRef1',
      id: 'CustomerRef1',
      maxlength : '250',
      datatype : 'Text',


    },

    {
      type: 'Input',
      label: 'Customer Reference 2',
      placeHolder: '',
      class: 'col-md-4',
      name: 'CustomerRef2',
      id: 'CustomerRef2',
      maxlength : '250',
      datatype : 'Text',
    },

    {
      type: 'textarea',
      label: 'Pick Up Location',
      name: 'PickupLocation',
      class: 'col-md-6',
      id: 'PickupLocation',
      maxlength : '250',
      datatype : 'Text',
    },


    {
      type: 'textarea',
      label: 'Pick Up Additional Instructions',
      name: 'PickupAddionalInstructions',
      class: 'col-md-6',
      id: 'PickupAddionalInstructions',
      datatype : 'Text',
      maxlength : '250',
    },

    {
      type: 'textarea',
      label: 'Booking Instructions',
      name: 'BookingInstructions',
      class: 'col-md-6',
      id: 'BookingInstructions',
      datatype : 'Text',
      maxlength : '250',
    },

    {
      type: 'textarea',
      label: 'Cargo Description',
      name: 'CargoDescriptions',
      class: 'col-md-6',
      id: 'CargoDescriptions',
      datatype : 'Text',
      maxlength : '250',
    },
  ],
  CreateBookingAddOnsAtOrigin : [
    {
      type: 'check',
      label: 'Pickup',
      name: 'Pickup',
      class: 'col-md-2',
      id: 'Pickup'
    },
    {
      type: 'check',
      label: 'Export Clearance',
      name: 'ExportClearance',
      class: 'col-md-2',
      id: 'ExportClearance'
    },
    {
      type: 'check',
      label: 'Cargo Insurance',
      name: 'CargoInsurance',
      class: 'col-md-2',
      id: 'CargoInsurance'
    },
        

  ],
  CreateBookingAddOnsAtDestination : [
    {
      type: 'check',
      label: 'Import Clearance',
      name: 'ImportClearance',
      class: 'col-md-2',
      id: 'ImportClearance'
    },
    {
      type: 'check',
      label: 'Final Delivery',
      name: 'FinalDelivery',
      class: 'col-md-2',
      id: 'FinalDelivery'
    },
    {
      type: 'check',
      label: 'Security Services',
      name: 'SecurityServices',
      class: 'col-md-2',
      id: 'SecurityServices'
    },
  ],
  CreateContainer : [
    {
      type: 'Input',
      label: 'Count Of Container',
      placeHolder: '',
      class: 'col-md-2',
      name: 'BookingContainerId',
      id: 'BookingContainerId',
      required : true,
      max: 99999999999999999999,
      datatype : 'Number',

    },
    {
      type: 'Select',
      options: 'CONTAINER-TYPES',
      label: 'Container Type',
      class: 'col-md-2',
      name: 'ContainerTypeCode',
      id: 'ContainerTypeCode',
      required : true,
    },
    {
      type: 'Select',
      options: 'COMMODITIES',
      label: 'Commodity',
      class: 'col-md-2',
      name: 'CommodityId',
      id: 'CommodityId',
      datatype : 'select',
      // required : true,


    },
    {
      type: 'Select',
      options: 'CONTAINER-GRADES',
      label: 'Container Grade',
      class: 'col-md-2',
      name: 'ContainerGrade',
      id: 'ContainerGrade',
      datatype : 'select',
      // required : true,


    },
    {
      type: 'Select',
      options: 'EMPTY-PICKUP-LOCATIONS',
      label: 'Empty Equipment Pickup Location',
      class: 'col-md-3',
      name: 'EmptyPickupLocationId',
      id: 'EmptyPickupLocationId',
      datatype : 'select',
      // required : true,
    },
  ],
  CreatePackage : [
    {
      type: 'Input',
      label: 'Count Of Package',
      placeHolder: '',
      class: 'col-md-2',
      name: 'CountPackages',
      id: 'CountPackages',
      required : true,
      max: 99999999999999999999,
      datatype : 'Number',
    },

    {
      type: 'Select',
      options: 'PACKAGE-TYPES',
      label: 'Package Type',
      class: 'col-md-3',
      name: 'PackageTypeCode',
      id: 'PackageTypeCode',
      datatype : 'select',
      required : true,
    },

    {
      type: 'Input',
      label: 'Weight',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Weight',
      id: 'Weight',
      datatype : 'Number',
      required : true,
      max: 99999

    },

    {
      type: 'Select',
      options: 'UNITS-WEIGHT',
      label: 'Units Of Weight',
      class: 'col-md-3',
      name: 'UnitWeightCode',
      id: 'UnitWeightCode',
      datatype : 'select',
      required : true,
    },

    {
      type: 'Input',
      label: 'Volume',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Volume',
      id: 'Volume',
      datatype : 'Number',
      required : true,
      max: 99999
    },

    {
      type: 'Select',
      options: 'UNITS-VOLUME',
      label: 'Units Of Volume',
      class: 'col-md-3',
      name: 'UnitVolumeCode',
      id: 'UnitVolumeCode',
      datatype : 'select',
      required : true,
    },

    {
      type: 'Input',
      label: 'Length',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Length',
      id: 'Length',
      datatype : 'Number',
      required : true,
      max: 999999999999999999999999
    },

    {
      type: 'Input',
      label: 'Width',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Width',
      id: 'Width',
      datatype : 'Number',
      required : true,
      max: 999999999999999999999999
    },

    {
      type: 'Input',
      label: 'Height',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Height',
      id: 'Height',
      datatype : 'Number',
      required : true,
      max: 999999999999999999999999
    },

    {
      type: 'Select',
      options: 'UNITS-DIMENSION',
      label: 'Units Of Dimension',
      class: 'col-md-3',
      name: 'UnitDimensionCode',
      id: 'UnitDimensionCode',
      datatype : 'select',
      required : true,


    },
  ],
  CarriedDetails : [
    {
      type: 'Select',
      options: 'CARRIERS-AIR',
      label: 'Carrier',
      class: 'col-md-3',
      name: 'CarrierAirCode',
      id: 'CarrierAirCode',
      datatype : 'select'

    },
    {
      type: 'Input',
      label: 'Vessel / Flight No',
      placeHolder: '',
      class: 'col-md-2',
      name: 'VesselName',
      id: 'VesselName',
    },

    {
      type: 'Input',
      label: 'Voyage',
      placeHolder: '',
      class: 'col-md-2',
      name: 'Voyage',
      id: 'Voyage',
    },

    {
      type: 'Input',
      label: 'Carrier Booking',
      placeHolder: '',
      class: 'col-md-2',
      name: 'CarrierBookingNumber',
      id: 'CarrierBookingNumber',
    },

    {
      type: 'Input',
      label: 'Co-Loader Booking',
      placeHolder: '',
      class: 'col-md-3',
      name: 'CoLoaderBookingNumber',
      id: 'CoLoaderBookingNumber',
    },


    {
      type: 'Datetime',
      label: 'ETD',
      placeHolder: '',
      class: 'col-md-2',
      name: 'EstimatedTimeDeparture',
      id: 'EstimatedTimeDeparture',
      required : true,

    },
    {
      type: 'Datetime',
      label: 'ETA',
      placeHolder: '',
      class: 'col-md-2',
      name: 'EstimatedTimeArrival',
      id: 'EstimatedTimeArrival',
      required : true,

    },
        
    {
      type: 'Datetime',
      label: 'Document Cut-Off',
      name: 'DocumentCuttOff',
      class: 'col-md-3',
      id: 'DocumentCuttOff',
      datatype : 'date',
      required : true,


    },
    {
      type: 'Datetime',
      label: 'CargoCutOff',
      name: 'CargoCutOff',
      class: 'col-md-3',
      id: 'CargoCutOff',
      datatype : 'date',
      required : true,


    },
    {
      type: 'Datetime',
      label: 'Estimated Cargo Picked up/Received',
      name: 'EstimatedCargoPicked',
      class: 'col-md-3',
      id: 'EstimatedCargoPicked',
      datatype : 'date',
      required : true,

    },
    {
      type: 'Select',
      options: 'SHIPMENT-TYPES',
      label: 'Shipment Type',
      class: 'col-md-2',
      name: 'ShipmentTypeId',
      id: 'ShipmentTypeId',
      datatype : 'select'

    },
  ],
  AdditioanalComments : [
    {
      type: 'textarea',
      label: 'Comments',
      name: 'Comments',
      class: 'col-md-6',
      id: 'Comments'
    },
    {
      type: 'textarea',
      label: 'Internal Comments',
      name: 'InternalComments',
      class: 'col-md-6',
      id: 'InternalComments'
    },
  ],
  CreateConsol: [
    {
      type: 'label',
      label: 'Importer Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'ImporteName',
      id: 'ImporteName',
      style : {},
      required : true
    },
    {
      type: 'Input',
      label: 'Consol No. ',
      class: 'col-md-3',
      placeHolder: '',
      name: 'ConsolNumber',
      id: 'ConsolNumber',
      style : {},
      // datatype : 'Number',
      required : true
    },
    {
      type: 'Select',
      options: 'EQUIPMENT',
      label: 'Equipment Type',
      name: 'ContainerTypeCode',
      class: 'col-md-3',
      id: 'ContainerTypeCode',
      style : {},
      required : true
    },
    {
      type: 'Input',
      label: 'Req. Temperature',
      placeHolder: '',
      class: 'col-md-3',
      name: 'RequestedTemp',
      id: 'RequestedTemp',
      datatype : 'Number',
      required : false,
    },
    {
      type: 'Datetime',
      label: 'Expected Salling Date',
      name: 'ExpectedSaillingDate',
      class: 'col-md-3',
      id: 'ExpectedSaillingDate',
      style : {},
      required : false
    },
    {
      type: 'Datetime',
      label: 'Est. Loading Date',
      name: 'EstLoadingDate',
      class: 'col-md-3',
      id: 'EstLoadingDate',
      style : {},
      required : false
    },
    {
      type: 'Datetime',
      label: 'Warehouse Cut-off',
      name: 'WarehouseCutoff',
      class: 'col-md-3',
      id: 'WarehouseCutoff',
      style : {},
      required : false
    },
    // {
    //   type: 'Datetime',
    //   label: 'Actual Loading Date',
    //   name: 'ActualLoadingDate',
    //   class: 'col-md-3',
    //   id: 'ActualLoadingDate',
    //   style : {},
    //   required : false
    // },
    {
      type: 'textarea',
      label: 'Consol Comments:',
      name: 'Comment',
      class: 'col-md-12',
      id: 'Comment',
      style : {}
    },
  ],
  CreateConsolView: [
    {
      type: 'label',
      label: 'Importer Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'ImporteName',
      id: 'ImporteName',
      style : {},
      required : true
    },
    // {
    //   type: 'label',
    //   label: 'Consol No. ',
    //   class: 'col-md-3',
    //   placeHolder: '',
    //   name: 'ConsolNumber',
    //   id: 'ConsolNumber',
    //   style : {},
    //   required : true
    // },
    {
      type: 'label',
      options: 'EQUIPMENT',
      label: 'Equipment Type',
      name: 'ContainerTypeCode',
      class: 'col-md-3',
      id: 'ContainerType',
      style : {},
      required : true
    },
    {
      type: 'label',
      label: 'Req. Temperature',
      placeHolder: '',
      class: 'col-md-3',
      name: 'RequestedTemp',
      id: 'RequestedTemp',
      datatype : 'Number',
      required : false,
    },
    {
      type: 'label',
      label: 'Expected Salling Date',
      name: 'ExpectedSaillingDate',
      class: 'col-md-3',
      id: 'ExpectedSaillingDate',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Est. Loading Date',
      name: 'EstLoadingDate',
      class: 'col-md-3',
      id: 'EstLoadingDate',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Warehouse Cut-off',
      name: 'WarehouseCutoff',
      class: 'col-md-3',
      id: 'WarehouseCutoff',
      style : {},
      required : false
    },
    // {
    //   type: 'label',
    //   label: 'Actual Loading Date',
    //   name: 'ActualLoadingDate',
    //   class: 'col-md-3',
    //   id: 'ActualLoadingDate',
    //   style : {},
    //   required : false
    // },
    {
      type: 'label',
      label: 'Consol Comments:',
      name: 'Comment',
      class: 'col-md-12',
      id: 'Comment',
      style : {}
    },
  ],
  createConsolEdit: [
    {
      type: 'label',
      label: 'Importer Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'ImporteName',
      id: 'ImporteName',
      style : {},
      required : true
    },
    // {
    //   type: 'label',
    //   label: 'Consol No. ',
    //   class: 'col-md-3',
    //   placeHolder: '',
    //   name: 'ConsolNumber',
    //   id: 'ConsolNumber',
    //   style : {},
    //   datatype : 'Number',
    //   required : true
    // },
    {
      type: 'Select',
      options: 'EQUIPMENT',
      label: 'Equipment Type',
      name: 'ContainerTypeCode',
      class: 'col-md-3',
      id: 'ContainerTypeCode',
      style : {},
      required : true
    },
    {
      type: 'Input',
      label: 'Req. Temperature',
      placeHolder: '',
      class: 'col-md-3',
      name: 'RequestedTemp',
      id: 'RequestedTemp',
      datatype : 'Number',
      required : false,
    },
    {
      type: 'Datetime',
      label: 'Expected Salling Date',
      name: 'ExpectedSaillingDate',
      class: 'col-md-3',
      id: 'ExpectedSaillingDate',
      style : {},
      required : false
    },
    {
      type: 'Datetime',
      label: 'Ext Loading Date',
      name: 'EstLoadingDate',
      class: 'col-md-3',
      id: 'EstLoadingDate',
      style : {},
      required : false
    },
    {
      type: 'Datetime',
      label: 'Warehouse Cut-off',
      name: 'WarehouseCutoff',
      class: 'col-md-3',
      id: 'WarehouseCutoff',
      style : {},
      required : false
    },
    // {
    //   type: 'Datetime',
    //   label: 'Actual Loading Date',
    //   name: 'ActualLoadingDate',
    //   class: 'col-md-3',
    //   id: 'ActualLoadingDate',
    //   style : {},
    //   required : false
    // },
    {
      type: 'textarea',
      label: 'Consol Comments:',
      name: 'Comment',
      class: 'col-md-12',
      id: 'Comment',
      style : {},
      required : false
    },
  ],
  ConsolBooking: [
    {
      type: 'label',
      label: 'Shipment No.',
      class: 'col-md-3',
      placeHolder: '',
      name: 'ContainerReference',
      id: 'ContainerReference',
      style : {},
      Icon : true,
      required : false
    },
    {
      type: 'label',
      label: 'Container No. ',
      class: 'col-md-3',
      placeHolder: '',
      name: 'ContainerNumber',
      id: 'ContainerNumber',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Seal 1',
      name: 'ContainerSeal',
      class: 'col-md-3',
      id: 'SeaContainerSeall1',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Seal 2',
      name: 'ContainerSeal2',
      class: 'col-md-3',
      id: 'ContainerSeal2',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'HBL',
      name: 'HBL',
      class: 'col-md-3',
      id: 'HBL',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Loading Date',
      name: 'ContainerLoading',
      class: 'col-md-3',
      id: 'ContainerLoading',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'ETD',
      name: 'ETD',
      class: 'col-md-3',
      id: 'ETD',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'ETA',
      name: 'ETA',
      class: 'col-md-3',
      id: 'ETA',
      style : {}
    },
    {
      type: 'label',
      label: 'POL',
      name: 'POL',
      class: 'col-md-3',
      id: 'POL',
      style : {}
    },
    {
      type: 'label',
      label: 'POD',
      name: 'POD',
      class: 'col-md-3',
      id: 'POD',
      style : {}
    },
  ],
  ConsolPolist: [
    {
      type: 'label',
      label: 'Total Pallets',
      class: 'col-md-3',
      placeHolder: '',
      name: 'TotalPallets',
      id: 'TotalPallets',
      style : {},
      Icon : true,
      datatype : 'Number',
      required : false
    },
    {
      type: 'label',
      label: 'Total QTY',
      class: 'col-md-3',
      placeHolder: '',
      name: 'TotalQTY',
      id: 'TotalQTY',
      style : {},
      datatype : 'Number',
      required : false
    },
    {
      type: 'label',
      label: 'Total Weight',
      name: 'TotalWeight',
      class: 'col-md-3',
      id: 'TotalWeight',
      style : {},
      datatype : 'Number',
      required : false
    },
    {
      type: 'label',
      label: 'Final Destination',
      name: 'FinalDestination',
      class: 'col-md-3',
      id: 'FinalDestination',
      style : {},
      required : false
    },
    {
      type: 'Input',
      label: 'Equipment Tare',
      name: 'EquipmentTare',
      class: 'col-md-3',
      id: 'EquipmentTare',
      style : {},
      required : false
    },
    {
      type: 'Input',
      label: 'Verified Goods Weight',
      name: 'VerifiedGoodsWeight',
      class: 'col-md-3',
      id: 'VerifiedGoodsWeight',
      style : {},
      required : false
    },
    {
      type: 'Input',
      label: 'VGM Total',
      name: 'VGMTotal',
      class: 'col-md-3',
      id: 'VGMTotal',
      style : {},
      required : false
    },
  ],
  viewConsolPolist: [
    {
      type: 'label',
      label: 'Total Pallets',
      class: 'col-md-3',
      placeHolder: '',
      name: 'TotalPallets',
      id: 'TotalPallets',
      style : {},
      Icon : true,
      required : false
    },
    {
      type: 'label',
      label: 'Total QTY',
      class: 'col-md-3',
      placeHolder: '',
      name: 'TotalQTY',
      id: 'TotalQTY',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Total Weight',
      name: 'TotalWeight',
      class: 'col-md-3',
      id: 'TotalWeight',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Final Destination',
      name: 'FinalDestination',
      class: 'col-md-3',
      id: 'FinalDestination',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Equipment Tare',
      name: 'EquipmentTare',
      class: 'col-md-3',
      id: 'EquipmentTare',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'Verified Goods Weight',
      name: 'VerifiedGoodsWeight',
      class: 'col-md-3',
      id: 'VerifiedGoodsWeight',
      style : {},
      required : false
    },
    {
      type: 'label',
      label: 'VGM Total',
      name: 'VGMTotal',
      class: 'col-md-3',
      id: 'VGMTotal',
      style : {},
      required : false
    },
  ],

};

export default orderForm;