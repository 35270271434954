import React, { Fragment } from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
// import { InfoButton } from './../../../../Global/Button/';
// import Search from './../../../../Global/Search/';
import Alert from './../../../../Global/Alert/';
import DialogComponent from './../../../../Global/Dialog';
import Table from './../../../../Global/TableV1';
import Modal from './../../../../Global/Modal/';
import { columns, ItemsToFilter } from './config';
import { Services } from './../../Services';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { UpdateSelection, SetSelection, Setno, SetContainerReference, PushHistori, SaveProcedure,AssignProcedure } from '../../../../../actions/Booking/create/';
import { withRouter } from 'react-router-dom';

// const Actions = 
// ? Header Component
const Header = (props) => {
  return (
    <React.Fragment>
      <h3 className='text-primary'>Shipment# {props.shipment}</h3>
      <h4 className='text-muted'>Select the PO´s to add:</h4>
    </React.Fragment>
  );
};

// ? Actions Component
const Actions = (props) => {
  return (
    <div>
      <DialogComponent
        disabled={false}
        color={'primary'}
        icon={'add'}
        actionText={'Add'}
        title='Add POs'
        // message={`Are you sure do you want to add the PO´s ${props.po_ids.join(',')} to the Shipment ${props.shipment}?`}
        message={`Are you sure do you want to add the PO´s to the Shipment ${props.shipment}?`}
        cancelText='Cancel'
        agreeText='Yes, I´m sure'
        agree={() => props.action()}
      />
    </div>
  );
};

/**
 * Associate PO Component
 */
function AssociatePOComponent(props) {
  let [selected, setSelected] = React.useState([]);
  let [rows, setRows] = React.useState([]);
  // let [rowsTMP, setRowsTMP] = React.useState([]);
  // * alert
  let [alert_open, setAlertOpen] = React.useState({
    open: false,
    type: 'success',
    message: ''
  });

  // ? on open alert
  const handleOpenAlert = React.useCallback((open, type, message) => {
    let alert = {
      open,
      type,
      message
    };
    setAlertOpen({ ...alert });
  }, []);

  /**
   * Get PO´s
   */
  const getPOs = React.useCallback(async () => {
    try {
      const url = Services.PROCEDURES.path;
      // const url = 'http://localhost:3004';
      const response = await axios.get(`${url}/order/search/all?type=bookingCreate&id=${props._customer}`);
      // console.log('this is the associate ==>', response);
      setRows(response.data.data);
      // setRowsTMP(response.data.data);
    } catch (error) {
      console.error('error trying get associate POs');
    }
  }, [props._customer]);

  /**
   * Assign POs
   */
  const AssignPOs = React.useCallback(async () => {
    // Axios.get(`https://www.magicanalytics.app/poseidon/api/booking/v1//booking/verifycontainer/${paramas.value.shipmentNo}`, {      
    Axios.get(`${Services.GET_ALL_BOOKINGS.path}/booking/verifycontainer/${props.shipment}?Proce=1`, {
      //Axios.post(`${Services.EXEC_PROCEDURE.path}/booking/procedure`, {
    }).then(response => {
      if (response.data.data.length <= 1) {
        let array = [];

        let IntergloboContainerReference = response.data.data[0].InternalReference;
        let items = selected;
  
        const Filtrated = rows.filter((order) => selected.indexOf(order.POId) !== -1);
        let stringSelected = '';
        Filtrated.forEach((e) => {
          stringSelected += ` ${e.PONumber},`;
        });
        let string = stringSelected.substring(0, stringSelected.length - 1);
        Swal.fire({
          title: `Are you sure you want to associate the following POs to the Shipment No. ${props.shipment}?`,
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#20c554',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          html: `<table id="table" border=0>
                      <thead>
                          <tr>
                              <th>Interglobo Container Ref./ Container No.</th>
                              <th>POs</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>${response.data.data[0].Container}</td>
                              <td>${string}</td>
                          </tr>
                    </tbody>
                    </table>`,
          preConfirm: () => {

            let groupPush = {
              id: IntergloboContainerReference,
              data: items
            };
            array.push(groupPush);
            // return Axios.post(`ttps://www.magicanalytics.app/poseidon/api/booking/v1/booking/associationcontainer`, { array }
            return Axios.post(`${Services.GET_ALL_BOOKINGS.path}/booking/associationcontainer`, { array }
              // return Axios.post(`${Services.EXEC_PROCEDURE.path}/booking/procedure`, {
            ).then(response => {
              // console.log('entre a la parte de response', response);
              return { resp: true };
            }).catch(() => {
              // console.log('catch');
              return { resp: false };
            });
          }
        })
          .then((result) => {
            // console.log('la respues despues de confirmar ', result);
            if (result.value && result.value.resp == true) {
              // console.log('se cumpli hacemos la otra parte');
              try{
                // const response = 
                const url = Services.PROCEDURES.path;
                // const url = 'http://localhost:3004';
                axios.post(`${url}/procedures/assign`, {
                  user: props._user,
                  shipment: props.shipment,
                  po_ids: selected
                });
                // console.log('The assign POs -->', response);
                Swal.fire(
                  '',
                  `Consolidation Completed for Shipment No. ${props.shipment}.`,
                  'success'
                );
                getPOs();
                setSelected([]);
                // props.restart();
              }catch(error){
                handleOpenAlert(true, 'error', 'Error trying associate PO´s');
                console.error('assing error ==>', error);
              }

            }
            else {
              // console.log("error");
            }
          });
      }
      else {
        const Filtrated = rows.filter((order) => selected.indexOf(order.POId) !== -1);
        let params = {value :{ resp: response.data, shipmentNo: props.shipment }};
        let obj = { 'paramas': params};
        let objet2={
          'user': props._user,
          'shipment': props.shipment,
          'po_ids': selected
        };

        props.AssignProcedure(objet2);
        props.SetContainerReference(response.data.data);
        props.PushHistori('/procedures');
        props.SaveProcedure(obj);
        props.SetSelection(Filtrated);
        props.history.push('/container-association');
      }
    }).catch(() => {
      console.log('AssignPOs : error--');
    });
  }, [props, selected, rows, getPOs, handleOpenAlert]);

  /**
   * Check handler
   * @param {*} object 
   */
  function checkHandler(lines) {
    // console.log('check Handler ->', lines);
    setSelected(lines);
  }

  /**
   * Check all lines handler
   * @param {*} allLines 
   */
  function checkAll(allLines) {
    // console.log('checked all ==>', allLines);
    setSelected(allLines);
  }



  return (
    <React.Fragment>
      <Alert open={alert_open.open} message={alert_open.message} type={alert_open.type} />
      <Modal
        title
        Header={<Header shipment={props.shipment}></Header>}
        actions={selected.length > 0 ? true : false}
        Actions={<Actions po_ids={selected} shipment={props.shipment} action={AssignPOs}></Actions>}
        buttonTitle={'Add PO'}
        iconButton={'add_circle_outline'}
        sizeButton={'small'}
        // typeButon={'info'}
        element={'button'}
        elementType={'info'}
        widthModal={'xl'}
        init={getPOs}
        onClose={props.restart}
      >
        <Fragment>
          {/* <Search data={rows} /> */}
          <Table
            search
            configSearch={{ fieldsToFilter: ItemsToFilter }}
            rows={rows}
            handler={checkHandler}
            onselectAll={checkAll}
            columns={columns}
            showSelect
            keyCheck={'POId'}
          />
        </Fragment>
      </Modal>
    </React.Fragment>
  );
};

AssociatePOComponent.propTypes = {
  _customer: propTypes.string,
  shipment: propTypes.string,
  _user: propTypes.string
};
AssociatePOComponent.defaultProps = {
  _customer: '0',
  shipment: '0',
  _user: '0'
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = dispatch => ({
  UpdateSelection: (selection) => dispatch(UpdateSelection(selection)),
  SetSelection: (selection) => dispatch(SetSelection(selection)),
  Setno: (selection) => dispatch(Setno(selection)),
  SetContainerReference: (selection) => dispatch(SetContainerReference(selection)),
  PushHistori: (selection) => dispatch(PushHistori(selection)),
  SaveProcedure: (selection) => dispatch(SaveProcedure(selection)),
  AssignProcedure: (selection) => dispatch(AssignProcedure(selection))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssociatePOComponent));