import React, { Component } from "react";
import { Container, Row, Col, CardBody, CardText } from "reactstrap";
import { ChartSpline, ChartBar } from "./ChartFlot.setup.js";
import { MyResponsiveBarCanvas } from "./Charts/BarChart";
import { MyResponsivePieCanvas } from "./Charts/DonutGraph";
import YearsSelect from "./CatYears";
import FlotChart from "./Charts/Float";
import axios from "axios";
import { Services } from "../../../Services";
import "./styles.scss";

class Graphic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearCat: props.yearCat ? props.yearCat : [],
      tercerChart: props.tercerChart ? props.tercerChart : [],
      ChartSpline: ChartSpline,
      ChartBar: ChartBar,
      data: this.props.data,
      clientesSelect: this.props.clientesSelect,
      newMeses: this.props.newMeses
        ? this.props.newMeses
        : [
            ["Jan", 0],
            ["Feb", 0],
            ["Mar", 0],
            ["Apr", 0],
            ["May", 0],
            ["Jun", 0],
            ["Jul", 0],
            ["Aug", 0],
            ["Sep", 0],
            ["Oct", 0],
            ["Nov", 0],
            ["Dec", 0],
          ],
    };
  }
  componentDidMount = () => {
    this.buildMounths();
  };

  buildMounths = (year) => {
    var today = new Date();
    var yearActual = today.getFullYear();

    let yearselected = year ? year : yearActual;
    let dataInvoiceTrend = this.state.data;
    let { ChartSpline } = this.state;
    let data = [];

    Object.keys(this.state.newMeses).forEach((e) => {
      let array = [];
      array.push(this.state.newMeses[e][1]);
      array.push(this.state.newMeses[e][2]);
      data.push(array);
    });

    ChartSpline.data[0].data = data;
    this.setState(
      {
        ChartSpline,
        chart1: "",
        yearselected,
      },
      () => {
        this.setState({
          chart1: (
            <>
              <Col md={12}>
                <div className="col-md-1"></div>
                <div className="flex-containerCatyear col-md-11">
                  {dataInvoiceTrend.length === 0 ? (
                    ""
                  ) : (
                    <YearsSelect
                      yearselected={this.state.yearselected}
                      yearCat={this.state.yearCat}
                      onChangeYear={this.onChangeYear}
                    />
                  )}
                </div>
              </Col>
              {dataInvoiceTrend.length === 0 ? (
                <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                  {" "}
                  No Results
                </h1>
              ) : (
                <FlotChart
                  options={ChartSpline.options}
                  data={ChartSpline.data}
                  className="flot-chart"
                  height="250px"
                />
              )}
            </>
          ),
        });
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        tercerChart: nextProps.tercerChart,
        yearCat: nextProps.yearCat,
        data: nextProps.data,
        newMeses: nextProps.newMeses,
        clientesSelect: nextProps.clientesSelect,
      },
      () => {
        this.buildMounths();
      }
    );
  };

  onChangeYear = async (yeards) => {
    // ? flotData
    let { ChartSpline } = this.state;

    let objPost = {
      client: this.state.clientesSelect,
      year: yeards,
    };

    axios
      .post(
        `${Services.GET_WAREHOUSING.path}warehouse/warehousechangeyeard`,
        objPost
      )
      .then((success) => {
        let data = [];

        Object.keys(success.data.data.newMeses).forEach((e) => {
          let array = [];
          array.push(success.data.data.newMeses[e][1]);
          array.push(success.data.data.newMeses[e][2]);
          data.push(array);
        });

        ChartSpline.data[0].data = data;
        this.setState(
          {
            ChartSpline,
            chart1: "",
            yearselected: yeards,
          },
          () => {
            this.setState({
              chart1: (
                <>
                  <Col md={12}>
                    <div className="col-md-1"></div>
                    <div className="flex-containerCatyear col-md-11">
                      {this.state.data.length === 0 ? (
                        ""
                      ) : (
                        <YearsSelect
                          yearselected={this.state.yearselected}
                          yearCat={this.state.yearCat}
                          onChangeYear={this.onChangeYear}
                        />
                      )}
                    </div>
                  </Col>
                  {this.state.data.length === 0 ? (
                    <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                      {" "}
                      No Results
                    </h1>
                  ) : (
                    <FlotChart
                      options={ChartSpline.options}
                      data={ChartSpline.data}
                      className="flot-chart"
                      height="250px"
                    />
                  )}
                </>
              ),
            });
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  render() {
    let { tercerChart, chart1 } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col style={{ marginBottom: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Cartons by Arrival at Warehouse
                </CardText>
                {chart1}
              </CardBody>
            </div>
          </Col>
          <Col style={{ marginBotton: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Top 10 By Item
                </CardText>
                {tercerChart.length === 0 ? (
                  <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                    {" "}
                    No Results
                  </h1>
                ) : (
                  <MyResponsiveBarCanvas
                    position="horizontal"
                    data={this.state.tercerChart}
                  />
                )}
              </CardBody>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Graphic;
