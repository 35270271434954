import React, { Component } from "react";
import { Container, Row, Col, CardBody, CardText } from "reactstrap";
import { ChartSpline, ChartBar } from "./ChartFlot.setup.js";
import { MyResponsiveBarCanvas } from "./Charts/BarChart";
import { MyResponsivePieCanvas } from "./Charts/DonutGraph";
import "./styles.scss";

class Graphic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tercerChart: props.tercerChart ? props.tercerChart : [],
      cuartaChar: props.cuartaChar ? props.cuartaChar : [],
      ChartSpline: ChartSpline,
      ChartBar: ChartBar,
    };
  }
  componentDidMount = () => {
    // this.buildMounths();
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      cuartaChar: nextProps.cuartaChar,
      tercerChart: nextProps.tercerChart,
    });
  };

  render() {
    let { cuartaChar, tercerChart } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col style={{ marginBotton: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Inventory By Warehouse
                </CardText>
                {cuartaChar.length === 0 ? (
                  <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                    {" "}
                    No Results
                  </h1>
                ) : (
                  <MyResponsivePieCanvas data={cuartaChar} />
                )}
              </CardBody>
            </div>
          </Col>
          <Col style={{ marginBotton: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Top10 By Item
                </CardText>
                {tercerChart.length === 0 ? (
                  <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                    {" "}
                    No Results
                  </h1>
                ) : (
                  <MyResponsiveBarCanvas
                    position="vertical"
                    data={this.state.tercerChart}
                  />
                )}
              </CardBody>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Graphic;
