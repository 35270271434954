import React, { Suspense } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import {
  Button,
  Input,
  TransitionablePortal,
  Segment,
  Header,
  Tab,
} from "semantic-ui-react";
import {
  Divider,
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import axios from "axios";
import { connect } from "react-redux";
import { message } from "antd";

import { Icon } from "@material-ui/core/";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import {
  SetActiveSave,
  SetDataFilters,
} from "./../../../../actions/Reports/Scheduling/";
import { Services } from "../Services";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { validateIdParams } from "../../../utils/regularExpressionValidation";
const DataReport = React.lazy(() => import("./data"));
const FilterReport = React.lazy(() => import("./filters"));
const PreviewReport = React.lazy(() => import("./preview"));

function pad(value) {
  return value < 10 ? "0" + value : value;
}
function createOffset(date) {
  var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign + hours + ":" + minutes;
}

function createOffsetValue(date) {
  var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = Math.floor(offset / 60);
  var minutes = ((offset % 60) / 60) * 10;
  return sign + hours + "." + minutes;
}

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  check: {
    color: blue[800],
    "&$checked": {
      color: blue[900],
    },
  },
}));
const RadioSelect = withStyles({
  root: {
    color: blue[700],
    "&$checked": {
      color: blue[800],
    },
  },
  checked: {},
})((props) => <Radio color="default" size="small" {...props} />);

function CheckSelect(props) {
  const classes = useStyles();
  return (
    <Checkbox
      checkedIcon={<Icon>{props.icon}</Icon>}
      color="none"
      className={classes.check}
      size="small"
      onClick={props.handler}
      checked={props.selected}
      inputProps={{ "aria-labelledby": props.id }}
    />
  );
}
class ReportAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
      isEdit: props.match.params
        ? props.match.params.id
          ? true
          : false
        : false,
      customReportId: props.match.params
        ? props.match.params.id
          ? props.match.params.id
          : ""
        : "",
      allFields: [],
      mailList: {
        columns: [
          {
            id: "Email",
            label: "",
          },
          {
            id: "Delete",
            label: "",
          },
        ],
        data: [],
      },
      openPortal: false,
      startDate: "",
      endDate: "",
      hour: moment()
        .hour(0)
        .minute(0),
      day: 1,
      reportType: "demand",
      frequency: "Daily",
      recurrence: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      recurrenceMonth: {
        January: false,
        February: false,
        March: false,
        April: false,
        May: false,
        June: false,
        July: false,
        August: false,
        September: false,
        October: false,
        November: false,
        December: false,
      },
      selectedFields: [],
      selectedFilters: [],
      reportName: "",
      descriptionReport: "",
      fields: [],
      filters: [],
      timeZone: createOffset(new Date()),
      timeZoneValue: createOffsetValue(new Date()),
    };
  }

  componentDidMount = () => {
    let {
      isEdit,
      customReportId,
      timeZone,
      timeZoneValue,
      recurrence,
      recurrenceMonth,
      mailList,
    } = this.state;
    if (isEdit) {
      let idValidate = validateIdParams(customReportId);
      if (!idValidate) {
        message.error("Data entered is invalid", 2);
        setTimeout(() => {
          this.props.history.push("/reports");
        }, 2000);
        return;
      }
      axios
        .get(`${Services.DOWNLOAD_FILES.path}reports/customs/${customReportId}`)
        .then((success) => {
          // axios.post(`http://localhost:3003/reports/genReport`,obj).then(success => {
          let resp = success.data.data;
          let startDate = "";
          let endDate = "";
          if (
            resp.config.config.startDate &&
            resp.config.config.startDate !== "Invalid date"
          ) {
            startDate = moment(new Date(resp.config.config.startDate)).format(
              "YYYY-MM-DD"
            );
          }
          if (
            resp.config.config.endDate &&
            resp.config.config.endDate !== "Invalid date"
          ) {
            endDate = moment(new Date(resp.config.config.endDate)).format(
              "YYYY-MM-DD"
            );
          }
          let reportType = resp.ReportType
            ? resp.ReportType === "O"
              ? "demand"
              : "Scheduled"
            : "demand";
          let hour = moment()
            .hour(0)
            .minute(0);
          if (resp.config.config.hour) {
            let h = new Date(resp.config.config.hour);
            hour = moment()
              .hour(h.getHours())
              .minute(h.getMinutes());
          }
          let day = 1;
          if (resp.config.config.day) {
            day = resp.config.config.day;
          }
          if (resp.config.config.timeZone) {
            timeZone = resp.config.config.timeZone;
          }
          if (resp.config.config.timeZoneValue) {
            timeZoneValue = resp.config.config.timeZoneValue;
          }
          let frequency = "Daily";
          if (resp.config.type) {
            frequency = resp.config.type;
            if (resp.config.type === "Daily") {
              recurrence = resp.config.config.recurrence;
            } else {
              recurrenceMonth = resp.config.config.recurrence;
            }
          }
          if (resp.Emails) {
            let emails = resp.Emails.split(",");
            emails.map((e) => {
              mailList.data.push({ Email: e });
              return e;
            });
          }
          this.props.SetDataFilters(resp.filters);
          this.setState(
            {
              fields: resp.fields,
              filters: resp.filters,
              reportName: resp.ReportName,
              descriptionReport: resp.Description,
              timeZoneValue,
              timeZone,
              mailList,
              startDate,
              endDate,
              reportType,
              hour,
              day,
              frequency,
              recurrence,
              recurrenceMonth,
            },
            () => {
              this.generateCatalogs();
            }
          );
        })
        .catch((error) => {
          console.error("ReportAnalytics: Didmount:", error);
        });
    } else {
      this.generateCatalogs();
    }
  };

  generateCatalogs = () => {
    let { isEdit } = this.state;
    axios
      .get(`${Services.DOWNLOAD_FILES.path}reports/getFields?isPreview=true`)
      .then((success) => {
        // console.log('success ==>', success);
        let allFields = success.data.data.map((e) => {
          let obj = {
            value: e.ColumnName,
            label: e.ColumnName,
            catalog: e.Table,
            type: e.ColumnType,
          };
          return obj;
        });
        this.setState({ allFields }, () => {
          if (isEdit) {
            this.setFilters();
          }
        });
        // this.setAllFields(allFields);
      })
      .catch((error) => {
        console.error("generateCatalogs: ", error);
      });
  };

  handleParseValue(value) {
    return value.replace(/'/g, "");
  }
  setFilters = () => {
    let { allFields, filters } = this.state;
    // console.log('this is the filters ==>', filters);
    let selectedFields = [];
    allFields.map((e) => {
      filters.map((f) => {
        if (`${e.value} (${e.catalog})` === f.table) {
          // console.log(`${e.value} (${e.catalog})`,'--------',  f.table);
          // console.log('value 1', f.value1);
          // console.log('value 2', f.value2);
          // console.log('this is type:', e.type);
          var value1 = f.value1;
          var value2 = f.value2;
          if (e.type === "DATE") {
            value1 = this.handleParseValue(value1);
            value2 = this.handleParseValue(value2);
            value1 = moment(new Date(value1)).format("YYYY-MM-DD");
            value2 = moment(new Date(value2)).format("YYYY-MM-DD");
          } else if (e.type === "TEXT") {
            value1 = this.handleParseValue(value1);
            value2 = this.handleParseValue(value2);
          } else if (e.type === "NUMERIC") {
            value1 = this.handleParseValue(value1);
            value2 = this.handleParseValue(value2);
          }
          selectedFields.push({
            title: e.value,
            catalog: e.catalog,
            id: e.value,
            type: e.type,
            operator: f.operator,
            value: value1,
            value2: value2,
            condition: "And",
          });
        }
        return f;
      });
      return e;
    });
    this.setState({
      selectedFilters: selectedFields,
      // , buttonDisabled: false
    });
    // console.log('selected fields: --->', selectedFields);
    this.props.SetDataFilters(selectedFields);
    this.props.SetActiveSave(false);
  };

  handleChange = (event, type) => {
    if (type === "frequency") {
      this.setState({ frequency: event.target.value });
    } else {
      this.setState({ reportType: event.target.value });
    }
  };

  handleChangeCheck = (value) => {
    let newRecurrence = this.state.recurrence;
    newRecurrence[value] = !newRecurrence[value];
    this.setState({ recurrence: newRecurrence });
  };

  handleMonthChangeCheck = (value) => {
    let newRecurrence = this.state.recurrenceMonth;
    newRecurrence[value] = !newRecurrence[value];
    this.setState({ recurrenceMonth: newRecurrence });
  };
  // // ! deprecated function
  // getSelected = selected => {
  //   // this.setState({selectedFields: selected, buttonDisabled: selected.length === 0 ? true : false});
  // }

  setAllFields = (selected) => {
    this.setState({ allFields: selected });
  };
  // // ! deprecated function
  // getFilters = selected => {
  //   // this.setState({selectedFilters: selected, buttonDisabled: false});
  // }

  frecuencySelected = () => {
    let { frequency, recurrence, recurrenceMonth } = this.state;
    if (frequency === "Daily") {
      let days = Object.keys(recurrence);
      return (
        <>
          <div className="col-4">
            <div className="row">
              <p className="pReport">Week Days:</p>
              <FormGroup>
                {days.map((e, ind) => (
                  <FormControlLabel
                    control={
                      // <Checkbox checked={recurrence[e]} onChange={() => this.handleChangeCheck(e)} />
                      <CheckSelect
                        icon={"check_circle"}
                        handler={() => this.handleChangeCheck(e)}
                        selected={recurrence[e]}
                        id={ind}
                      ></CheckSelect>
                    }
                    label={e}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
          <div className="col-4">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">Start Date:</p>
              <Input
                placeholder="Start Date"
                type="date"
                name="startDate"
                className="emailInput"
                id="startDate"
                value={this.state.startDate}
                onChange={(ev, result) => {
                  this.setState({ startDate: result.value });
                }}
              />
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">End Date:</p>
              <Input
                placeholder="End Date"
                type="date"
                name="endDate"
                className="emailInput"
                id="endDate"
                value={this.state.endDate}
                onChange={(ev, result) => {
                  this.setState({ endDate: result.value });
                }}
              />
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">Hour:</p>
              <TimePicker
                value={this.state.hour}
                showSecond={false}
                className="emailInput"
                allowEmpty={false}
                use12Hours
                format="h:mm a"
                onChange={(value) => {
                  this.setState({ hour: value });
                }}
              />
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">Timezone:</p>
              <Input
                placeholder="Timezone"
                disabled={true}
                name="Timezone"
                className="emailInput"
                id="Timezone"
                value={this.state.timeZone}
              />
            </div>
          </div>
        </>
      );
    } else {
      let months = Object.keys(recurrenceMonth);
      let firtsMonths = [];
      let secondMonths = [];
      months.map((e, i) => {
        if (i < 6) {
          firtsMonths.push({
            label: e,
            checked: recurrenceMonth[e],
          });
        } else {
          secondMonths.push({
            label: e,
            checked: recurrenceMonth[e],
          });
        }
        return e;
      });
      let days = [];
      for (var i = 1; i < 32; i++) {
        days.push({
          label: i,
          value: i,
        });
      }
      return (
        <>
          <div className="col-4">
            <div className="row">
              <p className="pReport">Months:</p>
              <div className="row">
                <div className="col-6">
                  <FormGroup>
                    {firtsMonths.map((e, ind) => (
                      <FormControlLabel
                        control={
                          <CheckSelect
                            icon={"check_circle"}
                            handler={() => this.handleMonthChangeCheck(e.label)}
                            selected={e.checked}
                            id={ind}
                          ></CheckSelect>
                        }
                        label={e.label}
                      />
                    ))}
                  </FormGroup>
                </div>
                <div className="col-6">
                  <FormGroup>
                    {secondMonths.map((e, ind) => (
                      <FormControlLabel
                        control={
                          // <Checkbox checked={e.checked} onChange={() => this.handleMonthChangeCheck(e.label)} />
                          <CheckSelect
                            icon={"check_circle"}
                            handler={() => this.handleMonthChangeCheck(e.label)}
                            selected={e.checked}
                            id={ind}
                          ></CheckSelect>
                        }
                        label={e.label}
                      />
                    ))}
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">Day</p>
              <Select
                className="emailInput"
                name="Interval"
                id="daySelect"
                placeholder="Day(s)"
                value={this.state.day}
                isClearable={false}
                clearable={false}
                options={days}
                onChange={(value) => {
                  this.setState({ day: value.value });
                }}
              />
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">Hour:</p>
              <TimePicker
                value={this.state.hour}
                showSecond={false}
                className="emailInput"
                use12Hours
                allowEmpty={false}
                format="h:mm a"
                onChange={(value) => {
                  this.setState({ hour: value });
                }}
              />
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p className="pInput">Timezone:</p>
              <Input
                placeholder="Timezone"
                disabled={true}
                name="Timezone"
                className="emailInput"
                id="Timezone"
                value={this.state.timeZone}
              />
            </div>
          </div>
        </>
      );
    }
  };

  checkSelected = () => {
    let { frequency, reportType } = this.state;
    if (reportType === "demand") {
      return <div />;
    } else {
      // return (
      //   <div />
      // )
      return (
        <div>
          <div className="col-md-12">
            <strong style={{ marginLeft: "-12px", color: "black" }}>
              Scheduling
            </strong>
          </div>
          <br />
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <div className="row">
                  <p className="pReport">Frequency:</p>
                  <RadioGroup
                    aria-label="gender"
                    name="gender2"
                    value={frequency}
                    onChange={(event) => {
                      this.handleChange(event, "frequency");
                    }}
                  >
                    <FormControlLabel
                      value="Daily"
                      control={
                        // <Radio className="MuiRadio-colorRadio" />
                        <RadioSelect />
                      }
                      label="Daily"
                    />
                    <FormControlLabel
                      value="Monthly"
                      control={
                        <RadioSelect />
                        // <Radio className="MuiRadio-colorRadio" />
                      }
                      label="Monthly"
                    />
                  </RadioGroup>
                </div>
              </div>
              {this.frecuencySelected()}
            </div>
          </div>
        </div>
      );
    }
  };

  deleteMail = (index, i) => {
    let { mailList, indexSelected } = this.state;
    mailList.data.splice(indexSelected, 1);
    this.setState({
      mailList: mailList,
      openPortal: false,
      //  buttonDisabled: mailList.length === 0 ? true : false
    });
    this.props.SetActiveSave(mailList.length === 0 ? false : true);
  };

  saveReport = () => {
    // ? erased from state
    // selectedFields
    let {
      isEdit,
      frequency,
      customReportId,
      recurrence,
      recurrenceMonth,
      startDate,
      endDate,
      hour,
      day,
      mailList,
      reportType,
      selectedFilters,
      reportName,
      descriptionReport,
    } = this.state;
    // let error = '';
    if (
      (reportType !== "demand" && mailList.data.length > 0) ||
      reportType === "demand"
    ) {
      let sendPost = true;
      if (reportType !== "demand") {
        if (frequency === "Daily") {
          let keys = Object.keys(recurrence);
          let findTrue = false;
          keys.map((e) => {
            if (recurrence[e]) {
              findTrue = true;
            }
            return e;
          });
          if (!startDate || !endDate || !hour || !findTrue) {
            sendPost = false;
            if (!findTrue) {
              // error = 'It is necessary to select a day of the week';
              message.error("It is necessary to select a day of the week", 2);
            }
            if (!hour) {
              // error = 'It is necessary to select an hour';
              message.error("It is necessary to select an hour", 2);
            }
            if (!startDate) {
              // error = 'It is necessary to select a start date';
              message.error("It is necessary to select a start date", 2);
            }
            if (!endDate) {
              // error = 'It is necessary to select a end date';
              message.error("It is necessary to select a end date", 2);
            }
          }
        } else {
          let keys = Object.keys(recurrenceMonth);

          let findTrue = false;
          keys.map((e) => {
            if (recurrenceMonth[e]) {
              findTrue = true;
            }
            return e;
          });
          if (!findTrue || !hour || !day) {
            sendPost = false;
            if (!findTrue) {
              // error = 'It is necessary to select a month';
              message.error("It is necessary to select a month", 2);
            }
            if (!hour) {
              // error = 'It is necessary to select an hour';
              message.error("It is necessary to select an hour", 2);
            }
            if (!day) {
              // error = 'It is necessary to select a day';
              message.error("It is necessary to select an hour", 2);
            }
          }
        }
      }
      if (sendPost) {
        if (!reportName || !descriptionReport) {
          message.error("Report name and description are required", 2);
        } else {
          let config = {
            type: frequency,
            config: {
              recurrence: frequency === "Daily" ? recurrence : recurrenceMonth,
              startDate: moment(startDate).format("LLLL"),
              endDate: moment(endDate).format("LLLL"),
              hour: hour.format("LLLL"),
              day: day,
              timeZone: createOffset(new Date()),
              timeZoneValue: createOffsetValue(new Date()),
            },
          };
          let obj = {
            // fields: selectedFields,
            // filters: selectedFilters,
            fields: this.props.data,
            filters: this.props.filters,
            isPreview: false,
            customer: this.props._customer,
            user: this.props._user,
            reportName: reportName,
            description: descriptionReport,
            reportType: reportType === "demand" ? "O" : "S",
            mailList: mailList.data ? mailList.data : [],
            config: config,
            customReportId: customReportId,
          };
          let method = "post";
          if (isEdit) {
            method = "put";
          }
          const key = method === "post" ? "create_report" : "update_report";
          message.loading({
            content:
              method === "post" ? "Creating Report..." : "Updating Report",
            key,
          });
          axios[method](`${Services.DOWNLOAD_FILES.path}reports/genReport`, obj)
            .then(() => {
              // axios[method](`http://localhost:3003/reports/genReport`,obj).then(success => {
              message.success({
                content:
                  method === "post"
                    ? "Report created successfully!"
                    : "Report updated successfully",
                key,
                duration: 2,
              });
              setTimeout(() => {
                this.props.history.push("/reporting");
              }, 2000);
            })
            .catch((error) => {
              message.error("Error trying save the report", 2);
              console.error("Error /reporting", error);
              // this.setState({haveError: true, error: 'Error trying to save the report'});
            });
        }
      } else {
        // console.log('mensaje de error Falta algo en la configuracion');
        // this.setState({haveError: true, error});
        // message.error('Some field are required, pelase verify', 2);
      }
    } else {
      // console.log('mensaje de error Faltan mails');
      // this.setState({haveError: true, error: 'It is necessary to add an email'});
      message.error("It is necessary to add an email", 2);
    }
  };

  render() {
    let {
      mailList,
      allFields,
      fields,
      filters,
      openPortal,
      reportType,
      selectedFields,
      selectedFilters,
      isEdit,
    } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas fa-1x mr-2 fas fa-table" />
            My Reports
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px", color: "black" }}>
                General
              </strong>
            </div>
            <br></br>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  Report Name*
                  <div className="row">
                    <Input
                      placeholder="Report Name"
                      name="reportName"
                      className="emailInput"
                      id="reportName"
                      value={this.state.reportName}
                      onChange={(ev, result) => {
                        this.setState({ reportName: result.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  Description*
                  <div className="row">
                    <Input
                      placeholder="Description"
                      name="descriptionReport"
                      className="emailInput"
                      id="descriptionReport"
                      value={this.state.descriptionReport}
                      onChange={(ev, result) => {
                        this.setState({ descriptionReport: result.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <Divider />
            <br></br>
            {reportType === "Scheduled" ? (
              <>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="col-12">
                        <strong style={{ marginLeft: "-12px", color: "black" }}>
                          Send to
                        </strong>
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 5 }}>
                        Email
                        <div className="row">
                          <Input
                            placeholder="Email"
                            name="email"
                            className="emailInput"
                            id="email"
                            value={this.state.emailInput}
                            onChange={(ev, result) => {
                              this.setState({ emailInput: result.value });
                            }}
                          />
                          <Fab
                            color="lightBlue"
                            className="MuiFab-lightBlue customSmall"
                            aria-label="add"
                            onClick={() => {
                              let { emailInput } = this.state;
                              if (emailInput) {
                                if (emailInput.length > 0) {
                                  mailList.data.push({ Email: emailInput });
                                  this.setState(
                                    {
                                      mailList: mailList,
                                      emailInput: "",
                                      // buttonDisabled: selectedFields.length === 0 ? true : false
                                    },
                                    () => {
                                      // console.log('this is the mailList', this.state.mailList);
                                      this.props.SetActiveSave(
                                        this.state.mailList.data.length > 0 &&
                                          this.props.data.length > 0
                                          ? true
                                          : false
                                      );
                                    }
                                  );
                                }
                              }
                            }}
                          >
                            <AddIcon fontSize="small" />
                          </Fab>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="col-12">
                        <strong style={{ marginLeft: "-12px", color: "black" }}>
                          Mail List
                        </strong>
                      </div>
                      <table className="table">
                        <tbody className="table-body">
                          {mailList.data.map((item, index1) => {
                            let td = mailList.columns.map((f, i) => {
                              if (f.id === "Delete") {
                                return (
                                  <td style={{ textAlign: "right" }} key={i}>
                                    <em
                                      onClick={() => {
                                        this.setState({
                                          openPortal: true,
                                          indexSelected: index1,
                                          mailSelected: item,
                                        });
                                      }}
                                      className="fa-2x mr-2 far fa-trash-alt"
                                      style={{ color: "#FC3737" }}
                                    ></em>
                                    <TransitionablePortal
                                      open={openPortal}
                                      transition={{
                                        animation: "drop",
                                        duration: 500,
                                      }}
                                    >
                                      <Segment
                                        style={{
                                          left: "70%",
                                          position: "fixed",
                                          top: "50%",
                                          zIndex: 1000,
                                        }}
                                      >
                                        <Header>Delete Mail</Header>
                                        <p>
                                          The report will not be send it if you
                                          delete the email account. Are you sure
                                          you want to delete this email?
                                        </p>
                                        <div className="row">
                                          <div className="col-6">
                                            <Button
                                              content="Cancel"
                                              negative
                                              onClick={() =>
                                                this.setState({
                                                  openPortal: false,
                                                })
                                              }
                                            />
                                          </div>
                                          <div
                                            className="col-6"
                                            style={{ textAlign: "right" }}
                                          >
                                            <Button
                                              content="Accept"
                                              negative={false}
                                              style={{
                                                backgroundColor: "#2B98F0",
                                                color: "white",
                                              }}
                                              onClick={() => {
                                                this.deleteMail(item, index1);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Segment>
                                    </TransitionablePortal>
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: 0,
                                    }}
                                    key={i}
                                  >
                                    {item[f.id]}
                                  </td>
                                );
                              }
                            });
                            return (
                              <tr style={{ border: "2px white solid" }}>
                                {td}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <br></br>
                <Divider />
                <br></br>
              </>
            ) : (
              <></>
            )}
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="col-md-12">
                    <strong style={{ marginLeft: "-12px", color: "black" }}>
                      Report Type
                    </strong>
                  </div>
                  <br></br>
                  <div className="col-12">
                    <div className="row">
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender2"
                        value={reportType}
                        onChange={(event) => {
                          this.handleChange(event, "reportType");
                        }}
                      >
                        <FormControlLabel
                          value="demand"
                          control={
                            // <Radio className="MuiRadio-colorRadio" />
                            <RadioSelect />
                          }
                          label="On Demand"
                        />
                        <FormControlLabel
                          value="Scheduled"
                          control={
                            // <Radio className="MuiRadio-colorRadio" />
                            <RadioSelect />
                          }
                          label="Scheduled"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  {this.checkSelected()}
                </div>
              </div>
            </div>
            <br></br>
            <Divider />
            <br></br>
            <Tab
              menu={{ secondary: true, className: "myWrapped" }}
              menuPosition="right"
              panes={[
                {
                  menuItem: "Data",
                  render: () => (
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <DataReport
                        allFields={allFields}
                        fields={fields}
                        filters={filters}
                        selectedFields={selectedFields}
                        setAllFields={this.setAllFields}
                        setSelectedFilters={this.getFilters}
                        setSelected={this.getSelected}
                      />
                    </Suspense>
                  ),
                },
                {
                  menuItem: "Filters",
                  render: () => (
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <FilterReport
                        filters={filters}
                        allFields={allFields}
                        selectedFields={selectedFields}
                        selectedFilters={selectedFilters}
                        setSelected={this.getFilters}
                      />
                    </Suspense>
                  ),
                },
                {
                  menuItem: "Preview",
                  render: () => (
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <PreviewReport
                        selectedFields={selectedFields}
                        selectedFilters={selectedFilters}
                        customer={this.props._customer}
                      />
                    </Suspense>
                  ),
                },
              ]}
            />
            <div
              style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}
            >
              {this.state.haveError ? (
                <Alert severity="error">{this.state.error}</Alert>
              ) : (
                <></>
              )}
              <br />
              {/* {this.state.buttonDisabled ? <></> : <Button disabled={this.state.buttonDisabled} variant="contained" className="saveButton" onClick={() => {this.saveReport();}}><p>Save</p></Button>} */}
              {isEdit ? (
                <Button
                  variant="contained"
                  className="saveButton"
                  onClick={this.saveReport}
                >
                  <p>Save</p>
                </Button>
              ) : this.props.active_save ? (
                <Button
                  disabled={this.props.active_save ? false : true}
                  variant="contained"
                  className="saveButton"
                  onClick={this.saveReport}
                >
                  <p>Save</p>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}
const MapStateToProps = (state) => ({
  data: state.reports.data,
  filters: state.reports.filters,
  active_save: state.reports.active_save,
});
const MapDispatchToProps = (dispatch) => ({
  SetActiveSave: (status) => dispatch(SetActiveSave(status)),
  SetDataFilters: (filters) => dispatch(SetDataFilters(filters)),
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withRouter(ReportAnalytics));
