import ContentWrapper from "../../../../components/template/Layout/ContentWrapper";
import React, { useEffect, useState, Suspense, useCallback } from "react";
import {
  useParams,
  withRouter,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { Tab } from "semantic-ui-react";
import Axios from "axios";
import { Services } from "../Services";
import Loader from "../../../Global/Loader";
import { message } from "antd";
import { validateIdParams } from "../../../utils/regularExpressionValidation";

const Summary = React.lazy(() => import("./tabs_components/summary.js"));
const Events = React.lazy(() =>
  import("../../Order/order-po-detail-view/components/Events")
);

const Comments = React.lazy(() =>
  import("../../Order/order-po-detail-view/components/Comments")
);
const Documments = React.lazy(() =>
  import("../../Order/order-po-detail-view/components/Documents")
);

function vendor_detail(params) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [fullLoader, setFullLoader] = useState(false);
  const history = useHistory();

  const get_order = useCallback(async () => {
    try {
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        message.error("Data entered is invalid", 2);
        setTimeout(() => {
          history.push("/vendor-management");
        }, 2000);
        return;
      }
      let url = `${Services.VENDOR_MANAGEMENT.path}order/${id}`;
      let response = await Axios.get(url);
      // console.log("response-->", response.data.data);
      setData(response.data.data);
      setFullLoader(true);
    } catch (error) {}
  }, []);

  useEffect(() => {
    get_order();
  }, []);

  return (
    <>
      <Loader hidden={fullLoader} />
      <ContentWrapper>
        <div className="content-heading">
          <i className="icon-people fa-1x mr-2"></i>
          Vendor Management Detail
        </div>
        <Tab
          menu={{ secondary: true }}
          panes={[
            {
              menuItem: "Summary",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Summary
                    data={data}
                    get_order={get_order}
                    rolId={params._rolId}
                  />
                </Suspense>
              ),
            },
            {
              menuItem: "Events",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Events rolId={params._rolId} data={data} id={id} />
                </Suspense>
              ),
            },
            {
              menuItem: "Comments",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Comments
                    data={data}
                    _user={params._user}
                    _customer={params._customer}
                    updateInfo={get_order}
                    id={id}
                  />
                </Suspense>
              ),
            },
            {
              menuItem: "Documents",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Documments
                    rolId={params._rolId}
                    data={data}
                    id={id}
                    _user={params._user}
                  />
                </Suspense>
              ),
            },
          ]}
        ></Tab>
      </ContentWrapper>
    </>
  );
}

export default withRouter(vendor_detail);
