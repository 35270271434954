import React, { Component } from "react";
import Table from "../../../../../template/Table";
import { Card, CardBody } from "reactstrap";
import { Modal, Icon } from "semantic-ui-react";
import { Button } from "reactstrap";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import { Input } from "reactstrap";
import "./styles.scss";
import axios from "axios";
import { Services } from "../../../Services";
import swal from "sweetalert";
import Checkbox from "@material-ui/core/Checkbox";
import Errors from "../../../../../template/Errors";
import { FilePond } from "react-filepond";
import { validateIdParams } from "../../../../../utils/regularExpressionValidation";

const styles = {
  background: "red",
};

const config = [
  { label: "Customer name", id: "CustomerName", type: "simple" },
  { label: "Telephone", id: "Telephone", type: "simple" },
  { label: "Tax ID", id: "TaxId", type: "simple" },
  { label: "Country", id: "CountryName", type: "simple" },
  { label: "Customer code", id: "CustomerCode", type: "simple" },
  { label: "Interglobo Id", id: "IntergloboId", type: "simple" },
  { label: "Automatic Shipments", id: "AutomaticShipments", type: "simple" },
];

class FormExtended extends Component {
  constructor(props) {
    super(props);
    this.pond = React.createRef();
    this.state = {
      avatartOriginal: "",
      rows: [],
      edit: false,
      columnsContact: [
        { label: "Name", key: "Name" },
        { label: "last Name", key: "LastName" },
        { label: "Position", key: "Position" },
        { label: "Telephone", key: "Telephone" },
        { label: "Email", key: "Email" },
        { label: "", key: "", custom: (value) => this.custom3(value) },
      ],
      itemsContact: [],
      columnsUser: [
        { label: "ID", key: "UserId" },
        { label: "Name", key: "Name" },
        { label: "last Name", key: "LastName" },
        { label: "Email", key: "Email" },
        { label: "Role", key: "Rol" },
      ],
      itemsUser: [],
      country: [],
      customer: {
        CustomerId: "",
        CustomerName: "",
        Telephone: "",
        TaxId: "",
        Country: "",
        CustomerCode: "",
        IntergloboId: "",
        CountryName: "",
        userIds: "",
        Avatar: "",
        AutomaticShipments: false,
      },
      error: {
        open: false,
        message: "",
        type: "admin",
      },
      items: [],
      contact: {
        Name: "",
        LastName: "",
        Position: "",
        Telephone: "",
        Email: "",
      },
      CreateFormData: [],
      CreateForAdd: [],
      Form: [],
      active: false,
      orders: [],
      data: [],
      columns: [
        {
          label: "ID",
          key: "CustomerId",
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ active: !this.state.active }, () => {
                  this.modalCustomer(value);
                })
              }
            >
              {" "}
              <label style={{ color: "#349ced" }}>
                {value.CustomerId}
              </label>{" "}
            </em>
          ),
        },
        { label: "Customer name", key: "CustomerName" },
        { label: "Customer Code", key: "CustomerCode" },
        { label: "Interglobo Id", key: "IntergloboId" },
        { label: "Tax Id", key: "TaxId" },
        { label: "Telephone", key: "Telephone" },
        { label: "Country", key: "Country" },
        { label: "Automatic Shipments", key: "AutomaticShipments" },
        { label: "Associated User", key: "AssociatedUsers" },
        { label: "action", key: "", custom: (value) => this.custom1(value) },
      ],
      servicesServicces: [
        {
          CargoId: 1,
          Descripcion: "My Dashboard",
          check: false,
          disable: false,
        },
        {
          CargoId: 2,
          Descripcion: "Warehousing",
          check: false,
          disable: false,
        },
        {
          CargoId: 3,
          Descripcion: "Purchase Orders",
          check: false,
          disable: false,
        },
        {
          CargoId: 4,
          Descripcion: "Tracking",
          check: false,
          disable: false,
        },
      ],
    };
  }

  custom1 = (value) => (
    <em
      onClick={() => this.validateDelete(value)}
      className="fa-2x mr-2 far fa-trash-alt"
      style={{ color: "#297AB9" }}
    ></em>
  );
  custom3 = (value) => (
    <em
      onClick={() => this.validedateContac(value)}
      className={this.state.edit ? "fa-2x mr-2 far fa-trash-alt" : ""}
      style={{ color: "#297AB9" }}
    ></em>
  );

  validedateContac = (val) => {
    let id = `${val.Name}`;
    swal({
      title: "Are you sure want to delete this Contact?",
      text: id,
      icon: "warning",
      buttons: true,
    }).then((yeah) => {
      if (yeah) this.deleteCcontact(val);
    });
  };

  deleteCcontact = (name) => {
    let data = name.ContactId;
    this.setState({
      active: true,
    });
    axios
      .delete(`${Services.DeleteContact.path}admin/deleteContact/${data}`)
      .then(() => {
        this.modalCustomer();
        this.setState({
          error: {
            message: "the contact has been deleted!",
            open: true,
            type: "admin",
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  validateDelete = (value) => {
    let id = `${value.CustomerName}`;
    swal({
      title: "Are you sure want to delete customer?",
      text: id,
      icon: "warning",
      buttons: true,
    }).then((yeah) => {
      if (yeah) this.props.deleteCustomer(value);
    });
  };

  modalCustomer = (name) => {
    if (!name) {
      let id = this.state.customer.CustomerId;
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/customer-service");
        }, 2000);
        return;
      }
      axios
        .get(`${Services.GetInfoCustomer.path}admin/infoCustomer/${id}`, {})
        .then((response) => {
          this.setState(
            {
              itemsUser: response.data.resp.array,
              itemsContact: response.data.resp.contact,
            },
            () => {
              this.buildRows();
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            error: {
              message: "Data entered is invalid",
              open: true,
              type: "admin",
            },
          });
          setTimeout(() => {
            this.props.history.push("/customer-service");
          }, 2000);
          return;
        });
    } else {
      let id = name.CustomerId;
      let customer = {
        CustomerId: name.CustomerId,
        CustomerName: name.CustomerName,
        Telephone: name.Telephone,
        TaxId: name.TaxId,
        Country: name.CountryId,
        CustomerCode: name.CustomerCode,
        IntergloboId: name.IntergloboId,
        CountryName: name.Country,
        userIds: name.userIds,
        Avatar: name.URLLogo,
        AutomaticShipments: name.AutomaticShipments === "0" ? false : true,
      };
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/customer-service");
        }, 2000);
        return;
      }
      axios
        .get(`${Services.GetInfoCustomer.path}admin/infoCustomer/${id}`, {})
        .then((response) => {
          this.setState(
            {
              itemsUser: response.data.resp.array
                ? response.data.resp.array
                : [],
              itemsContact: response.data.resp.contact
                ? response.data.resp.contact
                : [],
              customer,
              avatartOriginal: name.URLLogo,
              edit: false,
            },
            () => {
              this.buildFormCreate();
              this.buildRows();
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            error: {
              message: "Data entered is invalid",
              open: true,
              type: "admin",
            },
          });
          setTimeout(() => {
            this.props.history.push("/customer-service");
          }, 2000);
          return;
        });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    let customer_reset = {
      CustomerId: "",
      CustomerName: "",
      Telephone: "",
      TaxId: "",
      Country: "",
      CustomerCode: "",
      IntergloboId: "",
      CountryName: "",
      userIds: "",
      Avatar: "",
      AutomaticShipments: false,
    };
    if (nextProps.errorValidate) {
      this.setState(
        {
          customer: customer_reset,
          edit: false,
          data: nextProps.customer,
          Form: nextProps.Form,
          country: nextProps.country,
          active: true,
          error: {
            message: "Incorrect data, please verify",
            open: true,
            type: "error",
          },
        },
        () => {
          this.buildFormCreate();
          this.buildFormAdd();
        }
      );
    } else {
      this.setState(
        {
          customer: customer_reset,
          edit: false,
          data: nextProps.customer,
          Form: nextProps.Form,
          country: nextProps.country,
          active: false,
        },
        () => {
          this.buildFormCreate();
          this.buildFormAdd();
        }
      );
    }
  };

  updateImg = (str) => {
    let { customer } = this.state;
    customer["Avatar"] = str;
    this.setState({ customer }, () => {
      this.buildFormCreate();
    });
  };

  moreContact = () => {
    let arrayContact = [];
    let idCustomer = this.state.customer.CustomerId;
    arrayContact.push(this.state.contact);
    let data = {
      CustomerId: idCustomer,
      contact: arrayContact,
    };
    axios
      .post(`${Services.PostContact.path}admin/createContact`, data)
      .then(() => {
        let contactArray = this.state.itemsContact;
        contactArray.push(this.state.contact);
        let contact = {
          Name: "",
          LastName: "",
          Position: "",
          Telephone: "",
          Email: "",
        };
        this.setState(
          {
            itemsContact: contactArray,
            contact: contact,
          },
          () => {
            this.buildFormAdd();
            this.modalCustomer();
            this.setState({
              error: {
                message: "The contact has been added correctly",
                open: true,
                type: "admin",
              },
            });
          }
        );
      })
      .catch((error) => {
        console.error(error.response.data.data.message);
        this.setState({
          error: {
            message: "Incorrect data, please verify",
            open: true,
            type: "error",
          },
        });
      });
  };

  buildRows = () => {
    let { customer } = this.state;
    let rows = config.map((e) => {
      return (
        <div
          className={
            e.id === "CustomerName" || e.id === "AutomaticShipments"
              ? "col-md-4 col-lg-3 col-sm-6"
              : "col-md-4 col-lg-2 col-sm-6"
          }
          style={{ paddingTop: "1%" }}
        >
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span style={{ color: "#545350", fontWeight: "bold" }}>
                {e.label}
              </span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span>
                {customer.hasOwnProperty([e.id])
                  ? e.id === "AutomaticShipments"
                    ? customer[e.id] === true
                      ? "Active"
                      : "Disabled"
                    : customer[e.id]
                  : ""}
              </span>
            </div>
          </div>
        </div>
      );
    });
    this.setState({
      rows,
    });
  };

  handleChangeAdd = (name) => (event) => {
    let { contact } = this.state;
    contact[name] = event.target.value;
    this.setState(
      {
        contact,
      },
      () => {
        this.buildFormAdd();
      }
    );
  };

  handleChange = (name) => (event) => {
    let { customer } = this.state;
    customer[name] = event.target.value;
    this.setState(
      {
        customer,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  handleChangeSelect = (selected) => (event) => {
    if (event && event.value) {
      let { customer } = this.state;
      customer[selected] = event.value;
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    } else {
      let { customer } = this.state;
      customer[selected] = "";
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
  };

  handleChangeChecking = (selected) => (event) => {
    let { customer } = this.state;
    let checked = event.target.checked;
    customer[selected] = checked;
    this.setState({ selected }, () => {
      this.buildFormCreate();
    });
  };

  handleChangeCheck = (name) => (event) => {
    this.state.servicesServicces.map((e) => {
      if (e.CargoId === event.target.value) {
        e.check = !e.check;
      }
    });
    this.setState(
      {
        servicesServicces: this.state.servicesServicces,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  buildFormCreate = () => {
    const { Form, customer, country } = this.state;
    let CreateFormData = Form.CreateCustomer.map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              id={e.id}
              disabled={this.state.edit === false ? true : false}
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={customer[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Select")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}>
                {" "}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Select
              id={e.id}
              disabled={this.state.edit === false ? true : false}
              required
              type={e.datatype}
              name={e.name}
              value={customer[e.id]}
              onChange={this.handleChangeSelect(e.id)}
              options={country}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "check") {
        return (
          <div className={e.class} key={e.id}>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <Fade left opposite>
                <span id={e.label} style={e.style}>
                  {" "}
                  <label>
                    {e.label} {e.required == true ? <span>*</span> : ""}{" "}
                  </label>
                </span>
              </Fade>
            </div>
            <div
              className="col-md-12"
              style={{ textAlign: "center", marginTop: "-12px" }}
            >
              <Checkbox
                checked={customer[e.id]}
                onChange={this.handleChangeChecking(e.id)}
                value={customer[e.id]}
                color="primary"
              />
            </div>
          </div>
          // <div className={e.class} key={e.id}>
          //   <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          //   <Selecion style={{ width: '100%', maxWidth: '838px' }}
          //     multiple
          //     className="textField"
          //     value={[]}
          //     onChange={this.handleChangeCheck(e.id)}
          //     name=""
          //     MenuProps={MenuProps}
          //     input={<Line maxlength="6" id="select-multiple-checkbox" />}
          //     MenuProps={MenuProps}
          //     margin="normal"
          //   >
          //     {this.state.servicesServicces.map(option => (
          //       <MenuItem key={option.CargoId} value={option.CargoId} _value={option.Descripcion}>
          //         <Checkbox checked={option.check} />
          //         <ListItemText style={{ fontSize: '8px !important', }} primary={option.Descripcion} />
          //       </MenuItem>
          //     ))}
          //   </Selecion>
          // </div>
        );
      } else return "";
    });
    this.setState({
      CreateFormData,
    });
  };

  buildFormAdd = () => {
    const { Form, contact } = this.state;
    let CreateForAdd = Form.AddContact.map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.id === "Email" ? "col-md-2" : e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              id={e.id}
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={contact[e.id]}
              onChange={this.handleChangeAdd(e.id)}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else return "";
    });
    this.setState({
      CreateForAdd,
    });
  };

  handleOnClose = () => {
    this.props.chamge();

    this.setState({
      active: false,
      edit: false,
      contact: {
        Name: "",
        LastName: "",
        Position: "",
        Telephone: "",
        Email: "",
      },
    });
  };

  recieve = (array) => {};
  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    const { data, columns } = this.state;
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <div style={{ textAlign: "center" }} className="col-md-12">
              <strong>Customer Information</strong>
            </div>
            <Table
              columns={columns}
              data={data}
              itemsByPage={10}
              styles={styles}
              options={{
                pagination: true,
                search: true,
                downloads: {
                  xlsx: false,
                  pdf: false,
                },
              }}
              onSelect={this.recieve}
            />
          </CardBody>
        </Card>
        <Modal
          open={this.state.active}
          size={"large"}
          dimmer={"inverted"}
          centered={false}
          scrolling
        >
          <Modal.Header className="headerModal">
            {this.state.edit === false ? "View Customer" : "Edit Customer"}
            <div className="iconHeader">
              <Icon
                name="pencil"
                size="small"
                disabled=""
                onClick={() => {
                  this.setState({ edit: !this.state.edit }, () => {
                    if (this.state.edit === false) {
                      let { customer } = this.state;
                      customer["Avatar"] = this.state.avatartOriginal;
                      this.setState(
                        {
                          customer,
                        },
                        () => {
                          this.buildFormCreate();
                          this.buildRows();
                        }
                      );
                    }
                    this.buildFormCreate();
                  });
                }}
              />
            </div>
          </Modal.Header>
          <Errors
            open={this.state.error.open}
            type={this.state.error.type}
            message={this.state.error.message}
            setProps={this.recieveProps}
          ></Errors>

          <Modal.Content scrolling>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px" }}>
                Customer Information
              </strong>
            </div>
            <br></br>
            <div className="row" style={{ width: "100%", marginLeft: "20px" }}>
              {this.state.edit === true ? (
                <FilePond
                  allowFileEncode={true}
                  labelIdle="LOGO"
                  ref={this.pond}
                  labelFileWaitingForSize={false}
                  styleButtonRemoveItemPosition="right"
                  labelButtonRetryItemLoad={false}
                  onupdatefiles={(fileItems) => {
                    if (fileItems.length > 0) {
                      if (
                        fileItems[0].file.type === "image/jpeg" ||
                        fileItems[0].file.type === "image/png" ||
                        fileItems[0].file.type === "image/jpg"
                      ) {
                        if (fileItems[0].file.size < 1000000) {
                          var reader = new FileReader();
                          reader.readAsDataURL(fileItems[0].file);
                          reader.onload = () => {
                            this.updateImg(reader.result);
                          };
                        } else {
                          this.pond.current.removeFiles();
                          this.updateImg(
                            this.state.avatartOriginal
                              ? this.state.avatartOriginal
                              : "https://fsposeidon.blob.core.windows.net/files/user-default-grey.png"
                          );
                          this.setState({
                            error: {
                              message: "exceed the maximum supported size!",
                              open: true,
                              type: "error",
                            },
                          });
                        }
                      } else {
                        this.pond.current.removeFiles();
                        this.updateImg(
                          this.state.avatartOriginal
                            ? this.state.avatartOriginal
                            : "https://fsposeidon.blob.core.windows.net/files/user-default-grey.png"
                        );
                        this.setState({
                          error: {
                            message: "unsupported file type!",
                            open: true,
                            type: "error",
                          },
                        });
                      }
                    } else {
                      this.pond.current.removeFiles();
                      this.updateImg(
                        this.state.avatartOriginal
                          ? this.state.avatartOriginal
                          : "https://fsposeidon.blob.core.windows.net/files/user-default-grey.png"
                      );
                    }
                  }}
                ></FilePond>
              ) : (
                ""
              )}
              <img
                alt="grey_image"
                src={
                  this.state.customer.Avatar
                    ? this.state.customer.Avatar
                    : "https://fsposeidon.blob.core.windows.net/files/user-default-grey.png"
                }
                width="117"
                height="117"
                className="circule"
              />
              {this.state.edit === false ? (
                <div style={{ marginTop: "13px" }} className="col-md-10">
                  <div className="row">{this.state.rows}</div>
                </div>
              ) : (
                <div className="col-md-10">
                  <div className="row">{this.state.CreateFormData}</div>
                </div>
              )}
            </div>
            <br></br>
            <br></br>
            <div hidden={this.state.edit === true ? false : true}>
              <div className="col-md-12">
                <strong style={{ marginLeft: "-12px" }}>Add Contact</strong>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-11">
                  <div className="row">{this.state.CreateForAdd}</div>
                </div>
                <div align="center" className="col-md-1">
                  <em
                    onClick={this.moreContact}
                    className="fa-2x mr-2 fas fa-plus-circle"
                    style={{ marginTop: "33px", color: "#0079B8" }}
                  ></em>
                </div>
              </div>
            </div>
            <br></br>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px" }}>Contact List</strong>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-12">
                <Table
                  data={this.state.itemsContact}
                  columns={this.state.columnsContact}
                  options={{
                    pagination: false,
                    search: false,
                    downloads: { xlsx: false, pdf: false },
                  }}
                ></Table>
              </div>
            </div>
            <br></br>
            <div hidden={this.state.edit === true ? true : false}>
              <div className="col-md-12">
                <strong style={{ marginLeft: "-12px" }}>Associated User</strong>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-12">
                  <Table
                    data={this.state.itemsUser}
                    columns={this.state.columnsUser}
                    options={{
                      pagination: false,
                      search: false,
                      downloads: { xlsx: false, pdf: false },
                    }}
                  ></Table>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions className="actions_modalSelect">
            <div
              hidden={this.state.edit === true ? true : false}
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-12 col-sm-4"
            >
              <Button
                variant="contained"
                color="danger"
                className="button"
                onClick={this.handleOnClose}
              >
                Close
              </Button>
            </div>
            <div
              hidden={this.state.edit === true ? false : true}
              className="row"
            >
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: "center" }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  className="button"
                  onClick={this.handleOnClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={() => {
                    this.props.putCustomer(this.state.customer);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
export default FormExtended;
