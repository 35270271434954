import {
  SET_DATA_FILTER,
  SET_DATA_REPORT,
  SET_ACTIVE_SAVE
} from './../../../actions/Reports/Scheduling/types';

const initialState = {
  data: [],
  filters: [],
  active_save: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case SET_DATA_REPORT:
    return {
      ...state,
      data: action.payload
    };
  case SET_DATA_FILTER:
    return {
      ...state,
      filters: action.payload
    };
  case SET_ACTIVE_SAVE:
    return {
      ...state,
      active_save: action.payload
    };
  default:
    return state;
  }
};