import React, { Component } from 'react';
import pubsub from 'pubsub-js';
import { Collapse } from 'reactstrap';
// import LogoUser from '../../../resources/LogoUser.png'
import Services from '../../poseidon/Admin/Services';
import axios from 'axios';
import Authentication from '../../../services/Authentication.js';
const auth = new Authentication();


class SidebarUserBlock extends Component {

    state = {
      userBlockCollapse: false,
      userName:'',
      logo:[]
    }

    componentDidMount() {
      let id = auth.getAuthentication('customerSelect');
      let path = 'https://poseidonapiadmin.azurewebsites.net/admin/getInfoCustomer';
      axios.get(path+`/${id}`, {
      }).then(response => {
        this.pubsub_token = pubsub.subscribe('toggleUserblock', () => {
          this.setState({
            userBlockCollapse: !this.state.userBlockCollapse,
            userName:auth.getAuthentication('UserName') + '  ' + auth.getAuthentication('UserNameLast'),
            userCustomer:response.data.data[0].Name,
            logo:response.data.data[0].URLLogo
          });
        });
           
      }).catch(error => {
        console.error(error);
      });


    }

    componentWillUnmount() {
      pubsub.unsubscribe(this.pubsub_token);
    }

    render() {
      return (
        <Collapse id="user-block" isOpen={ this.state.userBlockCollapse }>
          <div>
            <div className="item user-block">
              {/* User picture */}
              <div className="user-block-picture">
                <div className="user-block-status">
                  <img className="rounded-circle" src={this.state.logo ? this.state.logo : 'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png'} alt="Avatar" width="60" height="60" />
                  <div className="circle bg-success circle-lg"></div>
                </div>
              </div>
              {/* Name and Job */}
              <div className="user-block-info">
                <span className="user-block-name">Hello, {this.state.userCustomer}</span>
                <span className="user-block-role">{this.state.userName}</span>
              </div>
            </div>
          </div>
        </Collapse>
      );
    }
}

export default SidebarUserBlock;
