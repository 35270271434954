
export const ChartSpline = {
  data: [
    {
      // "label": "Recurrent",
      "color": "#1f92fe",
      "data": [
      ]
    }],

  options: {
    series: {
      lines: {
        show: false
      },
      points: {
        show: true,
        radius: 4
      },
      splines: {
        show: true,
        tension: 0.4,
        lineWidth: 1,
        fill: 0.5
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
    },
    xaxis: {

      tickColor: '#fcfcfc',
      mode: 'categories',
      gridLines: false
    },
    yaxis: {
      // min: 0,
      // max: 1000000, // optional: use it for a clear represetation
      tickColor: '#eee',
      autoscaleMargin: .5,
      tickDecimals: 0,
      tickFormatter: function numberWithCommas(x) {
        return '$' + x.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
      }
    },
    shadowSize: 0
  }

}

export const ChartBar = {
  data: [{
    // "label": "Sales",
    "color": "#9cd159",
    "data": [
    ]
  }],
  options: {
    series: {
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      // position: 'right' or 'left'
      tickColor: '#eee',
      // min: 0,
      // max: 10,
    },
    shadowSize: 0
  }
}

