import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { Menu, Segment, Sidebar } from "semantic-ui-react";
import { connect } from "react-redux";
import { SetFilterUrl } from "./../../../../actions/Orders/List";
import Axios from "axios";

import { subscribeToSocket } from "./socket";
import Authentication from "../../../../services/Authentication.js";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import Table from "../../../template/Table";
import Loader from "./../../../Global/TableLoader";
import { Services } from "../Services";
import { columns, CloseIcon } from "./config";
import Comments from "./components/Comments/";
import Data from "./object";
import "./styles.scss";
const auth = new Authentication();

const styles = {
  background: "red",
};
const KeysFilter = [
  "ConsolNumber",
  "ContainerType",
  "Status",
  "POs",
  "ContainerNumber",
  "POL",
  "POD",
  "ETD",
  "ETA",
  "FinalDestination",
  "TotalPallets",
  "TotalQty",
  "TotalWeight",
  "CreateDate",
  "Customer",
  "LastUpdateDate",
];

class ConsolPlanListCommponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      data: [],
      advancedObj: [],
      loading: true,
      columns: columns,
      searchOnInit: false,
      searchText: "",
      POId: 0,
      openComments: false,
      handleResCom: "unset",
      isSearchComments: true,
      tableLoader: false,
      advancedSearch: [
        {
          title: "Consol Plan Information",
          data: [
            [
              {
                title: "Consol No",
                type: "text",
                id: "ConsolNumber",
                class: "col-3",
                values: [],
                value: "",
              },
              {
                title: "Status",
                type: "multipleSelect",
                id: "Status",
                UseIN: true,
                class: "col-3",
                values: [],
                value: "",
              },
              {
                title: "POL",
                type: "text",
                id: "POL",
                class: "col-3",
                values: [],
                value: "",
              },
              {
                title: "POD",
                type: "text",
                id: "POD",
                class: "col-3",
                values: [],
                value: "",
              },
            ],
          ],
        },
        {
          title: "Dates",
          data: [
            [
              {
                title: "ETD",
                type: "dateRange",
                id: "ETD",
                class: "col-6",
                values: [],
                value: "",
                date: { init: "", end: "" },
              },
              {
                title: "ETA",
                type: "dateRange",
                id: "ETA",
                class: "col-6",
                values: [],
                value: "",
                date: { init: "", end: "" },
              },
            ],
          ],
        },
      ],
    };
  }

  componentDidMount = () => {
    if (this.props.filterUrl) {
      this.props.setFilterUrl("");
    }
    this.getOrdersList("");
    this.getCatalogsIng();
    subscribeToSocket(this.props._customer, (error, message) => {
      if (!error) {
        if (message.customer === this.props._customer) {
          this.resetTable();
        }
      }
    });
  };

  /*=============================================
  =            Get Catalog advandsearch      =
  =============================================*/

  getCatalogsIng = () => {
    console.log("entre por los catalogos");
    let rol =
      this.props._rolId === "1" ||
        this.props._rolId === "3" ||
        this.props._rolId === "6"
        ? true
        : false;
    let id = auth.getAuthentication("customerSelect");
    let data = {
      id: id,
      rol: rol,
      user: this.props._user,
    };
    // axios.get(`${Services.Get_Traceability_Imports.Path}customs/allCustomImports`, {params:{EsDTA:0, search: this.state.search, allData: true}} ).then(success => {
    Axios.post(
      `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/getcatalog-advan/`,
      data
    )
      .then((success) => {
        console.log("success", success.data);
        let Status = success.data.data.Status.map((e, index) => {
          return {
            key: e.Status,
            text: e.Status,
            value: e.Status ? e.Status : "Sin Valor",
          };
        });
        let newAdvanced = this.state.advancedSearch;
        newAdvanced.map((e) => {
          e.data.map((f) => {
            f.map((g) => {
              if (g.id === "Status") {
                g.values = Status;
              }
              return g;
            });
            return f;
          });
          return e;
        });
        this.setState({
          advancedSearch: newAdvanced,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  /*=============================================
  =            Get Order List                   =
  =============================================*/

  setAdvanced = (val) => {
    console.log("val", val);
    this.setState({ advancedObj: val, activePage: 1, numberPage: 1 }, () => {
      this.getOrdersList();
    });
  };

  getOrdersList = async () => {
    let objPost = {
      advanced: this.state.advancedObj,
    };

    try {
      let id = auth.getAuthentication("customerSelect");
      let rol =
        this.props._rolId === "1" ||
          this.props._rolId === "3" ||
          this.props._rolId === "6"
          ? true
          : false;
      // let rol = false;

      let success = await Axios.post(
        `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/consollist/${id}?userId=${this.props._user}&isAll=${rol}`,
        objPost
      );
      // let success = await Axios.get(`https://poseidonapiconsoleplan.azurewebsites.net/console-plan/consollist/${id}`);
      this.setState({ data: success.data.data, loading: false });
      // }
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
    }
  };

  resetTable = () => {
    this.setState({ isSearchComments: false, handleResCom: "unset" }, () => {
      this.getOrdersList(this.props.filterUrl);
    });
  };

  /*=============================================
  =            Selected Order                   =
  =============================================*/

  selectedOrder = (order) => {
    this.setState({
      POId: order.POId,
      PONumber: order.PONumber,
      openComments: true,
      handleResCom: "set",
      isSearchComments: true,
    });
  };

  handleClose = () => {
    this.setState({ openComments: false, isSearchComments: false });
  };

  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    const {
      advancedSearch,
      data,
      columns,
      loading,
      searchOnInit,
      searchText,
      openComments,
      POId,
      PONumber,
      handleResCom,
      isSearchComments,
      tableLoader,
    } = this.state;
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-boxes fa-1x mr-2" />
              Consol Plan
            </div>
          </div>
        </div>
        <Card className="myCard">
          {loading ? (
            <Loader />
          ) : (
            <Sidebar.Pushable as={Segment}>
              <Sidebar
                direction="right"
                as={Menu}
                animation="overlay"
                icon="labeled"
                // onHide={() => setVisible(false)}
                // vertical
                visible={openComments}
                width="very wide"
                id={'sidebarComentsHeight'}
              >
                <Col xs={12} md={12} lg={12} className='d-flex row'>

                  <Col xs={12} md={12} lg={12} className='pr-0 mt-1'>
                    <div className='d-flex justify-content-between'>
                      <div className="mt-2">Consol Plan: {PONumber}</div>
                      <div className="mt-1" onClick={this.handleClose} style={{ cursor: 'pointer' }}>
                        <CloseIcon />
                      </div>
                    </div>
                  </Col>
                  <Comments
                    _customer={this.props._customer}
                    POId={POId}
                    _user={this.props._user}
                    _rolId={this.props._rolId}
                    isSearchComments={isSearchComments}
                    resetTable={this.resetTable}
                  />

                </Col>
              </Sidebar>

              <Sidebar.Pusher>
                <Segment basic>
                  <Table
                    columns={columns}
                    toFilter={KeysFilter}
                    data={data}
                    itemsByPage={10}
                    styles={styles}
                    // name="ConsolList"
                    modalName="ui Modal body order list"
                    customer={this.props._customer}
                    RolId={this.props._rolId}
                    user={this.props._user}
                    advancedSearchData={advancedSearch}
                    advancedSearch={(val) => {
                      this.setAdvanced(val);
                    }}
                    options={{
                      addConsolPlan: true,
                      colsolPlan: {
                        redirect: "/new-consol-plan",
                        return: "/consol-plan",
                      },
                      pagination: true,
                      search: true,
                      modal: false,
                      advandsearch: true,
                      downloads: {
                        xlsx: true,
                        pdf: false,
                        value: { Name: "ConsolPlan" },
                        disableConfig: true,
                      },
                      selectItems: false,
                      // orderAttention: {
                      //   activeFilter: searchOnInit,
                      //   activeText: searchText,
                      //   search: true,
                      //   xlsx: true
                      // },
                      trigger: this.selectedOrder,
                      handleResCom: handleResCom,
                    }}
                    cellType={"thick-rows"}
                  />
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>



          )}

        </Card>
      </ContentWrapper>
    );
  }
}

const MapStateToProps = (state) => ({
  filterUrl: state.order_list.filterUrl,
});

const MapDispatchToProps = (dispatch) => ({
  setFilterUrl: (url) => dispatch(SetFilterUrl(url)),
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withRouter(ConsolPlanListCommponent));
