import React, { Component } from "react";
import propTypes from "prop-types";
import { Table, Row, Col, Container, Button, Input, InputGroup} from "reactstrap";
import { Pagination, Grid, Search } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import Checkbox from "@material-ui/core/Checkbox";
import "react-datetime/css/react-datetime.css";
import { saveAs } from "file-saver";
import { Modal, Dropdown} from "semantic-ui-react";
import Select from "react-select";
import Datetime from "react-datetime";
import ModalReactstrap from "reactstrap/lib/Modal";
import ModalHeaderReactstrap from "reactstrap/lib/ModalHeader";
import ModalBodyReactstrap from "reactstrap/lib/ModalBody";
import ModalFooterReactstrap from "reactstrap/lib/ModalFooter";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import Axios from "axios";
import UsersSelection from "../Chip";
import Modall from "../Modal/AdvancedSearch";
import Modalant from "antd/es/modal";
import IMG from "../../../resources/filter.png";
import QuickView from "./../../poseidon/Order/order-list/components/quickView/";
import { FaCardExcel } from "./../../poseidon/Order/order-list/components/downloadReport/";
import { Services } from "./../../poseidon/Order/Services";
import Authentication from "./../../../services/Authentication";
import DialogComponent from "./../../Global/Dialog";
import "./styles.scss";
import { SetFilterUrl } from "./../../../actions/Orders/List";
import { Icon } from "semantic-ui-react";
import moment from 'moment';
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { withRouter } from "react-router-dom";
import { Switch, Popover,DatePicker } from "antd";
import { log } from "async";

const { RangePicker } = DatePicker;

const select2Value_ = {
  SupplierName: { Code: "SupplierCode", Id: "SupplierContactId" },
  ConsigneeName: { Code: "ConsigneeCode", Id: "ConsigneeContactId" },
  DeliveryName: { Code: "DeliveryCode", Id: "DeliveryContactId" },
};

const auth = new Authentication();

const Column = (props) => {
  return (
    <div
      style={
        props.isLarge
          ? { width: "350px", fontSize: "12px", textAlign: props.positionTitle }
          : { width: "150px", fontSize: "12px", textAlign: props.positionTitle }
      }
    >
      <p>{props.title}</p>
    </div>
    // <div style={props.isLarge ? { width: '350px', fontSize: '12px', textAlign: props.positionTitle } : { width: '150px', fontSize: '12px', textAlign: props.positionTitle }} ><p>{props.title}</p></div>
  );
};

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAdvans: false,
      advancedSearchData: this.props.advancedSearchData
        ? this.props.advancedSearchData
        : [],
      totalItems: 0,
      totalQty: 0,
      additemsQty: [],
      columnsQty: [
        { label: "Line No.", key: "LineNumber" },
        { label: "Item No.", key: "ItemNumber" },
        { label: "Item Description", key: "ItemDescription" },
        { label: "Qty Ordered", key: "QtyOrdered", regExp: /^-*[0-9]+$/ },
        { label: "Qty Confirmed", key: "QtyConfirmed", regExp: /^-*[0-9]+$/ },
      ],
      tableBackup: [],
      resultsfilter: [],
      loading: false,
      filters: [
        {
          id: "OriginCountryId",
          placeholder: "Origin Country",
          options: [],
          allOptions: [],
          value: "",
        },
        {
          id: "SupplierCode",
          placeholder: "Supplier",
          options: [],
          allOptions: [],
          value: "",
        },
        {
          id: "CustomerContainerID",
          placeholder: "Customer Container",
          options: [],
          allOptions: [],
          value: "",
        },
      ],
      nameDataModal: {},
      editModal: "",
      nameDataTime: {},
      editime: "",
      nameSelected: {},
      valueSelected: "",
      editSelectd: "",
      selectedConsolPlan: false,
      opcionsUse: "",
      datatime: false,
      condate: true,
      order: {},
      first: true,
      formDate: [
        {
          type: "Select",
          options: "multiplateOption",
          label: "Multiple Update",
          name: "multi",
          class: "col-md-3",
          id: "multi",
          style: {},
          required: false,
        },
        {
          type: "Datetime",
          label: "Value",
          name: "Value",
          class: "col-md-3",
          id: "value",
          style: {},
          required: true,
        },
      ],
      formSinDate: [
        {
          type: "Select",
          options: "multiplateOption",
          label: "Multiple Update",
          name: "multi",
          class: "col-md-3",
          id: "multi",
          style: {},
          required: false,
        },
        {
          type: "Input",
          label: "Value",
          name: "Value",
          class: "col-md-3",
          id: "value",
          style: {},
          required: false,
        },
      ],
      modalQty: false,
      updateData: [],
      selectedOpcion: "",
      CreateFormData: [],
      respaldo: [],
      edit: [],
      active: false,
      flat: false,
      OriginCountryId: "",
      SupplierCode: "",
      CustomerContainerID: "",
      catOrigin: [],
      catSupplier: [],
      catCustomer: [],
      selectedPo: [],
      all: this.props.data ? this.props.data : [],
      data: this.props.data ? this.props.data : [],
      _rolId: this.props.RolId ? this.props.RolId : 0,
      columns: [],
      mainColumns: [],
      columnsLogic: {},
      MainTable: "",
      grupoOpcion: [],

      /**
       *
       */
      ___rows: [],
      ___columns: [],
      ___mainColumns: [],
      /**
       *
       */
      pages: 0,
      activePage: 1,
      tableData: [],
      /**
       *
       */
      haveCheck: props.options ? props.options.selectItems : false,
      search: "",
      sorts: {},
      selected: [],
      selectControls: {},
      /**
       * downloads
       */
      xlsx: "",
      // require attention component
      text: "Quick view",
    };
  }

  toggle = () => this.setState({ collapse: !this.state.collapse });

  handleChange = (name) => (event) => {
    let { selectControls, data } = this.state;
    let checked = event.target.checked;
    selectControls[name] = checked;
    this.setState(
      {
        selectControls,
      },
      () => {
        this.buildRows();
        let array = this.state.selected;
        let index = data.findIndex((x) => x.mainKey === name);
        if (checked) {
          array.push(data[index]);
        } else {
          if (array.length === 1) array = [];
          else array.splice(index, 1);
        }
        this.setState({ selected: array }, () => {
          this.props.onSelect(this.state.selected);
        });
      }
    );
  };

  onChangeSwich = (checked) => {
    this.props.swich(checked);
  };

  handleChangeMultiple = (name) => (event) => {
    console.log("entre a");
    let selectedPo = [];
    let { data, all, respaldo } = this.state;
    let checked = event.target.checked;

    data.map((e) => {
      if (name.POId == e.POId) {
        e.checked = checked;
        e.edit = true;
      }
      return e;
    });
    all.map((e) => {
      if (name.POId == e.POId) {
        e.checked = checked;
        e.edit = checked;
      }
      return e;
    });
    respaldo.map((e) => {
      if (name.POId == e.POId) {
        e.checked = checked;
        e.edit = checked;
      }
      return e;
    });

    all.map((e) => {
      if (e.checked) {
        let obj = {
          key: e.POId,
          label: e.PONumber,
          avatar: " ",
        };
        selectedPo.push(obj);
      }
      return e;
    });
    this.setState(
      {
        data: checked == false ? respaldo : data,
        all: checked == false ? respaldo : all,
        respaldo: respaldo,
        selectedPo,
      },
      () => {
        if (selectedPo.length > 0) {
          this.setState(
            {
              flat: true,
            },
            () => {
              this.buildRows();
              if (document.getElementById("myBtn")) {
                document.getElementById("myBtn").disabled = true;
                document.getElementById("messagePoEdit").hidden = false;
              }
              if (document.getElementById("addPos")) {
                document.getElementById("addPos").hidden = true;
              }
            }
          );
        } else {
          this.setState(
            {
              flat: false,
            },
            () => {
              this.buildRows();
              if (document.getElementById("myBtn")) {
                document.getElementById("myBtn").disabled = false;
                document.getElementById("messagePoEdit").hidden = true;
              }
              if (document.getElementById("addPos")) {
                document.getElementById("addPos").hidden = false;
              }
            }
          );
        }
      }
    );
  };

  componentDidMount = () => {
    this.buildFormCreate();
    let { options, columns } = this.props;
    let xlsx =
      options.downloads && options.downloads.xlsx ? (
        <div>
          <em
            className="fa-2x fas fa-file-excel mr-2"
            style={{ color: "#66bb6a", cursor: "pointer" }}
            onClick={this.XLSX}
          ></em>
        </div>
      ) : (
        ""
      );

    if (options.orderAttention && options.orderAttention.activeFilter) {
      this.setPagination(columns, this.props);
      this.switchQuickView(options.orderAttention.activeText);
    } else
      this.setState(
        {
          xlsx,
        },
        () => {
          if (options && options.selectItems) {
            columns.map((f, index) => {
              if (f.key === "select") {
                columns.splice(index, 1);
              }
              return f;
            });
            let selectControls = {};
            let lengthData = this.props.data.length;
            for (var i = 0; i < lengthData; i++) {
              this.props.data[i]["mainKey"] = i;
              selectControls[i] = false;
            }
            this.setState({ selectControls, haveCheck: true }, () => {
              columns.unshift({
                label: "",
                key: "select",
                custom: (value) => {
                  return (
                    <Checkbox
                      checked={this.state.selectControls[value.mainKey]}
                      onChange={this.handleChange(value.mainKey)}
                      value={value.mainKey}
                      color="primary"
                    />
                  );
                },
              });
              this.setPagination(columns, this.props);
            });
          } else this.setPagination(columns, this.props);
        }
      );
  };

  buildSorts = () => {
    let { columns } = this.state;
    let sorts = {};
    columns.forEach((e) => {
      sorts[e.key ? e.key : e.label] = {
        active: false,
        sortBy: "ASC",
      };
    });
    this.setState({ sorts }, () => {
      this.buildMainColumns();
      this.buildColumns();
    });
  };

  partirArray = (page, all, pages) => {
    let { itemsByPage } = this.state;
    let array = [];
    if (page === 1)
      for (
        let i = 0;
        i < (all.length < itemsByPage ? all.length : itemsByPage);
        i++
      )
        array.push(all[i]);
    else if (page !== 1 && page !== pages)
      for (let i = (page - 1) * itemsByPage; i < page * itemsByPage; i++)
        array.push(all[i]);
    else
      for (let i = (page - 1) * itemsByPage; i < all.length; i++)
        array.push(all[i]);
    return array;
  };

  setPagination = (columns, props, pageReload) => {
    let { activePage, filters } = this.state;

    this.setState(
      {
        mainColumns: props.mainColumns ? props.mainColumns : [],
        columns,
        itemsByPage: props.itemsByPage ? props.itemsByPage : 10,
        data: props.data,
        all: props.data,
      },
      () => {
        this.buildSorts();
        if (props.options && props.options.pagination) {
          let pages =
            props.data.length > this.state.itemsByPage
              ? props.data.length / this.state.itemsByPage + 1
              : 1;
          let tableData = this.partirArray(
            pageReload ? 1 : activePage,
            props.data,
            pages
          );
          let width =
            this.state.columns.length > 2
              ? 100 / this.state.columns.length
              : 50;
          this.setState(
            {
              activePage: pageReload ? 1 : activePage,
              pages: parseInt(pages),
              totalItems: props.data.length,
              tableData,
              width: parseInt(width),
            },
            () => {
              this.setState({
                loading: false,
              });
              this.buildRows();
              filters.map((e) => {
                if (e.value) {
                  this.selectSearch(e.value, e.id);
                  if (this.state.search !== "") {
                    this.setSearch();
                  }
                } else {
                  if (this.state.search !== "") {
                    this.setSearch();
                  }
                }
              });
            }
          );
        } else {
          this.setState({ tableData: props.data }, () => this.buildRows());
        }
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    this.buildFormCreate();
    let { filters } = this.state;
    if (nextProps.Active) {
      this.setState(
        {
          flat: true,
        },
        () => this.buildRows()
      );
    }

    if (nextProps.MultiUpdate) {
      let data = nextProps.data;

      data.map((e) => {
        this.state.selectedPo.map((f) => {
          if (nextProps.ConsolPlan) {
            if (e.POId === f.key) {
              e.checked = nextProps.ConsolPlan ? false : true;
              e.edit = nextProps.ConsolPlan ? false : true;
            }
          } else {
            if (e.POId === f.key) {
              // e.checked = nextProps.ConsolPlan ? false : true;
              // e.edit = nextProps.ConsolPlan ? false : true;
              e.checked = true;
              e.edit = true;
            }
          }
        });
        return e;
      });

      filters[0].options = nextProps.catOrigin;
      filters[1].options = nextProps.catSupplier;
      filters[2].options = nextProps.catCustomer;
      filters[0].allOptions = nextProps.catOrigin;
      filters[1].allOptions = nextProps.catSupplier;
      filters[2].allOptions = nextProps.catCustomer;
      this.setState({
        CATPOLIST: nextProps.CATPOLIST ? nextProps.CATPOLIST : [],
        // loading: nextProps.ConsolPlan ? false : true,
        filters,
        data,
        respaldo: data,
        mas: nextProps.data,
        updateData: nextProps.optionSelected,
      });
    }
    if (nextProps.options && nextProps.options.handleResCom !== "set") {
      if (nextProps.options) {
        if (nextProps.options.reset) {
          let keysSelectControls = Object.keys(this.state.selectControls);
          let newSelectControls = this.state.selectControls;
          keysSelectControls.map((e) => {
            newSelectControls[e] = false;
            return e;
          });
          this.setState({ selected: [], selectControls: newSelectControls });
        }
      }
      let { options, columns } = nextProps;
      let { selectControls } = this.state;
      let xlsx =
        options.downloads && options.downloads.xlsx ? (
          <div>
            <em
              className="fa-2x fas fa-file-excel mr-2"
              style={{ color: "#66bb6a", cursor: "pointer" }}
              onClick={this.XLSX}
            ></em>
          </div>
        ) : (
          ""
        );

      this.setState(
        {
          xlsx,
        },
        () => {
          if (options && options.selectItems && !this.state.haveCheck) {
            let lengthData = nextProps.data.length;
            for (var i = 0; i < lengthData; i++) {
              nextProps.data[i]["mainKey"] = i;
              if (selectControls[i] === true) selectControls[i] = true;
              else selectControls[i] = false;
            }
            this.setState({ selectControls, xlsx }, () => {
              let index = columns.findIndex((x) => x.key === "select");

              if (index === -1)
                columns.unshift({
                  label: "Select",
                  key: "select",
                  custom: (value) => {
                    return (
                      <Checkbox
                        checked={this.state.selectControls[value.mainKey]}
                        onChange={this.handleChange(value.mainKey)}
                        value={value.mainKey}
                        color="primary"
                      />
                    );
                  },
                });
              this.setPagination(
                columns,
                nextProps,
                this.props.dataSearch != "" || this.props.selectedFilter != null
                  ? true
                  : false
              );
            });
          } else
            this.setPagination(
              columns,
              nextProps,
              this.props.dataSearch != "" || this.props.selectedFilter != null
                ? true
                : false
            );
        }
      );
    }
  };

  buildText = () => {
    let { updateData, order, selectedPo } = this.state;
    let select = "";
    updateData.forEach((e) => {
      if (order.multi == e.value) {
        select = e.label;
      }
    });
    let text = `¿Are you sure want to Update the ${select} on ${selectedPo.length} POs ?`;
    this.setState({
      textMultiple: text,
    });
  };

  handleChangeSelect = (selected) => (event) => {
    let { order } = this.state;
    if (event && event.value) {
      order[selected] = event.value;
      this.setState(
        {
          selectedOpcion: event.value,
          condate:
            event.value == "PromiseCargoReadyDate" ||
            event.value == "ActualCargoReadyDate" ||
            event.value == "ActualCargoPickup" ||
            event.value == "EstimatedArrivalConsolWH" ||
            event.value == "ActualArrivalConsolWH"
              ? true
              : false,
        },
        () => {
          this.buildFormCreate();
          this.buildText();
        }
      );
    } else {
      order[selected] = "";
      this.setState(
        {
          selectedOpcion: "",
        },
        () => {
          this.buildFormCreate();
          this.buildText();
        }
      );
    }
  };

  handleChangeDate2 = (name) => (event) => {
    let { order } = this.state;
    try {
      let value = event.format("YYYY-MM-DD");
      order[name] = value;
      this.buildFormCreate();
      this.buildText();
    } catch (ex) {
      order[name] = "";
      this.buildFormCreate();
      this.buildText();
    }
  };
  handleChangeopcion = (name) => (event) => {
    let { order } = this.state;
    order[name] = event.target.value;
    this.buildFormCreate();
    this.buildText();
  };

  buildFormCreate = () => {
    const { formDate, updateData, formSinDate, condate, order } = this.state;
    let dataopcion = condate ? formDate : formSinDate;
    let CreateFormData = dataopcion.map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              id={e.id}
              disabled={this.state.edit === false ? true : false}
              required
              type={"Number"}
              placeholder={e.placeHolder}
              name={e.id}
              value={order[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChangeopcion(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Select")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}>
                {" "}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Select
              id={e.id}
              disabled={this.state.edit === false ? true : false}
              required
              type={e.datatype}
              name={e.name}
              value={order[e.id]}
              onChange={this.handleChangeSelect(e.id)}
              options={updateData}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Datetime")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}>
                {" "}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Datetime
              timeFormat={false}
              type={e.datatype}
              dateFormat={"YYYY-MM-DD"}
              inputProps={{ className: "form-control", id: e.id }}
              value={order[e.id]}
              onChange={this.handleChangeDate2(e.id)}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else return "";
    });
    this.setState({
      CreateFormData,
    });
  };

  handleChangeInputQty = (id) => (event) => {
    let { additemsQty } = this.state;
    additemsQty.map((e) => {
      if (e.POId === Number(event.target.id) && e.LineNumber === Number(id)) {
        e["QtyConfirmed"] = event.target.value;
      }
      return e;
    });
    let totalQty = 0;
    additemsQty.forEach((e) => {
      totalQty = totalQty + Number(e.QtyConfirmed);
    });
    this.setState(
      {
        additemsQty,
        totalQty,
      },
      () => this.tableBackup()
    );

    //
  };

  handleChangeInput = (name, edit, exp) => (event) => {
    let { data, all } = this.state;
    let value = event.target.value;

    if (exp) {
      const emailRegex = new RegExp(exp);
      if (emailRegex.test(value)) {
        data.map((e) => {
          if (name.POId == e.POId) {
            e[edit] = value;
          }
          return e;
        });
        all.map((e) => {
          if (name.POId == e.POId) {
            e[edit] = value;
          }
          return e;
        });
        this.setState(
          {
            data,
            all,
          },
          () => this.buildRows()
        );
      } else {
        if (value == "") {
          data.map((e) => {
            if (name.POId == e.POId) {
              e[edit] = value;
            }
            return e;
          });
          all.map((e) => {
            if (name.POId == e.POId) {
              e[edit] = value;
            }
            return e;
          });
          this.setState(
            {
              data,
              all,
            },
            () => this.buildRows()
          );
        } else {
          let valor = "";
          data.map((e) => {
            valor = "";
            if (name.POId == e.POId) {
              e[edit] = valor;
            }
            return e;
          });
          all.map((e) => {
            if (name.POId == e.POId) {
              e[edit] = valor;
            }
            return e;
          });
          this.setState(
            {
              data,
              all,
            },
            () => this.buildRows()
          );
        }
      }
    } else {
      data.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      all.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      this.setState(
        {
          data,
          all,
        },
        () => this.buildRows()
      );
    }
  };

  handleChangeDate = (name, edit) => (event) => {
    let { data, all } = this.state;
    try {
      let value = event.format("YYYY-MM-DD");
      data.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      all.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      this.setState(
        {
          data,
          all,
        },
        () => this.buildRows()
      );
    } catch (ex) {
      data.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = "";
        }
        return e;
      });
      all.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = "";
        }
        return e;
      });
      this.setState(
        {
          data,
          all,
        },
        () => this.buildRows()
      );
    }
  };

  tableBackup = () => {
    let { additemsQty, columnsQty } = this.state;
    let table = [];
    additemsQty.map((e, index) => {
      if (e.hidden) {
      } else {
        table.push(
          <tr key={index}>
            {/* <th><Checkbox
              checked={e.checked}
              onChange={this.cheked('addBackup', e.POId)}
              value={e.checked}
              id={e.POId}
            /></th> */}
            <td>{e.LineNumber}</td>
            <td>{e.ItemNumber}</td>
            <td>{e.ItemDescription}</td>
            <td>{e.QtyOrdered}</td>
            <td>
              <Input
                id={e.POId}
                type="Input"
                name={e.POId}
                value={e.QtyConfirmed ? e.QtyConfirmed : ""}
                onChange={this.handleChangeInputQty(e.LineNumber)}
                // onChange={''}
              />
            </td>
          </tr>
        );
      }
      return e;
    });

    let subtds = [];
    columnsQty.forEach((subcolumn, __index_) => {
      subtds.push(<th className="eventsTableHeader">{subcolumn.label}</th>);
    });

    let table2 = [];
    table2.push(
      <Col style={{ fontSize: "12px" }}>
        <div
          style={{ overflowY: "auto", overflowX: "hidden" }}
          className="table-wrapper-scroll-y"
        >
          <table className="table">
            <thead>
              <tr>{subtds}</tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
        {/* </ExpansionPanelDetails>
        </ExpansionPanel> */}
      </Col>
    );
    this.setState({
      tableBackup: table2,
    });
  };

  getItemPoConsol = (name, edit) => {
    let id = name.POId;
    Axios.get(
      `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/getItemPo/${id}`
    )
      .then((success) => {
        let totalQty = 0;
        success.data.data.forEach((e) => {
          totalQty = totalQty + Number(e.QtyConfirmed);
        });
        this.setState(
          {
            additemsQty: success.data.data ? success.data.data : [],
            totalQty,
          },
          () => this.tableBackup()
        );
      })
      .catch((error) => {
        console.warn("item: ", error);
      });
  };

  handleChangeSelectConsol = (name, edit, cat) => (event) => {
    let { data, all } = this.state;

    this.setState({
      valueSelected: "",
    });
    if (event && event.value) {
      let valor = event.value;

      if (
        edit === "SupplierName" ||
        edit === "ConsigneeName" ||
        edit === "DeliveryName"
      ) {
        let label = "";
        cat.forEach((e) => {
          if (e.value === event.value) {
            label = e.label;
          }
        });
        data.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = label;
            // e['SupplierContactId'] = event.value;
            e[select2Value_[edit].Id] = event.value;

            // e['SupplierCode'] = event.value2;
            e[select2Value_[edit].Code] = event.value2;
          }
          return e;
        });
        all.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = label;
            // e['SupplierContactId'] = event.value;
            e[select2Value_[edit].Id] = event.value;

            // e['SupplierCode'] = event.value2;
            e[select2Value_[edit].Code] = event.value2;
          }
          return e;
        });
        this.setState(
          {
            valueSelected: valor,
            data,
            all,
          },
          () => this.buildRows()
        );
      } else {
        data.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = event.value;
          }
          return e;
        });
        all.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = event.value;
          }
          return e;
        });
        this.setState(
          {
            valueSelected: valor,
            data,
            all,
          },
          () => this.buildRows()
        );
      }
    } else {
      if (
        edit === "SupplierName" ||
        edit === "ConsigneeName" ||
        edit === "DeliveryName"
      ) {
        data.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = "";
            // e['SupplierContactId'] = '';
            e[select2Value_[edit].Id] = "";
            e[select2Value_[edit].Code] = "";
            // e['SupplierCode'] = '';
          }
          return e;
        });
        all.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = "";
            // e['SupplierContactId'] = '';
            // e['SupplierCode'] = '';
            e[select2Value_[edit].Id] = "";
            e[select2Value_[edit].Code] = "";
          }
          return e;
        });
        this.setState(
          {
            valueSelected: "",
            data,
            all,
          },
          () => this.buildRows()
        );
      } else {
        data.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = "";
          }
          return e;
        });
        all.map((e) => {
          if (name.POId === e.POId) {
            e[edit] = "";
          }
          return e;
        });
        this.setState(
          {
            valueSelected: "",
            data,
            all,
          },
          () => this.buildRows()
        );
      }
    }
  };

  builsub = (all) => {
    let { subTable } = this.state;
    // let subrows= all.map((w,index)=>{
    let subtds = [];
    all.forEach((subcolumn, __index_) => {
      // if (subcolumn.separator){
      //   subtds.push(
      //     <td key={__index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={ __index_} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}> {w[subcolumn.key] ?  (Number(w[subcolumn.key]).toLocaleString('En-us')) : ''}</td>
      //   );
      // }
      // else{
      subtds.push(
        <tr key={__index_} id={__index_}>
          {[subcolumn.key] ? [subcolumn.key] : ""}
        </tr>
      );
      // }
    });
    // return (<>
    //        <tr key={index}>
    //          {subtds}
    //        </tr>
    //       </>);

    // });
    return subtds;
  };

  buildRows = () => {
    let { columns, CATPOLIST } = this.state;
    let ___rows = this.state.tableData.map((e, _index) => {
      let tds = [];
      if (e) {
        columns.forEach((column, index_) => {
          if (column.launch) {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${
                  column.fixed ? "col-fixed" : ""
                }`}
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  minWidth: column.minWidth ? column.minWidth : 80,
                }}
              >
                {column.custom(
                  e,
                  this.props.options && this.props.options.trigger
                    ? this.props.options.trigger
                    : () => {}
                )}
              </td>
            );
          } else if (column.custom) {
            tds.push(
              <td
                onClick={() =>
                  this.props.modalPadre ? this.props.modalPadre(e) : ""
                }
                key={index_}
                className={`table-td ${this.props.cellType} ${
                  column.fixed ? "col-fixed" : ""
                }`}
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  width: column.Width ? column.Width : "",
                  textAlign: column.textAlign ? column.textAlign : "center",
                  minWidth: column.minWidth ? column.minWidth : 80,
                }}
              >
                {this.props.MultiUpdate && column.key == "PONumber" ? (
                  <>
                    <Row>
                      <Col style={{ marginTop: "-12px" }} md={2} xl={2}>
                        <Checkbox
                          checked={e.checked}
                          onChange={this.handleChangeMultiple(e)}
                          value={e.checked}
                          color="primary"
                        />
                      </Col>
                      <>{column.custom(e)} </>
                    </Row>
                  </>
                ) : column.anagrafico ? (
                  this.state.flat && e.edit ? (
                    <>{column.custom(e)}</>
                  ) : (
                    <></>
                  )
                ) : (
                  <>{column.custom(e)}</>
                )}
              </td>
            );
          } else if (column.index) {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${
                  column.fixed ? "col-fixed" : ""
                }`}
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  minWidth: column.minWidth ? column.minWidth : 80,
                }}
              >
                {_index + 1}
              </td>
            );
          } else if (column.popover) {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${
                  column.fixed ? "col-fixed" : ""
                }`}
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  textAlign: "center",
                  minWidth: column.minWidth ? column.minWidth : 80,
                  maxWidth: "100px",
                }}
              >
                {e[column.key] ? (
                  <Popover
                    title={column.titlePopover}
                    content={
                      <div
                        style={{
                          width: 140,
                          maxHeight: 150,
                          overflowY: "auto",
                        }}
                      >
                        {e[column.key]}
                      </div>
                    }
                  >
                    {e[column.key]
                      ? `${e[column.key].substring(0, 22)}${
                          e[column.key].length > 15 ? "..." : ""
                        }`
                      : ""}
                  </Popover>
                ) : (
                  ""
                )}{" "}
              </td>
            );
          } else if (column.multipleLine) {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${
                  column.fixed ? "col-fixed" : ""
                }`}
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  textAlign: "-webkit-center",
                  minWidth: column.minWidth ? column.minWidth : 80,
                  width: column.Width ? column.Width : "",
                  maxWidth: "100px",
                }}
              >
                {/* <tr >{e[column.key]}</tr>
                  <tr>{e[column.key]}</tr> */}
                {this.builsub(e[column.key])}
              </td>
            );
          } else {
            tds.push(
              <td
                onClick={() =>
                  this.props.modalPadre ? this.props.modalPadre(e) : ""
                }
                key={index_}
                className={`table-td ${this.props.cellType} ${
                  column.fixed ? "col-fixed" : ""
                }`}
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  textAlign: "center",
                  minWidth: column.minWidth ? column.minWidth : 80,
                  width: column.Width ? column.Width : "",
                }}
              >
                {this.state.flat && e.edit && column.edit ? (
                  column.type === "datatime" ? (
                    <div
                      className="pointer"
                      onClick={() => {
                        let nameDataTime = e;
                        let editime = column.key;
                        this.setState({
                          datatime: true,
                          nameDataTime,
                          editime,
                        });
                      }}
                    >
                      <Input type={""} value={e[column.key]} disabled={true} />
                    </div>
                  ) : column.type === "Selected" ? (
                    <div
                      className="pointer"
                      onClick={() => {
                        let nameSelected = e;
                        let editSelectd = column.key;
                        let opcionsUse = column.options;
                        this.setState({
                          selectedConsolPlan: true,
                          opcionsUse,
                          nameSelected,
                          editSelectd,
                        });
                      }}
                    >
                      <Input
                        style={{
                          background: column.color
                            ? e[column.idColor] === "0"
                              ? "#f7bfbf"
                              : "rgb(191 247 203)"
                            : "",
                        }}
                        type={""}
                        value={e[column.key]}
                        disabled={true}
                      />
                    </div>
                  ) : column.type === "modal" ? (
                    e.TotalItems === "0" ? (
                      <Input
                        id={e.POId}
                        type="Input"
                        name={e.POId}
                        value={e[column.key]}
                        onChange={this.handleChangeInput(
                          e,
                          column.key,
                          column.regExp
                        )}
                      />
                    ) : (
                      <div
                        className="pointer"
                        onClick={() => {
                          let nameDataModal = e;
                          let editModal = column.key;
                          this.setState(
                            {
                              modalQty: true,
                              nameDataModal,
                              editModal,
                            },
                            () =>
                              this.getItemPoConsol(
                                this.state.nameDataModal,
                                this.state.editModal
                              )
                          );
                        }}
                      >
                        <Input
                          type={""}
                          value={e[column.key]}
                          disabled={true}
                        />
                      </div>
                    )
                  ) : column.key === "GrossWeight" ||
                    column.key === "NoPallets" ||
                    column.key === "Spaces" ||
                    column.key === "Weigh" ||
                    column.key === "Qty" ||
                    column.key === "Pallets" ||
                    column.key === "Style" ||
                    column.key === "Shipper" ||
                    column.key === "TotalQtyOrdered" ||
                    column.key === "ActualCargoReadyDate" ||
                    column.key === "ActualCargoPickup" ||
                    column.key === "ActualArrivalConsolWH" ||
                    column.key === "SupplierName" ||
                    column.key === "PickupMode" ? (
                    <Input
                      id={e.POId}
                      type="Input"
                      name={e.POId}
                      value={e[column.key]}
                      onChange={this.handleChangeInput(
                        e,
                        column.key,
                        column.regExp
                      )}
                    />
                  ) : (
                    <>
                      {" "}
                      {e[column.key]
                        ? e[column.key] === "Null"
                          ? ""
                          : e[column.key]
                        : ""}
                    </>
                  )
                ) : (
                  <>
                    {" "}
                    {e[column.key]
                      ? e[column.key] === "Null"
                        ? ""
                        : e[column.key]
                      : e[column.key] === 0
                      ? "0"
                      : ""}
                  </>
                )}
              </td>
            );
          }
        });
      }
      return <tr key={_index}>{tds}</tr>;
    });
    this.setState({ ___rows }, () => this.buildColumns());
  };

  _sortBy = (id) => (event) => {
    let { data, sorts } = this.state;
    if (sorts[id]) {
      let { sortBy } = sorts[id];
      let formatedData = this.makeSort(data, id, sortBy);
      sorts[id].sortBy = sorts[id].sortBy === "ASC" ? "DESC" : "ASC";
      this.setState(
        {
          sorts,
          sortKey: id,
          sortBy: sorts[id].sortBy,
        },
        () => {
          let tableData = this.partirArray(
            this.state.activePage,
            formatedData,
            this.state.pages
          );
          this.setState({ tableData }, () => this.buildRows());
        }
      );
    }
  };

  makeSort = (items, key, by) => {
    switch (by) {
      case "ASC":
        return items.sort(function(a, b) {
          if (a[key] > b[key]) {
            return 1;
          }
          if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      case "DESC":
        return items.sort(function(a, b) {
          if (a[key] < b[key]) {
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          }
          return 0;
        });
      default:
        return items;
    }
  };

  buildMainColumns = () => {
    let ___mainColumns = [];
    this.state.mainColumns.forEach((e, index) => {
      ___mainColumns.push(
        <th
          key={index}
          id={index}
          style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}
          colspan={e.colspan}
        >
          {e.label}
        </th>
      );
    });
    this.setState({ ___mainColumns });
  };

  buildColumns = () => {
    let ___columns = [];
    let { sortKey, sortBy } = this.state;
    this.state.columns.forEach((e, index) => {
      let column = this.props.options.large ? (
        <Column
          title={e.label}
          isLarge={e.isLarge}
          positionTitle={e.position ? e.position : "center"}
        />
      ) : (
        e.label
      );
      if (e.key === sortKey) {
        ___columns.push(
          sortBy === "DESC" ? (
            <th
              className={e.fixed ? "col-fixed" : ""}
              key={index}
              id={e.idTh ? e.idTh : index}
              onClick={this._sortBy(e.key)}
            >
              <Fade left opposite>
                <em className="icon-arrow-down mr-2"></em>
              </Fade>
              {column}
            </th>
          ) : (
            <th
              className={e.fixed ? "col-fixed" : ""}
              key={index}
              id={e.idTh ? e.idTh : index}
              onClick={this._sortBy(e.key)}
            >
              <Fade left opposite>
                <em className="icon-arrow-up mr-2"></em>
              </Fade>
              {column}
            </th>
          )
        );
      } else {
        ___columns.push(
          <th
            className={e.fixed ? "col-fixed" : ""}
            key={index}
            id={e.idTh ? e.idTh : index}
            onClick={this._sortBy(e.key)}
          >
            {" "}
            <div
              className="thead-div"
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              {column}
            </div>
          </th>
        );
      }
    });
    this.setState({ ___columns }, () => {
      this.buildTable();
    });
  };

  buildTable = () => {
    if (this.props.bordered) {
    } else {
    }
  };

  handlePaginationChange = (e, { activePage }) => {
    let { data, pages, itemsByPage } = this.state;
    let tableData = this.partirArray(activePage, data, pages);
    this.setState({ activePage, tableData }, () => this.buildRows());
  };

  handleSearch = (name) => (event) =>
    this.setState({ [name]: event.target.value }, () => this.setSearch());
  setSearch = () => {
    let all = this.props.data;
    const { search } = this.state;
    let results = [];
    all.forEach((e) => {
      let found = 0;
      let keys = this.props.toFilter ? this.props.toFilter : Object.keys(e);
      keys.forEach((key) => {
        if (
          (e[key]
            ? (e[key] + "").toLowerCase().indexOf(search.toLowerCase())
            : -1) >= 0
        )
          found += 1;
      });
      if (found !== 0) results.push(e);
    });

    this.setState({ data: results }, () => {
      let pages =
        results.length > this.state.itemsByPage
          ? results.length / this.state.itemsByPage + 1
          : 1;
      let tableData = this.partirArray(1, results, pages);
      this.setState(
        {
          activePage: 1,
          totalItems: results.length,
          pages: parseInt(pages),
          tableData,
        },
        () => this.buildRows()
      );
    });
  };

  selectSearch = (value, name) => {
    let { filters } = this.state;
    let all = this.state.all;
    let results = all;

    filters.map((f) => {
      if (f.id === name) {
        if (value !== null) {
          f.value = value;
        } else {
          f.value = "";
        }
      }
      if (f.value) {
        results = results.filter((e) => {
          return e[f.id] === f.value.value;
        });
      }
      return f;
    });

    results.map((e) => {
      this.state.selectedPo.map((f) => {
        if (e.POId === f.key) {
          e.checked = true;
          e.edit = true;
        }
      });
      return e;
    });
    this.setState({ resultsfilter: results, data: results, filters }, () => {
      let pages =
        results.length > this.state.itemsByPage
          ? Math.ceil(parseInt(results.length) / this.state.itemsByPage)
          : 1;
      let tableData = this.partirArray(1, results, pages);
      this.setState(
        {
          activePage: 1,
          totalItems: results.length,
          pages: parseInt(pages),
          tableData,
          filters,
        },
        () => {
          this.buildRows();
          this.getNewCatalog(results);
        }
      );
    });
  };

  getNewCatalog = (results) => {
    let { first, data, all, filters } = this.state;
    let catalogs = this.returnData(results ? results : data);
    let allCatalogs = this.returnData(all);

    if (first) {
      filters[0].allOptions = allCatalogs.originCatalog;
      filters[1].allOptions = allCatalogs.supplierCatalog;
      filters[2].allOptions = allCatalogs.customerCatalog;
    }

    filters[0].options = filters[0].allOptions;
    filters[1].options = filters[1].allOptions;
    filters[2].options = filters[2].allOptions;

    if (filters[0].value || filters[1].value || filters[2].value) {
      filters[0].options = catalogs.originCatalog;
      filters[1].options = catalogs.supplierCatalog;
      filters[2].options = catalogs.customerCatalog;
      if (filters[0].value && !filters[1].value && !filters[2].value) {
        filters[0].options = allCatalogs.originCatalog;
      }
      if (filters[1].value && !filters[0].value && !filters[2].value) {
        filters[1].options = allCatalogs.supplierCatalog;
      }
      if (filters[2].value && !filters[0].value && !filters[1].value) {
        filters[2].options = allCatalogs.customerCatalog;
      }
    }
    this.setState({
      filters,
      first: false,
    });
  };

  returnData = (data) => {
    let newOriginCountry = [];
    let newSupplier = [];
    let newCustomerContainer = [];
    data.map((f) => {
      f.checked = false;
      f.hidden = false;
      f.edit = false;
      let findcantalog = false;
      let findcantalogSupplier = false;
      let findCustomerContainer = false;

      if (f.OriginCountryId == null) {
        f.OriginCountryId = 0;
        f.OriginCountry = "Null";
      }
      newOriginCountry.map((e) => {
        if (f.OriginCountryId === e.value) {
          findcantalog = true;
        }
        return e;
      });
      if (!findcantalog) {
        let obj = { value: f.OriginCountryId, label: f.OriginCountry };
        newOriginCountry.push(obj);
      }
      if (f.SupplierCode == null) {
        f.SupplierCode = 0;
        f.SupplierName = "Null";
      }
      newSupplier.map((e) => {
        if (f.SupplierCode === e.value) {
          findcantalogSupplier = true;
        }
        return e;
      });
      if (!findcantalogSupplier) {
        let obj = { value: f.SupplierCode, label: f.SupplierName };
        newSupplier.push(obj);
      }

      if (f.CustomerContainerID == null || f.CustomerContainerID == "") {
        f.CustomerContainerID = "Null";
      }
      newCustomerContainer.map((e) => {
        if (f.CustomerContainerID === e.value) {
          findCustomerContainer = true;
        }
        return e;
      });
      if (!findCustomerContainer) {
        let obj = {
          value: f.CustomerContainerID,
          label: f.CustomerContainerID,
        };
        newCustomerContainer.push(obj);
      }
      return f;
    });

    data.map((e) => {
      this.state.selectedPo.map((f) => {
        if (e.POId === f.key) {
          e.checked = true;
          e.edit = true;
        }
      });
      return e;
    });
    return {
      originCatalog: newOriginCountry,
      supplierCatalog: newSupplier,
      customerCatalog: newCustomerContainer,
    };
  };

  /*=============================================
  =            XLSX Function to download        =
  =============================================*/
  XLSX = () => {
    let { value } = this.props.options.downloads;
    this.setState(
      {
        xlsx: (
          <div className="ball-clip-rotate">
            <div></div>
          </div>
        ),
      },
      () => {
        let keys = [];
        let headers = [];
        this.state.columns.map((e) => {
          keys.push(e.key);
          headers.push(e.label);
          return e;
        });
        // fetch('https://poseidonreports.azurewebsites.net/reports/xlsx/', {
        let sendInfo = {
          data: this.state.data,
          options: {
            image: {
              name: "poseidon",
            },
            headers: headers,
            keys: keys,
          },
          info: {
            name: value ? value.Name : "",
            date: value ? value.CreateDate : "",
            results: this.state.data.length,
            downloadDate: value ? value.downloadDate : "",
            description: value ? value.Description : "",
            type: value
              ? value.ReportType === "O"
                ? "On Demand"
                : "Scheduled"
              : "",
            frecuency: value ? (value.config ? value.config.type : null) : null,
            config: {
              title: "",
              headers: {
                name: "Name",
                date: "Creation Date",
                results: "Results",
                downloadDate: "Download Date",
                description: "Description",
                type: "Type",
                frecuency: "Frecuency",
              },
            },
          },
          // columns: this.state.columns,
          // keys: keys,
          // headers: headers,
        };

        this.props.options.downloads.hasOwnProperty("disableConfig")
          ? delete sendInfo.info
          : console.log("");

        fetch(
          "https://servicereportapi.azurewebsites.net/report/dynamic/xlsx",
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(sendInfo),
          }
        )
          .then((data) => {
            return data.json();
          })
          .then((success) => {
            this.setState(
              {
                xlsx: (
                  <div>
                    <em
                      className="fa-2x fas fa-file-excel mr-2"
                      style={{ color: "#66bb6a", cursor: "pointer" }}
                      onClick={this.XLSX}
                    ></em>
                  </div>
                ),
              },
              () => {
                if (success.base64) {
                  const linkSource = `data:application/xlsx;base64,${success.base64}`;
                  const fileName =
                    (value ? value.Name : "REPORT_COST_") +
                    new Date() +
                    ".xlsx";
                  saveAs(linkSource, fileName);
                }
              }
            );
          })
          .catch((error) => {
            console.warn(error);
            this.setState(
              {
                xlsx: (
                  <div>
                    <em
                      className="fa-2x fas fa-file-excel mr-2"
                      style={{ color: "#66bb6a", cursor: "pointer" }}
                      onClick={this.XLSX}
                    ></em>
                  </div>
                ),
              },
              () => {}
            );
          });
      }
    );
  };
  /*=============================================
  =            Advance Search Component         =
  =============================================*/
  handleAdvancedSearch = (search, attention) => {
    let results = search;
    if (results === undefined) results = 0;
    if (!attention) this.setState({ text: "Quick view" });
    this.setState({ data: search }, () => {
      let pages =
        results.length > this.state.itemsByPage
          ? results.length / this.state.itemsByPage + 1
          : 1;
      let tableData;
      results === 0
        ? (tableData = [])
        : (tableData = this.partirArray(1, results, pages));
      this.setState(
        {
          activePage: 1,
          totalItems: results.length,
          pages: parseInt(pages),
          tableData,
        },
        () => this.buildRows()
      );
    });
    this.setState({ isOpen: false });
  };

  handleSearchChange = (e, i) => {
    this.setState({ search: i.value });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleSetFilterUrl = (url) => {
    this.props.setFilterUrl(url);
  };
  // ? Swith Quick View Data
  switchQuickView = (type) => {
    let {_rolId} = this.state;
    // console.log('_rolId--->', _rolId, 'Type-->', type);
    let id = auth.getAuthentication("customerSelect");
    let url = "";
    if (type === "ordersAttention") {
      if (_rolId !== "11") {
        url = `${
          Services.ORDER_LIST.path
        }/order/search/all?type=requiresAttention&id=${id}&attention=${true}`;
        this.setState({text: "Orders that require attention"});
        this.handleSetFilterUrl(url);
      } else {
        url = `${
          Services.ORDER_LIST.path
        }/order/search/all?type=requiresAttentionAgent&id=${id}&attention=${true}&user=${this.props.user}`;
        this.setState({text: "Orders that require attention"});
        this.handleSetFilterUrl(url);
      }
    } else if (type === "allOrders") {
      if (_rolId !== "11") {
        url = `${Services.ORDER_LIST.path}/order/search/all?type=idCustomer&id=${id}&read_messages=true&user=${this.props.user}`;
        this.setState({ text: "All Orders" });
        this.handleSetFilterUrl(url);
      } else {
        url = `${Services.ORDER_LIST.path}/order/search/all?type=Agent&id=${id}&read_messages=true&user=${this.props.user}`;
        this.setState({ text: "All Orders" });
        this.handleSetFilterUrl(url);
      }
    } else if (type === "newOrders") {

      if (_rolId !== "11") {
        url = `${
          Services.ORDER_LIST.path
        }/order/search/all?type=newOrders&id=${id}&new=${true}`;
        this.setState({text: "New Orders"});
        this.handleSetFilterUrl(url);
      } else {
        url = `${
          Services.ORDER_LIST.path
        }/order/search/all?type=newOrdersAgent&id=${id}&new=${true}&user=${this.props.user}`;
        this.setState({text: "New Orders"});
        this.handleSetFilterUrl(url);
      }

    } else if (type === "newCusComment") {
      if (_rolId !== "11") {
        url = `${Services.ORDER_LIST.path}/order/comments/status?type=newCusComment&id=${id}&user=${this.props.user}`;
        this.setState({ text: "New Customer Comments" });
        this.handleSetFilterUrl(url);
      } else {
        url = `${Services.ORDER_LIST.path}/order/comments/status?type=newCusCommentAgent&id=${id}&user=${this.props.user}`;
        this.setState({ text: "New Customer Comments" });
        this.handleSetFilterUrl(url);
      }

    } else if (type === "newIntComment") {
      if (_rolId !== "11") {
        url = `${Services.ORDER_LIST.path}/order/comments/status?type=newIntComment&id=${id}&user=${this.props.user}`;
        this.setState({text: "New Interglobo Comments"});
        this.handleSetFilterUrl(url);
      } else {
        url = `${Services.ORDER_LIST.path}/order/comments/status?type=newIntCommentAgent&id=${id}&user=${this.props.user}`;
        this.setState({text: "New Interglobo Comments"});
        this.handleSetFilterUrl(url);
      }
    } else if (type === "ONC") {
      url = `${Services.ORDER_LIST.path}/order/comments/status?type=ONC&id=${id}&user=${this.props.user}`;
      this.setState({ text: "Orders With New Comments" });
      this.handleSetFilterUrl(url);
    } else {
      // url = `${Services.ORDER_LIST.path}/order/search/all?type=idCustomer&id=${id}&read_messages=true&user=${this.props.user}`;
      // this.handleSetFilterUrl(url);
    }

    Axios.get(url)
      .then((success) => {
        this.handleAdvancedSearch(success.data.data, true);
      })
      .catch((error) => {
        console.warn("switchQuickView: ", error);
      });
  };

  handleDeleteSelected = (chipToDelete) => () => {
    const { data, selectedPo } = this.state;
    let newSelectedPo = [];
    let bulDeletd = false;
    selectedPo.map((f) => {
      if (chipToDelete.key === f.key) {
        bulDeletd = false;
      } else {
        bulDeletd = true;
      }
      if (bulDeletd) {
        newSelectedPo.push(f);
      }
      return f;
    });
    data.map((e) => {
      if (chipToDelete.key === e.POId) {
        e.checked = false;
        e.edit = false;
      }
      return e;
    });
    this.setState(
      {
        data,
        selectedPo: newSelectedPo,
      },
      () => this.buildRows()
    );
  };

  Qtysave = () => {
    let {
      nameDataModal,
      editModal,
      totalQty,
      data,
      all,
      additemsQty,
    } = this.state;
    let valor = totalQty;

    data.map((e) => {
      if (nameDataModal.POId === e.POId) {
        e[editModal] = valor;
      }
      return e;
    });
    all.map((e) => {
      if (nameDataModal.POId === e.POId) {
        e[editModal] = valor;
      }
      return e;
    });

    let body = {
      id: nameDataModal.POId,
      total: valor,
      items: additemsQty,
    };

    Axios.put(
      `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/putItemPo/`,
      body
    )
      .then((success) => {
        this.setState(
          {
            data,
            all,
            modalQty: false,
          },
          () => this.buildRows()
        );
      })
      .catch((error) => {
        console.warn("item: ", error);
      });
  };

  checkAll = () => {
    const { data, selectedPo } = this.state;
    let array = [];
    selectedPo.map((f) => {
      data.map((e) => {
        if (f.key === e.POId) {
          array.push(e);
        }
        return e;
      });
      return f;
    });

    if (this.props.ConsolPlan) {
      this.setState(
        {
          // loading: true,
          selectedPo: [],
        },
        () => {
          if (this.props.ConsolPlan) {
            if (document.getElementById("myBtn")) {
              document.getElementById("myBtn").disabled = false;
              document.getElementById("messagePoEdit").hidden = true;
            }
            if (document.getElementById("addPos")) {
              document.getElementById("addPos").hidden = false;
            }
            // data.map((e) => {
            //   e.checked = false;
            //   return e;
            // });

            // this.setState({
            //   data
            // });
          }
        }
      );
    } else {
      this.setState({
        loading: true,
        // selectedPo:[]
      });
    }

    this.props.checkAll(array);
  };

  multiSelect = () => {
    this.setState({
      active: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      active: false,
      order: {},
      textMultiple: "",
    });
  };

  handleOnCloseQty = () => {
    this.setState({
      modalQty: false,
      // order: {},
      // textMultiple: ''
    });
  };

  handleOnUpdate = () => {
    let { order, data, selectedPo } = this.state;
    let array = [];
    selectedPo.map((f) => {
      data.map((e) => {
        if (f.key == e.POId) {
          array.push(e);
        }
        return e;
      });
      return f;
    });

    array.map((f) => {
      f[order.multi] = order.value;
      return f;
    });
    this.props.checkAll(array);
    this.setState({
      // selectedPo: [],
      order: {},
      active: false,
      textMultiple: "",
    });
  };

  handleClose = () => {
    this.setState({
      datatime: false,
    });
  };

  handleCloseSelected = () => {
    this.setState({
      selectedConsolPlan: false,
    });
  };

  toggleAdvants = (evt) => {
    this.setState((prevState) => ({
      isOpenAdvans: !prevState.isOpenAdvans,
    }));
  };

  getFilterData = (e, index, secondIndex, firstIndex) => {
    // console.log("e, index, secondIndex, firstIndex",e, index, secondIndex, firstIndex);
    // console.log("this.state.currentValues",this.state.currentValues);
    // console.log("this.state.advancedSearchData",this.state.advancedSearchData);
    switch (e.type) {
      case "text":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? "!" : ""}
              </label>
              <InputGroup>
                <Input
                  type="text"
                  value={e.value}
                  name={e.title}
                  onChange={(e) => {
                    let newAdvanced = this.state.advancedSearchData;
                    if (newAdvanced[firstIndex]) {
                      if (newAdvanced[firstIndex].data) {
                        if (newAdvanced[firstIndex].data[secondIndex]) {
                          if (
                            newAdvanced[firstIndex].data[secondIndex][index]
                          ) {
                            newAdvanced[firstIndex].data[secondIndex][
                              index
                            ].value = e.target.value;
                            this.setState({ advancedSearchData: newAdvanced });
                          }
                        }
                      }
                    }
                  }}
                />
              </InputGroup>
          </div>
        );
      case "select":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? "!" : ""}
              <Select
                placeholder=""
                value={e.value}
                options={e.values}
                onChange={(value) => {
                  let newAdvanced = this.state.advancedSearchData;
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].value = value;
                          this.setState({ advancedSearchData: newAdvanced });
                        }
                      }
                    }
                  }
                }}
              />
            </label>
          </div>
        );
      case "multipleSelect":
        let ejemplos = [
          { key: 9, text: "En Inventari", value: 9 },
          { key: 10, text: "Despachado", value: 10 },
          { key: 11, text: "Levantado", value: 11 },
          { key: 12, text: "Instruccionado", value: 12 },
        ];
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5}}
          >
            <label>
              {e.title} {e.undefined ? "!" : ""}
            </label>
            <Dropdown
              className="NewClassAd"
              // onChange={this.handleOnChangeData}
              onChange={(evt, { value }) => {
                let newAdvanced = this.state.advancedSearchData;
                if (newAdvanced[firstIndex]) {
                  if (newAdvanced[firstIndex].data) {
                    if (newAdvanced[firstIndex].data[secondIndex]) {
                      if (newAdvanced[firstIndex].data[secondIndex][index]) {
                        newAdvanced[firstIndex].data[secondIndex][
                          index
                        ].value = value;
                        this.setState({
                          advancedSearchData: newAdvanced,
                          [e.id]: value,
                        });
                      }
                    }
                  }
                }
              }}
              id={e.id}
              options={e.values}
              clearable
              search
              selection
              fluid
              value={this.state[e.id]}
              multiple
              allowAdditions
            />
          </div>
        );
      case "date":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? "!" : ""}
              </label>
              <InputGroup>
                <Input
                  type="date"
                  name={e.title}
                  onChange={(e) => {
                    let newAdvanced = this.state.advancedSearchData;
                    if (newAdvanced[firstIndex]) {
                      if (newAdvanced[firstIndex].data) {
                        if (newAdvanced[firstIndex].data[secondIndex]) {
                          if (
                            newAdvanced[firstIndex].data[secondIndex][index]
                          ) {
                            newAdvanced[firstIndex].data[secondIndex][
                              index
                            ].value = e.target.value;
                            this.setState({ advancedSearchData: newAdvanced });
                          }
                        }
                      }
                    }
                  }}
                />
              </InputGroup>
           
          </div>
        );
      case "dateRange":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            {/* <center> */}
            <label>
              {e.title} {e.undefined ? "!" : ""}
            </label>
            {/* </center> */}
            {/* <div className="row"> */}

            {/* <div className="col-6"> */}
            <RangePicker
              onChange={(event) => {
                let newAdvanced = this.state.advancedSearchData;

                if (event === null) {
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.init = "";
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.end = "";
                          this.setState({
                            advancedSearchData: newAdvanced,
                            [e.id]: event,
                          });
                        }
                      }
                    }
                  }
                } else {
                  let init = event[0].format("YYYY-MM-DD");
                  let end = event[1].format("YYYY-MM-DD");
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.init = init;
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.end = end;
                          this.setState({
                            advancedSearchData: newAdvanced,
                            [e.id]: event,
                          });
                        }
                      }
                    }
                  }
                }
              }}
              value={
                this.state[e.id] === undefined || this.state[e.id] === null
                  ? ""
                  : [
                      moment(new Date(this.state[e.id][0])),
                      moment(new Date(this.state[e.id][1])),
                    ]
              }
              style={{
                height: "2.6em",
                borderRadius: ".3em",
                borderColor: "#CCCCCC",
              }}
              size="large"
              className="col-md-12"
            />
          </div>
        );
      default:
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? "!" : ""}
              <InputGroup>
                <Input type="text" name={e.title} />
              </InputGroup>
            </label>
          </div>
        );
    }
  };

  findAdvanced = () => {
    console.log("findAdvanced");
    let obj = [];
    this.state.advancedSearchData.map((e) => {
      e.data.map((f) => {
        f.map((g) => {
          if (!g.undefined) {
            let newPush = {
              id: g.id,
              UseIN: g.UseIN ? g.UseIN : false,
              isDate: g.type === 'dateRange' ? true : false,
              subconsulta: g.subconsulta ? g.subconsulta : false,
              value:
                g.type === 'dateRange'
                  ? g.date
                  : g.type === 'select'
                  ? g.value
                    ? g.value.value
                    : ''
                  : g.value,
            };
            obj.push(newPush);
          }
          return g;
        });
        return f;
      });
      return e;
    });
    this.props.advancedSearch(obj);
    this.toggleAdvants();
  };

  getAdvancedData = (e, index, firstIndex) => {
    return (
      <Row key={index}>
        {e.map((f, index1) => {
          return this.getFilterData(f, index1, index, firstIndex);
        })}
      </Row>
    );
  };

  /*=============================================
  =            Render Method For Table          =
  =============================================*/
  render() {
    const {
      tableData,
      activePage,
      itemsByPage,
      totalQty,
      tableBackup,
      valueSelected,
      CATPOLIST,
      opcionsUse,
      search,
      text,
      data,
      selectedPo,
      textMultiple,
      filters,
      loading,
      _rolId,
    } = this.state;

    const options = this.props.options ? this.props.options : {};
    const MultiUpdate = this.props.MultiUpdate ? this.props.MultiUpdate : false;
    const ConsolPlan = this.props.ConsolPlan ? this.props.ConsolPlan : false;
    let style = { height: "37em" };
    return (
      <div className="col-12">
        <Grid columns="equal">
          <Grid.Row>
            {MultiUpdate ? (
              <>
                {ConsolPlan ? (
                  <></>
                ) : (
                  <UsersSelection
                    onDelete={this.handleDeleteSelected}
                    users={selectedPo}
                  />
                )}

                {ConsolPlan ? (
                  <>
                    <div style={{ height: 30 }} className="col-md-12"></div>
                    <div className="col-md-12">
                      <Grid>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <div
                              hidden={
                                this.state.selectedPo.length > 0 ? false : true
                              }
                              className="row flex-container"
                            >
                              {loading ? (
                                <Icon
                                  loading={loading}
                                  name="circle notch"
                                  size="large"
                                />
                              ) : (
                                <em
                                  onClick={this.checkAll}
                                  className="fa-2x mr-2 fas fa-save colorText"
                                ></em>
                              )}
                              Save Changes
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ height: 30 }} className="col-md-12"></div>
                    <div className="col-md-12">
                      <Grid>
                        <Grid.Row columns={6}>
                          <Grid.Column>
                            <Grid.Column
                              style={{ marginLeft: "15px" }}
                              width={6}
                            >
                              <Search
                                input={{
                                  icon: "search",
                                  iconPosition: "left",
                                  fluid: true,
                                }}
                                placeholder="..."
                                value={search}
                                open={false}
                                name="search"
                                id="search"
                                onSearchChange={this.handleSearch("search")}
                              />
                            </Grid.Column>
                          </Grid.Column>
                          {filters.map((e, index) => (
                            <Grid.Column>
                              <Grid.Column
                                style={{ marginLeft: "5px" }}
                                width={3}
                              >
                                <Select
                                  placeholder={e.placeholder}
                                  value={e.value}
                                  name={e.id}
                                  options={e.options}
                                  onChange={(value) => {
                                    this.selectSearch(value, e.id);
                                  }}
                                />
                              </Grid.Column>
                            </Grid.Column>
                          ))}
                          <Grid.Column>
                            <div
                              hidden={
                                this.state.selectedPo.length > 0 ? false : true
                              }
                              className="row flex-container"
                            >
                              {loading ? (
                                <Icon
                                  loading={loading}
                                  name="circle notch"
                                  size="large"
                                />
                              ) : (
                                <em
                                  onClick={this.checkAll}
                                  className="fa-2x mr-2 fas fa-save colorText"
                                ></em>
                              )}
                              Save Changes
                            </div>
                          </Grid.Column>
                          <Grid.Column>
                            <div
                              hidden={
                                this.state.selectedPo.length > 1 ? false : true
                              }
                              className="row flex-container"
                            >
                              <em
                                onClick={this.multiSelect}
                                className="fa-2x mr-2  fab fa-gg-circle colorText"
                              ></em>
                              Multiple Update
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Grid.Column width={4}>
                {options.search ? (
                  <Search
                    input={{
                      icon: "search",
                      iconPosition: "left",
                      fluid: true,
                    }}
                    placeholder="..."
                    value={search}
                    open={false}
                    name="search"
                    id="search"
                    onSearchChange={this.handleSearch("search")}
                  />
                ) : (
                  ""
                )}
                <Modall
                  customer={this.props.customer}
                  show={this.state.isOpen}
                  onClose={this.toggleModal}
                  triggerParentupdate={this.handleAdvancedSearch}
                  name={this.props.name}
                  modalName={this.props.modalName}
                  centered={true}
                  _rolId={this.state._rolId}
                  _user={this.props.user}
                />
              </Grid.Column>
            )}
            <div
              style={{ marginLeft: "2em" }}
              className="drop_content row"
              hidden={this.props.options.anagraficoSwich ? false : true}
            >
              <Switch
                style={{ marginTop: "9px" }}
                defaultChecked={false}
                onChange={this.onChangeSwich}
              />
              <h5
                style={{
                  marginTop: "13px",
                  fontWeight: "bold",
                  marginLeft: "1em",
                }}
              >
                Show Unassigned
              </h5>{" "}
              <em
                style={{ color: "red", marginTop: "13px", marginLeft: "1em" }}
                className="fa-1x mr-1 fas fa-circle"
              ></em>
            </div>
            <Grid.Column
              width={4}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
            >
              {options.modal ? (
                <img
                  width={22}
                  height={22}
                  src={IMG}
                  alt="options"
                  onClick={this.toggleModal}
                  style={{ cursor: "pointer" }}
                ></img>
              ) : (
                ""
              )}
              {options.advandsearch ? (
                <img
                  width={22}
                  height={22}
                  src={IMG}
                  alt="options"
                  onClick={() => this.setState({ isOpenAdvans: true })}
                  style={{ cursor: "pointer" }}
                ></img>
              ) : (
                ""
              )}
            </Grid.Column>
            <Grid.Column width={7}>
              <div hidden={this.props.options.orderAttention ? false : true}>
                <div className="excel_icon">
                  <FaCardExcel data={data} />
                </div>
                <div className="drop_content">
                  <QuickView
                    attentionOrders={this.switchQuickView}
                    text={text}
                  />
                </div>
              </div>
              <div hidden={this.props.options.addConsolPlan ? false : true}>
                <div
                  hidden={
                    _rolId === "1" || _rolId === "3" || _rolId === "6"
                      ? false
                      : true
                  }
                  className="drop_content row"
                  style={{ marginRight: "5em" }}
                >
                  <Fab
                    color="primary"
                    size="small"
                    aria-label="add"
                    onClick={() =>
                      this.props.history.push(
                        this.props.options.colsolPlan.redirect
                      )
                    }
                  >
                    <AddIcon />
                  </Fab>
                  <h5
                    style={{
                      marginTop: "13px",
                      fontWeight: "bold",
                      marginLeft: "1em",
                    }}
                  >
                    New Consol
                  </h5>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={1}>{this.state.xlsx}</Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="col-12" style={{ paddingTop: "1%" }}></div>
        <div className={`${this.props.fixed ? "zui-scroller" : ""}`}>
          <Table
            id="_table_overflow"
            responsive
            className={`zui-table ${
              this.props.type === "GENERIC"
                ? "table-height table table-sticky table-bordered"
                : ""
            }`}
          >
            <thead style={{ textAlign: "center" }}>
              <tr>{this.state.___mainColumns}</tr>
              <tr style={{ cursor: "pointer" }}>{this.state.___columns}</tr>
            </thead>
            <tbody className="table-body">{this.state.___rows}</tbody>

            {options.total ? (
              <tbody className="table-body">
                {this.state.columns.map((e, index_) => {
                  // console.log("-->>e column", e);
                  let tds = [];

                  tds.push(
                    <th
                      key={index_}
                      className={`table-td`}
                      id={index_}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textAlign: "center",
                        minWidth: 80,
                      }}
                    >
                      <>
                        {" "}
                        {e.title
                          ? e.title
                          : e.sum
                          ? this.props.totalArray[0][e.key2]
                            ? this.props.totalArray[0][e.key2]
                            : 0
                          : ""}
                      </>
                    </th>
                  );

                  return tds;
                })}
              </tbody>
            ) : (
              ""
            )}
          </Table>
        </div>
        {options && options.pagination ? (
          <div className="col-12">
            <hr />
          </div>
        ) : (
          ""
        )}
        <div style={{textAlign : 'center'}} className={this.props.options.contData ? "row" : "col-12"}>
          <div className={this.props.options.contData ? "col-9" : "col-12"}>
            {options && options.pagination ? (
              <Pagination
                ellipsisItem={null}
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.pages}
              ></Pagination>
            ) : (
              ""
            )}
          </div>
          {this.props.options.contData ? (
            <div className="col-2" style={{ textAlign: "right" }}>
              <h5
                style={{ fontWeigt: "bold", marginTop: "1em", color: "#000" }}
              >
                showing{" "}
                {activePage * itemsByPage -
                  (tableData.length < itemsByPage ? itemsByPage : 0) +
                  (tableData.length < itemsByPage ? tableData.length : 0)}{" "}
                of {this.state.totalItems} results.
              </h5>
            </div>
          ) : (
            ""
          )}
        </div>

        {options && options.pagination ? (
          <div className="col-12">
            <hr />
          </div>
        ) : (
          ""
        )}
        <Modal
          open={this.state.active}
          size={"large"}
          dimmer={"inverted"}
          centered={false}
          scrolling
          style={style}
        >
          <Modal.Header className="headerModal headerModalMulti">
            {"Multiple Update"}
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px" }}>
                Purchase Orders Selected
              </strong>
            </div>
            <div className="col-md-12 flex-container2 ">
              <UsersSelection users={selectedPo} />
            </div>
            <div className="col-md-12" style={{ height: 10 }}></div>
            <div className="col-md-10" style={{ marginBottom: "12em" }}>
              <div className="row">{this.state.CreateFormData}</div>
            </div>
          </Modal.Content>
          <Modal.Actions className="modal-actions">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: "center" }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  style={{ fontSize: "16px" }}
                  className="button"
                  onClick={this.handleOnClose}
                >
                  Cancel
                </Button>
              </div>
              <div
                hidden={
                  this.state.order.multi && this.state.order.value
                    ? false
                    : true
                }
                className="col-md-2 col-lg-1 col-sm-4 buttonExter"
              >
                <DialogComponent
                  multi={true}
                  disabled={false}
                  color={"primary"}
                  actionText={"Update"}
                  title="Multiple Update"
                  message={textMultiple}
                  cancelText="Cancel"
                  agreeText="Yes, I´m sure"
                  agree={() => this.handleOnUpdate()}
                />
              </div>
            </div>
          </Modal.Actions>
        </Modal>
        <Dialog
          fullWidth={false}
          maxWidth={"xs"}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.datatime}
        >
          <div style={{ height: 20, width: "21em" }}></div>
          <div className="col-md-12" key={1}>
            <Datetime
              placeholder="Seleted Date"
              open={true}
              timeFormat={false}
              type={"dataTime"}
              dateFormat={"YYYY-MM-DD"}
              inputProps={{ className: "form-control", id: 1 }}
              value=""
              onChange={this.handleChangeDate(
                this.state.nameDataTime,
                this.state.editime
              )}
            />
          </div>
          <div style={{ height: 250 }}></div>
        </Dialog>

        {/* <Dialog
          fullWidth={false}
          maxWidth={'xs'}
          onClose={this.handleCloseSelected}
          aria-labelledby="simple-dialog-title"
          open={this.state.selectedConsolPlan}>
          <div style={{ height: 10, width: '21em' }}></div>
          <div className="col-md-12" key={1}>
            <Select
              id={'pordefinir2'}
              required
              type={'Selected'}
              name={'por definir'}
              value={valueSelected}
              onChange={this.handleChangeSelectConsol(this.state.nameSelected, this.state.editSelectd, opcionsUse ? CATPOLIST[opcionsUse] : [])}
              options={opcionsUse ? CATPOLIST[opcionsUse] : []}
            />
          </div>
          <div style={{ height: 250 }}></div>
        </Dialog> */}

        <Modalant
          title="Select an Option"
          centered
          visible={this.state.selectedConsolPlan}
          // onOk={() => setVisible(false)}
          // onCancel={() => this.setState({ anagraficoList: false })}
          width={500}
          closable={false}
          footer={null}
        >
          <div className="col-md-12" key={1}>
            <Select
              id={"pordefinir2"}
              required
              type={"Selected"}
              name={"por definir"}
              value={valueSelected ? valueSelected : ""}
              onChange={this.handleChangeSelectConsol(
                this.state.nameSelected,
                this.state.editSelectd,
                opcionsUse ? CATPOLIST[opcionsUse] : []
              )}
              options={opcionsUse ? CATPOLIST[opcionsUse] : []}
            />
          </div>
          <div style={{ height: "1em" }}></div>
          <div className="row">
            <div className="col-md-3 col-lg-7 col-sm-12"></div>
            <div
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-2 col-sm-4"
            >
              {/* <Button variant="contained" color="danger" style={{ fontSize: '14px' }} className="button" onClick={} >
                Cancel
              </Button> */}
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-1 col-sm-4"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "14px" }}
                className="button"
                onClick={() =>
                  this.setState({
                    selectedConsolPlan: false,
                    valueSelected: "",
                  })
                }
              >
                Select
              </Button>
            </div>
          </div>
        </Modalant>

        {/* <Modal
          open={this.state.modalQty}
          size={'Small'}
          // dimmer={'inverted'}
          centered={false}
          scrolling
          style={style}
        >
          <Modal.Content scrolling>
            <div className="col-md-12 flex-container2 " >
              <div className="col-md-12 ">
                <div style={{ marginBottom: '1em' }} className="col-md-12 row">
                  <div className="col-md-9">
                    <strong style={{ marginLeft: '-12px' }}>Item update</strong>
                  </div>
                  <div className="col-md-3">
                    <strong style={{ marginLeft: '-12px' }}>Total: {totalQty}</strong>
                  </div>

                </div>
                <div style={{ height: '21em' }}>
                  <br></br>
                  {tableBackup}
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions className='modal-actions'>
            <div className="row">
              <div className="col-md-6 col-lg-2 col-sm-12"></div>
              <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-4 col-sm-4">
                <Button variant="contained" color="danger" style={{ fontSize: '16px', width: '8em' }} className="button" onClick={this.handleOnCloseQty} >
                  Cancel
                </Button>
              </div>
              <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-1 col-sm-4">
                <Button variant="contained" color="primary" style={{ fontSize: '16px', width: '8em' }} className="button" onClick={this.Qtysave} >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal> */}
        <Modalant
          title=""
          centered
          visible={this.state.modalQty}
          // onOk={() => setVisible(false)}
          // onCancel={() => this.setState({ anagraficoList: false })}
          width={650}
          closable={false}
          footer={null}
        >
          <div className="">
            <div className="col-md-12 ">
              <div style={{ marginBottom: "1em" }} className="col-md-12 row">
                <div className="col-md-9">
                  <strong style={{ marginLeft: "-12px" }}>Item update</strong>
                </div>
                <div className="col-md-3">
                  <strong style={{ marginLeft: "-12px" }}>
                    Total: {totalQty}
                  </strong>
                </div>
              </div>
              <div style={{ height: "21em" }}>
                <br></br>
                {tableBackup}
              </div>
            </div>
          </div>
          <div style={{ height: "1em" }}></div>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12"></div>
            <div
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-3 col-sm-4"
            >
              <Button
                variant="contained"
                color="danger"
                style={{ fontSize: "16px", width: "8em" }}
                className="button"
                onClick={this.handleOnCloseQty}
              >
                Cancel
              </Button>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-3 col-sm-4"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "16px", width: "8em" }}
                className="button"
                onClick={this.Qtysave}
              >
                Update
              </Button>
            </div>
          </div>
        </Modalant>

        <ModalReactstrap
          backdrop="static"
          isOpen={this.state.isOpenAdvans}
          toggle={this.toggleAdvants}
          size="lg"
        >
          <ModalHeaderReactstrap
            className="advancedSearchModal"
            style={{
              background: "linear-gradient(to right, #007ab9 0%, #00558c 100%)",
              color: "white",
            }}
            toggle={this.toggle}
          >
            <b>Advanced Search</b>
          </ModalHeaderReactstrap>
          <ModalBodyReactstrap>
            {this.state.advancedSearchData.map((f, index1) => {
              return (
                <Container
                  key={index1}
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Row>
                    <Col style={{ fontWeight: "bold" }}>
                      <center>{f.title ? f.title : `${"\u00A0"}`}</center>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {f.data.map((h, index2) => {
                        return this.getAdvancedData(h, index2, index1);
                      })}
                    </Col>
                  </Row>
                  {index1 !== this.state.advancedSearchData.length - 1 ? (
                    <div
                      style={{
                        background: "#f0f0f0",
                        height: 1,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Container>
              );
            })}
          </ModalBodyReactstrap>
          <ModalFooterReactstrap className="modalFooterCenter">
            <center>
              <Button
                color="primary"
                onClick={() => {
                  this.findAdvanced();
                }}
              >
                Search
              </Button>{" "}
              {
                // <Button color="secondary" onClick={() =>{this.resetData();}}>Reset</Button>{' '}
              }
              <Button outline color="danger" onClick={this.toggleAdvants}>
                Cancel
              </Button>
            </center>
          </ModalFooterReactstrap>
        </ModalReactstrap>
      </div>
    );
  }
}
TableComponent.propTypes = {
  cellType: propTypes.oneOf(["tick-rows", "thin-rows"]),
  fixed: propTypes.bool,
};
TableComponent.defaultProps = {
  cellType: "thick-rows",
  fixed: false,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setFilterUrl: (url) => dispatch(SetFilterUrl(url)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableComponent)
);
