import React, { Component } from 'react';
import { Card, CardBody, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import {Table, Pagination, Grid, Icon, Popup } from 'semantic-ui-react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {Services} from '../../Services';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalPages: 1,
      loaderExcel: false,
      loaderTab: true,
      columns: [],
      customer: 0
    };
  }

  componentDidMount = () => {
    this.getSummary();
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      customer: nextProps.customer
    }, () => {this.getSummary();});
  }

  initPages = () => {

    // let dataPage = this.state.dataLength
    let pags = Math.ceil(parseInt(this.state.dataLength) / 10);
    this.setState(
      {
        totalPages: pags,
        activePage: 1
      },
      () => {
        this.buildPageResults();
      }
    );
  };

  buildPageResults = () => {
    
    let data = this.setResultsByPage(this.state.activePage, this.state.allData);

    this.setState({
      columns: data
    });
  };

  setResultsByPage = (page, all) => {
    page = page - 1;
    const pag = all.slice(page * 10, (page + 1) * 10);
    return pag;
  };

  getSummary = () => {
    //let id = auth.getAuthentication('customerSelect');
    let id = this.state.customer;
    axios
      .get(`${Services.STATISTICS.path}/statistics/summary?id=${id}`)
      .then(success => {
        this.setState({ columns: success.data.data, dataLength: success.data.data.length, allData: success.data.data, totalData: success.data.data, loaderTab: false }, ()=>{
          this.initPages();
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  changePage = page => {
    this.setState(
      {
        activePage: page
      },
      () => {this.buildPageResults();}
    );
  };

  handleChange = name => event => {
    let { allData } = this.state;
    this.setState({
      [name]: event.target.value
    }, () => {
      if (this.state.search === '') {
        this.setState({
          dataLength: this.state.totalData.length,
          allData: this.state.totalData
        }, () => this.initPages());
      }
      else {
        let results = this.searchBy(this.state.search, allData);
        this.setState({
          dataLength: results.length,
          allData: results
        }, () => this.initPages());
      }
    });
  }

  searchBy = (words,array)=>{
    let results = [];
    let all = array;
    all.forEach((e) => {
      let found = 0;
      let keys = Object.keys(e);
      keys.forEach((key) => {
        if ((e[key] ? (e[key] + '').toLowerCase().indexOf(words.toLowerCase()) : -1) >= 0) found += 1;
      });
      if (found !== 0) results.push(e);
    });
    return results;
  }
  
  generateExcel = () => {
    this.setState({ loaderExcel: true });
    let { totalData } = this.state;
    let headers = ['Name', 'Last Name','Customer',  'Rol', 'Start', 'End', 'Duration', 'Origin', 'Purchase Order', 'Bookings', 'Tracking', 'My Reports', 'Cost Management'];
    let keys = ['Name', 'LastName', 'Customer', 'Rol', 'Start', 'End', 'totalTime', 'Origin', 'PurchaseOrder', 'Booking', 'Tracking', 'Reports', 'CostManagement'];

    let obj = {
      data: totalData,
      options:{
        image: {
          name: 'poseidon'
        },
        headers: headers,
        keys: keys
      },
    };

    if (headers.length > 0) {
      axios.post(`${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`, obj).then(success => {
        if (success.data.base64) {
          const fileName = (Math.floor(Math.random() * 900000) + 100000) + Math.floor(Date.now() / 1000) + '.xlsx';
          const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
          saveAs(linkSource, fileName);
          this.setState({ loaderExcel: false });
        } else console.warn('Error downloading file', success);
      }).catch(error => {
        console.error('errorr', error);
        this.setState({ loaderExcel: false });
      });
    }

  }

  render() {
    let { columns, activePage, totalPages,loaderTab } = this.state;

    const checkbox = (element, from) => (
      element === 1 ? (<input checked  disabled type="checkbox"></input>) : (<input disabled type="checkbox" ></input>)
    );

    const tablebody = columns.map((e, index) => (
      <Table.Row key={index.toString()}>
        <Table.Cell textAlign="center">{e.Name}</Table.Cell>
        <Table.Cell textAlign="center">{e.LastName}</Table.Cell>
        <Table.Cell textAlign="center">{e.Customer}</Table.Cell>
        <Table.Cell textAlign="center">{e.Rol}</Table.Cell>
        <Table.Cell textAlign="center">{e.Start} hrs</Table.Cell>
        <Table.Cell textAlign="center">{e.End ? e.End + ' hrs' : ''}{' '}</Table.Cell>
        <Table.Cell textAlign="center">{e.End ? e.horas ? e.horas + ' Hrs ' + e.duration + ' Min' : e.duration + ' Min' : ''} {e.Logout === 1 ? <Popup content='Sesión caducada' trigger={<Icon name='circle' color='red' size='tiny' position='top center'/>} /> : ''}</Table.Cell>
        <Table.Cell textAlign="center">{ e.Origin }</Table.Cell>
        
        <Table.Cell textAlign="center">{checkbox(e.PurchaseOrder, 'PurchaseOrder')}</Table.Cell>
        <Table.Cell textAlign="center">{checkbox(e.Booking, 'Booking')}</Table.Cell>
        <Table.Cell textAlign="center">{checkbox(e.Tracking, 'Tracking')}</Table.Cell>
        <Table.Cell textAlign="center">{checkbox(e.Reports, 'Reports')}</Table.Cell>
        <Table.Cell textAlign="center">{checkbox(e.CostManagement, 'CostManagement')}</Table.Cell>
      </Table.Row>
    ));

    return (
      <React.Fragment>
        <Card className="myCard">
          <CardBody>
            <div className="col-12" style={{margin: '0', padding: '0'}}>
              <Grid style={{ paddingBottom: '1%' }}>
                <Grid.Row>
                  <Grid.Column style={{ marginLeft: '15px' }} width={4}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                      <Input onChange={this.handleChange('search')} />
                    </InputGroup>
                  </Grid.Column>
                  <Grid.Column style={{ marginLeft: '15px' }} width={10}>
                    
                  </Grid.Column>

                  <Grid.Column style={{display: 'flex',justifyContent:'center', alignItems: 'center', margin: '0', padding: '0' }} width={1}>
                    {
                      this.state.loaderExcel 
                        ? 
                        <Icon.Group size='large'>
                          <Icon loading={this.state.loaderExcel} name='circle notch' size="large" />
                          <Icon color='green' name='file excel' />
                        </Icon.Group>
                        : <Icon onClick={() => { this.generateExcel(); }} color='green' className="__downloader" name='file excel' size="big" />
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            {
              loaderTab ?
                <></>
                :
                <Table  basic='very' celled>
                  <Table.Header >
                    <Table.Row style={{fontSize: '12px', color: '#8c8c8c'}}>
                      <Table.HeaderCell colSpan="4">
                        <center>User</center>
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="4">
                        <center>Session</center>
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="4">
                        <center>Activity</center>
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{fontSize: '12px'}}>
                      <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Last Name</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Customer</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Rol</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Start</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">End</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                      Duration
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Origin</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                      Purchase Order
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                      Bookings
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Tracking</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                      My reports
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                      Cost Management
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body style={{fontSize: '12px', color: '#656565'}}>
                    {tablebody}
                  </Table.Body>
                </Table>
            }

            <br/>

            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePage}
                  ellipsisItem={null}
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage)
                  }
                />
              </center>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Summary;
