import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CardBody, FormGroup, Input } from 'reactstrap';
import { Col } from 'react-flexbox-grid';
// import Oauth from './../../../services/oauth/oauthService';
import Login from './Login';
import Errors from '../../../components/template/Errors';
import moment from 'moment';
import axios from 'axios';
import {Services} from './Services';


// const oauth = new Oauth();

const styleButton = {
  // color: 'white',
  color: '#808181',
  fontSize: '14px',
  textAlign: 'center',
  background: 'transparent',
  borderColor: 'transparent'
};

const inputAlert = {
  padding: '3px',
  marginTop: '-14px',
  color: '#808181 !important',
  fontSize: '14px',
  textAlign:'initial'
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response:'',
      recoverPassword:'',
      flatRecover: false,
      error: {
        open: false,
        message: '',
        type: ''
      },
      login: {
        email: '',
        valid: false
      },
      nexStep: false
    };
  }
  /**
   * Verify authentication
   */
  handleLogin = () => {
    if (this.state.login.valid) {
      this.setState({ nexStep: true });
    } else {
      document.getElementById('input_email').className = 'invalidInput';
      document.getElementById('input_email').focus();
      document.getElementById('email_alert').removeAttribute('hidden');
    }
  }

  onError = error => {
    this.setState({
      error: error.error
    });
  };


  changeView = name => event => {
    this.setState({ nexStep: false });
  }

  validateForm = name => event => {
    let value = event.target.value;
    let { login } = this.state;
    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);

    if (emailRegex.test(value)) {
      login.valid = true;
      document.getElementById('input_email').className = 'validInput';
      document.getElementById('email_alert').setAttribute('hidden', 'hidden');
    } else {
      login.valid = false;
      document.getElementById('input_email').className = 'invalidInput';
      document.getElementById('email_alert').removeAttribute('hidden');
    }

    login.email = value;
    this.setState({ login });

  }

  FirstLoad = () => {
    return (
      <div className="col-md-12 _containerLogin">
        <input type="text" id="input_email" className="_input" placeholder="Email Address" value={this.state.login.email} onChange={this.validateForm()}></input>
        <div className="_alert" id="email_alert" hidden>Please enter a valid email address</div>
        <button className="_loginButton" onClick={this.handleLogin}>Next</button>
      </div>
    );
  }

  componentDidMount = () => {
    try {
      let className = document.getElementById('body').className;
      className += ' _root_login';
      document.getElementById('body').className = className;
      if (document.getElementById('input_email').value === 'info@magic-log.com') {
        let { login } = this.state;
        login.valid = true;
        this.setState({ login });
      }
    } catch (error) {
      console.error('Error Login');
    }
  }

  changeCorreo = (event) => {
    document.getElementById('correo_alert').removeAttribute('hidden');
    document.getElementById('correo_alert_base').setAttribute('hidden', 'hidden');
    document.getElementById('correo_sin').setAttribute('hidden', 'hidden');

    let value = event.target.value;
    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    if (emailRegex.test(value)) {
      document.getElementById('correo_alert').setAttribute('hidden', 'hidden');
      document.getElementById('correo_aler_button').removeAttribute('hidden');
    } else {
      document.getElementById('correo_alert').removeAttribute('hidden');
      document
        .getElementById('correo_aler_button')
        .setAttribute('hidden', 'hidden');
    }
    this.setState({
      recoverPassword:value
    });
  }

  funtionRegresa = () => {
    this.setState({ flatRecover: !this.state.flatRecover, recoverPassword: '' });
    document.getElementById('input_correo').className = 'input100';
    document.getElementById('correo_sin').setAttribute('hidden', 'hidden');
    document.getElementById('correo_alert').setAttribute('hidden', 'hidden');
    document.getElementById('correo_alert_base').setAttribute('hidden', 'hidden');
    document.getElementById('correo_aler_button').setAttribute('hidden', 'hidden');
    document.getElementById('correo_exitoso').setAttribute('hidden', 'hidden');
  }

  handleAccount = () => {
    let  {recoverPassword} = this.state;
    if (!recoverPassword) {
      document.getElementById('correo_sin').removeAttribute('hidden');
    } else {
      document.getElementById('correo_sin').setAttribute('hidden', 'hidden');
      let data = {
        correo: recoverPassword,
      };
      
      axios
        .post(`${Services.ADMIN.path}/admin/recover`, data)
        // .post(`http://localhost:3022/admin/recover`, data)
        .then(() => {
          document.getElementById('correo_exitoso').removeAttribute('hidden');
          this.setState({recoverPassword: '' });
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              response:error.response.data.message
            });
            document
              .getElementById('correo_alert_base')
              .removeAttribute('hidden');
          }
        });
    }  }

  recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

  render() {
    let {recoverPassword} = this.state;
    return (
      <div className='login-container'>
        <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
        <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
          <CardBody style={{ textAlign: 'center' }}>
            <div className="logo-container">
              {/* <img className='img_logo' style={{ width: '100%' }} src={'https://fsposeidon.blob.core.windows.net/general/interglobo-logo-psdn-white-payoff.png'} alt='poseidon-logo'></img> */}
              <img className='img_logo' style={{ width: '100%' }} src={'https://fsposeidon.blob.core.windows.net/general/poseidon-without-TM.png'} alt='poseidon-logo'></img>
            </div>
          </CardBody>
          <CardBody>{this.state.flatRecover ?
            <>
              <div className="wrap-login1000 p-t-0 p-b-0" style={{ background: 'transparent' , marginTop:'-2em',marginLeft:'5em'}}>
                <div>
                  <div className="col-12" style={{ textAlign: 'center', color: '#808181', fontSize: '28px', }}>
                    Password Reset
                  </div>
                  <div className="col-12" style={{ height: 35 }}></div>
                  <div className="col-12" style={{ color: '#808181', fontSize: '15px' }}>
                  To reset your password, please provide your email.
                  </div>
                </div>
                <div style={{ height: 35 }} />
                <div className="col-12" style={{ textAlign: 'center' }}>
                  <div className="wrap-input100 validate-input m-b-12 " data-validate="Correo es requerio" >
                    <Input type="text" id="input_correo" name="correo" placeholder=""
                      onChange={this.changeCorreo}
                      value={recoverPassword} />
                    <span className="focus-input100"></span>
                  </div>
                  <div style={{ height: 10 }} />
                  <div style={inputAlert} className="_alert" id="correo_alert" hidden>
                    Please enter a valid email
                  </div>
                  <div style={inputAlert} className="_alert" id="correo_alert_base" hidden>
                    Unregistered email
                  </div>
                  <div style={inputAlert} className="_alert" id="correo_sin" hidden>
                    Email is required
                  </div>
                  <div style={inputAlert} className="_alert" id="error_correo" hidden>
                    Unregistered email
                  </div>
                  <div style={inputAlert} className="_alert" id="correo_exitoso" hidden>
                    Email successfully sent
                  </div>
                </div>
                <div style={{ height: 20 }} />
                <div className="container-login100-form-btn">
                  <div className="col-6 " style={{ marginTop: '-1px' }}>
                    <button className="_loginButton" onClick={this.funtionRegresa}>Back</button>
                  </div>
                  <div className="col-6 " style={{ textAlign: '-webkit-right' }}>
                    <button id="correo_aler_button" className="_loginButton" onClick={this.handleAccount}>Send</button>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <FormGroup>
                {this.state.nexStep ? <div className="goBack"><em className="fa-2x icon-arrow-left mr-2" onClick={this.changeView()}></em></div> : ''}
                {this.state.nexStep ? <Login onError={this.onError} email={this.state.login.email} props={this.props}></Login> : this.FirstLoad()}
              </FormGroup>
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  onClick={() => this.setState({ flatRecover: !this.state.flatRecover })}
                  style={styleButton}>
                  Forgot your password?
                </button>
              </div>
            </>
          }
          </CardBody>
          <CardBody>
            <Col className="_footerContent">
              <div className="col-md-12 footer-top"></div>
              <div className="col-md-12 page-footer">
                <div className="col-md-12 mobile">
                  <a id="login-apple-store-link" > <img src="https://fsposeidon.blob.core.windows.net/general/app_store_image.jpg" className="apple_image" alt='ios-app'></img> </a>
                  <a id="login-apple-store-link"> <img src="https://fsposeidon.blob.core.windows.net/general/android_download.png" className="android_image" alt='android-app'></img> </a>
                </div>
                <div className="col-md-12 copyright">Copyright © {moment(new Date()).format('YYYY')} <a className="em-a" href="https://www.interglobo.com/">Interglobo</a>. All rights reserved. </div>
                <div className="col-md-12 copyright-terms"> <a id="login-terms-link" className="em-a"> Terms and Conditions</a></div>
              </div>
              <div className="col-md-12 page-bootom"></div>
            </Col>
          </CardBody>
        </Col>
      </div>
    );
  }
}

export default withRouter(App);;
