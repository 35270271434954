import React from 'react';
import { ResponsivePieCanvas } from '@nivo/pie';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsivePieCanvas = ({ data , color, width, height,theme /* see data tab */ }) =>
{
  let colors = [];
          data.map((e) => {
            colors.push(e.color);
            return e;
          })
  return (
    (
      <ResponsivePieCanvas
      style={{background:"red"}}
        data={data}
        // margin={{ top: 40, right: 80, bottom: 80, left: 83 }}
        margin={{ top: 20, right: 20, bottom: 50, left: 40 }}
        pixelRatio={1}
        startAngle={-18}
        innerRadius={0.6}
        cornerRadius={0}
        padAngle={2} //tamaño de particiones
        colors={colors}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', '0' ] ] }}
        radialLabelsSkipAngle={10} //esta parte espara el texto de los label de las orillas de las particiones//
        radialLabelsTextXOffset={5}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={5}
        radialLabelsLinkHorizontalLength={10}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
        slicesLabelsSkipAngle={20}
        sliceLabel={function(e){return (Number(e.value).toLocaleString('En-us'))}}
        isInteractive={false}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={ (val)=> {  
          // return (
          //   <Row>
          //   <Col md={12}>
          //     <h5> <span style={{height:'15px',width:'15px',background:val.color,borderRadius:'50%',display:'inline-block'}}></span>  {val.data.id}  :  {(Number(val.value).toLocaleString('En-us'))} </h5>
          //   </Col>
          // </Row>
          // );
        }}


        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        fill={[
          {
            match: {
              id: 'ruby'
            },
            id: 'dots'
          },
          {
            match: {
              id: 'c'
            },
            id: 'dots'
          },
          {
            match: {
              id: 'go'
            },
            id: 'dots'
          },
          {
            match: {
              id: 'python'
            },
            id: 'dots'
          },
          {
            match: {
              id: 'scala'
            },
            id: 'lines'
          },
          {
            match: {
              id: 'lisp'
            },
            id: 'lines'
          },
          {
            match: {
              id: 'elixir'
            },
            id: 'lines'
          },
          {
            match: {
              id: 'javascript'
            },
            id: 'lines'
          }
        ]}
        legends={[
          {
            anchor: 'top',
            position: 'bottom',
            direction: 'column',
            translateX: 140,
            itemWidth: 60,
            itemHeight: 14,
            itemsSpacing: 2,
            symbolSize: 14,
            symbolShape: 'circle'
          }
        ]}
      />
    )
  )
};
