import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core/';
import { Card, CardBody, Input, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Select from 'react-select';
import './style/style.scss';
import ListContact from './listContact';
import ListCustomer from './listCustomer';
import axios from 'axios';
import {Services} from '../../Services';
import Checkbox from '@material-ui/core/Checkbox';
import Errors from '../../../../template/Errors';
import { Dropdown } from 'semantic-ui-react';
import { FilePond } from 'react-filepond';

class createCustomer extends Component {
  constructor(props) {
    super(props);
    this.pond = React.createRef();
    this.state = {
      currentValues: [],
      imaAvatar: 'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png',
      country: [],
      CustomerId: '',
      CreateFormData: [],
      CreateForAdd: [],
      Form: this.props.form,
      selected: [],
      customer: {
        CustomerName: '',
        Telephone: '',
        TaxId: '',
        Country: '',
        CustomerCode: '',
        Avatar: '',
        IntergloboId: '',
        AutomaticShipments:false
      },
      items: [
      ],
      contact: {
        Name: '',
        LastName: '',
        Position: '',
        Telephone: '',
        Email: ''
      },
      error: {
        open: false,
        message: '',
        type: 'admin'
      },
      submitt: false,
      part: {},
      servicesServicces: [{
        CargoId: 1,
        Descripcion: 'My Dashboard',
        check: false,
        disable: false
      },
      {
        CargoId: 2,
        Descripcion: 'Warehousing',
        check: false,
        disable: false
      },
      {
        CargoId: 3,
        Descripcion: 'Purchase Orders',
        check: false,
        disable: false
      },
      {
        CargoId: 4,
        Descripcion: 'Tracking',
        check: false,
        disable: false
      }],
      combo: [{
        key: 1,
        text: 'My Dashboard',
        value: 1
      },
      {
        key: 2,
        text: 'Warehousing',
        value: 2,
      },
      {
        key: 3,
        text: 'Purchase Orders',
        value: 3,
      },
      {
        key: 4,
        text: 'Bookings',
        value: 4,
      },
      {
        key: 5,
        text: 'Tracking',
        value: 5,
      },
      {
        key: 6,
        text: 'Reporting & Analytics',
        value: 6,
      },
      {
        key: 7,
        text: 'Cost Management',
        value: 7,
      },
      {
        key: 8,
        text: 'Customer Service',
        value:
          8,
      },
      {
        key: 9,
        text: 'Admin',
        value:
          9,
      },
      ],
    };
  }


  componentDidMount = () => {
    this.getCountrys();
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      Form: nextProps.form,
    }, () => {
      this.getCountrys();
    });
  }

  validateAddContac = () => {
    if (this.state.items.length > 0) {
      this.saveCustomer();
    }
    else {
      this.props.onError({
        error: {
          message: 'A contact needs to be added!',
          open: true,
          type: 'warning'
        }
      });
    }
  }

  validateSaveCustomer = e => evt => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.CreateCustomer;
    Form.map((e) => {
      if (e.required === true) {
        validation.push(e);
      }
      return e;
    });
    validation.forEach((e) => {
      if (this.state.customer[e.id] === '') {
        errors += e.label + ', ';
        document.getElementById(e.id).style.color = 'red';
      }
    });
    if (errors.length > 0) {
      this.setState({
        submitt: true
      }, () => {
        this.buildFormCreate();
        this.buildFormAdd();
        this.props.getlist();
      });
      this.setState({
        error: {
          message: 'Campos requeridos: ' + errors + '',
          open: true,
          type: 'error'
        }
      });
    }
    else {
      this.validateAddContac();
    }
  }

  saveCustomer = name => {
    let newarraymodule = [];
    this.state.servicesServicces.map((e, i) => {
      if (e.check) {
        newarraymodule.push(e);
      }
      return e;
    });
    let {customer} = this.state;
    customer['Avatar'] = this.state.imaAvatar;
  
    let data = customer;
    axios.post(`${Services.PostCustomer.path}admin/createCustomer`, data).then(success => {
      this.setState({
        CustomerId: success.data.data.CustomerId
      }, () => {
        let data = {
          CustomerId: this.state.CustomerId,
          contact: this.state.items
        };
        axios.post(`${Services.PostContact.path}admin/createContact`, data).then(() => {
          let contact = {
            Name: '',
            LastName: '',
            Position: '',
            Telephone: '',
            Email: ''
          };
          let customer = {
            CustomerName: '',
            Telephone: '',
            TaxId: '',
            Country: '',
            CustomerCode: '',
            IntergloboId: '',
            AutomaticShipments:false

          };
          this.pond.current.removeFiles();
          this.setState({
            contact,
            items: [],
            customer: customer,
            currentValues: [],
          }, () => {
            this.buildFormCreate();
            this.buildFormAdd();
            this.props.getlist();
            this.props.onError({
              error: {
                message: 'the Customer has been saved correctly',
                open: true,
                type: 'admin'
              }
            });
          });
        }
        )
          .catch(error => {
            console.error(error.response.data.data.message);
            this.props.onError({
              error: {
                message: 'Incorrect data, please verify',
                open: true,
                type: 'error'
              }
            });
          });
      });
    }).catch(() => {
      // console.error(error.response.data.data.message);
      this.props.onError({
        error: {
          message: 'Incorrect data, please verify',
          open: true,
          type: 'error'
        }
      });
    });
  }

  getCountrys = () => {
    axios.get(Services.GetCountrys.path).then(success => {
      this.setState({ country: success.data.data }, () => {
        this.buildFormCreate();
        this.buildFormAdd();
      });
    }).catch(error => {
      console.error(error);
    });
  }

  moreContact = name => event => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.AddContact;
    Form.map((name) => {
      if (name.required == true) {
        validation.push(name);
      }
      return name;
    });
    validation.forEach((name) => {
      if (this.state.contact[name.id] == '') {
        errors += name.label + ', ';
        document.getElementById(name.id).style.color = 'red';
      }
    });
    if (errors.length > 0) {
      this.setState({
        submitt: true
      }, () => {
        this.buildFormAdd();
      });
      this.setState({
        error: {
          message: 'Campos requeridos: ' + errors + '',
          open: true,
          type: 'error'
        }
      });
    }
    else {
      let { items } = this.state;
      items.push(this.state.contact);
      let contact = {
        Name: '',
        LastName: '',
        Position: '',
        Telephone: '',
        Email: ''
      };
      this.setState({
        items,
        contact: contact,
        CustomerId: ''
      }, () => {
        this.buildFormAdd();
      });
    }
  }

  DeleteContact = items => {
    this.setState({
      items: items
    });
  }

  handleChangeAdd = name => event => {
    document.getElementById(name).style.color = 'black';
    let { contact } = this.state;
    contact[name] = event.target.value;
    this.setState({
      contact
    }, () => {
      this.buildFormAdd();
    });
  }

  handleChange = name => event => {
    document.getElementById(name).style.color = 'black';
    let { customer } = this.state;
    customer[name] = event.target.value;
    this.setState({
      customer
    }, () => {
      this.buildFormCreate();
    });
  }

  handleChangeSelect = (selected) => event => {
    if (event && event.value) {
      let { customer } = this.state;
      customer[selected] = event.value;
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
    else {
      let { customer } = this.state;
      customer[selected] = '';
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
  }
  handleChangeChecking = (selected) => event => {
    let { customer } = this.state;
    let checked = event.target.checked;
    customer[selected] = checked;
    this.setState({ selected }, () => {
      this.buildFormCreate();
    });
  }

  handleChangeCheck = name => event => {
    this.state.servicesServicces.map((e) => {
      if (e.CargoId == event.target.value) {
        e.check = !e.check;
      }
      return e;
    });
    this.setState({
      servicesServicces: this.state.servicesServicces
    }, () => {
      this.buildFormCreate();
    });
  }

  buildFormCreate = () => {
    const { Form, customer, country } = this.state;

    let CreateFormData = Form.CreateCustomer.map((e, i) => {
      if (e.type === 'Input') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite> <span id={e.id} style={e.style}><label>{e.label} {e.required == true ? <span>*</span> : ''}</label></span></Fade>
          <Input
            required
            type={e.datatype}
            placeholder={e.placeHolder}
            name={e.id}
            value={customer[e.id]}
            maxlength={e.maxlength}
            minlength={e.minlength}
            onChange={this.handleChange(e.id)}
            max={e.max}
            error={true}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>
      );
      else if (e.type === 'Select') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite><span id={e.id} style={e.style}>  <label >{e.label} {e.required == true ? <span>*</span> : ''} </label></span></Fade>
          <Select
            required
            type={e.datatype}
            name={e.name}
            value={customer[e.id]}
            onChange={this.handleChangeSelect(e.id)}
            options={country}
            error={true}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>);
      else if (e.type === 'check') {
        return (
          <div className={e.class} key={e.id}>
            <div className="col-md-12"  style ={{textAlign:'center'}}>
              <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required == true ? <span>*</span> : ''} </label></span></Fade>
            </div>
            <div className="col-md-12" style ={{textAlign:'center',marginTop:'-12px'}}>
              <Checkbox
                checked={customer[e.id]}
                onChange={this.handleChangeChecking(e.id)}
                value={customer[e.id]}
                color="primary"
              />
            </div>
            {/* <Selecion style={{ width: '100%', maxWidth: '838px' }}
              multiple
              className="textField"
              value={[]}
              onChange={this.handleChangeCheck(e.id)}
              name=""
              MenuProps={MenuProps}
              input={<Line maxlength="6" id="select-multiple-checkbox" />}
              margin="normal"
              error={true}
            >
              {this.state.servicesServicces.map(option => (
                <MenuItem key={option.CargoId} value={option.CargoId} _value={option.Descripcion}>
                  <Checkbox checked={option.check} />
                  <ListItemText style={{ fontSize: '8px !important', }} primary={option.Descripcion} />
                </MenuItem>
              ))}
            </Selecion> */}
          </div>
        );
      }
      else if (e.type === 'multiCombo') {
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite><span id={e.label} >  <label >{e.label} {e.required == true ? <span>*</span> : ''} </label></span></Fade>
            <Dropdown
              onChange={this.handleOnChangeData}
              id={e.id}
              options={this.state.combo}
              clearable
              placeholder='modul ...'
              search
              selection
              fluid
              value={this.state.currentValues}
              multiple
              allowAdditions
            />
          </div>
        );
      } else return '';
    });
    this.setState({
      CreateFormData
    });
  }

  handleOnChangeData = (evt, { value }) => {
    this.setState({ currentValues: value }, () => {
      this.buildFormCreate();
    });
  };

  buildFormAdd = () => {
    const { Form, contact, selects } = this.state;
    let CreateForAdd = Form.AddContact.map((e, i) => {
      if (e.type === 'Input') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite> <span id={e.id} style={e.style}><label>{e.label} {e.required == true ? <span>*</span> : ''}</label></span></Fade>
          <Input
            required
            type={e.datatype}
            placeholder={e.placeHolder}
            name={e.id}
            value={contact[e.id]}
            onChange={this.handleChangeAdd(e.id)}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>
      );
      return e;
    });
    this.setState({
      CreateForAdd
    });
  }

  updateImg = str => {
    this.setState({ imaAvatar: str });
  }

  recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

  render() {
    var img = '';
    return (
      <>
        <Card>
          <CardBody className="myCard">
            <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
            <ExpansionPanel elevation={0} defaultExpanded >
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>Customer Information</strong>
              </div>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <FilePond
                    allowFileEncode={true}
                    labelIdle="LOGO"
                    ref={this.pond}
                    labelFileWaitingForSize={false}
                    styleButtonRemoveItemPosition="right"
                    labelButtonRetryItemLoad={false}
                    onupdatefiles={(fileItems) => {
                      if (fileItems.length > 0) {
                        if (fileItems[0].file.type == 'image/jpeg' || fileItems[0].file.type == 'image/png' || fileItems[0].file.type == 'image/jpg') {
                          if (fileItems[0].file.size < 1000000) {
                            var reader = new FileReader();
                            reader.readAsDataURL(fileItems[0].file);
                            reader.onload = () => {
                              this.updateImg(reader.result);
                            };
                          }
                          else {
                            this.pond.current.removeFiles();
                            this.updateImg('https://fsposeidon.blob.core.windows.net/files/user-default-grey.png');
                            this.props.onError({
                              error: {
                                message: 'exceed the maximum supported size!',
                                open: true,
                                type: 'error'
                              }
                            });
                          }
                        }
                        else {
                          this.pond.current.removeFiles();
                          this.updateImg('https://fsposeidon.blob.core.windows.net/files/user-default-grey.png');
                          this.props.onError({
                            error: {
                              message: 'unsupported file type!',
                              open: true,
                              type: 'error'
                            }
                          });
                        }
                      }
                      else {
                        this.pond.current.removeFiles();
                        this.updateImg('https://fsposeidon.blob.core.windows.net/files/user-default-grey.png');
                      }
                    }}
                  >
                  </FilePond>
                  <img src={this.state.imaAvatar} width="117" height="117" className="circule" alt="avatar_image" />
                  <div className="col-md-10">
                    <div className="row">
                      {this.state.CreateFormData}
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel elevation={0} defaultExpanded >
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>Add Contact</strong>
              </div>
              <ExpansionPanelDetails>
                <div className="col-md-11">
                  <div className="row">
                    {this.state.CreateForAdd}
                  </div>
                </div>
                <div align="center" className="col-md-1">
                  <em onClick={this.moreContact()} className="fa-2x mr-2 fas fa-plus-circle" style={{ marginTop: '33px', color: '#0079B8' }}></em>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ListContact DeleteContact={this.DeleteContact} item={this.state.items}></ListContact>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-2 col-sm-4">
                <Button variant="contained" color="danger" className="button" onClick="" >
                  Cancel
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button variant="contained" color="primary" className="button" onClick={this.validateSaveCustomer()}>
                  Save
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <ListCustomer chamge ={this.props.chamge} errorValidate={this.props.errorValidate} onError={this.props.onError} putCustomer={this.props.putCustomer} deleteCustomer={this.props.deleteCustomer} country={this.state.country} Form={this.state.Form} customer={this.props.customerList} ></ListCustomer>
      </>
    );
  }
}

export default createCustomer;