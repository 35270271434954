import React, { Component } from 'react';
import restService from '../../../../../../services/restService';
import Select from 'react-select';
import Axios from 'axios';
import {Services} from './../../../Services';

// const rest = new restService();

class suppliers extends Component {
  constructor(props) {        
    super(props);
    this.state = {
      suppliers: [],
      suppliersArray: [],
      selectedOption: '',
      supplierSelected: {},
      contactSelected: {},
      contacts: [],
      found : null,
      foundContact : null,
      isLoaded : false,
      errors : {},
      styleSupplier : {},
    };
  }


    componentDidMount = () => {
      let { isLoaded,styleSupplier } = this.state;
      if (!isLoaded) {
        this.setState({
          selected : this.props.selected,
          errors : this.props.errors

        },()=>{
          if(this.state.errors){
            if(Object.keys(this.state.errors).length > 0){
              styleSupplier = { color : this.state.errors['SupplierCode'] || this.state.errors['SupplierContactId']? '#e57373' : '#616161'};
            }
            this.setState({
              styleSupplier
            },()=>{
              this.getAllSuppliers(); 
            });
          }
          else{
            this.getAllSuppliers();
          }
                       
        });
      }else this.getAllSuppliers();
    }

    componentWillReceiveProps = (nextProps)=>{
      let { isLoaded ,styleSupplier} = this.state;
      if (!isLoaded) {
        this.setState({
          selected : nextProps.selected,
          errors : nextProps.errors

        },()=>{
          if(this.state.errors){
            if(Object.keys(this.state.errors).length > 0){
              styleSupplier = { color : this.state.errors['SupplierCode'] || this.state.errors['SupplierContactId']? '#e57373' : '#616161'};
            }
            this.setState({
              styleSupplier
            },()=>{
              this.getAllSuppliers(); 
            });  
          }
          else{
            this.getAllSuppliers();
          }
        });
      }
    }


    getAllSuppliers = () => {
      Axios.get(`${Services.GET_ALL_SUPPLIERS.path}/supplier/all`).then((data) => {
        this.setState({ suppliers: data.data.data }, () => this.buildArraySuppliers());
      }).catch((error) => {
        console.error(error);
      });
    }

    buildArraySuppliers = () => {
      const { suppliers } = this.state;
      let array = [];
      suppliers.forEach((e) => array.push({ value: e.SupplierCode, label: e.Name }));
      this.setState({ suppliersArray: array },()=>this.setFirstOrderValues());
    }

    setFirstOrderValues = ()=>{
      let {selected,suppliersArray,found} = this.state;
      if(selected && selected.length > 0 && !found) {
        let Code = selected[0].SupplierCode;
        let found = suppliersArray.find((element) => element.value === Code);
        if (found) this.handleChangeSelect(found);
        this.setState({
          found
        });
      }
    }

    handleChangeSelect = (selectedOption) => this.setState({ selectedOption }, () => {
      if (selectedOption) this.buildConsigneeDetail(selectedOption.value);
      else this.setState({ supplierSelected: {} }, () => this.props.sendProps({
        stateField: 'suppliers',
        id: ''
      }));
    });
    buildConsigneeDetail = (id) => {
      const { suppliers } = this.state;
      let index = suppliers.findIndex(x => x.SupplierCode === id);
      this.setState({ supplierSelected: suppliers[index] }, () => this.props.sendProps({ stateField: 'suppliers', id: this.state.supplierSelected.SupplierCode }));
      this.getContacts(suppliers[index].SupplierCode);

    }

    getContacts = (id) => {
      Axios.get(`${Services.GET_ONE_SUPPLIER.path}/supplier/search/${id}`).then((data) => {
        this.setState({
          contacts: data.data.data.contacts
        },()=>{
          this.setFirstOrderValuesContacts();
        });
      }).catch((error) => {
        console.error(error);
      });
    }

    setFirstOrderValuesContacts = ()=>{
      let {selected,contacts,foundContact} = this.state;
      if(selected && selected.length > 0 && !foundContact) {
        let Code = selected[0].SupplierContactId;
        let foundContact = contacts.find((element) => element.value === Code);
        if (foundContact) this.handleChangeSelectContact(foundContact);
        this.setState({
          foundContact
        });
      }
    }


    handleChangeSelectContact = contactSelected => {
      if (contactSelected)
        this.setState({ contactSelected }, () => {
          this.props.sendProps({ stateField: 'supplierContact', id: contactSelected.value });
        });
      else this.setState({ contactSelected: {} }, () => {
        this.props.sendProps({ stateField: 'supplierContact', id: '' });
      });
    }

    render() {
      const { suppliersArray, selectedOption, supplierSelected, contacts, contactSelected } = this.state;
      const value = selectedOption && selectedOption.value;
      const value2 = contactSelected && contactSelected.value;

      return (
        <div className="row" >
          <div className="col-md-12">
            <legend id="Supplier" style={this.state.styleSupplier}><em className="fas fa-industry fa-1x mr-2"></em>Supplier *</legend>
            <Select
              id="SupplierCode"
              name="select-name"
              value={value}
              onChange={this.handleChangeSelect}
              placeholder='Select a Supplier'
              options={suppliersArray} />
            <hr />
            <Select
              id="SupplierContactId"
              required
              name="select-contact"
              value={value2}
              placeholder={'Select a contact'}
              onChange={this.handleChangeSelectContact}
              options={contacts} />
            <hr />
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body d-flex align-items-center">
                <em className="fas fa-user mr-2"></em>
                <span>{supplierSelected.Name} </span>
              </div>

              <div className="card-body d-flex align-items-center">
                <em className="fas fa-map-marker-alt mr-2"></em>
                <span>{supplierSelected.Address} </span>
              </div>

              <div className="card-body d-flex align-items-center">
                <em className="fas fa-info-circle mr-2"></em>
                <span>{supplierSelected.AddressDetails} </span>
              </div>

              <div className="card-body d-flex align-items-center">
                <em className="fas fa-address-book mr-2"></em>
                <span>{contactSelected.details} </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default suppliers;