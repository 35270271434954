import React, { Component } from "react";
import { Card, CardBody, Button } from "reactstrap";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import { FilePond, File } from "react-filepond";
import Select from "react-virtualized-select";
import { Icon } from "semantic-ui-react";
import axios from "axios";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from '@material-ui/core/Tooltip';

import { Services } from "./Services";
class MultipleUpdate extends Component {
  constructor(props) {
    super(props);
    this.pond = React.createRef();
    this.state = {
      loading:false,
      success: false,
      response: false,
      file: "",
      flat: false,
      value: "",
      customerId: this.props._customer ? this.props._customer : "",
      template: "",
      entries: "",
      timeResponse: "",
      catalog: [
        {
          key: "InventoryInCardinal",
          value: "InventoryInCardinal",
          label: "Inventory In Cardinal",
        },
        {
          key: "InventoryOutCardinal",
          value: "InventoryOutCardinal",
          label: "Inventory Out Cardinal",
        },
      ],
    };
  }

  componentDidMount = () => {
  };

  updateImg = (str) => {
    this.setState({ file: str });
  };

  Clear = (e) => (evt) => {
    this.setState({
      success: false,
      response: false,
      file: "",
      flat: false,
      value: "",
      template: "",
      entries: "",
      timeResponse: "",
    });
  };

  handleTimeSearch = (time) => {
    let Time = "";
    time = (new Date().getTime() - time) * 0.001;
    time = time.toString();
    time = time.substring(0, 4) + " Seconds";
    Time = time;
    return Time;
  };

  proccesFile = (e) => (evt) => {
    this.setState({
      loading:true
    });

    let time_start = new Date().getTime();
    let { file, customerId, value } = this.state;
    let data = {
      file: file,
      customerId: customerId,
      template: value,
    };

    axios
      // .post(`https://poseidonapimultipleupdate.azurewebsites.net/multipleupdate/process-file`, data)
      // .post(`http://localhost:5220/multipleupdate/process-file`, data)
      .post(`${Services.GET_MULTIPLEUPDATE_SERVICE.path}/multipleupdate/process-file`, data)
      .then((success) => {
        console.log("success", success);

        let entriesNew = success.data.data[0].totalInserts;
        this.setState({
          entries: entriesNew,
          timeResponse: this.handleTimeSearch(time_start),
          response: true,
          success: true,
          loading:false
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          response: true,
          success: false,
          loading:false
        });
      });
  };

  Onchange = (value) => {
    let newValue = value.key;
    // let newImg = value.uri;
    this.setState({
      value: newValue,
      template: value.label,
    });
  };

  render() {
    let {
      loading,
      catalog,
      value,
      flat,
      file,
      response,
      timeResponse,
      entries,
      template,
      success,
    } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <legend>
              <em className=" fab fa-whmcs fa-1x mr-2"></em>Multiple Update
            </legend>
          </div>
        </div>
        <div style={{ marginBottom: "5em" }} className="col-12 row">
          <div className="col-2">
            <label className="subtitlecontenedor">Select template</label>
          </div>
          <div
            className="col-3"
            // style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Select
              placeholder=""
              value={value}
              options={catalog}
              clearable={false}
              onChange={(value) => this.Onchange(value)}
            />
          </div>
        </div>
        <div style={{ marginBottom: "5em" }} className="col-12 row">
          <div className="col-2">
            <label className="subtitlecontenedor">Select file</label>
          </div>
          <div
            className="col-10"
            // style={{ marginTop: 5, marginBottom: 5 }}
          >
            {flat ? (
              <div className="row">
                <div id="multipleUpdate" className="col-md-6">
                  <FilePond
                    labelFileProcessingComplete ="upload completed"
                    onupdatefiles={(fileItems) => {
                      if (fileItems.length > 0) {
                        var reader = new FileReader();
                        reader.readAsDataURL(fileItems[0].file);
                        reader.onload = () => {
                          this.updateImg(reader.result);
                        };
                      } else {
                        this.pond.current.removeFiles();
                        this.setState({ file: "" });
                      }
                    }}
                    allowMultiple={true}
                    maxFiles={1}
                    server={{
                      process: (
                        fieldName,
                        file,
                        metadata,
                        load,
                        error,
                        progress,
                        abort,
                        transfer,
                        options
                      ) => {
                        if (file) {
                          if (
                            file.type ===
                              "application/vnd.oasis.opendocument.spreadsheet" ||
                            file.type ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                            file.type === "application/vnd.ms-excel"
                          ) {
                            progress(true, 0, 1024);
                            load(file);
                          } else {
                            error("oh no, invalid file, try again");
                            //   props.callFile([]);
                          }
                        }
                      },
                    }}
                    one
                    //   labelIdle={props.labels.upload}
                    labelIdle={`
                    <div style="width:100%;height:100%;">
                      <p style="margin-left:-4em;">
                      Upload file
                      </p>
                    </div>
                  `}
                    ref={this.pond}
                    checkValidity={true}
                    labelFileProcessingError={(err) => {
                      console.log(err);
                      return err.body;
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Button
                    style={{ marginTop: "1em" }}
                    variant="contained"
                    color="primary"
                    disabled={value === "" || file === "" ? true : false}
                    className="button"
                    onClick={this.proccesFile()}
                  >
                    {loading ? (
                      // <center>
                      // {" "}
                      <Icon
                        loading={loading}
                        style={{ border: "aquamarine",color:'#000' }}
                        name="circle notch"
                        size="large"
                      />
                    ) : (
                      // </center>
                      <center>
                        <p>Process</p>
                      </center>
                    )}
                  </Button>
                </div>
              </div>
            ) : (
              <Fab
                color="primary"
                size="small"
                aria-label="add"
                onClick={() => this.setState({ flat: true })}
              >
                <AddIcon />
              </Fab>
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: "5em",
            display: response === true ? "" : "none",
          }}
          className="col-12 row"
        >
          <div className="col-2">
            <label className="subtitlecontenedor">Response</label>
          </div>
          <div className="col-10 row">
            {success ? (
              <label style={{ marginTop: "8px" }}>
                <em
                  style={{ color: "green" }}
                  className="fa-1x fas fa-check-square mr-2"
                ></em>{" "}
                File uploaded successfully:{" "}
                <spam style={{ fontWeight: "bold" }}>{entries}</spam> entries
                processed for{" "}
                <spam style={{ fontWeight: "bold" }}>{template}</spam> template
                in <spam style={{ fontWeight: "bold" }}>{timeResponse}</spam>
              </label>
            ) : (
              <label style={{ marginTop: "8px" }}>
                <em
                  style={{ color: "red" }}
                  className="fa-1x fas fa-exclamation-circle mr-2"
                ></em>{" "}
                Incorrect file format
              </label>
            )}
          </div>
          <div className="col-md-6">
            <Button
              style={{ marginTop: "1em" }}
              variant="clear"
              color="primary"
              // disabled={value === "" || file === "" ? true : false}
              className="button"
              onClick={this.Clear()}
            >
              Clear
            </Button>
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default MultipleUpdate;
