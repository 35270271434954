import React from 'react';
import { Button, Col, Row} from 'reactstrap';
import {Popup} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Popover from './../../../Global/Popup/';
import {IMAGE, IconChat, IconNewComment} from './components/img';
import './styles.scss';

const mapColor = {
  12: 'danger',
  13: 'secondary'
};

/*=============================================
=            order List Comments              =
=============================================*/

export const columns = [
  {label: '', minWidth: 120,key:'', launch: 'trigger', custom: (value, triggerFn) => (
    <Col md={12} xl={12}>
      <Row>
        <Col md={8}>
          <React.Fragment>
            <Popup
              className="_comments_icon"
              mouseEnterDelay={1500}
              trigger={<div className='container_icon_comment' onClick={() => {triggerFn({POId: value.ConsolPlanId, PONumber: value.ConsolNumber});}}>
                {
                  value.NewComments ? 
                    <IconNewComment></IconNewComment>
                    :
                    <IconChat></IconChat>
                }
              </div>}
              content='Comments'
              position='top center'
              size='mini'
              inverted
            >
            </Popup>
          </React.Fragment>
        </Col>        
        {/* <Col md={4}>
          {value.RequiresAttention === '1' ?
            <React.Fragment>
              <Popup
                className="_comments_icon"
                trigger={<div><img height="22px" width="22px" src={IMAGE} alt="img"/></div>}
                content='Orders that require attention'
                position='top center'
                size='mini'
                inverted
              >
              </Popup>
            </React.Fragment>
            : ''}
        </Col> */}
      </Row>
    </Col>    
  )},
  { label: 'Consol No.', key: 'ConsolNumber', minWidth: 80, custom: (value) => (
    <Col md={12} xl={12}>
      <Row>
        <Col md={12}>
          <Link to={`/new-consol-plan?searching=${value.ConsolPlanId}`}>{value.ConsolNumber}</Link>
        </Col>
      </Row>
    </Col>    
  )},
  // { label: 'Split No', key: 'SplitNo', minWidth: 80},
  { label: 'Consol Status', key: 'Status', minWidth: 80, custom: (value) => ( <Button className={value.StatusId === 13 ? 'btn-oval _gray_button': 'btn-oval'} color={mapColor[value.StatusId] ? mapColor[value.StatusId] : 'primary'} onClick={() =>{}}>{value.Status}</Button> ) },
  { label: 'Equipment Type', key: 'ContainerType', minWidth: 180},
  { label: 'List of Pos', key: 'POs', minWidth: 180, popover:true, titlePopover:'List of Pos'},
  { label: 'Container No', key: 'ContainerNumber', minWidth: 180},
  { label: 'POL', key: 'POL', minWidth: 100},
  { label: 'POD', key: 'POD', minWidth: 100},
  { label: 'ETD', key: 'ETD', minWidth: 100},
  { label: 'ETA', key: 'ETA', minWidth: 100},
  { label: 'Final Destination', key: 'FinalDestination', minWidth: 100},
  { label: 'Total Pallet', key: 'TotalPallets', minWidth: 150},
  { label: 'Total Qty.', key: 'TotalQty', minWidth: 100},
  { label: 'Total Weight', key: 'TotalWeight', minWidth: 100},
  { label: 'Creation Date', key: 'CreateDate', minWidth: 100},
  { label: 'Created by', key: 'Customer', minWidth: 100},
  { label: 'Last Update', key: 'LastUpdateDate', minWidth: 100}
];

export const CloseIcon = () => (
  <svg height="24px" id="Layer_1_close" version="1.1" viewBox="0 0 90 90" width="24px"  xmlns="http://www.w3.org/2000/svg">
    <circle className="st73" cx="45" cy="45" r="45"/><g><g><rect className="st7" height="50.9116898" transform="matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -19.3467178 45.2928925)" width="7.999836" x="41.0000801" y="20.5441551"/></g></g><g><g><rect className="st7" height="7.999836" transform="matrix(0.7071678 -0.7070457 0.7070457 0.7071678 -19.3466549 45.2873383)" width="50.9116898" x="19.5441551" y="42.0000801"/></g></g>
  </svg>
);

export const TrashIcon = () => (
  <svg enable-background="new 0 0 500 500" height="18px" id="Layer_1_trash" version="1.1" viewBox="0 0 500 500" width="18px">
    <path clip-rule="evenodd" d="M170,43h23v46h-23V43z" fill="#EF4823" fill-rule="evenodd"/><path clip-rule="evenodd" d="M308,43h23v46h-23V43z" fill="#EF4823" fill-rule="evenodd"/>
    <path clip-rule="evenodd" d="M170,20h161v23H170V20z" fill="#EF4823" fill-rule="evenodd"/><path clip-rule="evenodd" d="M78,66h345v69H78V66z" fill="#EF4823" fill-rule="evenodd"/>
    <path clip-rule="evenodd" d="M101,89v391h299V89H101z M193,411h-23V135h23V411z M262,411h-23  V135h23V411z M331,411h-23V135h23V411z" fill="#EF4823" fill-rule="evenodd"/>
  </svg>
);

