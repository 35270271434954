import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import restService from '../../../../services/restService';
import BookingCreation from './components/booking-creation';
import BookingDetails from './components/booking-details';
import { Button, Col, Row} from 'reactstrap';
import List from './components/booking-list';
import Containers from './components/booking-containers';
import Packages from './components/booking-packages';
import Carrier from './components/booking-carrier';
import Additional from './components/booking-additional';
import Documents from './components/booking-documents';
import ConsigneeDetails from './components/booking-consignee-details';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import async from 'async';
import Errors from '../../../template/Errors';
const rest = new restService();

class Booking extends Component {
    state = {
      selected: [],
      stringSelected: '',
      // main: <BookingCreation sendSelected={this.recieveSelected} />,
      secondary: '',
      containersOpackages: '',
      loader: '0',
      /**
             * 
             */
      AttachedOrders: '',
      ConsigneeDetails: '',
      BookingDetails: '',
      CarrierDetails: '',
      DocumentsDetails: '',
      CommentsDetails: '',
      Actions: '',
      errors: '',
      error: {
        open: false,
        message: '',
        type: 'error'
      },
      /**
             * 
             */
      containers: [],
      packages: [],
      details: {},
      documents: [],
      carrier: {},
      comments: {},
      consignee: {},
      loading: true
    };


    componentDidMount = () => {
      this.setState({main:  <BookingCreation sendSelected={this.recieveSelected} />});
    };
    newRecievedSelected = (selected) => {
      this.setState({
        selected
      }, () => this.setState({
        BookingDetails: <BookingDetails sendProps={this.recieveDetails} selected={this.state.selected}></BookingDetails>,
        ConsigneeDetails: <ConsigneeDetails sendProps={this.recieveConsigneDetails} selected={this.state.selected}></ConsigneeDetails>
      }));
    }
    recieveSelected = (selected) => {
      this.setState({
        selected
      }, () => this.setState({
        main: '',
        AttachedOrders: <List selected={this.state.selected} sendSelected={this.newRecievedSelected}></List>,
        ConsigneeDetails: <ConsigneeDetails sendProps={this.recieveConsigneDetails} selected={this.state.selected}></ConsigneeDetails>,
        BookingDetails: <BookingDetails sendProps={this.recieveDetails} selected={this.state.selected}></BookingDetails>,
        CarrierDetails: '',
        PackagesOrContainers: '',
        DocumentsDetails: <Documents sendProps={this.recieveDocuments}></Documents>,
        CommentsDetails: <Additional sendProps={this.recieveComments}></Additional>,
        Actions:
              <Row>
                <Col md={6} lg={8} sm={12}></Col>
                <Col md={3} lg={2} sm={6}>
                  <Button variant="contained" color="success" className="button" onClick={this.saveOrderActive(true)} >
                    Save as Draft
                  </Button>
                </Col>
                <Col md={3} lg={1} sm={6}>
                  <Button variant="contained" color="primary" className="button" onClick={this.saveOrderActive(false)}>
                    Save and complete
                  </Button>
                </Col>
              </Row>
      }));
    }
    recieveDetails = (details) => {
      if ((details.TransportModeId && details.TransportModeId === 1) || (details.TransportModeId === 2)) this.setState({
        details,
        PackagesOrContainers: details.TransportModeId === 2 ? <Packages sendProps={this.recievePackages}></Packages> : <Containers sendProps={this.recieveContainers}></Containers>,
        CarrierDetails: <Carrier sendProps={this.recieveCarrier} TransportModeId={details.TransportModeId}></Carrier>
      });
      else this.setState({
        details,
        PackagesOrContainers: <Packages sendProps={this.recievePackages}></Packages>,
        CarrierDetails: <Carrier sendProps={this.recieveCarrier} TransportModeId={3}></Carrier>
      });
    }


    recieveContainers = (containers) => this.setState({ containers })
    recievePackages = (packages) => this.setState({ packages })
    recieveDocuments = (documents) => this.setState({ documents })
    recieveCarrier = (carrier) => this.setState({ carrier })
    recieveComments = (comments) => this.setState({ comments })
    recieveConsigneDetails = (consignee) => this.setState({ consignee })

    buildIDS = (selected) => selected.map((e) => {
      return e.POId;
    })


    sendDocuments = (BookingId) => {
      const { documents } = this.state;
      async.eachSeries(documents, (e, callback) => {
        const formData = new FormData();
        formData.append('file', e.file);
        formData.append('Name', e.Name);
        formData.append('DocCategoryId', e.DocCategoryId);
        formData.append('Type', e.other);
        formData.append('RowId', BookingId);
        formData.append('ModuleId', e.ModuleId);
        fetch('https://poseidonfiles.azurewebsites.net/upload/', {
          method: 'POST',
          body: formData
        }).then(response => response.json())
          .then(() => {
            callback();
          })
          .catch(() => {
            callback();
          });
      });
    }

    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    saveOrderActive = (isDraft) => event => {
      let { details, carrier, comments, consignee, selected, packages, containers } = this.state;
      let ids = this.buildIDS(selected);
      let keys = Object.keys(details);
      keys.forEach((e) => {
        if (details[e] === '') delete details[e];
      });

      rest.EXEC({
        _function: 'PostBooking', params: isDraft ? '?draft=true' : '', data: {
          orders: ids,
          details,
          carrier,
          comments,
          consigneeData: consignee,
          packages,
          containers
        }
      }, fetch).then(data => {
        this.sendDocuments(data.BookingId);
        swal('Good job!', data.label ? data.label : 'Successfull', 'success')
          .then(() => {
            this.props.history.push('/booking-list');
          });

      }).catch(error => {
        console.error('----->>>', error);
        swal('Error', error.label ? error.label : 'INTERNAL_SERVER_ERROR', 'error');
      });
    }

    render() {
      return (
        <ContentWrapper>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-calendar fa-1x mr-2"></em>
                        Create  a New Booking
            </div>
          </div>
          <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
          {this.state.main}
          {this.state.AttachedOrders}
          {this.state.ConsigneeDetails}
          {this.state.BookingDetails}
          {this.state.CarrierDetails}
          {this.state.PackagesOrContainers}
          {this.state.DocumentsDetails}
          {this.state.CommentsDetails}
          {this.state.Actions}
        </ContentWrapper>
      );
    }
}
export default withRouter(Booking);