import React from 'react';
import propTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

function TableHeader({ columns, sortKey, selectItems, handleSort, direction }) {
  let _newColumns = [];
  columns.forEach((column, index) => {
    if (column.sort) {
      if (column.key === sortKey) {
        _newColumns.push(
          direction === 'desc' ?
            <th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index} onClick={() => handleSort(column.key, 'asc')} >

              <div className='thead-div headerText'>
                <Fade left opposite >
                  <i className="fas fa-angle-down mr-2"></i>
                </Fade>{column.label}
              </div>
            </th>
            : <th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index} onClick={() => handleSort(column.key, 'desc')}>

              <div className='thead-div headerText'>
                <Fade left opposite >
                  <i className="fas fa-angle-up mr-2"></i>
                </Fade>{column.label}
              </div>
            </th>
        );
      } else {
        _newColumns.push(<th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index} onClick={() => handleSort(column.key, 'asc')} >
          {/* <Fade left opposite >
          </Fade> */}
          <div className='thead-div headerText'>
            {column.label}
          </div>
        </th>);
      }
    } else {
      _newColumns.push(
        <th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index}> <div className={column.class ? column.class : 'thead-div'}>{column.label}</div></th>
      );
    }
  });

  return (
    <React.Fragment>
      {
        selectItems ?
          <th> </th> :
          ''
      }
      {_newColumns}
    </React.Fragment>
  );
}

TableHeader.propTypes = {
  columns: propTypes.array,
  sortKey: propTypes.string,
  direction: propTypes.oneOf(['desc', 'asc']),
  selectItems: propTypes.bool,
  handleSort: propTypes.func
};
TableHeader.defaultProps = {
  columns: [],
  sortKey: '',
  direction: 'desc',
  selectItems: false,
  handleSort: () => { }
};
export default TableHeader;