import React from 'react';
import propTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Dropdown, Transition } from 'semantic-ui-react';
import Axios from 'axios';
import moment from 'moment';
import { sendNewCommentEvent } from './../../../../../Global/Socket/';
import { Emoji } from './config';
import './styles.scss';

import 'emoji-mart/css/emoji-mart.css';
import { Picker as PickerD } from 'emoji-mart';

import { Services } from './../../../Services';
/*=============================================
=            Create New Comment Function      =
=============================================*/
function NewComment(props) {
  const [select, setSelect] = React.useState([]);
  const [comment, setComment] = React.useState('');
  const [commentType, setCommentType] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [chosenEmoji, setChosenEmoji] = React.useState(false);

  /**s
   * Get All Catalogs
   */
  const GetCatalogs = React.useCallback(async () => {
    // let response = await Axios.get(`${Services.CATALOG_SHIPMENT_COMMENTS.path}/all/shipment-comments`);
    // if(response.data && response.data.data){
    //   let array = [];
    //   for (let index = 0; index < response.data.data.COMMENTS[0].childs.length; index++) {
    //     const element = response.data.data.COMMENTS[0].childs[index];
    //     array.push({key: `${element.ComTypeId}`, text: element.ComType, value: `${element.ComTypeId}`});
    //   }
    //   setSelect(array);
    // }
  }, []);

  /**
   * On change comment
   * @param {*} evt 
   */
  function OnChangeComment(evt) {
    setComment(evt.target.value);
  }

  /**
   * On change comment type
   * @param {*} value 
   */
  function OnChangeCommentType(value) {
    setCommentType(value);
  }

  /**
   * On Key press
   * @param {*} evt 
   */
  function OnKeyPressFn(evt) {
    if (evt.charCode === 13) {
      saveComment();
    }

  }
  /**
   * Handle action
   */
  function handleAction() {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 3000);
  }
  /**
   * Send Event to WebSocket
   */
  function sendEvent() {
    sendNewCommentEvent({ user: props._user, customer: props._customer });
  }

  /**
   * Event handler for Emoji Click
   * @param {*} event 
   * @param {*} emojiObject 
   */
  function onEmojiClick(emoji) {
    // let newComment = `${document.getElementById('fakebox-input').value} ${emojiObject.emoji}`;
    let newComment = `${document.getElementById('fakebox-input').value} ${emoji.native}`;
    setComment(newComment);
  };
  /**
   * Set visible or invisible emoji picker
   */
  function handleEmoji() {
    setChosenEmoji(!chosenEmoji);
  }

  /*=============================================
  =            Save Comment Function            =
  =============================================*/

  async function saveComment() {
    let Comment = {};
    Comment.ModuleId = 7;
    Comment.ComTypeId = 41;
    Comment.RowId = props.POId;
    Comment.Date = moment(new Date()).format('LLLL');
    Comment.Comment = comment;
    Comment.UserId = parseInt(props._user);
    // Comment.VisibleClient = props._rolId === '1' ||props._rolId === '3' || props._rolId === '7' ? '1' :'0' ;
    Comment.VisibleClient = '1';
    if (chosenEmoji) handleEmoji();
    // let Comment = {};
    let flag = true;
    // Comment.ModuleId = 1;
    // Comment.ComTypeId = parseInt(commentType) ? parseInt(commentType): 1;
    // Comment.RowId = parseInt(props.POId) ? parseInt(props.POId): '';
    // Comment.Date = moment(new Date()).format('LLLL');
    // Comment.UserId = parseInt(props._user) ? parseInt(props._user): '';
    // Comment.Comment = comment;

    for (const key in Comment) {
      if (Comment.hasOwnProperty(key)) {
        const element = Comment[key];
        if (!element) flag = false;
      }
    }
    if (flag) {
      try {
        // let url = 'http://localhost:3005/';
        // await Axios.post(`${Services.CREATE_COMMENT.path}/`, Comment);
        let success = await Axios.post(`${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/create-comment`, Comment);

        sendEvent();
        setComment('');
        props.resetComments();

        // await Axios.post(`${Services.GET_ONE_ORDER.path}notifyPo`, {data: [Comment.RowId]});
      } catch (error) {
        console.error('error creating comment', error);
        handleAction();
      }

    } else {
      console.error('Error trying create comment');
      handleAction();
    }

  }


  React.useEffect(() => {
    GetCatalogs();
  }, [GetCatalogs]);
  return (
    <>
      <Row>
        <Col xs={12} md={12} lg={12} className='mb-3'>
          <div id='fakeboxComments'>
            <input id='fakebox-comments' value={comment} onChange={OnChangeComment}
              onKeyPress={OnKeyPressFn}></input>
            <div className='container-icons' onClick={handleEmoji}>
              <Emoji />
            </div>
            {/* <button id='fakebox-send' title='Send' onClick={saveComment}></button> */}
          </div>
          {
            chosenEmoji ?
              <PickerD
                emoji=''
                showSkinTones={false}
                onSelect={onEmojiClick}
                set='google'
                style={{ position: 'absolute', bottom: '60px', right: '50px' }}
                title='Pick your emoji…'
                showPreview={false}
              />
              : ''
          }
          <Transition visible={visible} animation='scale' duration={500}>
            <div className='container _error_message'>
              Error trying create comment, you can´t create an empty comment. Plase verify and try again.
            </div>
          </Transition>
        </Col>
      </Row>
    </>
  );
}

NewComment.propTypes = {
  POId: propTypes.number,
  _user: propTypes.string,
  _customer: propTypes.string,
  _rolId: propTypes.string,
  resetComments: propTypes.func
};

NewComment.defaultProps = {
  POId: 0,
  _user: '',
  _customer: '',
  _rolId: '',
  resetComments: () => { }
};

export default NewComment;