import React, { Component } from 'react';
import './App.scss';
import $ from 'jquery';
import './Vendor';
import './Globals';
import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import { Provider } from 'react-redux';
import store from './store';
/**
 * Middlewares
 */

import WrapperRoute from './components/Middlewares/WrapperRoute';

/**
 * Module Component
 */
import LoginComponent from './components/poseidon/Login/';

/**
 * PO MODULE
 */
import OrderCreate from './components/poseidon/Order/order-create';
import PODraft from './components/poseidon/Order/order-po-draft';
import OrdersList from './components/poseidon/Order/order-list';
import consolPlan from './components/poseidon/ConsolPlan/consol-list';
import newConsolPlan from './components/poseidon/ConsolPlan/new-consol-plan';
import Anagrafico from './components/poseidon/Analitico';
import POViewDetail from './components/poseidon/Order/order-po-detail-view';
import POConfirmation from './components/poseidon/Order/order-po-confirmation';
import POConfirmationLogged from './components/poseidon/Order/order-po-confirmation/confirmationFormLogged';
import PendingActivitiesOrder from './components/poseidon/Order/order-pending-activities';
import CargoReadinessConfirmation from './components/poseidon/Order/order-cargo-readiness-confirmation';
import BookingConfirmation from './components/poseidon/Booking/booking-confirmation/';
import Admin from './components/poseidon/Admin';
import Alerts from './components/poseidon/Alerts';
import MultiUpdate from './components/poseidon/Order/Multiple-Update';
import RecoverPasswors from './components/poseidon/RecoverPassword/';





/**
 * BOOKING MODULE
 */
import Booking from './components/poseidon/Booking/booking-create';
import ListBooking from './components/poseidon/Booking/booking-list';

import ContainerAssociation from './components/poseidon/ContainerAssociation';

/**
 * DASHBOARD MODULE
 */
import Dashboard from './components/poseidon/Dashboard';
/**
 * TRACKING MODULE
 */
// import Tracking from './components/poseidon/TrackTracking';
import Tracking from './components/poseidon/Shipment/tracking-summary';
import Shipment from './components/poseidon/Shipment/shipment-sumary';
import ContainerDetail from './components/poseidon/Shipment/tracking-summary/components/container';
/**
 * COST MANEGEMENT MODULE
 */

import Purchase from './components/poseidon/CostManagement/cm-purchase';
import Summary from './components/poseidon/CostManagement/cm-summary';
import Invoice from './components/poseidon/CostManagement/cm-invoice';
/**
 * HOME MODULE
 */
// import Home from './components/poseidon/Home/';
/**
 * REPORTING & ANALITICS MODULE
 */
import Reporting from './components/poseidon/Reporting&Analytics';
import CreateReport from './components/poseidon/Reporting&Analytics/create';
import EditReport from './components/poseidon/Reporting&Analytics/create';
import CreateStandarReport from './components/poseidon/Reporting&Analytics/standar';
import EditStandarReport from './components/poseidon/Reporting&Analytics/standar/';

/**
 * RATES MODULE
 */
import Rates from './components/poseidon/Rates';
/**
 * CONTROL TOWER WEEKLY MODULE
 */
import WeeklyPlanning from './components/poseidon/ControlTower/WeeklyPlanning';
import WeeklyPlanningDetail from './components/poseidon/ControlTower/WeeklyPlanning/bookingDetail';
/**
 * CONTROL TOWER CALENDAR MODULE
 */
import CalendarView from './components/poseidon/ControlTower/CalendarView';
/**
 * CONTROL TOWER CAPACITY SET UP
 */
import CapacitySetUp from './components/poseidon/ControlTower/CapacitySetUp';

/**
 * WAREHOUSING
 */
import Warehousing from './components/poseidon/Warehousing';
/**
 * CUSTOMER SERVICE
 */
import CustomerService from './components/poseidon/CustomerService';
/**
 * SOME MODULES
 */
import NotFound from './components/template/pages/notFound';

import QuickSearch from './components/poseidon/QuickSearch';
import Procedures from './components/poseidon/Procedures';
import Audit from './components/poseidon/Audit';


import Transactions from './components/poseidon/WarehousingBurgo/Transactions';
import Inventory from './components/poseidon/WarehousingBurgo/Inventory';
import InventoryIn from './components/poseidon/WarehousingBurgo/InventoryIn';
import InventoryOut from './components/poseidon/WarehousingBurgo/InventoryOut';
import ReceiptsDetail from './components/poseidon/WarehousingBurgo/Transactions/children/Details/detailsReceipts';
import ShipmentDetail from './components/poseidon/WarehousingBurgo/Transactions/children/Details/detailsShipments';
import DetailsTrucking from './components/poseidon/WarehousingBurgo/Transactions/children/Details/detailsTrucking';
import MultipleUpdate from './components/poseidon/MultipleUpdate'
import Gerencial from './components/poseidon/Reporting&Analytics/MyAnalitics/gerencial';

/* New Build */
import NewBuild from './components/poseidon/NewBuild'
import visibilityDetailPO from './components/poseidon/NewBuild/detail/detailPO'
import visibilityDetailMS from './components/poseidon/NewBuild/detail/detailMS'

/* Vendor Management */
import VendorManagement from './components/poseidon/VendorManagement'
import vendor_detail from './components/poseidon/VendorManagement/vendor_detail'


$.ajaxPrefilter(o => o.async = true);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history} basename={'/'}>
          <Switch>
            <Route exact path="/" component={LoginComponent} />
            <Route exact path="/dashboard" render={() => <WrapperRoute name={'dashboard'} component={Dashboard} ></WrapperRoute>} />
            {/* // ? Order create router */}
            <Route exact path="/order-create" render={() => <WrapperRoute name={'order_create'} component={OrderCreate} ></WrapperRoute>} />
            <Route exact path="/order-list" render={() => <WrapperRoute name={'order_list'} component={OrdersList} ></WrapperRoute>} />
            <Route exact path="/consol-plan" render={() => <WrapperRoute name={'consol_plan'} component={consolPlan} ></WrapperRoute>} />
            <Route exact path="/new-consol-plan" render={() => <WrapperRoute name={'new_consol_plan'} component={newConsolPlan} ></WrapperRoute>} />
            <Route exact path="/pending-activities" render={() => <WrapperRoute name={'pending_activities'} component={PendingActivitiesOrder} ></WrapperRoute>} />
            <Route exact path="/booking-create" render={() => <WrapperRoute name={'booking_create'} component={Booking} ></WrapperRoute>} />
            <Route exact path="/booking-list" render={() => <WrapperRoute name={'booking_list'} component={ListBooking} ></WrapperRoute>} />
            <Route exact path="/shipment/summary/" render={() => <WrapperRoute name={'shipment_summary'} component={Shipment} ></WrapperRoute>} />
            <Route exact path="/reporting" render={() => <WrapperRoute name={'reporting'} component={Reporting} ></WrapperRoute>} />
            <Route exact path="/reporting/create" render={() => <WrapperRoute name={'createReport'} component={CreateReport} ></WrapperRoute>} />
            <Route exact path="/reporting/standar" render={() => <WrapperRoute name={'createStandarReport'} component={CreateStandarReport} ></WrapperRoute>} />
            <Route exact path="/warehousing" render={() => <WrapperRoute name={'warehousing'} component={Warehousing} ></WrapperRoute>} />
            <Route exact path="/transactions" render={() => <WrapperRoute name={'transactions'} component={Transactions} ></WrapperRoute>} />
            <Route exact path="/inventoryIn" render={() => <WrapperRoute name={'inventoryIn'} component={InventoryIn} ></WrapperRoute>} />
            <Route exact path="/InventoryOut" render={() => <WrapperRoute name={'InventoryOut'} component={InventoryOut} ></WrapperRoute>} />
            <Route exact path="/inventory" render={() => <WrapperRoute name={'inventory'} component={Inventory} ></WrapperRoute>} />
            <Route exact path="/rates" render={() => <WrapperRoute name={'rates'} component={Rates} ></WrapperRoute>} />
            <Route exact path="/cost-management" render={() => <WrapperRoute name={'invoice'} component={Invoice} ></WrapperRoute>} />
            <Route exact path="/cost-purchasing" render={() => <WrapperRoute name={'purchase'} component={Purchase} ></WrapperRoute>} />
            <Route exact path="/cost-summary" render={() => <WrapperRoute name={'summary'} component={Summary} ></WrapperRoute>} />
            <Route exact path="/customer-service" render={() => <WrapperRoute name={'customer_service'} component={CustomerService} ></WrapperRoute>} />
            <Route exact path="/admin" render={() => <WrapperRoute name={'admin'} component={Admin} ></WrapperRoute>} />
            <Route exact path="/alerts" render={() => <WrapperRoute name={'alerts'} component={Alerts} ></WrapperRoute>} />
            <Route exact path="/procedures" render={() => <WrapperRoute name={'procedures'} component={Procedures} ></WrapperRoute>} />
            <Route exact path="/statistics" render={() => <WrapperRoute name={'audit'} component={Audit} ></WrapperRoute>} />
            <Route exact path="/weekly-planning" render={() => <WrapperRoute name={'weeklyPlanning'} component={WeeklyPlanning} ></WrapperRoute>} />
            <Route exact path="/calendar-view" render={() => <WrapperRoute name={'calendarView'} component={CalendarView} ></WrapperRoute>} />
            <Route exact path="/capacity-set-up" render={() => <WrapperRoute name={'capacity_set_up'} component={CapacitySetUp} ></WrapperRoute>} />
            <Route exact path="/multiple-update" render={() => <WrapperRoute name={'multiple_update'} component={MultiUpdate} ></WrapperRoute>} />
            <Route exact path="/container-association" render={() => <WrapperRoute name={'booking_list'} component={ContainerAssociation} ></WrapperRoute>} />
            <Route exact path="/anagrafico" render={() => <WrapperRoute name={'anagrafico'} component={Anagrafico} ></WrapperRoute>} />
            <Route exact path="/multiple-update-file" render={() => <WrapperRoute name={'multiple-update'} component={MultipleUpdate} ></WrapperRoute>} />
            <Route exact path="/visual-analytics" render={() => <WrapperRoute name={'visual-analytics'} component={Gerencial} ></WrapperRoute>}></Route>
            <Route exact path="/New-Build" render={() => <WrapperRoute name={'New-Build'} component={NewBuild} ></WrapperRoute>}></Route>
            <Route exact path="/vendor-management" render={() => <WrapperRoute name={'vendor-management'} component={VendorManagement} ></WrapperRoute>}></Route>

            {/* Routes with params */}
            <Route exact path="/order-edit/:POId" render={() => <WrapperRoute name={'order_edit'} component={PODraft} ></WrapperRoute>} />
            <Route exact path="/po-confirmation/:POId" render={() => <WrapperRoute name={'po_confirmation'} component={POConfirmationLogged} ></WrapperRoute>} />
            <Route exact path="/tracking/:id" render={() => <WrapperRoute name={'tracking'} component={Tracking} ></WrapperRoute>} />
            <Route exact path="/poconfirmation/:POId" render={() => <WrapperRoute name={'po_confirmation'} component={POConfirmation} ></WrapperRoute>} />
            <Route exact path="/search/:id" render={() => <WrapperRoute name={'quick_search'} component={QuickSearch} ></WrapperRoute>} />
            <Route exact path="/booking-confirmation/:id" render={() => <WrapperRoute name={'booking_confirmation'} component={BookingConfirmation} ></WrapperRoute>} />
            <Route exact path="/po-detail-view/:id" render={() => <WrapperRoute name={'po_detail'} component={POViewDetail} ></WrapperRoute>} />
            <Route exact path="/container-detail/:id" render={() => <WrapperRoute name={'containerDetail'} component={ContainerDetail} ></WrapperRoute>} />
            <Route exact path="/cargo-readiness-confirmation/:POId" render={() => <WrapperRoute name={'cargo_readiness'} component={CargoReadinessConfirmation} ></WrapperRoute>} />
            <Route exact path="/weekly-planning/:id" render={() => <WrapperRoute name={'weeklyPlanningDetail'} component={WeeklyPlanningDetail} ></WrapperRoute>} />
            <Route exact path="/reporting/edit/:id" render={() => <WrapperRoute name={'editReport'} component={EditReport} ></WrapperRoute>} />
            <Route exact path="/reporting/standard/edit/:id" render={() => <WrapperRoute name={'editStandardReport'} component={EditStandarReport} ></WrapperRoute>} />
            <Route exact path="/receipts-detail/:id" render={() => <WrapperRoute name={'receiptsDetail'} component={ReceiptsDetail} ></WrapperRoute>} />
            <Route exact path="/shipments-detail/:id" render={() => <WrapperRoute name={'shipmentDetail'} component={ShipmentDetail} ></WrapperRoute>} />
            <Route exact path="/trucking-detail/:id" render={() => <WrapperRoute name={'detailsTrucking'} component={DetailsTrucking} ></WrapperRoute>} />
            <Route exact path="/visibility-detail-po/:id/:line" render={() => <WrapperRoute name={'visibility-detail-po'} component={visibilityDetailPO} ></WrapperRoute>}></Route>
            <Route exact path="/visibility-detail-ms/:idms/:idpo/:idline" render={() => <WrapperRoute name={'visibility-detail-ms'} component={visibilityDetailMS} ></WrapperRoute>}></Route>
            <Route exact path="/vendor-detail/:id" render={() => <WrapperRoute name={'vendorDetail'} component={vendor_detail} ></WrapperRoute>} />
            <Route exact path="/recoverpassword/:token" component={RecoverPasswors}></Route>
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}
export default App;
