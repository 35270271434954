import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

/*=============================================
=            Card Toptals Conponent           =
=============================================*/
function CardTotals(props) {
  return (
    <Col title={props.messageTootip}  xl={props.xl} md={props.md} sm={props.sm}>
      <div style={{
        borderRadius: '1.5rem',
      }} className="shadow card flex-row align-items-center align-items-stretch border-0">
        {props.loading ?
          <Skeleton height={80} width="30%" />
          :
          <div className={props.propertiesIcon}>
            {props.image}
          </div>

        }
        {props.loading ?
          <Skeleton height={80} width="70%" className="margin_left" /> :
          <div className={props.propertiesTitle}>
            <div style={{ fontSize: '12px', marginTop:'5px' }} className="">{props.title}</div>
            <div style={{ fontSize: '40px', fontWeight: 'bold', marginBottom: '11px' }} className="h2 mt-0">{props.total}</div>
          </div>
        }
      </div>
    </Col>
  );
}

CardTotals.defaultProps = {
  xl: 3,
  md: 6,
  sm: 12,
};

CardTotals.propTypes = {
  xl: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  propertiesIcon: PropTypes.string,
  image: PropTypes.element,
  propertiesTitle: PropTypes.string,
  total: PropTypes.number,
  title: PropTypes.string,
};

export default CardTotals;
