import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Search, Pagination, Popup } from "semantic-ui-react";
import Axios from "axios";
import { saveAs } from "file-saver";
import imgFilter from '../../../../resources/filter.png'
import { Services } from "../Services";

const moment = require('moment')

function Cards(params) {
	// console.log('params-->', params);
	const [readyCard, setReadyCard] = useState([])
	const [dataCard, setDataCard] = useState([])
	// const [loaderExcel, setLoaderExcel] = useState(false)
	const [config, setConfig] = useState([])
	// const [activePage, setActivePage] = useState(0)
	// const [totalPages, setTotalPages] = useState(0)
	// const [itemsForPage, setItemsForPage] = useState(5)
	// const [activeFilter, setActiveFilter] = useState(false)
	// const [newDataFilter, setNewDataFilter] = useState([])

	function buildCards(data) {
		let cardResult = data.map((item, index) => {
			return (
				// <Card className={'myCard card-border-new-build'}>
				<Card className={'myCard'}>
					<CardBody>
						<center>
							<Row>
								<Col>
									<div>
										<b>Po.</b>
									</div>
									{/* <Link style={{ textDecoration: 'none' }} to={'/visibility-detail-po/' + item.PONewBuildId + `/${0}`} > */}
									<Link style={{ textDecoration: 'none' }} >
										<div>
											<span style={{ fontSize: '12.5px', color: '#13B0F0', fontWeight: 'bold' }}
												onClick={() => { params.getPONewBuildId(item.PONewBuildId, '0') }}
											>{item.PONo}</span>
										</div>
									</Link>
								</Col>
								<Col xs={2} >
									<div >
										<b>Status</b>
									</div>
									<div>
										<Button className={'btn-oval'} color={'primary'} >{item.Status}</Button>
										{/* <Badge style={{ backgroundColor: '#5D9CEC', padding: '0.4em' }}>
											&nbsp;&nbsp;&nbsp;&nbsp;{item.Status}&nbsp;&nbsp;&nbsp;&nbsp;
										</Badge> */}

									</div>
								</Col>
								<Col >
									<div>
										<b>Supplier</b>
									</div>
									<div>
										{item.Supplier}
									</div>
								</Col>
								<Col >
									<div>
										<b>Project</b>
									</div>
									<div>
										{item.NewBuild}
									</div>
								</Col>
								<Col >
									<div>
										<b>Department</b>
									</div>
									<div>
										{item.Department}
									</div>
								</Col>
								<Col >
									<div>
										<b>Container no.</b>
									</div>
									<div>
										<Popup
											position='bottom center'
											content={item.Containers ? item.Containers : 'No Information'}
											trigger={
												<div >
													{`${item.Containers ? item.Containers.substring(0, 35) : ''}...`}
												</div>
											} />

									</div>
								</Col>
								<Col >
									<div>
										<b>Buyer</b>
									</div>
									<div>
										{item.Buyer}
									</div>
								</Col>
								<Col >
									<div>
										<b>Value</b>
									</div>
									<div>
										{new Intl.NumberFormat('en-US').format(item.Value)}
									</div>
								</Col>
								<Col >
									<div>
										<b>Creation</b>
									</div>
									<div>
										{item.CreationDate}
									</div>
								</Col>
							</Row>
						</center>
					</CardBody>
				</Card >
			)
			// })
		})
		setReadyCard(cardResult)
	}

	// const generateExcel = useCallback(async () => {
	// 	setLoaderExcel(true)
	// 	let headers = []
	// 	let keys = []
	// 	config.map(e => {
	// 		if (e.xls) {
	// 			headers.push(e.title)
	// 			keys.push(e.id)
	// 		}
	// 		return e
	// 	})

	// 	let dataxls = { data: dataCard, options: { headers: headers, keys: keys, image: { name: 'poseidon' } } }

	// 	const url = `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`;
	// 	try {
	// 		const getXlsxFile = await Axios.post(url, dataxls);
	// 		const fileName = `New Build-${moment(new Date()).format('DD-MM-YYYY')}.xlsx`
	// 		const linkSource = "data:application/xlsx;base64," + getXlsxFile.data.base64;
	// 		saveAs(linkSource, fileName);
	// 		setLoaderExcel(false)
	// 	} catch (error) {
	// 		console.log('Error dowload file xls-->', error.message);
	// 		setLoaderExcel(false)
	// 	}
	// }, [dataCard, config])

	// const handleChange = useCallback(async (data, datax) => {
	// 	let dataSearch = datax ? datax : dataCard
	// 	let results = [];
	// 	// setActiveFilter(true)
	// 	params.setValueSearch(data)

	// 	// let greaterTen = dataSearch.filter(item => !item.Supplier.toUpperCase().indexOf(data.value.toUpperCase()) || !item.Status.toUpperCase().indexOf(data.value.toUpperCase()) || !item.PONo.toUpperCase().indexOf(data.value.toUpperCase()));
	// 	// console.log("🚀 ~ file: Card.js ~ line 156 ~ handleChange ~ greaterTen", greaterTen)
	// 	// // console.log(greaterTen);

	// 	dataSearch.forEach(e => {
	// 		let PONo = e.PONo ? e.PONo.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Status = e.Status ? e.Status.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Supplier = e.Supplier ? e.Supplier.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let NewBuild = e.NewBuild ? e.NewBuild.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Department = e.Department ? e.Department.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Containers = e.Containers ? e.Containers.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Buyer = e.Buyer ? e.Buyer.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Value = e.Value ? e.Value.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let CreationDate = e.CreationDate ? e.CreationDate.toUpperCase().indexOf(data.toUpperCase()) : -1;

	// 		if (PONo >= 0 || Status >= 0 || Supplier >= 0 || NewBuild >= 0 || Department >= 0 || Containers >= 0 || Buyer >= 0 || Value >= 0 || CreationDate >= 0) {
	// 			results.push(e);
	// 		}
	// 	})
	// 	// setDataCard(results)
	// 	console.log('results-->', results.length);
	// 	console.log('Pagina Activa-->', params.activePage);
	// 	setNewDataFilter(results)
	// 	// params.setActivePage(1)
	// 	initPages(results, params.activePage)


	// 	// let results = searchBy(data, dataCard);

	// }, [dataCard, params])

	// const changePage = useCallback(async (page) => {
	// 	// setActivePage(page)
	// 	params.setActivePage(page)
	// 	buildPageResults(page, dataCard, totalPages)
	// }, [dataCard, totalPages])

	// const buildPageResults = useCallback(async (pag, dataCard, total) => {
	// 	let data = setResultsByPage(pag, dataCard, total, itemsForPage);
	// 	if (data) {
	// 		buildCards(data)
	// 	}

	// }, [itemsForPage])

	// function setResultsByPage(page, allData, pages, itemsByPage) {
	// 	let array = [];
	// 	if (page === 1)
	// 		for (let i = 0; i < (allData.length < itemsByPage ? allData.length : itemsByPage); i++)
	// 			array.push(allData[i]);
	// 	else if (page !== 1 && page !== pages)
	// 		for (let i = (page - 1) * itemsByPage; i < (page) * itemsByPage; i++)
	// 			array.push(allData[i]);
	// 	else
	// 		for (let i = (page - 1) * itemsByPage; i < allData.length; i++)
	// 			array.push(allData[i]);
	// 	return array;
	// }

	// function initPages(data, pagueActive) {
	// 	let pages = parseInt(Math.ceil(data.length / itemsForPage));
	// 	if (pages === 0) {
	// 		pages = 1
	// 	}
	// 	// setActivePage(1)
	// 	setTotalPages(pages)

	// 	buildPageResults(pagueActive, data, pages)
	// }

	useEffect(() => {
		buildCards(params.data)
		// if (!activeFilter) {
		// 	console.log('entre aqui---> 1');

		// 	setDataCard(params.data)
		// 	setConfig(params.config)
		// 	initPages(params.data, params.activePage)
		// }

		// if (Boolean(!params.valueSearch)) {
		// 	console.log('entre aqui---> 1');

		// 	setDataCard(params.data)
		// 	setConfig(params.config)
		// 	initPages(params.data, params.activePage)
		// }

		// if (Boolean(params.valueSearch)) {
		// 	console.log('entre aqui---> 2', newDataFilter.length);
		// 	setDataCard(params.data)
		// 	handleChange(params.valueSearch, params.data)
		// 	// initPages(newDataFilter, params.activePage)
		// }

	}, [params])

	return (
		<div className="content">
			{/* <CardBody> */}
			<Row>
				<Col xs={10} md={4} lg={4} className='p-0'>
					<Search
						// icon
						// open={false}
						input={{ fluid: true, iconPosition: 'left' }}
						// onSearchChange={this.handleChange("search")}
						onSearchChange={(evt, data) => { params.setValueSearchPO(data.value.trim()) }}
						onKeyPress={event => {
							if (event.key === 'Enter') {
								// console.log('params.valueSearch', Boolean(params.valueSearch), !!params.valueSearch);
								params.handleChangeSearch(params.valueSearch)
							}
						}}
						open={false}
						placeholder="Search ..."
						value={params.valueSearch}
					/>
				</Col>
				<Col xs={10} md={4} lg={4} className='d-flex align-items-center'>
					<Row>
						{/* <div> */}
						{/* <Col className='d-flex align-items-center pl-2 pr-2'>
							<Popup
								inverted
								content={'Search PO'}
								trigger={
									<i className="fas fa-search fa-2x" style={{ color: '#818181', cursor: 'pointer' }} onClick={() => { params.handleChangeSearch(params.valueSearch) }}></i>
								} />
						</Col> */}
						<Col className='d-flex align-items-center pl-1 pr-1'>
							<Popup
								inverted
								content={'Filters'}
								trigger={
									<img
										// onClick={() => {
										// 	if (this.state.advancedSearchData.length > 1) {
										// 		this.setState({ isOpen: true });
										// 	}
										// }}
										id="toggler"
										alt="img"
										width={26}
										height={26}
										src={imgFilter}
										style={{ cursor: 'pointer' }}
									></img>
								} />
						</Col>
						<Col className='d-flex align-items-center pl-2 pr-1'>
							<Popup
								inverted
								content={'Download List'}
								trigger={
									<i className={params.loaderExcel ? `fas fa-spinner fa-pulse fa-2x` : `fas fa-file-excel fa-2x`} style={{ color: '#487629', cursor: 'pointer' }} onClick={params.generateExcel} />
								} />
						</Col>
					</Row>
				</Col>
				{/* <Col xs={10} md={4} lg={4} className='d-flex justify-content-end align-items-center'>
					<Warning />
					<span className="ml-2 mr-2"> show delayed</span>
					<Checkbox toggle />
				</Col> */}
			</Row>
			{/* <Col xs={10} md={12} lg={12}> */}
			<Row>
				<CardBody>
					<params.Filters dataForm={params.form} funtionFilter={params.funtionFilter} Catalogs={params.Catalogs} />
				</CardBody>
			</Row>
			{readyCard}
			<Col md={12}>
				<center>
					<Pagination
						className='pagination-table'
						activePage={params.activePage}
						ellipsisItem={null}
						totalPages={params.totalPages}
						onPageChange={(e, { activePage }) => params.changePage(activePage)}
					/>
				</center>
			</Col>
		</div>
	)
}

export default withRouter(Cards)