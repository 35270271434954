import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Row } from 'react-flexbox-grid';
import Fade from 'react-reveal/Fade';
import './styles/main.scss';
import { Pagination } from 'semantic-ui-react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      rows: [],
      columnsTable: [],
      subTable: [],
      totalInvoices: 0,
      totalInvoicesAmount: 0,
      pages: 0,
      activePage: 0,
      tableData: [],
      number: 10,
      ___columns: [],
      ___rows: [],
      ___colap: [],
      open: false,
      sorts: {},
      randomKey: 0,
    };
  }

  componentDidMount = () => {
    let { data, columnsTable, subTable } = this.props;
    this.setState({
      data,
      columnsTable, subTable
    }, () => {
      let pages = (data.length / 10) + 1;
      let tableData = this.partirArray(1, data, pages);
      this.setState({
        activePage: 1,
        pages: parseInt(pages),
        tableData
      }, () => {
        this.buildRows();
      });
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.data,
      columnsTable: nextProps.columnsTable,
      subTable: nextProps.subTable,
    }, () => {
      let newpages = [];
      nextProps.data.map((e) => {
        if (!e.hidden) {
          newpages.push(e);
        }
      });
      let pages = newpages.length > 10 ? (newpages.length / 10) + 1 : 1;
      let tableData = this.partirArray(1, nextProps.data, pages);
      this.setState({
        activePage: 1,
        pages: parseInt(pages),
        tableData
      }, () => {
        this.buildRows();
      });
    });
  }

  partirArray = (page, all, pages) => {
    let hiddtable  = [];
    all.map((e)=>{
      if (!e.hidden){
        hiddtable.push(e);
      }
    });
    let array = [];
    if (page === 1)
      for (let i = 0; i < (hiddtable.length < this.state.number ? hiddtable.length : this.state.number); i++)
        array.push(hiddtable[i]);
    else if (page !== 1 && page !== pages)
      for (let i = (page - 1) * 10; i < (page) * 10; i++)
        array.push(hiddtable[i]);
    else
      for (let i = (page - 1) * 10; i < hiddtable.length; i++)
        array.push(hiddtable[i]);
    return array;
  }

  handlePaginationChange = (e, { activePage }) => {
    let { data, pages } = this.state;
    let tableData = this.partirArray(activePage, data, pages);
    this.setState({ activePage, tableData }, () => { this.buildRows(); });
  }

  _sortBy = id => event => {
    let { data, sorts } = this.state;
    if (sorts[id]) {
      let { sortBy } = sorts[id];
      let formatedData = this.makeSort(data, id, sortBy);
      sorts[id].sortBy = sorts[id].sortBy === 'ASC' ? 'DESC' : 'ASC';
      this.setState({
        sorts,
        sortKey: id,
        sortBy: sorts[id].sortBy
      }, () => {
        let tableData = this.partirArray(this.state.activePage, formatedData, this.state.pages);
        this.setState({ tableData }, () => this.buildRows());
      });
    }
  }

  makeSort = (items, key, by) => {
    switch (by) {
    case 'ASC': return items.sort(function (a, b) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });
    case 'DESC':
      return items.sort(function (a, b) {
        if (a[key] < b[key]) {
          return 1;
        }
        if (a[key] > b[key]) {
          return -1;
        }
        return 0;
      });
    default: return items;
    }
  }

  buildColumns = () => {
    let ___columns = [];
    let { sortKey, sortBy } = this.state;
    this.state.columnsTable.forEach((e, index) => {
      let column = e.label;
      if (e.key === sortKey) {
        ___columns.push(sortBy === 'DESC' ?
          <th key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)} ><Fade left opposite ><em className="icon-arrow-down mr-2"></em></Fade>{column}</th>
          : <th key={index} id={e.idTh ? e.idTh : index} onClick={''}><Fade left opposite ><em className="icon-arrow-up mr-2"></em></Fade>{column}</th>
        );
      }
      else {
        // if (e.key === 'addAll'){
        //   ___columns.push(
        //     <th key={index} id={e.idTh ? e.idTh : index}  > <span style={{ color: '#8c8c8c', fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} >Agregar Todos <em class="fa-2x mr-2 fas fa-plus-circle"  onClick={ () => console.log("esto es para el all data")} style={{marginTop: "33px", color: "rgb(0, 121, 184)", marginTop : '5px'}} ></em></span></th>
        //   );
        // }
        // else {
        ___columns.push(
          <th style={{ textAlign: 'left' }} key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)} > <span style={{ color: '#8c8c8c', fontSize: '12px', cursor: 'pointer' }} >{column}</span></th>
        );
        // }
      }
    });
    this.setState({ ___columns });
  }

  builsub = ( all) => {
    let {subTable} = this.state;
    let subrows= all.map((w,index)=>{
      let subtds = [];
      subTable.forEach((subcolumn, __index_) => {
        if (subcolumn.separator){
          subtds.push(
            <td key={__index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={ __index_} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}> {w[subcolumn.key] ?  (Number(w[subcolumn.key]).toLocaleString('En-us')) : ''}</td>
          );
        }
        else{
          subtds.push(
            <td key={__index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={ __index_} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}>{w[subcolumn.key] ? w[subcolumn.key] : ''}</td>
          );
        }
      });
      return (<>
             <tr key={index}>
               {subtds}
             </tr>
            </>);
            
    });
    return subrows;
  }

  buildRows = () => {
    let { columnsTable, tableData, subTable } = this.state;
    let ___rows = tableData.map((e, _index) => {
      let tds = [];
      if (e.hidden) {
        return (<></>);
      }
      else {
        if (e) {
          columnsTable.forEach((column, index_) => {
            if (column.custom) {
              tds.push(
                <td key={index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}>{column.custom(e)}</td>
              );
            }
            else if (column.index) {
              tds.push(
                <td key={index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}>
                  {_index + 1}
                </td>
              );
            }
            else if (column.shiwchColor) {
              tds.push(
                <td key={index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'left', minWidth: '150px', maxWidth: '100px', color: e[column.key] === 'Paid' ? '#35c677' : 'red' }}>{e[column.key] ? e[column.key] : ''}</td>
              );
            }
            else if (column.CollapseTable) {
              tds.push(
                <td key={index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}>
                  <Row>
                    {column.flatColor ?
                      <div className='col-md-3' >
                        <center>
                          {/* <em style={{ color: data[e.id] === 'EXPO' ? '#e5e5e5' : (data[e.id] === 'IMPO' ? '#35c677' : '#4ab0ef') }} className="fa-1x mr-1 fas fa-circle"></em> */}
                          <em style={{ color: e[column.keydatavalidate] === 'Paid' ? '#35c677' : 'red', marginTop: '10px' }} className="fa-1x mr-1 fas fa-circle"></em>
                        </center>
                      </div>
                      :
                      <></>
                    }
                    <div className='col-md-3' >
                      <IconButton aria-label="expand row" size="small" onClick={(index_) => {
                        if (tableData[_index].collapse) {
                          tableData[_index].collapse = false;
                        } else {
                          tableData[_index].collapse = true;
                        }
                        this.setState({ tableData, randomKey: Math.random() }, () => { this.buildRows(); this.forceUpdate(); });
                      }}>
                        {e.collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </div>
                  </Row>
                </td>
              );
            }
            else if (column.separator) {
              tds.push(
                <td key={index_} className={this.state.styletd === true ? "table-td2" : "table-td"} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}>{e[column.key] ?  (Number(e[column.key]).toLocaleString('En-us')) : ''}</td>
              );
            }
            else {
              tds.push(
                <td key={index_} className={this.state.styletd === true ? 'table-td2' : 'table-td'} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'left', minWidth: '150px', maxWidth: '100px' }}>{e[column.key] ? e[column.key] : ''}</td>
              );
            }
          });
        }
      }
      return (
        <>
          <tr key={_index}>
            {tds}
          </tr>
          {e.collapse ? (
            <tr>
              <td colspan={'1'}></td>
              <td colspan={'4'}>
                <Box margin={0}>
                  {/* <Typography style={{textAlign:'center'}} variant="h6" gutterBottom component="div">
                  History
                </Typography> */}
                  <Table striped hover responsive>
                    <thead className="">
                      <tr>
                        <th style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>Code</th>
                        <th style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>Concept</th>
                        <th style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>Currency</th>
                        <th style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.builsub(e.sub)}
                    </tbody>
                  </Table>
                </Box>
              </td>
            </tr>
          ) : <></>}
        </>
      );
    });
    this.setState({ ___rows }, () => this.buildColumns());
  }

  buildHeaders = () => {
    let { data } = this.state;
    let totalInvoices = Array.isArray(data) ? data.length : 0;
    let totalInvoicesAmount = 0;
    data.forEach((e) => {
      totalInvoicesAmount += parseInt(e.invoiceAmount);
    });
    totalInvoicesAmount = '$' + totalInvoicesAmount.toLocaleString('en-US');
    this.setState({
      totalInvoices,
      totalInvoicesAmount
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            {/* <legend>Invoice List</legend> */}
          </div>
          <div className="col-12">
            <Table striped hover responsive key={this.state.randomKey}>
              <thead className="titleTable">
                <tr>
                  {this.state.___columns}
                </tr>
              </thead>
              <tbody>
                {this.state.___rows}
              </tbody>
            </Table>
          </div>
          <div className="col-12">
            <center><Pagination
              ellipsisItem={null}
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={this.state.pages}>
            </Pagination></center>
          </div>
          <div className="col-12">
            <hr></hr>
          </div>
        </div>
      </>
    );
  }
}
export default DataTable;
