
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row, Button } from "reactstrap";
import Stepper from "../components/Stepper";


function Production(props) {
	const [loader, setLoader] = useState(false)

	function changueLoader(params) {
		setTimeout(() => {
			setLoader(true)
		}, 3000);
	}

	// function handleChange(val, data) {
	// 	let ModelSend = { ...props.Model };
	// 	let value = val;
	// 	let id = data.id

	// 	console.log('value-->', value);
	// 	console.log('data-->', id);

	// 	console.log('ModelSend-->', ModelSend[id]);
	// 	ModelSend[id].value = value

	// }

	useEffect(() => {
	}, [])

	return (
		<>
			<Row>
				<Col xs={12} md={4} lg={4} className='ml-auto' >
					<Row>
						<Col xs={12} md={7} lg={7}>
							{props.shipmentLine.name}
						</Col >
						<Col className={'d-flex align-items-center'} xs={12} md={5} lg={5}>
							{/* <Badge style={{ background: '#5D9CEC', padding: '0.4em' }} pill>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.shipmentLine.status}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Badge> */}
							<Button className={'btn-oval'} color={'primary'} >{props.shipmentLine.status}</Button>
						</Col>
					</Row>
				</Col>
				<Col xs={12} md={8} lg={8} className='ml-auto'>
					<Stepper steps={props.steps} />
				</Col>
				<Col xs={12} md={12}>
					<Row>
						<props.FormCreator dataForm={props.dataForm} Model={props.Model} onChange={props.onChange} TabName={props.TabName} Catalog={props.Catalogs} />
					</Row>
				</Col>
			</Row>
		</>
	);
}

export default withRouter(Production);

