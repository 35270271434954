import React from 'react';
import propTypes from 'prop-types';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { CardBody, Col, Row } from 'reactstrap';
import NewComment from './../NewComment/';
import Axios from 'axios';
// import {TrashIcon} from './../../config';
import DelComment from './../DeleteComment/';
import './styles.scss';
import io from 'socket.io-client';
import { Services } from '../../../Services';



let socket;
/*=============================================
=            Comments Component               =
=============================================*/
class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _rolId:this.props._rolId,
      comments: [],
      POId: this.props.POId ? this.props.POId : 0,
      isSearchComments: this.props.isSearchComments ? true : false
    };
  }
  getComments = async () => {
    try {
      let success = await Axios.get(`${Services.GET_COMMENTS_BY_ROW.path}/${this.state.POId}?user=${this.props._user}`);
      // let success = await Axios.get(`http://localhost:3005/${this.state.POId}?user=${this.props._user}`);
      if (success.data && success.data.data) {
        this.setState({ comments: success.data.data });
        if (success.data.reset) {
          await Axios.put(
            `${Services.GET_COMMENTS_BY_ROW.path}/read`
            // 'http://localhost:3005/read'
            , {
              po_id: this.state.POId,
              user_id: this.props._user,
              user_rol: this.props._rolId
            });
          const END_POINT = `${Services.SOCKET_SERVICE_COMMENTS.path}`;
          socket = io(END_POINT);
          socket.emit('reset_comments', { user: this.props._user, customer: this.props._customer });
          socket.emit('disconnect');
          socket.off();

          this.props.resetTable();
        }

      }
    } catch (error) {
      console.error('Error loading comments', error);
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.POId && newProps.isSearchComments) this.setState({ POId: newProps.POId }, () => this.getComments());
  }

  render() {
    const { comments, _rolId } = this.state;
    return (
      <>
        <Col xs={12} md={12} lg={12}>
          <Timeline lineColor={'#fff'}>
            {
              comments.map((e) => {
                return (
                  <TimelineItem
                    key={e.CommentId}
                    dateText={`${e.Date}`}
                    dateInnerStyle={{ background: `${e.Color}`, fontWeight: 'bold', fontSize: '11px' }}
                  >
                    <Col className='_card_comments' md={12}>
                      <Row>
                        <div className="col-12">
                          <span className='comment_type'>{e.ComType}</span>
                          {
                            e.NewComment ?
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" className={'svg-inline--fa fa-circle fa-w-16 text-success new-comment'} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                              </svg> : ''
                          }
                        </div>
                        <div className="col-12">
                          <span className='comment_content'>{e.Comment}</span>
                        </div>
                        <Col md={12} className="footer_comments">
                          <div className="user_footer">
                            {/* <TrashIcon/> */}
                            {
                              // this.props._rolId === '1' && e.UserId+'' === this.props._user ?
                              e.UserId + '' === this.props._user ?
                                <DelComment _user={this.props._user} POId={this.state.POId} CommentId={e.CommentId} resetComments={this.getComments} />
                                : ''
                            }
                            {e.User ? e.User : ''}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </TimelineItem>
                );
              })
            }
          </Timeline>
        </Col>
        <Col xs={12} md={12} lg={12} className='mt-auto'>
          <NewComment POId={this.state.POId} _customer={this.props._customer} _user={this.props._user} _rolId={_rolId} resetComments={this.getComments} />
        </Col>

      </>
    );
  }
}

Comments.propTypes = {
  POId: propTypes.number,
  _customer: propTypes.string,
  _user: propTypes.string,
  _rolId: propTypes.string,
  isSearchComments: propTypes.bool,
  resetTable: propTypes.func
};

Comments.defaultProps = {
  resetTable: () => { },
  _customer: ''
};


export default Comments;