import React from "react";
import { Tab, Select } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Row, Card, CardBody, CardText, Col } from "reactstrap";
import Axios from "axios";
import Chart from "react-google-charts";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import Summary from "./components/summary";
import Details from "./components/details";
import { Cards } from "./components/cards";
import Values from "./config/graphValues";
import { Graph } from "./config/graphConfig";
import { Services } from "./Services";
import { validateIdParams } from "../../utils/regularExpressionValidation";

import Authentication from "../../../services/Authentication";
import FlotChart from "../../template/Common/Flot";

const auth = new Authentication();

function Audit() {
  let [data, setData] = React.useState([]);
  let [graphics, setGraphics] = React.useState([]);
  let [customers, setCustomers] = React.useState([]);
  let [customerSelect, setCustomerSelect] = React.useState([]);
  let [value, setValue] = React.useState(0);

  async function changeUser(e, data) {
    try {
      setValue(data.value);
      getDataGraphs(data.value);
      getCardList(data.value);
    } catch (error) {
      console.error("ERROR", error);
    }
  }

  const getCardList = React.useCallback(async (customer) => {
    let idValidate = validateIdParams(customer);
    if (!idValidate) {
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/statistics");
      }, 2000);
      return;
    }
    try {
      let success = await Axios.get(
        `${Services.STATISTICS.path}/statistics/cards?id=${customer}`
      );
      Values.Cards.map((e) => {
        if (success.data.data[e.aka]) {
          e.value = success.data.data[e.aka];
        }
        return e;
      });
      setData(Values.Cards);
    } catch (error) {
      console.error("getCardList ==>", error);
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/statistics");
      }, 2000);
      return;
    }
  }, []);

  const getDataGraphs = React.useCallback(async (customer) => {
    try {
      let user = auth.getAuthentication("dataUser");
      let idValidate = validateIdParams(customer);
      let idUserValidate = validateIdParams(user);
      if (!idValidate && idUserValidate) {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/statistics");
        }, 2000);
        return;
      }
      let dataGrap = await Axios.get(
        `${Services.STATISTICS.path}/statistics/graphs?id=${customer}&user=${user}`
      );

      let graphs = Graph(dataGrap.data.data);
      setGraphics(graphs);
    } catch (error) {
      console.error(error);
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/statistics");
      }, 2000);
      return;
    }
  }, []);

  const getCustomers = React.useCallback(async () => {
    try {
      let id = auth.getAuthentication("dataUser");
      let user = auth.getAuthentication("customerSelect");
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/statistics");
        }, 2000);
        return;
      }
      let clientes = await Axios.get(
        `${Services.ADMIN.path}/admin/customerForId/${id}`
      );

      let employe = [];
      let selectCust;

      clientes.data.resp.map((element) => {
        if (parseInt(user) === element.CustomerId) {
          selectCust = "ALL CUSTOMERS";
        }
        employe.push({
          key: element.CustomerCode,
          value: element.CustomerId,
          text: element.CustomerName,
        });
      });

      employe.push({ key: 0, value: 0, text: "ALL CUSTOMERS" });

      setCustomerSelect(selectCust);
      setCustomers(employe);
    } catch (error) {
      console.error(error);
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/statistics");
      }, 2000);
      return;
    }
  }, []);

  React.useEffect(() => {
    getCustomers();
    getCardList(value);
    getDataGraphs(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCardList, getDataGraphs, getCustomers]);

  return (
    <ContentWrapper>
      <div>
        <div className="content-heading">
          <div className="row">
            <em className="fa-1x mr-2 fas fa-cogs"></em>
            Statistics
          </div>
        </div>
      </div>

      <Col>
        <Select
          placeholder={customerSelect.toString()}
          options={customers}
          onChange={changeUser}
        />
        <br />
        <br />
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="row">
            <Col md={12}>
              <Row>
                {graphics.map((e, index) => {
                  let typeGraph =
                    e.type === "pie" ? (
                      <FlotChart
                        data={e.data}
                        options={e.options}
                        height="78%"
                      />
                    ) : (
                      <Chart
                        className={"tableChartG"}
                        width={"100%"}
                        height={"90%"}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={e.data}
                        options={{
                          chartArea: { width: "43%" },
                          bar: { groupWidth: "80%" },
                          colors: e.color,
                          animation: {
                            startup: true,
                            easing: "linear",
                            duration: 1000,
                          },
                        }}
                        rootProps={{ "data-testid": "6" }}
                      />
                    );

                  return (
                    <Col key={index} md={6} xl={6} sm={12} xs={12}>
                      <Card className="graphcardsize">
                        <CardBody className="graphcardbody">
                          <CardText
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              fontFamily: "unset",
                              fontWeight: "bold",
                            }}
                          >
                            {e.title}
                          </CardText>
                          {typeGraph}
                        </CardBody>
                      </Card>

                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {data[index] ? (
                          <Cards data={data[index]} element={index} />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </div>
        </div>
      </Col>

      <Tab
        loading="true"
        menu={{ secondary: true, className: "myWrapped" }}
        menuPosition="right"
        panes={[
          { menuItem: "Summary", render: () => <Summary customer={value} /> },
          { menuItem: "Detail", render: () => <Details customer={value} /> },
        ]}
      />
    </ContentWrapper>
  );
}

export default withRouter(Audit);
