import React, {Component} from 'react';
import { Button, Modal, Input, TransitionablePortal, Segment, Header } from 'semantic-ui-react';
import Select from 'react-select';
import axios from 'axios';
import 'react-select/dist/react-select.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Popover } from 'antd';
import './style.scss';

class AlertNotification extends Component {
  constructor(props){
    super(props);
    this.state = {
      columns: [
        {
          title: 'Name',
          id: 'Name',
          className: 'col-1'
        },
        {
          title: 'Configuration',
          id: 'Configuration',
          className: 'col-2'
        },
        {
          title: 'Description',
          id: 'Description',
          className: 'col-2'
        },
        {
          title: 'Type of Notification',
          id: 'TypeNoti',
          className: 'col-1'
        },
        {
          title: 'Periodicity',
          id: 'Periodicity',
          className: 'col-1'
        },
        {
          title: 'Time Interval (Start-End)',
          id: 'TimeInterval',
          className: 'col-3'
        },
        {
          title: 'Time Zone',
          id: 'TimeZone',
          className: 'col-2'
        }
      ],
      data: props.data,
      getAlertsCustomer: props.getAlertsCustomer,
      customer: props.customer,
      fromToValues: [
        {
          label: '09:00 AM',
          value: '9AM'
        },
        {
          label: '10:00 AM',
          value: '10AM'
        },
        {
          label: '11:00 AM',
          value: '11AM'
        },
        {
          label: '12:00 PM',
          value: '12PM'
        },
        {
          label: '01:00 PM',
          value: '1PM'
        },
        {
          label: '02:00 PM',
          value: '2PM'
        },
        {
          label: '03:00 PM',
          value: '3PM'
        },
        {
          label: '04:00 PM',
          value: '4PM'
        },
        {
          label: '05:00 PM',
          value: '5PM'
        },
        {
          label: '06:00 PM',
          value: '6PM'
        }
      ],
      intervalValues: [
        {
          label: 'Day(s)',
          value: 'Days'
        },
        {
          label: 'Weeks(s)',
          value: 'Weeks'
        }
      ],
      utcValues: [
        {
          label: 'UTC-12',
          value: 'UTC-12'
        },
        {
          label: 'UTC-11',
          value: 'UTC-11'
        },
        {
          label: 'UTC-10',
          value: 'UTC-10'
        },
        {
          label: 'UTC-9:30',
          value: 'UTC-930'
        },
        {
          label: 'UTC-9',
          value: 'UTC-9'
        },
        {
          label: 'UTC-8',
          value: 'UTC-8'
        },
        {
          label: 'UTC-7',
          value: 'UTC-7'
        },
        {
          label: 'UTC-6',
          value: 'UTC-6'
        },
        {
          label: 'UTC-5',
          value: 'UTC-5'
        },
        {
          label: 'UTC-4',
          value: 'UTC-4'
        },
        {
          label: 'UTC-3:30',
          value: 'UTC-330'
        },
        {
          label: 'UTC-3',
          value: 'UTC-3'
        },
        {
          label: 'UTC-2',
          value: 'UTC-2'
        },
        {
          label: 'UTC-1',
          value: 'UTC-1'
        },
        {
          label: 'UTC+0',
          value: 'UTC+0'
        },
        {
          label: 'UTC+1',
          value: 'UTC+1'
        },
        {
          label: 'UTC+2',
          value: 'UTC+2'
        },
        {
          label: 'UTC+3',
          value: 'UTC+3'
        },
        {
          label: 'UTC+3:30',
          value: 'UTC+330'
        },
        {
          label: 'UTC+4',
          value: 'UTC+4'
        },
        {
          label: 'UTC+4:30',
          value: 'UTC+430'
        },
        {
          label: 'UTC+5',
          value: 'UTC+5'
        },
        {
          label: 'UTC+5:30',
          value: 'UTC+530'
        },
        {
          label: 'UTC+5:45',
          value: 'UTC+545'
        },
        {
          label: 'UTC+6',
          value: 'UTC+6'
        },
        {
          label: 'UTC+6:30',
          value: 'UTC+630'
        },
        {
          label: 'UTC+7',
          value: 'UTC+7'
        },
        {
          label: 'UTC+8',
          value: 'UTC+8'
        },
        {
          label: 'UTC+8:45',
          value: 'UTC+845'
        },
        {
          label: 'UTC+9',
          value: 'UTC+9'
        },
        {
          label: 'UTC+10',
          value: 'UTC+10'
        },
        {
          label: 'UTC+10:30',
          value: 'UTC+1030'
        },
        {
          label: 'UTC+11',
          value: 'UTC+11'
        },
        {
          label: 'UTC+12',
          value: 'UTC+12'
        },
        {
          label: 'UTC+13',
          value: 'UTC+13'
        },
        {
          label: 'UTC+13:45',
          value: 'UTC+1345'
        },
        {
          label: 'UTC+14',
          value: 'UTC+14'
        }
      ],
      typeNotiValues: [
        {
          label: 'Real Time',
          value: 'RealTime'
        },
        {
          label: 'Consolidated',
          value: 'Consolidated'
        }
      ],
      periodicityValues: [
        {
          label: 'Every 1 Hour',
          value: '1Hour'
        },
        {
          label: 'Every 2 Hours',
          value: '2Hours'
        },
        {
          label: 'Every 4 Hours',
          value: '4Hours'
        },
        {
          label: 'Every 8 Hours',
          value: '8Hours'
        }
      ],
      divKey: 0,
      newAlert: {
        type: '',
        alertValues: [],
        configuration: {},
        description: '',
        valueSelect: undefined,
        TypeNoti: undefined,
        Periodicity: undefined,
      }
    };
  }

  componentDidMount = () => {
    axios.get('https://poseidonapialerts.azurewebsites.net/alerts/AlertsCatalog').then(success => {
      var catalogs;
      let newAlert = this.state.newAlert;
      if(this.props.type === '1'){
        catalogs = success.data.data.alerts;
      }
      else{
        catalogs = success.data.data.notifications;
      }
      let alertValues = [];
      let obj = {};
      catalogs.map(e =>{
        let pushAlert = {
          label: e.Name,
          value: e.AlertNotificationId
        };
        obj[e.AlertNotificationId] = e.Configuration;
        alertValues.push(pushAlert);
        return e;
      });
      newAlert.alertValues = alertValues;
      newAlert.configuration = obj;
      this.setState({newAlert: newAlert});
    }).catch(error => {
      console.error(error);
    });
  }

  componentWillReceiveProps = (nextProps) =>{
    this.setState({
      valueSelect: undefined,
      TypeNoti: undefined,
      Periodicity: undefined,
    });
    if(nextProps){
      this.setState(nextProps);
    }
  }

  deleteAlert = (id) =>{
    axios.delete(`https://poseidonapialerts.azurewebsites.net/alerts/AlertsCustomer/${id}`).then(success => {
      this.props.getAlertsCustomer();
    }).catch(error =>{

    });
    this.setState({openPortal: false});
  }

  renderTable = (e, f, index) =>{
    let { data, fromToValues, intervalValues, typeNotiValues, utcValues, divKey, periodicityValues, openPortal } = this.state;
    switch (f.id) {
    case 'TimeInterval':
      let isReal = false;
      // if(e.TypeNoti === 'RealTime'){
      //   isReal = true;
      // }
      // if(isReal){
        return(
          <div className="row">
            <div className="col-12">
                &nbsp;
            </div>
          </div>
        );
      // };
      // else{
      //   return(
      //     <div className="row">
      //       <div className="col-4">
      //         <Select
      //           className='_comboFilters'
      //           name='From'
      //           id={`From${index}`}
      //           placeholder='09:00AM'
      //           isClearable={false}
      //           clearable={false}
      //           isDisabled={!e.disabled}
      //           disabled={!e.disabled}
      //           value={e.TimeInterval ? e.TimeInterval.From : ''}
      //           options={fromToValues}
      //           onChange={(value) =>{
      //             let newData = data;
      //             newData[index].TimeInterval.From = '';
      //             if(value){
      //               newData[index].TimeInterval.From = value.value;
      //             }
      //             this.forceUpdate();
      //             this.setState({data: newData, divKey: Math.random()});
      //           }}
      //         />
      //       </div>
      //       <div className="col-4">
      //         <Select
      //           className='_comboFilters'
      //           name='To'
      //           id={`To${index}`}
      //           placeholder='09:00AM'
      //           value={e.TimeInterval ? e.TimeInterval.To : ''}
      //           isClearable={false}
      //           clearable={false}
      //           isDisabled={!e.disabled}
      //           disabled={!e.disabled}
      //           options={fromToValues}
      //           onChange={(value) =>{
      //             let newData = data;
      //             newData[index].TimeInterval.To = '';
      //             if(value){
      //               newData[index].TimeInterval.To = value.value;
      //             }
      //             this.forceUpdate();
      //             this.setState({data: newData, divKey: Math.random()});
      //           }}
      //         />
      //       </div>
      //       <div className="col-4">
      //         <Select
      //           className='_comboFilters'
      //           name='Interval'
      //           id={`Interval${index}`}
      //           placeholder='Day(s)'
      //           value={e.TimeInterval ? e.TimeInterval.Interval : ''}
      //           isClearable={false}
      //           clearable={false}
      //           isDisabled={!e.disabled}
      //           disabled={!e.disabled}
      //           options={intervalValues}
      //           onChange={(value) =>{
      //             let newData = data;
      //             newData[index].TimeInterval.Interval = '';
      //             if(value){
      //               newData[index].TimeInterval.Interval = value.value;
      //             }
      //             this.forceUpdate();
      //             this.setState({data: newData, divKey: Math.random()});
      //           }}
      //         />
      //       </div>
      //     </div>
      //   );
      // }
      // break;
    case 'TimeZone':
      return (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-9">
                {/* <Select
                  className='_comboFilters'
                  name='Time Zone'
                  id={`TimeZone${index}`}
                  placeholder='UTC-6 America/Mexico City'
                  value={e.TimeZone}
                  options={utcValues}
                  isClearable={false}
                  isDisabled={!e.disabled}
                  disabled={!e.disabled}
                  clearable={false}
                  onChange={(value) =>{
                    let newData = data;
                    newData[index].TimeZone = '';
                    if(value){
                      newData[index].TimeZone = value.value;
                    }
                    this.forceUpdate();
                    this.setState({data: newData, divKey: Math.random()});
                  }}
                /> */}
              </div>
              <div className="col-3">
                <div className="row" style={{marginTop: 8}}>

                <Popover
                      placement="left"
                      title={'Delete Alert'}
                      trigger="hover"
                      content={() => {
                        return (
                          <div className="row">
                            <div
                              className="col-12"
                              style={{
                                marginBottom: '1em',
                                textAlign: 'center',
                              }}
                            >
                              <p>
                                Are you want to delete this {' '}
                                {this.props.type === '1'
                                  ? 'alerta'
                                  : 'notificaciòn'}
                                ?
                              </p>
                            </div>
                            {/* <div className="col-6">
                          <Button content='Cancel' negative onClick={() => this.setState({ openPortal: false })} />
                        </div> */}
                            <div
                              className="col-12"
                              style={{ textAlign: 'center' }}
                            >
                              <Button
                                content="Aceptar"
                                negative={false}
                                style={{
                                  backgroundColor: '#2B98F0',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  this.deleteAlert(e.ConfigurationId);
                                }}
                              />
                            </div>
                          </div>
                        );
                      }}
                    >
                      <em
                        onClick={() => {
                          this.setState({ openPortal: true });
                        }}
                        className="mr-2 far fa-trash-alt"
                        style={{ color: '#FC3737', fontSize: '1.4em' }}
                      ></em>
                    </Popover>
                    {e.propsDisabled === true ? (
                      <></>
                    ) : (
                      <em
                        onClick={() => {
                          let newData = data;
                          newData[index].disabled = !newData[index].disabled;
                          if (newData[index].disabled === true) {
                            newData.map((e) => {
                              if (
                                newData[index].ConfigurationId !==
                                e.ConfigurationId
                              ) {
                                e.propsDisabled = true;
                              }
                            });
                          } else {
                            newData.map((e) => {
                              // if(newData[index].ConfigurationId !== e.ConfigurationId){
                              e.propsDisabled = false;
                              // }
                            });
                          }
                          // let newData = data;
                          // newData[index].disabled = !newData[index].disabled;
                          this.props.selectAlert(newData[index]);

                          this.setState({ data: newData });
                        }}
                        className={`mr-2 ${
                          !e.disabled ? 'far fa-edit' : 'fa fa-save'
                        }`}
                        style={{
                          color: !e.disabled ? '#9E9E9E' : '#297AB9',
                          fontSize: '1.4em',
                        }}
                      ></em>
                    )}

                  {/* <em onClick={() => {this.setState({openPortal: true});}} className="mr-2 far fa-trash-alt" style={{ color: '#FC3737', fontSize: '1.4em' }}></em>
                  <em onClick={() => {
                    
                    let newData = data;
                    newData[index].disabled = !newData[index].disabled;
                    if (newData[index].disabled === true) {
                      newData.map((e) => {
                        if (
                          newData[index].ConfigurationId !==
                          e.ConfigurationId
                        ) {
                          e.propsDisabled = true;
                        }
                      });
                    } else {
                      newData.map((e) => {
                        // if(newData[index].ConfigurationId !== e.ConfigurationId){
                        e.propsDisabled = false;
                        // }
                      });
                    }
                    // let newData = data;
                    newData[index].disabled = !newData[index].disabled;

                    this.props.selectAlert(newData[index]);
                    this.setState({data: newData});
                  }} className={`mr-2 ${!e.disabled ? 'far fa-edit' : 'fa fa-save'}`} style={{ color: !e.disabled ? '#9E9E9E' : '#297AB9', fontSize: '1.4em' }}></em> */}
                 
                 
                 
                  {/* <TransitionablePortal open={openPortal} transition={{animation: 'drop', duration: 500}}>
                    <Segment style={{ left: '70%', position: 'fixed', top: '50%', zIndex: 1000 }}>
                      <Header>Delete {this.props.type === '1' ? 'Alert' : 'Notification'}</Header>
                      <p>Are you sure you want to delete this {this.props.type === '1' ? 'alert' : 'notification'}?</p>
                      <div className="row">
                        <div className="col-6">
                          <Button content='Cancel' negative onClick={() => this.setState({openPortal: false})}/>
                        </div>
                        <div className="col-6" style={{textAlign: 'right'}}>
                          <Button content='Accept' negative={false} style={{backgroundColor: '#2B98F0', color: 'white'}} onClick={() => {this.deleteAlert(e.ConfigurationId);}}/>
                        </div>
                      </div>
                    </Segment>
                  </TransitionablePortal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      // break;
    case 'TypeNoti':
      return(
        <div className="row">
          <div className="col-12">
            <Select
              className='_comboFilters'
              name='Type of Notification'
              id={`TypeNoti${index}`}
              placeholder='RealTime'
              // value={e.TypeNoti}
              value={'RealTime'}
              isClearable={false}
              clearable={false}
              isDisabled={!e.disabled}
              // isDisabled={!e.disabled}
              disabled={true}
              options={typeNotiValues}
              onChange={(value) =>{
                let newData = data;
                let TypeNoti = {};
                newData[index].TypeNoti = '';
                if(value){
                  newData[index].TypeNoti = value.value;
                  TypeNoti.value = value.value;
                  TypeNoti.label = value.label;
                }
                this.forceUpdate();
                this.setState({data: newData, divKey: Math.random()});
              }}
            />
          </div>
        </div>
      );
      // break;
    case 'Periodicity':
      let isRealP = false;
      if(e.TypeNoti === 'RealTime'){
        isRealP = true;
      }
      // if(isRealP){
      return(
        <div className="row">
          <div className="col-12">
                Real Time
          </div>
        </div>
      );
      // }
      // else{
      //   return(
      //     <div className="row">
      //       <div className="col-12">
      //         <Select
      //           className='_comboFilters'
      //           name='Periodicity'
      //           id={`Periodicity${index}`}
      //           placeholder='Periodicity'
      //           value={e.Periodicity}
      //           isClearable={false}
      //           clearable={false}
      //           isDisabled={!e.disabled}
      //           disabled={!e.disabled}
      //           options={periodicityValues}
      //           onChange={(value) =>{
      //             let newData = data;
      //             newData[index].Periodicity = '';
      //             if(value){
      //               newData[index].Periodicity = value.value;
      //             }
      //             this.forceUpdate();
      //             this.setState({data: newData, divKey: Math.random()});
      //           }}
      //         />
      //       </div>
      //     </div>
      //   );
      // }
      // break;
    default:
      return(
        <div className="row">
          <div className="col-12">
            {e[f.id]}
          </div>
        </div>
      );
    }
  }

  saveAlert = () =>{
    let { newAlert, customer } = this.state;
    if(newAlert.type){
      let insertObj = {
        CustomerId: customer.CustomerId,
        AlertNotificationId: newAlert.type,
        Description: newAlert.description,
        TypeNotification: this.props.type === '1' ? 'A' : 'N'
      };
      newAlert.type = '';
      newAlert.description = '';
      axios.post(`https://poseidonapialerts.azurewebsites.net/alerts/AlertsCustomer/${this.state.customer.CustomerId}`, insertObj).then(success => {
        this.props.getAlertsCustomer();
      }).catch(error =>{

      });
      this.setState({active: false, newAlert: newAlert});
    }
  }

  render() {
    let { columns, data, divKey, newAlert } = this.state;
    return (
      <div className="col-12">
        <div className="row">
          {
            columns.map((e,index) =>{
              return (
                <div className={e.className} key={index}>
                  {e.title}
                </div>
              );
            })
          }
        </div>
        <div>
          {
            data.map((e, index) =>{
              return (
                <div className="row" key={index} style={{marginTop: 10, marginBottom: 10}}>
                  {
                    columns.map((f,ind) =>{
                      return (
                        <div className={f.className} key={ind}>
                          <div className="row">
                            <div className="col-12">
                              {
                                this.renderTable(e,f,index)
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
        <div className="row">
          <div className="col-12" style={{textAlign: 'right', marginTop: 10}}>
            <Fab color='lightBlue' className='MuiFab-lightBlue' size="small" aria-label="add" onClick={() =>{
              this.setState({active: true});
            }}>
              <AddIcon />
            </Fab>
          </div>
        </div>
        <Modal
          open={this.state.active}
          size={'medium'}
          centered={false}
          onClose={() => {this.setState({active: false});}}
          className="modalTable"
        >
          <Modal.Header className='headerModal Modal'>Add Alert</Modal.Header>
          <Modal.Content>
            <div className="row">
              <div style={{height: 100}} />
              <div className="col-4">
                <div className="col-12">
                  Select Alert
                </div>
                <div className="col-12">
                  <Select
                    className='_comboFilters'
                    name='Alert'
                    id='idNewAlert'
                    placeholder='Select'
                    value={newAlert.type}
                    isClearable={false}
                    clearable={false}
                    options={newAlert.alertValues}
                    onChange={(value) =>{
                      newAlert.type = '';
                      const newvalueSelect = {
                        value: '',
                        label: '',
                      };
                      if (value) {
                        newAlert.type = value.value;
                        newvalueSelect.value = value.value;
                        newvalueSelect.label = value.label;
                      }
                      this.setState({
                        newAlert: newAlert,
                        valueSelect: newvalueSelect,
                      });
                      // newAlert.type = '';
                      // if(value){
                      //   newAlert.type = value.value;
                      // }
                      // this.setState({newAlert: newAlert});
                      // let newData = data;
                      // newData[index].TypeNoti = '';
                      // if(value){
                      //   newData[index].TypeNoti = value.value;
                      // }
                      // this.forceUpdate();
                      // this.setState({data: newData, divKey: Math.random()})
                    }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="col-12">
                  Configuration
                </div>
                <div className="col-12">
                  {newAlert.configuration[newAlert.type]}
                </div>
              </div>
              <div className="col-4">
                <div className="col-12">
                  Description
                </div>
                <div className="col-12">
                  <Input
                    placeholder='Add Description'
                    name="description"
                    className="descriptionInput"
                    id="descriptionInput"
                    value={newAlert.description}
                    onChange={(ev, result) => {
                      newAlert.description = result.value;
                      this.setState({newAlert: newAlert});
                    }}
                  />
                </div>
              </div>
              <div style={{height: 100}} />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="row">
              <div style={{height: 100}} />
              <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-2 col-sm-4">
                <Button variant="contained" color="danger" className="button" onClick={() => {this.setState({active: false});}} >
                  Cancel
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button variant="contained" color="primary" className="button" onClick={() => {this.saveAlert();}}>
                  Save
                </Button>
              </div>
              <div style={{height: 100}} />
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default AlertNotification;