import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Col, Row } from "reactstrap";
import { Switch, Table, Tag, Transfer, Input } from 'antd';
import Swal from "sweetalert2";
import difference from 'lodash/difference';
import Axios from "axios";
import { Services } from '../Services'
import "./style.scss"

function NewMasterShip(params) {
	const [modal, setModal] = useState(false);
	const [userId, setUserId] = useState(undefined);
	const [targetKeys, setTargetKeys] = useState([]);
	const [dataPos, setDataPos] = useState([]);
	const [msName, setMSName] = useState('');
	const [columsLeft, setColumsLefth] = useState([
		{ dataIndex: 'PONo', title: 'Po No.', },
		// { dataIndex: 'NoLine', title: 'Line', render: tag => <Tag>{tag}</Tag>, },
		{ dataIndex: 'NoLine', title: 'Line', },
		{ dataIndex: 'Status', title: 'Status', },
	]);
	const [columsRight, setColumsRight] = useState([
		{ dataIndex: 'PONo', title: 'Po No.', },
		{ dataIndex: 'NoLine', title: 'Line', },
		{ dataIndex: 'Status', title: 'Status', },
		// { dataIndex: 'MasterShipment', title: 'Master Shipment', },
	]);

	const toggle = () => setModal(!modal);

	// async function getPoAvailable() {
	//     try {
	//         let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/POsavailable/`;
	//         let data = await Axios.get(url)
	//         setDataPos(data.data.data)
	//     } catch (error) {
	//         console.log("🚀 ~ file: addMasterShip.js ~ getPoAvailable ~ error", error.message)
	//     }
	// }

	const getPoAvailable = useCallback(async (_customer) => {
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/POsavailable/?customer=${_customer}`;
			let data = await Axios.get(url)
			let PosAvailable = data.data.data
			PosAvailable.map(e => {
				e.key = e.ShipmentNewBuildId
			})

			setDataPos(PosAvailable)

			// const originTargetKeys = PosAvailable
			//     .filter((item) => Number(item.key) % 3 > 1)
			//     .map((item) => item.key);

			// setTargetKeys(originTargetKeys)

		} catch (error) {
			console.log("🚀 ~ file: addMasterShip.js ~ getPoAvailable ~ error", error.message)
		}
	})

	const addMS = useCallback(async () => {
		let body = {
			NameMS: msName,
			SLadded: targetKeys,
			userId: userId
		}

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/addMS/`;
			let data = await Axios.put(url, body)
			if (data.data.status === 200) {
				setModal(!modal)
				Swal.fire({
					// position: 'top-end',
					type: 'success',
					title: 'Master shipment created successfully',
					showConfirmButton: false,
					timer: 1500
				})
				setTargetKeys([])
				params.getPONewBuilds()
			}
		} catch (error) {
			console.log("🚀 ~ file: addMS.js ~ error", error.message)
		}
	})

	useEffect(() => {
		getPoAvailable(params.customerSelect)
		setUserId(params.userId)
	}, [params])

	const onChange = (nextTargetKeys) => {
		setTargetKeys(nextTargetKeys);
	};

	const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
		<Transfer {...restProps} locale={{
			itemUnit: "Po´s",
			itemsUnit: "Po´s"
		}}>
			{({
				direction,
				filteredItems,
				onItemSelectAll,
				onItemSelect,
				selectedKeys: listSelectedKeys,
				disabled: listDisabled,
			}) => {
				const columns = direction === 'left' ? leftColumns : rightColumns;
				const rowSelection = {
					getCheckboxProps: (item) => ({
						disabled: listDisabled || item.disabled,
					}),

					onSelectAll(selected, selectedRows) {
						const treeSelectedKeys = selectedRows
							.filter((item) => !item.disabled)
							.map(({ key }) => key);
						const diffKeys = selected
							? difference(treeSelectedKeys, listSelectedKeys)
							: difference(listSelectedKeys, treeSelectedKeys);
						onItemSelectAll(diffKeys, selected);
					},

					onSelect({ key }, selected) {
						onItemSelect(key, selected);
					},

					selectedRowKeys: listSelectedKeys,
				};
				return (
					<Table
						rowSelection={rowSelection}
						columns={columns}
						dataSource={filteredItems}
						size="small"

						style={{
							pointerEvents: listDisabled ? 'none' : undefined,
						}}
						onRow={({ key, disabled: itemDisabled }) => ({
							onClick: () => {
								if (itemDisabled || listDisabled) return;
								onItemSelect(key, !listSelectedKeys.includes(key));
							},
						})}
					/>
				);
			}}
		</Transfer>
	);

	return (
		<>
			<div
				// style={{ cursor: 'pointer', padding: '0px 0px 8px 0px' }} 
				className='d-flex justify-content-end pb-2'
			>
				<i onClick={toggle} className="fas fa-plus-circle fa-2x mr-1 mb-1" style={{ color: '#13B0F0', cursor: 'pointer' }} > </i><span style={{ marginTop: '3px' }}>New Master Shipment</span>
			</div>
			<Modal isOpen={modal} {...params}
				size={'lg'}
				style={{ maxWidth: '75em', width: '100%' }}
			>
				<ModalHeader toggle={toggle}><span className="headerModalNMS"> New Master Shipment</span></ModalHeader>
				<ModalBody>
					<Row>
						<Col xs={12} md={6} lg={6}>
							<Label style={{ color: 'black' }}>Master Shipment No.</Label><Input placeholder="Master Shipment No." onChange={(evt) => { setMSName(evt.target.value) }} />
						</Col>
					</Row>
					<Row>
						<Col xs={6} md={6} lg={6} className="ml-auto mr-auto">
							<div className="new-ms-po">
								Purchase Orders available
							</div>
						</Col>
						<Col xs={6} md={6} lg={6} className="ml-auto mr-auto">
							<div className="new-ms-po">
								Purchase Orders added
							</div>
						</Col>
					</Row>
					<TableTransfer
						// id={"modal-transfer"}
						dataSource={dataPos}
						targetKeys={targetKeys}
						showSearch={true}
						onChange={onChange}
						// pagination={false}
						filterOption={(inputValue, item) =>
							item.PONo.indexOf(inputValue) !== -1 || item.NoLine.indexOf(inputValue) !== -1
						}
						leftColumns={columsLeft}
						rightColumns={columsRight}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => { toggle(); setMSName(''); setTargetKeys([]) }}>
						Cancel
					</Button>{' '}
					<Button color="success" onClick={addMS} hidden={(msName.length !== 0 && targetKeys.length > 0) ? false : true}>
						Create
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default NewMasterShip