import React from 'react';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import propTypes from 'prop-types';

import Table from './../../../../../../../template/Table';

function ContainerDetails(props){
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [columns] = React.useState([{label: 'Code', key: 'Code'},  {label: 'Description', key: 'Description'}, {label: 'Reference', key: 'Reference'}]);
  let {ContainerItemId} = props;
  const getDetails = React.useCallback(async() => {
    try{
      let response = await axios.get(`https://poseidonbooking.azurewebsites.net/containers-booking/reference/${ContainerItemId}`);
      // const response = await axios.get(`http://localhost:3017/containers-booking/reference/${ContainerItemId}`);
      setData(response.data.data);
      setLoading(false);
    }catch(error){
      console.error('error: getContainerItems', error);
      setData([]);
    }
  }, [ContainerItemId]);

  React.useEffect(() => {
    getDetails();
  }, [getDetails]);


  return (
    <React.Fragment>
      {
        loading ? 
          <React.Fragment>
            <div width='100%'>
              <Skeleton />
              <Skeleton animation={false} />
              <Skeleton animation="wave" />
            </div>
          </React.Fragment>:
          <React.Fragment>
            {
              data.length > 0 ?
                <Table data={data} columns={columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}/>
                :
                <div width='100%' className='d-flex justify-content-center'>
                  <h2 className='text-muted'>Data Not Found</h2>
                </div>
            }
          </React.Fragment>
      }    
    </React.Fragment>
  );
}

ContainerDetails.propTypes = {
  ContainerItemId: propTypes.number
};
ContainerDetails.defaultProps = {
  ContainerItemId: 0
};

export default ContainerDetails;