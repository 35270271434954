import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Skeleton from '@material-ui/lab/Skeleton';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


// ? Local imports
import { UpdateSelection, SetSelection, Setno, SetContainerReference, PushHistori, SaveProcedure } from './../../../../../../actions/Booking/create/';
import Authentication from './../../../../../../services/Authentication';
import restService from '../../../../../../services/restService';
// import Table from '../../../../../template/Table';
import Table from './../../../../../Global/Tablev2/';
import { Services } from './../../../Services';
import Loader from './../../../../../Global/TableLoader';
import './styles.scss';
const auth = new Authentication();
const rest = new restService();

const ItemsToFilter = ['PONumber', 'SplitNo', 'SupplierName', 'ConsigneeName', 'CustomerContainerID', 'POCreateDate', 'PromiseCargoReadyDate', 'StatusDesc'];
class FormExtended extends Component {
  state = {
    orders: [],
    id: '',
    mainMessage: 'Do you want to atach the following PO(s) to a new booking? ',
    selected: [],
    disabled: true,
    stringSelected: '',
    columns: [
      { label: 'PO No', key: 'PONumber' },
      { label: 'Split No.', key: 'SplitNo' },
      { label: 'Supplier', key: 'SupplierName' },
      { label: 'Consignee', key: 'ConsigneeName' },
      { label: 'Customer Container ID', key: 'CustomerContainerID' },
      { label: 'Creation Date', key: 'POCreateDate' },
      { label: 'Promise Cargo Ready', key: 'PromiseCargoReadyDate' },
      { label: 'Status', key: 'StatusDesc' },
    ],
    loading: true
  }

  componentDidMount = () => {
    let { id } = this.state;
    id = auth.getAuthentication('customerSelect');
    this.setState({ id });
    this.getOrdersList();
  }

  /*=============================================
  =            Handle Consol Shipment ID        =
  =============================================*/

  handleConsoleShipment = () => {
    // const {selected, id} = this.state;
    const { id } = this.state;
    let transformPOs = this.props.selected_pos.map((e) => { return { POId: e }; });
    Swal.fire({
      title: 'Please insert the Shipment No. to consolidate:',
      input: 'text',
      type: 'question',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#20c554',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      preConfirm: (shipmentNo) => {
        return Axios.get(`${Services.VERIFY_SHIPMENT_NO.path}/booking/verify/shipment/${shipmentNo}`)
          // return Axios.get(`http://localhost:3017/booking/verify/shipment/${shipmentNo}`)
          // return Axios.get(`${Services.VERIFY_SHIPMENT_NO.path}/booking/verify/shipment/${shipmentNo}`)
          .then(response => {
            if (response.data.success) {
              return { resp: response.data, shipmentNo: shipmentNo };
            } else {
              Swal.showValidationMessage(`The inserted Shipment No. ${shipmentNo} was not found 
                as an Existing Shipment. Please check and try again.`);
            }
          }).catch(() => {
            Swal.showValidationMessage(`The inserted Shipment No. ${shipmentNo} was not found 
            as an Existing Shipment. Please check and try again.`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      let paramas = result;

      if (result.value && result.value.resp.success) {
        Swal.fire({
          title: '',
          text: `The Shipment No. ${result.value.shipmentNo} was found as an Existing 
            Shipment, Do you want to consolidate the selected POs?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#20c554',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          preConfirm: () => {
            return undefined;
          }
        }).then((result) => {
          if (result.value) {
            Axios.get(`${Services.VERIFY_SHIPMENT_NO.path}/booking/verifycontainer/${paramas.value.shipmentNo}`, {
              // Axios.get(`http://localhost:3017/booking/verifycontainer/${paramas.value.shipmentNo}`, {
              //Axios.post(`${Services.EXEC_PROCEDURE.path}/booking/procedure`, {
            }).then(response => {
              let repon = response.data.data;
              let IntergloboContainerReference = response.data.data[0].IntergloboContainerReference;
              let items = this.props.selected_pos;
              let array = [];

              if (response.data.data.length <= 1) {
                Axios.post(`${Services.VERIFY_SHIPMENT_NO.path}/booking/validateone`, {
                  // Axios.post('http://localhost:3017/booking/validateone', {
                  WPSShipmentId: paramas.value.shipmentNo,
                }).then(succc => {
                  if (succc.data.success) {
                    Swal.fire({
                      title: `Are you sure you want to associate the following POs to the Shipment No.${paramas.value.shipmentNo}?`,
                      text: '',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#20c554',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes',
                      cancelButtonText: 'No',
                      html: `<table id="table" border=0>
                                  <thead>
                                      <tr>
                                          <th>Interglobo Container Ref./ Container No.</th>
                                          <th>POs</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>${response.data.data[0].Container}</td>
                                          <td>${this.props.selected_pos_str}</td>
                                      </tr>
                                </tbody>
                                </table>`,
                      preConfirm: () => {
                        let groupPush = {
                          id: IntergloboContainerReference,
                          data: items
                        };
                        array.push(groupPush);
                        return Axios.post(`${Services.VERIFY_SHIPMENT_NO.path}/booking/procedure`, {
                          // Axios.post('http://localhost:3017/booking/procedure', {
                          // return Axios.post(`${Services.EXEC_PROCEDURE.path}/booking/procedure`, {
                          WPSShipmentId: paramas.value.shipmentNo,
                          idCustomer: id,
                          // POs: selected,
                          POs: transformPOs,
                          type: paramas.value.resp.procedure
                        }).then(response => {
                          if (response.data && response.data.founded === true) {
                            return { shipmentNo: result.value.shipmentNo, success: true, founded: true };
                          } else {
                            return { shipmentNo: result.value.shipmentNo, success: true, founded: false };
                          }
                        }).catch(() => {
                          return { shipmentNo: result.value.shipmentNo, success: false };
                        });
                      }
                    }).then((result) => {
                      if (result.value && result.value.founded === true) {
                        Swal.fire(
                          '',
                          `The Shipment No. ${paramas.value.shipmentNo} is already created in
                                Poseidon could not be created again. Please
                                retry with other Shipment or contact your system administrator.`,
                          'error'
                        );
                      } else if (result.value && result.value.founded === false) {
                        this.saveProcedure(array, paramas);
                      } else if (result.value && result.value.success === false) {
                        Swal.fire({
                          title: '',
                          text: `Consolidation Error for Shipment No. ${paramas.value.shipmentNo}.
                              Please retry the operation. If this condition persists, 
                              contact your system administrator.`,
                          type: 'error',
                          confirmButtonColor: '#20c554',
                        });
                      }
                    });
                  }
                  else {
                    Swal.fire(
                      '',
                      `The Shipment No. ${paramas.value.shipmentNo} is already created in
                            Poseidon could not be created again. Please
                            retry with other Shipment or contact your system administrator.`,
                      'error'
                    );
                  }
                }).catch(() => {
                  console.log('trajo error');
                });
              }
              else {
                Axios.post(`${Services.VERIFY_SHIPMENT_NO.path}/booking/validateone`, {
                  // Axios.post('http://localhost:3017/booking/validateone', {
                  WPSShipmentId: paramas.value.shipmentNo,
                }).then(response => {
                  if (response.data.success) {
                    let array = [];
                    let obj = { 'paramas': paramas, 'id': id, 'transformPOs': transformPOs };
                    array.push(obj);

                    this.props.Setno(id);
                    this.props.SetContainerReference(repon);
                    this.props.PushHistori('/booking-create');
                    this.props.SaveProcedure(obj);
                    this.props.history.push('/container-association');
                  }
                  else {
                    Swal.fire(
                      '',
                      `The Shipment No. ${paramas.value.shipmentNo} is already created in
                            Poseidon could not be created again. Please
                            retry with other Shipment or contact your system administrator.`,
                      'error'
                    );
                  }
                }).catch(() => {
                  console.log('trajo error');
                });
              }
            }).catch(() => {
            });
          }
        });
      }
    });
  };
  resetSelection = () => {
    this.props.UpdateSelection({ selected_pos: [], selected_pos_str: '', disabled_actions: true });
  }

  saveProcedure = (array, paramas) => {
    Axios.post(`${Services.VERIFY_SHIPMENT_NO.path}/booking/associationcontainer`, { array }
      // return Axios.post('http://localhost:3017/booking/associationcontainer',{array}
      // return Axios.post(`${Services.EXEC_PROCEDURE.path}/booking/procedure`, {
    ).then(response => {
      Swal.fire(
        '',
        `Consolidation Completed for Shipment No. ${paramas.value.shipmentNo}.`,
        'success'
      );
      this.getOrdersList();
      this.resetSelection();
    }).catch(() => {
      console.log('saveProcedure - ');
    });
  }

  attachOrders = () => {
    const { mainMessage } = this.state;
    swal({
      title: mainMessage,
      // text: stringSelected,
      text: this.props.selected_pos_str,
      icon: 'warning',
      buttons: true,
    }).then((yeah) => {
      if (yeah) {
        this.props.sendSelected(this.props.selected_pos);
        this.resetSelection();
      }
    });
  }

  continueWithOutAttachOrders = () => {
    this.props.sendSelected([]);
    this.resetSelection();
  }

  /*=============================================
  =     Handle check selected inside the table  =
  =============================================*/

  recieve = array => {
    let disabled = true;
    if (array.length > 0) disabled = false;
    const Filtrated = this.state.orders.filter((order) => array.indexOf(order.POId) !== -1);
    let stringSelected = '';
    Filtrated.forEach((e) => {
      stringSelected += ` ${e.PONumber},`;
    });
    this.props.UpdateSelection({ selected_pos: array, selected_pos_str: stringSelected.substring(0, stringSelected.length - 1), disabled_actions: disabled });
    this.props.SetSelection(Filtrated);
  }

  /*=============================================
  =            Get OrderList                    =
  =============================================*/

  getOrdersList = () => {
    const id = auth.getAuthentication('customerSelect');
    rest.EXEC({
      _function: 'GetOrdersList',
      params: `?type=bookingCreate&id=${id}`
    }, fetch).then(success => this.setState({ orders: success.data, loading: false })).catch(() => { this.setState({ loading: false }); });
  }

  render() {
    // console.log('--- Entre al Render ----');
    const { orders, columns, loading } = this.state;
    return (
      <Card className="myCard">
        <CardBody>
          <div className="row">
            <div className="col-12">
              {
                loading ?
                  <Loader /> :
                  <Table
                    columns={columns}
                    data={orders}
                    configSearch={{ fieldsToFilter: ItemsToFilter }}
                    itemsPerPage={10}
                    keyCheck={'POId'}
                    options={
                      {
                        pagination: true,
                        search: true,
                        selectItems: true
                      }
                    }
                    handleSelection={this.recieve}
                  />
              }
            </div>
          </div>
          <Row>
            <Col md={2} lg={4} sm={12}></Col>
            <Col md={3} lg={3} sm={4}>
              {loading ?
                <Skeleton variant="rect" width={'30%'} height={40} />
                :
                <Button variant="contained" disabled={this.props.disabled_actions} className="button btn_shipment" size="lg" onClick={this.handleConsoleShipment}>
                  Consol to Existing Shipment
                </Button>
              }
            </Col>
            <Col md={3} lg={3} sm={4}>
              {loading ?
                <Skeleton variant="rect" width={'30%'} height={40} />
                :
                <Button variant="contained" color="success" className="button" size="lg" onClick={this.continueWithOutAttachOrders}>
                  Continue without attaching PO(s)
                </Button>
              }
            </Col>
            <Col md={3} lg={2} sm={4}>
              {loading ?
                <Skeleton variant="rect" width={'30%'} height={40} />
                :
                <Button variant="contained" color="primary" disabled={this.props.disabled_actions} className="button" onClick={this.attachOrders} size="lg">
                  Attach PO(s) to Booking
                </Button>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  selected_pos: state.booking_create.selected_pos,
  disabled_actions: state.booking_create.disabled_actions,
  selected_pos_str: state.booking_create.selected_pos_str
});
const mapDispatchToProps = dispatch => ({
  UpdateSelection: (selection) => dispatch(UpdateSelection(selection)),
  SetSelection: (selection) => dispatch(SetSelection(selection)),
  Setno: (selection) => dispatch(Setno(selection)),
  SetContainerReference: (selection) => dispatch(SetContainerReference(selection)),
  PushHistori: (selection) => dispatch(PushHistori(selection)),
  SaveProcedure: (selection) => dispatch(SaveProcedure(selection))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormExtended));