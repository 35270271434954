import React from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.scss';
import { Services } from './../../../Services';
import { saveAs } from 'file-saver';
/**
 * Card for Excel element 
 * @param {*} param0 icon
 */

export const FaCardExcel = ({ data }) => {
  const [loading, setLoading] = React.useState(false);

  const Headers = ['PO No', 'Status', 'Supplier', 'Ship to', 'POD PO',
    'Required at Destination', 'PO Creation', 'Est. Cargo Pickup', 'Promise Cargo Ready',
    'Actual Cargo Ready', 'Actual Cargo Pickup', 'Requested Ex Works Date',
    'Last Contact Date', 'Transport', 'Split No', 'Distributor PO', 'Total No. Pallets',
    'Total Gross Weight', 'Total QTY Ordered', 'Total QTY Confirmed',
    'Customer Container ID', 'Container No.', 'ShipmentNumber',
    'Est. Arrival at Warehouse', 'Consignee', 'Project',
    ,];
  // 'Customer Container ID', 'Container No.', 'Shipment Number.', 'Est. Arrival at Warehouse', 'Project', 'PO Creation', , , , , , , 'Total Qty Requested', 'Qty Confirmed', 
  const keysHeaders = ['PONumber', 'StatusDesc', 'SupplierName', 'DeliveryName',
    'POPOD', 'RequiredDestination', 'POCreateDate', 'EstimatedCargoPickup',
    'PromiseCargoReadyDate', 'ActualCargoReadyDate', 'ActualCargoPickup',
    'RequestedExWorksDate', 'LastContactDate', 'TransportMode',
    'SplitNo', 'DistributorPO', 'NoPallets', 'GrossWeight', 'TotalQtyOrdered',
    'TotalQtyConfirmed', 'CustomerContainerID', 'ContainerNumber', 'ShipmentNumber',
    'EstimatedArrivalConsolWH', 'ConsigneeName', 'ProjectName'
  ];

  async function downLoadFile() {
    setLoading(true);
    fetch(`${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        data: data,
        options: {
          columnType: {
            'Total QTY Confirmed': 'number',
            'Total No. Pallets': 'number',
            'Total Gross Weight': 'number',
            'Total QTY Ordered': 'number'
          },
          headers: Headers,
          keys: keysHeaders,
          image: {
            name: 'poseidon',
          },
          total: true
        }
      })
    }).then(data => {
      return data.json();
    }).then(success => {
      // let success = await Axios.post('https://poseidon-reports-dev.azurewebsites.net/reports/order/list/xlsx',{ data});

      if (success.base64) {
        setLoading(false);
        const linkSource = `data:application/xlsx;base64,${success.base64}`;
        const fileName = (Math.floor(Math.random() * 900000) + 100000) + Math.floor(Date.now() / 1000) + '.xlsx';
        saveAs(linkSource, fileName);
      } else console.warn('Error downloading file', success);
    });
  }

  return (
    <>
      {loading ? (
        <Icon.Group size="large">
          <Icon loading={loading} name="circle notch" size="large" />
          <Icon color="green" name="file excel" />
        </Icon.Group>
      ) : (
        <div hidden={data.length !== 0 ? false : true}>
          <Icon
            onClick={downLoadFile}
            color="green"
            className="__downloader"
            name="file excel"
            size="big"
          />
        </div>
      )}
    </>
  );
};