import { combineReducers } from 'redux';
import dashboard from './dashboard';
import order_list from './Orders/List';
import booking_create from './Booking/create';
import reports from './Reports/Scheduling/';

export default combineReducers({
  dashboard,
  order_list,
  booking_create,
  reports
});