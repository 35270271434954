/**
 * Menu Handler
 * -------------
 * Roles:
 * 1- Admin
 * 2- Interglobo Edit
 * 3- Customer Edit
 * 4- Interglobo Read
 * 5- Customer Read
 * 6- Interglobo SU
 * 7- Customer Invoice
 */

//este es un cambio de prueba
export const MenuHandler = {
  // admin
  1: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Control Tower",
      icon: "fas fa-broadcast-tower",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Weekly Planning",
          path: "/weekly-planning",
        },
        {
          name: "Calendar View",
          path: "/calendar-view",
        },
        // {
        //   name: 'Capacity Set Up',
        //   path: '/capacity-set-up'
        // }
      ],
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        // {
        //   name: 'Create',
        //   path: '/order-create'
        // },
        {
          name: "List",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },
      ],
    },
    {
      name: "Vendor Management",
      icon: "icon-people",
      path: "/vendor-management",
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Consol Plan",
      icon: "fas fa-boxes",
      translate: "sidebar.nav.ConsolPlan",
      submenu: [
        {
          name: "Consol Plan List",
          path: "/consol-plan",
        },
      ],
    },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: " Cost Management",
      icon: "icon-chart",
      translate: "sidebar.nav.COST",
      submenu: [
        // {
        //   name: 'Rates',
        //   path: '/rates'
        // },
        // {
        //   name: 'Summary',
        //   path: '/cost-summary'
        // },
        {
          name: "Invoicing",
          path: "/cost-management",
        },
        // {
        //   name : 'Purchasing',
        //   path : '/cost-purchasing'
        // }
      ],
    },
    {
      name: "Projects",
      icon: "icon-globe",
      translate: "sidebar.nav.COST",
      submenu: [
        {
          name: "Budget",
          path: "/cost-summary",
        },
        {
          name: "Purchasing",
          path: "/cost-purchasing",
        },
        {
          name: "Rates",
          path: "/rates",
        },
        {
          name: "Warehousing",
          path: "/warehousing",
        },
        {
          name: "Visibility",
          path: "/New-Build",
        },
      ],
    },
    {
      name: "Customer Service",
      icon: "icon-support",
      path: "/customer-service",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Admin",
      icon: "icon-settings",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Customer Management",
          path: "/admin",
        },
        {
          name: "Alerts & Notifications",
          path: "/alerts",
        },
        {
          name: "Run Procedures",
          path: "/procedures",
        },
        {
          name: "Statistics",
          path: "/statistics",
        },
        {
          name: "Anagrafico",
          path: "/anagrafico",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update-file",
        },
      ],
    },
  ],
  2: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        // {
        //   name: 'Create',
        //   path: '/order-create'
        // },
        {
          name: "List",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },

        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    // {
    //   name: 'Consol Plan',
    //   icon: 'fas fa-boxes',
    //   translate: 'sidebar.nav.ConsolPlan',
    //   submenu: [{
    //     name: 'Consol Plan List',
    //     path: '/consol-plan'
    //   }]
    // },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: " Cost Management",
      icon: "icon-chart",
      translate: "sidebar.nav.COST",
      submenu: [
        // {
        //   name: 'Rates',
        //   path: '/rates'
        // },
        // {
        //   name: 'Summary',
        //   path: '/cost-summary'
        // },
        {
          name: "Invoicing",
          path: "/cost-management",
        },
        // {
        //   name : 'Purchasing',
        //   path : '/cost-purchasing'
        // }
      ],
    },
  ],
  3: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "List",
          path: "/order-list",
        },
        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    // {
    //   name: 'Consol Plan',
    //   icon: 'fas fa-boxes',
    //   translate: 'sidebar.nav.ConsolPlan',
    //   submenu: [{
    //     name: 'Consol Plan List',
    //     path: '/consol-plan'
    //   }]
    // },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
  ],
  4: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        // {
        //   name: 'Create',
        //   path: '/order-create'
        // },
        {
          name: "List",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },

        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    // {
    //   name: 'Consol Plan',
    //   icon: 'fas fa-boxes',
    //   translate: 'sidebar.nav.ConsolPlan',
    //   submenu: [{
    //     name: 'Consol Plan List',
    //     path: '/consol-plan'
    //   }]
    // },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
  ],
  5: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "List",
          path: "/order-list",
        },
        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    // {
    //   name: 'Consol Plan',
    //   icon: 'fas fa-boxes',
    //   translate: 'sidebar.nav.ConsolPlan',
    //   submenu: [{
    //     name: 'Consol Plan List',
    //     path: '/consol-plan'
    //   }]
    // },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
  ],
  6: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        // {
        //   name: 'Create',
        //   path: '/order-create'
        // },
        {
          name: "List",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },

        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    // {
    //   name: 'Consol Plan',
    //   icon: 'fas fa-boxes',
    //   translate: 'sidebar.nav.ConsolPlan',
    //   submenu: [{
    //     name: 'Consol Plan List',
    //     path: '/consol-plan'
    //   }]
    // },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: " Cost Management",
      icon: "icon-chart",
      translate: "sidebar.nav.COST",
      submenu: [
        // {
        //   name: 'Rates',
        //   path: '/rates'
        // },
        // {
        //   name: 'Summary',
        //   path: '/cost-summary'
        // },
        {
          name: "Invoicing",
          path: "/cost-management",
        },
        // {
        //   name : 'Purchasing',
        //   path : '/cost-purchasing'
        // }
      ],
    },
    {
      name: "Admin",
      icon: "icon-settings",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Run Procedures",
          path: "/procedures",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update-file",
        },
      ],
    },
  ],
  7: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon-pie-chart",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "List",
          path: "/order-list",
        },
        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon-calendar",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    // {
    //   name: 'Consol Plan',
    //   icon: 'fas fa-boxes',
    //   translate: 'sidebar.nav.ConsolPlan',
    //   submenu: [{
    //     name: 'Consol Plan List',
    //     path: '/consol-plan'
    //   }]
    // },
    {
      name: "Tracking",
      icon: "icon-location-pin",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Warehousing",
      icon: "icon-home",
      // path: '/warehousing',
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Transactions",
          path: "/transactions",
        },
        {
          name: "Inventory",
          path: "/inventory",
        },
        {
          name: "Inventory In",
          path: "/inventoryIn",
        },
        {
          name: "Inventory Out",
          path: "/inventoryOut",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: " Cost Management",
      icon: "icon-chart",
      translate: "sidebar.nav.COST",
      submenu: [
        // {
        //   name: 'Rates',
        //   path: '/rates'
        // },
        // {
        //   name: 'Summary',
        //   path: '/cost-summary'
        // },
        {
          name: "Invoicing",
          path: "/cost-management",
        },
        // {
        //   name : 'Purchasing',
        //   path : '/cost-purchasing'
        // }
      ],
    },
  ],
  8: [
    {
      name: "Projects",
      icon: "icon-globe",
      translate: "sidebar.nav.COST",
      submenu: [
        {
          name: "Visibility",
          path: "/New-Build",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
  ],
  9: [
    {
      name: "Projects",
      icon: "icon-globe",
      translate: "sidebar.nav.COST",
      submenu: [
        {
          name: "Visibility",
          path: "/New-Build",
        },
      ],
    },
    {
      name: "Reporting & Analytics",
      icon: "icon-graph",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
  ],
  10: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Vendor Management",
      icon: "icon-people",
      path: "/vendor-management",
    },
  ],
  11: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Purchase Orders",
      icon: "icon-basket-loaded",
      path:"/order-list",
      translate: "sidebar.nav.ORDERS",
      // submenu: [
      //   // {
      //   //   name: 'Create',
      //   //   path: '/order-create'
      //   // },
      //   {
      //     name: "List",
      //     path: "/order-list",
      //   },
      //   {
      //     name: "Multiple Update",
      //     path: "/multiple-update",
      //   },
      // ],
    },
  ],
};
