import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TableFilter from "react-table-filter";
import { Pagination, Popup } from 'semantic-ui-react';
import { CardBody, Col, Row, Table } from 'reactstrap';
import Axios from "axios";
import { saveAs } from "file-saver";
import imgFilter from '../../../resources/filter.png'


// import { SampleData } from "../sampleData";
import "react-table-filter/lib/styles.css";
import "./styles.scss";

// const moment = require('moment');

class TableFilterComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterIcon: true,
      episodes: props.data,
      columns: [],
      pageCounter: props.options.pagination ? props.options.pagination : false,
      xlsx: props.options.xlsx ? props.options.xlsx.show : false,
      headers: props.options.xlsx ? props.options.xlsx.headers : [],
      keys: props.options.xlsx ? props.options.xlsx.keys : [],
      filName: props.options.xlsx ? props.options.xlsx.filName : 'document.xlsx',
      projectName: props.options.xlsx ? props.options.xlsx.projectName : '',
      sheetName: props.options.xlsx ? props.options.xlsx.sheetName : '',
      _url: props.options.xlsx ? props.options.xlsx.URL : '',

      active_page: 1,
      total_pages: this.calculatePages(props.data.length, props.options.itemsPerPage),
      itemsPerPage: props.options.itemsPerPage,
      totalItems: props.data.length,
      tableRows: [],
      loaderExcel: false,

      addLine: props.options.addLine ? props.options.addLine.visible : false
    };
    this.filterUpdated = this.filterUpdated.bind(this);
  }

  filterUpdated(newData) {
    console.log("newData", newData);
    this.setState({
      episodes: newData,
      totalItems: newData.length,
      total_pages: this.calculatePages(newData.length, this.state.itemsPerPage)
    }, () => { this.setResultsByPage() });
  }
  componentDidMount = () => {
    let { dataTable, columns, data } = this.props;
    this.setState({
      episodes: data,
      filterIcon: this.props.options.filter,
      total_pages: this.calculatePages(this.props.data.length, this.props.options.itemsPerPage),
      itemsPerPage: this.props.options.itemsPerPage,
      totalItems: this.props.data.length,
      columns,
    });

    this.setResultsByPage()
  };

  componentWillReceiveProps = (nextProps) => {
    // console.log("componentWillReceiveProps", nextProps);
    let { data, columns, options } = nextProps;
    // console.log("dataTable", dataTable);
    this.setState({
      filterIcon: options.filter,
      episodes: data,
      pageCounter: options.pagination,
      total_pages: this.calculatePages(data.length, options.itemsPerPage),
      itemsPerPage: options.itemsPerPage,
      totalItems: data.length,
      columns,
    });
    this.setResultsByPage()
  };

  setResultsByPage() {
    const { episodes, active_page, itemsPerPage, total_pages } = this.state
    let active_page2 = active_page - 1
    const ROWS = []
    episodes.slice(active_page2 * itemsPerPage, active_page2 * itemsPerPage + itemsPerPage).map((row, indexrow) => {
      ROWS.push(row)
    })

    // this.buildRows(ROWS)
    this.setState({
      tableRows: ROWS
    })
  }

  handleChangePage = (evt, { activePage }) => {
    this.setState({
      active_page: activePage
    }, () => { this.setResultsByPage() })
  }

  generateExcel = async () => {
    let { headers, keys, episodes, filName, projectName, _url, sheetName } = this.state;
    this.setState({
      loaderExcel: true
    })
    const dataxls = {
      data: episodes,
      options: {
        keys,
        headers,
        image: {
          name: projectName,
        },
        sheetName: sheetName
      },
    }

    try {
      if (episodes.length >= 1) {
        const getXlsxFile = await Axios.post(_url, dataxls);
        const fileName = `${filName}.xlsx`
        const linkSource = "data:application/xlsx;base64," + getXlsxFile.data.base64;
        saveAs(linkSource, fileName);
        this.setState({
          loaderExcel: false
        })
      } else {
        this.setState({
          loaderExcel: false
        })
      }
    } catch (error) {
      console.log('The Error-->', error);
      this.setState({
        loaderExcel: false
      })
    }
  };


  calculatePages(total, itemsPerPage) {
    const total_pages = total / itemsPerPage;
    return total_pages % 1 === 0 ? total_pages : Math.ceil(total_pages);
  }

  buildColumns = () => {
    let { columns } = this.state;
    let ___columns = [];

    columns.forEach((e, index) => {
      ___columns.push(
        <th
          key={e.key}
          filterkey={e.key}
          className={e.class ? e.class : "cell td_head"}
          style={{
            minWidth: e.minWidth ? e.minWidth : '10eṃ̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣',
            maxWidth: e.maxWidth ? e.maxWidth : '12em'
          }}
        // style={{ width: e.width ? e.width : '20px' }}
        // casesensitive={"true"}
        // showsearch={"true"}
        >
          {e.label}
        </th>
      );
    });
    return ___columns;
  };

  buildRows = (items) => {
    let { columns } = this.state;
    let ___rows = items.map((e, _index) => {
      let tds = [];
      if (e.hidden) {
        return <></>;
      } else {
        if (e) {
          columns.forEach((column, index_) => {
            if (column.custom) {
              tds.push(
                <td
                  key={index_}
                  className={
                    column.styletd ? column.styletd : "styledTdFilterTable"
                  }
                  id={
                    e[column.key]
                      ? column.idTd
                        ? column.idTd
                        : e[column.key]
                      : e[column.key]
                  }
                >
                  {column.custom(e)}
                </td >
              );
            } else if (column.index) {
              tds.push(
                <td
                  key={index_}
                  className={
                    this.state.styletd === true ? "table-td2" : "table-td"
                  }
                  id={
                    e[column.key]
                      ? column.idTd
                        ? column.idTd
                        : e[column.key]
                      : e[column.key]
                  }
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    minWidth: "150px",
                    maxWidth: "100px",
                  }}
                >
                  {_index + 1}
                </td>
              );
            } else if (column.shiwchColor) {
              tds.push(
                <td
                  key={index_}
                  className={
                    this.state.styletd === true ? "table-td2" : "table-td"
                  }
                  id={
                    e[column.key]
                      ? column.idTd
                        ? column.idTd
                        : e[column.key]
                      : e[column.key]
                  }
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    textAlign: "center",
                    minWidth: "150px",
                    maxWidth: "100px",
                    color: e[column.key] === "Paid" ? "#35c677" : "red",
                  }}
                >
                  {e[column.key] ? e[column.key] : ""}
                </td>
              );
            } else if (column.separator) {
              tds.push(
                <td
                  key={index_}
                  className={
                    this.state.styletd === true ? "table-td2" : "table-td"
                  }
                  id={
                    e[column.key]
                      ? column.idTd
                        ? column.idTd
                        : e[column.key]
                      : e[column.key]
                  }
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    textAlign: "center",
                    minWidth: "150px",
                    maxWidth: "100px",
                  }}
                >
                  {e[column.key]
                    ? "$" + Number(e[column.key]).toLocaleString("En-us")
                    : ""}
                </td>
              );
            }
            // else if (column.popover) {
            //   tds.push(
            //     <td key={index_} className={this.state.styletd === true ? "table-td2" : "table-td"} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center', minWidth: '150px', maxWidth: '100px' }}>{e[column.key] ? <Popover title={column.titlePopover} content={
            //       (<div style={{ width: 140, maxHeight: 150, overflowY: 'auto' }}>
            //         {e[column.key]}
            //       </div>
            //       )
            //     }>
            //       {e[column.key] ? `${e[column.key].substring(0, 22)}${e[column.key].length > 15 ? '...' : ''}` : ''}
            //     </Popover> : ''} </td>
            //   );
            // }
            else {
              tds.push(
                <td className="cell td_table">
                  {e[column.key] ? e[column.key] : ""}
                </td>
              );
            }
          });
        }
      }

      return (
        <>
          <tr className="hoverStyle" key={"row_" + e.key}>
            {tds}
          </tr>
        </>
      );
    });
    return ___rows;
  };

  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    let { episodes, pageCounter, active_page, total_pages, tableRows, totalItems, xlsx, loaderExcel, addLine, filterIcon } = this.state;
    return (
      <>
        <div style={{ textAlign: 'right' }}>
          <Col xs={12} md={12} lg={12} className='d-flex justify-content-end'>
            <Row>
              {filterIcon === false ? "" :
                <Col className='d-flex align-items-center'>
                  <Popup
                    inverted
                    content={'Filters'}
                    trigger={
                      <img
                        // onClick={() => {
                        // 	if (this.state.advancedSearchData.length > 1) {
                        // 		this.setState({ isOpen: true });
                        // 	}
                        // }}
                        alt="img"
                        id="toggler"
                        width={26}
                        height={26}
                        src={imgFilter}
                        style={{ cursor: 'pointer' }}
                      ></img>
                    } />
                </Col>
              }

              <Col className='d-flex align-items-center'>
                {
                  xlsx ?
                    <Popup
                      inverted
                      content={'Download List'}
                      position='top right'
                      trigger={
                        <i className={loaderExcel ? `fas fa-spinner fa-pulse fa-2x` : `fas fa-file-excel fa-2x`} style={{ color: '#487629', cursor: 'pointer' }}
                          onClick={this.generateExcel}
                        />
                      }
                    />
                    : ''
                }
              </Col>
            </Row>
          </Col>
        </div>
        {
          this.props.options.Filter ?
            <Row>
              <CardBody>
                <this.props.options.Filter.filters dataForm={this.props.options.Filter.form} funtionFilter={this.props.options.Filter.funtionFilter} Catalogs={this.props.options.Filter.Catalogs} />
              </CardBody>
            </Row>
            : ''
        }
        <table className="table-responsive">
          <thead>
            <TableFilter rows={episodes} onFilterUpdate={this.filterUpdated} showSearch>
              {this.buildColumns()}
            </TableFilter>
          </thead>
          <tbody>
            {this.buildRows(tableRows)}
          </tbody>
          <div style={{ marginTop: addLine ? '25px' : (totalItems <= 3 ? '200px' : '') }} />
        </table>

        {
          addLine ?
            <Col style={{ textAlign: 'right' }}>
              <CardBody>
                <Popup
                  basic
                  inverted
                  content='Add new shipment line'
                  size='mini'
                  trigger={
                    <i className="fas fa-plus-circle fa-2x" onClick={() => { this.props.options.addLine.addLineFunction() }} style={{ color: '#5D9CEC', cursor: 'pointer' }}></i>
                  } />
              </CardBody>
            </Col>
            : <br></br>
        }
        {
          pageCounter ?
            <div style={{ textAlign: 'center' }}>
              {/* <br></br> */}
              {/* <br></br> */}
              <Pagination
                size={window.innerWidth < 993 ? 'mini' : 'mini'}
                ellipsisItem={null}
                siblingRange={0}
                activePage={active_page}
                onPageChange={this.handleChangePage}
                totalPages={total_pages}
              >
              </Pagination>
              <br></br>
              <br></br>
            </div>
            : ''
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // clients: state.header.clients,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableFilterComponents));
