import React, { Component } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import Form from '../../../../../../models/FormData';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Errors from '../../../../../template/Errors';
import Icon from '@material-ui/core/Icon';
import { FilePond, File } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import path from 'path';
import Fade from 'react-reveal/Fade';
import Tables from '../../../../../template/Table';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';
import restService from '../../../../../../services/restService';
import { Services } from '../../../Services';

const rest = new restService();

class FormUpload extends Component {
    state = {
      PONumber: this.props.PONumber,
      name: '',
      files: [],
      open: true,
      items: [],
      columns: [
        { label: 'Doc Name', key: 'Name' },
        { label: 'Doc Type', key: 'other' },
        { label: 'Doc Category', key: 'Category' },
        {
          label: 'Actions', key: '', custom: (value) => {
            return (
              <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value)}>
                <Icon className="icon" color="inherent" style={{ fontSize: 20 }}>
                                remove
                </Icon>
              </Button>
            );
          }
        }
      ],
      Form: Form.CreateDocument,
      selects: {
        'DOC-CATEGORIES': [
          { value: 1, label: 'Purchase Order' },
          { value: 2, label: ' Commercial Invoice' },
        ]
      },
      Document: {
        Name: '',
        DocCategoryId: '',
        file: '',
        other: '',
        ModuleId: '',
        RowId: '',
        Category: '',

      },
      createDocumentForm: [],
      Documents: [],
      error: {
        open: false,
        message: '',
        type: ''
      },
      DocumentsList: '',
      SelectedIsLoaded: false
    }
    componentDidMount = () => {
      let { selected } = this.props;
      let { SelectedIsLoaded } = this.state;
      if (Array.isArray(selected) && selected.length > 0 && selected[0].Documents && selected[0].Documents.data && selected[0].Documents.data.length > 0 && !SelectedIsLoaded) {
        this.setState({
          Documents: this.parseDocuments(selected[0].Documents.data),
          SelectedIsLoaded: true,
        });
      }
      else this.buildDocumentForm();
    }

    componentWillReceiveProps = (nextProps) => {
      let { selected } = nextProps;
      let { SelectedIsLoaded } = this.state;
      if (Array.isArray(selected) && selected.length > 0 && selected[0].Documents && selected[0].Documents.data && selected[0].Documents.data.length > 0 && !SelectedIsLoaded) {
        this.setState({
          Documents: this.parseDocuments(selected[0].Documents.data),
          SelectedIsLoaded: true,
        });
      }
    }


    parseDocuments = (Documents) => {
      return Documents.map((e) => {
        return this.parseOneDocument(e);
      });
    }

    parseOneDocument = (Document) =>
      ({
        'DocumentId': Document.DocumentId,
        'ModuleId': Document.ModuleId,
        'DocCategoryId': Document.DocCategoryId,
        'RowId': Document.RowId,
        'Module': Document.Module,
        'Name': Document.Name,
        'other': Document.Type,
        'Category': Document.Category,
        'CreationDate': Document.CreationDate,
        'CreateBy': Document.CreateBy,
        'URL': Document.URL
      })



    handleChange = name => event => {
      let { Document } = this.state;
      Document[name] = event.target.value;
      this.setState({ Document }, () => this.buildDocumentForm());
    }
    handleChangeSelect = name => event => {
      let { Document } = this.state;
      if (event && event.value) {
        Document[name] = event.value;
        this.setState({ Document }, () => { this.buildDocumentForm(); });
      }
      else {
        Document[name] = '';
        this.setState({ Document }, () => { this.buildDocumentForm(); });
      }
    }


    onDrop = fileItems => {
      let { Document } = this.state;
      Document.file = fileItems.length > 0 ? fileItems[0].file : '';
      Document.Name = fileItems.length > 0 ? fileItems[0].file.name : '';
      this.setState({ Document }, () => this.buildDocumentForm());
    }


    buildDocumentForm = () => {
      const { Form, Document, selects } = this.state;
      let createDocumentForm = Form.map((e, i) => {
        if (e.type === 'Input') return (

          <div className={e.class} key={e.id}>
            <label>{e.label} {e.required === true ? <label>*</label> : ''}</label>
            <Input
              type="text"
              placeholder={e.placeHolder}
              value={Document[e.id]}
              name={e.id}
              onChange={this.handleChange(e.id)} />
          </div>
        );
        else if (e.type === 'Select') return (
          <div className={e.class} key={e.id}><label >{e.label}  {e.required === true ? <label>*</label> : ''}</label>
            <Select
              name={e.name}
              value={Document[e.id]}
              onChange={this.handleChangeSelect(e.id)} options={selects[e.options]} />
          </div>);
        else return '';
      });
      this.setState({
        createDocumentForm: createDocumentForm
      });
    }

    


    addDocument = () => {
      let { Document } = this.state;
      let { PONumber } = this.props;
      Document.other = path.extname(Document.file.name);
      if (Document.DocCategoryId === 1) Document.Category = 'Commercial Invoice';
      if (Document.DocCategoryId === 2) Document.Category = 'Purchase Order';
      Document.ModuleId = '1';
      Document.RowId = PONumber;

      let keys = ['Name', 'file', 'DocCategoryId', 'other', 'ModuleId', 'RowId', 'Category'];
      let errors = '';
      keys.forEach((e) => {
        if (!Document[e]) {
          errors += e + ', ';
        }
      });
      if (errors.length > 0)
        this.setState({
          error: {
            message: 'Some fileds are required to add new lime item: ( ' + errors + ' )',
            open: true,
            type: 'warning'
          }
        });
      else {
        this.setState({
          Document: {
            Name: '',
            DocCategoryId: '',
            file: ''
          },
          files: [],
        }, () => {
          const formData = new FormData();
          formData.append('file', Document.file);
          formData.append('Name', Document.Name);
          formData.append('DocCategoryId', Document.DocCategoryId);
          formData.append('Type', Document.other);
          formData.append('RowId', PONumber);
          formData.append('ModuleId', Document.ModuleId);

          fetch(`${Services.DOCUMENTS.path}/upload/`, {
            method: 'POST',
            body: formData
          })
            .then(response => response.json())
            .then(response => {
              if (response.data) {
                this.setState({
                  Documents: [...this.state.Documents, this.parseOneDocument(response.data)]
                }, () => {
                  this.buildDocumentsList();
                  this.buildDocumentForm();
                  this.setState({
                    error: {
                      message: 'Document Added',
                      open: true,
                      type: 'success'
                    }
                  });
                });
              }
            })
            .catch(error => {
              console.error('Error:', error);
            });
        });
      }
    }


    deleteLine = index => event => {
      let {DocumentId,RowId,Name} = index;
      let { Documents } = this.state;
      swal('Delete', 'Document ' + Name, 'warning').then(() => { 
        rest.EXEC({
          _function: 'RemoveFile', params: '', data: {
            RowId: RowId,
            DocumentId: DocumentId
          }
        }, fetch).then(success => {
          Documents.splice(index, 1);
          this.buildDocumentsList();
          this.setState({
            error: {
              message: 'Document Removed',
              open: true,
              type: 'warning'
            }
          });
        }).catch(error => {
                
        });
      });    
    }


    buildDocumentsList = () => {
      const { Documents } = this.state;
      // let categories = this.state.selects['DOC-CATEGORIES'];
      if (Documents && Documents.length > 0) {
        let DocumentsList = Documents.map((e, index) => {
          // let _index = categories.findIndex(x => x.value === e.DocCategoryId);
          return (
            <tr>
              <td><Fade left opposite>{e.Name}</Fade></td>
              <td><Fade left opposite></Fade></td>
              <td><Fade left opposite>{e.DocCategoryId}</Fade></td>

              <td>
                <Fade left opposite>
                  <center>
                    <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(index)}>
                      <Icon className="icon" color="inherent" style={{ fontSize: 20 }}>
                                            remove
                      </Icon>
                    </Button>
                  </center>
                </Fade>
              </td>
            </tr>
          );
        });
        this.setState({ DocumentsList });
      }
      else {
        this.setState({ DocumentsList: '' });
      }
    }
    handleClickOpen = () => this.setState({ open: true });
    handleClose = () => this.setState({ open: false });
    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })
    render() {
      // let { DocumentsList } = this.state
      // const { classes } = this.props
      let { Documents } = this.state;
      return (
        <ExpansionPanel elevation={0} defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <legend id ="Documents"><em className="fas fa-folder-open fa-1x mr-2"></em>Documents</legend>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row" style={{ width: '100%' }}>
              <div className="col-md-12">
                <FilePond
                  onupdatefiles={(fileItems) => {
                    this.setState({
                      files: fileItems.map(fileItem => fileItem.file)
                    }, () => {
                      this.onDrop(fileItems);
                    });
                  }}>
                  {this.state.files.map(file => (
                    <File key={file} src={file} origin="local" />
                  ))}
                </FilePond>
              </div>
              {this.state.createDocumentForm}
              <div className="col-md-1" style={{ paddingTop: '25px' }}>
                <Button variant="fab" mini color="primary" aria-label="Add" onClick={this.addDocument}>
                  <AddIcon />
                </Button>
              </div>
              <div className="col-md-12">
                <hr></hr>
              </div>
              <div className="col-md-12">
                <Tables data={Documents} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
              </div>
              <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
}

export default FormUpload;

