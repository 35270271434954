import React, {Component} from 'react';
import { Icon, Button, Popup} from 'semantic-ui-react';
import './styles.scss';
import Axios from 'axios';
// import {Services} from './../services';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';
import { Services } from '../../../Services';


class DeleteOp extends Component {
  constructor(props){
    super(props);
    this.state = {
      POId: this.props.opId,
      _user: this.props._user,
      resetComments: this.props.resetComments,
      loading: false,
      CommentId: this.props.CommentId
    };
  }


  
  /*=============================================
  =            handle Confirm Delete            =
  =============================================*/
  
  handleConfirm = async() => {
    const {resetComments, CommentId} = this.state;
    this.handleLoading();
    try{
      // let url = `https://poseidonapicomments.azurewebsites.net/${CommentId}`;
      let success = await Axios.delete(`${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/delete-comment/${CommentId}`);
      this.handleLoading();
      resetComments();
    }catch(error){
      console.error('handleConfirm: ', error);
    }
  }

  
  /*=============================================
  =            Handle loading button            =
  =============================================*/

  handleLoading = () => {
    this.setState({loading: !this.state.loading});
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.POId) this.setState({POId: nextProps.POId});
    if (nextProps.CommentId) this.setState({CommentId: nextProps.CommentId});
    if (nextProps.resetTable) this.setState({resetTable: nextProps.resetTable});
  };


  render(){
    const { loading } = this.state;
    return (
      <div className='trash_icon'>
        {/* <ToastContainer /> */}
        <Popup
          trigger={
            <Icon name='trash alternate' className="icon_trash_component" onClick={this.handleShow}/>
          }
          content={
            <React.Fragment>
              <p className='delete_text'>Are you sure you want to delete this comment?</p>
              <Button className='btn-delete' color='green' size='tiny' content='Confirm' loading={loading} onClick={this.handleConfirm}/>
            </React.Fragment>
          }
          on='click'
          position='top right'
        />
      </div>
    );
  }
};

export default DeleteOp;