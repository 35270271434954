import React, { Component } from "react";
import Table from "../../../../../template/Table";
import ContentWrapper from "../../../../../template/Layout/ContentWrapper";
import { Tab } from "semantic-ui-react";
import containerImage from "../../../../../../resources/container.png";
import { withRouter } from "react-router-dom";
import ContainerSummaryDetail from "./summary";
import ContainerEventsDetail from "./events";
import axios from "axios";
import { Services } from "./../../../Services";
import Loader from "./../../../../../Global/Loader";
import { validateIdParams } from "../../../../../utils/regularExpressionValidation";
import { message } from "antd";

class ContainerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullLoader: false,
      container: {},
      allSteps: [],
      PosRes: [],
      steps: [
        {
          label: "Empty Returned",
          value: "",
          id: 126,
          completed: false,
          order: 1,
        },
        {
          label: "Delivered",
          value: "",
          id: 125,
          completed: false,
          order: 2,
        },
        {
          label: "Appointment",
          value: "",
          id: 124,
          completed: false,
          order: 3,
        },
        {
          label: "Out Of Gated",
          value: "",
          id: 123,
          completed: false,
          order: 4,
        },
        {
          label: "Customs Released",
          value: "",
          id: 98,
          completed: false,
          order: 5,
        },
        {
          label: "Discharged",
          value: "",
          id: 122,
          completed: false,
          order: 6,
        },
        {
          label: "Arrived",
          value: "",
          id: 91,
          completed: false,
          order: 7,
        },
        {
          label: "Departed",
          value: "",
          id: 62,
          completed: false,
          order: 8,
        },
        {
          label: "Container Loading",
          value: "",
          id: 121,
          completed: false,
          order: 9,
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getContainer();
  };

  getContainer = async () => {
    console.log("getContainer");
    let idContainer = this.props.match.params.id;
    let moduleId = 4;

    let idValidate = validateIdParams(idContainer);
    if (!idValidate) {
      message.error("Data entered is invalid", 2);
      setTimeout(() => {
        this.props.history.push("/shipment/summary");
      }, 2000);
      return;
    }

    let container = await axios.get(
      `${Services.GET_ALL_BOOKINGS.path}/containers-booking/container/${idContainer}`
    );
    // let container = await axios.get(`http://localhost:3017/containers-booking/container/${idContainer}`);

    // console.log("container",container)
    let containerRes =
      container.data && container.data.data ? container.data.data : {};
    let containerReference =
      container.data && container.data.data
        ? container.data.data.InternalReference
        : 0;

    let events = await axios.get(
      `${Services.GET_ALL_BOOKINGS.path}/containers-booking/containerEventsTimeline/${moduleId}/${containerRes.BookingContainerId}`
    );
    // let events = await axios.get(`http://localhost:3017/containers-booking/containerEventsTimeline/${moduleId}/${containerRes.BookingContainerId}`);

    let eventsRes = events.data && events.data.data ? events.data.data : [];

    let Pos = await axios.get(
      `${Services.GET_ALL_BOOKINGS.path}/containers-booking/POs/${containerReference}`
    );
    // let Pos = await axios.get(`http://localhost:3017/containers-booking/POs/${containerReference}`);

    //  console.log("Pos",Pos)
    let PosRes = Pos.data && Pos.data.data ? Pos.data.data : [];

    var newSteps = this.state.steps;
    let dataEvents = eventsRes;
    var findNext = false;

    newSteps.map((f) => {
      dataEvents.map((e) => {
        if (findNext) {
          f.completed = true;
        }
        if (e.EventDescriptionId === f.id) {
          findNext = true;
          f.value = e.EventDate;
          if (e.InTimeLine === "1") {
            f.completed = true;
          }
        }
      });
    });
    let valorComplete = false;
    for (let i = 0; i < newSteps.length; i++) {
      const e = newSteps[i];
      if ((e.id === 125 && e.value != "") || (e.id === 126 && e.value != "")) {
        valorComplete = true;
      }
    }
    if (!valorComplete) {
      // console.log("aqui ira la logica")
      for (let i = 0; i < newSteps.length; i++) {
        const e = newSteps[i];
        if (
          (e.id === 122 && e.value === "") ||
          (e.id === 98 && e.value === "")
        ) {
          e.completed = false;
        }
      }
    }

    // console.log(newSteps[2].value)
    // console.log(newSteps[3].value)
    if (Date.parse(newSteps[2].value) < Date.parse(newSteps[3].value)) {
      // console.log("las fechas se invierte")
      newSteps[2].order = 4;
      newSteps[3].order = 3;
    }
    this.setState({
      container: containerRes,
      steps: newSteps,
      allSteps: dataEvents,
      PosRes,
      fullLoader: true,
    });
  };

  render() {
    let { container, steps, allSteps, PosRes, fullLoader } = this.state;

    return (
      <ContentWrapper>
        <Loader hidden={fullLoader} />
        <div className="content-heading">
          <div className="row">
            <legend>
              <img
                src={containerImage}
                width="26"
                height="26"
                className="mr-2"
              ></img>
              <label>Container Details</label>
            </legend>
          </div>
        </div>
        <Tab
          menu={{ secondary: true, className: "myWrapped" }}
          menuPosition="right"
          panes={[
            {
              menuItem: "Summary",
              render: () => (
                <ContainerSummaryDetail
                  container={container}
                  POs={PosRes}
                  steps={steps}
                />
              ),
            },
            {
              menuItem: "Events",
              render: () => (
                <ContainerEventsDetail
                  container={container}
                  steps={steps}
                  allSteps={allSteps}
                />
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}

export default withRouter(ContainerDetail);
