import React, { Component } from 'react';
import { Container, Row, Col, CardBody, CardText, } from 'reactstrap';
import FlotChart from './Charts/Float';
import { ChartSpline, ChartBar } from './ChartFlot.setup.js';
import './styles.scss';
import YearsSelect from './CatYears';
import { MyResponsiveBarCanvas2 } from './Charts/BarChart2';
import { MyResponsiveBarCanvas } from './Charts/BarChart';
import { MyResponsivePieCanvas } from './Charts/DonutGraph';

class Graphic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearCat:props.yearCat ? props.yearCat :[],
      tercerChart:props.tercerChart ? props.tercerChart : [],
      cuartaChar: props.cuartaChar ? props.cuartaChar:[],
      sparkOptions: {
        barColor: '#cfdbe2',
        height: 20,
        barWidth: 3,
        barSpacing: 2
      },
      datos:[],
      keys:[],
      colors: ['#f9ed90', '#83db86', '#d3d3d3','#2B98F0','#f4ab35'],
      sparkValues: [1, 0, 4, 9, 5, 7, 8, 9, 5, 7, 8, 4, 7],
      data: this.props.data,
      Graphic1: [],
      enero: 0,
      chart1: '',
      chart2: '',
      ChartSpline: ChartSpline,
      ChartBar: ChartBar,
    };
  };
  componentDidMount = () => {
    this.buildMounths();
    this.buildTransportMode();
    }

  buildTransportMode = () =>{
    const InvoiceTrend = this.state.data;
    let catalogo = [];
    let chartree = [
      {id: "Jan"  ,label: "Jan"},
      {id: "Feb"   ,label: "Feb"},
      {id: "Mar"   ,label: "Mar"},
      {id: "Apr"   ,label: "Apr"},
      { id: "May"   ,label: "May"},
      {id: "Jun"  ,label: "Jun"},
      {id: "Jul"   ,label: "Jul"},
      {id: "Aug"   ,label: "Aug"},
      {id: "Sep"    ,label: "Sep"},
      {id: "Oct"   ,label: "Oct"},
      {id: "Nov"   ,label: "Nov"},
      {id: "Dic"   ,label: "Dic"},
    ]
    // let year = 2020
    InvoiceTrend.map(e => {
      let findCatalogo = false;
      catalogo.map(f => {
       if(f === e.TransportMode){
      findCatalogo = true;
      }
      })
      if (!findCatalogo){
      catalogo.push(e.TransportMode);
      }
      });
      catalogo.map(e => {
        let name = e ? e : 'null';
        chartree.map(f => {
          f[e] = 0;
        });
      });
      InvoiceTrend.map(e => {
        let date = e.InvoiceDate.split('-')[1] + ',' + e.InvoiceDate.split('-')[0] + ',' + e.InvoiceDate.split('-')[2];
        let monthTrend = new Date(date).getMonth();
        // let yearTrend = d.getYear();
        // if (yearTrend === year) {  //ESTO SE COMENTARA SI NO SE OCUPA AÑo
          if (chartree[monthTrend]) {
            chartree[monthTrend][e.TransportMode ? e.TransportMode : 'null'] = chartree[monthTrend][e.TransportMode ? e.TransportMode : 'null'] + Number(e.TotalAmount);
          }
        // } //ESTO SE COMENTARA SI NO SE OCUPA AÑo
      });
      this.setState({
        datos:InvoiceTrend.length === 0 ? [] :  chartree,
        keys:catalogo
      })
  }

  buildMounths = (year) => {
    const InvoiceTrend = this.state.data;
    let yearselected = year ? year  :  (this.state.yearCat.length >0 ? this.state.yearCat[0].value : '');
    let dataInvoiceTrend = []
    InvoiceTrend.map((e)=>{
      if (e.year === yearselected){
        dataInvoiceTrend.push(e)
      }
    })
    let { ChartSpline } = this.state;
    let json = {
      1: { val: 0, label: 'Jan' },
      2: { val: 0, label: 'Feb' },
      3: { val: 0, label: 'Mar' },
      4: { val: 0, label: 'Apr' },
      5: { val: 0, label: 'May' },
      6: { val: 0, label: 'Jun' },
      7: { val: 0, label: 'Jul' },
      8: { val: 0, label: 'Aug' },
      9: { val: 0, label: 'Sep' },
      10: { val: 0, label: 'Oct' },
      11: { val: 0, label: 'Nov' },
      12: { val: 0, label: 'Dec' },
    };
    dataInvoiceTrend.map(function (index) {
      let date = index.InvoiceDate.split('-')[1] + '-' + index.InvoiceDate.split('-')[0] + '-' + index.InvoiceDate.split('-')[2];
      let Mes = new Date(date).getMonth() + 1;
      let found = json[Mes];
      if (found) {
        json[Mes].val += parseInt(index.TotalAmount);
      }
      return index;
    });
    let data = [];
    Object.keys(json).forEach((e) => {
      let array = [];
      array.push(json[e].label);
      array.push(json[e].val);
      data.push(array);
    });
    ChartSpline.data[0].data = data;
    this.setState({
      ChartSpline,
      chart1: ''
    }, () => {
      this.setState({
        chart1: 
        <>
        <Col md={12}>
            <div className="col-md-1"></div>
            <div className="flex-containerCatyear col-md-11">
            {dataInvoiceTrend.length=== 0 ? '' : <YearsSelect yearCat={this.state.yearCat} onChangeYear={this.onChangeYear} />}
            </div>
        </Col>
        {dataInvoiceTrend.length=== 0 ?
        <h1 style={{textAlign:'center',marginTop:'2em'}}> No Results</h1>
        :
        <FlotChart options={ChartSpline.options} data={ChartSpline.data} className="flot-chart" height="250px" />
        }
        </>
      });
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.data,
      yearCat:nextProps.yearCat,
      cuartaChar:nextProps.cuartaChar,
      tercerChart:nextProps.tercerChart
    }, () => {
      this.buildMounths();
      this.buildTransportMode();
    });
  }

  onChangeYear = async (data) => { // ? flotData
   this.buildMounths(data)
  };

  render() {
   let {datos, keys, colors,cuartaChar, chart1,tercerChart}=this.state;
    return (
      <Container fluid>
        <Row>
          <Col style={{marginBottom:'20px' }} lg={6}>
          <div className=" equalsWidth2">
              <CardBody style={{borderRadius:'22px'}} className="graphcardbody">
                <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>Total Invoice Trend</CardText>
                 {chart1}
              </CardBody>
            </div>
          </Col>
          <Col style={{marginBotton:'20px' }} lg={6}>
          <div className=" equalsWidth2">
              <CardBody style={{borderRadius:'22px'}} className="graphcardbody">
                <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>Status</CardText>
                 {cuartaChar.length === 0 ?  <h1 style={{textAlign:'center',marginTop:'2em'}}> No Results</h1>  : <MyResponsivePieCanvas  data={cuartaChar} /> } 
                </CardBody>
            </div>
          </Col>
          <Col style={{marginBotton:'20px' }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody style={{borderRadius:'22px'}} className="graphcardbody">
                <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>Transport Mode</CardText>
                {datos.length === 0 ?  <h1 style={{textAlign:'center',marginTop:'2em'}}> No Results</h1>  : <MyResponsiveBarCanvas2 data={datos} keys={keys} colors={colors} /> }                    
              </CardBody>
            </div>
          </Col>
          <Col style={{marginBotton:'20px' }} lg={6}>
          <div className=" equalsWidth2">
              <CardBody style={{borderRadius:'22px'}} className="graphcardbody">
                <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>Cost Category</CardText>
                {tercerChart.length === 0 ?  <h1 style={{textAlign:'center',marginTop:'2em'}}> No Results</h1>  : <MyResponsiveBarCanvas position="vertical" data={this.state.tercerChart} /> }                    
              </CardBody>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Graphic;
