import React from 'react';
import propTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Icon, Checkbox} from '@material-ui/core/';
import {blue} from '@material-ui/core/colors';

// import Search from './Search';
import Search from './../Search/';
import './styles.scss';

/**
 * [EnhancedTableHead]
 * @param {[type]} props [description]
 */
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount, rows} = props;
  return (
    <TableHead>
      <TableRow>
        {
          props.showSelect ? 
            <TableCell padding="checkbox" className='cell-table'>
              <Checkbox
                className={classes.check}
                checkedIcon={<Icon>check_circle</Icon>}
                size='small'
                color='none'
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all' }}
              />
            </TableCell>
            : ''
        }
        {rows.map(headCell => (

          <TableCell
            className='cell-table table-header-text'
            key={`${headCell.id}-${Math.random().toString(36).substring(7)}`}
            align={headCell.align}
            style={{ minWidth: headCell.minWidth, width: headCell.width ? headCell.width: 130}}
          >
            {headCell.label}
          </TableCell>  

        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: propTypes.object.isRequired,
  numSelected: propTypes.number.isRequired,
  // onRequestSort: propTypes.func.isRequired,
  onSelectAllClick: propTypes.func.isRequired,
  order: propTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: propTypes.string.isRequired,
  rowCount: propTypes.number.isRequired,
  rows: propTypes.array.isRequired,
  showSelect: propTypes.bool
};

EnhancedTableHead.defaultProps = {
  showSelect: false,
  order: 'asc'
};


/**
 * Styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    // maxHeight: 450,
  },
  check: {
    color: blue[800],
    '&$checked': {
      color: blue[900],
    },    
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
}));

/**
 * [Scroll Table Component]
 */
export default function ScrollTableComponent(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let [rows, setRows] = React.useState(props.rows);
  let [rowSearch, setRowSearch] = React.useState(props.rows);
  const [selected, setSelected] = React.useState([]);

  // ? Handle on page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // ? handle calculate rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ? Handle select all lines 
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n[props.keyCheck]);
      setSelected(newSelecteds);
      props.onselectAll(newSelecteds);
      return;
    }
    setSelected([]);
    props.onselectAll([]);
  };

  // ? Handle select by line
  const handleClick = (event, line_no) => {

    const selectedIndex = selected.indexOf(line_no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, line_no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    props.handler(newSelected);
  };
  const isSelected = line_no => selected.indexOf(line_no) !== -1;


  /**
   * On Change function search
   */
  function OnSearch(data){
    setRows([...data]);
  };


  React.useEffect(() => {
    setSelected([]);
    setRows(props.rows);
    setRowSearch(props.rows);
  }, [props.rows]);
  

  return (
    <React.Fragment>
      {
        props.search ? 
          <Search data={rowSearch} handler={OnSearch} configSearch={props.configSearch}></Search>:
          ''
      }
      <TableContainer id='table-container' className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            showSelect={props.showSelect}
            numSelected={selected.length}
            // order={order}
            // orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            // onRequestSort={handleRequestSort}
            rowCount={rows.length}
            rows={props.columns(props.functions)}
          />
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              // let count = 0;
              const isItemSelected = isSelected(row[props.keyCheck]);
              const labelId = `table-checkbox-${row[props.keyCheck]}`;
              // count ++;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1} 
                  key={`${row[props.keyCheck]}-row-${Math.random().toString(36).substring(7)}`}
                >
                  {
                    props.showSelect ? 
                      <TableCell className='cell-table' padding="checkbox">
                        <Checkbox
                          checkedIcon={<Icon>check_circle</Icon>}
                          color='none'
                          className={classes.check}
                          size='small'
                          onClick={event => handleClick(event, row[props.keyCheck])}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>:
                      ''
                  }

                  {props.columns(props.functions).map(column => {
                    return (
                      <React.Fragment>
                        {
                          column.cell ? 
                            <TableCell className={column.fixed ? 'cell-table fixed-cell': 'cell-table'} key={`${column.id}-col-${Math.random().toString(36).substring(7)}`} align={column.align}>
                              {column.cell(row)}
                            </TableCell>:
                            <TableCell className={column.fixed ? 'cell-table fixed-cell': 'cell-table'} key={`${column.id}-col-${Math.random().toString(36).substring(7)}`} align={column.align}>
                              {column.format && typeof row[column.id] === 'number' ? column.format(row[column.id]) : row[column.id]}
                            </TableCell>                          
                        }
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className='v1-PaginationT'
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}
/**
 * columns      * columns for table component
 * rows         * rows for columns table component
 * handler      * checkbox handler
 * handlerInput * input handler when use inputs inside the table
 * key          * is a JSON key and allows handle checkbox,required when showSelect = true
 * onselectAll  * function that is excuted when are selected all lines in table
 * showSelect   * enable checkbox as firts rows
 */
ScrollTableComponent.propTypes = {
  columns: propTypes.func,
  functions: propTypes.object,
  handler: propTypes.func,
  handlerInput: propTypes.func,
  keyCheck: propTypes.string,
  onselectAll: propTypes.func,
  rows: propTypes.array,
  search: propTypes.bool,
  showSelect: propTypes.bool,
  titleSearch: propTypes.string,
  keysSearch: propTypes.array
};

ScrollTableComponent.defaultProps = {
  columns: () => [],
  functions: {},
  handler: () => {},
  handlerInput: () => {},
  keyCheck: '',
  onselectAll: () => {},
  rows: () => [],
  search: false,
  showSelect: false,
  titleSearch: '',
  keysSearch: []
};

