import React,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import Mobile from './mobile';
// import Model from './models';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class Tracking extends Component {
  constructor(props){
    super(props);
    this.state = {
      steps : [],
      width: window.innerWidth,
    };
  }
    
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }


    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };
    componentDidMount = ()=>{
      this.setState({
        steps : this.props.steps ? this.props.steps : []
      });
    }
    
    componentWillReceiveProps = (nextProps)=>{
      this.setState({
        steps : nextProps.steps ? nextProps.steps : []
      });
    }



    render() {
      const { width } = this.state;
      const isMobile = width <= 900;
      return (
      <>
      {isMobile ? 
        <Mobile steps = {this.state.steps}/>
        : 
        <Stepper alternativeLabel nonLinear activeStep={true}>
          {this.state.steps.map((e, index) => {
            const props = {};
            const buttonProps = {};
            buttonProps.optional = <Typography variant="caption">{e.value}</Typography>;
            return (
              <Step key={`${e.label}-${index}-${e.label}`} {...props}>
                <StepButton
                  completed={e.completed}
                  {...buttonProps}>
                  {e.label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      }
      </>
      );
    }
}


export default withStyles(styles)(Tracking);