
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row } from "reactstrap";
import Table from "../../../../../components/template/TableComponents/Table";


function Cost(props) {
    const [loader, setLoader] = useState(false)
    const [columns, setColumns] = useState([

        { key: 'NewBuild', label: 'Charge Code', class: 'ChargeCode', custom: (value) => <span className='columTableStyle'>{value.NewBuild}</span> },
        { key: 'Department', label: 'Estimated', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.Department}</span> },
        { key: 'Supplier', label: 'Actual', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.Supplier}</span> },
        { key: 'POL', label: 'To', class: '% Difference', custom: (value) => <span className='columTableStyle'>{value.POL}</span> },
    ])
    const [data, setData] = useState([
        { Shipment: '1', Status: 'Delivered', NewBuild: 'MSC Europa', Department: 'Hotel', Supplier: 'Westex', POL: 'Montreal' },
        { Shipment: '2', Status: 'In Transit', NewBuild: 'MSC Europa', Department: 'Hotel', Supplier: 'Westex', POL: 'Montreal' },
        { Shipment: '3', Status: 'Readines Confirmed', NewBuild: 'MSC Europa', Department: 'Hotel', Supplier: 'Westex', POL: 'Montreal' },
        { Shipment: '4', Status: 'Delivered', NewBuild: 'MSC Europa', Department: 'Hotel', Supplier: 'Westex', POL: 'Montreal' },
    ])

    function changueLoader(params) {
        setTimeout(() => {
            setLoader(true)
        }, 3000);
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <Row>
                <Col xs={12} md={6} lg={6}>
                    <Table
                        columns={columns}
                        data={data}
                        // itemsByPage={10}
                        // styles={styles}
                        // configSearch={{ fieldsToFilter: ItemsToFilter }}
                        options={
                            {
                                card: false,
                                pagination: false,
                                search: false,
                                placeholder: 'Search ...',
                                selectItems: false,
                                xlsx: false,
                                Accordion: { Active: false, Title: 'Logistic Cost' },
                                borderless: true
                            }
                        }
                    />
                </Col>
            </Row>
        </>
    );
}

export default withRouter(Cost);

