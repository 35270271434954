import React from "react";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import { Row } from "reactstrap";
import { connect } from "react-redux";
// import { Services } from './../../Services';
import { Services } from "../../../Services";

/*=============================================
=            Catalogs Trends By Year          =
=============================================*/

function CatYears(props) {
  const [CatalogYears, setCatalogYears] = React.useState([]);
  const [CatalogModele, setCatalogModele] = React.useState([]);
  const [Module, setModule] = React.useState("");
  const [Textstate, setTex] = React.useState("");
  const [date, setDate] = React.useState(moment(new Date()).format("YYYY"));
  const { onChangeYear, clients } = props;

  const GetCatalog = React.useCallback(async () => {
    try {
      let data = {
        // customer:clientSave.getAuthentication('client')
        customer: clients ? JSON.stringify([clients]) : "[0]",
      };
      let CatYears = await Axios.post(
        `${Services.GET_WAREHOUSING.path}warehouse/catyears`,
        data
      );

      // let CatYears ={"message":"Customs list","status":200,"data":{"year":[{"key":2021,"value":2021,"flag":2021,"text":2021},{"key":2020,"value":2020,"flag":2020,"text":2020}],"module":[{"key":" Receipts","value":" Receipts","flag":" Receipts","text":" Receipts","date":"Receipts"},{"key":" Shipments","value":" Shipments","flag":" Shipments","text":" Shipments","date":"Shipments"},{"key":" Truckings","value":" Truckings","flag":" Truckings","text":" Truckings","date":"Truckings"}]}}
      const dataCats = CatYears.data.data;
      setCatalogYears(CatYears.data.data.year ? CatYears.data.data.year : []);
      setCatalogModele(
        CatYears.data.data.module ? CatYears.data.data.module : []
      );
      setModule(
        CatYears.data.data.module.length > 0
          ? CatYears.data.data.module[0].value
          : ""
      );
      setTex(
        CatYears.data.data.module.length > 0
          ? CatYears.data.data.module[0].date
          : ""
      );
      onChangeYear(
        date,
        dataCats.module.length > 0 ? dataCats.module[0].value : "",
        dataCats.module.length > 0 ? dataCats.module[0].date : ""
      );
    } catch (error) {
      console.error(error);
    }
  }, [clients,  onChangeYear]);

  function onChangeDate(evt, data) {
    setDate(data.value);
    props.onChangeYear(data.value, Module, Textstate);
  }
  function onChangeModule(evt, data) {
    let text = "";
    data.options.map((e) => {
      if (e.value === data.value) text = e.date;
    });
    setModule(data.value);
    setTex(text);

    props.onChangeYear(date, data.value, text);
  }

  React.useEffect(() => {
    GetCatalog();
  }, [GetCatalog]);

  return (
    <>
      <div>
        <Row>
          <Dropdown
            className="catalog-year-container"
            text={Module}
            scrolling
            placeholder="Receipts"
            // fluid
            // selection
            onChange={onChangeModule}
            defaultValue={Module}
            options={CatalogModele}
          />
          {/* <button style={{ fontSize: '11px', marginLeft: '-17px',marginTop:'10px'}}>▼</button> */}
        </Row>
      </div>

      <div style={{ marginLeft: "106px", marginTop: "-28px" }}>
        <Row>
          <Dropdown
            className="catalog-year-container"
            text={date}
            scrolling
            // placeholder="Year"
            // fluid
            // selection
            onChange={onChangeDate}
            defaultValue={date}
            options={CatalogYears}
          />
          {/* <button style={{ fontSize: '11px', marginLeft: '-17px',marginTop:'10px'}}>▼</button> */}
        </Row>
      </div>
    </>
  );
}

export default CatYears;
