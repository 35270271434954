import React, { Suspense } from "react";

import axios from "axios";
import { Card, CardBody } from "reactstrap";
import { Input, Button, Tab } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { message } from "antd";

import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { Services } from "../Services";

import { validateIdParams } from "../../../utils/regularExpressionValidation";

const QueryReport = React.lazy(() => import("./queryReport"));
const Preview = React.lazy(() => import("./preview"));

class ReportAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        data: {
          data: "No query available",
        },
      },
      reportName: "",
      descriptionReport: "",
      queryText: "",
      isEdit: props.match.params
        ? props.match.params.id
          ? true
          : false
        : false,
      ReportId: props.match.params
        ? props.match.params.id
          ? props.match.params.id
          : ""
        : "",
      customerId: props._customer,
      isDisabled: false,
    };
  }

  componentDidMount = () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editReport();
    }
  };

  goToReports = () => {
    this.props.history.push("/reporting");
  };

  saveReport = async () => {
    const { reportName, descriptionReport, queryText } = this.state;
    this.setState({ isDisabled: true });

    try {
      let band = true;

      if (!reportName) {
        message.error("Report name is required", 2);
        band = false;
        this.setState({ isDisabled: false });
      }

      // if(!queryText){
      //   message.error('Script is required', 2);
      //   band = false;
      //   this.setState({isDisabled: false});
      // }

      if (band) {
        let obj = {
          user: this.props._user,
          customer: this.props._customer,
          reportName: reportName,
          description: descriptionReport,
          date: new Date(),
          queryText,
        };

        await axios.post(
          `${Services.DOWNLOAD_FILES.path}reports/genStandarReport`,
          obj
        );
        message.success({
          content: "Report created successfully!",
          key: "POST",
          duration: 0.7,
        });

        setTimeout(() => {
          this.props.history.push("/reporting");
          this.setState({ isDisabled: false });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  queryResponse = (data) => {
    this.setState({ queryText: data });
  };

  editReport = () => {
    const { ReportId, customerId } = this.state;
    let idValidate = validateIdParams(ReportId);
    let idValidateCustomer = validateIdParams(customerId);
    if (!idValidate && !idValidateCustomer) {
      message.error("Data entered is invalid", 2);
      setTimeout(() => {
        this.props.history.push("/reports");
      }, 2000);
      return;
    }
    axios
      .get(
        `${Services.DOWNLOAD_FILES.path}reports/standar/report/${ReportId}/${customerId}`
      )
      .then((success) => {
        // axios.get(`http://localhost:3003/reports/standar/report/${ReportId}/${customerId}`).then(success => {
        const { Name, Description, ViewReport } = success.data.data[0];
        this.setState({
          reportName: Name,
          descriptionReport: Description,
          queryText: ViewReport,
        });
      })
      .catch((error) => {
        console.error("ReportAnalytics: Didmount:", error);
        message.error("Data entered is invalid", 2);
        setTimeout(() => {
          this.props.history.push("/reports");
        }, 2000);
        return;
      });
  };

  updateReport = async () => {
    const { reportName, descriptionReport, queryText, ReportId } = this.state;
    this.setState({ isDisabled: true });

    let band = true;

    if (!reportName) {
      message.error("Report name is required", 2);
      band = false;
      this.setState({ isDisabled: false });
    }

    // if(!queryText){
    //   message.error('Script is required', 2);
    //   band = false;
    //   this.setState({isDisabled: false});
    // }

    if (band) {
      let obj = {
        user: this.props._user,
        customer: this.props._customer,
        reportName: reportName,
        description: descriptionReport,
        reportId: ReportId,
        queryText,
      };

      await axios.put(
        `${Services.DOWNLOAD_FILES.path}reports/update/StandarReport`,
        obj
      );
      // await axios.put('http://localhost:3003/reports/update/StandarReport', obj);
      message.success({
        content: "Report updated successfully!",
        key: "POST",
        duration: 0.7,
      });
      setTimeout(() => {
        this.props.history.push("/reporting");
        this.setState({ isDisabled: false });
      }, 1000);
    }
  };

  render() {
    const { queryText, isEdit, isDisabled } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas fa-1x mr-2 fas fa-table" />
            My Reports
          </div>
        </div>

        <Card className="myCard">
          <CardBody>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px", color: "black" }}>
                General
              </strong>
            </div>
            <br></br>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  Report Name*
                  <div className="row">
                    <Input
                      placeholder="Report Name"
                      name="reportName"
                      className="emailInput"
                      id="reportName"
                      value={this.state.reportName}
                      onChange={(ev, result) => {
                        this.setState({ reportName: result.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  Description
                  <div className="row">
                    <Input
                      placeholder="Description"
                      name="descriptionReport"
                      className="emailInput"
                      id="descriptionReport"
                      value={this.state.descriptionReport}
                      onChange={(ev, result) => {
                        this.setState({ descriptionReport: result.value });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <br />
                  <Tab
                    menu={{ secondary: true, className: "myWrapped" }}
                    menuPosition="right"
                    panes={[
                      {
                        menuItem: "Query Report",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            {" "}
                            <QueryReport
                              query={this.queryResponse}
                              queryText={queryText}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        menuItem: "Preview",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            {" "}
                            <Preview data={queryText} />
                          </Suspense>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>

              <div className={"row"}>
                <div className={"col-4"}></div>
                <div className={"col-4"}></div>
                <div className={"col-4"}>
                  <Button
                    floated="right"
                    positive
                    compact
                    disabled={isDisabled}
                    onClick={!isEdit ? this.saveReport : this.updateReport}
                  >
                    Save
                  </Button>
                  <Button
                    floated="right"
                    negative
                    compact
                    onClick={this.goToReports}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default withRouter(ReportAnalytics);
