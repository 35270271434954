import React from 'react';
import propTypes from 'prop-types';
import { Table } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { Pagination, Grid} from 'semantic-ui-react';

import Search from './../Search';
import TableHeader from './components/Header/';
import TableBody from './components/TableBody';
import {Sort} from './handlers';
import './styles.scss';


/*=============================================
=            Login Handler For Table          =
=============================================*/

function init({search_text, total_pages, active_page, order_by, order_direction, rows, rows_search, selected_items}){
  return {
    search_text,
    total_pages,
    active_page,
    order_by,
    order_direction,
    rows,
    rows_search,
    selected_items
  };
}

function reducer(state, action){
  switch(action.type){
  case 'set_rows':
    return {
      ...state,
      rows: action.payload
    };
  case 'search':
    return {
      ...state,
      search_text: action.payload.search_text
    };
  case 'set_selected':
    return {
      ...state,
      selected_items: [...action.payload]
    };
  case 'set_order':
    return {
      ...state,
      order_by: action.payload.order_by,
      order_direction: action.payload.order_direction
    };
  case 'reset_pager':
    return {
      ...state,
      active_page: action.payload.active_page,
      total_pages: action.payload.total_pages
    };
  case 'active_page':
    return {
      ...state,
      active_page: action.payload
    };
  case 'reset_state':
    return init(action.payload);
  default:
    return state;
  }
}

/*=====  End of Login Handler For Table  ======*/

function calculatePages(total, itemsPerPage){
  const total_pages = total / itemsPerPage;
  return total_pages % 1 === 0 ? total_pages: Math.ceil(total_pages);
}

/*=============================================
=            Main Component                  =
=============================================*/
function TableMainComponent(props){
  const [state, dispatch] = React.useReducer(reducer,{
    rows: [...props.data],
    rows_search: [...props.data],
    search_text: '',
    total_pages: calculatePages(props.data.length, props.itemsPerPage),
    active_page: 1,
    order_by: '',
    order_direction: 'desc',
    selected_items: []
  }, init);

  function onSelecteItem(evt, item_no){
    // console.log('this are the selected items --->', state.selected_items);
    const selectedIndex = state.selected_items.indexOf(item_no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected_items, item_no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected_items.slice(1));
    } else if (selectedIndex === state.selected_items.length - 1) {
      newSelected = newSelected.concat(state.selected_items.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.selected_items.slice(0, selectedIndex),
        state.selected_items.slice(selectedIndex + 1),
      );
    }
    dispatch({type: 'set_selected', payload: newSelected});
    props.handleSelection(newSelected);
  }

  function isSelected(line_no){
    return state.selected_items.indexOf(line_no) !== -1;
  }

  function handleChangePage(evt, { activePage }){
    dispatch({type: 'active_page', payload: activePage});
  }
  function resetPager(totalPages, activePage){
    dispatch({type: 'reset_pager', payload: {active_page: activePage, total_pages: calculatePages(totalPages, props.itemsPerPage)}});
  }

  function sortData(key, direction){
    dispatch({type: 'set_order', payload: {order_by: key, order_direction: direction}});
    dispatch({type: 'set_rows', payload: Sort([...state.rows], key, direction)});
  }
  // ! maybe deprecated funcion
  function handleItemsPerPage(){
    // return totalItems > props.itemsPerPage ? 1: 0;
    return 1;
  }
  function handleOnSearch(data){
    if (state.order_by){
      dispatch({type: 'set_rows', payload: [...Sort([...data], state.order_by, state.order_direction)]});
      resetPager(data.length, handleItemsPerPage());
    } else {
      dispatch({type: 'set_rows', payload: [...data]});
      resetPager(data.length, handleItemsPerPage());
    }
  }
  React.useEffect(() => {
    dispatch({
      type: 'reset_state', 
      payload: {
        rows: [...props.data],
        rows_search: [...props.data],
        search_text: '',
        total_pages: calculatePages(props.data.length, props.itemsPerPage),
        active_page: 1,
        order_by: '',
        order_direction: 'desc',
        selected_items: []
      }
    });
  }, [props.data]);

  return (
    <div className="col-12">
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={4}>
            {props.options.search ?
              <Search data={state.rows_search} handler={handleOnSearch} configSearch={props.configSearch} width={'100%'}></Search>
              : ''}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="col-12" style={{ paddingTop: '1%' }}></div>
      <div className={`${props.fixed ? 'zui-scroller' : 'col-12'}`}>
        <Table id="_table_overflow" responsive className={`zui-table ${props.type === 'GENERIC' ? 'table-height table table-sticky table-bordered' : ''}`}>
          <thead style={{ textAlign: 'center', }}>
            <tr style={{ cursor: 'pointer' }}>
              <TableHeader 
                columns={props.columns} 
                selectItems={props.options && props.options.selectItems}
                handleSort={sortData}
                sortKey={state.order_by}
                direction={state.order_direction}
              />
            </tr>
          </thead>
          <tbody className="table-body">
            <TableBody 
              rows={state.rows}
              columns={props.columns}
              selectItems={props.options && props.options.selectItems}
              keyCheck={props.keyCheck}
              onSelectItem={onSelecteItem}
              isSelected={isSelected}
              page={state.active_page - 1 }
              rowsPerPage={props.itemsPerPage}
              cellType={props.cellType}
            />
          </tbody>
        </Table>
      </div>
      {
        props.options && props.options.pagination ?
          <div className="col-12">
            <hr />
          </div> : ''
      }
      <div className="col-12" >
        {
          props.options && props.options.pagination ?
            <Pagination
              ellipsisItem={null}
              activePage={state.active_page}
              onPageChange={handleChangePage}
              totalPages={state.total_pages}>
            </Pagination>
            : ''
        }
      </div>
      {
        props.options && props.options.pagination ?
          <div className="col-12">
            <hr />
          </div> : ''
      }
    </div>    
  );
}

TableMainComponent.propTypes = {
  cellType: propTypes.oneOf(['tick-rows', 'thin-rows']),
  data: propTypes.array,
  fixed: propTypes.bool,
  type: propTypes.string,
  itemsPerPage: propTypes.number,
  keyCheck: propTypes.string,
  handleSelection: propTypes.func
};
TableMainComponent.defaultProps = {
  cellType: 'thick-rows',
  data: [],
  fixed: false,
  type: '',
  itemsPerPage: 10,
  keyCheck: '',
  handleSelection: () => {}
};

export default TableMainComponent;
