import React, { Component } from 'react';
import {saveAs} from 'file-saver';
class EUGround extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns : [
        { label : 'ID', key : 'ID'},
        { label : 'Description' ,isLarge:true, key : 'Description'},
        { label : 'UnitCost', key : 'UnitCost'},
        { label : 'Currency', key : 'Currency'},
        { label : 'UnitOfMeasure', key : 'UnitOfMeasure'},
        { label : 'Group', key : 'Group'}
      ],
      aux : {},
      data : [],
      xlsx :  <em className="fa-2x fas fa-file-excel mr-2" 
        style={{ color: '#66bb6a', cursor: 'pointer' }} 
        onClick={this.XLSX} />
    };
  }


    componentDidMount = () => {
      this.setState({
        aux : this.props.aux
      },()=>this.buildData());
    }


    componentWillReceiveProps = (nextProps) => {
      this.setState({
        aux : nextProps.aux
      },()=>this.buildData());
    }


    buildData = ()=>{
      let {aux } = this.state;
      let Keys = Object.keys(aux);
      let data = [];
      Keys.map((e)=>{
        aux[e].forEach((item)=>{
          data.push(item);
        });
      });
      this.setState({
        data
      });
    }


    XLSX = () => {
      this.setState({
        xlsx: <div className="ball-clip-rotate">
          <div></div>
        </div>
      }, () => {
        fetch('https://poseidonapireports.azurewebsites.net/xlsx/', {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            data: this.state.data,
            columns: this.state.columns
          })
        }).then(data => {
          return data.json();
        }).then(success => {
          this.setState({
            xlsx: <div><em className="fa-2x fas fa-file-excel mr-2" style={{ color: '#66bb6a', cursor: 'pointer' }} onClick={this.XLSX}></em></div>
          }, () => {
            if (success.base64) {
              const linkSource = `data:application/xlsx;base64,${success.base64}`;
              const fileName = 'RATES_EU_GROUND_CONTAINER_TRANS_' + new Date() + '.xlsx';
              saveAs(linkSource, fileName);
            }
          });
        }).catch(error => {
          console.warn(error);
          this.setState({
            xlsx: <div><em className="fa-2x fas fa-file-excel mr-2" style={{ color: '#66bb6a', cursor: 'pointer' }} onClick={this.XLSX}></em></div>
          }, () => {});
        });
      });
    }

    render() {
      return (
            <>
                {this.state.xlsx}
            </>
           
      );
    }
}

export default EUGround;


