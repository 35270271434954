import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { Row } from "reactstrap";
// import restService from '../../../../services/restService';
import { Tab } from "semantic-ui-react";
import ShipmentModel from "./models/shipment.model";
import shipmentDataPlaneModel from "./models/shipment.plane.model";
import Axios from "axios";
import { Services } from "./../Services";
import Loader from "./../../../Global/Loader";
import { Button } from "reactstrap";
import { message } from "antd";
import { validateIdParams } from "../../../utils/regularExpressionValidation";
import Errors from "../../../../components/template/Errors";

const Summary = React.lazy(() => import("./components/summary"));
const Documents = React.lazy(() => import("./components/documents"));
const Comments = React.lazy(() => import("./components/comments"));
const Events = React.lazy(() => import("./components/events"));

/*=============================================
=            Tracking summary component       =
=============================================*/

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panes: [],
      shipment: ShipmentModel,
      shipmentDataPlane: shipmentDataPlaneModel,
      steps: [],
      fullLoader: false,
      error: {
        open: false,
        message: "",
        type: "admin",
        errorValidate: false,
      },
      copyUrl: false,
    };
  }

  getShareURL = async () => {
    this.setState({ copyUrl: true });
    setTimeout(() => {
      this.setState({ copyUrl: false });
    }, 5000);
    let success = await Axios.get(
      `${Services.GET_ONE_SHIPMENT.path}/shipment/public/${
        parseInt(this.props.match.params.id)
          ? parseInt(this.props.match.params.id)
          : this.props.match.params.id
      }`
    );
    if (success.data && success.data.url) {
      navigator.clipboard.writeText(success.data.url);
      this.setState(
        {
          error: {
            message:
              "Link was copied to clipboard.\nLink will expire in 70 days.",
            open: true,
            type: "admin",
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: {
                message: "",
                open: false,
                type: "admin",
              },
            });
          }, 10000);
        }
      );
    } else {
      message.error("Error to generate url", 2);
    }
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  componentDidMount = async () => {
    let idValidate = validateIdParams(this.props.match.params.id);
    if (!idValidate) {
      message.error("Data entered is invalid", 2);
      setTimeout(() => {
        this.props.history.push("/shipment/summary");
      }, 2000);
      return;
    }
    let success = await Axios.get(
      `${Services.GET_ONE_SHIPMENT.path}/shipment/${
        parseInt(this.props.match.params.id)
          ? parseInt(this.props.match.params.id)
          : this.props.match.params.id
      }`
    );
    // let success = await Axios.get(`http://localhost:3001/shipment/${parseInt(this.props.match.params.id) ? parseInt(this.props.match.params.id): this.props.match.params.id}`);
    // console.log("success?????",success.data)
    if (success.data && success.data.data) {
      success.data.data.Route.ROD = success.data.plane.ROD;
      success.data.data.Route.ROL = success.data.plane.ROL;
      this.setState(
        {
          shipment: success.data.data,
          shipmentDataPlane: success.data.plane,
        },
        () => this.handleGetEvents(success.data.data.ShipmentId)
      );
    }
  };

  handleGetEvents = async (shipmentId) => {
    let moduleId = 3;
    let events = await Axios.get(
      `${Services.GET_ALL_BOOKINGS.path}/containers-booking/containerEventsTimeline/${moduleId}/${shipmentId}`
    );
    // let events = await Axios.get(`http://localhost:3017/containers-booking/containerEventsTimeline/${moduleId}/${shipmentId}`);
    if (events.data) {
      let update = false;
      for (let index = events.data.steps.length; index >= 0; index--) {
        const element = events.data.steps[index];
        if (element && element.completed) update = true;
        if (update) element.completed = true;
      }
      this.setState({ steps: events.data.steps, fullLoader: true });
    }
  };

  render() {
    const { shipment, shipmentDataPlane, steps, fullLoader } = this.state;
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader} />
        <div className="content-heading">
          <Row>
            <legend>
              <em className="fas fa-globe fa-1x mr-2"></em>Shipment Details
            </legend>
          </Row>
        </div>

        <div className="float-right">
          <Button
            disable={this.state.copyUrl}
            onClick={this.getShareURL}
            style={{ borderColor: "gray" }}
          >
            {
              <span style={{ color: "black", marginTop: "16px" }}>
                <em className="fas fa-share"></em> Share
              </span>
            }
          </Button>
        </div>
        <Errors
          open={this.state.error.open}
          type={this.state.error.type}
          message={this.state.error.message}
          setProps={this.recieveProps}
        ></Errors>
        <Tab
          menu={{ secondary: true, className: "myWrapped" }}
          menuPosition="right"
          panes={[
            {
              menuItem: "Summary",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Summary
                    rolId={this.props._rolId}
                    shipment={shipment}
                    shipmentDataPlane={shipmentDataPlane}
                    steps={steps}
                  ></Summary>
                </Suspense>
              ),
            },
            {
              menuItem: "Events",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Events
                    shipment={shipment}
                    shipmentDataPlane={shipmentDataPlane}
                  ></Events>
                </Suspense>
              ),
            },
            {
              menuItem: "Comments",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Comments
                    shipment={shipment}
                    shipmentDataPlane={shipmentDataPlane}
                    _user={this.props._user}
                  ></Comments>
                </Suspense>
              ),
            },
            {
              menuItem: "Documents",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Documents
                    rolId={this.props._rolId}
                    shipment={shipment}
                    shipmentDataPlane={shipmentDataPlane}
                    _user={this.props._user}
                  ></Documents>
                </Suspense>
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}

export default withRouter(Tracking);
