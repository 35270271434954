import React from 'react'
import { Button, Row, Col, UncontrolledCollapse } from 'reactstrap'
import propTypes from 'prop-types';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
import { DatePicker, Space } from 'antd';
import { Dropdown } from 'semantic-ui-react';

const { RangePicker } = DatePicker;

function Filters({ dataForm, Catalogs, funtionFilter }) {
	const FORM_ITEMS = dataForm.map((item, key) => {
		// console.log('item-->', item.id);
		// console.log('Catalogos-->', Catalogs['Project']);
		if (item.type === 'multiSelect') {
			return (
				< Col key={key} md={item.colm} xl={item.colm} className={item.class} >
					<label className='input__label'>
						{`${item.label}`}
					</label>
					<Dropdown
						tabIndex={item.id}
						key={item.id}
						placeholder={item.label}
						fluid
						multiple
						search
						selection
						options={Catalogs[item.idNameCat]}
						// options={[{ key: '1', text: 'Angular', value: '1' },
						// { key: '2', text: 'CSS', value: '2' },]}
						onChange={(evt, data) => { funtionFilter(evt, data, item) }}
					/>
					{/* <Autocomplete
						options={Catalogos[item.name]}
						onChange={(evt, data) => { handleChange(evt, data, item); }}
						getOptionLabel={(option) => option.title}
						renderInput={(params) => (
							<TextField {...params} label={item.label} variant="standard" />
						)}
					/> */}
				</Col >
			)
		} else if (item.type === 'date') {
			return (
				< Col key={key} md={item.colm} xl={item.colm} className={item.class} >
					<label className='input__label'>
						{item.label}
					</label>
					<RangePicker
						format="DD/MM/YYYY"
						style={{ width: '100%', borderRadius: '0.28571429rem', }}
						onChange={(evt, data) => { funtionFilter(evt, data, item) }}


					// onChange={(evt, data) => handleChange(evt, data, item)}
					/>
					{/* <DatePicker
						// onChange={(date, dateString) => { onChange(date, dateString, item, TabName) }}
						// defaultValue={Model && Model[item.id].value ? (moment(Model[item.id].value, 'DD/MM/YYYY')) : ''}
						format={'DD/MM/YYYY'}
						style={{ width: '100%', borderRadius: '0.28571429rem', }}
					/> */}
				</Col >
			)
		}
	})

	return (

		<React.Fragment>
			<UncontrolledCollapse toggler="#toggler">
				<Row>
					{FORM_ITEMS}
				</Row>
			</UncontrolledCollapse>
		</React.Fragment>
	);
}

Filters.propTypes = {
	dataForm: propTypes.array,
	Catalogos: propTypes.object,


	Model: propTypes.object,
	onChange: propTypes.func,
	placeholders: propTypes.object,
	type: propTypes.string,
};

Filters.defaultProps = {
	dataForm: [],
	Catalogos: {},

	inptIconHandler: () => { },
	Model: {},
	onChange: () => { },
	placeholder: undefined,
	// type: '---'
};

export default Filters