import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import POLines from './polines';
// import Button from '@material-ui/core/Button';
// import Icon from '@material-ui/core/Icon';
import {Card,CardBody} from 'reactstrap';
import Errors from '../../../../../template/Errors';
import Tables from '../../../../../template/Table';
import swal from 'sweetalert';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import restService from '../../../../../../services/restService';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';

// ? styles
import './styles.scss';

const rest = new restService();
class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PONumber: this.props.PONumber ? this.props.PONumber : 0,
      items: [],
      SelectedIsLoaded: false,
      columns: [
        { label: 'Line Number', key: '', index: true },
        { label: 'Item Number', key: 'ItemNumber' },
        { label: 'Item Description', key: 'ItemDescription' },
        { label: 'Qty Ordered', key: 'QtyOrdered' },
        { label: 'Unit Measure', key: 'UnitMeasure' },
        { label: 'Origin Country Id', key: 'OriginCountryId' },
        { label: 'Vendor Code', key: 'VendorCode'},
        { label: 'Collection', key: 'Collection'},
        { label: 'Style', key: 'Style'},
        { label: 'Color', key: 'Color'},
        { label: 'Size', key: 'Size'},
        { label: 'Actions', key: '', custom: (value) => this.custom1(value) }
      ],
      POLineComponent: '',
      error: {
        open: false,
        message: '',
        type: 'error'
      },
      errors : {},
      styleLabelItems : {}
    };
  }
    
  /*=============================================
    =            BUtton delete Item               =
    =============================================*/
        
    custom1 = (value) => (
      <Fab color="secondary" size="small" aria-label="remove" onClick={this.deleteLine(value)}>
        <RemoveIcon className="iconRemove" />
      </Fab>        
    );
    
    /*=============================================
    =            Set Items For Table              =
    =============================================*/
    
   setItems = (item) => {
     this.setState({
       items: [...this.state.items, item]
     }, () => {
       this.props.sendItems(this.state.items);
     });
   }
    deleteLine = index => event => {
      let { items, isDraft } = this.state;
      if (isDraft) {
        swal('Delete', 'Item ' + index.ItemNumber, 'warning').then(() => {
          rest.EXEC({
            _function: 'RemoveOneItemFromOrder', params: '', data: {
              ItemNumber: index.ItemNumber,
              PONumber: this.props.PONumber
            }
          }, fetch).then(success => {
            this.removeLine(index, items);
          }).catch(error => {
            console.error('delete items:', error);
          });
        });
      }
      else this.removeLine(index, items);
    }
    removeLine = (index, items) => {
      items.splice(index, 1);
      this.setState({
        error: {
          message: 'A line item has been removed correctly',
          open: true,
          type: 'warning'
        }
      }, () => {
        this.props.sendItems(this.state.items);
      });
    }
    componentDidMount = () => {
      let {styleLabelItems} = this.state;
      this.setState({
        POLineComponent: <POLines refreshItems={this.setItems} ></POLines>,
        errors : this.props.errors
      },()=>{
        if(Object.keys(this.state.errors).length > 0){
          styleLabelItems = {color : this.state.errors['items'] ? 'red':'black'};
        }
        this.setState({
          styleLabelItems
        });
      });
    }
    componentWillReceiveProps = (nextProps) => {
      let { selected, isDraft } = this.props;
      let { SelectedIsLoaded, items,styleLabelItems } = this.state;
      if (Array.isArray(selected) && selected.length > 0 && Array.isArray(selected[0].items) && selected[0].items.length > 0 && !SelectedIsLoaded && items.length === 0) {
        this.setState({
          items: this.parseItems(selected[0].items),
          SelectedIsLoaded: true,
          isDraft,
          errors : nextProps.errors
        }, () => {
          if(Object.keys(this.state.errors).length > 0){
            styleLabelItems = {color : this.state.errors['items'] ? '#e57373':'black'};
          }
          this.setState({
            styleLabelItems
          },()=>this.props.sendItems(this.state.items));
        });
      }
      else{
        this.setState({
          errors : nextProps.errors
        },()=>{
          if(Object.keys(this.state.errors).length > 0){
            styleLabelItems = {color : this.state.errors['items'] ? '#e57373':'black'};
          }
          this.setState({
            styleLabelItems
          });
        });
      }
    }
    parseItems = (items) => {
      return items.map((e) => {
        return ({
          ItemDescription: e.ItemDescription,
          ItemNumber: e.ItemNumber,
          OriginCountryId: e.CountryOrigin,
          QtyOrdered: e.QtyOrdered,
          UnitMeasure: e.UnitsMeasure
        });
      });
    }
    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })
    render() {
      let { items } = this.state;
      return (
        <Card className="myCard">
          <CardBody>
            <ExpansionPanel elevation={0} defaultExpanded >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <legend id ="items" style={this.state.styleLabelItems}><em className="fas fa-stream fa-1x mr-2"></em>Line Items</legend>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-12">
                    {this.state.POLineComponent}
                  </div>
                  <div className="col-md-12">
                    <hr></hr>
                  </div>
                  <div className="col-md-12">
                    <Tables data={items} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
                  </div>
                  <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>

        </Card>
            
      );
    }
}
export default ViewOrder;
