const shipment = {
    ATA: '',
    ATD: '',
    Action: "",
    BookingNumber: '',
    CargoReady: '',
    Carrier: {
        CarrierName: "", VesselOrFlight: "", Latitude: "", Longitude: ""
    },
    Consignee: { name: "", address: "", pickupDate: "" },
    Cruise: { CruiseName: "" },
    Delivery: { name: "", address: "", pickupDate: "" },
    Dispatch: { status: "", pickupDate: "", sentDate: "" },
    ETA: "",
    ETD: "",
    Events: {},
    Exceptions: {},
    From: {
        Contact: {
            contactDetail: "",
            name: ""
        }, Location: {
            Latitude: "",
            Longitude: "",
            address: "",
            city: "",
            country: "",
            state: "",
            zipcode: "",
        }
    },
    LastUpdate: { porcent: "", date: "" },
    Notes: "",
    PONumber: "",
    Shipper: { name: "", address: "", pickupDate: "" },
    Shipping: { carrier: "", service: "" },
    Status: { label: "", id: 41 },
    Supplier: { name: "", address: "", pickupDate: "" },
    To: {
        Contact: {
            contactDetail: "",
            name: ""
        }, Location: {
            Latitude: "",
            Longitude: "",
            address: "",
            city: "",
            country: "",
            state: "",
            zipcode: "",
        }
    },
    Traking: { name: "" },
    TransportMode: { id: null, name: "" },
    estDelivery: "",
    id: "",
    reqPicukp: "",
    trackingStatus:"",
    Route : {
        Origin : '',
        Destination : '',
        ROL : '',
        ROD : '',
        FinalDestination : '',
        ETD : '',
        ETA : '',
        ATD : '',
        ATA : ''
    },
    Cargo : {
        totalWeight:0,
        totalVolume:0,
        totalPieces:0,
        totalPackages:0,
        totalContainers:0,
        totalTeus:0
    },
    ExtraFields : {
        DescriptionOfGods : 'Hotel and Bathrooms',
        DeclaredValue : '$1,500.00',
        FinalDestination : 'Queretaro',
        LocalAgentAtOrigin: 'John Macintosh',
        LocalAgentAtDestination:'Marc Garopolo'
    },
    Containers : [],
    Packages : [],
    Project : {
        id : '',
        name : ''
    },
    POs : []
}

export default shipment