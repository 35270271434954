import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import {withRouter} from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Update from './components/Update';
import Upload from './components/Upload';
import 'react-datetime/css/react-datetime.css';
import './styles.scss';
import axios from 'axios';
import moment from 'moment';
import Errors from './../../../template/Errors';


import {Services} from './../Services';

class MultiUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [{ value: 2, label: 'Actual Cargo Ready' }],
      CreateFormData: [],
      active: true,
      data: [],
      loading: false,
      error: {
        open: false,
        type: '',
        message: ''
      },
    };
  }

  componentDidMount = () => {
    this.getOrdersList();
  }

  getOrdersList = async (array) => {    
    try {
      let success = await axios.get(`${Services.GET_ONE_ORDER.path}order/search/all?type=idCustomer&id=${this.props._customer}`);
      success.data.data.map((e) => e.ActualArrivalConsolWH = e.ActualArrivalConsolWH ? moment(e.ActualArrivalConsolWH).format('YYYY-MM-DD') : '');
      if(array){
      let newArray =[]
      success.data.data.map((e)=>{
        array.map((w)=>{
          if (w.POId ===e.POId){
            newArray.push(e)
          }
          return w;
        })
        return e;
      });
      
      let successCount = [];
      let errorCount = [];

      for (let index = 0; index < newArray.length; index++) {
        const ModelEdit = newArray[index];
        const url = 'https://apiposeidon.azurewebsites.net';
        // const url = `http://localhost:3004`;
            let resp = await axios.put(`${url}/order/update/po/${ModelEdit.POId}`, ModelEdit);
            if (resp.data.success){
              successCount.push({
                id : ModelEdit.POId
              });
            } else{
              errorCount.push(ModelEdit.POId);
            } 

            if (index + 1 === newArray.length){
              if (errorCount.length >0 ){
              let ids= errorCount.toString()
              this.setState({error: {open: true, type: 'error', message: `Error trying update: ${ids}`}});
              }
              else {
                this.setState({error: {open: true, type: 'success', message: 'Data updated successfully!'}})
              }
            }
        }
      }
      this.setState({ data: success.data.data, loading: false });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }

  putOrdersPo = async (array) => {    
    try {
      await axios.post(`${Services.GET_ONE_ORDER.path}order/multiupdate/`, {data: array});
      this.getOrdersList(array);
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }

  recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } });


  render() {
    let { data,error } = this.state;
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fa-1x mr-2 fas icon-cloud-upload"></em>
              Purchase Orders - Multiple Update
            </div>
          </div>
        </div>
        <Tab
          loading={true}
          menu={{ secondary: true, className: 'myWrapped' }}
          menuPosition='right'
          panes={[
            { menuItem: 'Update', render: () => <Update putOrdersPo={this.putOrdersPo} data={data}></Update> },
            { menuItem: 'Upload', render: () => <Upload ></Upload> },
          ]} />
                    <Errors open={error.open} type={error.type} message={error.message} setProps={this.recieveProps}></Errors>
      </ContentWrapper>
      
    );
  }
}

export default withRouter(MultiUpdate);