import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { Menu, Segment, Sidebar, Search } from "semantic-ui-react";
import { connect } from "react-redux";
import { SetFilterUrl } from "./../../../actions/Orders/List";
import Table from "../../template/Table";
import Axios from "axios";
import { Switch, Modal } from "antd";
import Swal from "sweetalert2";
import { Icon } from "semantic-ui-react";
import { subscribeToSocket } from "./socket";
import ContentWrapper from "../../template/Layout/ContentWrapper";
// import Table from '../../../template/Table';
// import Loader from './../../../Global/TableLoader';
import { Services } from "./Services";
import { columns, CloseIcon, columnsWps } from "./config";
import Authentication from "../../../services/Authentication.js";
import Errors from "../../../components/template/Errors";
import { validateIdParams } from "../../utils/regularExpressionValidation";

// import Comments from './components/Comments/';
// import Data from './object';
// import './styles.scss';
// const auth = new Authentication();
import "./styles.scss";

const auth = new Authentication();

class Anagrafico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSearch: false,
      error: {
        open: false,
        message: "",
        type: "error",
      },
      wpsSelected: [],
      search: "",
      selected: {},
      visible: false,
      dataRespaldo: [],
      dataWPS: [],
      wpsFlat: false,
      data: [],
      //   loading: true,
      columns: columns,
      columnsWps: columnsWps,
      //   tableLoader: false
    };
  }

  componentDidMount = () => {
    if (this.props.filterUrl) {
      this.props.setFilterUrl("");
    }
    this.getOrdersList();
    subscribeToSocket(this.props._customer, (error, message) => {
      if (!error) {
        if (message.customer === this.props._customer) {
          //   this.resetTable();
        }
      }
    });
  };

  /*=============================================
  =            Get Order List                   =
  =============================================*/

  getOrdersList = async () => {
    try {
      let id = auth.getAuthentication("customerSelect");
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/anagrafico");
        }, 2000);
        return;
      }
      // let success = await Axios.get(`http://localhost:3044/anagrafico/all/${id}`);
      let success = await Axios.get(
        `${Services.GET_CONSOLPLAN_SERVICE.path}anagrafico/all/${id}`
      );
      this.setState({ data: success.data.data, loading: false });
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/anagrafico");
      }, 2000);
      return;
    }
  };

  onChangeSwich = (checked) => {
    let { dataRespaldo, data } = this.state;
    let newdata = [];

    data.map((e) => {
      if (e.flat === 0) {
        newdata.push(e);
      }
    });
    if (checked) {
      this.setState({
        data: newdata,
        dataRespaldo: data,
      });
    } else {
      this.setState({
        data: dataRespaldo,
        // dataRespaldo:data
      });
    }
  };

  /*=============================================
  =    Modal Padre funtion to component table  =
  =============================================*/

  modalPadre = (id) => {
    let selected = [];
    selected.push(id);
    this.setState({
      visible: true,
      selected,
    });
  };

  modalPadrewps = (id) => {
    let wpsSelected = [];
    wpsSelected.push(id);
    this.setState({
      wpsSelected,
      wpsFlat: true,
    });
  };

  /*=============================================
=    Search for Query     =
=============================================*/

  handleSearch = (name) => (event) =>
    this.setState({ [name]: event.target.value });
  setSearch = async () => {
    try {
      let { search } = this.state;
      let idValidate = validateIdParams(search);
      if (!idValidate) {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/anagrafico");
        }, 2000);
        return;
      }
      // let success = await Axios.get(`http://localhost:3044/anagrafico/wpsanagrafico/${search}`);
      let success = await Axios.get(
        `${Services.GET_CONSOLPLAN_SERVICE.path}anagrafico/wpsanagrafico/${search}`
      );
      this.setState({
        dataWPS: success.data.data,
        loadingSearch: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({ loadingSearch: false, loading: false, dataWPS: [] });
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/anagrafico");
      }, 2000);
      return;
    }
  };

  asociationWps = () => {
    let { selected, wpsSelected } = this.state;

    Swal.fire({
      title: `Are you sure you want to update the following information?`,
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#20c554",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes I´m sure",
      cancelButtonText: "No",
      customClass: {
        popup: "format-pre",
      },
      html: `
        <br></br>
        <table id="table" border=0>
          <thead>
            <tr>
              <th style="width:13em;" >Poseidon Code</th>
              <th style="width:13em;" >Name</th>
              <th style="width:13em;" >Poseidon Adreess</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${selected[0].Code}</td>
              <td>${selected[0].Name}</td>
              <td>${selected[0].Address}</td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <table id="table" border=0>
          <thead>
            <tr>
              <th style="width:13em;" >WPS Code</th>
              <th style="width:13em;" >Name</th>
              <th style="width:13em;" >WPS Adreess</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${wpsSelected[0].EntryCode}</td>
              <td>${wpsSelected[0].EntityName}</td>
              <td>${wpsSelected[0].Address}</td>
            </tr>
          </tbody>
        </table>
        `,
      preConfirm: () => {
        this.updateAsociationWps();
      },
    }).then((result) => {
      if (!result.value) {
        // console.log("cancelo")
      }
    });
  };

  updateAsociationWps = async () => {
    let { selected, wpsSelected } = this.state;
    try {
      let dataUserPut = { anagrafico: selected, wps: wpsSelected };
      // let success = await Axios.put(`http://localhost:3044/anagrafico/updateanagrafico`,dataUserPut);
      let success = await Axios.put(
        `${Services.GET_CONSOLPLAN_SERVICE.path}anagrafico/updateanagrafico`,
        dataUserPut
      );

      this.setState(
        {
          visible: false,
          wpsFlat: false,
          dataRespaldo: [],
          dataWPS: [],
          wpsSelected: [],
          search: "",
          error: {
            message: "Associated Anagrafico",
            open: true,
            type: "success",
          },
        },
        () => this.getOrdersList()
      );
      // console.log("success", success.data);
    } catch (err) {
      console.error(err);
      this.setState({
        error: {
          message: "Line Item Removed",
          open: true,
          type: "error",
        },
      });
    }
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    const {
      loadingSearch,
      data,
      columns,
      visible,
      columnsWps,
      dataWPS,
      search,
      wpsFlat,
      wpsSelected,
    } = this.state;
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-database fa-1x mr-2" />
              Anagrafico
            </div>
          </div>
        </div>
        <Card className="myCard">
          {/* <div style={{ marginLeft: '2em' }} className='drop_content row' >
              <Switch style={{ marginTop: '9px' }} defaultChecked={false} onChange={this.onChangeSwich} />
              <h5 style={{ marginTop: '13px', fontWeight: 'bold', marginLeft: '1em' }}>Show Unassigned</h5> <em style={{ color: 'red', marginTop: '13px', marginLeft: '1em' }} className="fa-1x mr-1 fas fa-circle"></em>
            </div> */}
          <CardBody>
            <Table
              columns={columns}
              // toFilter={KeysFilter}
              data={data}
              itemsByPage={10}
              // styles={styles}
              // name="OrderList"
              // modalName="ui Modal body order list"
              customer={this.props._customer}
              RolId={this.props._rolId}
              user={this.props._user}
              swich={this.onChangeSwich}
              modalPadre={this.modalPadre}
              options={{
                anagraficoSwich: true,
                // colsolPlan: {
                //   redirect: '/new-consol-plan',
                //   return: '/consol-plan'
                // },
                pagination: true,
                search: true,
                contData: true,
                // modal: true,
                // downloads: {
                //   xlsx: true,
                //   pdf: false
                // },
                // selectItems: false,
                // orderAttention: {
                //   activeFilter: searchOnInit,
                //   activeText: searchText,
                //   search: true,
                //   xlsx: true
                // },
                // trigger: this.selectedOrder,
                // handleResCom: handleResCom
              }}
              cellType={"thick-rows"}
            />
          </CardBody>
        </Card>

        <Modal
          title="WPS Anagrafico"
          centered
          visible={visible}
          // onOk={() => setVisible(false)}
          onCancel={() =>
            this.setState({
              visible: false,
              dataWPS: [],
              search: "",
              wpsFlat: false,
              wpsSelected: [],
            })
          }
          width={1000}
          footer={null}
        >
          <div className="row">
            <div className="col-6">
              {wpsFlat ? (
                <></>
              ) : (
                <Search
                  input={{ icon: "search", iconPosition: "left", fluid: true }}
                  placeholder="..."
                  value={search}
                  open={false}
                  name="search"
                  id="search"
                  onSearchChange={this.handleSearch("search")}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.setState({ loadingSearch: true }, () =>
                        this.setSearch()
                      );
                    }
                  }}
                />
              )}
            </div>
            {wpsFlat ? (
              <></>
            ) : (
              <div style={{ textAlign: "end" }} className="col-1">
                <Button
                  className={"btn-oval"}
                  color={"primary"}
                  onClick={() =>
                    this.setState({ loadingSearch: true }, () =>
                      this.setSearch()
                    )
                  }
                >
                  {loadingSearch ? (
                    <center>
                      {" "}
                      <Icon
                        loading={loadingSearch}
                        style={{ background: "transparent" }}
                        name="circle notch"
                        size="large"
                      />
                    </center>
                  ) : (
                    <> Search</>
                  )}
                </Button>
              </div>
            )}

            <div
              hidden={wpsFlat ? false : true}
              style={{ textAlign: "end" }}
              className="col-6"
            >
              <Button
                style={{ marginTop: "-32px" }}
                className={"btn-oval"}
                color={"success"}
                onClick={() => this.asociationWps()}
              >
                Confirm
              </Button>
            </div>
          </div>
          <Table
            columns={columnsWps}
            data={wpsFlat ? wpsSelected : dataWPS}
            itemsByPage={7}
            customer={this.props._customer}
            RolId={this.props._rolId}
            user={this.props._user}
            swich={this.onChangeSwich}
            modalPadre={wpsFlat ? "" : this.modalPadrewps}
            options={{
              contData: wpsFlat ? false : true,
              pagination: wpsFlat ? false : true,
            }}
            cellType={"thick-rows"}
          />
        </Modal>
        <Errors
          open={this.state.error.open}
          type={this.state.error.type}
          message={this.state.error.message}
          setProps={this.recieveProps}
        ></Errors>
      </ContentWrapper>
    );
  }
}

const MapStateToProps = (state) => ({
  filterUrl: state.order_list.filterUrl,
});

const MapDispatchToProps = (dispatch) => ({
  setFilterUrl: (url) => dispatch(SetFilterUrl(url)),
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withRouter(Anagrafico));
