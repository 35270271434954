import React, { Component, Suspense } from "react";
import "react-select/dist/react-select.css";
import "react-datetime/css/react-datetime.css";
import { Card, CardBody, Row, Button, Col, Input } from "reactstrap";
import Errors from "../../../../../template/Errors";
import Tables from "../../../../../template/Table";
import { Link } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Fade from "react-reveal/Fade";
import { Modal } from "antd";
import Axios from "axios";
import { Services } from "../../../Services";
import Authentication from "../../../../../../services/Authentication.js";
// ? styles
import "./styles.scss";
const auth = new Authentication();

const AnagraficoSpace = React.lazy(() => import("../Anagrafico"));

// {(event)=>{

//   let value2 = event.target.value;
// console.log("value2",value2);
// const emailRegex = new RegExp(/^\d*\.?\d*$/);
// if (emailRegex.test(value2)) {
// console.log("if",emailRegex.test(value2));
// this.handleChange(e.id)
// } else {
// console.log("else");
// }

// }}
const mapColor = {
  12: "danger",
  13: "secondary",
};

class Polist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelOperacion: false,
      itemComplete: {},
      disabledButton: true,
      itemSelected: [],
      itemAnagrafico: [],
      anagraficoList: false,
      CATPOLIST: this.props.CATPOLIST ? this.props.CATPOLIST : [],
      order: this.props.order ? this.props.order : {},
      CreateOrderFormData: [],
      Form: this.props.Form ? this.props.Form : {},
      view: this.props.view ? this.props.view : false,
      PONumber: this.props.PONumber ? this.props.PONumber : 0,
      items: this.props.items ? this.props.items : [],
      ModelSend: {},
      SelectedIsLoaded: false,
      Active: false,
      columns: [
        {
          label: "PO  No",
          key: "PONumber",
          minWidth: 200,
          align: "center",
          fixed: true,
          custom: (value) => (
            <Col md={6} xl={6}>
              <Row>
                <Col md={12}>
                  <Link to={"/po-detail-view/" + value.POId}>
                    {value.PONumber}
                  </Link>
                </Col>
              </Row>
            </Col>
          ),
        },
        {
          label: "Status",
          key: "StatusDesc",
          minWidth: 80,
          align: "center",
          custom: (value) => (
            <Button
              className={
                value.StatusId === 13 ? "btn-oval _gray_button" : "btn-oval"
              }
              color={
                mapColor[value.StatusId] ? mapColor[value.StatusId] : "primary"
              }
              onClick={() => {}}
            >
              {value.StatusDesc}
            </Button>
          ),
        },
        {
          label: "Manufacturer",
          key: "Manufacturer",
          options: "Manufacturer",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "Selected",
        },
        {
          label: "Shipper",
          color: true,
          idColor: "ShipperColor",
          key: "SupplierName",
          options: "SupplierName",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "Selected",
        },
        {
          label: "Consignee",
          color: true,
          idColor: "ConsigneeColor",
          key: "ConsigneeName",
          options: "ConsigneeName",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "Selected",
        },
        {
          label: "Ship To",
          color: true,
          idColor: "ShipToColor",
          key: "DeliveryName",
          options: "DeliveryName",
          edit: true,
          type: "Selected",
        },
        {
          label: "Ship to Ref.",
          key: "DistributorPO",
          minWidth: 120,
          align: "center",
        },
        {
          label: "Country",
          key: "OriginCountry",
          minWidth: 120,
          align: "center",
        },
        { label: "POA", key: "POPOD", minWidth: 120, align: "center" },
        {
          label: "Ready Date",
          key: "ActualCargoReadyDate",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "datatime",
        },
        {
          label: "Pickup Mode",
          key: "PickupMode",
          options: "PickupMode",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "Selected",
        },
        {
          label: "Pickup Date",
          key: "ActualCargoPickup",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "datatime",
        },
        {
          label: "Arrival at WH",
          key: "ActualArrivalConsolWH",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "datatime",
        },
        {
          label: "Pallets",
          key: "NoPallets",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "Input",
        },
        {
          label: "QTY",
          key: "TotalQtyOrdered",
          minWidth: 120,
          align: "center",
        },
        {
          label: "QTY Confirmed",
          key: "TotalQtyConfirmed",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "modal",
        },
        {
          label: "Weight",
          key: "GrossWeight",
          minWidth: 120,
          align: "center",
          edit: true,
          type: "Input",
        },
        // { label: 'Actions', key: '', custom: (value) => this.custom1(value) }
        {
          label: "",
          anagrafico: true,
          edit: true,
          key: "Anagrafico",
          minWidth: 80,
          align: "center",
          custom: (value) => (
            <Button
              color={"success"}
              onClick={() => this.anagraficoGetList(value)}
            >
              {"Anagrafico"}
            </Button>
          ),
        },
      ],
      POLineComponent: "",
      error: {
        open: false,
        message: "",
        type: "error",
      },
      errors: {},
      styleLabelItems: {},
    };
  }

  /*=============================================
    =            BUtton delete Item               =
    =============================================*/

  custom1 = (value) => (
    <Fab
      color="secondary"
      size="small"
      aria-label="remove"
      onClick={this.deleteLine(value)}
    >
      <RemoveIcon className="iconRemove" />
    </Fab>
  );

  anagraficoGetList = async (item, reload) => {
    let id = auth.getAuthentication("customerSelect");
    try {
      let data = {};

      if (reload) {
        data = this.state.itemSelected;
      } else {
        data = {
          ConsigneeCode: item.ConsigneeCode,
          SupplierCode: item.SupplierCode,
          DeliveryCode: item.DeliveryCode,
        };
      }

      let success = await Axios.post(
        `${Services.GET_CONSOLPLAN_SERVICE.path}anagrafico/getlistforcode/${id}`,
        { data: data }
      );
      this.setState({
        itemComplete: reload ? this.state.itemComplete : item,
        disabledButton: reload ? false : true,
        itemSelected: data,
        anagraficoList: true,
        itemAnagrafico: success.data.data ? success.data.data : [],
      });
    } catch (err) {
      console.warn(err);
    }
  };

  /*=============================================
  =            Set Items For Table              =
  =============================================*/

  setItems = (item) => {
    this.setState(
      {
        items: [...this.state.items, item],
      },
      () => {
        this.props.sendItems(this.state.items);
      }
    );
  };

  removeLine = (index, items) => {
    items.splice(index, 1);
    this.setState(
      {
        error: {
          message: "A line item has been removed correctly",
          open: true,
          type: "warning",
        },
      },
      () => {
        this.props.sendItems(this.state.items);
      }
    );
  };
  componentDidMount = () => {
    this.buildFormCreateOrder();
    console.log("componentDidMount---this.props.order", this.props.order);
  };
  componentWillReceiveProps = (nextProps) => {
    console.log("componentWillReceiveProps---nextProps.order", nextProps.order);

    this.setState(
      {
        Form: nextProps.Form,
        CATPOLIST: nextProps.CATPOLIST ? nextProps.CATPOLIST : [],
        items: nextProps.items,
        ModelSend: nextProps.ModelSend,
        view: nextProps.view,
        order: nextProps.order,
      },
      () => this.buildFormCreateOrder()
    );
  };

  handleChange = (name) => (event) => {
    console.log("entre", name);
    let { order } = this.state;
    console.log("order", order);
    let value = event.target.value;
    const emailRegex = new RegExp(/^\d*\.?\d*$/);
    if (emailRegex.test(value)) {
      console.log("if", emailRegex.test(value));
      this.props.setFieldToOrderPolist(name, event.target.value);
    } else {
      console.log("else");
      let value2 = order[name] ? order[name] : "";
      this.props.setFieldToOrderPolist(name, value2);
    }
  };

  buildFormCreateOrder = () => {
    const { Form, order, selects, view, isEdit } = this.state;
    let CreateOrderFormData = Form[
      view ? "viewConsolPolist" : "ConsolPolist"
    ].map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              id={e.id}
              required
              autoComplete="off"
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={order[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "label")
        return (
          <div className={e.class} key={e.id}>
            <Col>
              <Row>
                <Col style={{ marginLeft: "-1em" }} md={12} lg={12} sm={6}>
                  <Fade left opposite>
                    {" "}
                    <span id={e.label} style={e.style}>
                      <label>
                        {e.label} {e.required === true ? <span>*</span> : ""}
                      </label>
                    </span>
                  </Fade>
                </Col>
                <Col md={12} lg={12} sm={6}>
                  <Fade left opposite>
                    {" "}
                    <span id={"user"}>
                      <label>{order[e.id]}</label>
                    </span>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </div>
        );
      else return "";
    });
    this.setState({
      CreateOrderFormData,
    });
  };

  parseItems = (items) => {
    return items.map((e) => {
      return {
        ItemDescription: e.ItemDescription,
        ItemNumber: e.ItemNumber,
        OriginCountryId: e.CountryOrigin,
        QtyOrdered: e.QtyOrdered,
        UnitMeasure: e.UnitsMeasure,
      };
    });
  };

  checkAll = (allLines) => {
    console.log("allLines",allLines);
    this.props.putOrdersPo(allLines);
  };

  getOrdersNewList = async () => {
    let { items } = this.state;
    let poId = this.state.itemComplete.POId;
    try {
      let id = auth.getAuthentication("customerSelect");
      let success = await Axios.post(
        `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/searchorderanagrafico/${id}`,
        { data: items }
      );
      let newArray = success.data.data;

      this.setState(
        {
          items: newArray,
          anagraficoList: false,
        },
        () => {
          this.props.sendItemsNew(newArray);
        }
      );
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
    }
  };

  changeDisabled = async () => {
    this.setState({ disabledButton: true, cancelOperacion: false });
  };

  cancelOperacion = async () => {
    this.setState({ cancelOperacion: true, anagraficoList: false });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });
  render() {
    let {
      cancelOperacion,
      disabledButton,
      itemAnagrafico,
      anagraficoList,
      items,
      view,
      ModelSend,
      CreateOrderFormData,
      CATPOLIST,
    } = this.state;
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <ExpansionPanel elevation={0} defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <legend id="ArrayPOList" style={this.state.styleLabelItems}>
                  <em className="fas fa-dolly-flatbed fa-1x mr-2"></em>PO List
                </legend>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row col-md-12" style={{ width: "100%" }}>
                  <div className="col-md-12 col-lg-12 col-sm-12 margin_bottom">
                    <div className="row">{CreateOrderFormData}</div>
                    <div
                      className="col-md-12 row"
                      style={{ marginBottom: "-42px", textAlign: "end" }}
                    >
                      <div className="col-md-10"></div>
                      <div id="addPos"  style={{ textAlign: "center" }} className="col-md-2">
                        {view ? (
                          <></>
                        ) : (
                          <>
                            <Fab
                              color="primary"
                              size="small"
                              aria-label="add"
                              onClick={() => this.props.activeModal(false)}
                            >
                              <AddIcon />
                            </Fab>
                            <label
                              style={{ fontSize: "13px", marginLeft: "1em" }}
                            >
                              Add/Remove POs
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr></hr>
                  </div>
                  <div
                    style={{ overflowY: "auto", maxHeight: "30em" }}
                    className="col-md-12"
                  >
                    <Tables
                      data2={items}
                      data={items}
                      columns={this.state.columns}
                      options={{
                        pagination: false,
                        search: false,
                        downloads: { xlsx: false, pdf: false },
                      }}
                      MultiUpdate={view ? false : true}
                      fixed
                      checkAll={this.checkAll}
                      ConsolPlan={true}
                      CATPOLIST={CATPOLIST}
                    ></Tables>
                  </div>
                  <Errors
                    open={this.state.error.open}
                    type={this.state.error.type}
                    message={this.state.error.message}
                    setProps={this.recieveProps}
                  ></Errors>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>
        </Card>
        <Modal
          title="WPS Anagrafico"
          centered
          visible={anagraficoList}
          // onOk={() => setVisible(false)}
          // onCancel={() => this.setState({ anagraficoList: false })}
          width={1000}
          closable={false}
          footer={null}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AnagraficoSpace
              itemAnagrafico={itemAnagrafico}
              anagraficoGetList={this.anagraficoGetList}
              changeDisabled={this.changeDisabled}
              cancelOperacion={cancelOperacion}
            ></AnagraficoSpace>
          </Suspense>
          <div style={{ height: "1em" }}></div>
          <div className="row">
            <div className="col-md-3 col-lg-4 col-sm-12"></div>
            <div
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-2 col-sm-4"
            >
              <Button
                variant="contained"
                color="danger"
                style={{ fontSize: "16px", width: "8em" }}
                className="button"
                onClick={() => this.cancelOperacion()}
              >
                Cancel
              </Button>
            </div>
            <div
              style={{ textAlign: "center",display: disabledButton ? 'none' : '' }}
              className="col-md-2 col-lg-1 col-sm-4"
            >
              <Button
                disabled={disabledButton}
                variant="contained"
                color="primary"
                style={{ fontSize: "16px", width: "8em" }}
                className="button"
                onClick={() => this.getOrdersNewList()}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default Polist;
