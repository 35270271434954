import React from 'react';
import propTypes from 'prop-types';
import axios from 'axios';

import Alert from './../../../../Global/Alert/';
import Table from './../../../../Global/TableV1';
import Modal from './../../../../Global/Modal/';
import {columns, ItemsToFilter} from './config';
import {Services} from './../../Services';

/**
 * Header Component
 */
const Header = () => {
  return (
    <h4 className='text-muted'>Purchase Orders Associated:</h4>
  );
};

/**
 * Disassociate PO Component
 */
function DisassociatePoComponent(props){
  const [rows, setRows] = React.useState([]);
  // * alert
  let [alert_open, setAlertOpen] = React.useState({
    open: false,
    type: 'success',
    message: ''
  });
  
  // ? on open alert
  const handleOpenAlert = React.useCallback((open, type, message) => {
    let alert = {
      open,
      type,
      message
    };
    setAlertOpen({...alert});
  }, []);

  /**
   * getPOs
   */
  const getPOs = React.useCallback(async() => {
    try{
      const url = Services.SHIPMENTS.path;
      const response = await axios.get(`${url}/shipment/${props.shipment}`);
      // console.log('the Shipment response ==>', response);
      setRows(response.data.data.POs);

    }catch(error){
      console.error('The error ==>', error);
    }
  }, [props.shipment]);

  /**
   * Assign POs
   */
  const {_user} = props;
  const UnassignPOs = React.useCallback(async(POId) => {
    try{
      // const response = 
      const url = Services.PROCEDURES.path;
      // const url = 'http://localhost:3004';
      await axios.post(`${url}/procedures/unassign`, {
        user: _user,
        po_id: POId
      });
      // console.log('The assign POs -->', response);
      getPOs();
      // props.restart();
      
    }catch(error){
      console.error('assing error ==>', error);
      handleOpenAlert(true, 'error', 'Error trying disassociate PO´s');
    }
  }, [getPOs, handleOpenAlert, _user]);

  return (
    <React.Fragment>
      <Alert open={alert_open.open} message={alert_open.message} type={alert_open.type}/>
      <Modal
        title
        Header={<Header/>}
        buttonTitle={'Remove PO'}
        iconButton={'delete_outline'}
        sizeButton={'small'}
        // typeButon={'danger'}
        element={'button'}
        elementType={'danger'}
        widthModal={'xl'}
        init={getPOs}
        onClose={props.restart}
      >
        <Table
          search
          configSearch={{fieldsToFilter: ItemsToFilter}}        
          rows={rows}
          columns={columns}
          keyCheck={'POId'}
          functions={{
            assign: UnassignPOs
          }}
        />

      </Modal>
    </React.Fragment>
  );
};

DisassociatePoComponent.propTypes = {
  shipment: propTypes.string,
  _user: propTypes.string
};

DisassociatePoComponent.defaultProps = {
  shipment: '0',
  _user: '0'
};

export default DisassociatePoComponent;