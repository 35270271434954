const Values = {
  Cards: [
    {
      title: 'Access per Client',
      aka: 'AccesesPerCustomer',
      value: 0,
      label: 'Accesses',
      color: {
        backgroundColor: '#5D9CEC',
        color: '#fff'
      }
    },
    {
      title: 'Access per day',
      aka: 'AccesesPerDay',
      value: 0,
      label: 'Users',
      color: {
        backgroundColor: '#FFAC35',
        color: '#fff'
      }
    },
    {
      title: 'Average time in sessions',
      aka: 'PromedioSesion',
      value: 0,
      label: 'MIN',
      color: {
        backgroundColor: '#57B65B',
        color: '#fff'
      }
    },
    {
      title: 'Active sessions',
      aka: 'ActiveSessions',
      value: 0,
      label: 'Users',
      color: {
        backgroundColor: '#8D8F89',
        color: '#fff'
      }
    }
  ]
};

export default Values;
