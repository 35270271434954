
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row } from "reactstrap";


function Warehousing(props) {
    const [loader, setLoader] = useState(false)

    function changueLoader(params) {
        setTimeout(() => {
            setLoader(true)
        }, 3000);
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <Row>
                <Col xs={12} md={4} lg={4} >
                    <Row>
                        <Col xs={12} md={7} lg={7}>
                            {props.shipmentLine.name}
                        </Col >
                    </Row>
                </Col>
                <div className="margin-footer-3" />
                <Col xs={12} md={12}>
                    <Row>
                        <props.FormCreator dataForm={props.dataForm} Model={props.Model} onChange={props.onChange} TabName={props.TabName} />
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default withRouter(Warehousing);

