import React, { Component } from 'react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import containerImage from '../../../resources/container.png';
import Table from './../../Global/Tablev2/';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Select from 'react-select';
import Fade from 'react-reveal/Fade';
import Tables from '../../template/Table';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import {Services} from './Services';

const ItemsToFilter = ['PONumber', 'SplitNo', 'SupplierName', 'ConsigneeName', 'CustomerContainerID', 'POCreateDate', 'PromiseCargoReadyDate', 'StatusDesc'];

class ContainerAssociation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lable: '',
      catContainer: [],
      loading: true,
      push_histori: props.push_histori ? props.push_histori : '',
      ShipmentNo: props.selection_No ? props.selection_No : '',
      orders: props.selection_pos ? props.selection_pos : [],
      ordersrespaldo: props.selection_pos ? props.selection_pos : [],
      save_procederu: props.save_procederu ? props.save_procederu : {},
      assign_procederu:props.assign_procederu ? props.assign_procederu : {},
      arrayOrder: [],
      ordertables: [],
      data: [],
      customerSelect: '',
      columns: [
        { label: 'PO No', key: 'PONumber', },
        { label: 'Split No.', key: 'SplitNo' },
        { label: 'Supplier', key: 'SupplierName' },
        { label: 'Consignee', key: 'ConsigneeName' },
        { label: 'Customer Container ID', key: 'CustomerContainerID' },
        { label: 'Creation Date', key: 'POCreateDate' },
        { label: 'Promise Cargo Ready', key: 'PromiseCargoReadyDate' },
        { label: 'Status', key: 'StatusDesc' },
        // { label: 'Acción', key: '', custom: (value) => this.custom1(value) }
      ],
      columnsMultiTables: [
        { label: 'PO No', key: 'PONumber', idTh: 'firstTh', idTd: 'firstTd', position: 'left' },
        { label: 'Split No.', key: 'SplitNo', idTh: 'firstTh', idTd: 'firstTd', position: 'left' },
        { label: 'Supplier', key: 'SupplierName', idTh: 'firstTh', idTd: 'firstTd', position: 'left' },
        { label: 'Status', key: 'StatusDesc', idTh: 'firstTh', idTd: 'firstTd', position: 'left' },
        { label: '', key: '', custom: (value) => this.custom1(value), idTh: 'firstTh', idTd: 'firstTd', position: 'left' }
      ],
      tables: []
    };
  }

  componentDidMount = () => {
    this.catSelectcontainer();
  }

  catSelectcontainer = () => {
    let catContainer = [];
    this.props.set_Container_Reference.map((e) => {
      let obj = {
        value: e.IntergloboContainerReference ? e.IntergloboContainerReference : e.InternalReference, label: e.Container
      };
      catContainer.push(obj);
    });
    this.setState({
      catContainer: catContainer
    });
  }

  validateOnChange = name => event => {
    if (event && event.value) {
      let id = event.value;
      let text = '';
      this.state.catContainer.map((e) => {
        if (e.value === id) text = e.label;
      });
      this.setState({
        customerSelect: id,
        lable: text
      });
    } else {
      let id = '';
      this.setState({
        customerSelect: id,
        lable: ''
      });
    }
  }

  custom1 = (value) => (
    <RemoveCircleIcon onClick={this.deleteLine(value)} className="icon" style={{ fontSize: 20, color: 'red' }}>
      HighlightOff
    </RemoveCircleIcon>
  )

  deleteLine = index => event => {
    let deleteValue = index;
    let newOrder = this.state.orders;
    let tables = this.state.tables;
    let flat = false;
    let newData = [];
    tables.map((e) => {
      // e.data.map((w)=>{
      let itemNew = e.data.filter(e => {
        if (e.POId !== deleteValue.POId) {
          return e;
        }
        return 0;
      });

      let itemNew2 = e.items.filter(e => {
        if (e !== deleteValue.POId) {
          return e;
        }
        return 0;
      });
      e.data = itemNew;
      e.items = itemNew2;
    });

    newData.push(index);
    newOrder.map((e) => {
      newData.push(e);
    });

    let tableClean = [];
    tables.map((e) => {
      if (e.data.length > 0) {
        tableClean.push(e);
      }
    });
    this.setState({
      orders: newData,
      tables: tableClean

    });
  }

  recieve = array => {
    const Filtrated = this.state.orders.filter((order) => array.indexOf(order.POId) !== -1);
    // let stringSelected = '';
    // Filtrated.forEach((e) => {
    //   stringSelected += ` ${e.PONumber},`;
    // });
    this.setState({
      arrayOrder: array,
      ordertables: Filtrated
    });
  }

  saveAsscociation = () => {
    let { tables,push_histori } = this.state;
    tables.map((e) => {
      const Filtrated = e.data.filter((order) => e.items.indexOf(order.POId) !== -1);
      let stringSelected = '';
      Filtrated.forEach((e) => {
        stringSelected += ` ${e.PONumber},`;
      });
      e.string = stringSelected.substring(0, stringSelected.length - 1);
      return e;
    });
    this.setState({
      tables: tables
    }, () => {
      if (push_histori === '/procedures'){
        this.confirmationAsscociationProcedure();
      }
      else{
        this.confirmationAsscociation();
      }
    });
  }

  confirmationAsscociationProcedure = () => {
    let { tables, push_histori, assign_procederu} = this.state;
    let tds = [];
    let array = [];

    tables.map((e) => {
      tds.push(
        `<tr>
        <td>${e.label}</td>
        <td>${e.string}</td>
        </tr>`
      );
    });
    Swal.fire({
      title: `Are you sure you want to associate the following POs to the Shipment No.${this.state.save_procederu ? this.state.save_procederu.paramas.value.shipmentNo : ''}?`,
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#20c554',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      html: `<table id="table" border=0>
              <thead>
                  <tr>
                      <th>Interglobo Container Ref./ Container No.</th>
                      <th>POs</th>
                  </tr>
              </thead>
              <tbody>
              ${tds.join('')}
            </tbody>
            </table>`,
      preConfirm: () => {
        tables.map((e) => {
          let groupPush = {
            id: e.title,
            data: e.items
          };
          array.push(groupPush);
        });

        Axios.post(`${Services.GET_ALL_BOOKINGS.path}/booking/associationcontainer`, { array }
        // return Axios.post('http://localhost:3017/booking/associationcontainer',{array}
        ).then(() => {
          return { resp: true };
        }).catch(() => {
          return { resp: false };
        });
      }
    }).then((result) => {
      if (result.value) {
        try{
          // const response = 
          // const url = Services.PROCEDURES.path;
          // const url = 'http://localhost:3004';
          Axios.post(`${Services.ORDERS.path}/procedures/assign`, {
            user: assign_procederu.user,
            shipment: assign_procederu.shipment,
            po_ids: assign_procederu.po_ids
          });
          // console.log('The assign POs -->', result);
          Swal.fire(
            '',
            `Consolidation Completed for Shipment No. ${this.state.save_procederu ? this.state.save_procederu.paramas.value.shipmentNo : ''}.`,
            'success'
          );
          this.props.history.push(push_histori); 
        }catch(error){
          console.error('assing error ==>', error);
        }

      }
      else {
      }
    });
  }

 confirmationAsscociation = () => {
   let { tables } = this.state;
   let tds = [];
   let array = [];

   tables.map((e) => {
     tds.push(
       `<tr>
        <td>${e.label}</td>
        <td>${e.string}</td>
        </tr>`
     );
   });
   Swal.fire({
     title: `Are you sure you want to associate the following POs to the Shipment No.${this.state.save_procederu ? this.state.save_procederu.paramas.value.shipmentNo : ''}?`,
     text: '',
     type: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#20c554',
     cancelButtonColor: '#d33',
     confirmButtonText: 'Yes',
     cancelButtonText: 'No',
     html: `<table id="table" border=0>
              <thead>
                  <tr>
                      <th>Interglobo Container Ref./ Container No.</th>
                      <th>POs</th>
                  </tr>
              </thead>
              <tbody>
              ${tds.join('')}
            </tbody>
            </table>`,
     preConfirm: () => {
       tables.map((e) => {
         let groupPush = {
           id: e.title,
           data: e.items
         };
         array.push(groupPush);
       });

       return Axios.post(`${Services.GET_ALL_BOOKINGS.path}/booking/procedure`, {
         // Axios.post('http://localhost:3017/booking/procedure', {
         WPSShipmentId: this.state.save_procederu.paramas.value.shipmentNo,
         idCustomer: this.state.save_procederu.id,
         // POs: selected,
         POs: this.state.save_procederu.transformPOs,
         type: this.state.save_procederu.paramas.value.resp.procedure
       }).then(response => {
         if (response.data && response.data.founded === true) {
           return { shipmentNo: this.state.save_procederu.paramas.value.shipmentNo, success: true, founded: true };
         } else {
           return { shipmentNo: this.state.save_procederu.paramas.value.shipmentNo, success: true, founded: false };
         }
       }).catch(() => {
         return { shipmentNo: this.state.save_procederu.paramas.value.shipmentNo, success: false };
       });
     }
   }).then((result) => {
     if (result.value && result.value.founded === true) {
       Swal.fire(
         '',
         `The Shipment No. ${this.state.save_procederu.paramas.value.shipmentNo} is already created in
                Poseidon could not be created again. Please
                retry with other Shipment or contact your system administrator.`,
         'error'
       );
     } else if (result.value && result.value.founded === false) {
       this.saveProcedure(array);
     } else if (result.value && result.value.success === false) {
       Swal.fire({
         title: '',
         text: `Consolidation Error for Shipment No. ${this.state.save_procederu.paramas.value.shipmentNo}.
              Please retry the operation. If this condition persists, 
              contact your system administrator.`,
         type: 'error',
         confirmButtonColor: '#20c554',
       });
     }
   });
 }

  saveProcedure = (array) => {
    let { push_histori } = this.state;
    Axios.post(`${Services.GET_ALL_BOOKINGS.path}/booking/associationcontainer`, { array }
      // return Axios.post('http://localhost:3017/booking/associationcontainer',{array}
      // return Axios.post(`${Services.EXEC_PROCEDURE.path}/booking/procedure`, {
    ).then(() => {
      Swal.fire(
        '',
        `Consolidation Completed for Shipment No. ${this.state.save_procederu.paramas.value.shipmentNo}.`,
        'success'
      );
      this.props.history.push(push_histori);
    }).catch(() => {
      console.error('saveProcedure -');
    });
  }

  handleConsoleShipment = () => {
    let newTables = [];
    let tables = this.state.tables;

    if (tables.length > 0) {
      let flat = false;
      tables.map((e) => {
        if (e.title === this.state.customerSelect) {
          flat = true;
          this.state.ordertables.map((w) => {
            e.data.push(w);
            return w;
          });
          this.state.arrayOrder.map((z) => {
            e.items.push(z);
            return z;
          });
        }
        return e;
      });
      if (!flat) {
        let obj =
        {
          columns: this.state.columnsMultiTables,
          data: this.state.ordertables,
          title: this.state.customerSelect,
          items: this.state.arrayOrder,
          label: this.state.lable
        };
        tables.push(obj);
      }
      this.setState({
        tables: tables
      });
    }
    else {
      let obj =
      {
        columns: this.state.columnsMultiTables,
        data: this.state.ordertables,
        title: this.state.customerSelect,
        items: this.state.arrayOrder,
        label: this.state.lable
      };

      newTables.push(obj);
      this.setState({
        tables: newTables
      });
    }
    let solution = this.state.orders.filter(e => {
      let res = true;
      this.state.ordertables.map(f => {
        if (e.POId === f.POId) res = false;
      });
      return res;
    });
    this.setState({
      orders: solution,
      arrayOrder: []
    });
  }

  render() {
    let {customerSelect, catContainer, orders, columns, arrayOrder, push_histori } = this.state;

    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <legend><img src={containerImage} width="26" height="26" className="mr-2" alt='container-picture'></img><label>Container/POs Association</label></legend>
            </div>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <Fade left opposite><span id="title"><label>The Shipment No. {this.state.save_procederu && this.state.save_procederu.paramas ? this.state.save_procederu.paramas.value.shipmentNo : ''} has multiple containers, please specify the PO association for each Container:</label></span></Fade>
            <Fade left opposite><span style={{ fontWeight: 'bold' }} id="title"><label>Interglobo Container Ref. / Container No.</label></span></Fade>
            <Select
              id=""
              placeholder="Select Container"
              required
              type=""
              name=""
              value={customerSelect}
              onChange={this.validateOnChange()}
              options={catContainer}
            />
          </div>
          <div className="col-9">
            <Card className="myCard">
              <CardBody>
                <div className="row">
                  <div className="col-12">
                    <Table
                      columns={columns}
                      data={orders}
                      configSearch={{ fieldsToFilter: ItemsToFilter }}
                      itemsPerPage={10}
                      keyCheck={'POId'}
                      options={
                        {
                          pagination: true,
                          search: true,
                          selectItems: true
                        }
                      }
                      handleSelection={this.recieve}
                    />
                  </div>
                </div>
                <Row>
                  <Col md={2} lg={4} sm={12}></Col>
                  <Col md={3} lg={3} sm={4}></Col>
                  <Col md={3} lg={2} sm={4}></Col>
                  <Col md={3} lg={3} sm={4}>
                    <Button variant="contained" color="primary" disabled={orders.length <= 0 || arrayOrder.length <= 0 || customerSelect === '' ? true : false ? true : false} className="button" onClick={this.handleConsoleShipment} size="lg">
                      Add to Container
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="col-12">
          {this.state.tables.map((e, key) => {
            return (
              <Card className="myCard" key={key}>
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <div className="col-12"><img src={containerImage} width="26" height="26" className="mr-2" alt='container-picture'></img><Fade left opposite><span style={{ fontWeight: 'bold' }} id="title"><label>Interglobo Container Ref. / Container No.</label></span></Fade></div>
                      <div className="col-12"><Fade left opposite><span style={{ fontWeight: 'bold', marginLeft: '35px' }} id="title"><label>{e.label}</label></span></Fade></div>
                    </div>
                    <div className="col-12">
                      <Tables
                        columns={e.columns}
                        data={e.data}
                        options={{
                          large: true,
                          downloads: {
                            pdf: false,
                            xlsx: false
                          }
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-8 col-sm-12"></div>
          <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-2 col-sm-4">
            <Button variant="contained" color="danger" className="button" onClick={() => this.props.history.push(push_histori ? push_histori : '/dashboard')} size="sm" >
              Cancel
            </Button>
          </div>
          <div className="col-md-2 col-lg-1 col-sm-4">
            {this.state.save_procederu && this.state.save_procederu.paramas ? 
              <Button variant="contained" color="primary" disabled={this.state.orders.length <= 0 ? false : true} className="button" onClick={this.saveAsscociation } size="sm">
            Save
              </Button>
              : <></>}
          </div>
        </div>

      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  selection_pos: state.booking_create.selection_pos,
  selection_No: state.booking_create.selection_No,
  set_Container_Reference: state.booking_create.set_Container_Reference,
  push_histori: state.booking_create.push_histori,
  save_procederu: state.booking_create.save_procederu,
  assign_procederu: state.booking_create.assign_procederu,
  
});
const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContainerAssociation));