import React from 'react';
import {Button} from 'reactstrap';

/**
 * Table columns
 */
export const columns = () => [
  {
    id: 'PONumber',
    label: 'PO No',
    minWidth: 80,
    align: 'center',
    cell: (element)=> (<p className='text-primary'>{element.PONumber}</p>)
  },
  {
    id: 'SplitNo',
    label: 'Split No',
    minWidth: 80,
    align: 'center'
  },
  {
    id: 'StatusDesc',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    cell: (element) => (<Button className="btn-oval" color='primary'>{element.StatusDesc}</Button>)
  },
  {
    id: 'SupplierName',
    label: 'Supplier',
    minWidth: 80,
    align: 'center'    
  },
  {
    id: 'ConsigneeName',
    label: 'Consignee',
    minWidth: 80,
    align: 'center'    
  },
  {
    id: 'DeliveryName',
    label: 'Ship To',
    minWidth: 80,
    align: 'center'    
  },
  {
    id: 'CustomerContainerID',
    label: 'Customer Container ID',
    minWidth: 80,
    align: 'center'
  },
  {
    id: 'DistributorPO',
    label: 'Distributor PO',
    minWidth: 80,
    align: 'center'    
  }
];
export const ItemsToFilter = ['PONumber', 'SplitNo', 'StatusDesc', 'SupplierName', 'ConsigneeName', 'DeliveryName', 'DistributorPO'];