import React from 'react';
import {Button} from 'reactstrap';

import DialogComponent from './../../../../Global/Dialog';

/**
 * Columns Table
 */
export const columns = (functions) => [
  {
    id: 'PONumber',
    label: 'PO No',
    minWidth: 80,
    align: 'center',
    cell: (element)=> (<p className='text-primary'>{element.PONumber}</p>)
  },
  {
    id: 'SplitNo',
    label: 'Split No',
    minWidth: 80,
    align: 'center'
  },
  {
    id: 'StatusDesc',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    cell: (element) => (<Button className="btn-oval" color='primary'>{element.StatusDesc}</Button>)   
  },
  {
    id: 'SupplierName',
    label: 'Supplier',
    minWidth: 80,
    align: 'center'    
  },
  {
    id: 'ConsigneeName',
    label: 'Consignee',
    minWidth: 80,
    align: 'center'    
  },
  {
    id: 'DeliveryName',
    label: 'Ship To',
    minWidth: 80,
    align: 'center'    
  },
  {
    id: 'DistributorPO',
    label: 'Distributor PO',
    minWidth: 80,
    align: 'center'    
  },
  {
    id:'',
    label: 'Action',
    minWidth: 80,
    align: 'center',
    cell: (value) => (
    // <DangerButton size='small' startIcon={<Icon>{'delete_outline'}</Icon>}>Delete PO</DangerButton>
      <DialogComponent
        disabled={false}
        color={'secondary'}
        icon={'delete_outline'}
        actionText={'Remove'}
        title='Detele'
        message={`Are you sure you want to delete the ${value.PONumber} from the Shipment ${value.ShipmentNumber}`}
        cancelText='Cancel'
        agreeText='Yes, I´m sure'
        agree={()=> functions && functions.assign ? functions.assign(value.POId) : () => {}}
      /> 
    )
  }
];

export const ItemsToFilter = ['PONumber', 'SplitNo', 'StatusDesc', 'SupplierName', 'ConsigneeName', 'DeliveryName', 'DistributorPO'];