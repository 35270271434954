import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import Axios from 'axios';
import moment from 'moment';
import { Services } from './../../Services';
import './styles.scss';



/*=============================================
=            Catalogs Trends By Year          =
=============================================*/

function CatYears(props){
  const [CatalogYears, setCatalogYears] = React.useState([]);
  const [date, setDate] = React.useState(moment(new Date()).format('YYYY'));
  const GetCatalog = React.useCallback( async ()=> {
    try{
      let CatYears = await Axios.post(`${Services.GET_DASHBOARD_SERVICE.path}`, {
        query: `query Catalog{ 
          CatYears {
            key
            value
            flag
            text
          }
        }`,
        variables: {}
      });
      setCatalogYears(CatYears.data.data.CatYears);
    } catch(error){
      console.error(error);
    }
  }, []);

  function onChangeDate(evt, data){
    setDate(data.value);
    props.onChangeYear(data);
  };

  React.useEffect(() => {
    GetCatalog();
  }, [GetCatalog]);

  return(
    <div>
      <Dropdown
        className="catalog-year-container"
        text={date}
        scrolling
        // placeholder="Year"
        // fluid
        // selection
        onChange={onChangeDate}
        defaultValue={date}
        options={CatalogYears}
      />
    </div>
  );
}



export default CatYears;