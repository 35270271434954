import React, { Component } from "react";
import "react-select/dist/react-select.css";
import "react-datetime/css/react-datetime.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Input, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import "./style.scss";
import Fade from "react-reveal/Fade";
import { Card, CardBody } from "reactstrap";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Modal } from "semantic-ui-react";
import Axios from "axios";
import ImgAdmiracion from "../../../../../../resources/admiracion.png";
import Swal from "sweetalert2";
import { Services } from "../../../Services";
import { validateIdParams } from "../../../../../utils/regularExpressionValidation";

class BookingSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _rolId: this.props._rolId,
      containerSelected: "",
      catContainer: [],
      selecteOpcion: false,
      shipmentNumberInsert: "",
      open: true,
      selectedOption: "",
      collapse: true,
      order: this.props.order ? this.props.order : {},
      isEdit: this.props.isEdit ? this.props.isEdit : false,
      errors: {},
      selects: { EQUIPMENT: [] },
      CreateOrderFormData: [],
      Form: {},
      icon: "fas fa-angle-down",
      selected: [],
      orderSelected: [],
      firtsOrderLoader: false,
      view: this.props.view ? this.props : false,
      CustomerName: props.CustomerName ? props.CustomerName : "",
      idCustomer: props.idCustomer ? props.idCustomer : "",
      userId: props.userId ? props.userId : "",
      active: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        Form: this.props.Form,
      },
      () => {
        this.setFirstOrder();
      }
    );
  };
  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        Form: nextProps.Form,
        order: nextProps.order,
        view: nextProps.view,
        isEdit: nextProps.isEdit ? nextProps.isEdit : false,
      },
      () => {
        this.setFirstOrder();
      }
    );
  };

  setFirstOrder = () => {
    this.getAllCatalogs();
  };

  getAllCatalogs = async () => {
    this.buildFormCreateOrder();
  };

  activeModal = () => {
    this.setState({
      active: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      active: false,
    });
  };

  handleChangeSelect = () => (event) => {
    let containerSelected = "";
    if (event && event.value) {
      containerSelected = event.value;
      this.setState({ containerSelected });
    } else {
      containerSelected = "";
      this.setState({ containerSelected });
    }
  };

  buildFormCreateOrder = () => {
    const { Form, order, view, _rolId } = this.state;
    let CreateOrderFormData = Form.ConsolBooking.map((e, i) => {
      if (e.type === "label")
        return (
          <div className={e.class} key={e.id}>
            <Col>
              <Row>
                <Col md={12} lg={12} sm={6}>
                  <Fade left opposite>
                    {" "}
                    <span
                      id={e.label}
                      style={{ marginRight: e.Icon ? "1em" : "" }}
                    >
                      <label>
                        {e.label} {e.required === true ? <span>*</span> : ""}
                      </label>
                    </span>
                  </Fade>
                  {/* {e.Icon && !view ?
                  <Fab disabled={_rolId ==='1' || _rolId ==='2' || _rolId ==='6' ? false :true} classes="smallsmall" color="primary" size="small" aria-label="add" onClick={() => this.activeModal()}>
                    <AddIcon />
                  </Fab> :
                  <></>
                } */}
                </Col>
                <Col md={12} lg={12} sm={6}>
                  <Fade left opposite>
                    {" "}
                    <span id={e.label}>
                      <label>{order[e.id] ? order[e.id] : ""}</label>
                    </span>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </div>
        );
      else return "";
    });
    this.setState({
      CreateOrderFormData,
    });
  };

  handleShipment = (name) => (event) => {
    let shipmentNumberInsert = event.target.value;
    this.setState({ shipmentNumberInsert });
  };

  handleSave = async () => {
    let { shipmentNumberInsert } = this.state;
    let id = shipmentNumberInsert;
    let idValidate = validateIdParams(id);
    if (!idValidate) {
      Swal.fire({
        title: "",
        text: `Data entered is invalid`,
        type: "error",
        confirmButtonColor: "#20c554",
      });
      setTimeout(() => {
        this.props.history.push("/console-plan");
      }, 2000);
      return;
    }
    let resp = await Axios.get(
      `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/shipmentcontainer/${id}`
    );

    if (resp.data.data && resp.data.exit) {
      this.setState({
        catContainer: resp.data.data,
        selecteOpcion: true,
      });
    } else {
      Swal.fire({
        title: "",
        text: `${resp.data.message}`,
        type: "error",
        confirmButtonColor: "#20c554",
      });
    }
  };

  handleSavecontainer = async () => {
    let { containerSelected } = this.state;
    this.setState(
      {
        containerSelected: "",
        shipmentNumberInsert: "",
        active: false,
      },
      () =>
        this.props.setFieldToOrderPolist(
          "ContainerReference",
          containerSelected
        )
    );
  };

  render() {
    let {
      CreateOrderFormData,
      selecteOpcion,
      shipmentNumberInsert,
      containerSelected,
      catContainer,
    } = this.state;
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <ExpansionPanel elevation={0} defaultExpanded>
              <ExpansionPanelSummary
                id="mytext"
                expandIcon={<ExpandMoreIcon />}
              >
                <legend>
                  <em className="fas fa-calendar-alt fa-1x mr-2"></em>Booking
                </legend>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row col-md-12">{CreateOrderFormData}</div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>
        </Card>
        <Modal
          open={this.state.active}
          size={"tiny"}
          centered={false}
          scrolling
        >
          <Modal.Header className="headerModal headerModalMulti">
            {"Add Shipment No."}
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="col-md-12">
              <div style={{ marginBottom: "10em" }} className="col-md-12">
                <div
                  style={{ textAlign: "center", marginBottom: "1em" }}
                  className="col-md-12"
                >
                  <img
                    src={ImgAdmiracion}
                    width="45%"
                    height="45%"
                    alt="active_po"
                  />
                </div>
                <div style={{ marginBottom: "1em" }} className="col-md-12">
                  <div className="col-md-12" key={"container"}>
                    <Fade left opposite>
                      {" "}
                      <span id="container">
                        <label>{"Please insert ths Shipments No.:"}</label>
                      </span>
                    </Fade>
                    <Input
                      id="input"
                      type="number"
                      name="container"
                      value={shipmentNumberInsert}
                      onChange={this.handleShipment()}
                    />
                  </div>
                </div>
                <div hidden={!selecteOpcion} className="col-md-12">
                  <div className="col-md-12" key="selectcontainer">
                    <Fade left opposite>
                      <span id="selectcontainer">
                        {" "}
                        <label>{"Select the Container No.:"} </label>
                      </span>
                    </Fade>
                    <Select
                      id="selectcontainer"
                      required
                      type="Select"
                      name="selectcontainer"
                      value={containerSelected}
                      onChange={this.handleChangeSelect()}
                      options={catContainer}
                    />
                    {/* <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions className="modal-actions">
            <div className="row">
              <div className="col-md-3 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: "center" }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  style={{ fontSize: "16px" }}
                  className="button"
                  onClick={this.handleOnClose}
                >
                  Cancel
                </Button>
              </div>
              {selecteOpcion ? (
                <div
                  style={{ textAlign: "center" }}
                  className="col-md-2 col-lg-1 col-sm-4"
                >
                  <Button
                    disabled={containerSelected !== "" ? false : true}
                    variant="contained"
                    color="success"
                    style={{ fontSize: "16px" }}
                    className="button"
                    onClick={this.handleSavecontainer}
                  >
                    ok
                  </Button>
                </div>
              ) : (
                <div
                  style={{ textAlign: "center" }}
                  className="col-md-2 col-lg-1 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="success"
                    style={{ fontSize: "16px" }}
                    className="button"
                    onClick={this.handleSave}
                  >
                    ok
                  </Button>
                </div>
              )}
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default BookingSelected;
