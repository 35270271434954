import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core/';
import { Card, CardBody } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { Button } from 'reactstrap';
import Table from '../../../../template/Table';
import axios from 'axios';
import {Services} from '../../Services';
import ListUser from './listUsers';
import ReportAsing from './reportAssig';
import Tooltip from '@material-ui/core/Tooltip';
import Errors from '../../../../template/Errors';
import moment from 'moment';


class createUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectcheck: true,
      modal: false,
      dataDocuments: [],
      dataReport: {},
      flatNext: false,
      reset: false,
      UserList: [],
      newSelected: [],
      mainColumns: [],
      roles: [],
      CustomerId: '',
      CreateFormData: [],
      CreateForAdd: [],
      Form: this.props.form,
      selected: [],
      data: this.props.customerList,
      error: {
        open: false,
        message: '',
        type: 'admin'
      },
      user: {
        Name: '',
        LastName: '',
        Position: '',
        Email: '',
        Password: '',
        RoleId: '',
        Telephone: ''
      },
      columns: [
        { label: 'ID', key: 'CustomerId', custom: (value) => <em onClick={() => this.setState({ active: !this.state.active }, () => { })}>  <label style={{}}>{value.CustomerId}</label> </em> },
        { label: 'Customer name', key: 'CustomerName' },
        { label: 'Customer Code', key: 'CustomerCode' },
        { label: 'Tax Id', key: 'TaxId' },
        { label: 'Telephone', key: 'Telephone' },
        { label: 'Country', key: 'Country' },
        { label: 'Customer Logo', key: 'CustomerLogo' },
        { label: 'Associated User', key: 'AssociatedUsers' },
      ],
      errorValidate:false
    };
  }

  componentDidMount = () => {
    this.getRoles();
    this.getUser();
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      newSelected: [],
      selectcheck: false,
      Form: nextProps.form,
      reset: true,
    }, () => {
      this.getRoles();
      this.getUser();
    });
  }

  getUser = (save) => {
    axios.get(`${Services.GetUser.path}admin/GetUser`, {
    }).then(response => {
      response.data.data.map((e)=>{
        e.CreateDate = e.CreateDate ? moment(e.CreateDate).format('YYYY-MM-DD') : '';
        return e;
      });
      if (save) {
        this.setState({
          UserList: response.data.data,
          reset: true
        });
      }
      else {
        this.setState({
          UserList: response.data.data,
          reset: false
        });
      }
    }).catch(error => {
      console.error(error);
    });

  }

  getRoles = () => {
    axios.get(`${Services.GetRole.path}admin/roles`, {
    }).then(response => {
      this.setState({ roles: response.data.data }, () => { this.buildFormCreate(); });
    }).catch(error => {
      console.error(error);
    });
  }

  validateSaveUser = e => evt => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.CreateUser;
    Form.map((e) => {
      if (e.required === true) {
        validation.push(e);
      }
      return e;
    });
    validation.forEach((e) => {
      if (this.state.user[e.id] === '') {
        errors += e.label + ', ';
        document.getElementById(e.id).style.color = 'red';
      }
    });
    if (errors.length > 0) {
      this.setState({
        submitt: true
      }, () => {
        this.buildFormCreate();
      });
      this.setState({
        error: {
          message: 'Campos requeridos: ' + errors + '',
          open: true,
          type: 'error'
        }
      });
    }
    else {
      this.saveUser();
    }
  }

  saveUser = () => {
    let data =
    {
      data: this.state.user,
      items: this.state.newSelected,
      report: this.state.dataReport,
      documents: this.state.dataDocuments
    };
    axios.post(`${Services.GetUser.path}admin/createUser`, data).then(() => {
      this.setState({
        newSelected: [],
        user: {
          Name: '',
          LastName: '',
          Position: '',
          Email: '',
          Password: '',
          RoleId: '',
          Telephone: ''
        },
        reset: true,
        data: this.state.data,
        columns: this.state.columns,
        flatNext: false
      }, () => {
        this.getUser(true);
        this.buildFormCreate();
        this.props.onError({
          error: {
            message: 'The User Has Been Saved Correctly!',
            open: true,
            type: 'admin'
          }
        });
      });
    }).catch(error => {
      if (error.response.data.message === 'Existing email, try again'){
        this.setState({
          error: {
            message:error.response.data.message,
            open: true,
            type: 'error'
          }
        });
      }
      else {
        this.setState({
          error: {
            message:'Dato incorrecto, favor de verificar',
            open: true,
            type: 'error'
          }
        });
      }
    });
  }

  PutUser = (dataUserPut) => {
    axios.put(`${Services.GetUser.path}admin/updateUser`, dataUserPut).then(() => {
      
      this.setState({
        error: {
          message: 'The User Has Been Saved Correctly!',
          open: true,
          type: 'admin'
        },
        modal: false,
        errorValidate:false

      },()=>{this.getUser();
        this.buildFormCreate();});

    }).catch(error => {
      // console.log(error.response.data.data.message);
      if (error.response.data.message === 'Existing email, try again'){
        this.setState({
          error: {
            message:error.response.data.message,
            open: true,
            type: 'error'
          }
        });
      }
      else {
        this.setState({
          error: {
            message:'Dato incorrecto, favor de verificar',
            open: true,
            type: 'error'
          }
        });
      }
      this.setState({
        errorValidate:true
      });
    });
  }

  handleChange = name => event => {
    document.getElementById(name).style.color = 'black';
    let { user } = this.state;
    user[name] = event.target.value;
    this.setState({
      user
    }, () => {
      this.buildFormCreate();
    });
  }
  

  handleChangeSelect = (selected) => event => {
    document.getElementById(selected).style.color = 'black';
    if (event && event.value) {
      let { user } = this.state;
      user[selected] = event.value;
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
    else {
      let { user } = this.state;
      user[selected] = '';
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
  }

  buildFormCreate = () => {
    const { Form, user, roles } = this.state;
    // let catalog = [
    //   {
    //     value: 1,
    //     label: 'Ceramic Tiles, Stone & Marble'
    //   },
    // ];

    let CreateFormData = Form.CreateUser.map((e) => {
      if (e.type === 'Input') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite> <span id={e.id} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
          <Input
            // id={e.id}
            required
            type={e.datatype}
            placeholder={e.placeHolder}
            name={e.id}
            value={user[e.id]}
            maxlength={e.maxlength}
            minlength={e.minlength}
            onChange={this.handleChange(e.id)}
            max={e.max} />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>
      );
      else if (e.type === 'Select') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite><span id={e.id} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <Select
            // id={e.id}
            required
            type={e.datatype}
            name={e.name}
            value={user[e.id]}
            onChange={this.handleChangeSelect(e.id)}
            options={roles}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>);
      else return '';
    });
    this.setState({
      CreateFormData
    });
  }
  recieve = array => {
    this.setState({
      newSelected: array
    }
    );
  }

  dataReport = (data) => {
    let dataReport = { 'data': data, 'userId': this.props._user };
    this.setState({
      dataReport,
    });
  }

  dataDocuments = (dataPo, dataship) => {
    let array = [];
    dataPo.map((e) => {
      if (e.check) {
        let data = {
          'DocCategoryId': e.DocCategoryId,
          'ModuleId': e.ModuleId,
          'UserId': this.props._user
        };
        array.push(data);
      }
      return e;
    });
    dataship.map((e) => {
      if (e.check) {
        let data = {
          'DocCategoryId': e.DocCategoryId,
          'ModuleId': e.ModuleId,
          'UserId': this.props._user
        };
        array.push(data);
      }
      return e;
    });
    this.setState({
      dataDocuments: array,
    });
  }

  recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

  render() {
    return (
      <>
       <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
        <Card>
          <CardBody className="myCard">
            <ExpansionPanel elevation={0} defaultExpanded >
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>User Information</strong>
                <br></br>
              </div>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-12">
                    <div className="row">
                      {this.state.CreateFormData}
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {this.state.flatNext ?
              <div className="col-md-12"><ReportAsing dataDocuments={this.dataDocuments} dataReport={this.dataReport} columns={this.state.columns} newSelected={this.state.newSelected}></ReportAsing></div>
              :
              <>
                <div className="col-md-12">
                  <strong style={{ marginLeft: '-12px' }}>Select Customer</strong>
                  <br></br>
                  <br></br>
                </div>
                <Table
                  columns={this.state.columns}
                  data={this.state.data}
                  mainColumns={this.state.mainColumns}
                  bordered={true}
                  type={'GENERIC'}
                  options={{
                    large: true,
                    search: true,
                    // pagination:true,
                    downloads: {
                      pdf: false,
                      xlsx: false
                    },
                    selectItems: true,
                    reset: this.state.reset
                  }
                  }
                  onSelect={this.recieve}
                /> </>}
            <br></br><br></br>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-2 col-sm-4">
                <Button variant="contained" color="danger" className="button" onClick={() => {
                  this.setState({
                    reset: true, flatNext: false, user: {
                      Name: '',
                      LastName: '',
                      Position: '',
                      Email: '',
                      Password: '',
                      RoleId: '',
                      Telephone: ''
                    },
                    newSelected:[]
                  }, () => {
                    let Form = this.props.form.CreateUser;
                    Form.map((e) => {
                      document.getElementById(e.id).style.color = 'black';
                      return e;
                    });
                    this.buildFormCreate();
                    this.setState({ reset: false });
                  });
                }} >
                  Cancel
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                {this.state.flatNext ? <Button variant="contained" disabled={this.state.newSelected.length <= 0 ? true : false} color="primary" className="button" onClick={this.validateSaveUser()}>
                  Save
                </Button> :  <>  <Tooltip open={false} interactive={true} title="selecting a customer is required" placement="placement" >
                  <span><Button variant="contained" disabled={this.state.newSelected.length <= 0 ? true : false} color="primary" className="button" onClick={() => { this.setState({ flatNext: true }); }}>
                    Next
                  </Button></span>
                </Tooltip></>}
              </div>
            </div>
          </CardBody>
        </Card>
        <ListUser errorValidate = {this.state.errorValidate} userId={this.props._user} modal={this.state.modal} dataDocuments={this.dataDocuments} dataReport={this.dataReport} saveUser={this.PutUser} onError={this.onError} deleteUser={this.props.deleteUser} customerList={this.props.customerList} Roles={this.state.roles} Form={this.state.Form} UserList={this.state.UserList}></ListUser>
      </>
    );
  }
}

export default createUsers;