import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {Icon, Dialog} from '@material-ui/core/';

import { InfoButton, DangerButton, DefaultButton} from './../Button/';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
 * Dialog Title
 */
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * Dialog Content
 */
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

/**
 * Dialog Actions
 */
const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * Modal Component
 * @param {*} props 
 */
function ModalComponent(props){
  const [open, setOpen] = React.useState(false);
  // const [Header] = React.useState(props.Header);
  // const [Actions] = React.useState(props.Actions);
  /**
   * Open handler for dialog
   */
  const handleClickOpen = () => {
    setOpen(true);
    props.init();
  };

  /**
   * Close handler 
   */
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  
  /**
   * Types button
   */
  const type = {
    button: {
      danger: <DangerButton size={props.sizeButton} startIcon={<Icon>{props.iconButton}</Icon>} onClick={handleClickOpen}>{props.buttonTitle}</DangerButton>,
      info: <InfoButton size={props.sizeButton} startIcon={<Icon>{props.iconButton}</Icon>} onClick={handleClickOpen}>{props.buttonTitle}</InfoButton>,
      default: <DefaultButton size={props.sizeButton} startIcon={<Icon>{props.iconButton}</Icon>} onClick={handleClickOpen}>{props.buttonTitle}</DefaultButton>
    },
    text: {
      cuted: props.text.length > 20 ? 
        <p className='text-primary' onClick={handleClickOpen}>{props.text.substr(0, 20)}...</p>:
        <p className='text-primary' onClick={handleClickOpen}>{props.text}</p>,
      normal: <p style={{cursor: 'pointer'}} onClick={handleClickOpen}>{props.text}</p>
    }
  };

  return (
    <div>
      {
        type[props.element][props.elementType]
      }
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={props.widthModal}>
        {
          props.title ? 
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {props.Header}
            </DialogTitle>: ''     

        }
        <DialogContent dividers>
          {props.children}
        </DialogContent>
        {
          props.actions ? 
            <DialogActions>
              {props.Actions}
            </DialogActions>: ''
        }
      </Dialog>
    </div>
  );
};

ModalComponent.propTypes = {
  actions: propTypes.bool,
  buttonTitle: propTypes.string,
  element: propTypes.string,
  elementType: propTypes.string,
  iconButton: propTypes.string,
  init: propTypes.func,
  // textCancel: propTypes.string,
  // textSave: propTypes.string,
  onClose: propTypes.func,
  text: propTypes.string,
  title: propTypes.bool,
  typeButon: propTypes.string,
  sizeButton: propTypes.string,
  widthModal: propTypes.string,

  Header: propTypes.element,
  Actions: propTypes.element
};

ModalComponent.defaultProps = {
  actions: false,
  buttonTitle: '',
  elementType: 'default',
  element: 'button',
  iconButton: '',
  init: () => {},
  // textCancel: '',
  // textSave: 'Save',
  onClose: () => {},
  title: false,
  text: '',
  typeButon: 'default',
  sizeButton: 'small',
  widthModal: 'xl',
  Header: <React.Fragment><h2> </h2></React.Fragment>,
  Actions: <React.Fragment><h2> </h2></React.Fragment>
};

export default ModalComponent;

