import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import io from "socket.io-client";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import Loader from "../../../Global/Loader";
import Graphics from "./componets/graphics/graphics";
import Tables from "../../../template/Table";
import { saveAs } from "file-saver";
import { Search } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import Select from "react-select";
import { Services } from "../Services";
import axios from "axios";

/*=============================================
=            Inventory Component              =
=============================================*/

const headers = [
  "Conto PO No.",
  "Supplier",
  "Item Id",
  "Description",
  "Case Description",
  "Cartons",
  "Pallets",
  "Gross Weight",
  "Lot No.",
  "Packing List No.",
  "Packing List Date",
  "Arrival at Warehouse",
];
const keys = [
  "ContoPONo",
  "Supplier",
  "ItemID",
  "Description",
  "CaseDescription",
  "Cartons",
  "Pallets",
  "GrossWeight",
  "LotNo",
  "PackingListNo",
  "PackingListDate",
  "ArrivalWarehousing",
];

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      search: "",
      catalog: [],
      filter: "",
      clientesSelect: this.props._customer,
      fullLoader: false,
      yearCat: [],
      lastupdate: "",
      newMeses: [
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],
      loading: false,
      pages: 1,
      columns: [
        { label: "Conto PO No.", key: "ContoPONo", type: "Input" },
        { label: "Supplier", key: "Supplier", type: "Input" },
        { label: "Item Id", key: "ItemID", type: "Input" },
        { label: "Description", key: "Description" },
        { label: "Case Description", key: "CaseDescription" },
        { label: "Cartons", key: "Cartons" },
        { label: "Pallets", key: "Pallets" },
        { label: "Gross Weight", key: "GrossWeight" },
        { label: "Lot No.", key: "LotNo" },
        { label: "Packing List No.", key: "PackingListNo" },
        {
          label: "Packing List Date",
          key: "PackingListDate",
          type: "datatime",
        },
        {
          label: "Arrival at Warehouse",
          key: "ArrivalWarehousing",
          type: "datatime",
        },
      ],
      items: [],
      tercerChart: [],
      dataTable: [],
    };
  }

  downloadExcel = async () => {
    this.setState({
      loading: true,
    });
    let { dataReport, items } = this.state;
    // let data =dataReport;

    let newData = [];
    items.map((e) => {
      if (e.hidden === false) {
        newData.push(e);
      }
    });

    let data = newData;
    let success = await axios.post(
      "https://servicereportapi.azurewebsites.net/report/dynamic/xlsx",
      {
        data,
        options: {
          headers: headers,
          keys: keys,
          image: {
            name: "poseidon",
          },
        },
      }
    );
    if (success.data.base64) {
      let dateNow = new Date();
      let titleDownload = `InventoryIn-${dateNow.getDate() +
        "" +
        (dateNow.getMonth() + 1).toString().padStart(2, "0") +
        "" +
        dateNow.getFullYear()}.xlsx`;
      //   const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';
      const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
      saveAs(linkSource, titleDownload);
      this.setState({
        loading: false,
      });
    } else console.warn("Error downloading file", success);
  };

  componentDidMount = () => {
    this.getDataInventory();
  };

  getDataInventory = () => {
    var today = new Date();
    var year = today.getFullYear();

    let objPost = {
      filter: this.state.filter,
      client: this.state.clientesSelect,
      year: year,
    };
    let yearCat = [];

    axios
      .post(`${Services.GET_WAREHOUSING.path}warehouse/inventory-in`, objPost)
      .then((success) => {
        let {
          byWarehouse,
          byitem,
          catalogYear,
          catalog,
          table,
          newMeses,
        } = success.data.data;
        let lastupdate = "";
        if (table.length > 0) {
          lastupdate = table[0].LastUpdate;
        }

        let findGraph = false;

        catalogYear.map((e) => {
          // toltlAmoun = toltlAmoun + Number(e.TotalFactura);
          e.hidden = false;
          yearCat.map((f) => {
            if (e.date_part === f.key) {
              findGraph = true;
            } else {
              findGraph = false;
            }
            return f;
          });
          if (!findGraph) {
            let date_part = {
              value: e.date_part,
              key: e.date_part,
              flag: e.date_part,
              text: e.date_part,
            };
            yearCat.push(date_part);
          }
        });

        this.setState({
          fullLoader: true,
          tercerChart: byitem,
          items: table,
          catalog,
          yearCat,
          newMeses,
          lastupdate,
        });
      })
      .catch((error) => {
        this.setState({
          fullLoader: true,
        });
        console.warn(error);
      });
  };

  handleSearch = (name) => (event) =>
    this.setState({ [name]: event.target.value }, () => this.setSearch());
  setSearch = () => {
    let search = this.state.search;
    let cliente = [...this.state.items];
    let haveSearch = false;
    if (search.length > 0) {
      haveSearch = true;
    }
    let all = cliente;
    let newAll = all.map((item) => {
      const ContoPONo = item.ContoPONo
        ? item.ContoPONo.toString().toUpperCase()
        : "".toString().toUpperCase();
      const Supplier = item.Supplier
        ? item.Supplier.toString().toUpperCase()
        : "".toString().toUpperCase();
      const LotNo = item.LotNo
        ? item.LotNo.toString().toUpperCase()
        : "".toString().toUpperCase();
      const Description = item.Description
        ? item.Description.toUpperCase()
        : "".toUpperCase();
      const PackingListNo = item.PackingListNo
        ? item.PackingListNo.toString().toUpperCase()
        : "".toString().toUpperCase();
      const ItemID = item.ItemID
        ? item.ItemID.toString().toUpperCase()
        : "".toString().toUpperCase();

      const textData = search.toUpperCase();

      if (
        ContoPONo.indexOf(textData) > -1 ||
        Description.indexOf(textData) > -1 ||
        LotNo.indexOf(textData) > -1 ||
        Supplier.indexOf(textData) > -1 ||
        PackingListNo.indexOf(textData) > -1 ||
        ItemID.indexOf(textData) > -1
      ) {
        item.hidden = false;
      } else {
        item.hidden = haveSearch ? true : false;
      }
      return item;
    });
    let pages = newAll.length / 10 + 1;

    this.setState({
      items: newAll,
      pages: pages,
    });
  };

  render() {
    const {
      fullLoader,
      tercerChart,
      search,
      items,
      newMeses,
      columns,
      loading,
      yearCat,
      lastupdate,
      selected,
      catalog,
      clientesSelect,
    } = this.state;

    let dataTable = [];

    items.map((e) => {
      if (e.hidden === false) {
        dataTable.push(e);
      }
      return e;
    });

    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-th fa-1x mr-2"></em>
              Inventory In
            </div>
          </div>
          <Col md={12} xl={12}>
            <Graphics
              tercerChart={tercerChart}
              yearCat={yearCat}
              data={this.state.items}
              newMeses={newMeses}
              clientesSelect={clientesSelect}
            ></Graphics>
          </Col>
          <div style={{ height: "4em" }}></div>

          <Card className="myCard">
            <CardBody>
              {/* <div style={{ height: 67 }}></div> */}
              <div style={{ marginBottom: "2em" }} className="row">
                <div className="col-5">
                  <Search
                    input={{
                      icon: "search",
                      iconPosition: "left",
                      fluid: true,
                    }}
                    placeholder="..."
                    value={search}
                    open={false}
                    name="search"
                    id="search"
                    onSearchChange={this.handleSearch("search")}
                  />
                </div>
                <div className="col-1">
                  <Col lg={12} sm={12} md={12}>
                    <div className=" col-2 text-center ">
                      <Row>
                        {loading ? (
                          <center>
                            {" "}
                            <Icon
                              loading={loading}
                              style={{ marginTop: "6px" }}
                              name="circle notch"
                              size="large"
                            />
                          </center>
                        ) : (
                          <center>
                            <em
                              style={{ color: "#43a047", marginTop: "6px" }}
                              onClick={this.downloadExcel}
                              className="download fa-2x fas fa-file-excel mr-2"
                            ></em>
                          </center>
                        )}
                      </Row>
                    </div>
                  </Col>
                </div>
                <div style={{ textAlign: "end" }} className="col-6">
                  <div style={{ marginLeft: "1em" }} className="date_content">
                    <h5 className="date_header">
                      <em className="label_title"> Last Update: </em>
                      <spam style={{ fontWeight: "bold" }}>{lastupdate}</spam>
                    </h5>
                  </div>
                </div>
              </div>

              <Tables
                data={dataTable}
                columns={columns}
                dataSearch={this.state.search}
                selectedFilter={this.state.filter}
                options={{
                  pagination: true,
                  search: false,
                  downloads: { xlsx: false, pdf: false },
                }}
              ></Tables>
            </CardBody>
          </Card>
        </ContentWrapper>
      </>
    );
  }
}

export default withRouter(Inventory);
