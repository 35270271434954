import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { Row, Table, Col, CardBody, Card } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { Pagination, Checkbox, Popup } from 'semantic-ui-react';
import { saveAs } from "file-saver";
import Search from '../Search';
import TableHeader from './Components/Header';
import TableBody from './Components/TableBody';
import Axios from "axios";
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import imgFilter from '../../../resources/filter.png'
import { Warning } from '../../poseidon/NewBuild/components/img'

import { Sort } from './handlers';
import './styles.scss';
const moment = require('moment');


/*=============================================
=            Login Handler For Table          =
=============================================*/

function init({ search_text, total_pages, active_page, order_by, order_direction, rows, rows_search, selected_items }) {
  return {
    search_text,
    total_pages,
    active_page,
    order_by,
    order_direction,
    rows,
    rows_search,
    selected_items
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_rows':
      return {
        ...state,
        rows: action.payload
      };
    case 'search':
      return {
        ...state,
        search_text: action.payload.search_text
      };
    case 'set_selected':
      return {
        ...state,
        selected_items: [...action.payload]
      };
    case 'set_order':
      return {
        ...state,
        order_by: action.payload.order_by,
        order_direction: action.payload.order_direction
      };
    case 'reset_pager':
      return {
        ...state,
        active_page: action.payload.active_page,
        total_pages: action.payload.total_pages
      };
    case 'active_page':
      return {
        ...state,
        active_page: action.payload
      };
    case 'reset_state':
      return init(action.payload);
    default:
      return state;
  }
}

/*=====  End of Login Handler For Table  ======*/

function calculatePages(total, itemsPerPage) {
  const total_pages = total / itemsPerPage;
  return total_pages % 1 === 0 ? total_pages : Math.ceil(total_pages);
}

/*=============================================
=            Main Component                  =
=============================================*/
function TableMainComponent(props) {
  // console.log('props', props);
  const [loaderExcel, setLoaderExcel] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    rows: [...props.data],
    rows_search: [...props.data],
    search_text: '',
    total_pages: calculatePages(props.data.length, props.itemsPerPage),
    active_page: 1,
    order_by: '',
    order_direction: 'desc',
    selected_items: []
  }, init);

  function onSelecteItem(evt, item_no) {
    // console.log('this are the selected items --->', state.selected_items);
    const selectedIndex = state.selected_items.indexOf(item_no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected_items, item_no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected_items.slice(1));
    } else if (selectedIndex === state.selected_items.length - 1) {
      newSelected = newSelected.concat(state.selected_items.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.selected_items.slice(0, selectedIndex),
        state.selected_items.slice(selectedIndex + 1),
      );
    }
    dispatch({ type: 'set_selected', payload: newSelected });
    props.handleSelection(newSelected);
  }

  function isSelected(line_no) {
    return state.selected_items.indexOf(line_no) !== -1;
  }

  function handleChangePage(evt, { activePage }) {
    dispatch({ type: 'active_page', payload: activePage });
  }
  function resetPager(totalPages, activePage) {
    dispatch({ type: 'reset_pager', payload: { active_page: activePage, total_pages: calculatePages(totalPages, props.itemsPerPage) } });
  }

  function sortData(key, direction) {
    dispatch({ type: 'set_order', payload: { order_by: key, order_direction: direction } });
    dispatch({ type: 'set_rows', payload: Sort([...state.rows], key, direction) });
  }
  // ! maybe deprecated funcion
  function handleItemsPerPage() {
    // return totalItems > props.itemsPerPage ? 1: 0;
    return 1;
  }
  function handleOnSearch(data) {
    if (state.order_by) {
      dispatch({ type: 'set_rows', payload: [...Sort([...data], state.order_by, state.order_direction)] });
      resetPager(data.length, handleItemsPerPage());
    } else {
      dispatch({ type: 'set_rows', payload: [...data] });
      resetPager(data.length, handleItemsPerPage());
    }
  }

  const generateExcel = useCallback(async () => {
    let headers = props.headers
    console.log("🚀 ~ file: Table.js ~ line 156 ~ generateExcel ~ headers", headers)
    let keys = props.keys
    setLoaderExcel(true)
    // console.log('data-->', state.rows.length);
    const dataxls = {
      data: state.rows,
      options: {
        keys,
        headers,
        image: {
          name: 'poseidon',
          // width: 150,
          // height: 80
        },

        // totales: ["AnnualRevenew", "AnnualCM"],
        // specialFormat: {
        //   format: "money",
        //   applyTo: ["AnnualRevenew", "AnnualCM"]
        // }
      },
    }

    const url = 'https://servicereportapi.azurewebsites.net/report/dynamic/xlsx';
    try {
      if (state.rows.length >= 1) {
        const getXlsxFile = await Axios.post(url, dataxls);
        const fileName = `New Build Shipments-${moment(new Date()).format('DD-MM-YYYY')}.xlsx`
        const linkSource = "data:application/xlsx;base64," + getXlsxFile.data.base64;
        saveAs(linkSource, fileName);
        setLoaderExcel(false)
      } else {
        setLoaderExcel(false)
      }

    } catch (error) {
      console.log('The Error-->', error);
    }
  }, [props.headers, props.keys, state.rows])

  React.useEffect(() => {
    dispatch({
      type: 'reset_state',
      payload: {
        rows: [...props.data],
        rows_search: [...props.data],
        search_text: '',
        total_pages: calculatePages(props.data.length, props.itemsPerPage),
        active_page: 1,
        order_by: '',
        order_direction: 'desc',
        selected_items: []
      }
    });
  }, [props.data, props.itemsPerPage]);

  return (
    <>

      {props.options.search ?
        <Row>
          <Col xs={10} md={4} lg={4}>
            {props.options.search ?
              <Search data={state.rows_search} handler={handleOnSearch} configSearch={props.configSearch} placeholder={props.options.placeholder} width={'100%'}  ></Search>
              : ''}
          </Col>
          <Col xs={2} md={4} lg={4} className='d-flex align-items-center'>
            <Row>
              <Col className='d-flex align-items-center'>
                <Popup
                  inverted
                  content={'Filters'}
                  trigger={
                    <img
                      // onClick={() => {
                      // 	if (this.state.advancedSearchData.length > 1) {
                      // 		this.setState({ isOpen: true });
                      // 	}
                      // }}
                      alt="img"
                      id="toggler"
                      width={26}
                      height={26}
                      src={imgFilter}
                      style={{ cursor: 'pointer' }}
                    ></img>
                  } />
              </Col>
              <Col className='d-flex align-items-center'>
                {
                  props.options && props.options.xlsx ?
                    // <Button style={{ color: '#487629' }}
                    //   onClick={generateExcel}
                    //   disabled={loaderExcel}
                    // >
                    // <i className={loaderExcel ? `fas fa-sync fa-spin fa-2x` : `fas fa-file-excel fa-2x`} />
                    <Popup
                      inverted
                      content={'Download List'}
                      trigger={
                        <i className={loaderExcel ? `fas fa-spinner fa-pulse fa-2x` : `fas fa-file-excel fa-2x`} style={{ color: '#487629', cursor: 'pointer' }} onClick={generateExcel} />
                      } />
                    // </Button>
                    : ''
                }
              </Col>
            </Row>
          </Col>
          <Col xs={10} md={4} lg={4} className='d-flex justify-content-end align-items-center'>
            {/* <div className="row text-end"> */}
            {/*     <Warning />
            <span className="ml-2 mr-2"> show delayed</span>
            <Checkbox toggle /> */}
            {/* </div> */}
          </Col>
        </Row>

        : ''}
      {
        props.options.Filter ?
          <Row>
            <CardBody>
              <props.options.Filter.filters dataForm={props.options.Filter.form} funtionFilter={props.options.Filter.funtionFilter} Catalogs={props.options.Filter.Catalogs} />
            </CardBody>
          </Row>
          : ''
      }
      {/* <Card> */}
      {/* <div className="col-12" style={{ paddingTop: '1%' }}></div> */}
      <Card className={props.options.card ? "myCard" : ''}>
        {/* <Card className={props.options.card ? "myCard card-border-new-build" : ''}> */}

        {
          props.options.Accordion.Active ?
            <Accordion defaultExpanded='true'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography className='title-card-po'>{props.options.Accordion.Title}</Typography>
              </AccordionSummary>
              <div className={`${props.fixed ? 'zui-scroller' : 'col-12'}`}>
                <Table
                  id="_table_overflow"
                  responsive
                  bordered={props.options.bordered}
                  hover={props.options.hover}
                  borderless={props.options.borderless}
                // className={`zui-table ${props.type === 'GENERIC' ? 'table-height table table-sticky table-bordered' : ''}`}
                >
                  <thead style={{ textAlign: 'center', }}>
                    <tr style={{ cursor: 'pointer' }}>
                      <TableHeader
                        columns={props.columns}
                        selectItems={props.options && props.options.selectItems}
                        handleSort={sortData}
                        sortKey={state.order_by}
                        direction={state.order_direction}
                      />
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    <TableBody
                      rows={state.rows}
                      columns={props.columns}
                      selectItems={props.options && props.options.selectItems}
                      keyCheck={props.keyCheck}
                      onSelectItem={onSelecteItem}
                      isSelected={isSelected}
                      page={state.active_page - 1}
                      rowsPerPage={props.itemsPerPage}
                      cellType={props.cellType}
                      hoverClass={props.options.hoverClass}
                    />
                  </tbody>
                </Table>
              </div>
              <div>
                {
                  props.options.addShip.visible ?
                    <Col style={{ textAlign: 'right' }}>
                      <CardBody>
                        <Popup
                          basic
                          inverted
                          content='Add new shipment line'
                          size='mini'
                          trigger={
                            <i className="fas fa-plus-circle fa-2x" onClick={() => { props.options.addShip.addShipLine() }} style={{ color: '#5D9CEC', cursor: 'pointer' }}></i>
                          } />
                      </CardBody>
                    </Col>
                    : <br></br>
                }

              </div>
              {
                props.options && props.options.pagination ?
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    <br></br>
                    <br></br>

                    <Pagination
                      size={window.innerWidth < 993 ? 'mini' : ''}
                      ellipsisItem={null}
                      siblingRange={0}
                      activePage={state.active_page}
                      onPageChange={handleChangePage}
                      totalPages={state.total_pages}>
                    </Pagination>
                    <br></br>
                    <br></br>
                  </div>
                  : ''
              }
            </Accordion>

            :

            <>
              <div className={`${props.fixed ? 'zui-scroller' : 'col-12'}`}>
                <Table
                  id="_table_overflow"
                  responsive
                  bordered={props.options.bordered}
                  hover={props.options.hover}
                  borderless={props.options.borderless}
                // className={`zui-table ${props.type === 'GENERIC' ? 'table-height table table-sticky table-bordered' : ''}`}
                >
                  <thead style={{ textAlign: 'center', }}>
                    <tr style={{ cursor: 'pointer' }}>
                      <TableHeader
                        columns={props.columns}
                        selectItems={props.options && props.options.selectItems}
                        handleSort={sortData}
                        sortKey={state.order_by}
                        direction={state.order_direction}
                      />
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    <TableBody
                      rows={state.rows}
                      columns={props.columns}
                      selectItems={props.options && props.options.selectItems}
                      keyCheck={props.keyCheck}
                      onSelectItem={onSelecteItem}
                      isSelected={isSelected}
                      page={state.active_page - 1}
                      rowsPerPage={props.itemsPerPage}
                      cellType={props.cellType}
                    />
                  </tbody>
                </Table>
              </div>
              {
                props.options && props.options.pagination ?
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    <br></br>
                    <br></br>

                    <Pagination
                      size={window.innerWidth < 993 ? 'mini' : ''}
                      ellipsisItem={null}
                      siblingRange={0}
                      activePage={state.active_page}
                      onPageChange={handleChangePage}
                      totalPages={state.total_pages}>
                    </Pagination>
                    <br></br>
                    <br></br>
                  </div>
                  : ''
              }
            </>
        }

      </Card>
    </>
  );
}

TableMainComponent.propTypes = {
  cellType: propTypes.oneOf(['tick-rows', 'thin-rows']),
  data: propTypes.array,
  fixed: propTypes.bool,
  type: propTypes.string,
  itemsPerPage: propTypes.number,
  keyCheck: propTypes.string,
  handleSelection: propTypes.func
};
TableMainComponent.defaultProps = {
  cellType: 'thick-rows',
  data: [],
  fixed: false,
  type: '',
  itemsPerPage: 10,
  keyCheck: '',
  handleSelection: () => { }
};

export default TableMainComponent;
