import {
  UPDATE_SELECTION, SET_SELECTION, SET_NO, SET_CONTAINER_REFERENCE,PUSH_HISTORI,SAVE_PROCEDURE,ASSIGN_PROCEDURE
} from './types';

export const UpdateSelection = (data) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_SELECTION,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};

export const SetSelection = (data) => async dispatch => {
  try {
    dispatch({
      type: SET_SELECTION,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};

export const Setno = (data) => async dispatch => {
  try {
    dispatch({
      type: SET_NO,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};

export const SetContainerReference = (data) => async dispatch => {
  try {
    dispatch({
      type: SET_CONTAINER_REFERENCE,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};

export const PushHistori = (data) => async dispatch => {
  try {
    dispatch({
      type: PUSH_HISTORI,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};

export const SaveProcedure = (data) => async dispatch => {
  try {
    dispatch({
      type: SAVE_PROCEDURE,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};

export const AssignProcedure = (data) => async dispatch => {
  try {
    dispatch({
      type: ASSIGN_PROCEDURE,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};






