import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import io from "socket.io-client";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import Loader from "../../../Global/Loader";
import Graphics from "./componets/graphics/graphics";
import Tables from "../../../template/Table";
import { saveAs } from "file-saver";
import { Search } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import Select from "react-select";
import { Services } from "../Services";
import axios from "axios";

/*=============================================
=            Inventory Component              =
=============================================*/

const headers = [
  "Warehouse",
  "Receipt No.",
  "Item",
  "Description",
  "Count QTY",
  "UOM",
  "PO No.",
  "REF Transload",
  "Sublot",
  "Buyer",
  "Size 1",
  "Size 2",
  "Grams",
  "Weight",
  "Alt QTY"
];
const keys = [
  "WarehouseCode",
  "DocumentNo",
  "Item",
  "Description",
  "CountQty",
  "UOM",
  "Lot",
  "Ref1",
  "Sublot",
  "Ref2",
  "Size1",
  "Size2",
  "Grams",
  "Weight",
  "AltQty"
];

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      search: "",
      catalog: [],
      filter: "",
      clientesSelect: this.props._customer,
      fullLoader: false,
      yearCat: [],
      loading: false,
      pages: 1,
      columns: [
        { label: "Warehouse", key: "WarehouseCode", type: "Input" },
        { label: "Receipt No.", key: "DocumentNo", type: "Input" },
        { label: "Item", key: "Item", type: "Input" },
        { label: "Description", key: "Description" },
        { label: "Count QTY", key: "CountQty", type: "datatime" },
        { label: "UOM", key: "UOM", type: "datatime" },
        { label: "PO No.", key: "Lot", type: "datatime" },
        { label: "REF Transload", key: "Ref1", },
        { label: "Sublot", key: "Sublot" },
        { label: "Buyer", key: "Ref2" },
        { label: "Size 1", key: "Size1" },
        { label: "Size 2", key: "Size2" },
        { label: "Grams", key: "Grams" },
        { label: "Weight", key: "Weight" },
        { label: "Alt QTY", key: "AltQty", type: "Input" },

      ],
      items: [],
      tercerChart: [],
      cuartaChar: [],
      dataTable: [],
    };
  }

  downloadExcel = async () => {
    this.setState({
      loading: true,
    });
    let { dataReport, items } = this.state;
    // let data =dataReport;

    let newData = [];
    items.map((e) => {
      if (e.hidden === false) {
        newData.push(e);
      }
    });

    let data = newData;
    let success = await axios.post(
      "https://servicereportapi.azurewebsites.net/report/dynamic/xlsx",
      {
        data,
        options: {
          headers: headers,
          keys: keys,
          image: {
            name: 'poseidon',
          },
        },
      }
    );
    if (success.data.base64) {
      let dateNow = new Date();
      let titleDownload = `Inventory-${dateNow.getDate() +
        "" +
        (dateNow.getMonth() + 1).toString().padStart(2, "0") +
        "" +
        dateNow.getFullYear()}.xlsx`;
      //   const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';
      const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
      saveAs(linkSource, titleDownload);
      this.setState({
        loading: false,
      });
    } else console.warn("Error downloading file", success);
  };

  componentDidMount = () => {
    this.getDataInventory();
  };

  getDataInventory = () => {
    let objPost = {
      filter: this.state.filter,
      client: this.state.clientesSelect,
    };
    axios
      .post(`${Services.GET_WAREHOUSING.path}warehouse/inventory`, objPost)
      .then((success) => {
        let { byWarehouse, byitem, catalog, table } = success.data.data;
        this.setState({
          fullLoader: true,
          cuartaChar: byWarehouse,
          tercerChart: byitem,
          items: table,
          catalog,
        });
      })
      .catch((error) => {
        this.setState({
          fullLoader: true,
        })
        console.warn(error);
      });
  };

  handleSearch = (name) => (event) =>
    this.setState({ [name]: event.target.value }, () => this.setSearch());
  setSearch = () => {
    let search = this.state.search;
    let cliente = [...this.state.items];
    let haveSearch = false;
    if (search.length > 0) {
      haveSearch = true;
    }
    let all = cliente;
    let newAll = all.map((item) => {
      // const WarehouseCode = item.WarehouseCode
      //   ? item.WarehouseCode.toString().toUpperCase()
      //   : "".toString().toUpperCase();
      const Receipt = item.DocumentNo
        ? item.DocumentNo.toString().toUpperCase()
        : "".toString().toUpperCase();
      const PoNo = item.Lot
        ? item.Lot.toString().toUpperCase()
        : "".toString().toUpperCase();
      const REF = item.Ref1
        ? item.Ref1.toString().toUpperCase()
        : "".toString().toUpperCase();
      const Sublot = item.Sublot
        ? item.Sublot.toString().toUpperCase()
        : "".toString().toUpperCase();
      const Item = item.Item
        ? item.Item.toString().toUpperCase()
        : "".toString().toUpperCase();
      const Description = item.Description
        ? item.Description.toUpperCase()
        : "".toUpperCase();
      const CountQty = item.CountQty
        ? item.CountQty.toString().toUpperCase()
        : "".toString().toUpperCase();
      const UOM = item.UOM ? item.UOM.toUpperCase() : "".toUpperCase();
      const Grams = item.Grams ? item.Grams.toUpperCase() : "".toUpperCase();
      const Ref2 = item.Ref2 ? item.Ref2.toUpperCase() : "".toUpperCase();

      const textData = search.toUpperCase();

      if (
        // WarehouseCode.indexOf(textData) > -1 ||
        Receipt.indexOf(textData) > -1 ||
        PoNo.indexOf(textData) > -1 ||
        REF.indexOf(textData) > -1 ||
        Description.indexOf(textData) > -1 ||
        Item.indexOf(textData) > -1 ||
        Sublot.indexOf(textData) > -1 ||
        CountQty.indexOf(textData) > -1 ||
        UOM.indexOf(textData) > -1 ||
        Grams.indexOf(textData) > -1 ||
        Ref2.indexOf(textData) > -1
      ) {
        item.hidden = false;
      } else {
        item.hidden = haveSearch ? true : false;
      }
      return item;
    });
    let pages = newAll.length / 10 + 1;

    this.setState({
      items: newAll,
      pages: pages,
    });
  };

  handleChangeSelect = () => (event) => {
    let { selected, items } = this.state;
    let newData = [];
    if (event && event.value) {
      selected = event.value;
      items.map((e) => {
        if (e.WarehouseCode === selected) {
          e.hidden = false;
        } else {
          e.hidden = true;
        }

        return e;
      });
      this.setState({ items, selected }, () => { });
    } else {
      selected = null;
      items.map((e) => {
        e.hidden = false;
        return e;
      });
      this.setState({ items, selected }, () => { });
    }
  };

  render() {
    const {
      fullLoader,
      cuartaChar,
      tercerChart,
      search,
      items,
      columns,
      loading,
      selected,
      catalog,
    } = this.state;

    let dataTable = [];

    items.map((e) => {
      if (e.hidden === false) {
        dataTable.push(e);
      }
      return e;
    });

    return (
      <>
        {console.log('data table-->', dataTable)}
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-th fa-1x mr-2"></em>
              Inventory
            </div>
          </div>
          <Col md={12} xl={12}>
            <Graphics
              cuartaChar={cuartaChar}
              tercerChart={tercerChart}
            ></Graphics>
          </Col>
          <div style={{ height: "4em" }}></div>

          <Card className="myCard">
            <CardBody>
              {/* <div style={{ height: 67 }}></div> */}
              <div style={{ marginBottom: "3em" }} className="row">
                <div style={{ marginTop: "6px" }} className="col-7 row">
                  {" "}
                  <div className={"col-3"}>
                    <h4
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: "8px",
                      }}
                    >
                      Warehouse
                    </h4>
                  </div>
                  <div className={"col-4"} key={1}>
                    <Select
                      autoComplete="off"
                      id=""
                      required
                      name=""
                      className="sizewit"
                      placeholder="Select"
                      value={selected}
                      onChange={this.handleChangeSelect()}
                      options={catalog}
                    />
                  </div>
                </div>

                <div className="col-4">
                  <Search
                    input={{
                      icon: "search",
                      iconPosition: "left",
                      fluid: true,
                    }}
                    placeholder="..."
                    value={search}
                    open={false}
                    name="search"
                    id="search"
                    onSearchChange={this.handleSearch("search")}
                  />
                </div>
                <div className="col-1">
                  <Col lg={12} sm={12} md={12}>
                    <div className=" col-2 text-center ">
                      <Row>
                        {loading ? (
                          <center>
                            {" "}
                            <Icon
                              loading={loading}
                              style={{ marginTop: "6px" }}
                              name="circle notch"
                              size="large"
                            />
                          </center>
                        ) : (
                          <center>
                            <em
                              style={{ color: "#43a047", marginTop: "6px" }}
                              onClick={this.downloadExcel}
                              className="download fa-2x fas fa-file-excel mr-2"
                            ></em>
                          </center>
                        )}
                      </Row>
                    </div>
                  </Col>
                </div>
              </div>

              <Tables
                data={dataTable}
                columns={columns}
                dataSearch={this.state.search}
                selectedFilter={this.state.filter}
                options={{
                  pagination: true,
                  search: false,
                  downloads: { xlsx: false, pdf: false },
                }}
              ></Tables>
            </CardBody>
          </Card>
        </ContentWrapper>
      </>
    );
  }
}

export default withRouter(Inventory);
