import {
  UPDATE_SELECTION,SET_SELECTION,SET_NO,SET_CONTAINER_REFERENCE,PUSH_HISTORI,SAVE_PROCEDURE,ASSIGN_PROCEDURE
} from './../../../actions/Booking/create/types';

const initialState = {
  selected_pos_str: '',
  selected_pos: [],
  disabled_actions: true,
  selection_pos : [],
  selection_No : '',
  set_Container_Reference:[],
  push_histori:'',
  save_procederu:{},
  assign_procederu:{}


};

export default (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_SELECTION:
    return {
      selected_pos_str: action.payload.selected_pos_str,
      selected_pos: action.payload.selected_pos,
      disabled_actions: action.payload.disabled_actions
    }; 
  case SET_SELECTION:
    return {
      ...state,
      selection_pos:action.payload
    };  
  case SET_NO:
    return {
      ...state,
      selection_No:action.payload
    };
  case SET_CONTAINER_REFERENCE:
    return {
      ...state,
      set_Container_Reference:action.payload
    };  
  case PUSH_HISTORI:
    return {
      ...state,
      push_histori:action.payload
    }; 
  case SAVE_PROCEDURE:
    return {
      ...state,
      save_procederu:action.payload
    }; 
  case ASSIGN_PROCEDURE:
    return {
      ...state,
      assign_procederu:action.payload
    };     
  default:
    return state;
  }
};