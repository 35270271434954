
export const FormComponent = {
	Order_Summary: [
		// {
		// 	label: 'Order summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'Supplier',
			type: 'info',
			id: 'Supplier',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Buyer',
			type: 'info',
			// typeValue: 'number',
			id: 'Buyer',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Department',
			type: 'info',
			id: 'Department',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Project',
			type: 'info',
			id: 'ProjectCutOffId',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Project cut-off',
			type: 'info',
			id: 'CutOffDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Incoterms',
			type: 'info',
			id: 'CodIncoterm',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Product description',
			type: 'info',
			id: 'ProductDescription',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Measures (CM)',
			type: 'info',
			id: 'Mesures',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Weight (kg)',
			type: 'info',
			id: 'Weight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'From',
			type: 'info',
			id: 'From',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'To',
			type: 'info',
			id: 'To',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Type of delivery',
			type: 'info',
			id: 'TypeDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Value euro',
			type: 'info',
			id: 'Value',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Chargable weight (KG)',
			type: 'info',
			id: 'ChargeableWeight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Delivery conditions',
			type: 'info',
			id: 'DeliveryConditions',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Date of delivery',
			type: 'info',
			id: 'DateDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Note',
			type: 'info',
			// typeValue: 'number',
			id: 'Note',
			class: '_align _name',
			colm: 12,
		},
	],
	Summary_SM: [
		// {
		// 	label: 'Order summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'ETA',
			type: 'info',
			id: 'ETA',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Actual Delivery Date',
			type: 'info',
			// typeValue: 'number',
			id: 'ActualDeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Booking No.',
			type: 'info',
			id: 'BookingNo',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Vessel',
			type: 'info',
			id: 'Vessel',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'POD',
			type: 'info',
			id: 'POD',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Container',
			type: 'info',
			id: 'ContainerNo',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'BL/ AWB/ Plate',
			type: 'info',
			id: 'BL_AWB',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Operator',
		// 	type: 'info',
		// 	id: 'Operator',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
		{
			label: 'Ship Cost',
			type: 'info',
			id: 'ShipmentCost',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Weight',
			type: 'info',
			id: 'Weight',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Volume',
		// 	type: 'info',
		// 	id: 'Volume',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
		{
			label: 'Operator',
			type: 'info',
			id: 'Operator',
			class: '_align _name',
			colm: 3,
		},
	],
	PO: [
		// {
		// 	label: 'Order Summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'Supplier',
			type: 'info',
			id: 'Supplier',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Buyer',
			type: 'info',
			id: 'Buyer',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Department',
			type: 'info',
			id: 'Department',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Project',
			type: 'info',
			id: 'ProjectCutOffId',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Project Cut-off',
			type: 'info',
			id: 'CutOffDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Incoterm',
			type: 'info',
			id: 'CodIncoterm',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Product Description',
			type: 'info',
			id: 'ProductDescription',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Measures (CM)',
			type: 'info',
			id: 'Mesures',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Weight (Kg)',
			type: 'info',
			id: 'Weight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'From',
			type: 'info',
			id: 'From',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'To',
			type: 'info',
			id: 'To',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Type of Delivery',
			type: 'info',
			id: 'TypeDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Value Euro',
			type: 'info',
			id: 'Value',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Chargable Weight (Kg)',
			type: 'info',
			id: 'ChargeableWeight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Delivery Conditions',
			type: 'info',
			id: 'DeliveryConditions',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Date of Delivery',
			type: 'info',
			id: 'DateDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Note',
			type: 'info',
			// typeValue: 'number',
			id: 'Note',
			class: '_align _name',
			colm: 12,
		},
	],
	Production: [
		// {
		// 	label: 'Order Summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'Promise cargo ready',
			type: 'info',
			id: 'PromiseCargoReady',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Supplier contacted?',
			type: 'info',
			id: 'SupplierContacted',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Confirmed cargo ready',
			type: 'info',
			id: 'ConfirmedCargoReady',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Actual cargo ready',
			type: 'info',
			id: 'ActualCargoReadyDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Est. pickup/receive',
			type: 'info',
			id: 'EstPickupReceiveDate',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Confirmed readiness date at plant',
		// 	type: 'info',
		// 	id: 'ConfirmedReadinessDate',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
		{
			label: 'Actual pickup/receive',
			type: 'info',
			id: 'ActualPickupReceiveDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Logistic PO (FCA)',
			type: 'info',
			id: 'CustomerReference1',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Logistic PO (FOB)',
			type: 'info',
			id: 'CustomerReference2',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Product desciption',
			type: 'info',
			id: 'ProductDescription',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Measure',
			type: 'info',
			id: 'Mesures',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Weight',
			type: 'info',
			id: 'Weight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'From',
			type: 'info',
			id: 'From',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Type of delivery',
			type: 'info',
			id: 'TypeDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Incoterm Code',
			type: 'info',
			id: 'CodIncoterm',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Date of delivery',
			type: 'info',
			id: 'DateDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Operator',
			type: 'info',
			id: 'Operator',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Out of time not planned with procurement',
			type: 'info',
			id: 'OutTimePlanProcurement',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Customer Note',
			type: 'info',
			id: 'Note',
			class: '_align _name',
			colm: 12,
		},
	],
	ProductionSM: [
		// {
		// 	label: 'Order Summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'Promise cargo ready',
			type: 'info',
			id: 'PromiseCargoReady',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Supplier contacted?',
			type: 'info',
			id: 'SupplierContacted',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Confirmed cargo ready',
			type: 'info',
			id: 'ConfirmedCargoReady',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Actual cargo ready',
			type: 'info',
			id: 'ActualCargoReadyDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Est. pickup/receive',
			type: 'info',
			id: 'EstPickupReceiveDate',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Confirmed readiness date at plant',
		// 	type: 'info',
		// 	id: 'ConfirmedReadinessDate',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
		{
			label: 'Actual pickup/receive',
			type: 'info',
			id: 'ActualPickupReceiveDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Logistic PO (FCA)',
			type: 'info',
			id: 'CustomerReference1',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Logistic PO (FOB)',
			type: 'info',
			id: 'CustomerReference2',
			class: '_align _name',
			colm: 3,
		},
	],
	Warehousing: [
		// {
		// 	label: 'Order Summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'WMS reference',
			type: 'info',
			id: 'WMSReference',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Est. arrival consol WH',
			type: 'info',
			id: 'EstArrivalConsolWH',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Actual arrival consol WH',
			type: 'info',
			id: 'ActualArrivalConsolWH',
			class: '_align _name',
			colm: 3,
		},

	],
	Transportation: [
		{
			label: 'Transport mode',
			type: 'info',
			id: 'TransportModeId',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Booking No.',
			type: 'info',
			id: 'BookingNo',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Vessel',
			type: 'info',
			id: 'Vessel',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Voyage / flight No.',
			type: 'info',
			id: 'VoyageFlightNo',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Carrier',
			type: 'info',
			id: 'Carrier',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'POL',
			type: 'info',
			id: 'POL',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'POD',
			type: 'info',
			id: 'POD',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'BL/AWB/Plate',
			type: 'info',
			id: 'BL_AWB',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Container No.',
			type: 'info',
			id: 'ContainerNo',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Interglobo file',
			type: 'info',
			id: 'IntergloboFile',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Real weight',
			type: 'info',
			id: 'RealWeigth',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Chargeable weight',
			type: 'info',
			id: 'ChargeableWeigth',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'ETD',
			type: 'info',
			id: 'ETD',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'ETA',
			type: 'info',
			id: 'ETA',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Confirmed delivery',
			type: 'info',
			id: 'ActualDeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'New planned delivery',
			type: 'info',
			id: 'NewPlannedDeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Est. delivery',
			type: 'info',
			id: 'EstDeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Out of time not planned with shipyard',
			type: 'info',
			id: 'OutTimePlanShipyard',
			class: '_align _name',
			colm: 3,
		},
	],
	TransportationSM: [
		{
			label: 'ETD',
			type: 'info',
			id: 'ETD',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'POL',
			type: 'info',
			id: 'POL',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Actual Delivery Date',
			type: 'info',
			// typeValue: 'number',
			id: 'ActualDeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Interglobo file',
			type: 'info',
			id: 'IntergloboFile',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Real weight',
			type: 'info',
			id: 'RealWeigth',
			class: '_align _name',
			colm: 3,
		}, {
			label: 'Chargeable weight',
			type: 'info',
			id: 'ChargeableWeigth',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Confirmed delivery',
		// 	type: 'info',
		// 	id: 'ActualDeliveryDate',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
		{
			label: 'New planned delivery',
			type: 'info',
			id: 'NewPlannedDeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Combined',
			type: 'info',
			id: 'Combined',
			class: '_align _name',
			colm: 3,
		},
	],
	Comments: [
		{
			label: 'Comment type',
			type: 'combo',
			id: 'ComTypeId',
			class: '_align _name',
			colm: 12,
		},
		{
			label: 'Comment',
			type: 'text-area',
			id: 'Comment',
			class: '_align _name',
			colm: 12,
		},
	],
	Documments: [
		{
			label: 'Name',
			type: 'text',
			id: 'Name',
			class: '_align _name',
			colm: 6,
		},
		{
			label: 'Category',
			type: 'combo',
			id: 'CategoryId',
			class: '_align _name',
			colm: 6,
		},
	],
	FiltersPurchase: [
		{
			label: 'Status',
			type: 'multiSelect',
			id: 'StatusId',
			idNameCat: 'StatusId',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Supplier',
			type: 'multiSelect',
			id: 'Supplier',
			idNameCat: 'Supplier',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Project',
			type: 'multiSelect',
			id: 'ProjectCutOffId',
			idNameCat: 'ProjectCutOffId',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Buyer',
			type: 'multiSelect',
			id: 'Buyer',
			idNameCat: 'Buyer',
			class: '_align _name',
			colm: 3,
		},
	],
	// FiltersShipments: [
	// 	{
	// 		label: 'Status',
	// 		type: 'multiSelect',
	// 		id: 'StatusId',
	// 		idNameCat: 'StatusShip',
	// 		class: '_align _name',
	// 		colm: 3,
	// 	},
	// 	{
	// 		label: 'Supplier',
	// 		type: 'multiSelect',
	// 		id: 'Supplier',
	// 		idNameCat: 'Supplier',
	// 		class: '_align _name',
	// 		colm: 3,
	// 	},
	// 	{
	// 		label: 'Project',
	// 		type: 'multiSelect',
	// 		id: 'ProjectCutOffId',
	// 		idNameCat: 'ProjectCutOffId',
	// 		class: '_align _name',
	// 		colm: 3,
	// 	},
	// 	{
	// 		label: 'Est. delivery',
	// 		type: 'date',
	// 		id: 'EstDeliveryDate',
	// 		class: '_align _name',
	// 		colm: 3,
	// 	},
	// 	{
	// 		label: 'Confirmed delivery',
	// 		type: 'date',
	// 		id: 'ActualDeliveryDate',
	// 		class: '_align _name',
	// 		colm: 3,
	// 	},
	// 	{
	// 		label: 'Confirmed cargo ready',
	// 		type: 'date',
	// 		id: 'ConfirmedCargoReady',
	// 		class: '_align _name',
	// 		colm: 3,
	// 	},
	// 	// {
	// 	// 	label: 'Department',
	// 	// 	type: 'multiSelect',
	// 	// 	id: 'Department',
	// 	// 	idNameCat: 'Department',
	// 	// 	class: '_align _name',
	// 	// 	colm: 3,
	// 	// },
	// ],
	FiltersShipments: [
		{
			label: 'Promise Cargo Ready',
			type: 'date',
			id: 'PromiseCargoReady',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Actual Cargo Ready',
			type: 'date',
			id: 'ActualCargoReadyDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'ETD',
			type: 'date',
			id: 'ETD',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'ETA',
			type: 'date',
			id: 'ETA',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Department',
		// 	type: 'multiSelect',
		// 	id: 'Department',
		// 	idNameCat: 'Department',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
	],
	form_new_po: [
		// {
		// 	label: 'Order summary',
		// 	type: 'title',
		// 	colm: 12,
		// },
		{
			label: 'PO Number',
			type: 'text_without_spaces',
			id: 'PONo',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Supplier',
			type: 'autocomplete',
			id: 'Supplier',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Buyer',
			type: 'autocomplete',
			id: 'Buyer',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Department',
			type: 'autocomplete',
			id: 'Department',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Project',
			type: 'combo',
			id: 'ProjectCutOffId',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Product Description',
			type: 'text',
			id: 'ProductDescription',
			class: '_align _name',
			colm: 9,
		},
		{
			label: 'Measures (CM)',
			type: 'text',
			id: 'Mesures',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Weight (KG)',
			type: 'text',
			id: 'Weight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Chargeable Weight (KG)',
			type: 'text',
			id: 'ChargeableWeight',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'From',
			type: 'text',
			id: 'From',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'To',
			type: 'autocomplete',
			id: 'To',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Type of delivery',
			type: 'autocomplete',
			id: 'TypeDelivery',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Incoterms',
			type: 'combo',
			id: 'CodIncoterm',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Value Euro',
			type: 'text',
			id: 'Value',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Cargo Readiness (Customer)',
			type: 'date',
			id: 'DateReadiness',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Date of delivery',
			type: 'date',
			id: 'DeliveryDate',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Logistic PO (FCA)',
			type: 'text',
			id: 'CustomerReference1',
			class: '_align _name',
			colm: 3,
		},
		{
			label: 'Logistic PO (FOB)',
			type: 'text',
			id: 'CustomerReference2',
			class: '_align _name',
			colm: 3,
		},
		// {
		// 	label: 'Product Description',
		// 	type: 'text-area',
		// 	id: 'ProductDescription',
		// 	class: '_align _name',
		// 	colm: 3,
		// },
		{
			label: 'Note',
			type: 'text-area',
			id: 'Note',
			class: '_align _name',
			colm: 6,
		},
	],
}