import React, { Component } from 'react';
import RestService from '../../../../../services/restService';
import { InputGroup, InputGroupAddon, InputGroupText, Input,Collapse,Card,CardBody } from 'reactstrap';

import FormModel from './models/form';
import Fade from 'react-reveal/Fade';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import Axios from 'axios';
import {Services} from './../../Services';

const rest = new RestService();



class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Form: FormModel,
      MAIN: '',
      DATES: '',
      selects: {
        CurrentPortLoad: [],
        DestinationPort: [],
        MoveType: [],
        EquipSize: [],
        EquipType: []
      },
      Filter: {},
      Inputs: '',
      actionFiltersText : 'SHOW FILTERS',
      open : false
    };
  }


    componentDidMount = () => {
      this.getCatalogs();
    }


    componentWillReceiveProps = (nextProps) => {
      this.getCatalogs();
    }


    getCatalogs = async () => {
      let success = await Axios.get(`${Services.GET_FILTERS.path}search/filters/`);
      
      if (success.data && success.data.data) {
        this.setState({
          selects: success.data.data
        }, () => {
          this.buildForm('MAIN');
          this.buildForm('DATES');
        });
      }
    }
    handleChangeSelect = (selected) => event => {
      let { Filter } = this.state;
      if (event && event.value) {
        Filter[selected] = event.value;
        this.setState({ Filter }, () => {
          this.props.sendQuery(this.state.Filter);
          this.buildForm('MAIN');
          this.buildForm('DATES');
        });
      } else {
        Filter[selected] = '';
        this.setState({ Filter }, () => {
          this.props.sendQuery(this.state.Filter);
          this.buildForm('MAIN');
          this.buildForm('DATES');
        });
      }
    };



    handleChangeDate = name => event => {
      let { Filter } = this.state;
      try {
        Filter[name] = event.format('YYYY-MM-DD');
        this.setState({ Filter }, () => {
          this.props.sendQuery(this.state.Filter);
          this.buildForm('MAIN');
          this.buildForm('DATES');
        });
      }
      catch (ex) {
        Filter[name] = '';
        this.setState({ Filter }, () => {
          this.props.sendQuery(this.state.Filter);
          this.buildForm('MAIN');
          this.buildForm('DATES');
        });
      }
    }



    buildForm = (key) => {
      const { Filter, selects, Form } = this.state;
      let Inputs = Form[key].map((e, i) => {
        if (e.type === 'Select' && e.show) return (
          <div className={e.class} key={e.id}>
            <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required == true ? <span>*</span> : ''} </label></span></Fade>
            <Select
              id={e.id}
              required
              type={e.datatype}
              name={e.name}
              value={Filter[e.id]}
              onChange={this.handleChangeSelect(e.id)}
              options={selects[e.options]}
            />
            <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
          </div>);
        else if (e.type === 'Datetime') return (
          <div className={e.class} key={e.id} >
            <Fade left opposite>
              <span id={e.label} style={e.style}> <label  >{e.label} {e.required == true ? <span>*</span> : ''} </label></span></Fade>
            <Datetime
              required
              type={e.datatype}
              inputProps={{ className: 'form-control', id: e.id }}
              value={Filter[e.id]}
                        
              onChange={this.handleChangeDate(e.id)}
            />
            <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
          </div>
        );
      });
      this.setState({
        [key]: Inputs
      });
    }

    actionFilters = ()=>{
      this.setState(prevState => ({    // prevState?
        open: !prevState.open
      }));
    };


    render() {
      const {open} = this.state;
      return (
        <Card className="myCard">
          <CardBody>
            <div>
              <div className="row">
                <div className="col-md-2 col-lg-2 col-sm-12">
                  <InputGroup style={{paddingTop:'10%'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText onClick={this.actionFilters} style={{'cursor':'pointer'}}>{!open ? 'SHOW FILTERS' : 'HIDE FILTERS'}</InputGroupText>
                    </InputGroupAddon>
                                
                  </InputGroup>
                </div>
                {this.state.DATES}
              </div>
              <Collapse isOpen={this.state.open}>
                <div className="row">
                  {this.state.MAIN}
                </div>
              </Collapse>
            </div>
          </CardBody>
        </Card>
            
      );
    }
}

export default Filters;


