
/*eslint-disable*/
import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Link, withRouter, useParams } from 'react-router-dom';
import moment from 'moment';

import { Button, Card, CardBody, Col, Row, Badge, Label } from "reactstrap";
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import FormCreator from "../../../Components/FormBuilder";
import { FormComponent } from "./components/Form";
import { Model_New_Build } from "./components/Model";
import Table from '../../../../components/template/TableComponents/Table'
// import TableFilterComponents from '../../../Components/TableFilters'
import { Popup, Tab, Form, Input } from "semantic-ui-react";
import { Services } from "../Services";
import Axios from "axios";
import Swal from "sweetalert2";
import { ValidatorService, ValidatorSchema, ValidatorSchema2 } from "../../../Components/Helpers";
import Courier from '../components/svg_img/courier.svg'
import Loader from "../../../Global/Loader";
import path from 'path'


import Production from "./Tabs/production";
import PurcharseOrder from "./Tabs/purchaseOrder";
import Warehousing from "./Tabs/warehousing";
import Transportation from "./Tabs/transportation";
import Events from "./Tabs/events";
import Cost from "./Tabs/Cost";
import Comments from "./Tabs/comments";
import Documents from "./Tabs/documents";
import { Accordion, Typography, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// const TableFilterComponents = React.lazy(() => import("../../../Components/TableFilters"));

const TransportMode = {
	1: 'fas fa-ship fa-lg',
	2: 'fas fa-ship fa-lg',
	3: 'fas fa-plane fa-lg',
	4: 'fas fa-truck fa-lg',
	5: 'fas fa-truck fa-lg',
	6: <img src={Courier} />
	// 6: <Warning />
}

// const Message = {
// 	CargoReadyAlert: { '#FF0000': 'Cargo readiness - Out of Time', '#32CB2F': 'Cargo readiness - On Time', '#B5B2B2': 'Cargo readiness' },
// 	DeliveryAlert: { '#FF0000': 'Delivery - Out of Time', '#32CB2F': 'Delivery - On Time', '#B5B2B2': 'Delivery', '#000000': 'Delivery - Out of Project Cut-off' }
// }

const Color = { 0: '#FF0000', 1: '#7E7E7E', 2: '#000000', 'On Time': '#32CB2F', 'Out of Time': '#FF0000', 'Out of Project Cut-off': '#000000' }

function BuildDetail(props) {
	// const { idms, idpo, idline } = useParams();

	const [idms, setIdms] = useState(props.MS)
	const [idpo, setIdpo] = useState(props.poId)
	const [idline, setIdline] = useState(props.noLine)


	const [customerSelect, setCustomerSelect] = useState(undefined)
	const [fullLoader, setFullLoader] = useState(false)
	const [form, setForm] = useState(FormComponent.Summary_SM)
	const [modelNewBuild, setModelNewBuild] = useState({ ...Model_New_Build() })
	const [Catalogs, setCatalogs] = useState({ ...Model_New_Build().Cat_Detail });
	const [MS, setMS] = useState([])
	const [poSelected, setPOSelected] = useState(undefined)
	const [slSelected, setSLSelected] = useState(undefined)

	const [editMS, setEditMS] = useState(false)
	const [editShip, setEditShip] = useState(false)
	const [flatCheck, setFlatCheck] = useState(false)
	const [headerInfo, setHeaderInfo] = useState({})
	const [key, setKey] = useState(1)
	const [modalComment, setModalCommet] = React.useState(false);
	const [groupShipments, setGroupShipments] = useState({})
	const [comments, setComments] = useState([])
	const [documents, setDocuments] = useState([])
	const [dataPOs, setDataPOs] = useState([])
	const [userId, setUserId] = useState(undefined)
	const [rolId, setRolId] = useState(undefined)
	const [groupsEvents, setGroupsEvents] = useState([])
	// const [columns, setColumns] = useState([
	// 	{
	// 		key: '', label: '', width: '5px', custom: (value) => <>
	// 			<Popup
	// 				basic
	// 				inverted
	// 				content={Message.CargoReadyAlert[value.CargoReadyAlert]}
	// 				// content='Cargo readiness'
	// 				size='mini'
	// 				trigger={
	// 					<i className="fas fa-circle fa-lg mr-1 ml-1"
	// 						// style={{ color: Color[Math.round(Math.random() * 1)] }}
	// 						style={{ color: value.CargoReadyAlert }}
	// 					></i>
	// 				} />

	// 			<Popup
	// 				basic
	// 				inverted
	// 				content={Message.DeliveryAlert[value.DeliveryAlert]}
	// 				// content='Delivery'
	// 				size='mini'
	// 				trigger={
	// 					<i className="fas fa-circle fa-lg mr-1 ml-1"
	// 						//  style={{ color: Color[Math.round(Math.random() * 1)] }}
	// 						style={{ color: value.DeliveryAlert }}
	// 					></i>
	// 				} />
	// 		</>
	// 	},
	// 	{
	// 		key: 'NoLine', label: 'No. Line', class: 'headerText', custom: (value) =>
	// 			<Col style={{ cursor: editShip ? 'not-allowed' : 'pointer' }}
	// 				onClick={() => { setShipmentLine({ name: value.NoLine, status: value.Status }), shipLineId(value.ShipmentNewBuildId) }}>
	// 				<span style={{ color: '#5D9CEC' }}>{value.NoLine}</span>
	// 			</Col>
	// 	},
	// 	{
	// 		key: 'TransportMode', label: 'Transport mode', class: 'headerText', custom: (value) =>
	// 			<div >
	// 				{value.TransportModeId !== 6
	// 					?
	// 					<i className={TransportMode[value.TransportModeId]} style={{ color: '#7E7E7E' }} />
	// 					:
	// 					TransportMode[value.TransportModeId]
	// 				}
	// 			</div>
	// 	},
	// 	// { key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Badge style={{ background: '#5D9CEC', padding: '0.4em' }} pill>&nbsp;&nbsp;&nbsp;&nbsp;{value.Status}&nbsp;&nbsp;&nbsp;&nbsp;</Badge> },
	// 	{ key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Button className={'btn-oval'} color={'primary'} >{value.Status}</Button> },
	// 	{ key: 'ContainerNo', label: 'Container No.', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ContainerNo}</span> },
	// 	{ key: 'POL', label: 'POL', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.POL}</span> },
	// 	{ key: 'POD', label: 'POD', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.POD}</span> },
	// 	// { key: 'ReadyDateMSC', label: 'Type of delivery', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ReadyDateMSC}</span> },
	// 	{ key: 'PromiseCargoReady', label: 'Promise cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.PromiseCargoReady}</span> },
	// 	// { key: 'ActualCargoReadyDate', label: 'Actual cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ActualCargoReadyDate}</span> },
	// 	{ key: 'ConfirmedCargoReady', label: 'Confirmed cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ConfirmedCargoReady}</span> },
	// 	{ key: 'ETD', label: 'ETD', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ETD}</span> },
	// 	{ key: 'ETA', label: 'ETA', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ETA}</span> },
	// 	{ key: 'ActualDeliveryDate', label: 'Confirmed delivery', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ActualDeliveryDate}</span> },

	// ])
	const [columnsMS, setColumnsMS] = useState([
		{
			key: 'TransportMode', label: '', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) =>
				<div >
					{value.TransportModeId !== 6
						?
						<i className={TransportMode[value.TransportModeId]} style={{ color: '#7E7E7E' }} />
						:
						TransportMode[value.TransportModeId]
					}
				</div>
		},
		{
			key: 'PONo', label: 'Po No.', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <Col style={{ cursor: value.Selected !== undefined ? (value.Selected ? 'pointer' : 'not-allowed') : 'pointer' }}
				onClick={value.Selected === undefined ? () => { getMasterShipment(value.ShipmentNewBuildId, value.PONewBuildId) } : ''}>
				<span className={value.Selected !== undefined ? (value.Selected ? 'lineSelectedOn' : 'lineSelectedOff') : 'lineSelected'}>  {value.PONo}</span>
			</Col>
		},
		{
			key: 'NoLine', label: 'Line', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.NoLine}</span>

		},
		{
			key: 'Combined', label: 'Combined', minWidth: '7em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <>
				{/* <div style={{ cursor: 'pointer' }}> */}
				{value.Combined !== '0' ?
					<i className="fas fa-check" style={{ color: '#13B0F0' }}></i>
					: ''
				}
				{/* </div> */}
			</>
		},
		// { key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Button className={'btn-oval'} color={'primary'} >{value.Status}</Button> },
		{ key: 'Status', label: 'Status', minWidth: '7em', maxWidth: '10em', class: 'headerText', custom: (value) => <Badge style={{ background: '#5D9CEC', padding: '0.4em' }} pill>&nbsp;&nbsp;&nbsp;&nbsp;{value.Status}&nbsp;&nbsp;&nbsp;&nbsp;</Badge> },
		// { key: 'Status', label: 'Status', maxWidth: '11em', minWidth: '11em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle' style={{ color: '#09b1e5', fontWeight: 'bold' }}>{value.Status} </span> },
		{ key: 'NewBuild', label: 'Project', minWidth: '7em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.NewBuild}</span> },
		{ key: 'Department', label: 'Department', minWidth: '10em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.Department}</span> },
		{ key: 'Supplier', label: 'Supplier', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.Supplier}</span> },
		{ key: 'From', label: 'From', minWidth: '7em', maxWidth: '10em', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.From}</span> },
		{ key: 'To', label: 'To', class: 'headerText', minWidth: '7em', maxWidth: '10em', custom: (value) => <span className='columTableStyle'>{value.To}</span> },
		{ key: 'TypeDelivery', label: 'Type of delivery', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.TypeDelivery}</span> },
		{ key: 'PromiseCargoReady', label: 'Promise cargo ready', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.PromiseCargoReady}</span> },
		{ key: 'ConfirmedCargoReady', label: 'Confirmed cargo ready', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.ConfirmedCargoReady}</span> },
		{ key: 'CargoReadyAlert', label: 'Cargo readiness', minWidth: '5em', maxWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle' style={{ color: Color[value.CargoReadyAlert], fontWeight: 'bold' }}>{value.CargoReadyAlert}</span> },
	])

	const [events, setEvents] = useState(
		{
			152: { label: "Supplier contacted", value: undefined, completed: false, orden: 1 },
			153: { label: "Confirmed cargo ready", value: undefined, completed: false, orden: 2 },
			159: { label: "In transit", value: undefined, completed: false, orden: 3 },
			162: { label: "Confirmed delivery", value: undefined, completed: false, orden: 4 },
		}
	)


	function EditBuild(flat, form) {
		let Model = { ...modelNewBuild };

		if (flat) {
			if (form === 'MS') {
				// Model.Order_Summary.CutOffDate.hidden = true
				FormComponent.Summary_SM.find((e) => {
					if (e.id === 'BookingNo' || e.id === 'POD' || e.id === 'ContainerNo' || e.id === 'BL_AWB' || e.id === 'ShipmentCost' || e.id === 'ChargeableWeigth' || e.id === 'Weight' || e.id === 'Vessel' || e.id === 'Volume') {
						e.type = 'text'
					} else if (e.id === 'ETA' || e.id === 'ActualDeliveryDate') {
						e.type = 'date'
					} else if (e.id === 'Operator') {
						e.type = 'combo'
					}
				})
			} else if (form === 'Ship') {
				// Model.Transportation.NewPlannedDeliveryDate.hidden = true
				// Model.Transportation.EstDeliveryDate.hidden = true

				FormComponent.PO.find((e) => {
					if (e.id === 'DateDelivery') {
						e.type = 'date'
					} else if (e.id === 'Supplier' || e.id === 'Buyer' || e.id === 'Department' || e.id === 'ProductDescription' || e.id === 'Mesures' || e.id === 'Weight' || e.id === 'From' || e.id === 'To' || e.id === 'Value' || e.id === 'ChargeableWeight' || e.id === 'DeliveryConditions') {
						e.type = 'text'
					} else if (e.id === 'CodIncoterm' || e.id === 'ProjectCutOffId') {
						e.type = 'combo'
					} else if (e.id === 'Note') {
						e.type = 'text-area'
					} else if (e.id === 'TypeDelivery') {
						e.type = 'autocomplete'
					}
				})

				FormComponent.ProductionSM.find((e) => {
					if (e.id === 'PromiseCargoReady' || e.id === 'ConfirmedCargoReady' || e.id === 'ActualCargoReadyDate' || e.id === 'EstPickupReceiveDate' || e.id === 'ActualPickupReceiveDate') {
						e.type = 'date'
					} else if (e.id === 'CustomerReference1' || e.id === 'CustomerReference2') {
						e.type = 'text'
					} else if (e.id === 'SupplierContacted') {
						e.type = 'check'
					}
				})

				FormComponent.Warehousing.find((e) => {
					if (e.id === 'ActualArrivalConsolWH' || e.id === 'EstArrivalConsolWH') {
						e.type = 'date'
					} else if (e.id === 'WMSReference') {
						e.type = 'text'
					}
				})

				FormComponent.TransportationSM.find((e) => {
					if (e.id === 'ETD' || e.id === 'ETA' || e.id === 'ActualDeliveryDate' || e.id === 'NewPlannedDeliveryDate') {
						e.type = 'date'
					} else if (e.id === 'BookingNo' || e.id === 'Vessel' || e.id === 'VoyageFlightNo' || e.id === 'Carrier' || e.id === 'POL' || e.id === 'POD' || e.id === 'BL_AWB' || e.id === 'ContainerNo' || e.id === 'IntergloboFile' || e.id === 'RealWeigth' || e.id === 'ChargeableWeigth' || e.id === 'Chargeable' || e.id === 'Logistivcs') {
						e.type = 'text'
					} else if (e.id === 'TransportModeId') {
						e.type = 'combo'
					} else if (e.id === 'Combined') {
						e.type = 'check'
					}
				})
			}
		} else {
			if (form === 'MS') {
				FormComponent.Summary_SM.find((e) => {
					if (e.id) {
						e.type = 'info'
					}
				})
			} else if (form === 'Ship') {
				FormComponent.PO.find((e) => {
					e.type = 'info'
				})
				FormComponent.ProductionSM.find((e) => {
					e.type = 'info'
				})
				FormComponent.Warehousing.find((e) => {
					e.type = 'info'
				})
				FormComponent.TransportationSM.find((e) => {
					e.type = 'info'
				})
			}
		}

		if (flat && form === 'Ship') {
			dataPOs.map((e) => {
				e.Selected = e.ShipmentNewBuildId.toString() === slSelected.toString() ? true : false
			})
			setDataPOs(dataPOs)
		} else if (!flat && form === 'Ship') {
			dataPOs.map((e) => {
				e.Selected = undefined
			})
			setDataPOs(dataPOs)
		}
	}

	// function ConfirmDeleteShip(params) {
	// 	Swal.fire({
	// 		title: 'Are you sure?',
	// 		text: "You won't be able to revert this!",
	// 		type: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonColor: '#3085d6',
	// 		cancelButtonColor: '#d33',
	// 		confirmButtonText: 'Delete',
	// 		preConfirm: (result) => {
	// 			if (result) {
	// 				deleteShipLine(params)
	// 			}

	// 		}
	// 	})

	// }

	// const deleteShipLine = useCallback(async (NoLine) => {
	// 	let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/delete_ship_line/${NoLine}`;

	// 	try {
	// 		let response = await Axios.put(url)
	// 		if (response.data.status === 200) {
	// 			Swal.fire({
	// 				// 'Deleted!',
	// 				// 'Your file has been deleted.',
	// 				// 'success'
	// 				type: 'success',
	// 				title: 'Deleted',
	// 				text: "Shipment line has been deleted.",
	// 				showConfirmButton: false,
	// 				timer: 1500
	// 			})
	// 			getPOId()
	// 		}
	// 	} catch (error) {
	// 		console.log("🚀 ~ file: index.js ~ deleteShipLine ~ error", error)
	// 	}

	// }, [])

	// const copy = useCallback(async (NoLine) => {

	// 	try {
	// 		let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/copy_ship_line/${NoLine}`;
	// 		let response = await Axios.put(url)
	// 		if (response.data.status === 200) {
	// 			getPOId()
	// 			Swal.fire({
	// 				// position: 'top-end',
	// 				type: 'success',
	// 				title: 'Shipment line copy Successfully',
	// 				showConfirmButton: false,
	// 				timer: 1500
	// 			})
	// 		}
	// 	} catch (error) {
	// 		console.log("🚀 ~ file: index.js ~ copy ~ error", error)
	// 	}
	// }, [id])

	const updateMS = useCallback(async () => {
		let newMS = modelNewBuild.Master_Shipment.MasterShipment.value

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/updateMS/${idms}`;
			let response = await Axios.put(url, modelNewBuild.Master_Shipment)
			if (response.data.status === 200) {
				Swal.fire({
					// position: 'top-end',
					type: 'success',
					title: 'Master Shipment Updated Successfully',
					showConfirmButton: false,
					timer: 1500
				})
				// getMasterShipment(idline, idpo)
				props.updateDataShip[idms] = newMS
				setIdms(newMS)
				getMS(customerSelect, newMS)
			}
		} catch (error) {
			console.log("🚀 ~ file: detailMS.js ~ updateMS ~ error", error.message)
			let requeridos = ['MasterShipment']
			let flag = ValidatorSchema(modelNewBuild.Master_Shipment, requeridos)

			if (!flag) {
				ValidatorSchema2(modelNewBuild.Master_Shipment, requeridos)
				setModelNewBuild({ modelNewBuild, ...modelNewBuild });
			}
			EditBuild(!editMS, 'MS')
			setEditMS(!editMS)
		}
	}, [idms, idline, idpo, customerSelect])

	const updatePOMaster = useCallback(async () => {
		let Model = { ...modelNewBuild };
		try {
			if (dataPOs.length === 1 && !Model.TransportationSM.Combined.check) {
				EditBuild(false, 'Ship')
				setEditShip(false)

				Swal.fire({
					// position: 'top-end',
					type: 'warning',
					title: 'Are you sure do you want to proceed?',
					showConfirmButton: true,
					showCancelButton: true
					// timer: 1500
				}).then(async (result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.value) {
						let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/updatePOMaster/${poSelected}/${slSelected}`;
						let response = await Axios.put(url, Model)
						if (response.data.status === 200) {
							Swal.fire({
								// position: 'top-end',
								type: 'success',
								title: 'Updated successfully',
								showConfirmButton: false,
								timer: 1500
							})
							// getMasterShipment(slSelected, poSelected)
							getMS()
						}
					}
				})
			} else {
				let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/updatePOMaster/${poSelected}/${slSelected}`;
				let response = await Axios.put(url, Model)

				if (response.data.status === 200) {
					EditBuild(false, 'Ship')
					setEditShip(false)
					Swal.fire({
						// position: 'top-end',
						type: 'success',
						title: 'Updated successfully',
						showConfirmButton: false,
						timer: 1500
					})


					if (Model.TransportationSM.Combined.check) {
						// getMasterShipment(slSelected, poSelected)
						getMS()
					} else {
						setFlatCheck(true)
						// Model.TransportationSM = ({ ...Model_New_Build().TransportationSM })
						// Model.PO = ({ ...Model_New_Build().PO })
						// Model.Production = ({ ...Model_New_Build().Production })
						// Model.Warehousing = ({ ...Model_New_Build().Warehousing })

						Model.PO['Supplier'].value = undefined
						Model.PO['Buyer'].value = undefined
						Model.PO['Department'].value = undefined
						Model.PO['ProjectCutOffId'].value = undefined
						Model.PO['ProjectCutOffId'].id = undefined
						Model.PO['CutOffDate'].value = undefined
						Model.PO['CodIncoterm'].value = undefined
						Model.PO['CodIncoterm'].id = undefined
						Model.PO['ProductDescription'].value = undefined
						Model.PO['Mesures'].value = undefined
						Model.PO['Weight'].value = undefined
						Model.PO['From'].value = undefined
						Model.PO['To'].value = undefined
						Model.PO['TypeDelivery'].value = undefined
						Model.PO['Value'].value = undefined
						Model.PO['ChargeableWeight'].value = undefined
						Model.PO['DeliveryConditions'].value = undefined
						Model.PO['DateDelivery'].value = undefined
						Model.PO['Note'].value = undefined

						Model.ProductionSM['PromiseCargoReady'].value = undefined
						Model.ProductionSM['SupplierContacted'].value = undefined
						Model.ProductionSM['SupplierContacted'].check = undefined
						Model.ProductionSM['ConfirmedCargoReady'].value = undefined
						Model.ProductionSM['ActualCargoReadyDate'].value = undefined
						Model.ProductionSM['EstPickupReceiveDate'].value = undefined
						// modelNewBuildsmProductionSM.Production['ConfirmedReadinessDate'].value = dataShipSelected.ConfirmedReadinessDate
						Model.ProductionSM['ActualPickupReceiveDate'].value = undefined
						Model.ProductionSM['CustomerReference1'].value = undefined
						Model.ProductionSM['CustomerReference2'].value = undefined

						Model.Warehousing['WMSReference'].value = undefined
						Model.Warehousing['EstArrivalConsolWH'].value = undefined
						Model.Warehousing['ActualArrivalConsolWH'].value = undefined

						Model.TransportationSM['ETD'].value = undefined
						Model.TransportationSM['POL'].value = undefined
						Model.TransportationSM['ActualDeliveryDate'].value = undefined
						Model.TransportationSM['IntergloboFile'].value = undefined
						Model.TransportationSM['RealWeigth'].value = undefined
						Model.TransportationSM['ChargeableWeigth'].value = undefined
						Model.TransportationSM['NewPlannedDeliveryDate'].value = undefined
						Model.TransportationSM['Combined'].value = undefined
						Model.TransportationSM['Combined'].check = undefined

						setModelNewBuild({ Model, ...Model });

						setMS({ name: '', status: '---' })
						setGroupsEvents([])
						setComments([])
						setDocuments([])
						// getMS()
						getPOassociated()
					}
				}
			}
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ updatePOMaster ~ error", error)
		}
	}, [poSelected, slSelected, dataPOs])

	const getMS = useCallback(async (customer_ID, MS) => {
		let MSId = MS ? MS : modelNewBuild.Master_Shipment.MasterShipment.value
		let _CustomSelected = customer_ID ? customer_ID : customerSelect

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/MSid/${MSId}/${_CustomSelected}`;
			let data = await Axios.get(url)

			let dataMS = data.data.data.dataMS[0]
			let dataPOs = data.data.data.dataMS

			if (dataMS) {
				modelNewBuild.Master_Shipment['MasterShipment'].value = dataMS.MasterShipment
				modelNewBuild.Master_Shipment['ETA'].value = dataMS.ETA
				modelNewBuild.Master_Shipment['ActualDeliveryDate'].value = dataMS.ActualDeliveryDate
				modelNewBuild.Master_Shipment['BookingNo'].value = dataMS.BookingNo
				modelNewBuild.Master_Shipment['Operator'].id = dataMS.OperatorId
				modelNewBuild.Master_Shipment['Operator'].value = dataMS.OperatorNB
				modelNewBuild.Master_Shipment['Vessel'].value = dataMS.Vessel
				modelNewBuild.Master_Shipment['POD'].value = dataMS.POD
				modelNewBuild.Master_Shipment['ContainerNo'].value = dataMS.ContainerNo
				modelNewBuild.Master_Shipment['BL_AWB'].value = dataMS.BL_AWB
				modelNewBuild.Master_Shipment['ShipmentCost'].value = dataMS.ShipmentCost
				modelNewBuild.Master_Shipment['Weight'].value = dataMS.RealWeigth
				// modelNewBuild.Master_Shipment['Volume'].value = dataMS.ChargeableWeigth

				// modelNewBuild.Master_Shipment['Weight'].value = new Intl.NumberFormat('en-US').format(dataMS.Weight)

				headerInfo.MS = dataMS.MasterShipment
				headerInfo.Status = dataMS.Status
				headerInfo.CreationDate = dataMS.CreationDate
				headerInfo.LastUpdate = dataMS.LastUpdate
				headerInfo.UserUpdate = dataMS.Operator
			} else {
				props.history.push('/New-Build')
			}

			Catalogs.TransportModeId = data.data.data.Cat_TransportMode
			Catalogs.ComTypeId = data.data.data.Cat_ComType
			Catalogs.CodIncoterm = data.data.data.Cat_Incoterms
			Catalogs.ProjectCutOffId = data.data.data.Cat_Projects
			Catalogs.CategoryId = data.data.data.Cat_DocType
			Catalogs.Operator = data.data.data.Cat_Operators
			Catalogs.TypeDelivery = data.data.data.Cat_TypeDelivery


			setCatalogs({ Catalogs, ...Catalogs })

			dataPOs.map((e) => {
				e.Selected = undefined
				modelNewBuild.Master_Shipment['POS'].ids.push(e.PONewBuildId)
			})
			setDataPOs(dataPOs)
			// setKey(Math.random())

			if (idpo && slSelected) {
				getMasterShipment(slSelected, poSelected)
			} else {
				getMasterShipment(idline, idpo)
			}

			setModelNewBuild({ modelNewBuild, ...modelNewBuild });
			setTimeout(() => {
				setFullLoader(true)
			}, 2000);

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ getMS ~ error", error)
		}
	}, [idpo, idline, customerSelect, slSelected, poSelected])

	const getMasterShipment = useCallback(async (NoLine, POid) => {
		setPOSelected(POid)
		setSLSelected(NoLine)
		setFlatCheck(false)

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/Shimpent_line_id/${NoLine}`;
			let urlPO = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/ms_poid/${POid}`;
			let responsePO = await Axios.get(urlPO)
			let responseShipLine = await Axios.get(url)
			let dataPoSelected = responsePO.data.data.dataPO[0]
			let dataShipLine = responseShipLine.data.data.dataShip[0]
			let dataEvt = { 'Groups': responseShipLine.data.data.groups, 'Steps': responseShipLine.data.data.dataEvents }


			modelNewBuild.PO['Supplier'].value = dataPoSelected.Supplier
			modelNewBuild.PO['Buyer'].value = dataPoSelected.Buyer
			modelNewBuild.PO['Department'].value = dataPoSelected.Department
			modelNewBuild.PO['ProjectCutOffId'].value = dataPoSelected.NewBuild
			modelNewBuild.PO['ProjectCutOffId'].id = dataPoSelected.ProjectCutOffId
			modelNewBuild.PO['CutOffDate'].value = dataPoSelected.CutOffDate
			modelNewBuild.PO['CodIncoterm'].value = dataPoSelected.CodIncoterm
			modelNewBuild.PO['CodIncoterm'].id = dataPoSelected.CodIncoterm
			modelNewBuild.PO['ProductDescription'].value = dataPoSelected.ProductDescription
			modelNewBuild.PO['Mesures'].value = dataPoSelected.Mesures
			// modelNewBuild.PO['Weight'].value = new Intl.NumberFormat('en-US').format(dataPoSelected.Weight)
			modelNewBuild.PO['Weight'].value = dataPoSelected.Weight
			modelNewBuild.PO['From'].value = dataPoSelected.From
			modelNewBuild.PO['To'].value = dataPoSelected.To
			modelNewBuild.PO['TypeDelivery'].value = dataPoSelected.TypeDelivery
			modelNewBuild.PO['Value'].value = new Intl.NumberFormat('en-US').format(dataPoSelected.Value)
			modelNewBuild.PO['ChargeableWeight'].value = new Intl.NumberFormat('en-US').format(dataPoSelected.ChargeableWeight)
			modelNewBuild.PO['DeliveryConditions'].value = dataPoSelected.DeliveryConditions
			modelNewBuild.PO['DateDelivery'].value = dataPoSelected.DateDelivery
			modelNewBuild.PO['Note'].value = dataPoSelected.Note

			modelNewBuild.ProductionSM['PromiseCargoReady'].value = dataShipLine.PromiseCargoReady
			modelNewBuild.ProductionSM['SupplierContacted'].value = dataShipLine.SupplierContacted ? (dataShipLine.SupplierContacted === '1' ? 'Yes' : 'No') : ''
			modelNewBuild.ProductionSM['SupplierContacted'].check = dataShipLine.SupplierContacted ? (dataShipLine.SupplierContacted === '1' ? true : false) : ''
			modelNewBuild.ProductionSM['ConfirmedCargoReady'].value = dataShipLine.ConfirmedCargoReady
			modelNewBuild.ProductionSM['ActualCargoReadyDate'].value = dataShipLine.ActualCargoReadyDate
			modelNewBuild.ProductionSM['EstPickupReceiveDate'].value = dataShipLine.EstPickupReceiveDate
			// modelNewBuild.Production['ConfirmedReadinessDate'].value = dataShipSelected.ConfirmedReadinessDate
			modelNewBuild.ProductionSM['ActualPickupReceiveDate'].value = dataShipLine.ActualPickupReceiveDate
			modelNewBuild.ProductionSM['CustomerReference1'].value = dataShipLine.CustomerReference1
			modelNewBuild.ProductionSM['CustomerReference2'].value = dataShipLine.CustomerReference2

			modelNewBuild.Warehousing['WMSReference'].value = dataShipLine.WMSReference
			modelNewBuild.Warehousing['EstArrivalConsolWH'].value = dataShipLine.EstArrivalConsolWH
			modelNewBuild.Warehousing['ActualArrivalConsolWH'].value = dataShipLine.ActualArrivalConsolWH

			modelNewBuild.TransportationSM['ETD'].value = dataShipLine.ETD
			modelNewBuild.TransportationSM['POL'].value = dataShipLine.POL
			modelNewBuild.TransportationSM['ActualDeliveryDate'].value = dataShipLine.ActualDeliveryDate
			modelNewBuild.TransportationSM['IntergloboFile'].value = dataShipLine.IntergloboFile
			modelNewBuild.TransportationSM['RealWeigth'].value = new Intl.NumberFormat('en-US').format(dataShipLine.RealWeigth)
			modelNewBuild.TransportationSM['ChargeableWeigth'].value = new Intl.NumberFormat('en-US').format(dataShipLine.ChargeableWeigth)
			modelNewBuild.TransportationSM['NewPlannedDeliveryDate'].value = dataShipLine.NewPlannedDeliveryDate
			modelNewBuild.TransportationSM['Combined'].value = dataShipLine.Combined ? (dataShipLine.Combined === '1' ? 'Yes' : 'No') : ''
			modelNewBuild.TransportationSM['Combined'].check = dataShipLine.Combined ? (dataShipLine.Combined === '1' ? true : false) : ''

			// modelNewBuild.Transportation['TransportModeId'].value = dataShipLine.TransportMode
			// modelNewBuild.Transportation['TransportModeId'].id = dataShipLine.TransportModeId
			// modelNewBuild.Transportation['BookingNo'].value = dataShipLine.BookingNo
			// modelNewBuild.Transportation['Vessel'].value = dataShipLine.Vessel
			// modelNewBuild.Transportation['VoyageFlightNo'].value = dataShipLine.VoyageFlightNo
			// modelNewBuild.Transportation['Carrier'].value = dataShipLine.Carrier
			// modelNewBuild.Transportation['POD'].value = dataShipLine.POD
			// modelNewBuild.Transportation['BL_AWB'].value = dataShipLine.BL_AWB
			// modelNewBuild.Transportation['ContainerNo'].value = dataShipLine.ContainerNo
			// modelNewBuild.Transportation['ETA'].value = dataShipLine.ETA
			// modelNewBuild.Transportation['EstDeliveryDate'].value = dataShipLine.EstDeliveryDate

			// setSLSelected(!modelNewBuild.TransportationSM.Combined.check ? undefined : NoLine)


			setModelNewBuild({ modelNewBuild, ...modelNewBuild });

			setMS({ name: <span className="shipment-line">Po No. <Label>{dataShipLine.PONo}</Label> Line {dataShipLine.NoLine}</span>, status: dataShipLine.Status })
			buildEvents(dataEvt)
			getComments(NoLine)
			// getDocs(NoLine)
			setKey(Math.random())

		} catch (error) {
			console.log("🚀 ~ file: index.js ~  shipLineId ~ error", error)
		}
	}, [idms])

	function buildEvents(params) {
		Object.entries(events).map(([key, data]) => {
			data.completed = false
			data.value = undefined
		})

		setGroupsEvents(params.Groups)
		params.Steps.forEach(element => {

			if (events[element.EventDescriptionId]) {
				events[element.EventDescriptionId].value = element.EventDate
				events[element.EventDescriptionId].completed = true
			}
		});
	}


	function handleChange(val, data, item, TabName) {
		if (TabName === 'PO') {
			let ModelSend = { ...modelNewBuild.PO };

			let id = item.id
			let dataLabel = data ? data : '';

			if (item.type === 'text') {
				let value = val;
				let res = ValidatorService(ModelSend[id].type, value);
				if (res) {
					ModelSend[id].value = value
				}
			}
			if (item.type === 'date') {
				let value = dataLabel;
				ModelSend[id].value = value
			}
			if (item.type === 'text-area') {
				let value = data.value;
				let res = ValidatorService(ModelSend[id].type, value);
				if (res) {
					ModelSend[id].value = value
				}
			}
			if (item.type === 'combo') {
				let value = data.value;
				let res = ValidatorService(ModelSend[id].type, value);

				if (!res) {
					ModelSend[id].valid = res
				} else {
					ModelSend[id].valid = res
				}
				// let flag = ValidatorSchema(ModelSend, ['ProjectCutOffId'])
				ModelSend[id].id = value
			}
			if (item.type === 'autocomplete') {
				let value = val;
				ModelSend[id].value = value
			}
		}
		if (TabName === 'Production') {
			let ModelSend = { ...modelNewBuild.ProductionSM };
			let id = item.id
			let dataLabel = data ? data : '';

			if (item.type === 'text') {
				let value = val;
				ModelSend[id].value = value
			}
			if (item.type === 'date') {
				let value = dataLabel;
				ModelSend[id].value = value
			}
			if (item.type === 'check') {
				let value = data.checked;
				ModelSend[id].check = value
			}
		}

		if (TabName === 'Warehousing') {
			let ModelSend = { ...modelNewBuild.Warehousing };

			let id = item.id
			let dataLabel = data ? data : '';

			if (item.type === 'text') {
				let value = val;
				ModelSend[id].value = value
			}
			if (item.type === 'date') {
				let value = dataLabel;
				ModelSend[id].value = value
			}
		}

		if (TabName === 'Transportation') {
			let ModelSend = { ...modelNewBuild.TransportationSM };

			let id = item.id
			let dataLabel = data ? data : '';
			if (item.type === 'text') {
				let value = val;
				let res = ValidatorService(ModelSend[id].type, value);
				if (res) {
					ModelSend[id].value = value
				}
				// ModelSend[id].value = value
			}
			if (item.type === 'date') {
				let value = dataLabel;
				ModelSend[id].value = value
			}
			if (item.type === 'combo') {
				let value = data.value;
				ModelSend[id].id = value
			}
			if (item.type === 'check') {
				let value = data.checked;
				ModelSend[id].check = value
				// ModelSend[id].value = value ? 'Yes' : 'No'
			}
		}

		if (TabName === 'Comments') {
			let ModelSend = { ...modelNewBuild.Comments };

			let id = item.id
			if (item.type === 'combo') {
				let value = data.value;
				ModelSend[id].id = value
				ModelSend[id].value = value

			}
			if (item.type === 'text-area') {
				let value = data.value;
				ModelSend[id].value = value
			}
			let flag = ValidatorSchema(ModelSend, ['ComTypeId', 'Comment'])
			if (flag) {
				ModelSend['hiddenButtonSave'].hidden = false
			} else {
				ModelSend['hiddenButtonSave'].hidden = true
			}
		}

		if (TabName === 'Documments') {
			let ModelSend = { ...modelNewBuild.Documments };
			let id = item.id
			if (item.type === 'combo') {
				let value = data.value;
				ModelSend[id].id = value
			}
			if (item.type === 'text') {
				let value = val;
				ModelSend[id].value = value
			}
		}

		if (TabName === 'SummarySM') {
			let ModelSend = { ...modelNewBuild.Master_Shipment };

			let id = item.id
			let dataLabel = data ? data : '';

			if (item.type === 'text') {
				let value = val;
				let res = ValidatorService(ModelSend[id].type, value);
				if (res) {
					ModelSend[id].value = value
				}
				ValidatorSchema2(ModelSend, [id])

			}
			if (item.type === 'date') {
				let value = dataLabel;
				ModelSend[id].value = value
			} if (item.type === 'combo') {
				let value = data.value;
				let res = ValidatorService(ModelSend[id].type, value);

				if (!res) {
					ModelSend[id].valid = res
				} else {
					ModelSend[id].valid = res
				}
				// let flag = ValidatorSchema(ModelSend, ['ProjectCutOffId'])
				ModelSend[id].id = value
			}
		}
		setModelNewBuild({ modelNewBuild, ...modelNewBuild })
	}


	// async function addShipLine(params) {
	// 	let idPO = id
	// 	let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/new_ship_line/${idPO}`;
	// 	let data = await Axios.post(url)
	// 	if (data.status === 200) {

	// 		Swal.fire({
	// 			// position: 'top-end',
	// 			type: 'success',
	// 			title: 'New shipment added',
	// 			showConfirmButton: false,
	// 			timer: 1500
	// 		})

	// 		getPOId()
	// 	}
	// }

	const addComment = useCallback(async () => {
		let ModelComments = { ...modelNewBuild.Comments };
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/addCommentShipLine/${slSelected}?usr=${userId}`;
			let data = await Axios.post(url, ModelComments)
			if (data.status === 200) {
				setModalCommet(false)

				Swal.fire({
					// position: 'top-end',
					type: 'success',
					title: 'New Comment added',
					showConfirmButton: false,
					timer: 1500
				})
				getComments(slSelected)
			}
			ModelComments['Comment'].value = undefined
			ModelComments['ComTypeId'].id = undefined
			ModelComments['ComTypeId'].value = undefined
			// ModelSend['hiddenButtonSave'].hidden = true
			// setKey(Math.random())
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ addComment ~ error", error)
		}
	}, [slSelected, userId])

	const getComments = useCallback(async (idline) => {
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/getComments/${idline}`;
			let data = await Axios.get(url)
			setComments(data.data.data.dataComments)

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ getComments ~ error", error)
		}
	}, [])

	function toggleModalComments() {
		let ModelComments = { ...modelNewBuild.Comments };

		setModalCommet(!modalComment);
		ModelComments['Comment'].value = undefined
		ModelComments['ComTypeId'].id = undefined
		ModelComments['ComTypeId'].value = undefined
		ModelComments['hiddenButtonSave'].hidden = true
	}

	const callFile = (file) => {
		let ModelSend = { ...modelNewBuild.Documments };
		ModelSend['File'].data = file[0].file
		setModelNewBuild({ modelNewBuild, ...modelNewBuild })
	}

	const addNewDocumment = useCallback(async () => {
		let modelDocumments = { ...modelNewBuild.Documments };
		// evt.preventDefault();
		// let { Document, id } = this.state
		const formData = new FormData();
		if (modelDocumments.File.data && modelDocumments.Name.value && modelDocumments.CategoryId.id) {
			formData.append('file', modelDocumments.File.data)
			formData.append('Name', modelDocumments.Name.value)
			formData.append('DocCategoryId', parseInt(modelDocumments.CategoryId.id))
			formData.append('Type', path.extname(modelDocumments.File.data.name))
			formData.append('RowId', slSelected)
			formData.append('ModuleId', 9)
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}documents/upload_doc/`;

			// this.setState({ disable: true })
			fetch(`${url}`, {
				method: 'POST',
				body: formData
			})
				.then(response => response.json())
				.then(success => {

					Swal.fire({
						// position: 'top-end',
						type: 'success',
						title: 'New document added',
						showConfirmButton: false,
						timer: 1500
					})
					getDocs(slSelected)
					// setModelNewBuild({ ...Model_New_Build().Documments })
				})
				.catch(error => {
					console.log("🚀 ~ file: index.js ~ line 600 ~ addNewDocumment ~ error", error)
					// this.props.onError(error)
				})
		}
	}, [slSelected])


	const getDocs = useCallback(async (idline) => {
		let module = 9

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}documents/get_docs/${idline}?module=${module}`;
			let data = await Axios.get(url)
			setDocuments(data.data.data.dataDocs)
			setKey(Math.random())

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ getDocs ~ error", error.message)
		}
	}, [])


	const rmDocument = useCallback(async (params) => {
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}documents/delete_doc/${params.DocumentId}`;
			let data = await Axios.delete(url)
			if (data.data.status === 200) {
				Swal.fire({
					// position: 'top-end',
					type: 'success',
					title: 'deleted document',
					showConfirmButton: false,
					timer: 1500
				})
				getDocs(slSelected)
			}
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ rmDocument ~ error", error)
		}
	}, [slSelected])

	const getPOassociated = useCallback(async () => {
		let MSId = idms
		let _CustomSelected = customerSelect

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/MSid/${MSId}/${_CustomSelected}`;
			let data = await Axios.get(url)
			let dataPOs = data.data.data.dataMS

			dataPOs.map((e) => {
				e.Selected = undefined
			})
			setDataPOs(dataPOs)

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ getMS ~ error", error)
		}
	}, [idms, customerSelect])



	// const createData = amount => {
	// 	let data = [];
	// 	for (let i = 0; i < amount; i++) {
	// 		data.push({
	// 			firstName: 'name',
	// 			middleName: 'apellido',
	// 			lastName: 'hola'
	// 		});
	// 	}
	// 	return data;
	// };

	useEffect(() => {
		// setEdit(false)
		setCustomerSelect(props._customerSelect)
		setUserId(props._user)
		setRolId(props._rolId)
		// EditBuild(false, 'PO')
		// EditBuild(false, 'Ship')
		setKey(Math.random())
		getMS(props._customerSelect, props.MS)
		modelNewBuild.Master_Shipment.User.id = props._user
		modelNewBuild.Master_Shipment.User.rol = props._rolId

	}, [])

	return (
		<>
			<Loader hidden={fullLoader} />
			<ContentWrapper>
				<div className="content-heading d-flex justify-content-between ">
					<div>
						<i className='fas fa-map-marker-altfas fa-ship fa-1x mr-2'></i>Visibility Details
					</div>
					<div onClick={props.back} className='backButton d-flex align-items-center'>
						<i className='fas fa-chevron-left' /><span>&nbsp;Back</span>
					</div>
				</div>
				{/* <Col className='d-flex align-items-center'
					onClick={props.back}
					style={{ cursor: 'pointer', padding: 0 }} >
					<i className='fas fa-arrow-circle-left fa-3x mr-2' style={{ color: '#00558c' }} />
					<spam style={{ color: '#00558c' }}><h4><b>Back</b></h4></spam>
				</Col> */}
				<Row>
					<Col xs={12} md={12}>
						<CardBody style={{ textAlign: 'right' }}>
							<div hidden={rolId !== '9' ? false : true}>
								<Button className='btn-width' color='success' size='sm' hidden={editMS || editShip} onClick={() => { EditBuild(!editMS, 'MS'), setEditMS(!editMS) }}>
									Edit
								</Button>
							</div>
							<Button className='btn-width' color='danger' size='sm' hidden={!editMS} onClick={() => { EditBuild(!editMS, 'MS'), setEditMS(!editMS), getMS(customerSelect, idms) }}>
								Cancel
							</Button>
							<Button className='btn-width mr-2 ml-2' color='primary' size='sm' hidden={!editMS} onClick={() => { updateMS(), EditBuild(!editMS, 'MS'), setEditMS(!editMS) }}>
								Save
							</Button>
						</CardBody>
						<Card className="myCard">
							{/* <Card className="myCard card-border-new-build"> */}
							<CardBody >
								<Row>
									<Col xs={6} md={3} lg={3} className='d-flex align-items-center'>

										{
											!editMS ?
												<div>
													<span className="headerCard">Master Shipment # {headerInfo.MS} </span>
												</div>

												:
												<div>
													<Input
														// icon='tags'
														// iconPosition='left'
														label={{ tag: true, content: 'Master Shipment #' }}
														labelPosition='left'
														// size='small'
														onChange={(evt, data) => { handleChange(evt.target.value, data, { id: 'MasterShipment', type: 'text' }, 'SummarySM'); }}
														value={modelNewBuild.Master_Shipment['MasterShipment'].value ? modelNewBuild.Master_Shipment['MasterShipment'].value : ''}
														error={modelNewBuild.Master_Shipment['MasterShipment'].valid ? false : true}
													/>
												</div>			
										}
									</Col>

									<Col xs={6} md={2} lg={2} className='d-flex align-items-center'>
										<Button className={'btn-oval'} color={'primary'} >{headerInfo.Status}</Button>
									</Col>
									<Col xs={6} md={7} lg={7} style={{ textAlign: 'right' }} >
										{/* <Row> */}
										<span className="datesPO">Creation Date: <label>{headerInfo.CreationDate}</label></span>
										<span className="datesPO">Last Update: <label>{headerInfo.LastUpdate}</label></span>
										<span className="datesPO">Update User: <label>{headerInfo.UserUpdate}</label></span>
										{/* </Row> */}
									</Col>
								</Row>
							</CardBody>
						</Card>
						<Card className="myCard">
							{/* <Card className="myCard card-border-new-build"> */}
							<Accordion defaultExpanded='true'>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography className='title-card-po'>Summary</Typography>
									{/* <h4 >Order summary</h4> */}
								</AccordionSummary>
								<CardBody>
									<Row>
										<FormCreator dataForm={form} Model={modelNewBuild.Master_Shipment} onChange={handleChange} TabName={'SummarySM'} Catalog={Catalogs} />
									</Row>
								</CardBody>
							</Accordion>

						</Card>
					</Col>
				</Row>
				<Card className={"myCard"}>
					{/* <CardBody> */}
					<Table
						data={dataPOs}
						columns={columnsMS}
						options={
							{
								hoverClass: "hoverClassStyle",
								pagination: true,
								itemsPerPage: 5,
								addLine: { visible: false },
								Accordion: { Active: true, Title: 'Purchase Orders' },
								addShip: { visble: false }
							}
						}
					/>
					{/* </CardBody> */}
				</Card>
				{/* 			{
					slSelected !== undefined ? */}
				<Row>
					<Col xs={12} md={12}>
						<Card className="myCard">

							{/* <Card className="myCard card-border-new-build"> */}
							<CardBody>
								<Row>
									<Col xs={12} md={12} >
										<div hidden={rolId !== '9' ? false : true}>
											<CardBody style={{ textAlign: 'right' }} hidden={flatCheck}>
												<Button className='btn-width' color='success' size='sm'
													hidden={editShip || editMS}
													onClick={() => {
														EditBuild(!editShip, 'Ship')
														setEditShip(!editShip)
													}}>
													Edit
												</Button>
											</CardBody>
										</div>
									</Col>
								</Row>
								<Tab
									defaultActiveIndex={0}
									menu={{ secondary: true }}
									panes={[
										{
											menuItem: 'Purcharse Order',
											render: () => <Suspense fallback={<div>Loading...</div>}><PurcharseOrder key={key} FormCreator={FormCreator} dataForm={FormComponent.PO} Model={modelNewBuild.PO} onChange={handleChange} TabName={'PO'} shipmentLine={MS} Catalogs={Catalogs} /></Suspense>
										},
										{
											menuItem: 'Production',
											render: () => <Suspense fallback={<div>Loading...</div>}><Production key={key} FormCreator={FormCreator} dataForm={FormComponent.ProductionSM} Model={modelNewBuild.ProductionSM} onChange={handleChange} TabName={'Production'} shipmentLine={MS} steps={events} /></Suspense>
										},
										{
											menuItem: 'Warehousing',
											render: () => <Suspense fallback={<div>Loading...</div>}><Warehousing key={key} FormCreator={FormCreator} dataForm={FormComponent.Warehousing} Model={modelNewBuild.Warehousing} onChange={handleChange} TabName={'Warehousing'} shipmentLine={MS} /></Suspense>
										},
										{
											menuItem: 'Transportation',
											render: () => <Suspense fallback={<div>Loading...</div>}><Transportation key={key} FormCreator={FormCreator} dataForm={FormComponent.TransportationSM} Model={modelNewBuild.TransportationSM} onChange={handleChange} TabName={'Transportation'} shipmentLine={MS} Catalogs={Catalogs} /></Suspense>
										},
										{
											menuItem: 'Events',
											render: () => <Suspense fallback={<div>Loading...</div>}><Events key={key} FormCreator={FormCreator} dataForm={FormComponent.Production} Model={Model_New_Build().Production} Groups={groupsEvents} shipmentLine={MS} /></Suspense>
										},
										// {
										// 	menuItem: 'Cost',
										// 	render: () => <Suspense fallback={<div>Loading...</div>}><Cost FormCreator={FormCreator} dataForm={FormComponent.Production} Model={Model_New_Build().Production} /></Suspense>
										// },
										{
											menuItem: 'Comments',
											render: () => <Suspense fallback={<div>Loading...</div>}><Comments key={key} FormCreator={FormCreator} dataForm={FormComponent.Comments} Model={modelNewBuild.Comments} onChange={handleChange} TabName={'Comments'} Catalogs={Catalogs} addComment={addComment} comments={comments} toggleModalComments={toggleModalComments} modalComment={modalComment} shipmentLine={MS} /></Suspense>
										},
										{
											menuItem: 'Documents',
											render: () => <Suspense fallback={<div>Loading...</div>}><Documents key={key} FormCreator={FormCreator} dataForm={FormComponent.Documments} Model={modelNewBuild.Documments} onChange={handleChange} TabName={'Documments'} Catalogs={Catalogs} callFile={callFile} addNewDocumment={addNewDocumment} documents={documents} rmDocument={rmDocument} shipmentLine={MS} /></Suspense>
										},
									]}
								/>
								<div style={{ textAlign: 'right' }} hidden={!editShip}>
									<CardBody>
										<Row>
											<Col xs={6} md={10} lg={10}></Col>
											<Col xs={6} md={1} lg={1}>
												<Button color='danger' size='sm' block onClick={() => { EditBuild(false, 'Ship'), setEditShip(!editShip) }}>
													Cancel
												</Button>
											</Col>
											<Col xs={6} md={1} lg={1}>
												<Button color='success' size='sm' block onClick={() => { updatePOMaster() }}>
													Save
												</Button>
											</Col>
										</Row>
									</CardBody>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{/* 			: ''
				} */}
				<div className="margin-footer-6" />
			</ContentWrapper>
		</>
	);
}

export default withRouter(BuildDetail);

