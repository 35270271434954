import React from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { Container, Row, Col, CardBody, CardText, } from 'reactstrap';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


export const MyResponsiveBarCanvas2 = ({ position, data, keys, colors  /* see data tab */ }) =>{
  var colorsGraphs = colors;
  if(!colors){
    let newColors = [];
    data.map((e) => {
      newColors.push(e.color);
      return e;
    });
    colorsGraphs = newColors;
  }
  return((
    <ResponsiveBarCanvas
      data={data}
      keys={keys}
      indexBy="id"
      margin={{ top: 3, right: 10, bottom: 80, left: 80 }}
      pixelRatio={1}
      padding={0.3}
      innerPadding={0}
      minValue="auto"
      maxValue="auto"
      groupMode="stacked"
      layout={'vertical'}
      reverse={false}
      colors={colorsGraphs}
      // box-shadow={{0 8px 16px 0 rgba(0,0,0,0.2)}}
      colorBy="id"
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: -76,
        // legend: 'country',
        legendPosition: 'middle',
        legendOffset: 36
      }}
      axisLeft={{
        format: '$,',
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'food',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      tooltip={ (val)=> {   
        return (<>
          <Row>
            <Col md={12}>
              <h5> <span style={{height:'15px',width:'15px',background:val.color,borderRadius:'50%',display:'inline-block'}}></span>  {val.id + ' - ' + val.data.id}  :  {(Number(val.value).toLocaleString('En-us'))} </h5>
            </Col>
          </Row>
        </>);
      }}
      enableGridX={false}
      enableGridY={false}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      isInteractive={true}
    />
  ));


};
