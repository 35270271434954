import moment from 'moment';

export const Graph = (data) => {
  let graphs = [
    {
      title: 'Top 10 by Client',
      type: 'bar',
      data: data.topClient,
      color: ['#B7D968', '#FDE47F'],
      size: 4,
      theme: true
    },
    {
      title: 'User Access by Module',
      type: 'bar',
      data: data.modules,
      color: ['#79B926', '#b87333'],
      size: 4,
      theme: true
    },
    {
      title: 'Monthly Traffic',
      type: 'pie',
      data: data.traficByMonth,
      options: {
        legend: {
          show: false
        },
        height: '108',
        series: {
          lines: {
            show: false
          },
          points: {
            show: true,
            radius: 4
          },
          splines: {
            show: true,
            tension: 0.4,
            lineWidth: 1,
            fill: 0.5
          }
        },
        grid: {
          borderColor: '#eee',
          borderWidth: 1,
          hoverable: true,
          backgroundColor: '#fcfcfc',
          color: '#dee1ec'
        },
        tooltip: true,
        tooltipOpts: {
          content: (label, x, y) => x + ' : ' + y
        },
        xaxis: {
          // show: true,
          color: '#fafafa',
          tickColor: '#fcfcfc',
          mode: 'categories',
          // font: { size: 100, style: 'normal', width: 300 },
          gridLines: false
        },
        yaxis: {
          show: true,
          // min: 0,
          // max: 10,
          tickColor: '#eee',
          autoscaleMargin: 1,
          color: '#fafafa',
          tickDecimals: 0,
          tickFormatter: v => v
        },
        shadowSize: 0
      },
      size: 4,
      height: '200'
    },
    {
      title: 'Average Traffic by Hour',
      type: 'pie',
      data: data.traficByHour,
      options: {
        legend: {
          show: false
        },
        series: {
          lines: {
            show: false
          },
          points: {
            show: true,
            radius: 4
          },
          splines: {
            show: true,
            tension: 0.4,
            lineWidth: 1,
            fill: 0.5
          }
        },
        grid: {
          borderColor: '#eee',
          borderWidth: 1,
          hoverable: true,
          backgroundColor: '#fcfcfc',
          color: '#dee1ec'
        },
        tooltip: true,
        tooltipOpts: {
          content: (label, x, y) =>
            moment.utc(x).format('HH:mm') + ' hrs : ' + y
        },
        xaxis: {
          mode: 'time',
          timeBase: 'milliseconds',
          timeformat: '%H:%M %p',
          tickLength: 5,
          tickSize: [5, 'hour']
        },
        yaxis: {
          show: true,
          // min: 0,
          // max: 10, 
          tickColor: '#eee',
          autoscaleMargin: 1,
          color: '#fafafa',
          tickDecimals: 0,
          tickFormatter: v => v 
        },
        shadowSize: 1
      },
      size: 4,
      height: '298'
    }
  ];

  return graphs;
};
