const Main = {
  'ORDER-CREATE': {
    "headerDetails": {
      type: 'Object',
      fields: {
        "SupplierCode": {
          required: true
        },
        "SupplierContactId": {
          required: true
        },
        "ConsigneeCode": {
          required: true,
        },
        "ConsigneeContactId": {
          required: true
        },
        "DeliveryCode": {
          required: true
        },
        "DeliveryContactId": {
          required: true
        },
        "PONumber": {
          required: true
        },

        "POCreateDate": {
          required: true
        },
        "RequestedExWorksDate": {
          required: true
        },
        "RequiredDestination": {
          required: true
        },

        "OriginCountryId": {
          required: true
        },

        "ProductTypeId": {
          required: false
        },
        "DivisionId": {
          required: true
        },

        "CruiseId": {
          required: false
        },
        "TransportModeId": {
          required: true
        },
        "Incoterm": {
          required: false
        },
        "OrderValue": {
          required: false,
          validation: (value) => {
            if (value >= 1) return true
            else return false
          }
        },
        "OrderCurrencyId": {
          required: false
        },
        "PODescription": {
          required: false
        },

        "AdditionalPOTerms": {
          required: false,
        },
      }
    },
    "Documents": {
      type: 'Array',
      minLength: 0,
      error: 'You must send a document',
    },
    "items": {
      type: 'Array',
      minLength: 1,
      error: 'You must send an item',
    },
  },
  "PO-LINE": {
    "ItemDescription": {
      required: true,

    },
    "ItemNumber": {
      required: true
    },
    "OriginCountryId": {
      required: true
    },
    "QtyOrdered": {
      required: true,
      validation: (value) => {
        if (!Number.isInteger(value) || (value + '').length >= 11 || value === 0 || value === '0') return false
        else return true
      }
    },
    "UnitMeasure": {
      required: true
    }
  },

  'BOOKING-CREATE': {

  },
  'CONSOL-CREATE': {
    'headerDetails': {
      type: 'Object',
      fields: {
        'ConsolNumber': {
          required: true
        },
        'ContainerTypeCode': {
          required: true
        },
        'RequestedTemp': {
          required: true,
          dependient:true,
          idDepende:'ContainerTypeCode',
          value1:'20R',
          value2:'40R'
        },
      }
    }
  }
};


export default Main;