import React, { Component, Suspense } from "react";
import axios from "axios";
import { Card, CardBody, CardText, Col, Row, Button } from "reactstrap";
import ContentWrapper from "./../../../../../template/Layout/ContentWrapper";
// import mapJson from '../../../Services/mapJson';
// import { Services } from '../../Storage/Services';
import Loader from "../../../../../Global/Loader";
// import Loader from './../../../Global/Loader';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "../../../../../Components/TablaSubcolum";
import Axios from "axios";
import { saveAs } from "file-saver";
import { Search } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { Services } from "../../../Services";
import { message } from "antd";
import { validateIdParams } from "../../../../../utils/regularExpressionValidation";
const CardInfo = React.lazy(() => import("../../../../../Components/CardsV2"));

const headers = [
  "Line No.",
  "Item",
  "Description",
  "Declarated QTY",
  "Count QTY",
  "Line UOM",
  "Alternative QTY",
  "Item Gross Weight",
  "UOM Weight",
  "Item Category",
];
const keys = [
  "LineNo",
  "Item",
  "Description",
  "DeclarratedQty",
  "CountQty",
  "LineUOM",
  "AlternativeQty",
  "GrosWeight",
  "WeightUOM",
  "ItemCategory",
];

// import ClientesList from '../../../../services/storageClient'
// const Table = React.lazy(() => import('../../Components/Table'));

const stylebutton = {
  width: "7em",
  color: "#0179b8db",
  background: "#fff",
  padding: "4px",
  fontWeight: "bold",
};

class DetailsReceipts extends Component {
  state = {
    loading: false,
    // clientesSelect: clientSave.getAuthentication('nit'),
    clientesSelect: this.props.nit ? JSON.stringify(this.props.nit) : "[0]",
    dataTable: [],
    fullLoader: true,
    id: this.props.match.params.id,
    loadingGraphs: true,
    dataReport: [],
    CardIndoHeaders: {
      title: "Transaction Header",
      subTitle: "",
      Icon: "",
      style: { color: "#25dfeae3" },
    },
    config: [
      {
        title: "Buyer",
        positionTitleLeft: true,
        id: "VendorName",
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Warehouse",
        id: "WarehouseCode",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        nocenter: true,
        title: "Container No",
        data: {
          link: true,
          style: {
            fontWeight: "bold",
            fontSize: "13px",
            color: "rgb(0, 151, 220)",
          },
        },
        link: "/container-detail/",
        id: "ContainerNumber",
        positionTitleLeft: true,
        idlink: "BookingContainerId",
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "PO No.",
        id: "POsNo",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "WPS File No",
        id: "WPSFileNo",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Ref 1",
        id: "Ref1",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Ref 2",
        id: "Ref2",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Total Gross Weight",
        id: "TotalGrossWeight",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Total Received Qty",
        id: "TotalReceivedQty",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Total Alternative Qty",
        id: "TotalAlternativeQty",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-2 col-sm-6",
      },
    ],
    pages: 0,
    Info: [{ icon: {} }],
    subTable: [
      { label: "Po No.", key: "PONo" },
      { label: "Sublot", key: "Sublot" },
      { label: "Tag ID", key: "TeagID" },
      { label: "Gram", key: "Gram" },
      { label: "Ref No.", key: "RefNo" },
      { label: "Size 1", key: "Size1" },
      { label: "Size 2", key: "Size2" },
      { label: "Buyer", key: "Buyer" },
    ],
    columnsTable: [
      {
        label: "",
        key: "",
        CollapseTable: true,
        flatColor: true,
        keydatavalidate: "Status",
      },
      { label: "Line No.", key: "LineNo" },
      { label: "Item", key: "Item" },
      { label: "Description", key: "Descriptions" },
      { label: "Declarated QTY", key: "DeclarratedQty" },
      { label: "Count QTY", key: "CountQty" },
      { label: "Line UOM", key: "LineUOM" },
      { label: "Alternative QTY", key: "AlternativeQty" },
      // { label: 'Total Amount', key: 'LineUOM',separator:true  },
      { label: "Item Gross Weight", key: "GrosWeight" },
      { label: "UOM Weight", key: "WeightUOM" },
      { label: "Item Category", key: "ItemCategory" },
      // { label: 'Equipment No.', key: 'ItemCategory'},
      // { label: 'Status', key: 'Status', shiwchColor: true },
      // { label: '', key: '', custom: (value) => this.custom(value) }
    ],
  };
  // let succes = {"message":"Storage Data Ofiice","status":200,"data":[{"VendorName":'SAREGO',icon: {},"Warehause":'NJWH',"ContainerNo":08975,"PONo":"12345-2, 012123-4","WPSFileNo":'test2',"TipoAlmacen":"Bodega Nacional","Ref1":4784,"Ref1":,"RefCliente":"1","DocTransporte":"W840","Documento":"W840","TipoDocumento":"RECE","Proceso":"RECE","TipoDocumentoEspecifico":"INGR","TipoVehiculo":"TURBO","CantidadesEsperadas":"5","CantidadesRecibidas":"5","CantidadesCalidad":"0","ObservacionesRecepcion":null,"EstatusId":155,"Estatus":"En Inventario","ClienteId":8098,"Cliente":"WESTCON GROUP COLOMBIA LTDA WESTCON GROUP COLOMBIA LTDA","FechaNotificacionIngreso":"24-05-2021","FechaLlegadaAlmacen":"24-05-2021","NIT":"830089336","FechaInicioDescargue":"2021-05-22T10:58:26.870Z","FechaFinDescargue":"24-05-2021","FechaManifiesto":null,"FechaVencimientoManifiesto":null,"FechaDisponibilidadInventario":"24-05-2021","FechaDevolucionContenedor":null,"GrupoId":null,"Grupo":null,"FechaActualizacion":"03-06-2021","Proyecto":null}]}

  custom = (value) => (
    <em
      onClick={() => this.downloadPdf(value.GrosWeight)}
      style={{ color: "#bf360c" }}
      className={"download fa-2x fas fa-file-pdf mr-2"}
    ></em>
  );

  componentDidMount = () => {
    this.getDataStorage();
  };

  downloadExcel = async () => {
    this.setState({
      loading: true,
    });
    let { dataReport, Amoun, toltlAmoun, dataTable } = this.state;
    let newData = [];
    dataTable.map((e) => {
      if (e.hidden === false) {
        dataReport.map((w) => {
          if (w.W_ReceiptLineId === e.W_ReceiptLineId) {
            newData.push(w);
          }
        });
      }
    });

    let data = newData;
    let success = await Axios.post(
      "https://poseidonreports.azurewebsites.net/reports/dinamic",
      { data, headers, keys }
    );
    if (success.data.base64) {
      let dateNow = new Date();
      let titleDownload = `Lines-${dateNow.getDate() +
        "" +
        (dateNow.getMonth() + 1).toString().padStart(2, "0") +
        "" +
        dateNow.getFullYear()}.xlsx`;
      //   const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';
      const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
      saveAs(linkSource, titleDownload);
      this.setState({
        loading: false,
      });
    } else console.warn("Error downloading file", success);
  };

  getDataStorage = () => {
    let { id } = this.state;
    let idValidate = validateIdParams(id);
    if (!idValidate) {
      message.error("Data entered is invalid", 2);
      setTimeout(() => {
        this.props.history.push("/transactions");
      }, 2000);
      return;
    }
    axios
      .get(`${Services.GET_WAREHOUSING.path}warehouse/receipts-data-id/${id}`)
      .then((success) => {
        let Info = [];
        Info.push(success.data.data.info[0]);
        // Info[0].ContainerNumber = 'INBU5464955';
        // Info[0].BookingContainerId = 8006;

        success.data.data.Table.map((e) => {
          e.hidden = false;
        });

        this.setState({
          Info,
          dataTable: success.data.data.Table,
          dataReport: success.data.data.dataReport,
        });
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          fullLoader: true,
        });
      });
  };

  handleSearch = (name) => (event) =>
    this.setState({ [name]: event.target.value }, () => this.setSearch());
  setSearch = () => {
    let search = this.state.search;
    let cliente = [...this.state.dataTable];
    let haveSearch = false;
    if (search.length > 0) {
      haveSearch = true;
    }
    let all = cliente;
    let newAll = all.map((item) => {
      const W_ReceiptLineId = item.W_ReceiptLineId
        ? item.W_ReceiptLineId.toString().toUpperCase()
        : "".toString().toUpperCase();
      const Item = item.Item ? item.Item.toUpperCase() : "".toUpperCase();
      const Description = item.Description
        ? item.Description.toUpperCase()
        : "".toUpperCase();
      const DeclarratedQty = item.DeclarratedQty
        ? item.DeclarratedQty.toUpperCase()
        : "".toUpperCase();
      const CountQty = item.CountQty
        ? item.CountQty.toUpperCase()
        : "".toUpperCase();
      const LineUOM = item.LineUOM
        ? item.LineUOM.toUpperCase()
        : "".toUpperCase();
      const AlternativeUOM = item.AlternativeUOM
        ? item.AlternativeUOM.toUpperCase()
        : "".toUpperCase();
      const GrosWeight = item.GrosWeight
        ? item.GrosWeight.toString().toUpperCase()
        : "".toString().toUpperCase();
      const WeightUOM = item.WeightUOM
        ? item.WeightUOM.toString().toUpperCase()
        : "".toString().toUpperCase();
      const ItemCategory = item.ItemCategory
        ? item.ItemCategory.toUpperCase()
        : "".toUpperCase();
      const TagID = item.TagID ? item.TagID.toUpperCase() : "".toUpperCase();

      const textData = search.toUpperCase();

      if (
        W_ReceiptLineId.indexOf(textData) > -1 ||
        Item.indexOf(textData) > -1 ||
        Description.indexOf(textData) > -1 ||
        DeclarratedQty.indexOf(textData) > -1 ||
        CountQty.indexOf(textData) > -1 ||
        LineUOM.indexOf(textData) > -1 ||
        AlternativeUOM.indexOf(textData) > -1 ||
        GrosWeight.indexOf(textData) > -1 ||
        WeightUOM.indexOf(textData) > -1 ||
        TagID.indexOf(TagID) > -1 ||
        ItemCategory.indexOf(textData) > -1
      ) {
        item.hidden = false;
      } else {
        item.hidden = haveSearch ? true : false;
      }
      return item;
    });

    let toltlAmoun = 0;
    let Amoun = 0;
    newAll.map((e) => {
      if (e.hidden === false) {
        Amoun = Amoun + 1;
        toltlAmoun = toltlAmoun + Number(e.LineUOM);
      }
    });
    let pages = newAll.length / 10 + 1;
    this.setState({
      dataTable: newAll,
      pages: pages,
      toltlAmoun: "$" + Number(toltlAmoun).toLocaleString("En-us"),
      Amoun,
    });
  };

  render() {
    let {
      graphs,
      fullLoader,
      loading,
      dataTable,
      columnsTable,
      subTable,
      pages,
      search,
    } = this.state;
    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div className="content-heading">
            <Row>
              <legend>
                <em
                  style={{ color: "rgb(60, 172, 244)" }}
                  className="fa-1x mr-2 fas fa-arrow-alt-circle-right"
                ></em>
                Receptions Detail
              </legend>
            </Row>
          </div>
          <Col>
            {/* <div style={{ height: 50 }} /> */}
            <Card className="myCard cardheader">
              <CardBody className="cardPading">
                <Col md={12} xl={12}>
                  <Row>
                    <Col md={4} lg={3} sm={12}>
                      <h5
                        style={{ fontWeight: "bold" }}
                        className="label_title_aling"
                      >
                        Document No:{" "}
                        {this.state.Info[0]
                          ? this.state.Info[0].DocumentNo
                          : ""}
                      </h5>
                    </Col>
                    <Col md={4} lg={3} sm={12}>
                      <h5
                        style={{ fontWeight: "bold" }}
                        className="label_title_aling"
                      >
                        Receipt Date:{" "}
                        {this.state.Info[0]
                          ? this.state.Info[0].ReceiptDate
                          : ""}
                      </h5>
                    </Col>
                    <Col style={{ textAlign: "end" }} md={3} lg={2} sm={12}>
                      <Button
                        className={"btn-oval"}
                        style={stylebutton}
                        color={"primary"}
                      >
                        {this.state.Info[0] ? this.state.Info[0].Status : ""}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            <Suspense fallback={<div>Loading...</div>}>
              {" "}
              <CardInfo
                CardIndoHeaders={this.state.CardIndoHeaders}
                config={this.state.config}
                data={this.state.Info}
                cardInfon={true}
              ></CardInfo>
            </Suspense>{" "}
            <br />
            <Card className="myCard">
              <CardBody>
                {/* <div style={{ height: 67 }}></div> */}
                <div className="row">
                  <div className="col-5">
                    {" "}
                    <h4 style={{ fontWeight: "bold" }}>Lines</h4>
                  </div>
                  <div className="col-6">
                    <Search
                      input={{
                        icon: "search",
                        iconPosition: "left",
                        fluid: true,
                      }}
                      placeholder="..."
                      value={search}
                      open={false}
                      name="search"
                      id="search"
                      onSearchChange={this.handleSearch("search")}
                    />
                  </div>
                  <div className="col-1">
                    <Col lg={12} sm={12} md={12}>
                      <div className=" col-2 text-center ">
                        <Row>
                          {loading ? (
                            <center>
                              {" "}
                              <Icon
                                loading={loading}
                                style={{ marginTop: "6px" }}
                                name="circle notch"
                                size="large"
                              />
                            </center>
                          ) : (
                            <center>
                              <em
                                style={{ color: "#43a047", marginTop: "6px" }}
                                onClick={this.downloadExcel}
                                className="download fa-2x fas fa-file-excel mr-2"
                              ></em>
                            </center>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </div>
                </div>

                <DataTable
                  data={dataTable}
                  columnsTable={columnsTable}
                  subTable={subTable}
                  pages={pages}
                ></DataTable>
              </CardBody>
            </Card>
            <CardBody></CardBody>
          </Col>
        </ContentWrapper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  //   nit: state.header.nit
});
const mapDispatchToProps = () => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsReceipts)
);
