
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row, Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";
import { FilePond, File } from 'react-filepond';

import { Dropdown, Menu } from "antd";
import Table from "../../../../template/Table";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Services } from "../../Services";



import { MoreOutlined, DeleteOutlined, CloudDownloadOutlined, MonitorOutlined } from '@ant-design/icons';

function Documents(props) {
	const [loader, setLoader] = useState(false)
	const [modal, setModal] = React.useState(false);
	const [modalDeleteDoc, setModalDeleteDoc] = React.useState(false);
	const [nameDoc, setNameDoc] = React.useState(undefined);
	const [dataItem, setDataItem] = React.useState([]);

	const [columns, setColumns] = useState([

		{
			key: '', label: 'Actions', custom: (value) => <Dropdown overlay={
				(
					<Menu>
						<Menu.Item >
							<CloudDownloadOutlined style={{ fontSize: '18px' }} />
							<a className='link-options' href={value.URL}>
								<i className='global-text'> Download</i>
							</a>
						</Menu.Item>
						<Menu.Item onClick={() => window.open(`${Services.ORDERS.path}order/document/view/pdf/?url=` + value.URL)}>
							<MonitorOutlined style={{ fontSize: '18px' }} /> <i className='global-text'>View</i>
						</Menu.Item>
						<Menu.Item onClick={() => { setModalDeleteDoc(!modalDeleteDoc), setNameDoc(value.Name), setDataItem(value) }}>
							<DeleteOutlined style={{ fontSize: '18px' }} /> <i className='global-text'>Delete</i>
						</Menu.Item>
					</Menu>
				)
			} placement="topRight">
				<div >
					<MoreOutlined className='btn-expander' style={{ fontSize: '26px' }} />
				</div>
			</Dropdown>
		},
		{ key: 'Name', label: 'Document Name' },
		{ key: 'Type', label: 'Doument Type' },
		{ key: 'Category', label: 'Document Category' },
		{ key: 'CreationDate', label: 'Creation Date', },
		{ key: 'CreateBy', label: 'Updated By' },
	])
	const [data, setData] = useState([])

	function changueLoader(params) {
		setTimeout(() => {
			setLoader(true)
		}, 3000);
	}


	function toggleModal() {
		setModal(!modal);
	}

	function toggleDeleteModal() {
		setModalDeleteDoc(!modalDeleteDoc);
	}

	useEffect(() => {
		setData(props.documents)
	}, [])

	return (
		<>
			<Col xs={12} md={6} lg={6} >
				<Row>
					<Col xs={12} md={12} lg={12}>
						{props.shipmentLine.name}
					</Col >
				</Row>
			</Col>
			<div className="margin-footer-2" />

			<Table
				columns={columns}
				data={data}
				options={{
					downloads: {
						pdf: false,
						xlsx: false
					},
					pagination: true,
					search: false,
				}}
			/>
			<div className="row">
				<div className="col-11">
				</div>
				<div className="col-1">
					<Fab color="primary" size="small" aria-label="add"
						onClick={toggleModal}
					>
						<AddIcon />
					</Fab>
				</div>
			</div>
			<Modal isOpen={modal} toggle={toggleModal} >
				<ModalHeader toggle={toggleModal}>Create new document</ModalHeader>
				<ModalBody>
					{/* <AddComment callback={this.callback} onError={this.onError} id={this.state.id} _user={this.props._user} /> */}
					<FilePond
						onupdatefiles={(fileItems) => { props.callFile(fileItems) }}>
						{/* {this.state.files.map(file => (
                            <File key={file} src={file} origin="local" />
                        ))} */}
					</FilePond>
					<Row>
						<props.FormCreator dataForm={props.dataForm} Model={props.Model} Catalog={props.Catalogs} onChange={props.onChange} TabName={props.TabName} />
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button
						// disabled={this.state.disable}
						variant="contained"
						color="primary"
						className="button"
						onClick={() => { props.addNewDocumment(), setModal(false) }}
						size="lg"
					>
						Save and complete
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={modalDeleteDoc} toggle={toggleDeleteModal}>
				<ModalHeader id={'delete_document'} className="header Modal Remove Document" toggle={toggleDeleteModal}><h4>Delete Document</h4></ModalHeader>
				<ModalBody>
					<center>Are you sure you want to delete this document  <b>{`"${nameDoc}"`}</b> ?</center>
				</ModalBody>
				<ModalFooter>
					<Button color="success" onClick={() => { props.rmDocument(dataItem), toggleDeleteModal() }}>Yes</Button>{' '}
					<Button color="danger" onClick={toggleDeleteModal}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default withRouter(Documents);

