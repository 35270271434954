import React, { Component } from 'react';
import pubsub from 'pubsub-js';
import { Link, withRouter } from 'react-router-dom';
// , DropdownMenu, DropdownItem, ListGroup, ListGroupItem
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';

import ToggleState from '../Common/ToggleState';
import TriggerResize from '../Common/TriggerResize';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from './Header.run';
import smallIMG from '../../../resources/small.png';
// import largeIMG from '../../../resources/interglobo-logo.png';
import { connect } from 'react-redux';
import $ from 'jquery';
import Authentication from '../../../../src/services/Authentication.js';
import { Services } from '../../poseidon/Login/Services'
const auth = new Authentication();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      new_comments: this.props.getNewComments,
      customer: this.props.customer,
      interglobo: this.props.interglobo,
      neworder_attention: this.props.neworder_attention,
      rolId: auth.getAuthentication('RolId'),
    };
  }

  componentDidMount() {
    HeaderRun();
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      new_comments: nextProps.getNewComments,
      customer: nextProps.customer,
      interglobo: nextProps.interglobo,
      neworder_attention: nextProps.neworder_attention
    });
  }

  toggleUserblock(e) {
    e.preventDefault();
    pubsub.publish('toggleUserblock');
  }

  handeInputData = selected => event => {
    this.setState({ searchValue: event.target.value });
  };

  navigateToExactPath = () => {

    // const { history } = this.props
    const navbarFormSelector = 'form.navbar-form';

    $(navbarFormSelector).removeClass('open');
    $('input[type="text"]').val('');

    // history.push(localStorage.getItem('route'))
  }

  handleLogOut = () => {

    const createOffsetValue = date => {
      var sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
      var offset = Math.abs(date.getTimezoneOffset());
      var hours = Math.floor(offset / 60);
      var minutes = (offset % 60) / 60 * 10;
      return sign + hours + '.' + minutes;
    };

    let endSession = {
      SessionId: auth.getAuthentication('token'),
      CustomerId: auth.getAuthentication('customerSelect'),
      Logout: "1",
      timezone: createOffsetValue(new Date())
    }

    fetch(`${Services.SESSIONRECORD.path}/user/logout`, {
      method: 'POST',
      body: JSON.stringify(endSession),
      keepalive: true
    });

  };


  removeToken = () => {
    this.handleLogOut()
    this.props.history.push('/');
    auth.deleteAuthentication('token');
    auth.deleteAuthentication('dataUser');
    auth.deleteAuthentication('customerSelect');
  }

  render() {
    const { searchValue, new_comments, customer, interglobo, neworder_attention, rolId } = this.state;
    let totalsum = Number(new_comments) + Number(customer) + Number(interglobo) + Number(neworder_attention.new_orders) + Number(neworder_attention.attention);
    let acction;

    if (searchValue === '' || searchValue === undefined) {
    } else {
      acction = <Link to={`/search/${searchValue}`}> <button className="d-none" type="submit">Submit</button></Link>;
    }

    return (
      <header className="topnavbar-wrapper">
        { /* START Top Navbar */}
        <nav className="navbar topnavbar">
          { /* START navbar header */}
          <div className="navbar-header">
            <a className="navbar-brand" href="#/">
              <div className="brand-logo">
                {/* <img className="img-fluid" src={largeIMG} alt="App Logo" width="130" height="70"/> */}
                <img className="img-fluid" src={'https://fsposeidon.blob.core.windows.net/general/interglobo-logo-border-none.png'} alt="App Logo" width="170" height="70" />
              </div>
              <div className="brand-logo-collapsed">
                <img className="img-fluid" src={smallIMG} alt="App Logo" />
              </div>
            </a>
          </div>
          { /* END navbar header */}
          { /* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <TriggerResize>
                <ToggleState state="aside-collapsed">
                  <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block">
                    <em className="fas fa-bars"></em>
                  </a>
                </ToggleState>
              </TriggerResize>
              { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <ToggleState state="aside-toggled" nopersist={true}>
                <a href="" className="nav-link sidebar-toggle d-md-none">
                  <em className="fas fa-bars"></em>
                </a>
              </ToggleState>
            </li>
            { /* START User avatar toggle */}
            <li className="nav-item d-none d-md-block">
              <a className="nav-link" onClick={this.toggleUserblock}>
                <em className="icon-user"></em>
              </a>
            </li>
            { /* END User avatar toggle */}
            { /* START lock screen */}
            <p onClick={this.removeToken} className="nav-item d-none d-md-block">
              <Link to="lock" title="Logout" className="nav-link">
                <em className="icon-logout"></em>
              </Link>
            </p>
            { /* END lock screen */}
          </ul>
          { /* END Left navbar */}
          { /* START Right Navbar */}
          <ul className="navbar-nav flex-row">
            { /* Search icon */}
            {
              rolId === '8' || rolId === '9' || rolId === '10' || rolId === '11' ? '' :
                <li className="nav-item">
                  <a className="nav-link" href="" data-search-open="">
                    <em className="icon-magnifier"></em>
                  </a>
                </li>
            }
            { /* Fullscreen (only desktops) */}
            {/* <li className="nav-item d-none d-md-block">
                <ToggleFullscreen className="nav-link"/>
              </li> */}

            { /* START Alert menu */}
            {/* // FIXME: se comenta el apartado de campana de notificaciones */}
            {
              rolId === '8' || rolId === '9' || rolId === '10' || rolId === '11' ? '' :
                <UncontrolledDropdown nav inNavbar className="dropdown-list">
                  <DropdownToggle nav className="dropdown-toggle-nocaret">
                    <em className='icon-bell'></em>
                    {
                      totalsum > 0 ?
                        <span className="badge badge-danger">{totalsum}</span> : ''
                    }
                  </DropdownToggle>

                  <DropdownMenu id="dropdown-custom-comments" right className="dropdown-menu-right animated flipInX">
                    <DropdownItem>

                      <ListGroup>
                        <ListGroupItem action tag="a" href="" onClick={() => this.props.history.push('/order-list?searching=ONC')}>
                          <div className="media">
                            <div className="align-self-start mr-2">
                            </div>
                            <div className="media-body">
                              <span className="d-flex align-items-center">
                                <p className="m-1">Orders with new comments</p>
                                <span className="badge badge-danger ml-auto">{new_comments}</span>
                              </span>
                            </div>
                          </div>
                        </ListGroupItem>

                        <ListGroupItem action tag="a" href="" onClick={() => this.props.history.push('/order-list?searching=ordersAttention')}>
                          <div className="media">
                            <div className="align-self-start mr-2">
                            </div>
                            <div className="media-body">
                              <span className="d-flex align-items-center">
                                <p className="m-1">Orders that require attention</p>
                                <span className="badge badge-danger ml-auto">{neworder_attention.attention}</span>
                              </span>
                            </div>
                          </div>
                        </ListGroupItem>

                        <ListGroupItem action tag="a" href="" onClick={() => this.props.history.push('/order-list?searching=newOrders')}>
                          <div className="media">
                            <div className="align-self-start mr-2">
                            </div>
                            <div className="media-body">
                              <span className="d-flex align-items-center">
                                <p className="m-1">New orders</p>
                                <span className="badge badge-danger ml-auto">{neworder_attention.new_orders}</span>
                              </span>
                            </div>
                          </div>
                        </ListGroupItem>

                        <ListGroupItem action tag="a" href="" onClick={() => this.props.history.push('/order-list?searching=newCusComment')}>
                          <div className="media">
                            <div className="align-self-start mr-2">
                            </div>
                            <div className="media-body">
                              <span className="d-flex align-items-center">
                                <p className="m-1">New customer comments</p>
                                <span className="badge badge-danger ml-auto">{customer}</span>
                              </span>
                            </div>
                          </div>
                        </ListGroupItem>
                        <ListGroupItem action tag="a" href="" onClick={() => this.props.history.push('/order-list?searching=newIntComment')}>
                          <div className="media">
                            <div className="align-self-start mr-2">
                            </div>
                            <div className="media-body">
                              <span className="d-flex align-items-center">
                                <p className="m-1">New interglobo comments</p>
                                <span className="badge badge-danger ml-auto">{interglobo}</span>
                              </span>
                            </div>
                          </div>
                        </ListGroupItem>

                        {/*<ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="fa fa-envelope fa-2x text-warning"></em>
                                             </div>
                                             <div className="media-body">
                                                <p className="m-0">New e-mails</p>
                                                <p className="m-0 text-muted text-sm">You have 10 new emails</p>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                        <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="fa fa-tasks fa-2x text-success"></em>
                                             </div>
                                             <div className="media-body">
                                                <p className="m-0">Pending Tasks</p>
                                                <p className="m-0 text-muted text-sm">11 pending task</p>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <span className="d-flex align-items-center">
                                             <span className="text-sm">More notifications</span>
                                             <span className="badge badge-danger ml-auto">14</span>
                                          </span>
                                       </ListGroupItem> */}
                      </ListGroup>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
            }

            { /* END Alert menu */}

            { /* Fullscreen (only desktops) */}
            <li className="nav-item d-none d-md-block">
              <ToggleFullscreen className="nav-link" />
            </li>

            { /* START Offsidebar button */}
            {/* <li className="nav-item">
                            <ToggleState state="offsidebar-open"onClick={this.removeToken} nopersist={true}>
                                <a className="nav-link" >
                                    <em  className="icon-notebook"></em>
                                </a>
                            </ToggleState>
                        </li> */}
            { /* END Offsidebar menu */}
          </ul>
          { /* END Right Navbar */}
          { /* START Search form */}
          <form className="navbar-form">
            <div className="form-group">
              <input className="form-control" type="text" onChange={this.handeInputData()} placeholder="Quick Search By:  PO No, SKU No, Container No, HBL/HAWB, Shipment No, Status, Supplier, Consignee, From, To, Cruise Name ..." />
              <div className="fa fa-times navbar-form-close" onClick={() => this.navigateToExactPath()}></div>
            </div>
            {acction}
          </form>
          { /* END Search form */}
        </nav>
        { /* END Top Navbar */}
      </header>);
  }
}

const mapStateToProps = state => ({
  getNewComments: state.dashboard.new_comments,
  customer: state.dashboard.new_customer_comments,
  interglobo: state.dashboard.new_interglobo_comments,
  neworder_attention: state.dashboard.attention_and_new
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));