import React, { Component, Suspense } from "react";
import axios from "axios";
import { Card, CardBody, CardText, Col, Row, Button } from "reactstrap";
import ContentWrapper from "./../../../../../template/Layout/ContentWrapper";
// import mapJson from '../../../Services/mapJson';
// import { Services } from '../../Storage/Services';
import Loader from "../../../../../Global/Loader";
// import Loader from './../../../Global/Loader';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Timeline } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import Tables from "../../../../../template/Table";
import { Link } from "react-router-dom";
import { Services } from "../../../Services";

import { message } from "antd";
import { validateIdParams } from "../../../../../utils/regularExpressionValidation";

// import ClientesList from '../../../../services/storageClient'
// const Table = React.lazy(() => import('../../Components/Table'));

const stylebutton = {
  width: "7em",
  color: "#0179b8db",
  background: "#fff",
  padding: "4px",
  fontWeight: "bold",
};

class DetailsTrucking extends Component {
  state = {
    items: [],
    columns: [
      {
        label: "Shipment No.",
        key: "PONumber",
        minWidth: 80,
        custom: (value) => (
          <Col md={12} xl={12}>
            <Row>
              <Col md={12}>
                <Link to={"/shipments-detail/" + value.W_ShipmentId}>
                  {value.DocumentNo}
                </Link>
              </Col>
            </Row>
          </Col>
        ),
      },
      {
        label: "Status",
        key: "StatusDesc",
        minWidth: 80,
        custom: (value) => (
          <Button className={"btn-oval"} color={"primary"} onClick={() => {}}>
            {value.Status}
          </Button>
        ),
      },
      { label: "Consignee", key: "Consignee", type: "Input" },
      { label: "City", key: "ConsigneeCity" },
      { label: "Warehouse", key: "WarehouseCode" },
      { label: "Date to Ship", key: "DateShip", type: "datatime" },
      { label: "Ship date", key: "ShipDate", type: "datatime" },
    ],
    loading: false,
    // clientesSelect: clientSave.getAuthentication('nit'),
    clientesSelect: this.props.nit ? JSON.stringify(this.props.nit) : "[0]",
    fullLoader: true,
    id: this.props.match.params.id,
    CardIndoHeaders: {
      classNameTitle: "col-md-3 col-lg-8 col-sm-6",
      styleTitle: { marginTop: "5px", marginLeft: "-12px", fontWeight: "bold" },
      title: "Header References",
      subTitle: "",
      Icon: "fa-2x mr-2 fas fa-list-ul",
      style: { color: "#000000", marginBottom: "1em" },
    },
    CardIndoHeadersDelivery: {
      classNameTitle: "col-md-3 col-lg-8 col-sm-6",
      styleTitle: { marginTop: "5px", marginLeft: "-12px", fontWeight: "bold" },
      title: "Delivery",
      subTitle: "",
      Icon: "fa-2x mr-2 fab fa-openid",
      style: { color: "#000000", marginBottom: "1em" },
    },
    config2: [
      {
        title: "Destination Name",
        positionTitleLeft: true,
        id: "DestinationName",
        className: "col-md-3 col-lg-3 col-sm-6",
      },
      {
        title: "Destination City",
        id: "DestinationCity",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-3 col-sm-6",
      },
      {
        title: "Destination State",
        data: {
          style: {
            fontWeight: "bold",
            fontSize: "13px",
            color: "rgb(0, 151, 220)",
          },
        },
        link: true,
        linkPath: "/detalle-container",
        id: "DestinationState",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-3 col-sm-6",
      },
      {
        title: "Destination Post Code",
        id: "DestinationPostCode",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-3 col-sm-6",
      },
    ],
    config: [
      {
        title: "Warehouse",
        positionTitleLeft: true,
        id: "WarehouseCode",
        className: "col-md-3 col-lg-3 col-sm-6",
      },
      {
        title: "Reference",
        id: "Reference",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-3 col-sm-6",
      },
      {
        title: "Pieces",
        data: {
          style: {
            fontWeight: "bold",
            fontSize: "13px",
            color: "rgb(0, 151, 220)",
          },
        },
        link: true,
        linkPath: "/detalle-container",
        id: "Pieces",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-3 col-sm-6",
      },
      {
        title: "Weight",
        id: "Weight",
        positionTitleLeft: true,
        className: "col-md-3 col-lg-3 col-sm-6",
      },
    ],
    pages: 0,
    Info: [{ icon: {} }],
  };

  custom = (value) => (
    <em
      onClick={() => this.downloadPdf(value.ShipmentNo)}
      style={{ color: "#bf360c" }}
      className={"download fa-2x fas fa-file-pdf mr-2"}
    ></em>
  );

  componentDidMount = () => {
    this.getDataStorage();
  };

  getDataStorage = () => {
    let { id } = this.state;
    let idValidate = validateIdParams(id);
    if (!idValidate) {
      message.error("Data entered is invalid", 2);
      setTimeout(() => {
        this.props.history.push("/transactions");
      }, 2000);
      return;
    }
    axios
      .get(`${Services.GET_WAREHOUSING.path}warehouse/trucking-data-id/${id}`)
      .then((success) => {
        let Info = [];
        Info.push(success.data.data.info[0]);
        this.setState({
          Info,
          items: success.data.data.shipList ? success.data.data.shipList : [],
        });
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          fullLoader: true,
        });
        message.error("Data entered is invalid", 2);
        setTimeout(() => {
          this.props.history.push("/transactions");
        }, 2000);
        return;
      });
  };

  buildRows = (config, customer) => {
    let rows = config.map((e) => {
      return (
        <div className={e.className} style={{ paddingTop: "1%" }}>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span style={{ color: "#545350", fontWeight: "bold" }}>
                {e.title}
              </span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span>{customer[0][e.id] ? customer[0][e.id] : ""}</span>
            </div>
          </div>
        </div>
      );
    });

    return rows;
  };

  render() {
    let { fullLoader, loading, search, columns, items } = this.state;
    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div className="content-heading">
            <Row>
              <legend>
                <em
                  style={{ color: "#000000" }}
                  className="fa-1x mr-2 fas fa-truck"
                ></em>
                Trucking Detail
              </legend>
            </Row>
          </div>
          <Col md={12} xl={12}>
            <Row>
              <Col md={7} xl={7}>
                <Card className="myCard cardheader">
                  <CardBody className="cardPading">
                    <Col md={12} xl={12}>
                      <Row>
                        <Col md={4} lg={7} sm={12}>
                          <h5
                            style={{ fontWeight: "bold" }}
                            className="label_title_aling"
                          >
                            Load No:{" "}
                            {this.state.Info[0]
                              ? this.state.Info[0].LoadNo
                              : ""}
                          </h5>
                        </Col>

                        <Col
                          style={{ textAlign: "end", fontWeight: "bold" }}
                          md={3}
                          lg={4}
                          sm={12}
                        >
                          <Button
                            className={"btn-oval"}
                            style={stylebutton}
                            color={"primary"}
                          >
                            {this.state.Info[0]
                              ? this.state.Info[0].Status
                              : ""}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
                {/* <Suspense fallback={<div>Loading...</div>}> <CardInfo CardIndoHeaders={this.state.CardIndoHeaders} config={this.state.config} data={this.state.Info} cardInfon={true}></CardInfo></Suspense> <br /> */}
                <Suspense fallback={<div>Loading...</div>}>
                  <Card style={{ minHeight: "25em" }} className="myCard">
                    <CardBody className="cardPading">
                      <div style={{ marginTop: "13px" }} className="col-md-12">
                        <>
                          <div className="row">
                            {this.state.CardIndoHeaders.Icon ? (
                              <div
                                className="col-md-3 col-lg-1 col-sm-6"
                                style={{ textAlign: "center" }}
                              >
                                <em
                                  className={
                                    this.state.CardIndoHeaders.Icon
                                      ? this.state.CardIndoHeaders.Icon
                                      : ""
                                  }
                                  style={
                                    this.state.CardIndoHeaders.style
                                      ? this.state.CardIndoHeaders.style
                                      : ""
                                  }
                                ></em>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              className={
                                this.state.CardIndoHeaders.classNameTitle
                                  ? this.state.CardIndoHeaders.classNameTitle
                                  : "col-md-3 col-lg-3 col-sm-6"
                              }
                              style={
                                this.state.CardIndoHeaders.styleTitle
                                  ? this.state.CardIndoHeaders.styleTitle
                                  : {
                                      marginTop: "12px",
                                      marginLeft: this.state.CardIndoHeaders
                                        .Icon
                                        ? "-12px"
                                        : "",
                                      fontWeight: "bold",
                                    }
                              }
                            >
                              <h4>
                                {this.state.CardIndoHeaders.title
                                  ? this.state.CardIndoHeaders.title
                                  : ""}
                              </h4>
                            </div>
                          </div>
                          <div
                            className="mb-mail-subject"
                            style={{ textAlign: "" }}
                          >
                            <center>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                {this.state.CardIndoHeaders.subTitle
                                  ? this.state.CardIndoHeaders.subTitle
                                  : ""}
                              </span>
                            </center>
                          </div>
                          <div style={{ height: 5 }} />{" "}
                        </>
                        <div style={{ marginBottom: "3em" }} className="row">
                          {this.buildRows(this.state.config, this.state.Info)}
                        </div>
                      </div>
                      <div style={{ marginTop: "13px" }} className="col-md-12">
                        <>
                          <div className="row">
                            {this.state.CardIndoHeadersDelivery.Icon ? (
                              <div
                                className="col-md-3 col-lg-1 col-sm-6"
                                style={{ textAlign: "center" }}
                              >
                                <em
                                  className={
                                    this.state.CardIndoHeadersDelivery.Icon
                                      ? this.state.CardIndoHeadersDelivery.Icon
                                      : ""
                                  }
                                  style={
                                    this.state.CardIndoHeadersDelivery.style
                                      ? this.state.CardIndoHeadersDelivery.style
                                      : ""
                                  }
                                ></em>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              className={
                                this.state.CardIndoHeadersDelivery
                                  .classNameTitle
                                  ? this.state.CardIndoHeadersDelivery
                                      .classNameTitle
                                  : "col-md-3 col-lg-3 col-sm-6"
                              }
                              style={
                                this.state.CardIndoHeadersDelivery.styleTitle
                                  ? this.state.CardIndoHeadersDelivery
                                      .styleTitle
                                  : {
                                      marginTop: "12px",
                                      marginLeft: this.state
                                        .CardIndoHeadersDelivery.Icon
                                        ? "-12px"
                                        : "",
                                      fontWeight: "bold",
                                    }
                              }
                            >
                              <h4>
                                {this.state.CardIndoHeadersDelivery.title
                                  ? this.state.CardIndoHeadersDelivery.title
                                  : ""}
                              </h4>
                            </div>
                          </div>
                          <div
                            className="mb-mail-subject"
                            style={{ textAlign: "" }}
                          >
                            <center>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                {this.state.CardIndoHeadersDelivery.subTitle
                                  ? this.state.CardIndoHeadersDelivery.subTitle
                                  : ""}
                              </span>
                            </center>
                          </div>
                          <div style={{ height: 5 }} />{" "}
                        </>
                        <div style={{ marginBottom: "3em" }} className="row">
                          {this.buildRows(
                            this.state.config2,
                            this.state.Info
                            // this.state.CardIndoHeaders
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Suspense>{" "}
                <br />
              </Col>

              {/* </Col> */}

              {/* <Col md={12} xl={12}> */}
              <Col md={5} xl={5}>
                <Card className="myCard cardheader">
                  <CardBody className="cardPading">
                    <Col md={12} xl={12}>
                      <Row>
                        <Col md={4} lg={4} sm={12}></Col>
                        <Col md={4} lg={6} sm={12}>
                          <h5
                            style={{ fontWeight: "bold" }}
                            className="label_title_aling"
                          >
                            <em
                              style={{ color: "#fff" }}
                              className="fa-1x mr-2 far fa-calendar-check"
                            ></em>
                            Events
                          </h5>
                        </Col>
                        <Col md={3} lg={2} sm={12}></Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
                <Suspense fallback={<div>Loading...</div>}>
                  <Card className="myCard eventsCard">
                    <CardBody
                      style={{ minHeight: "25em" }}
                      className="cardPading"
                    >
                      <div style={{ height: "3em" }}></div>
                      <Timeline mode="alternate">
                        <Timeline.Item
                          dot={
                            <CheckCircleOutlined style={{ fontSize: "16px" }} />
                          }
                        >
                          <div style={{ height: "2px" }}></div>
                          <p style={{ fontWeight: "bold", marginTop: "1px" }}>
                            Departure from Origin
                          </p>
                          <div className="row">
                            <div
                              style={{ fontSize: "12px" }}
                              className="col-12"
                            >
                              {this.state.Info[0] ? (
                                <p>ETD: {this.state.Info[0].ETD}</p>
                              ) : (
                                <p></p>
                              )}

                              {this.state.Info[0] ? (
                                <p>ATD: {this.state.Info[0].ATD}</p>
                              ) : (
                                <p></p>
                              )}
                            </div>
                          </div>
                        </Timeline.Item>

                        <Timeline.Item
                          dot={
                            <CheckCircleOutlined style={{ fontSize: "16px" }} />
                          }
                        >
                          <div style={{ height: "2px" }}></div>
                          <p style={{ fontWeight: "bold", marginTop: "1px" }}>
                            Arrival At Destination
                          </p>
                          <div className="row">
                            <div
                              style={{ fontSize: "12px" }}
                              className="col-12"
                            >
                              {this.state.Info[0] ? (
                                <p>ETA: {this.state.Info[0].ETA}</p>
                              ) : (
                                <p></p>
                              )}

                              {this.state.Info[0] ? (
                                <p>ATA: {this.state.Info[0].ATA}</p>
                              ) : (
                                <p></p>
                              )}
                            </div>
                          </div>
                        </Timeline.Item>
                      </Timeline>
                    </CardBody>
                  </Card>
                </Suspense>
                <br />
              </Col>
            </Row>

            <Card className="myCard">
              <CardBody>
                <Row>
                  <Col md={12} xl={12}>
                    <h3>
                      <em
                        style={{ color: "rgb(33, 186, 69)" }}
                        className="fa-1x mr-1 fas fa-arrow-alt-circle-left"
                      ></em>
                      Shipment
                    </h3>
                  </Col>
                </Row>
                <Tables
                  data={items}
                  columns={columns}
                  options={{
                    pagination: false,
                    search: false,
                    downloads: { xlsx: false, pdf: false },
                  }}
                ></Tables>
              </CardBody>
            </Card>
            <CardBody></CardBody>
          </Col>
        </ContentWrapper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  //   nit: state.header.nit
});
const mapDispatchToProps = () => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsTrucking)
);
