import React, { Component } from 'react';
import { Row, Label } from 'reactstrap';
import QuickCard from './../../../../Global/CardItems';
import { Pagination } from 'semantic-ui-react';
import {
  configOrder, configBooking, configShipment,
  configContainer, configReceipt, configTrucking, configShipmentBurgo, configInventory
} from './config';

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activePageorder: 0,
      activePageBooking: 0,
      activePageShipment: 0,
      activePageContainer: 0,
      activePageReceipt: 0,
      activePageShiBurgo: 0,
      activePageTrucking: 0,
      activePageInventory: 0,

      totalPagesOrder: 0,
      totalPagesBooking: 0,
      totalPageShipment: 0,
      totalPageContainer: 0,
      totalPageWarehousesReceipt: 0,
      totalPageWarehousesShiBurgo: 0,
      totalPageWarehouseTruckings: 0,
      totalPageInventory: 0
    };
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.data) this.setState({ data: nextProps.data, dataPage: nextProps.data, allData: nextProps.data }, () => { this.initPages(); });
  }

  initPages = () => {
    const { dataPage } = this.state;
    let pagOrder = Math.ceil(parseInt(dataPage.Order.length) / 10);
    let pagBooking = Math.ceil(parseInt(dataPage.Booking.length) / 10);
    let pagShipment = Math.ceil(parseInt(dataPage.Shipment.length) / 10);
    let pagContainer = Math.ceil(parseInt(dataPage.Container ? dataPage.Container.length : 0) / 10);
    let pagReceipt = Math.ceil(parseInt(dataPage.Receipt ? dataPage.Receipt.length : 0) / 10);
    let pagShipBurgo = Math.ceil(parseInt(dataPage.ShipmentBurgo ? dataPage.ShipmentBurgo.length : 0) / 10);
    let pagTruckings = Math.ceil(parseInt(dataPage.Truckings ? dataPage.Truckings.length : 0) / 10);
    let pagInventory = Math.ceil(parseInt(dataPage.Inventory ? dataPage.Inventory.length : 0) / 10);

    const totalOrders = dataPage.Order.length;
    const totalBookings = dataPage.Booking.length;
    const totalShipments = dataPage.Shipment.length;
    const totalContainer = dataPage.Container ? dataPage.Container.length : 0;
    const totalReceipt = dataPage.Receipt ? dataPage.Receipt.length : 0;


    const totalShiBurgo = dataPage.ShipmentBurgo ? dataPage.ShipmentBurgo.length : 0;
    const totalTruckings = dataPage.Truckings ? dataPage.Truckings.length : 0;
    const totalInventory = dataPage.Inventory ? dataPage.Inventory.length : 0;
    this.setState({
      activePageorder: 1,
      activePageBooking: 1,
      activePageShipment: 1,
      activePageContainer: 1,
      activePageReceipt: 1,
      activePageShiBurgo: 1,
      activePageTrucking: 1,
      activePageInventory: 1,
      totalPagesOrder: pagOrder,
      totalPagesBooking: pagBooking,
      totalPageShipment: pagShipment,
      totalPageContainer: pagContainer,
      totalPageWarehousesReceipt: pagReceipt,
      totalPageWarehousesShiBurgo: pagShipBurgo,
      totalPageWarehouseTruckings: pagTruckings,
      totalPageInventory: pagInventory,

      totalOrders, totalBookings, totalShipments, totalReceipt, totalContainer, totalShiBurgo, totalTruckings, totalInventory
    }, () => {
      this.buildPageResults();
    });
  }

  buildPageResults = () => {
    let { activePageShipment, activePageContainer, activePageorder, activePageBooking, activePageReceipt, activePageShiBurgo, activePageTrucking, activePageInventory } = this.state;

    let dataOrder = this.setResultsByPage(activePageorder, this.state.dataPage.Order);
    let dataBooking = this.setResultsByPage(activePageBooking, this.state.dataPage.Booking);
    let dataShipments = this.setResultsByPage(activePageShipment, this.state.dataPage.Shipment);

    let dataContainers = this.setResultsByPage(activePageContainer, this.state.dataPage.Container ? this.state.dataPage.Container : []);
    let dataReceipt = this.setResultsByPage(activePageReceipt, this.state.dataPage.Receipt ? this.state.dataPage.Receipt : []);
    let dataShiBurgo = this.setResultsByPage(activePageShiBurgo, this.state.dataPage.ShipmentBurgo ? this.state.dataPage.ShipmentBurgo : []);
    let dataTrucking = this.setResultsByPage(activePageTrucking, this.state.dataPage.Truckings ? this.state.dataPage.Truckings : []);
    let dataInventory = this.setResultsByPage(activePageInventory, this.state.dataPage.Inventory ? this.state.dataPage.Inventory : []);

    this.setState({
      dataOrder,
      dataBooking,
      dataShipments,
      dataContainers,
      dataReceipt,
      dataShiBurgo,
      dataTrucking,
      dataInventory
    }, () => {
      this.buildCards();
    });
  }

  setResultsByPage = (page, all) => {
    page = page - 1;
    const pag = all.slice((page) * 10, (page + 1) * 10);
    return pag;
  }

  changePage = (page, from) => {
    if (from === 'order') {
      this.setState({
        activePageorder: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'booking') {
      this.setState({
        activePageBooking: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'shipment') {
      this.setState({
        activePageShipment: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'container') {
      this.setState({
        activePageContainer: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'receipt') {
      this.setState({
        activePageReceipt: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'shiburgo') {
      this.setState({
        activePageShiBurgo: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'trucking') {
      this.setState({
        activePageTrucking: page,
      }, () => {
        this.buildPageResults();
      });
    }
    if (from === 'inventory') {
      this.setState({
        activePageInventory: page,
      }, () => {
        this.buildPageResults();
      });
    }
  }

  buildCards = () => {
    const { dataOrder, dataBooking, dataShipments, dataContainers, dataReceipt, dataShiBurgo, dataTrucking, dataInventory } = this.state;

    /* ========================ORDERS================================================================*/
    let tags = dataOrder.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configOrder}></QuickCard>
        </div>
      );
    });
    this.setState({
      cards: ''
    }, () => this.setState({ cards: tags }));

    /* ========================BOOKINGS================================================================*/
    let tagsBooking = dataBooking.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configBooking}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsBooking: ''
    }, () => this.setState({ cardsBooking: tagsBooking }));

    /* =======================SHIPMENTS================================================================ */
    let tagsShipments = dataShipments.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configShipment}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsShipments: ''
    }, () => this.setState({ cardsShipments: tagsShipments }));

    /* =======================CONTAINER================================================================ */
    let tagsContainers = dataContainers.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configContainer}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsContainers: ''
    }, () => this.setState({ cardsContainers: tagsContainers }));


    /* =======================Receipt================================================================ */
    let tagsReceipt = dataReceipt.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configReceipt}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsReceipt: ''
    }, () => this.setState({ cardsReceipt: tagsReceipt }));

    /* =======================Shipment burgo================================================================ */
    let tagsShiBurgo = dataShiBurgo.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configShipmentBurgo}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsShiburgo: ''
    }, () => this.setState({ cardsShiburgo: tagsShiBurgo }));

    /* =======================Trucking burgo================================================================ */
    let tagsTrucking = dataTrucking.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configTrucking}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsTrucking: ''
    }, () => this.setState({ cardsTrucking: tagsTrucking }));

    /* =======================Warehouse Inventory================================================================ */
    let tagsInventory = dataInventory.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <QuickCard element={e} config={configInventory}></QuickCard>
        </div>
      );
    });
    this.setState({
      cardsInventory: ''
    }, () => this.setState({ cardsInventory: tagsInventory }));
  }

  render() {
    let { activePageShipment, activePageContainer, activePageBooking, activePageorder, activePageReceipt, activePageShiBurgo, activePageTrucking, totalOrders, totalPagesOrder, totalBookings, totalReceipt, totalContainer, totalShiBurgo, totalTruckings, totalPagesBooking, totalShipments, totalPageShipment, totalPageContainer, totalPageWarehousesReceipt, totalPageWarehousesShiBurgo, totalPageWarehouseTruckings, activePageInventory, totalInventory, totalPageInventory } = this.state;
    return (
      <Row>
        <div className="col-12">
          <h4>Purchase Orders</h4>
          <Label>{(activePageorder * 10) > totalOrders ? totalOrders : activePageorder * 10} of {totalOrders} results</Label>
          <div className="col-12">
            {this.state.cards}
          </div>
          {(totalOrders !== 0) ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageorder}
                  ellipsisItem={null}
                  totalPages={totalPagesOrder}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'order')} />
              </center>
            </div> : ''
          }
          <br />
        </div>
        <div className="col-12">
          <h4>Booking</h4>
          <Label>{(activePageBooking * 10) > totalBookings ? totalBookings : activePageBooking * 10} of {totalBookings} results</Label>
          <div className="col-12">
            {this.state.cardsBooking}
          </div>
          {(totalBookings !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageBooking}
                  ellipsisItem={null}
                  totalPages={totalPagesBooking}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'booking')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
        <div className="col-12">
          <h4>Shipment</h4>
          <Label>{(activePageShipment * 10) > totalShipments ? totalShipments : activePageShipment * 10} of {totalShipments} results</Label>
          <div className="col-12">
            {this.state.cardsShipments}
          </div>
          {(totalShipments !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageShipment}
                  ellipsisItem={null}
                  totalPages={totalPageShipment}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'shipment')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
        <div className="col-12">
          <h4>Container</h4>
          <Label>{(activePageContainer * 10) > totalContainer ? totalContainer : activePageContainer * 10} of {totalContainer} results</Label>
          <div className="col-12">
            {this.state.cardsContainers}
          </div>
          {(totalContainer !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageContainer}
                  ellipsisItem={null}
                  totalPages={totalPageContainer}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'container')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
        <div className="col-12">
          <h4>Warehouse Receipt</h4>
          <Label>{(activePageReceipt * 10) > totalReceipt ? totalReceipt : activePageReceipt * 10} of {totalReceipt} results</Label>
          <div className="col-12">
            {this.state.cardsReceipt}
          </div>
          {(totalReceipt !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageReceipt}
                  ellipsisItem={null}
                  totalPages={totalPageWarehousesReceipt}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'receipt')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
        <div className="col-12">
          <h4>Warehouse Shipments</h4>
          <Label>{(activePageShiBurgo * 10) > totalShiBurgo ? totalShiBurgo : activePageShiBurgo * 10} of {totalShiBurgo} results</Label>
          <div className="col-12">
            {this.state.cardsShiburgo}
          </div>
          {(totalShiBurgo !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageShiBurgo}
                  ellipsisItem={null}
                  totalPages={totalPageWarehousesShiBurgo}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'shiburgo')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
        <div className="col-12">
          <h4>Warehouse Trucking</h4>
          <Label>{(activePageTrucking * 10) > totalTruckings ? totalTruckings : activePageTrucking * 10} of {totalTruckings} results</Label>
          <div className="col-12">
            {this.state.cardsTrucking}
          </div>
          {(totalTruckings !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageTrucking}
                  ellipsisItem={null}
                  totalPages={totalPageWarehouseTruckings}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'trucking')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
        <div className="col-12">
          <h4>Warehouse Inventory</h4>
          <Label>{(activePageInventory * 10) > totalInventory ? totalInventory : activePageInventory * 10} of {totalInventory} results</Label>
          <div className="col-12">
            {this.state.cardsInventory}
          </div>
          {(totalInventory !== 0 ?
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageInventory}
                  ellipsisItem={null}
                  totalPages={totalPageInventory}
                  onPageChange={(e, { activePage }) => this.changePage(activePage, 'inventory')} />
              </center>
            </div> : ''
          )}
          <br />
        </div>
      </Row>
    );
  }
}
export default Cards;


