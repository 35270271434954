import React, { useEffect, useState } from "react";
import { Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Button } from "reactstrap";
import { message, Space, notification } from 'antd';

function newPO(params) {

	// const [modal, setModal] = useState(false)
	// const [messageApi, contextHolder] = message.useMessage();

	// const toggle = () => setModal(!modal);

	const error = () => {
		// messageApi.open({
		// 	type: 'error',
		// 	content: 'This is an error message',
		// });

		// message.error({
		// 	content: 'This is an error message',

		// 	// className: 'classCustomMessa',
		// });

		// notification.error({
		// 	description:'Hola jajaja'
		// })
	};


	useEffect(() => {

	}, [])


	return (
		<div>

			<div className='d-flex justify-content-end new_po_button'>
					<div className='d-flex align-items-center mt-2'>
						<i onClick={() => { params.toggle() }} className="fas fa-plus-circle fa-2x mr-1" style={{ color: '#13B0F0', cursor: 'pointer' }} />New PO
					</div>
			</div>

			{/* <div className='new_po_button'>
			</div> */}

			<Modal isOpen={params.modal_new_po} {...params}
				// size={'lg'}
				style={{ maxWidth: '90em' }}
			>
				<ModalHeader toggle={() => { params.toggle() }}>
					<span className="headerModalNMS"> New Purchase Order</span>
				</ModalHeader>
				<ModalBody>
					<Row>
						<params.formcreator dataForm={params.form} Model={params.model} onChange={params.handle_change_new_po} Catalog={params.catalogs} />
					</Row>
				</ModalBody>
				<ModalFooter>
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<div className="col-2">
								<Button color="danger" block
									onClick={() => {
										params.toggle()
										// error()
										// toggle();
										// setMSName(''); setTargetKeys([])
									}}
								>
									Cancel
								</Button>{' '}
							</div>
							<div className="col-2">
								<Button color="primary" block
									onClick={params.function_new_po}
								// hidden={(msName.length !== 0 && targetKeys.length > 0) ? false : true}
								>
									Create
								</Button>
							</div>
						</div>


					</div>

				</ModalFooter>
			</Modal>
		</div >

	)
}

export default newPO