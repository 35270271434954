import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
/**
 * @Styles
 */
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
/**
 * @Components
 */
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import Consignees from './components/Consignees';
import Deliveries from './components/Deliveries';
import Suppliers from './components/Suppliers';
import ViewOrder from './components/LineItems/viewOrder';
import Errors from '../../../template/Errors';
import ExtraFields from './components/ExtraFields';
import DropZoneComponent from './components/UploadFiles';
import HeaderDetails from './components/HeaderDetails';
/**
 * @Design
 */
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import { Button } from 'reactstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';
import {Card,CardBody} from 'reactstrap';
/**
 * @Models
 */
import Required from '../../../../models/RequiresPOcreate';
import Form from '../../../../models/FormData';
import Config from '../Data/Config';
import { Services } from '../Services';
/**
 * @Services
 */
import restService from '../../../../services/restService';
import VALIDATION from '../../../../validation';
/**
 * @Libs
 */
import async from 'async';

/**
 * @constant
 */
const ValidationService = new VALIDATION();
const rest = new restService();
const map = Config.map;

class FormExtended extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      open: false,
      POId : '',
      Required: Required,
      Form: {},
      errors: {},
      items: [],
      Documents: [],
      isDraft: false,
      error: {
        open: false,
        message: '',
        type: 'error'
      }
    };
  }

    componentDidMount = () => {
      let PONumber  = this.props.match.params && this.props.match.params.PONumber ? this.props.match.params.PONumber: '';
      let { selected } = this.state;
      this.setState({
        Form: Form
      },()=>{
        if (PONumber) {
          rest.EXEC({ _function: 'GetOneOrder', params: PONumber + '?type=detail' }, fetch).then(success => {
            if (success.data) selected.push(success.data);
            this.setState({ selected });
          }).catch(error => {
            console.error(error);
          });
        }
      });
    }

    refreshStateOrder = (newOrderState) => {
      let { order } = this.state;
      Object.keys(newOrderState).forEach((e) => {
        order[e] = newOrderState[e];
      });
      this.setState({ order });
    }


    recieveProps = (object, open) => this.setState({
      [object.stateField]: object.data,
      error: {
        open: open,
        type: this.state.error.type
      }
    }, () => this.setFieldToOrder(object['stateField'], object['id']))


    setFieldToOrder = (key, value) => {
      let { order } = this.state;
      order[map[key] ? map[key] : key] = value;
      this.setState({ order });
    }

    recieveItems = (items) => this.setState({ items })

    recieveDocuments = (Documents) => this.setState({ Documents })


    sendDocuments = (POId) => {
      const { Documents } = this.state;
      async.eachSeries(Documents, (e, callback) => {
        const formData = new FormData();
        formData.append('file', e.file);
        formData.append('Name', e.Name);
        formData.append('DocCategoryId', e.DocCategoryId);
        formData.append('Type', e.other);
        formData.append('RowId', POId);
        formData.append('ModuleId', e.ModuleId);
        fetch(`${Services.DOCUMENTS.path}/upload/`, {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(() => {
            callback();
          })
          .catch(error => {
            console.error('---->', error);
            callback();
          });
      }, () => {
           
      });
    }

    clearObject = (array) => {
      let keys = Object.keys(array);
      keys.forEach((e) => {
        if (array[e] === '') {
          delete array[e];
        }
      });
      return array;
    }

    validate = isDraft => event => {
      let { order, items, Documents, Form } = this.state;
      order = this.clearObject(order);
      let BODY = {
        headerDetails: order,
        items: items,
        Documents: Documents
      };
      ValidationService.validate({
        target: 'ORDER-CREATE',
        data: BODY
      }).then(() => {
        Form.CreateOrder.forEach((e) => {
          e.style = { color: '#616161' };
          e.error = '';
        });
        this.setState({
          Form
        },()=>{
          if (isDraft) this.setDraft();
          else this.saveOrder();
        });
           
      }).catch(errors => {
        let Element = document.getElementById(Object.keys(errors)[0]);
        if(Element){
          Element.scrollIntoView({ behavior: 'smooth' });
          Element.focus();
        }
        Form.CreateOrder.forEach((e) => {
          if (errors[e.id]) {
            e.style = { color: '#e57373' };
            e.error = errors[e.id].label;
          }
          else {
            e.error = '';
            e.style = { color: '#616161' };
          }
        });
        this.setState({ Form, errors, error: {
          open: true,
          message: 'There are some errors',
          type: 'warning'
        }});
      });
    }

    saveOrder = () => {
      let { order, items, isDraft } = this.state;
      order = this.clearObject(order);
      rest.EXEC({
        _function: 'PostOrderAllInOne', params: isDraft ? '?isDraft=true' : '', data: {
          headerDetails: order,
          items: items,
        }
      }, fetch).then(success => {
        if(success.POId){
          this.sendDocuments(success.POId);
          swal('Good job!', !isDraft ? 'Your purchase order has been saved correctly' : 'Your purchase order has been saved correctly as a draft', 'success').then(() => {
            this.props.history.push('/order-list');
          });
        }
      }).catch(error => {
        swal('Error', error.label ? error.label  : 'INTERNAL DB ERROR', 'error');
      });
    }

    setDraft = () => {
      let { order } = this.state;
      order.StatusId = 1;
      this.setState({ order, isDraft: true }, () => { this.saveOrder();});
    }

    render() {
      return (
        <ContentWrapper>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-dolly-flatbed fa-1x mr-2"></em>
                        Create a New order
            </div>
          </div>
          {/* CONSIGNEE DETAILS */}
          <Card>
            <CardBody className="myCard">
              <ExpansionPanel elevation={0} defaultExpanded >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="row" style={{ width: '100%' }}>
                    <div className="col-md-4">
                      <Suppliers sendProps={this.recieveProps} selected={this.state.selected} errors={this.state.errors} ></Suppliers>
                    </div>
                    <div className="col-md-4">
                      <Consignees sendProps={this.recieveProps} selected={this.state.selected} errors={this.state.errors} ></Consignees>
                    </div>
                    <div className="col-md-4">
                      <Deliveries sendProps={this.recieveProps} selected={this.state.selected} errors={this.state.errors}></Deliveries>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </CardBody>
          </Card>
                
          {/* HEADER DETAILS */}
          <HeaderDetails open={this.state.open} sendOrderField={this.setFieldToOrder} Form={this.state.Form} ></HeaderDetails>
          {/* EXTRA FIELDS */}
          <ExtraFields></ExtraFields>
          {/* LINE ITEMS */}
          <ViewOrder sendItems={this.recieveItems} errors={this.state.errors}></ViewOrder>
          {/* DOCUMENTS UPLOAD */}
          <DropZoneComponent sendDocuments={this.recieveDocuments} ></DropZoneComponent>
          <div className="row">
            <div className="col-md-6 col-lg-8 col-sm-12"></div>
            <div className="col-md-3 col-lg-2 col-sm-6">
              <Button variant="contained" color="success" size="lg" className="button" onClick={this.validate(true)} >
                            Save as Draft
              </Button>
            </div>
            <div className="col-md-3 col-lg-1 col-sm-6">
              <Button variant="contained" color="primary" className="button" onClick={this.validate(false)} size="lg">
                            Save and complete
              </Button>
            </div>
          </div>
          <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
        </ContentWrapper>
      );
    }
}
export default withRouter(FormExtended);