import React, { useCallback, useState, useEffect } from 'react';
import Axios from 'axios';
import { Card, Icon, Placeholder } from 'semantic-ui-react';
import { Col, Label } from 'reactstrap';
import { Timeline, Skeleton } from 'antd';
import moment from 'moment';
import NoEdiEvents from './noEdiEvents';
import { Services } from '../../../../../Services';

const EdiMockup = ({mockUpEdi}) => {
  return (
    <Timeline mode={'left'}>
      {mockUpEdi.map((e, index) => {
        return (
          <Timeline.Item key={index} color="#00558c" label={<Placeholder.Line length="short" />}>
            <Card style={{ width: '300px' }}>
              <Card.Content>
                <Skeleton active />
              </Card.Content>
            </Card>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

function EdiEvents(props) {
  let containerReference = props.container.InternalReference;
  const [allEdiEvents, setAllEdiEvents] = useState([]);
  const [hideContent, setHideContent] = useState(false);
  const [selectCard, setSelectCard] = useState('');
  const [ediError, setEdiError] = useState(false);
  const [ediResults, setEdiResults] = useState(false);
  const mockUpEdi = [1, 2, 3];

  const handleEvent = (index) => {
    setSelectCard(index);
    if(index === selectCard){
      setHideContent(prevState => !prevState);
    }else{
      setHideContent(true);
    }
  };

  const getEdiEvent = useCallback(async () => {
    try {
      let getAllDataEvents = await Axios.get(
        `${Services.GET_ONE_SHIPMENT.path}/shipment/edi/${containerReference}`
      );

      setAllEdiEvents(getAllDataEvents.data.message);
      setEdiResults(true);
    } catch (error) {
      console.error('Error en eventos edi', error);
      setEdiResults(true);
      setEdiError(true);
    }
  }, [containerReference]);

  useEffect(() => {
    getEdiEvent();
  }, [getEdiEvent]);

  const ediEvents = (allEvents) => {
    return (
      <Timeline mode={'left'}>
        {allEvents.map((e, index) => {
          return (
            <Timeline.Item color="#00558c"
              style={{ fontSize: '14px' }}
              label={moment(e.EVENT_DATETIME).format('YYYY-MM-DD HH:mm:ss')}
            >
              <Card style={{ width: '300px' }}>
                <Card.Content>
                  <Card.Meta>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-10">
                            <Label className='labelMainTitle' style={{color: '#00558C'}}>
                              {e.EVENT_D}
                            </Label>
                          </div>
                          <div className="col-1">
                            <Icon
                              name={
                                (selectCard === index) && hideContent
                                  ? 'chevron up'
                                  : 'chevron down'
                              }
                              onClick={() => handleEvent(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Meta>
                  {hideContent && (selectCard === index)? (
                      <>
                        <Card.Description>
                          <Label className='labelTitle'>Location Name:</Label>
                        </Card.Description>
                        <Card.Description>
                          <Label className='labelContent'>{e.LOCATION_NAME}</Label>
                        </Card.Description>
                        <Card.Description>
                          <Label className='labelTitle'>Location:</Label>
                        </Card.Description>
                        <Card.Description >
                          <Label className='labelContent'>{e.LOCATION}</Label>
                        </Card.Description>
                        <Card.Description>
                          <Label className='labelTitle'>Provider:</Label>
                        </Card.Description>
                        <Card.Description >
                          <Label className='labelContent'>{e.PROVIDER}</Label>
                        </Card.Description>
                        <Card.Description>
                          <Label className='labelTitle'>Vessel Name:</Label>
                        </Card.Description>
                        <Card.Description >
                          <Label className='labelContent'>{e.VESSEL_NAME}</Label>
                        </Card.Description>
                        <Card.Description>
                          <Label className='labelTitle'>Voyage No.:</Label>
                        </Card.Description>
                        <Card.Description >
                          <Label className='labelContent'>{e.VOYAGE_NUMBER}</Label>
                        </Card.Description>
                      </>
                  ) : (
                    ''
                  )}
                </Card.Content>
              </Card>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  return (
    <>
      <div className="row">
        <Col md={12}>
          {!ediResults ? (
            // ediMockup()
            <EdiMockup mockUpEdi={mockUpEdi ? mockUpEdi: []}/>
          ) : !allEdiEvents.length || ediError ? (
            <>
              <div className="col-12">
                <center>
                  <NoEdiEvents />
                </center>
              </div>
              <div style={{ height: 50 }} />
            </>
          ) : (
            ediEvents(allEdiEvents)
          )}
        </Col>
      </div>
    </>
  );
}

export default EdiEvents;
