import React, { Component } from 'react';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import ReactDOM from 'react-dom';
import { Card, CardBody } from 'reactstrap';
import { Map, Marker, InfoWindow, GoogleApiWrapper, Polyline } from 'google-maps-react';
// import { Progress } from 'reactstrap';
import createHistory from 'history/createBrowserHistory';
import { withRouter } from 'react-router-dom';
// import policeIcon from '../../../../resources/icons/policeman2.png';
import { Link } from 'react-router-dom';
import './styles/style.scss';

const status_ = {
  1: <img alt="" src="https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png" width="30"></img>,
  2: <img alt="" src="https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png" width="30"></img>,
  3: <img alt="" src='https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png' width="30"></img>,
  4: <em className="fa-1x fas fa-plane mr-2"></em>,
  5: <em className="fa-1x fas fa-plane mr-2"></em>,
  6: <em className="fa-1x fas fa-plane mr-2"></em>,
  7: <em className="fa-1x fas fa-plane mr-2"></em>
};

if (!window.AppHistory) {
  window.AppHistory = createHistory();
}

class InfoWindowEx extends Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement('div');
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.children !== prevProps.children) {
      ReactDOM.render(
        React.Children.only(this.props.children),
        this.contentElement
      );
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Size: props.heidIcon ? props.heidIcon : 30,
      activeMarker: {},
      dataRecieve: props.dataDrawer,
      tags: '',
      dataOptions: props.dataOptions ? props.dataOptions : {},
      markerInfo: {},
      polylineDefault: '',
      polyline: props.polyline ? props.polyline : [],
      marker: props.marker,
      type: props.type,
      showingInfoWindow: false,
      showDrawerInfo: false
    };
    this.buttonRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      marker: this.props.marker
    }, () => {
      this.buildTags();
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      marker: nextProps.marker
    }, () => {
      this.setState({ polyline: nextProps.polyline }, () => this.buildTags());
    });
    this.setState({
      dataRecieve: nextProps.dataDrawer,
      dataOptions: nextProps.dataOptions
    }, () => {
      let sendData = {
        data: nextProps.dataDrawer
      };
      this.onMarkerClick(sendData, null, null);
    });
  }

  onMarkerClick = (props, marker, e) => {
    switch (this.state.type) {
      case 'info':
        if(marker != null && e !== null){
          this.setState({ showingInfoWindow: false }, () => { this.setState({ showingInfoWindow: true, activeMarker: props.data, markerInfo: marker }); });
        }
        break;
      case 'drawer':
        this.setState({ showDrawerInfo: false }, () => { this.setState({ showDrawerInfo: true, activeMarker: props.data }); });
        break;
      case 'polyline':
        if (props.data) {
          if (props.data.OrigenLatitude && props.data.DestinoLatitude) {
            let polyline = [
              { lat: parseFloat(props.data.OrigenLatitude), lng: parseFloat(props.data.OrigenLongitude) },
              { lat: parseFloat(props.data.DestinoLatitude), lng: parseFloat(props.data.DestinoLongitude) }
            ];
            this.setState({ polyline: polyline });

          }
        }
        break;
      case 'polylineDrawer':
        if (props.data) {
          if (props.data.OrigenLatitude && props.data.DestinoLatitude) {
            let polyline = [
              { lat: parseFloat(props.data.OrigenLatitude), lng: parseFloat(props.data.OrigenLongitude) },
              { lat: parseFloat(props.data.DestinoLatitude), lng: parseFloat(props.data.DestinoLongitude) }
            ];
            this.setState({ polyline: polyline });
          }
          this.setState({ showDrawerInfo: false }, () => { this.setState({ showDrawerInfo: true, activeMarker: props.data }); });
        }
        break;
      default:

    }
  }

  onInfoWindowClose = () => {
    this.setState({
      showingInfoWindow: false
    });
  }

  buildTags = () => {
    let { marker, type } = this.state;
    let tags = [];
    let polylineDefault = [];
    marker.map((e) => {
      tags.push(
        <Marker
          key={e.key}
          onClick={this.onMarkerClick}
          title={e.title}
          name={e.name}
          info={e}
          data={e.data}
          position={e.position}
          icon={{
            url: e.icon.url,
            anchor: new window.google.maps.Point(e.anchor ? e.anchor : 10, e.amchor ? e.anchor : 10),///***************PARA AJUTAR EL POLYLINE */
            scaledSize: new window.google.maps.Size(this.state.Size, this.state.Size)
          }}
        />
      );
      return e;
    });
    var lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 3
    };
    if (type === 'polyline') {
      polylineDefault.push(
        <Polyline
          key={Math.random()}
          path={this.props.data ? this.props.data : []}
          icons={[{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px'
          }]}
          strokeOpacity={0} />
      );
    }
    if (type === 'polylineDrawer') {
      polylineDefault.push(
        <Polyline
          key={Math.random()}
          path={this.props.data ? this.props.data : []}
          icons={[{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px'
          }]}
          strokeOpacity={0} />
      );
    }
    this.setState({ tags, polylineDefault });
  }

  redirectTo = (event) => {
    this.props.history.push(this.state.dataOptions.config.link);
  }

  render() {
    let { activeMarker, markerInfo, showDrawerInfo, showingInfoWindow, polyline, polylineDefault } = this.state;
    let dataDrawer = activeMarker;
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    const info = [
      {
        data: '',
        img: true,
        imgSrc:'https://fsposeidon.blob.core.windows.net/poseidonpic/2923815_factory_stock_warehouse_icon_yellow (1).png',
        title: 'Warehouse:',
        value: activeMarker.Code
      },
      {
        data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: 'rgb(60, 172, 244)' }}></em>),
        img: false,
        title: 'Receipts:',
        value: activeMarker.TotalReceipts
      },
      {
        data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-left" style={{ color: '#21BA45' }}></em>),
        img: false,
        title: 'Shipments:',
        value: activeMarker.TotalShipments
      },
      {
        data: '',
        img: true,
        imgSrc:'https://fsposeidon.blob.core.windows.net/poseidonpic/134062_truck_lorry_icon.png',
        title: 'Trucking:',
        value: activeMarker.TotalTruckings
      }
    ];

    var dataOptions = this.state.dataOptions ? this.state.dataOptions.data : '';
    if (dataOptions && markerInfo) {
      dataOptions.map(e => {
        if (markerInfo.info) {
          e.value = markerInfo.info[e.key];
        }
        return e;
      });
    }
    let dataInfo = this.state.dataOptions ? (this.state.dataOptions.data ? this.state.dataOptions.data : info) : info;
    // let haveButton = this.state.dataOptions ? (this.state.dataOptions.config ? this.state.dataOptions.config.button : false ) : false;
    return (
      <>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            vertical
            visible={showDrawerInfo}
            width='wide'
            direction={'right'}
          >
            <Menu.Item as='a' onClick={() => { this.setState({ showDrawerInfo: false }); }}>
              Cerrar
            </Menu.Item>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-hover mb-mails">
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="mb-mail-meta">
                                    {status_[dataDrawer.ModoTransporte === 'MARITIMA' ? 1 : 2]}
                                    <span>
                                      {dataDrawer.Estatus ? dataDrawer.Estatus : ''}
                                    </span>
                                    <br />
                                    <Link to={'/shipmentsDetal/' + dataDrawer.EmbarqueId} style={{ textDecoration: 'none' }}>
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.DoEmbarque}
                                      </span>
                                    </Link>-
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.HBL_HAWB}
                                    </span>
                                    <br />
                                    {/* <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.HBL_HAWB} 
                                    </span> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  {/* <em style={{ color: '#0097dc', paddingTop: '5%' }} className="fas fa-warehouse mr-3"></em> */}
                                  <img style={{ padding: '0px' }} alt="" src="https://fsposeidon.blob.core.windows.net/poseidonpic/3144031_crates_storage_warehouse_icon.png" height="25" width="25"></img>
                                  <div className="mb-mail-meta" >
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      <a href > Fecha De Carga Lista: {dataDrawer.CargoReady}</a>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  {/* <em style={{ color: '#0097dc', paddingTop: '8%' }} className="fas fa-truck-moving mr-3"></em> */}
                                  <div><img style={{ padding: '0px' }} alt="" src="https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png" height="30" width="30"></img></div>
                                  <div style={{ marginLeft: '6px' }} className="mb-mail-meta">
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.Delivery ? dataDrawer.Delivery : ''}
                                    </span>
                                    <br />
                                    {/* <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.Supplier ? dataDrawer.Supplier.address : ''}
                                    </span>
                                    <br />
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.Supplier ? dataDrawer.Supplier.pickupDate : ''}
                                    </span> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <em style={{ color: '#001489', paddingTop: '10%' }} className="fas fa-long-arrow-alt-down"></em>
                                  <em style={{ color: '#001489', paddingTop: '10%' }} className="fas fa-long-arrow-alt-down mr-3"></em>
                                  <div className="mb-mail-meta">
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {`ETD: ${dataDrawer.ETD}`}
                                    </span>
                                    <br />
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {`ATD: ${dataDrawer.ATD}`}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <em style={{ color: '#001489', paddingTop: '10%' }} className="fas fa-long-arrow-alt-up"></em>
                                  <em style={{ color: '#001489', paddingTop: '10%' }} className="fas fa-long-arrow-alt-up mr-3"></em>
                                  <div className="mb-mail-meta">
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {`ETA: ${dataDrawer.ETA}`}
                                    </span>
                                    <br></br>
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {`ATA: ${dataDrawer.ATA}`}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <div className="d-flex">
                                  <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-truck mr-2"></em>
                                  <div className="mb-mail-meta">
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.Delivery ? dataDrawer.Delivery.name : ''}
                                    </span>
                                    <br></br>
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.Delivery ? dataDrawer.Delivery.address : ''}
                                    </span>
                                    <br></br>
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      {dataDrawer.Delivery ? dataDrawer.Delivery.pickupDate : ''}
                                    </span>
                                    <br></br>
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                      Actual Delivery:
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-hover mb-mails">
                          <tbody>
                            <tr>
                              <td>
                                {/* <div className="d-flex">
                                  <div className="mb-mail-meta">
                                    {<span style={{ color: 'green' }}>A tiempo</span>}
                                    <div className="mb-mail-subject">
                                      <Progress color={'success'} value={dataDrawer.LastUpdate ? dataDrawer.LastUpdate.porcent : '60'} />
                                    </div>
                                    <span style={{ fontWeight: '', fontSize: '10px' }}>
                                      {`Última actualización: ${dataDrawer.LastUpdate ? dataDrawer.LastUpdate.date : ''}`}
                                    </span>
                                  </div>
                                </div> */}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div style={{ textAlign: 'left' }} className="d-flex">
                                  <div className="mb-mail-meta">
                                    <em style={{ color: '#001489' }} className="fas fa-map-marker-alt mr-2"></em>
                                  </div>
                                  <div className="mb-mail-meta">
                                    <div className="mb-mail-from">
                                      <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Origen</span>
                                    </div>
                                    <span style={{ fontWeight: '', fontSize: '10px' }}>
                                      {dataDrawer.OrigenNombre ? dataDrawer.OrigenNombre : ''}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div style={{ textAlign: 'left' }} className="d-flex">
                                  <div className="mb-mail-meta">
                                    <em style={{ color: '#001489' }} className="fas fa-map-marker-alt mr-2"></em>
                                  </div>
                                  <div className="mb-mail-meta">
                                    <div className="mb-mail-from">
                                      <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        Destino
                                      </span>
                                    </div>
                                    <span style={{ fontWeight: '', fontSize: '10px' }}>
                                      {dataDrawer.DestinoNombre ? dataDrawer.DestinoNombre : ''}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="mb-mail-meta">
                                    <em className="icon-compass mr-2" style={{ paddingTop: '10%', color: '#001489' }}></em>
                                  </div>
                                  <div className="mb-mail-meta">
                                    <span style={{ fontWeight: '', fontSize: '10px' }}>
                                      {dataDrawer.Carrier ? dataDrawer.Carrier : ''}
                                    </span>
                                    <br></br>
                                    {/* <span style={{ fontWeight: '', fontSize: '10px' }}>
                                      {dataDrawer.Carrier ? dataDrawer.Carrier.Latitude : ''}
                                    </span>
                                    <br></br>
                                    <span style={{ fontWeight: '', fontSize: '10px' }}>
                                      {dataDrawer.Carrier ? dataDrawer.Carrier.Longitude : ''}
                                    </span> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="mb-mail-meta">
                                    {dataDrawer.ModoTransporte === 'MARITIMA' ? <img alt="img" src="https://poseidonwebapp.firebaseapp.com/static/media/cruise.bf26de23.png" width="19" height="19" className="mr-2" ></img> : <></>}
                                  </div>
                                  <div className="mb-mail-meta" style={{ paddingTop: '7%' }}>
                                    <div className="mb-mail-from">
                                      <span>{dataDrawer.NoMotonaveVuelo ? dataDrawer.NoMotonaveVuelo : ''}</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button onClick={() => { this.setState({ showDrawerInfo: false }); this.props.history.push('/shipmentsDetal/' + activeMarker.EmbarqueId); }} style={{ fontSize: '14px', margin: 10, borderRadius: 25, width: 124, height: 32, backgroundColor: '#5d9cec', color: 'white' }}>Ver Detalle</button>
              </div>
            </Card>
            {
              // <div>
              //   <div onClick={() => {this.setState({showDrawerInfo: false})}} className="col-12" style={{borderBottom: '1px solid #D1D1D1', textAlign: 'center'}}>
              //     <button onClick={() => {this.setState({showDrawerInfo: false})}} style={{margin: 10}}>Cerrar</button>
              //   </div>
              //   <div style={{margin: 15}}>
              //     <div className="row">
              //       <div className="col-6" style={{fontSize: 12, fontWeight: 'bold'}}>
              //         <em className="fa-2x fas fa-ship mr-1"></em>
              //         <span style={{color: '#00C5FF'}}>DO ID</span> {activeMarker.BookingNumber}
              //       </div>
              //       <div className="col-6">
              //         <div style={{textAlign: 'center'}}>
              //           <span style={{fontSize: 12, fontWeight: 'bold', color: '#00BF3A'}}>A tiempo</span>
              //           <Progress color='success' value={80} />
              //           <span style={{fontSize: 10, fontWeight: 'bold'}}>Ultima actualizacion <br/>12/08/19</span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="row">
              //       <div className="col-6 noRight">
              //         <div className="row col-12 noRight" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em style={{color: '#00C5FF'}} className="fa-2x fas fa-home mr-1"></em>
              //           <span className="drawerSpan">FECHA DE <br/>CARGA LISTA</span>
              //         </div>
              //       </div>
              //       <div className="col-6">
              //         <div className="row" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em className="fa-2x mr-2 fas fa-map-marker-alt"></em>
              //           <span className="drawerSpan">Desde<br/><span style={{fontWeight: 'normal'}}>CNOZ</span></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div style={{height: 20}} />
              //     <div className="row">
              //       <div className="col-6 noRight">
              //         <div className="row col-12 noRight" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em style={{color: '#00C5FF'}} className="fa-2x fas fa-truck mr-1"></em>
              //           <span className="drawerSpan">INFORMACIÓN <br/>DEL DELIVERY</span>
              //         </div>
              //       </div>
              //       <div className="col-6">
              //         <div className="row" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em className="fa-2x mr-2 fas fa-map-marker-alt"></em>
              //           <span className="drawerSpan">A<br/><span style={{fontWeight: 'normal'}}>ECMEC</span></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div style={{height: 20}} />
              //     <div className="row">
              //       <div className="col-6 noRight">
              //         <div className="row col-12 noRight" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em style={{color: '#00C5FF'}} className="fa-2x fas fa-long-arrow-up mr-1"></em>
              //           <em style={{color: '#00C5FF'}} className="fa-2x mr-2 fas fa-sort-amount-up"></em>
              //           <span style={{marginTop: -5}}>
              //             <span className="drawerSpan">ETD: <span style={{fontWeight: 'bold'}}>{"03-07-2019"}</span></span><br/>
              //             <span className="drawerSpan">ATD: <span style={{fontWeight: 'bold'}}>{"03-07-2019"}</span></span>
              //           </span>
              //         </div>
              //       </div>
              //       <div className="col-6">
              //         <div className="row" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em className="fa-2x mr-2 fab fa-safari"></em>
              //           <span className="drawerSpan"> <br/><span style={{fontWeight: 'normal'}}>.</span></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div style={{height: 20}} />
              //     <div className="row">
              //       <div className="col-6 noRight">
              //         <div className="row col-12 noRight" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <em style={{color: '#00C5FF'}} className="fa-2x fas fa-long-arrow-up mr-1"></em>
              //           <em style={{color: '#00C5FF'}} className="fa-2x mr-2 fas fa-sort-amount-up"></em>
              //           <span style={{marginTop: -5}}>
              //             <span className="drawerSpan">ETA: <span style={{fontWeight: 'bold'}}>{"03-07-2019"}</span></span><br/>
              //             <span className="drawerSpan">ATA: <span style={{fontWeight: 'bold'}}>{"03-07-2019"}</span></span>
              //           </span>
              //         </div>
              //       </div>
              //       <div className="col-6">
              //         <div className="row" style={{fontSize: 12, fontWeight: 'bold'}}>
              //           <img alt="img" src="https://poseidonwebapp.firebaseapp.com/static/media/cruise.bf26de23.png" width="23" height="23" class="mr-2" />
              //           <span className=""><span style={{fontWeight: 'normal'}}>MOTONAVE</span></span>
              //         </div>
              //       </div>
              //     </div>
              //     <br></br>
              //     <div className="col-12" style={{textAlign: 'center'}}>
              //       <button onClick={() => {this.setState({showDrawerInfo: false}); this.props.history.push("/shipmentsDetal/" + activeMarker.BookingNumber)}} style={{margin: 10, borderRadius: 25, width: 150, height: 50, backgroundColor: '#00C5FF', color: 'white'}}>Ver Detalle</button>
              //     </div>
              //   </div>
              // </div>
            }
          </Sidebar>
          <Sidebar.Pusher>
            <Segment>
              <div style={{ position: 'relative', height: this.props.maxHeight ? this.props.maxHeight : '500px' }}>
                <Map 
                disableDefaultUI={true}  google={this.props.google} zoom={this.props.zoom ? this.props.zoom : 2}
                  initialCenter={this.props.position ? this.props.position : {
                    lat: 4.0000000,
                    lng: -72.0000000
                  }}>
                  {this.state.tags}
                  <InfoWindowEx visible={showingInfoWindow} onClose={this.onInfoWindowClose} marker={markerInfo}>
                    <div style={{fontSize:'10px', minWidth: 150 }}>
                      {
                        dataInfo.map((f, index) => {
                          return (
                            <div key={index} className="row" style={{ marginTop: index === 0 ? 0 : 10 }}>
                              <div className="col-1">
                                {f.img ? <img alt="img" src={f.imgSrc} width="25" height="25" className="mr-2" ></img> : f.data}
                              </div>
                              <div className="col-5" style={{ marginLeft: 5, fontWeight: 'bold', marginTop: 7 }}>
                                {f.title}
                              </div>
                              <div className="col-5" style={{ textAlign: 'center',marginTop:9, fontSize:'11px'}}>
                                {f.value}
                              </div>
                            </div>
                          );
                        })
                      }
                      {/* {
                        haveButton ? (
                          <div className="col-12" style={{textAlign: 'center', margin: 20}}>
                            <button ref={this.buttonRef} onClick={() =>{
                              this.redirectTo(this)}} style={{background: '#2196F3',color: 'white', width: '60%', borderRadius: 50, height: 30, marginRight: 20}}>
                              {this.state.dataOptions.config.textButton}
                            </button>
                          </div>
                        ) : (<></>)
                      } */}
                    </div>
                  </InfoWindowEx>
                  {polylineDefault}
                  <Polyline
                    path={polyline}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2} />
                </Map>
              </div>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAl_10ZKSqnrviAV-KSQ6c8i4Sa_gEhEmA',
  v: '3.30'
})(withRouter(MapContainer));
