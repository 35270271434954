import React, { Component } from 'react'
import { Table, Progress, Card, CardBody } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import { Pagination} from 'semantic-ui-react';
import IMAGENDRI from "./file"

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            rows: [],
            totalInvoices: 0,
            totalInvoicesAmount: 0,
            pages: 0,
            activePage: 0,
            tableData: []
        }
    }
    componentDidMount = () => {
        let { data } = this.props
        this.setState({
            data
        }, () => {
            let pages = (data.length / 10) + 1;
            let tableData = this.partirArray(1,data,pages)
            this.setState({
                activePage : 1,
                pages: parseInt(pages),
                tableData 
            },()=>{
                this.buildTable()
            })
        })
    }


    partirArray = (page, all,pages)=>{
        let array = []
        if (page === 1) 
            for (let i = 0; i < (all.length < 10 ? all.length : 10) ; i++)
                array.push(all[i])
        else if (page !== 1 && page !== pages) 
            for (let i = (page - 1) * 10; i < (page) * 10; i++)
                array.push(all[i])
        else 
            for (let i = (page - 1) * 10; i < all.length; i++)
                array.push(all[i])
        return array
    }


    handlePaginationChange = (e, { activePage }) => {
        let {data,pages} = this.state
        let tableData = this.partirArray(activePage,data,pages)
        this.setState({ activePage, tableData  }, () => { this.buildTable(tableData) })
    }

    buildTable = () => {
        let { tableData   } = this.state
        let rows = tableData.map((e) => {  
            return (
                <tr key={e.invoice}>
                    <td><Fade left opposite> <a href="#">{e.cruise}</a></Fade></td>
                    <td><Fade left opposite>{e.period}</Fade></td>
                    <td><Fade left opposite>{`$ ${parseInt(e.poAmount).toLocaleString('en-US')}.00`}</Fade></td>
                    <td><Fade left opposite>{`$ ${parseInt(e.logisticCost).toLocaleString('en-US')}.00`}</Fade></td>
                    <td><Fade left opposite>{`$ ${parseInt(e.total).toLocaleString('en-US')}.00`}</Fade></td>
                    <td><Fade left opposite>{`$ ${parseInt(e.budget).toLocaleString('en-US')}.00`}</Fade></td>
                    <td><Fade left opposite><span style={{ color: e.color }}>{`$ ${parseInt(e.derivation).toLocaleString('en-US')}.00`}</span></Fade></td>
                    <td><Fade left opposite><span style={{ color: e.color }}>{`${e.percentage} %`}</span></Fade></td>
                    <td><Fade left opposite><Progress color={parseInt(e.dryDrockProgress < 50) ? 'danger' : "success"} value={e.dryDrockProgress} /></Fade></td>
                </tr>
            )
        })
        this.setState({
            rows
        })

    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        }, () => {
            let pages = nextProps.data.length > 10 ? (nextProps.data.length / 10) + 1 : 1;
            let tableData = this.partirArray(1,nextProps.data,pages)
            this.setState({
                activePage : 1,
                pages: parseInt(pages),
                tableData 
            },()=>{
                this.buildTable()
            })
        })
    }
    render() {
        return (
            <Card>
                <CardBody>
                    {/* <div className="row"> */}
                    <div className="row"> 
                    <img alt="dri-ui" className="imgggggg1" src={IMAGENDRI} />                 
                    <span ><h4 className="mb-3 py-2">Dry Drock Performance</h4></span>
                    </div>
                        <div className="col-12">
                            <Table striped hover responsive>
                                <thead className="titleTable">
                                    <tr>
                                        <th>Cruise</th>
                                        <th>Period</th>
                                        <th>PO Amount</th>
                                        <th>Logistic Cost</th>
                                        <th>Total</th>
                                        <th>Budget</th>
                                        <th>Deviation</th>
                                        <th>Percentage</th>
                                        <th>Dry Drock Progress</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.rows}
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12">
                    <center><Pagination
                                ellipsisItem={null}
                                activePage={this.state.activePage}
                                onPageChange={this.handlePaginationChange}
                                totalPages={this.state.pages}>
                            </Pagination></center>
                    </div>
                        <div className="col-12">
                            <hr></hr>
                        </div>
                    {/* </div> */}
                </CardBody>
            </Card>

        )
    }
}

export default DataTable;