import React, {Fragment} from 'react';
import propTypes from 'prop-types';
import {Search} from 'semantic-ui-react';
import Searcher from './../../utils/Searcher/';
const SearchOnTable = new Searcher();

function init({initialSearch, data}){
  return { searchText: initialSearch, data: data};
}

function reducer(state, action){
  switch(action.type){
  case 'search':
    if(action.payload.searchText !== ''){
      const dataFiltered = SearchOnTable.search(action.payload.data, action.payload.searchText, action.payload.config);
      action.payload.handler(dataFiltered);
      return {...{searcherText: action.payload.searchText, data: dataFiltered}};
    } else {
      action.payload.handler(action.payload.data);
      return {...{searcherText: '', data: action.payload.data}};
    }
  case 'reset':
    return init(...action.payload);
  default:
    return state;
  }
}


function SearchComponent(props){
  const [state, dispatch] = React.useReducer(reducer, {initialSearch: '', data: props.data}, init);

  function onSearch(evt, data){
    dispatch({type: 'search', payload: {data: props.data, searchText: data.value, handler: props.handler, config: props.configSearch}});
  }
  return (
    <Fragment>
      <Search
        style={{marginBottom: '.5em', width: props.width}}
        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
        placeholder="..."
        open={false}
        name="search-input-table"
        id="search-input-table"
        onSearchChange={onSearch}
      />
    </Fragment>
  );
}

SearchComponent.propTypes = {
  data: propTypes.array,
  handler: propTypes.func,
  width: propTypes.string
};

SearchComponent.defaultProps = {
  data: [],
  handler: () => {},
  width: '30%'
};

export default SearchComponent;
