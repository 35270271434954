import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from 'reactstrap';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import Errors from '../../../../../template/Errors';
import Icon from '@material-ui/core/Icon';
import Tables from '../../../../../template/Table';
import Config from '../../models/Config';
import Form from '../../../../../../models/FormData';
import restService from '../../../../../../services/restService';
import PACKASGESTYPES from './models/packages.types';
import { Card, CardBody } from 'reactstrap';

const rest = new restService();

const UNITSOFWEIGHT = {
  't': 'Tons',
  'kg': 'Kilograms',
  'lb': 'Pounds',
  'g': 'Grams',
  'cg': 'Centigrams',
  'mg': 'Milligrams',
  'oz': 'Ounces',
};

const UNITSOFDIMENSION = {
  'm': 'Meters',
  'dm': 'Decimeters',
  'cm': 'Centimeters',
  'mm': 'Milimeters',
  'in': 'Inches',
  'yd': 'Yards',
  'ft': 'Foots'
};

const UNITSOFVOLUME = {
  'cbm':	'Cubic meters',
  'cbd'	:'Cubic decimeters',
  'cbc'	:'Cubic centimeters',
  'l'	:'Pounds',
  'dl':	'Deciliters',
  'cl':	'Centilites',
  'ml'	:'Mililiters',
  'cin'	:'Cubic inches',
  'cft'	:'Cubic foots',
  'cby'	:'Cubic yards',
  'gal'	:'Gallons',
};
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CreateBookingFormData: '',
      columns: [
        { label: 'Count Of', key: 'CountPackages', },
        {
          label: 'Package Type', key: 'PackageTypeCode', custom: (value) => {
            return PACKASGESTYPES[value.PackageTypeCode] ? PACKASGESTYPES[value.PackageTypeCode] : value.PackageTypeCode;
          }
        },
        { label: 'Weight', key: 'Weight' },
        {
          label: 'Unit Of Weight', key: 'UnitWeightCode', custom: (value) => {
            return UNITSOFWEIGHT[value.UnitWeightCode] ? UNITSOFWEIGHT[value.UnitWeightCode] : value.UnitWeightCode;
          }
        },
        { label: 'Volume', key: 'Length' },
        {
          label: 'Unit Of Volume	', key: 'UnitVolumeCode', custom: (value) => {
            return UNITSOFVOLUME[value.UnitVolumeCode] ? UNITSOFVOLUME[value.UnitVolumeCode] : value.UnitVolumeCode;
          }
        },
        { label: 'Dimensions', key: 'Dimensions' },
        {
          label: 'Unit Of Dimensions	', key: 'UnitDimensionCode', custom: (value) => {
            return UNITSOFDIMENSION[value.UnitDimensionCode] ? UNITSOFDIMENSION[value.UnitDimensionCode] : value.UnitDimensionCode;
          }
        },
        { label: 'Actions', key: '', custom: (value) => this.custom1(value) }
      ],
      Form: Form,
      selects: Config.selectsAdditional,
      CreatePackage: '',
      _package: {
        CountPackages: '',
        PackageTypeCode: '',
        Weight: '',
        UnitWeightCode: '',
        Volume: '',
        UnitVolumeCode: '',
        Length: '',
        Width: '',
        Height: '',
        UnitDimensionCode: '',
        Dimensions: ''
      },
      error: {
        open: false,
        message: '',
        type: 'error'
      },
      packages: [],
      packageLines: ''
    };
  }

    custom1 = (value) => (
      <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value)}>
        <Icon className="icon" style={{ fontSize: 20 }}>
                remove
        </Icon>
      </Button>
    )
    componentDidMount = () => this.getAllCatalogs()

    getAllCatalogs = () =>
      rest.EXEC({ _function: 'GetAllCatalogsBooking', params: '' }, fetch).then(data => {
        this.setState({ selects: data.data }, () => {
          this.buildFormContainers();
        });
      }).catch(error => {

      })

    handleChange = name => event => {
      let _package = this.state._package;
      if (name === 'CountPackages') {
        try {
          let number = parseInt(event.target.value);
          if (number > 0)
            _package[name] = number;
        }
        catch (e) {
          console.error(e);
        }
      }
      else {
        _package[name] = event.target.value;
      }
      this.setState({ _package }, () => this.buildFormContainers());
    }
    handleChangeSelect = name => event => {
      let _package = this.state._package;
      if (event && event.value) {
        _package[name] = event.value;
        this.setState({ _package }, () => { this.buildFormContainers(); });
      }
      else {
        _package[name] = '';
        this.setState({ _package }, () => { this.buildFormContainers(); });
      }
    }

    buildFormContainers = () => {
      const { Form, _package, selects } = this.state;
      let CreatePackage = Form.CreatePackage.map((e, i) => {
        if (e.type === 'Input') return (
          <div className={e.class} key={e.id}>
            <span id="containersOpackages"> <label>{e.label} {e.required === true ? <span>*</span> : ''}</label></span>
            <Input
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              value={_package[e.id]}
              name={e.id}
              max={e.max}
              onChange={this.handleChange(e.id)}
            />
          </div>
        );
        else if (e.type === 'Select') return (
          <div className={e.class} key={e.id} >
            <span id={e.label}> <label >{e.label} {e.required === true ? <span>*</span> : ''}</label></span>
            <Select
              id={e.label}
              name={e.name}
              value={_package[e.id]}
              onChange={this.handleChangeSelect(e.id)} options={selects[e.options]}
            />
          </div>
        );
        else return '';
      });
      this.setState({ CreatePackage });
    }


    deleteLine = index => event => {
      let { packages } = this.state;
      packages.splice(index, 1);
      this.setState({
        packages,
      }, () => {
        this.buildFormContainers();
        this.buildContainerLines();
        this.setState({
          error: {
            message: 'Package Removed',
            open: true,
            type: 'warning'
          }
        });
      });

    }

    addLine = () => {
      let { _package, packages } = this.state;
      this.state._package.Dimensions = this.state._package.Length + '*' + this.state._package.Width + '*' + this.state._package.Height;

      let keys = ['PackageTypeCode', 'Weight', 'UnitWeightCode', 'Volume', 'UnitVolumeCode', 'Length', 'Width', 'Height', 'UnitDimensionCode', 'Dimensions'];
      let errors = '';
      keys.forEach((e) => {
        if (!_package[e])
          errors += e + ', ';
      });

      if (errors.length > 0)
        this.setState({
          error: {
            message: 'Some fileds are required to add new document: ( ' + errors + ' )',
            open: true,
            type: 'warning'
          }
        });
      else {
        this.setState({
          _package: {
            PackageId: '',
            PackageTypeCode: '',
            Weight: '',
            UnitWeightCode: '',
            Volume: '',
            UnitVolumeCode: '',
            Length: '',
            Width: '',
            Height: '',
            UnitDimensionCode: '',
            Dimensions: ''
          }
        }, () => {
          packages.push(_package);
          this.setState({
            packages,
          }, () => {
            this.props.sendProps(packages);
            this.buildContainerLines();
            this.buildFormContainers();
            this.setState({
              error: {
                message: 'New Line Item Added',
                open: true,
                type: 'success'
              }
            });
          });
        });
      }
    }

    buildContainerLines = () => {
      const { packages } = this.state;
      if (packages && packages.length > 0) {
        let packageLines = packages.map((e, index) => {
          return (
            <tr key={index}>
              <td>{e.CountPackages}</td>
              <td>{e.PackageTypeCode}</td>
              <td>{e.Weight}</td>
              <td>{e.UnitWeightCode}</td>
              <td>{e.Volume}</td>
              <td>{`${e.Length} * ${e.Width} * ${e.Height} `}</td>
              <td>{e.UnitDimensionCode}</td>
              <td>
                <center>
                  <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(index)}>
                    <Icon className="icon" style={{ fontSize: 20 }}>
                                        remove
                    </Icon>
                  </Button>
                </center>
              </td>
            </tr>
          );
        });
        this.setState({ packageLines });
      }
      else this.setState({ packageLines: '' });
    }

    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
      const { CreatePackage } = this.state;
      const { packages } = this.state;
      return (
        <Card className="myCard">
          <CardBody>
            <ExpansionPanel elevation={0} defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <legend><em className="fas fa-boxes fa-1x mr-2"></em>Packages</legend>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-12">
                    <div className="row">
                      {CreatePackage}
                      <div className="col-md-1" style={{ paddingTop: '25px' }}>
                        <Button variant="fab" mini color="primary" aria-label="Add" onClick={this.addLine}>
                          <AddIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr></hr>
                  </div>
                  <div className="col-md-12">
                    <Tables data={packages} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
                  </div>
                </div>
                <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>
        </Card>

      );
    }
}
export default List;