import {
  SET_DATA_REPORT,
  SET_DATA_FILTER,
  SET_ACTIVE_SAVE
} from './types';

export const SetDataReport = (data) => async dispatch => {
  try {
    dispatch({
      type: SET_DATA_REPORT,
      payload: data
    });
  }catch(error){
    console.error('SetDataReport: ', error);
  }
};

export const SetDataFilters = (data) => async dispatch => {
  try{
    dispatch({
      type: SET_DATA_FILTER,
      payload: data
    });
  }catch(error){
    console.error('SetDataFilters: ', error);
  }
};

export const SetActiveSave = (data) => async dispatch => {
  try{
    dispatch({
      type: SET_ACTIVE_SAVE,
      payload: data
    });
  }catch(error){
    console.error('SetActiveSave:');
  }
};