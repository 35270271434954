import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col } from "reactstrap";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
// import Loader from './../../../Global/TableLoader';
import GeneralInformation from "./components/GeneralInformation";
import BookingSelected from "./components/Booking";
import PoList from "./components/PoList";
import BackupPoList from "./components/BackupPoList";
import { Modal } from "semantic-ui-react";
import { InputGroup, Input } from "reactstrap";
import { Checkbox } from "@material-ui/core";
import Authentication from "../../../../services/Authentication.js";
import Axios from "axios";
import Swal from "sweetalert2";
import Form from "../../../../models/FormData";
import { Tab } from "semantic-ui-react";
import "./styles.scss";
import { Card, CardBody, Row } from "reactstrap";
import { Services } from "../Services";
import ImgAdmiracion from "../../../../resources/warn3.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loader from "./../../../Global/Loader/";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, Dropdown, message } from "antd";
import VALIDATION from "../../../../validation";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { validateIdParams } from "../../../utils/regularExpressionValidation";
// import { message, Button, Space } from 'antd';

const ValidationService = new VALIDATION();

const createOffsetValue = (date) => {
  var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = Math.floor(offset / 60);
  var minutes = ((offset % 60) / 60) * 10;
  return sign + hours + "." + minutes;
};

const ConsolComment = React.lazy(() => import("./components/Comment"));
const ConsolRevisionLogs = React.lazy(() =>
  import("./components/RevisionLogs")
);
const auth = new Authentication();
const stylebutton = {
  padding: "0.375rem 1rem",
  fontSize: "15px",
  borderRadius: "8px",
  marginTop: "-7em",
};

const buttonHomologado = {
  width: "8em",
};

class NewConsolPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSearchAprobal: false,
      changeEdit: true,
      changeLog: [],
      changeLog2: [],
      loadingSearch: false,
      fullLoader: false,
      statusApprova: "",
      commentApprova: "",
      numberConsol: "",
      activeAproval: false,
      CATPOLIST: [],
      poListEdit: [],
      IdConsol: "",
      selectsgeneral: { EQUIPMENT: [] },
      error: {
        open: true,
        message: "",
        type: "success",
      },
      backup: false,
      orders: [],
      data: [],
      order: {},
      loading: true,
      Form: Form,
      CustomerName: props._CustomerName,
      idCustomer: props._customerSelect,
      userId: props._user,
      active: false,
      tableSelect: [],
      tableaDD: [],
      dataSave: [],
      dataSaveBackup: [],
      addConsolPlan: [],
      tableBackup: [],
      addBackup: [],
      ModelSend: {
        TotalPallets: 0,
        TotalQTY: 0,
        TotalWeight: 0,
        FinalDestination: 0,
        EquipentTare: 0,
        VerifiedGoodsWeight: 0,
        VGMTotal: 0,
      },
      posList: [],
      isEdit: false,
      view: false,
      columns: [
        { label: "", key: "" },
        { label: "PO  No", key: "PONumber" },
        { label: "Split No.", key: "SplitNo" },
        { label: "Status", key: "StatusDesc" },
        { label: "Shipper", key: "SupplierName" },
        { label: "Ship To.", key: "DeliveryName" },
        { label: "Ship to Ref.", key: "DistributorPO" },
      ],
    };
  }

  componentDidMount = () => {
    let query = new URLSearchParams(this.props.location.search);
    if (query.get("searching")) {
      this.setState(
        {
          isEdit: true,
          view: true,
          IdConsol: query.get("searching"),
        },
        () => this.getAllCatalogsgeneral()
      );
    } else {
      this.getAllCatalogsgeneral();
    }
  };

  getAllCatalogs = async () => {
    try {
      let id = auth.getAuthentication("customerSelect");
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        message.error({
          content: "Data entered is invalid",
          className: "classCustomMessa",
        });
        setTimeout(() => {
          this.props.history.push("/consol-plan");
        }, 2000);
        return;
      }
      let success = await Axios.get(
        `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/catalogtable/${id}`
      );
      this.setState(
        { CATPOLIST: success.data.data ? success.data.data : [] },
        () => this.getOrdersList("")
      );
    } catch (err) {
      console.warn(err);
      message.error({
        content: "Data entered is invalid",
        className: "classCustomMessa",
      });
      setTimeout(() => {
        this.props.history.push("/consol-plan");
      }, 2000);
      return;
      /*this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/consol-plan");
      }, 2000);
      return;*/
    }
  };

  getAllCatalogsgeneral = async () => {
    try {
      let success = await Axios.get(
        `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/catalogconsol`
      );
      this.setState(
        { selectsgeneral: success.data.data ? success.data.data : [] },
        () => this.getAllCatalogs()
      );
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
    }
  };
  /*=============================================
  =            Get Order List                   =
  =============================================*/

  getOrdersList = async () => {
    let { isEdit } = this.state;
    try {
      let id = auth.getAuthentication("customerSelect");
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        message.error({
          content: "Data entered is invalid",
          className: "classCustomMessa",
        });
        setTimeout(() => {
          this.props.history.push("/consol-plan");
        }, 2000);
        return;
      }
      let success = await Axios.get(
        `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/searchorder/${id}`
      );
      success.data.data.forEach((e) => {
        e.checked = false;
        e.hidden = false;
        // e.edit = true;
      });

      if (isEdit) {
        this.setState(
          {
            posList: success.data.data ? success.data.data : [],
            loading: false,
          },
          () => {
            this.getConsolOne();
            // this.tableADD();
          }
        );
      } else {
        this.setState(
          {
            posList: success.data.data ? success.data.data : [],
            loading: false,
            fullLoader: true,
          },
          () => {
            this.tableList();
            this.tableADD();
          }
        );
      }
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
      message.error({
        content: "Data entered is invalid",
        className: "classCustomMessa",
      });
      setTimeout(() => {
        this.props.history.push("/consol-plan");
      }, 2000);
      return;
    }
  };

  putOrdersPo = async (array) => {
    let { poListEdit } = this.state;
    if (poListEdit.length > 0) {
      let newArray = array;

      newArray.map((y) => {
        let flat = false;
        poListEdit = poListEdit.map((e) => {
          if (y.POId === e.POId) {
            e = y;
            flat = true;
          }
          return e;
        });
        if (!flat) {
          poListEdit.push(y);
        }
      });
      this.setState(
        {
          poListEdit,
        },
        () => {
          this.handleSave();
        }
      );
    } else {
      this.setState(
        {
          poListEdit: array,
        },
        () => {
          this.handleSave();
        }
      );
    }
  };

  getConsolOne = async () => {
    let { posList } = this.state;
    try {
      let query = new URLSearchParams(this.props.location.search);
      let id = query.get("searching");
      let idValidate = validateIdParams(id);
      if (!idValidate) {
        message.error("Data entered is invalid", 2);
        setTimeout(() => {
          this.props.history.push("/consol-plan");
        }, 2000);
        return;
      }
      let success = await Axios.get(
        `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/oneconsolplan/${id}`
      );
      let select = [];
      let newData = [];
      posList.map((e) => {
        let findId = true;
        success.data.data.poRelationConsol.map((f) => {
          if (f.POId === e.POId) {
            findId = false;
            e.backuppo = f.BackupPO;
          }
          return f;
        });
        if (findId) {
          newData.push(e);
        } else {
          select.push(e);
        }
        return e;
      });
      let poSelectet = [];
      let backupSelected = [];

      select.forEach((e) => {
        if (e.backuppo === "0") {
          poSelectet.push(e);
        } else {
          backupSelected.push(e);
        }
      });

      // success.data.data.tableM.forEach(e => {
      //   e.UpdateDate = moment(e.UpdateDate).format('YYYY/MM/DD HH:mm');
      // });

      this.setState(
        {
          order: success.data.data.consolInfo[0],
          // changeLog:success.data.data.changeLog ? success.data.data.changeLog :[] ,
          changeLog2: success.data.data.tableM ? success.data.data.tableM : [],
          posList: newData,
          addConsolPlan: poSelectet,
          addBackup: backupSelected,
          fullLoader: true,
        },
        () => {
          this.handleSave();
          this.tableList();
          this.tableADD();
          // this.tableBackup();
        }
      );
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/consol-plan");
      }, 2000);
      return;
    }
  };

  setFieldToOrder = (order) => {
    this.setState({ order, changeEdit: false });
  };

  setFieldToOrderPolist = (name, event) => {
    let order = this.state.order;
    order[name] = event;
    this.setState({ order, changeEdit: false });
  };

  handleOnClose = () => {
    console.log("entre a cancelar");
    console.log("addConsolPlan", this.state.addConsolPlan);
    let newAddConsolPlan = this.state.addConsolPlan;

    for (let i = 0; i < newAddConsolPlan.length; i++) {
      const e = newAddConsolPlan[i];
      e.checked = false;
    }
    console.log("newAddConsolPlan", newAddConsolPlan);

    this.setState(
      {
        active: false,
        addConsolPlan: newAddConsolPlan,
      },
      () => this.tableADD()
    );
  };

  handleSave = () => {
    let { addConsolPlan, addBackup } = this.state;
    console.log("addConsolPlan", addConsolPlan);

    let newAddConsolPlan = addConsolPlan;

    for (let i = 0; i < newAddConsolPlan.length; i++) {
      const e = newAddConsolPlan[i];
      e.checked = false;
    }
    console.log("newAddConsolPlan", newAddConsolPlan);

    let dataSave = newAddConsolPlan;

    let dataSaveBackup = addBackup;
    let order = this.state.order;

    order.TotalPallets = 0;
    order.TotalQTY = 0;
    order.TotalWeight = 0;
    order.FinalDestination = "";
    dataSave.forEach((e, index) => {
      order.TotalPallets = Math.round(order.TotalPallets + Number(e.NoPallets));
      order.TotalQTY = order.TotalQTY + Number(e.TotalQtyOrdered);
      order.TotalWeight = order.TotalWeight + Number(e.GrossWeight);
      order.FinalDestination =
        order.FinalDestination +
        `${e.FinalDestination ? e.FinalDestination : "S/N"} ${
          dataSave.length - 1 === index ? "" : "/"
        } `;
    });

    this.setState({
      order,
      dataSave,
      active: false,
      dataSaveBackup,
      changeEdit: false,
      addConsolPlan: newAddConsolPlan,
    });
  };

  handleOnCloseAproval = () => {
    this.setState({
      activeAproval: false,
    });
  };

  handleSaveAproval = () => {
    let { order, statusApprova, commentApprova, IdConsol } = this.state;
    const dte = createOffsetValue(new Date());
    // order.dte = dte;
    let uri =
      Number(statusApprova) === 85
        ? `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/approve-console`
        : `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/rejected-console`;

    Axios.post(uri, {
      order: order,
      dte: dte,
      user: this.props._user,
      IdConsol: IdConsol,
      statusId: Number(statusApprova),
      commetAditional: commentApprova,
    })
      .then((response) => {
        Swal.fire(
          "",
          `${
            Number(statusApprova) === 85
              ? "Consol Plan Approved!"
              : "Consol Plan rejected succesfully!"
          }`,
          "success"
        );
        this.props.history.push("/consol-plan");
      })
      .catch(() => {
        console.log("error de save");
      });
  };

  validateConsolPlan = async () => {
    let {
      order,
      dataSave,
      dataSaveBackup,
      isEdit,
      IdConsol,
      poListEdit,
      Form,
    } = this.state;
    let array3 = dataSave.concat(dataSaveBackup);
    let data = {
      order: order,
      poList: dataSave,
      poBackup: dataSaveBackup,
      array3: array3,
      PoEdit: poListEdit,
    };

    let BODY = {
      headerDetails: order,
    };
    ValidationService.validate({
      target: "CONSOL-CREATE",
      data: BODY,
    })
      .then(() => {
        Form[isEdit ? "createConsolEdit" : "CreateConsol"].forEach((e) => {
          e.style = { color: "#616161" };
          e.error = "";
        });
        this.setState(
          {
            Form,
          },
          () => {
            if (dataSave.length > 0) {
              this.saveConsolPlan();
            } else {
              var element = document.getElementById("ArrayPOList");
              element.style.color = "#ff0000";
              element.scrollIntoView({ block: "center", behavior: "smooth" });
              message.error({
                content: "It is necessary to add at least one Purchase Order.",
                className: "classCustomMessa",
              });
            }
          }
        );
      })
      .catch((errors) => {
        let Element = document.getElementById(Object.keys(errors)[0]);
        if (Element) {
          Element.scrollIntoView({ block: "end", behavior: "smooth" });
          Element.focus();
        }
        Form[isEdit ? "createConsolEdit" : "CreateConsol"].forEach((e) => {
          if (errors[e.id]) {
            e.style = { color: "#e57373" };
            e.error = errors[e.id].label;
          } else {
            e.error = "";
            e.style = { color: "#616161" };
          }
        });
        this.setState({ Form }, () => {
          message.error({
            content: "There are some errors",
            className: "classCustomMessa",
          });
        });
      });
  };

  generationJson = async (data, list) => {
    let obj = {};

    obj.ConsolNumber = data.ConsolNumber ? data.ConsolNumber : "";
    obj.ContainerNumber = data.ContainerNumber ? data.ContainerNumber : "";
    obj.ContainerType = data.ContainerType ? data.ContainerType : "";
    obj.ShipmentNumber = data.ShipmentNumber ? data.ShipmentNumber : "";
    obj.EstLoadingDate = data.EstLoadingDate ? data.EstLoadingDate : "";
    obj.POD = data.POD ? data.POD : "";
    obj.POL = data.POL ? data.POL : "";
    obj.EquipmentTare = data.EquipmentTare ? data.EquipmentTare : "";
    obj.VerifiedGoodsWeight = data.VerifiedGoodsWeight
      ? data.VerifiedGoodsWeight
      : "";
    obj.VGMTotal = data.VGMTotal ? data.VGMTotal : "";
    obj.po = list;
    return obj;
  };

  saveConsolPlan = async () => {
    let {
      order,
      dataSave,
      dataSaveBackup,
      isEdit,
      IdConsol,
      poListEdit,
    } = this.state;

    const dte = createOffsetValue(new Date());
    order.dte = dte;

    try {
      dataSave.forEach((e) => {
        e.BackupP0 = 0;
      });
      dataSaveBackup.forEach((e) => {
        e.BackupP0 = 1;
      });
      this.setState({
        loadingSearch: true,
      });

      let array3 = dataSave.concat(dataSaveBackup);
      let jsonUpdate = await this.generationJson(order, dataSave);
      let data = {
        jsonUpdate: JSON.stringify(jsonUpdate),
        order: order,
        poList: dataSave,
        poBackup: dataSaveBackup,
        array3: array3,
        PoEdit: poListEdit,
      };

      if (isEdit) {
        data.IdConsol = IdConsol;
        if (order.ContainerReference !== null) {
          if (order.StatusId === 84) {
            let resp = await Axios.put(
              `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/updatedata-Consol`,
              data
            );
            if (resp.data.update) {
              this.setState(
                {
                  view: true,
                  loadingSearch: false,
                  // fullLoader:false
                },
                () => {
                  Swal.fire("", "Consol Plan Update Successfully !", "success");
                  this.getAllCatalogsgeneral();
                }
              );
            }
          } else {
            // let resp = await Axios.put(`${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/updatedata-Consol-booking`, data);
            let resp = await Axios.put(
              `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/updatedata-Consol`,
              data
            );
            if (resp.data.update) {
              this.setState(
                {
                  view: true,
                  loadingSearch: false,
                  // fullLoader:false
                },
                () => {
                  Swal.fire("", "Consol Plan Update Successfully !", "success");
                  this.getAllCatalogsgeneral();
                }
              );
            }
          }
        } else {
          let resp = await Axios.put(
            `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/updatedata-Consol`,
            data
          );
          if (resp.data.update) {
            this.setState(
              {
                view: true,
                loadingSearch: false,
                // fullLoader:false
              },
              () => {
                Swal.fire("", "Consol Plan Update Successfully !", "success");
                this.getAllCatalogsgeneral();
              }
            );
          }
        }
      } else {
        let resp = await Axios.post(
          `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/saveconsol`,
          { data: data }
        );
        if (resp.data.save) {
          Swal.fire("", "Consol Plan Created Successfully !", "success");
          this.props.history.push("/consol-plan");
        }
      }
    } catch (err) {
      // console.error("--->", err.response.data.iscreate);
      if (err.response) {
        if (err.response.data.iscreate) {
          var element = document.getElementById("ConsolNumber");
          element.scrollIntoView({ block: "end", behavior: "smooth" });
          element.style.color = "#ff0000";

          this.setState(
            {
              loadingSearch: false,
            },
            () => {
              Swal.fire({
                title: "",
                text: `${err.response.data.message}`,
                type: "error",
                confirmButtonColor: "#20c554",
              });
            }
          );
        }
      }
    }
  };

  activeModal = (backup) => {
    console.log("entre??");
    console.log("backup", backup);
    let { addConsolPlan } = this.state;
    console.log("addConsolPlan", addConsolPlan);
    var element = document.getElementById("ArrayPOList");
    element.style.color = "#222222";

    this.setState(
      {
        active: true,
        backup: backup,
        addConsolPlan,
      },
      () => (backup ? this.tableBackup() : this.tableADD())
    );
  };

  handleSearch = (name) => (event) => {
    this.setState({ [name]: event.target.value }, () => this.setSearch(name));
  };
  setSearch = (id) => {
    let { posList, addConsolPlan, addBackup } = this.state;
    let search = this.state[id];
    let cliente =
      id === "search"
        ? [...posList]
        : id === "search2"
        ? [...addConsolPlan]
        : [...addBackup];
    let haveSearch = false;

    if (search.length > 0) {
      haveSearch = true;
    }

    let all = cliente;
    let newAll = all.map((item) => {
      const nameData = item.PONumber
        ? item.PONumber.toString().toUpperCase()
        : "".toUpperCase();
      const nameNIT = item.SplitNo
        ? item.SplitNo.toString().toUpperCase()
        : "".toUpperCase();
      const idData = item.StatusDesc
        ? item.StatusDesc.toString().toUpperCase()
        : "".toUpperCase();
      const textData = search.toUpperCase();
      if (
        nameData.indexOf(textData) > -1 ||
        idData.indexOf(textData) > -1 ||
        nameNIT.indexOf(textData) > -1
      ) {
        item.hidden = false;
      } else {
        item.hidden = haveSearch ? true : false;
      }
      return item;
    });
    if (id === "search") {
      this.setState({ posList: newAll }, () => {
        this.tableADD();
        this.tableList();
        this.tableBackup();
      });
    } else if (id === "search2") {
      this.setState({ addConsolPlan: newAll }, () => {
        this.tableADD();
        this.tableList();
        this.tableBackup();
      });
    } else {
      this.setState({ addBackup: newAll }, () => {
        this.tableADD();
        this.tableList();
        this.tableBackup();
      });
    }
  };

  cheked = (id) => (event) => {
    console.log(id, event.target.id);

    let array = this.state[id];
    let newposList = array.map((e) => {
      if (e.POId === Number(event.target.id)) {
        e.checked = !e.checked;
      }
      return e;
    });
    this.setState(
      {
        [id]: newposList,
      },
      () => {
        this.tableADD();
        this.tableList();
        this.tableBackup();
      }
    );
  };

  tableList = () => {
    let { posList, columns } = this.state;
    let table = [];
    posList.map((e, index) => {
      if (e.hidden) {
      } else {
        if (e.Visible === 1) {
          table.push(
            <tr key={index}>
              <th>
                <Checkbox
                  checked={e.checked}
                  onChange={this.cheked("posList", e.POId)}
                  value={e.checked}
                  id={e.POId}
                />
              </th>
              <td>{e.PONumber}</td>
              <td>{e.SplitNo}</td>
              <td>{e.StatusDesc}</td>
              <td>{e.SupplierName}</td>
              <td>{e.DeliveryName}</td>
              <td>{e.DistributorPO}</td>
            </tr>
          );
        }
      }
      return e;
    });

    let subtds = [];
    columns.forEach((subcolumn, __index_) => {
      subtds.push(<th className="eventsTableHeader">{subcolumn.label}</th>);
    });

    let table2 = [];
    table2.push(
      <Col style={{ fontSize: "12px" }} md="12">
        <InputGroup style={{ width: "25em" }}>
          <Input
            style={{ marginLeft: "-4px", height: "31px", fontSize: "11px" }}
            placeholder="Search by: PO No, Shipper, Ship to, Status"
            onChange={this.handleSearch("search")}
          />
        </InputGroup>
        <br></br>
        <h4>{table.length} POs</h4>
        <div
          style={{ overflowY: "auto", overflowX: "hidden" }}
          className="table-wrapper-scroll-y"
        >
          <table className="table">
            <thead>
              <tr>{subtds}</tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      tableSelect: table2,
    });
  };

  tableADD = () => {
    let { addConsolPlan, columns } = this.state;
    // for (let i = 0; i < addConsolPlan.length; i++) {
    //   const e = addConsolPlan[i];
    //   e.checked = false;
    // }

    console.log("entre woe", addConsolPlan);
    let table = [];
    addConsolPlan.map((e, index) => {
      if (e.hidden) {
      } else {
        table.push(
          <tr key={index}>
            <th>
              <Checkbox
                checked={e.checked}
                onChange={this.cheked("addConsolPlan", e.POId)}
                value={e.checked}
                id={e.POId}
              />
            </th>
            <td>{e.PONumber}</td>
            <td>{e.SplitNo}</td>
            <td>{e.StatusDesc}</td>
            <td>{e.SupplierName}</td>
            <td>{e.DeliveryName}</td>
            <td>{e.DistributorPO}</td>
          </tr>
        );
      }
      return e;
    });

    let subtds = [];
    columns.forEach((subcolumn, __index_) => {
      subtds.push(<th className="eventsTableHeader">{subcolumn.label}</th>);
    });

    let table2 = [];
    table2.push(
      <Col style={{ fontSize: "12px" }} md="12">
        <InputGroup style={{ width: "25em" }}>
          <Input
            style={{ marginLeft: "-4px", height: "31px", fontSize: "11px" }}
            placeholder="Search by: PO No, Shipper, Ship to, Status"
            onChange={this.handleSearch("search2")}
          />
        </InputGroup>
        <br></br>
        <h4>{addConsolPlan.length} POs</h4>
        <div
          style={{ overflowY: "auto", overflowX: "hidden" }}
          className="table-wrapper-scroll-y"
        >
          <table className="table">
            <thead>
              <tr>{subtds}</tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      tableaDD: table2,
    });
  };

  tableBackup = () => {
    let { addBackup, columns } = this.state;
    let table = [];
    addBackup.map((e, index) => {
      if (e.hidden) {
      } else {
        // if (e.Visible === 1) {
        table.push(
          <tr key={index}>
            <th>
              <Checkbox
                checked={e.checked}
                onChange={this.cheked("addBackup", e.POId)}
                value={e.checked}
                id={e.POId}
              />
            </th>
            <td>{e.PONumber}</td>
            <td>{e.SplitNo}</td>
            <td>{e.StatusDesc}</td>
            <td>{e.SupplierName}</td>
            <td>{e.DeliveryName}</td>
            <td>{e.DistributorPO}</td>
          </tr>
        );
        // }
      }
      return e;
    });

    let subtds = [];
    columns.forEach((subcolumn, __index_) => {
      subtds.push(<th className="eventsTableHeader">{subcolumn.label}</th>);
    });

    let table2 = [];
    table2.push(
      <Col style={{ fontSize: "12px" }} md="12">
        <InputGroup style={{ width: "25em" }}>
          <Input
            style={{ marginLeft: "-4px", height: "31px", fontSize: "11px" }}
            placeholder="Search by: PO No, Shipper, Ship to, Status"
            onChange={this.handleSearch("search3")}
          />
        </InputGroup>
        <br></br>
        <h4>{addBackup.length} POs</h4>
        <div
          style={{ overflowY: "auto", overflowX: "hidden" }}
          className="table-wrapper-scroll-y"
        >
          <table className="table">
            <thead>
              <tr>{subtds}</tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      tableBackup: table2,
    });
  };

  newConsol = (flat) => {
    let { addConsolPlan, posList, addBackup } = this.state;

    if (flat) {
      if (this.state.backup) {
        let allCliente = posList;
        let allCliente2 = addBackup.filter((e) => {
          if (!e.checked) {
            return e;
          } else {
            e.Visible = 1;
            allCliente.push(e);
          }
          return 0;
        });
        allCliente.map((e) => {
          e.checked = false;
          return e;
        });
        this.setState(
          {
            posList: allCliente,
            addBackup: allCliente2,
          },
          () => {
            this.tableBackup();
            this.tableList();
          }
        );
      } else {
        let allCliente = posList;
        let allCliente2 = addConsolPlan.filter((e) => {
          if (!e.checked) {
            return e;
          } else {
            e.Visible = 1;
            allCliente.push(e);
          }
          return 0;
        });
        allCliente.map((e) => {
          e.checked = false;
          return e;
        });
        this.setState(
          {
            posList: allCliente,
            addConsolPlan: allCliente2,
          },
          () => {
            this.tableADD();
            this.tableList();
          }
        );
      }
    } else {
      if (this.state.backup) {
        let allCliente = addBackup;
        let allCliente2 = posList.filter((e) => {
          if (!e.checked) {
            return e;
          } else {
            e.Visible = 1;
            allCliente.push(e);
          }
          return 0;
        });
        allCliente.map((e) => {
          e.checked = false;
          return e;
        });
        this.setState(
          {
            addBackup: allCliente,
            posList: allCliente2,
          },
          () => {
            this.tableBackup();
            this.tableList();
          }
        );
      } else {
        let allCliente = addConsolPlan;
        let allCliente2 = posList.filter((e) => {
          if (!e.checked) {
            return e;
          } else {
            e.Visible = 1;
            allCliente.push(e);
          }
          return 0;
        });
        allCliente.map((e) => {
          e.checked = false;
          return e;
        });
        this.setState(
          {
            addConsolPlan: allCliente,
            posList: allCliente2,
          },
          () => {
            this.tableADD();
            this.tableList();
          }
        );
      }
    }
  };

  editConsol = () => {
    this.setState(
      {
        view: false,
        changeEdit: true,
      },
      () => {
        // this.tableBackup();
      }
    );
  };
  cancelEditConsol = () => {
    this.setState({
      view: true,
      changeEdit: true,
    });
  };

  changueStatusConsol = async (statusId, string, isonhold) => {
    let { IdConsol } = this.state;
    const dte = createOffsetValue(new Date());
    let body = {
      user: this.props._user,
      IdConsol: IdConsol,
      statusId: statusId,
      previouStatusId: isonhold ? isonhold : null,
      dte: dte,
    };
    let resp = await Axios.put(
      `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/changuestatus-Consol`,
      body
    );
    if (resp.data.update) {
      Swal.fire("", `Consol Plan ${string} Successfully !`, "success");
      this.props.history.push("/consol-plan");
    }
  };

  cancelStatusConsol = async (statusId, string) => {
    let { IdConsol, order } = this.state;
    const dte = createOffsetValue(new Date());
    // order.dte=dte;
    Swal.fire({
      title: "Are you sure you want to Cancel the consol plan?",
      html: `<h3 style="color:#31b20a">#${order.ConsolNumber}</h3>`,
      input: "text",
      inputLabel: "Cancelacion Comment",
      inputValue: "",
      confirmButtonColor: "#3B87E8",
      reverseButtons: true,
      cancelButtonColor: "#d33",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        } else {
          Axios.post(
            `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/cancel-console`,
            {
              order: order,
              user: this.props._user,
              IdConsol: IdConsol,
              statusId: 88,
              dte: dte,
              commetAditional: value,
            }
          )
            .then((response) => {
              Swal.fire("", "Consol Plan Cancel Successfully !", "success");
              this.props.history.push("/consol-plan");
            })
            .catch(() => {
              console.log("error de save");
            });
        }
      },
    });
  };

  ConsolApproval = async (order) => {
    let idTemporal = order.ConsolNumber;
    this.setState(
      {
        activeAproval: true,
        numberConsol: idTemporal,
      },
      () => {}
    );
  };

  requestApproval = async (order) => {
    const dte = createOffsetValue(new Date());

    let { IdConsol } = this.state;
    Swal.fire({
      title: `Are you sure you want to request the approval for the  consol plan?`,
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3B87E8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, im sure.",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      html: `<h3>#${order.ConsolNumber}</h3>`,
      preConfirm: () => {
        return Axios.post(
          `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/request-approval`,
          {
            order: order,
            user: this.props._user,
            IdConsol: IdConsol,
            statusId: 84,
            dte: dte,
          }
        )
          .then((response) => {
            if (response.data.save) {
              return { success: true, save: true };
            } else {
              return { success: true, save: false };
            }
          })
          .catch(() => {
            console.log("error de save");
            return { success: false, save: false };
          });
      },
    }).then((result) => {
      if (result.value && result.value.save === true) {
        Swal.fire(
          "",
          "Consol Plan approval requested successfully!",
          "success"
        );
        this.props.history.push("/consol-plan");
      } else {
        // this.cancelStatusConsol();
      }
    });
  };

  changeApproval = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  bookingRequest = async (id, existsBokking) => {
    let {
      order,
      dataSave,
      dataSaveBackup,
      isEdit,
      IdConsol,
      poListEdit,
      Form,
    } = this.state;

    let aux = dataSave.filter(
      (e) =>
        e.ConsigneeColor === "0" ||
        e.ShipToColor === "0" ||
        e.ShipperColor === "0"
    );
    const dte = createOffsetValue(new Date());
    if (aux.length > 0) {
      message.error({
        content: "All the entities must be associated.",
        className: "classCustomMessa",
      });
    } else {
      if (existsBokking) {
        this.validateConsolPlan();
      } else {
        this.setState({ fullLoader: false });
        try {
          let idValidate = validateIdParams(id);
          if (!idValidate) {
            message.error({
              content: "Data entered is invalid",
              className: "classCustomMessa",
            });
            setTimeout(() => {
              this.props.history.push("/console-plan");
            }, 2000);
            return;
          }
          let success = await Axios.get(
            `${Services.GET_CONSOLPLAN_SERVICE.path}console-plan/bookingrequest/${id}?zh=${dte}`
          );
          Swal.fire(
            "",
            // 'Json granted and status change to Booked!',
            "Dear User, A Booking Request has been requested.",
            "success"
          );
          this.setState({ fullLoader: true }, () =>
            this.props.history.push("/consol-plan")
          );
        } catch (err) {
          console.warn(err);
          this.setState({ fullLoader: true }, () => {
            Swal.fire("", "Booking error", "error");
          });
        }
      }
    }
  };

  sendItemsNew = async (newItems) => {
    this.setState(
      {
        dataSave: newItems,
        addConsolPlan: newItems,
      },
      () => {}
    );
  };

  /*=============================================
  =            Render Method                    =
  =============================================*/
  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    const {
      changeEdit,
      loadingSearchAprobal,
      changeLog2,
      changeLog,
      loadingSearch,
      modalAnagrafico,
      selectsgeneral,
      fullLoader,
      numberConsol,
      CATPOLIST,
      isEdit,
      order,
      view,
      loading,
      idCustomer,
      CustomerName,
      backup,
      Form,
      tableSelect,
      tableaDD,
      dataSave,
      ModelSend,
      tableBackup,
      dataSaveBackup,
      userId,
    } = this.state;
    let style = { height: "42em" };
    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div>
            <div className="content-heading">
              <div className="row">
                <em className="fas fa-boxes fa-1x mr-2" />
                Consol Plan
              </div>
            </div>
          </div>
          {/* <Card className="myCard"> */}
          {/* <CardBody> */}
          {isEdit ? (
            <>
              <Tab
                loading={true}
                menu={{ secondary: true, className: "myWrapped" }}
                menuPosition="right"
                panes={[
                  {
                    menuItem: "Consol Plan",
                    render: () => (
                      <>
                        <Col
                          style={{ marginBottom: "-1em" }}
                          className="row"
                          md={12}
                          xl={12}
                        >
                          <div
                            className={`col-md-6 col-lg-${
                              view ? "9" : "7"
                            }  col-sm-12`}
                          ></div>
                          <div
                            hidden={
                              view
                                ? order.Edit > 0
                                  ? order.StatusId === 88 ||
                                    order.StatusId === 87 ||
                                    order.StatusId === 85
                                    ? true
                                    : false
                                  : true
                                : true
                            }
                            className="col-md-2 col-lg-2 col-sm-6"
                          >
                            <Button
                              style={stylebutton}
                              color="success"
                              onClick={() => this.editConsol()}
                            >
                              Edit Consol
                            </Button>
                          </div>
                          <div
                            hidden={
                              view
                                ? order.Edit > 0
                                  ? order.StatusId === 88 ||
                                    order.StatusId === 87
                                    ? false
                                    : true
                                  : false
                                : false
                            }
                            className="col-md-2 col-lg-2 col-sm-6"
                          ></div>
                          <div
                            hidden={view ? true : false}
                            className="col-md-2 col-lg-2 col-sm-6"
                          >
                            <Button
                              style={stylebutton}
                              color="danger"
                              onClick={() => this.cancelEditConsol()}
                            >
                              Cancel Edit
                            </Button>
                          </div>
                          <div
                            hidden={
                              view
                                ? order.StatusId === 88 || order.StatusId === 85
                                  ? true
                                  : false
                                : true
                            }
                            className="col-md-2 col-lg-1 col-sm-6"
                          >
                            <Dropdown
                              overlay={() => {
                                return (
                                  <Menu>
                                    {order.StatusId === 82 ||
                                    order.StatusId === 83 ||
                                    order.StatusId === 84 ||
                                    order.StatusId === 87 ? (
                                      <></>
                                    ) : (
                                      <Menu.Item
                                        disabled={
                                          this.props._rolId === "1" ||
                                          this.props._rolId === "6"
                                            ? false
                                            : true
                                        }
                                      >
                                        <h5
                                          onClick={() =>
                                            this.props._rolId === "1" ||
                                            this.props._rolId === "6"
                                              ? this.changueStatusConsol(
                                                  82,
                                                  "Accepted"
                                                )
                                              : ""
                                          }
                                        >
                                          Accept Consol
                                        </h5>
                                      </Menu.Item>
                                    )}
                                    {order.StatusId === 83 ? (
                                      <Menu.Item
                                        disabled={
                                          this.props._rolId === "1" ||
                                          this.props._rolId === "6"
                                            ? false
                                            : true
                                        }
                                      >
                                        <h5
                                          onClick={() =>
                                            this.requestApproval(order)
                                          }
                                        >
                                          Request for Approval
                                        </h5>
                                      </Menu.Item>
                                    ) : (
                                      <></>
                                    )}
                                    {order.StatusId === 84 ? (
                                      <Menu.Item
                                        disabled={
                                          this.props._rolId === "1" ||
                                          this.props._rolId === "6"
                                            ? false
                                            : true
                                        }
                                      >
                                        <h5
                                          onClick={() =>
                                            this.ConsolApproval(order)
                                          }
                                        >
                                          Consol Approval
                                        </h5>
                                      </Menu.Item>
                                    ) : (
                                      <></>
                                    )}
                                    {order.Edit > 1 ? (
                                      order.StatusId === 87 ? (
                                        <></>
                                      ) : (
                                        <Menu.Item
                                          disabled={
                                            this.props._rolId === "1" ||
                                            this.props._rolId === "6"
                                              ? false
                                              : true
                                          }
                                        >
                                          <h5
                                            onClick={() =>
                                              this.changueStatusConsol(
                                                87,
                                                "On Hold",
                                                order.StatusId
                                              )
                                            }
                                          >
                                            On Hold
                                          </h5>
                                        </Menu.Item>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {order.StatusId === 87 ? (
                                      <Menu.Item>
                                        <h5
                                          onClick={() =>
                                            this.changueStatusConsol(
                                              order.PreviousStatusId,
                                              "Activate"
                                            )
                                          }
                                        >
                                          Activate
                                        </h5>
                                      </Menu.Item>
                                    ) : (
                                      <></>
                                    )}
                                    {order.StatusId === 82 ? (
                                      <Menu.Item
                                        disabled={
                                          this.props._rolId === "1" ||
                                          this.props._rolId === "6"
                                            ? false
                                            : true
                                        }
                                      >
                                        <h5
                                          onClick={() =>
                                            this.bookingRequest(
                                              order.ConsolPlanId
                                            )
                                          }
                                        >
                                          Booking Request
                                        </h5>
                                      </Menu.Item>
                                    ) : (
                                      <></>
                                    )}

                                    {order.Edit > 1 ? (
                                      <Menu.Item>
                                        <h5
                                          style={{ color: "red" }}
                                          onClick={() =>
                                            this.cancelStatusConsol()
                                          }
                                        >
                                          Cancel
                                        </h5>
                                      </Menu.Item>
                                    ) : (
                                      <></>
                                    )}
                                  </Menu>
                                );
                              }}
                            >
                              <IconButton
                                style={stylebutton}
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Dropdown>
                          </div>
                        </Col>
                        {/* <Col md={12}> */}
                        <Card className="myCard">
                          <CardBody>
                            <Col md={12} xl={12}>
                              <Row>
                                <Col md={3} lg={2} sm={12}>
                                  <h4>
                                    Consol No# &nbsp;
                                    {/* {
                                  (data && data.ParentPOId) ?
                                  <em onClick={""} className='redirect-ponumber'>989898</em>

                                    // <em onClick={() => {this.props.history.push(`/po-detail-view/${data.ParentPOId}`); resetInfo(data.ParentPOId);}} className='redirect-ponumber'>{this.state.data.PONumber}</em>
                                    :  */}
                                    <React.Fragment>
                                      {order.ConsolNumber
                                        ? order.ConsolNumber
                                        : ""}
                                    </React.Fragment>
                                    {/* // } */}
                                  </h4>
                                </Col>
                                {/* // !if -- data.ParentPOId ?  */}
                                <Col md={3} lg={2} sm={12}>
                                  <Button
                                    className={"btn-oval"}
                                    style={{ cursor: "auto" }}
                                    color="primary"
                                  >
                                    {order.Status ? order.Status : ""}
                                  </Button>
                                </Col>
                                <Col
                                  className="row"
                                  /* md={3} lg={8} */ style={{ float: "right" }}
                                >
                                  <div
                                    style={{ marginLeft: "1em" }}
                                    className="date_content"
                                  >
                                    <h5 className="date_header">
                                      <em className="label_title">
                                        {" "}
                                        Created by:{" "}
                                      </em>
                                      {order.UserCreate ? order.UserCreate : ""}
                                    </h5>
                                  </div>
                                  <div
                                    style={{ marginLeft: "1em" }}
                                    className="date_content"
                                  >
                                    <h5 className="date_header">
                                      <em className="label_title">
                                        {" "}
                                        Created:{" "}
                                      </em>
                                      {order.CreateDate ? order.CreateDate : ""}
                                    </h5>
                                  </div>
                                  <div
                                    style={{ marginLeft: "1em" }}
                                    className="date_content"
                                  >
                                    <h5 className="date_header">
                                      <em className="label_title">
                                        {" "}
                                        Last Update by:{" "}
                                      </em>
                                      {order.UserUpdate ? order.UserUpdate : ""}
                                    </h5>
                                  </div>
                                  <div
                                    style={{ marginLeft: "1em" }}
                                    className="date_content"
                                  >
                                    <h5 className="date_header">
                                      <em className="label_title">
                                        {" "}
                                        Last Update:{" "}
                                      </em>
                                      {order.LastUpdateDate
                                        ? order.LastUpdateDate
                                        : ""}
                                    </h5>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </CardBody>
                        </Card>
                        {/* </Col> */}
                        {/* <Col md={12}>
                      <Card className="myCard">
                        <CardBody>
                        </CardBody>
                      </Card>
                    </Col> */}

                        <GeneralInformation
                          selectsgeneral={selectsgeneral}
                          isEdit={isEdit}
                          view={view}
                          order={order}
                          open={this.state.open}
                          sendOrderField={this.setFieldToOrder}
                          Form={Form}
                          CustomerName={CustomerName}
                          idCustomer={idCustomer}
                          userId={userId}
                        ></GeneralInformation>
                        {order.StatusId === 82 ||
                        order.StatusId === 83 ||
                        order.StatusId === 84 ||
                        order.StatusId === 85 ? (
                          <BookingSelected
                            _rolId={this.props._rolId}
                            order={order}
                            setFieldToOrderPolist={this.setFieldToOrderPolist}
                            view={view}
                            isEdit={isEdit}
                            Form={Form}
                            CustomerName={CustomerName}
                            idCustomer={idCustomer}
                            userId={userId}
                          ></BookingSelected>
                        ) : (
                          <></>
                        )}
                        <PoList
                          CATPOLIST={CATPOLIST}
                          putOrdersPo={this.putOrdersPo}
                          setFieldToOrderPolist={this.setFieldToOrderPolist}
                          Form={Form}
                          order={order}
                          view={view}
                          ModelSend={ModelSend}
                          items={dataSave}
                          activeModal={this.activeModal}
                          sendItems={this.recieveItems}
                          sendItemsNew={this.sendItemsNew}
                          errors={this.state.errors}
                        ></PoList>
                        <BackupPoList
                          view={view}
                          activeModal={this.activeModal}
                          items={dataSaveBackup}
                          sendItems={this.recieveItems}
                          errors={this.state.errors}
                        ></BackupPoList>

                        <div
                          style={{ textAlign: "end", marginBottom: "1em" }}
                          hidden={true}
                          id="messagePoEdit"
                        >
                          <h3 style={{ color: "red", fontSize: "14px" }}>
                            Please "Save Changes" in the POS edition
                          </h3>
                        </div>
                        <div hidden={view ? true : false} className="row">
                          <div className="col-md-6 col-lg-8 col-sm-12"></div>
                          <div
                            style={{ textAlign: "end" }}
                            className="col-md-3 col-lg-2 col-sm-6"
                          >
                            <Button
                              variant="contained"
                              style={buttonHomologado}
                              color="danger"
                              size="lg"
                              className="button"
                              onClick={() =>
                                this.props.history.push("/consol-plan")
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                          <div
                            hidden={changeEdit}
                            className="col-md-3 col-lg-1 col-sm-6"
                          >
                            <Button
                              id="myBtn"
                              variant="contained"
                              style={buttonHomologado}
                              color="primary"
                              className="button"
                              onClick={() => {
                                if (
                                  isEdit === true &&
                                  order.BookingRequestDate !== null
                                ) {
                                  this.bookingRequest(null, true);
                                } else {
                                  this.validateConsolPlan();
                                }
                              }}
                              size="lg"
                            >
                              {loadingSearch ? (
                                <center>
                                  {" "}
                                  <Icon
                                    loading={loadingSearch}
                                    style={{ background: "transparent" }}
                                    name="circle notch"
                                    size="large"
                                  />
                                </center>
                              ) : (
                                <> Save Consol</>
                              )}
                            </Button>
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    menuItem: "Comments",
                    render: () => (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ConsolComment
                          _rolId={this.props._rolId}
                          order={order}
                          idCustomer={idCustomer}
                          userId={userId}
                        ></ConsolComment>
                      </Suspense>
                    ),
                  },
                  {
                    menuItem: "Change Logs",
                    render: () => (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ConsolRevisionLogs
                          changeLog2={changeLog2}
                          changeLog={changeLog}
                        ></ConsolRevisionLogs>
                      </Suspense>
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <>
              <GeneralInformation
                selectsgeneral={selectsgeneral}
                order={order}
                open={this.state.open}
                sendOrderField={this.setFieldToOrder}
                Form={Form}
                CustomerName={CustomerName}
                idCustomer={idCustomer}
                userId={userId}
              ></GeneralInformation>
              <PoList
                sendItemsNew={this.sendItemsNew}
                CATPOLIST={CATPOLIST}
                putOrdersPo={this.putOrdersPo}
                setFieldToOrderPolist={this.setFieldToOrderPolist}
                Form={Form}
                order={order}
                ModelSend={ModelSend}
                items={dataSave}
                activeModal={this.activeModal}
                sendItems={this.recieveItems}
                errors={this.state.errors}
              ></PoList>
              <BackupPoList
                activeModal={this.activeModal}
                items={dataSaveBackup}
                sendItems={this.recieveItems}
                errors={this.state.errors}
              ></BackupPoList>
              <div
                style={{ textAlign: "end", marginBottom: "1em" }}
                hidden={true}
                id="messagePoEdit"
              >
                <h3 style={{ color: "red", fontSize: "14px" }}>
                  Please "Save Changes" in the POS edition
                </h3>
              </div>
              <div hidden={view ? true : false} className="row">
                <div className="col-md-6 col-lg-8 col-sm-12"></div>
                <div
                  style={{ textAlign: "end" }}
                  className="col-md-3 col-lg-2 col-sm-6"
                >
                  <Button
                    variant="contained"
                    style={buttonHomologado}
                    color="danger"
                    size="lg"
                    className="button"
                    onClick={() => this.props.history.push("/consol-plan")}
                  >
                    Cancel
                  </Button>
                </div>
                <div hidden={changeEdit} className="col-md-3 col-lg-1 col-sm-6">
                  <Button
                    id="myBtn"
                    variant="contained"
                    style={buttonHomologado}
                    color="primary"
                    className="button"
                    onClick={() => {
                      if (
                        isEdit === true &&
                        order.BookingRequestDate !== null
                      ) {
                        this.bookingRequest(null, true);
                      } else {
                        this.validateConsolPlan();
                      }
                    }}
                    size="lg"
                  >
                    {loadingSearch ? (
                      <center>
                        {" "}
                        <Icon
                          loading={loadingSearch}
                          style={{ background: "transparent" }}
                          name="circle notch"
                          size="large"
                        />
                      </center>
                    ) : (
                      <> Save Consol</>
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}

          {/* {
              loading ? 
                <Loader/>
                :
                'holoo'
            } */}
          {/* </CardBody>
        </Card> */}
          <Modal
            open={this.state.active}
            size={"fullscreen"}
            // dimmer={'inverted'}
            centered={false}
            scrolling
            style={style}
          >
            <Modal.Header className="headerModal headerModalMulti">
              {backup ? "Add/Remove BackUp POs" : "Add/Remove POs"}
            </Modal.Header>
            <Modal.Content scrolling>
              <div className="col-md-12 flex-container2 ">
                <div className="col-md-6">
                  <div style={{ marginBottom: "1em" }} className="col-md-12">
                    <strong style={{ marginLeft: "-12px" }}>PO List</strong>
                  </div>
                  <div className="col-md-12  divTable ">
                    <br></br>
                    {tableSelect}
                  </div>
                </div>
                <div className="iconosAdd">
                  <div className="col-md-12" style={{ height: "8em" }}></div>
                  <em
                    style={{ marginBottom: "1em" }}
                    onClick={() => this.newConsol()}
                    className="fas fa-chevron-circle-right fa-1x mr-2"
                  />
                  <em
                    onClick={() => this.newConsol(true)}
                    className="fas fa-chevron-circle-left fa-1x mr-2"
                  />
                </div>
                <div className="col-md-6">
                  <div style={{ marginBottom: "1em" }} className="col-md-12">
                    <strong style={{ marginLeft: "-12px" }}>PO List</strong>
                  </div>
                  <div className="col-md-12 divTable ">
                    <br></br>
                    {backup ? tableBackup : tableaDD}
                  </div>
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions className="modal-actions">
              <div className="row">
                <div className="col-md-6 col-lg-8 col-sm-12"></div>
                <div
                  style={{ textAlign: "end" }}
                  className="col-md-2 col-lg-2 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="danger"
                    style={{ fontSize: "16px", width: "8em" }}
                    className="button"
                    onClick={this.handleOnClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div
                  style={{ textAlign: "center" }}
                  className="col-md-2 col-lg-1 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "16px", width: "8em" }}
                    className="button"
                    onClick={this.handleSave}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Modal.Actions>
          </Modal>
          <Modal open={this.state.activeAproval} size={"tiny"} centered={false}>
            <Modal.Content scrolling>
              <div className="col-md-12">
                <div style={{}} className="col-md-12">
                  <div
                    style={{ textAlign: "center", marginBottom: "1em" }}
                    className="col-md-12"
                  >
                    <img
                      src={ImgAdmiracion}
                      width="35%"
                      height="35%"
                      alt="active_po"
                    />
                  </div>
                  <div style={{ fontSize: "19px" }} className="col-md-12">
                    <label style={{ marginBottom: "2em" }}>
                      {" "}
                      Do you want to approve the consol plan
                    </label>
                    <label style={{ color: "#31b20a" }}> #{numberConsol}</label>
                    <RadioGroup
                      onChange={this.changeApproval("statusApprova")}
                      style={{ marginLeft: "5em" }}
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="85"
                        control={<Radio color="primary" />}
                        label="Approve"
                      />
                      <FormControlLabel
                        value="82"
                        control={<Radio color="primary" />}
                        label="Reject"
                      />
                    </RadioGroup>
                    <div
                      style={{ textAlign: "center", fontSize: "19px" }}
                    ></div>
                    <label style={{}}>Additional comment:</label>
                    <Input
                      onChange={this.changeApproval("commentApprova")}
                      type="textarea"
                      name="text"
                      id="exampleText"
                    />
                  </div>
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions
              style={{ marginTop: "1em" }}
              className="modal-actions"
            >
              <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-12"></div>
                <div
                  style={{ textAlign: "center" }}
                  className="col-md-2 col-lg-3 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="danger"
                    style={{ fontSize: "16px", width: "8em" }}
                    className="button"
                    onClick={this.handleOnCloseAproval}
                  >
                    Cancel
                  </Button>
                </div>
                <div
                  style={{ textAlign: "center" }}
                  className="col-md-2 col-lg-1 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "16px", width: "8em" }}
                    className="button"
                    onClick={() =>
                      this.setState({ loadingSearchAprobal: true }, () =>
                        this.handleSaveAproval()
                      )
                    }
                  >
                    {loadingSearchAprobal ? (
                      <center>
                        {" "}
                        <Icon
                          loading={loadingSearchAprobal}
                          style={{ background: "transparent" }}
                          name="circle notch"
                          size="large"
                        />
                      </center>
                    ) : (
                      <> Yes, im sure.</>
                    )}
                  </Button>
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        </ContentWrapper>
      </>
    );
  }
}

export default withRouter(NewConsolPlan);
