const Forms = {
    BODY:[
        {
            type: 'Select',
            options: 'EquipmentNo',
            label: 'Equipment No.',
            class: 'col-md-2 col-lg-2 col-sm-12',
            name: 'EquipmentNo',
            id: 'EquipmentNo',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'Code',
            label: 'Code',
            class: 'col-md-2 col-lg-2 col-sm-12',
            name: 'Code',
            id: 'Code',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'Category',
            label: 'Category',
            class: 'col-md-2 col-lg-2 col-sm-12',
            name: 'Category',
            id: 'Category',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'Status',
            label: 'Status',
            class: 'col-md-2 col-lg-2 col-sm-12',
            name: 'Status',
            id: 'Status',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},
        },
    ],
    HEADER:[
        {
            type: 'Select',
            options: 'InvoiceNo',
            label: 'No. Invoice',
            name: 'InvoiceNo',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'InvoiceNo',
            style : { fontSize : '11px',fontWeight:'bold'},
        },
        {
            type: 'Datetime',
            label: 'Invoice Date',
            name: 'InvoiceDate',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'InvoiceDate',
            style : { fontSize : '11px',fontWeight:'bold'},
        },
        {
            type: 'Datetime',
            label: 'Due Date',
            name: 'DueDate',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'DueDate',
            style : { fontSize : '11px',fontWeight:'bold'},
        },

        // {
        //     type: 'Datetime2',
        //     label: 'ejemplo Date',
        //     name: 'Ejemplo',
        //     class: 'col-md-2 col-lg-2 col-sm-12',
        //     id: 'Ejemplo',
        //     style : { fontSize : '11px',fontWeight:'bold'},
        // },

        {
            type: 'Select',
            options: 'TransportMode',
            label: 'Transport Mode',
            name: 'TransportMode',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'TransportMode',
            style : { fontSize : '11px',fontWeight:'bold'},
        },
        {
            type: 'Select',
            label: 'Shipment No.',
            options: 'ShipmentNo',
            name: 'ShipmentNo',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'ShipmentNo',
            style : { fontSize : '11px',fontWeight:'bold'},
        },
    ]
    
   
}

export default Forms