const _MAXLENGHT = 15;

export const SummaryData = {
  data: [
    {
      "cruise": "MSC Seaside",
      "period": "Sep 2021",
      "year": 2021,
      "month": "September",
      "poAmount": "802928",
      "logisticCost": "281024",
      "total": "1083953",
      "budget": "1029755",
      "derivation": "-54197",
      "percentage": "5",
      "color":'red',
      "dryDrockProgress": "83",
      "division": "2",
      "country": "Italy"
    },
    {
      "cruise": "MSC Bellissima",
      "period": "Aug 2021",
      "year": 2021,
      "month": "August",
      "poAmount": "709609",
      "logisticCost": "177402",
      "total": "887011",
      "budget": "1046672",
      "derivation": "159661",
      "percentage": "-15",
      "color":'#29C24D',
      "dryDrockProgress": "90",
      "division": "3",
      "country": "Italy"
    },
    {
      "cruise": "MSC Fantasia",
      "period": "Dec 2020",
      "year": 2020,
      "month": "December",
      "poAmount": "545330",
      "logisticCost": "115532",
      "total": "660862",
      "budget": "553074",
      "derivation": "-107788",
      "percentage": "16",
      "color":'red',
      "dryDrockProgress": "100",
      "division": "3",
      "country": "Italy"
    },
    {
      "cruise": "MSC Armonia",
      "period": "Oct 2020",
      "year": 2020,
      "month": "October",
      "poAmount": "674281",
      "logisticCost": "269712",
      "total": "943993",
      "budget": "1038392",
      "derivation": "94399",
      "percentage": "-9",
      "color":'#29C24D',
      "dryDrockProgress": "100",
      "division": "1",
      "country": "Italy"
    },
    {
      "cruise": "MSC Grandiosa",
      "period": "Sep 2020",
      "year": 2020,
      "month": "September",
      "poAmount": "998473",
      "logisticCost": "385221",
      "total": "1383694",
      "budget": "1425000",
      "derivation": "58694",
      "percentage": "-4",
      "color":'#29C24D',
      "dryDrockProgress": "100",
      "division": "3",
      "country": "Italy"
    },
    {
      "cruise": "MSC Divina",
      "period": "Dec 2019",
      "year": 2019,
      "month": "December",
      "poAmount": "766483",
      "logisticCost": "147794",
      "total": "914277",
      "budget": "1125814",
      "derivation": "211537",
      "color":'#29C24D',
      "percentage": "-23",
      "dryDrockProgress": "100",
      "division": "3",
      "country": "Italy"
    }
  ]
};

export const SummaryModel = {
  year: '',
  month: '',
  division: '',
  cruise: '',
  country: ''
};

export const SummaryForm = {
  form: [
    {
      type: 'Input',
      label:'Year',
      id: 'year',
      name: 'year',
      class: '_inputFilters',
      placeholder: 'Year',
      maxlenght: _MAXLENGHT      
    },{
      type: 'Combo',
      label:'Month',
      id: 'month',
      name: 'month',
      class: '_inputFilters',
      placeholder: 'Month',
      data: [
        {value: 'january', label: 'January'}, 
        {value: 'february', label: 'February'}, 
        {value: 'march', label: 'March'}, 
        {value: 'april', label: 'April'}, 
        {value: 'may', label: 'May'}, 
        {value: 'june', label: 'June'}, 
        {value: 'july', label: 'July'}, 
        {value: 'august', label: 'August'}, 
        {value: 'september', label: 'September'}, 
        {value: 'october', label:'October'}, 
        {value: 'november', label: 'November'}, 
        {value: 'december', label: 'December'}],
      maxlenght: _MAXLENGHT
    },{
      type: 'Input',
      label:'Division',
      id: 'division',
      name: 'division',
      class: '_inputFilters',
      placeholder: 'Division',
      maxlenght: _MAXLENGHT      
    },{
      type: 'Input',
      label:'Cruise',
      id: 'cruise',
      name: 'cruise',
      class: '_inputFilters',
      placeholder: 'Cruise',
      maxlenght: _MAXLENGHT      
    },{
      type: 'Input',
      label:'Country',
      id: 'country',
      name: 'country',
      class: '_inputFilters',
      placeholder: 'Country',
      maxlenght: _MAXLENGHT      
    }
  ]
};