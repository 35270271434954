import ENVIRONMENTS from '../config/environment';
const ORDER = ENVIRONMENTS.ORDER;
const CATALOG = ENVIRONMENTS.CATALOG;
const ADMIN = ENVIRONMENTS.ADMIN;
// const DIRECTORY = ENVIRONMENTS.DIRECTORY;
const FILES = ENVIRONMENTS.FILES;
const MAILS = ENVIRONMENTS.MAILS;
const BOOKING = ENVIRONMENTS.BOOKING;
const SHIPMENT = ENVIRONMENTS.SHIPMENT;
const EVENTS = ENVIRONMENTS.EVENTS;
// const COMMENTS = ENVIRONMENTS.COMMENTS;
const RATES = ENVIRONMENTS.RATES;
// const WAREHOUSING = ENVIRONMENTS.WAREHOUSING;

const headers = { 'Content-Type' : 'application/json' };

const HTTP_REQUEST = {
  GetAllCatalogs: {
    host: CATALOG + '/all/order',
    headers,
    method: 'GET'
  },
  PostOrder: {
    host: ORDER + '/order/',
    headers,
    method: 'POST'
  },
  PutOrder: {
    host: ORDER + '/order/',
    headers,
    method: 'PUT'
  },
  AddOneItemFromOrder: {
    host: ORDER + '/po-line/',
    headers,
    method: 'POST'
  },
  RemoveOneItemFromOrder: {
    host: ORDER + '/po-line/',
    headers,
    method: 'DELETE'
  },
  PostOrderAllInOne: {
    host: ORDER + '/order/all-in-one/',
    headers,
    method: 'POST'
  },
  GetOneBooking: {
    host: BOOKING + '/search/',
    headers,
    method: 'GET'
  },
  GetOrdersList: {
    host: ORDER + '/order/search/all',
    // host:'https://www.magicanalytics.app/poseidon/api/order/v1/order/search/all',
    headers,
    method: 'GET'
  },
  uploadFiles: {
    host: FILES + '/upload/',
    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
    method: 'POST'
  },
  RemoveFile: {
    host: FILES + '/delete/',
    headers,
    method: 'DELETE'
  },
  getViewConfirmation: {
    host: ORDER + '/order/confirmation/order/',
    headers,
    method: 'GET'
  },
  PutBooking: {
    host: BOOKING + '/update/',
    headers,
    method: 'PUT'
  },
  confirmationPO: {
    host: MAILS + '/send/',
    headers,
    method: 'POST'
  },
  BookingCreate: {
    host: MAILS + '/send//booking-create',
    headers,
    method: 'POST'
  },
  PostBooking: {
    host: BOOKING + '/booking/save/',
    headers,
    method: 'POST'
  },
  GetAllCatalogsBooking: {
    host: CATALOG + '/all/booking',
    headers,
    method: 'GET'
  },
  GetAdvanceSearchBooking: {
    host: CATALOG + '/advance/search',
    headers,
    method: 'GET'
  },
  GetAllBookings: {
    host: BOOKING + '/search',
    headers,
    method: 'GET'
  },
  GetBookingsFilter:{
    host: BOOKING + '/search',
    headers,
    method: 'POST'
  },
  CargoReadyConfirmation: {
    host: ORDER + '/order/confirmation/cargo-ready/',
    headers,
    method: 'PUT'
  },
  CreateShipment: {
    host: SHIPMENT + '/shipment',
    headers,
    method: 'POST'
  },
  GetAllShipments: {
    host: SHIPMENT + '/shipment/search/all',
    headers,
    method: 'GET'
  },
  GetOneShipment: {
    host: SHIPMENT + '/shipment/',
    headers,
    method: 'GET'
  },
  GetShipmentsFilter: {
    host: SHIPMENT + '/shipment/search/filter',
    headers,
    method: 'POST'
  },
  GetExceptionsByShipmentId: {
    host: SHIPMENT + '/shipment/exceptions/',
    headers,
    method: 'GET'
  },

  CreateException: {
    host: SHIPMENT + '/shipment/exceptions/',
    headers,
    method: 'POST'
  },
  CatalogRouting: {
    host: CATALOG + '/all/shipment',
    headers,
    method: 'GET'
  },
  CatalogEvents: {
    host: CATALOG + '/all/events',
    headers,
    method: 'GET'
  },
  CatalogShipmentComments: {
    host: CATALOG + '/all/shipment-comments',
    headers,
    method: 'GET'
  },
  GetRoutingsByShipmentId: {
    host: SHIPMENT + '/shipment/routing/',
    headers,
    method: 'GET'
  },
  GetEventsByShipmentId: {
    host: SHIPMENT + '/shipment/events/',
    headers,
    method: 'GET'
  },
  GetEventsByRowId: {
    host: EVENTS + '/',
    headers,
    method: 'GET'
  },
  CreateEvents: {
    host: EVENTS + '/',
    headers,
    method: 'POST'
  },
  CreateRouting: {
    host: SHIPMENT + '/shipment/routing/',
    headers,
    method: 'POST'
  },
  GetFilesByRowId: {
    host: FILES + '/search/',
    headers,
    method: 'GET'
  },
  CreateEvent: {
    host: SHIPMENT + '/shipment/events',
    headers,
    method: 'POST'
  },
  GetEUGroundResults : {
    host: RATES+'/search/eu-ground-container/',
    headers,
    method: 'GET'
  },
  GetPOManagementResults : {
    host: RATES+'/search/po-management/',
    headers,
    method: 'GET'
  },
  GetWarehousesResults : {
    host: RATES+'/search/warehouses/',
    headers,
    method: 'GET'
  },

  RatesEUInlandFilters : {
    host: RATES+'/search/eu-inland/filters',
    headers,
    method: 'GET'
  },
  GetEUInlandResults : {
    host: RATES+'/search/eu-inland/?',
    headers,
    method: 'GET'
  },
  RatesOceanFreightFilters : {
    host: RATES+'/search/ocean-freight/filters',
    headers,
    method: 'GET'
  },
  GetOceanFreightResults : {
    host: RATES+'/search/ocean-freight/?',
    headers,
    method: 'GET'
  },
  GetCountrys: {
    host: CATALOG + '/all/country',
    headers,
    method: 'GET'
  },
  PostCustomer: {
    host: ADMIN + '/admin/createCustomer',
    headers,
    method: 'POST'
  },
  PostContact: {
    host: ADMIN + '/admin/createContact',
    headers,
    method: 'POST'
  },
  GetListCustomer: {
    host: ADMIN + '/admin/listCustomer',
    headers,
    method: 'GET'
  },
  DeleteCustomer: {
    host: ADMIN + '/admin/deleteCustomer',
    headers,
    method: 'DELETE'
  },
};


export default HTTP_REQUEST;