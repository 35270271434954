import React, { Component } from 'react';
import moment from 'moment';

class Footer extends Component {
    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>&copy; Copyright {moment(new Date()).format('YYYY')}, Interglobo Srl. All rights reserved</span>
            </footer>
        );
    }
}
export default Footer;
