import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import CardTotals from "../../Components/CardTotals";
import { IconMessages, CloseIcon } from "../NewBuild/components/img";
import { saveAs } from "file-saver";
import YearsSelect from "../../Components/CatYears";
import { TotalsCircle } from "../../Components/Loader/";
import Datetime from "react-datetime";

import Loader from "./../../Global/Loader/";

import {
  Popup,
  Segment,
  Sidebar,
  Dropdown,
  Search,
  Icon,
} from "semantic-ui-react";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Collapse,
  Button,
} from "reactstrap";
import TableComponent from "../../Components/Table_2";
import { Services } from "./Services";
import Chart from "react-google-charts";
import Axios from "axios";
import moment from "moment";
import FormFiltros from "./model/form";
import { DatePicker, Button as ButtonAnt, Skeleton, Switch } from "antd";
import Comments from "../../poseidon/Order/order-list/components/Comments/";
import { MyCommandCell } from "../../Components/Table_2/myCommandCell";
import Swal from "sweetalert2";

const { RangePicker } = DatePicker;
const IMG =
  "https://magiclog.blob.core.windows.net/poseidonpic/search_advanced.png";
const IMG_ATTENTION =
  "https://magiclog.blob.core.windows.net/poseidonpic/attentionOrder.png";
const CARD_TOTALS_SUMMARY = [
  {
    Tipo: 1,
    classNameImg: "fas icon-basket-loaded fa-2x",
    total: 0,
    title: "Total PO´s",
    propertiesIcon:
      "col-3 d-flex align-items-center CardTotalPo rounded-left2 ",
    propertiesTitle: "col-9 CardTotalPoTitle rounded-right2 endPosition",
    messageTootip: "Total purchase orders.",
  },
  {
    Tipo: 2,
    classNameImg: "fas fa-clock fa-2x",
    total: 0,
    title: "Pending to confirm on time",
    propertiesIcon:
      "col-3 d-flex align-items-center CardPendingTime rounded-left2",
    propertiesTitle: "col-9 CardPendingTimeTitle rounded-right2 endPosition",
    messageTootip:
      "Total purchase orders to be confirmed that are on time (2 days before comments last call) to be delivered according to the required date.",
  },
  {
    Tipo: 3,
    classNameImg: "fas fa-check-circle fa-2x",
    total: 0,
    title: "Pending to confirm late",
    propertiesIcon:
      "col-3 d-flex align-items-center CardPendingLate rounded-left2",
    propertiesTitle: "col-9 CardPendingLateTitle rounded-right2 endPosition",
    messageTootip:
      "Total purchase orders delayed to confirm that they are not on time to be delivered according to the required date.",
  },
  {
    Tipo: 4,
    classNameImg: "fas fa-exclamation-triangle fa-1x",
    total: 0,
    title: "Urgent PO´s",
    propertiesIcon: "col-3 d-flex align-items-center CardUrgent rounded-left2",
    propertiesTitle: "col-9 CardUrgentTitle rounded-right2 endPosition",
    messageTootip: "Total purchase orders marked as urgent.",
  },
];

const mapColor = {
  12: "danger",
  13: "secondary",
};

const HEADERS = [
  "PO No.",
  "Status",
  "Supplier",
  "Consignee",
  "Creation Date",
  "Requested Delivery Date",
  "Comments Last Call",
  "Actual Cargo Ready",
  "Total QTY Ordered",
];
const KEYS = [
  "PONumber",
  "StatusDesc",
  "SupplierName",
  "ConsigneeName",
  "POCreateDate",
  "RequiredDestination",
  "CommentsLastCall",
  "ActualCargoReadyDate",
  "TotalQtyOrdered",
];

const CHART_FOR_CREATION_DATE = [
  ["x", "Po"],
  ["Jan", 0],
  ["Feb", 0],
  ["Mar", 0],
  ["Apr", 0],
  ["May", 0],
  ["Jun", 0],
  ["Jul", 0],
  ["Aug", 0],
  ["Sep", 0],
  ["Oct", 0],
  ["Nov", 0],
  ["Dec", 0],
];

const editField = "inEdit";
const editColums = [{ key: "ActualCargoReadyDate" }];

function VendorManagement(params) {
  const [dataPOs, setDataPOs] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loaderChartComponents, setLoaderChartComponents] = useState(true);
  const [requiresAttention, setRequiresAttention] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [excelInvoiceLoading, setExcelInvoiceLoading] = useState(false);
  const [numberPage, setNumberPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [viewWindowTotal, setViewWindowTotal] = useState(0);
  const [_customerSelect] = useState(params._customerSelect);
  const [_user] = useState(params._user);
  const [_rolId] = useState(params._rolId);
  const [yearSelected, setYearSelected] = useState(
    moment(new Date()).format("YYYY")
  );
  const [isSearchComments, setIsSearchComments] = useState(true);
  const [handleResCom, setHandleResCom] = useState("unset");
  const [POId, setPOId] = useState(0);
  const [PONumber, setPONumber] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchVendor, setSearchVendor] = useState("");
  const [headers, setHeaders] = useState([
    {
      key: "",
      label: "",
      minWidth: "1em",
      class: "headerTextShipList",
      custom: (value) => (
        <>
          <div>
            <Popup
              size="mini"
              inverted
              content="Show messages"
              trigger={
                <div style={{ cursor: "pointer" }}>
                  <i onClick={() => selectedOrder(value)} className="mr-1 ml-1">
                    <IconMessages />
                  </i>
                </div>
              }
            />
          </div>
        </>
      ),
    },
    {
      key: "",
      label: "",
      minWidth: "1em",
      class: "headerTextShipList",
      custom: (value) => (
        <>
          {value.RequiresAttention === "1" ? (
            <div>
              <Popup
                className="_comments_icon"
                trigger={
                  <div>
                    <img
                      height="22px"
                      width="22px"
                      src={IMG_ATTENTION}
                      alt="img"
                    />
                  </div>
                }
                content="Orders that require attention"
                position="top center"
                size="mini"
                inverted
              ></Popup>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      minWidth: "9em",
      key: "PONumber",
      label: "PO No.",
      custom: (value) => (
        <span className="pono-column">
          <Link to={"/vendor-detail/" + value.POId}>{value.PONumber}</Link>
        </span>
      ),
    },
    {
      minWidth: "11em",
      key: "StatusDesc",
      label: "Status",
      custom: (value) => (
        <Button
          className={
            value.StatusId === 13 ? "btn-oval _gray_button" : "btn-oval"
          }
          color={
            mapColor[value.StatusId] ? mapColor[value.StatusId] : "primary"
          }
          onClick={() => { }}
        >
          {value.StatusDesc}
        </Button>
        // <span className="status-column">{value.StatusDesc}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "SupplierName",
      label: "Supplier",
      custom: (value) => (
        <span className="columsTable">{value.SupplierName}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "ConsigneeName",
      label: "Consignee",
      custom: (value) => (
        <span className="columsTable">{value.ConsigneeName}</span>
      ),
    },
    {
      key: "POCreateDate",
      label: "Creation Date",
      minWidth: "11em",
      custom: (value) => (
        <span className="columsTable">{value.POCreateDate}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "RequiredDestination",
      label: "Requested Delivery Date",
      minWidth: "11em",
      custom: (value) => (
        <span className="columsTable">{value.RequiredDestination}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "",
      label: "Comments Last Call",
      custom: (value) => (
        <span className="columsTable">{value.CommentsLastCall}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "ActualCargoReadyDate",
      label: "Actual Cargo Ready",
      custom: (value) =>
        value.inEdit ? (
          <DatePicker
            defaultValue={
              value.ActualCargoReadyDate
                ? moment(value.ActualCargoReadyDate, "YYYY/MM/DD")
                : ""
            }
            format={"YYYY-MM-DD"}
            style={{ width: "100%" }}
            onChange={(date, dateString) => {
              handleChangeTable(value, dateString);
            }}
          />
        ) : (
          <>
            <span className="columsTable">{value.ActualCargoReadyDate}</span>
          </>
        ),
    },
    {
      minWidth: "11em",
      key: "TotalQtyOrdered",
      label: "Total QTY Ordered",
      custom: (value) => (
        <span className="columsTable">{value.TotalQtyOrdered}</span>
      ),
    },
    {
      minWidth: "15em",
      key: "",
      label: "",
      custom: (value) => (
        <MyCommandCell
          dataItem={value}
          editField={editField}
          edit={Edit}
          cancel={Cancel}
          update={updateRow}
        />
      ),
    },
  ]);

  const [cardsSummary, setCardsSummary] = useState(CARD_TOTALS_SUMMARY);
  const [catalogCombo, setCatalogCombo] = useState([]);
  const [filtros, setFiltros] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState([]);
  const [advancedObj, setAdvancedObj] = useState([]);
  const [statusChart, setStatusChart] = useState([]);

  const [creationDateChart, setcreationDateChart] = useState([]);

  useEffect(() => {
    let newFormFiltros = [...FormFiltros];

    for (let i = 0; i < newFormFiltros.length; i++) {
      newFormFiltros[i].values = [];
    }
    setAdvancedSearch(newFormFiltros);
    get_opportunities();
    getCatalogs();
    getTotal();
  }, []);

  const get_opports_function = useCallback(
    async (pag, require, search, advanced) => {
      let infoTable = {
        client: params._customer,
        pag: pag ? pag : numberPage,
        limit: limit,
        search: search,
        advanced: advanced ? advanced.length !== 0 ? advanced : advancedObj : advancedObj,
        requiresAttention: require !== undefined ? require : requiresAttention,
        user: params._user,
        rolId: _rolId,
      };

      try {
        let url = `${Services.VENDOR_MANAGEMENT.path}pos/get-pos`;
        let response = await Axios.post(url, infoTable);
        let data = response.data;
        data.data.pos.map((e) => {
          e["Page"] = pag ? pag : numberPage;
          e["flatRequiere"] = require ? require : requiresAttention
          e["flatSearch"] = search ? search : searchVendor
          e["flatAdvanced"] = advanced ? advanced.length !== 0 ? advanced : advancedObj : advancedObj
        });
        // console.log('data-->', data);
        setNumberPage(pag ? pag : numberPage)
        setDataPOs(data.data.pos);
        setTotalPages(data.totalPage);
        return data;
      } catch (error) {
        setFullLoader(true);
        console.log("error", error);
      }
    },
    [numberPage, limit, searchVendor, advancedObj, _rolId]
  );

  // async function setPagRow(pag) {
  //   const resp = await get_opports_function();
  //   let setNum = [];
  //   resp.data.pos.map((e) => {
  //     e["Page"] = pag;
  //     setNum.push(e);
  //   });

  //   setDataPOs(setNum);
  // }

  const Edit = useCallback(async (poSelected) => {
    let page = poSelected.Page;
    let require = poSelected.flatRequiere;
    let search = poSelected.flatSearch;
    let advanced = poSelected.flatAdvanced;
    setNumberPage(page);

    try {
      const resp = await get_opports_function(page, require, search, advanced);
      setDataPOs(
        resp.data.pos.map((item) =>
          item.POId === poSelected.POId
            ? {
              ...item,
              inEdit: true,
            }
            : item
        )
      );
    } catch (error) {
      setFullLoader(true);
      console.log("error", error);
    }
  }, [numberPage, requiresAttention]);

  const Cancel = useCallback(async (poSelected) => {
    let page = poSelected.Page;
    let require = poSelected.flatRequiere;
    let search = poSelected.flatSearch;
    let advanced = poSelected.flatAdvanced;

    setNumberPage(page);
    const resp = await get_opports_function(page, require, search, advanced);
    // let setNum = [];
    // resp.data.pos.map((e) => {
    //   e["Page"] = page;
    //   setNum.push(e);
    // });

    // setDataPOs(setNum);
    setDataPOs(resp.data.pos);
  }, []);

  function isKeyExists(obj, key) {
    return key in obj;
  }

  const handleChangeTable = useCallback(async (po, value) => {
    let page = po.Page;
    let require = po.flatRequiere;
    let search = po.flatSearch;
    let advanced = po.flatAdvanced;
    setNumberPage(page);

    try {
      const resp = await get_opports_function(page, require, search, advanced);
      // console.log("🚀 ~ file: index.js:473 ~ resp:", resp);

      // setDataPOs(
      //   data.pos.map((e) =>
      //     e.POId === po.POId
      //       ? editColums.filter((item) => {
      //           console.log("item-->", Boolean(po[item.key]));
      //           if (Boolean(po[item.key])) {
      //             console.log("si viene y el valor es-->", po[item.key]);
      //           }
      //         })
      //       : e
      //   )
      // );

      /* 
      Trena con los que no traen FeedbackSharp
       */
      let newItems = [];
      resp.data.pos.map((e) => {
        if (e.POId === po.POId) {
          e["inEdit"] = true;
          editColums.filter((item) => {
            if (isKeyExists(e, item.key)) {
              e[item.key] = value;
            }
          });
          newItems.push(e);
        } else {
          newItems.push(e);
        }
      });
      // resp.data.pos.map((e) => {
      //   e.POId === po.POId
      //     ? editColums.filter((item) => {
      //         console.log("🚀 ~ file: index.js:478 ~ ?editColums.filter ~ item:", item)
      //         if (isKeyExists(e, item.key)) {
      //           e[item.key] = value;
      //           e["inEdit"] = true;
      //           // e['Page']= page
      //           newItems.push(e);
      //         }
      //       })

      //     : newItems.push(e);
      // });
      setDataPOs(newItems);

      // if (e.POId === po.POId) {
      //   console.log("si es el item ", key);
      //   editColums.filter((item) => {
      //     console.log("item-->", item);
      //     if (po[item.key]) {
      //       console.log("si viene y el valor es-->", po[item.key]);
      //     }
      //   });
      // }
    } catch (error) {
      setFullLoader(true);
      console.log("error", error);
    }
  },
    [dataPOs]
  );

  const updateRow = useCallback(async (item) => {
    // console.log("item---->", item);
    let idpo = item.POId;
    let page = item.Page;
    let require = item.flatRequiere;
    let search = item.flatSearch;
    let advanced = item.flatAdvanced;
    setNumberPage(page);

    try {
      let url = `${Services.VENDOR_MANAGEMENT.path}pos/update-po/${idpo}`;
      let response = await Axios.put(url, item);
      if (response.status === 200) {
        Swal.fire({
          // position: 'top-end',
          type: "success",
          title: "Order Updated Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        const resp = await get_opports_function(page, require, search, advanced);
        setDataPOs(resp.data.pos);
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js:472 ~ updateRow ~ error:", error);
    }
  }, []);


  useEffect(() => {
    get_opportunities();
    generateFilters();
    getCharts();
  }, [
    // numberPage,
    // searchVendor,
    advancedObj,
    // requiresAttention,
    catalogCombo,
    yearSelected,
  ]);

  const getCharts = useCallback(async () => {
    let infoCustomers = {
      client: _customerSelect,
      user: _user,
      rolId: _rolId,
      yearSelected: yearSelected,
    };

    try {
      let url = `${Services.VENDOR_MANAGEMENT.path}pos/get-charts`;
      let response = await Axios.post(url, infoCustomers);
      let poStatus = response.data.dataStatusPo;
      let poCreationDate = response.data.dataCreationDate;
      let maxTotal = response.data.maxTotal;

      setcreationDateChart(poCreationDate);
      setStatusChart(poStatus);
      setViewWindowTotal(maxTotal);
      setLoaderChartComponents(false);
    } catch (error) {
      console.log("error", error);
      setLoaderChartComponents(false);
    }
  });

  const getTotal = useCallback(async () => {
    let infoCustomers = {
      client: _customerSelect,
      user: _user,
      rolId: _rolId,
    };

    try {
      let url = `${Services.VENDOR_MANAGEMENT.path}pos/get-summary-card`;
      let response = await Axios.post(url, infoCustomers);
      let data = response.data.data;
      let newCardsSummary = [...cardsSummary];

      for (let y = 0; y < data.length; y++) {
        const e = data[y];
        for (let i = 0; i < newCardsSummary.length; i++) {
          const element = newCardsSummary[i];
          if (element.Tipo === e.Tipo) {
            element.total = e.Total;
          }
        }
      }
      setCardsSummary(newCardsSummary);
      setLoadingCard(false);
    } catch (error) {
      setLoadingCard(false);
      console.log("error", error);
    }
  });

  const selectedOrder = (order) => {
    setVisible(!visible);
    setHandleResCom("set");
    setIsSearchComments(true);
    setPOId(order.POId);
    setPONumber(order.PONumber);
  };

  const handleChangeSelectMultiple = (id, value) => {
    for (let i = 0; i < advancedSearch.length; i++) {
      const e = advancedSearch[i];
      if (e.id === id) {
        e.values = value;
      }
    }
    setAdvancedSearch(advancedSearch);
    generateFilters();
    findAdvanced();
  };

  const resetAdvance = () => {
    advancedSearch.map((advData) => {
      if (advData.type == "Datetime") {
        advData.values = [];
        advData.value = "";
      } else {
        advData.values = [];
        advData.value = "";
      }
    });

    setAdvancedSearch(advancedSearch);
    generateFilters();
    findAdvanced();
  };

  const findAdvanced = () => {
    let obj = [];
    advancedSearch.map((e) => {
      if (e.values && e.values.length > 0) {
        let newPush = {
          id: e.id2,
          UseIN: true,
          isDate: e.type === "Datetime" ? true : false,
          value: e.values,
        };
        obj.push(newPush);
      } else {
        obj.push({
          id: e.id,
          UseIN: false,
          value: e.value,
        });
      }
    });
    setAdvancedObj(obj);
    setTotalPages(1);
    setNumberPage(1);
  };

  const get_opportunities = useCallback(async () => {
    try {
      const resp = await get_opports_function();
      // let setNum = [];
      // resp.data.pos.map((e) => {
      //   e["Page"] = numberPage;
      //   setNum.push(e);
      // });

      // setDataPOs(setNum);
      setDataPOs(resp.data.pos);
      setTotalPages(resp.totalPage);
      setFullLoader(true);
    } catch (error) {
      setFullLoader(true);
      console.log("error", error);
    }
  });

  const getCatalogs = useCallback(async () => {
    let data = {
      client: params._customer,
      user: params._user,
      rolId: _rolId,
    };
    try {
      let url = `${Services.VENDOR_MANAGEMENT.path}pos/catalog-vendor`;
      let response = await Axios.post(url, data);
      setCatalogCombo(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  });

  const handleChangeDatePicKer = (name, event) => {
    try {
      if (event) {
        advancedSearch.map((search) => {
          if (search.id == name) {
            search.values = [
              {
                init: event[0].format("YYYY-MM-DD HH:mm:ss"),
                end: event[1].format("YYYY-MM-DD HH:mm:ss"),
              },
            ];
          }
        });
        setAdvancedSearch(advancedSearch);
        generateFilters();
        findAdvanced();
      } else {
        advancedSearch.map((search) => {
          if (search.id == name) {
            search.values = [];
          }
        });
        setAdvancedSearch(advancedSearch);
        generateFilters();
        findAdvanced();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const generateFilters = (newCat) => {
    let Inputs = advancedSearch.map((e, i) => {
      if (e.type === "Datetime")
        return (
          <div
            key={e.id}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.label} {e.undefined ? "!" : ""}
            </label>
            <RangePicker
              className="col-md-12"
              placeholder={["Start date", "End date"]}
              value={
                Array.isArray(e.values)
                  ? e.values.length > 0
                    ? [
                      moment(new Date(e.values[0].init)),
                      moment(new Date(e.values[0].end)),
                    ]
                    : []
                  : []
              }
              id={e.id}
              onChange={(evt) => handleChangeDatePicKer(e.id, evt)}
              size="middle"
              format={e.format}
            />
          </div>
        );
      else if (e.type === "multipleSelect")
        return (
          <div
            key={e.id}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.label} {e.undefined ? "!" : ""}
            </label>
            <Dropdown
              className="NewClass"
              onChange={(evt, { value }) =>
                handleChangeSelectMultiple(e.id, value)
              }
              id={e.id}
              options={catalogCombo[e.options]}
              clearable
              search
              selection
              fluid
              value={e.values}
              multiple
              allowAdditions
            />
          </div>
        );
    });
    let NewInput = (
      <div className="col-12 row">
        <div className="col-12 divContenEnd ">
          <em
            class="fa-2x mr-2 fas fa-eraser"
            style={{
              color: "#2B3B57",
              marginTop: "7px",
              cursor: "pointer",
              textAlign: "end",
            }}
            title={"Remove filters"}
            onClick={() => resetAdvance()}
          />
        </div>

        {Inputs}
      </div>
    );
    setFiltros(NewInput);
  };

  const generateInvoiceExcel = async () => {
    setExcelInvoiceLoading(true);

    let infoTable = {
      xmls: true,
      client: params._customer,
      pag: numberPage,
      limit: limit,
      search: searchVendor,
      advanced: advancedObj,
      requiresAttention: requiresAttention,
      user: params._user,
      rolId: _rolId,
    };

    try {
      let url = `${Services.VENDOR_MANAGEMENT.path}pos/get-pos-xmls`;
      let response = await Axios.post(url, infoTable);
      let data = response.data.data.pos;
      let dateNow = new Date();

      let titleDownload = `PO list-${dateNow.getFullYear() +
        (dateNow.getMonth() + 1).toString().padStart(2, "0") +
        dateNow
          .getDate()
          .toString()
          .padStart(2, "0")}`;

      let info = {
        config: {},
      };

      let options = {
        headers: HEADERS,
        keys: KEYS,
        image: {
          name: "poseidon",
        },
        sheetName: "PO´s",
      };

      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        e.ActualCargoReadyDate = e.ActualCargoReadyDate
          ? e.ActualCargoReadyDate.replace(/-/g, "/")
          : "";
        e.POCreateDate = e.POCreateDate
          ? e.POCreateDate.replace(/-/g, "/")
          : "";
        e.CommentsLastCall = e.CommentsLastCall
          ? e.CommentsLastCall.replace(/-/g, "/")
          : "";
        e.RequiredDestination = e.RequiredDestination
          ? e.RequiredDestination.replace(/-/g, "/")
          : "";
      }

      let infoXmls = {
        info,
        data,
        options,
      };

      let XMLS_DOWNDLOAD = await Axios.post(
        `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`,
        infoXmls
      );

      if (XMLS_DOWNDLOAD.data.base64) {
        const fileName = titleDownload + ".xlsx";
        const linkSource = `data:application/xlsx;base64,${XMLS_DOWNDLOAD.data.base64}`;
        saveAs(linkSource, fileName);
        setExcelInvoiceLoading(false);
      } else console.warn("Error downloading file", XMLS_DOWNDLOAD);
    } catch (error) {
      setExcelInvoiceLoading(false);
      console.log("error", error);
    }
  };

  const resetTable = () => {
    console.log("resetTable");
  };
  return (
    <>
      <Loader hidden={fullLoader} />
      <ContentWrapper>
        <div className="content-heading">
          <i className="icon-people  fa-1x mr-2"></i>
          Vendor Management
        </div>
        <Row>
          {cardsSummary.map((item) => {
            return (
              <CardTotals
                image={
                  <i
                    style={{
                      color: "#ffff",
                      fontSize: "35px",
                      marginLeft: "-5px",
                    }}
                    className={item.classNameImg}
                  ></i>
                }
                messageTootip={item.messageTootip}
                xl={3}
                md={6}
                sm={6}
                loading={loadingCard}
                total={item.total}
                title={item.title}
                propertiesIcon={item.propertiesIcon}
                propertiesTitle={item.propertiesTitle}
              />
            );
          })}

          <Col xs={12} md={6} className="ml-auto mr-auto">
            <Card className="myCard card-chart minHeightCharts">
              <CardHeader>
                <CardTitle tag="h6">PO´s by Status</CardTitle>
              </CardHeader>
              <CardBody>
                {loaderChartComponents ? (
                  <TotalsCircle height={220} />
                ) : (
                  <div className="canvas-container">
                    {statusChart.length === 0 ? (
                      " "
                    ) : (
                      <Chart
                        style={{
                          width: "100%",
                          marginTop: "-15px",
                        }}
                        className={"valueTable"}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={statusChart}
                        options={{
                          width: "200%",
                          height: "250px",

                          chartArea: {
                            width: "70%",
                          },
                          hAxis: {
                            format: "#,###",
                            minValue: 0,
                          },
                          tooltip: { isHtml: true },
                          legend: {
                            position: "none",
                          },
                          animation: {
                            startup: true,
                            easing: "linear",
                            duration: 1000,
                          },
                        }}
                        rootProps={{ "data-testid": "6" }}
                      />
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6} className="ml-auto mr-auto">
            <Card className="myCard card-chart minHeightCharts">
              <CardHeader>
                <CardTitle tag="h6">PO´s by Creation Date</CardTitle>
                <div className="col-12 row divContenEnd">
                  {statusChart.length === 0 ? (
                    ""
                  ) : (
                    <YearsSelect
                      yearselected={yearSelected}
                      yearsPrevious={2}
                      onChangeYear={(value) => {
                        setYearSelected(value.value)
                      }
                      }
                    />
                  )}
                </div>
              </CardHeader>
              <CardBody>
                {loaderChartComponents ? (
                  <TotalsCircle height={220} />
                ) : creationDateChart.length === 0 ? (
                  ""
                ) : (
                  <Chart
                    style={{
                      width: "100%",
                      marginTop: "-27px",
                    }}
                    className={"valueTable"}
                    // chartType="AreaChart"
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={
                      creationDateChart.length === 1
                        ? CHART_FOR_CREATION_DATE
                        : creationDateChart
                    }
                    options={{
                      width: "100%",
                      height: "250px",
                      curveType: "function",
                      legend: {
                        position: "none",
                      },
                      tooltip: { isHtml: true },
                      vAxis: {
                        minValue: 0,
                        viewWindow: {
                          min: 0,
                          max: viewWindowTotal ? viewWindowTotal : 0,
                        },
                      },

                      pointSize: 5,
                      series: {
                        0: {
                          color: "#13B0F0",

                          lineWidth: 2,
                        },
                      },
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className={"myCard"}>
          <div className="col-12" style={{ paddingTop: "1%" }}></div>
          <div class="row col-xs-6 col-md-12">
            <div style={{ marginTop: "1em" }} class="col-xs-6 col-md-3">
              {/* {options.search ? ( */}
              <Search
                input={{
                  icon: "search",
                  iconPosition: "left",
                  fluid: true,
                }}
                style={{ fontSize: "12px" }}
                placeholder="..."
                value={searchVendor}
                open={false}
                name="search"
                id="search"
                // onSearchChange={(evt, data) => setSearchVendor(data.value)}
                onSearchChange={(evt, data) => {
                  get_opports_function(1, false, data.value); setSearchVendor(data.value); setRequiresAttention(false)
                }}
              />
            </div>
            <div
              class="col-xs-3 col-md-4 row"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <img
                width={22}
                height={22}
                src={IMG}
                alt="xml"
                title={"Advance filter"}
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer" }}
              ></img>
              <div className="mr-2 ml-2">
                <div>
                  {excelInvoiceLoading ? (
                    <Icon
                      loading={excelInvoiceLoading}
                      style={{ marginTop: "9px" }}
                      name="circle notch"
                      size="large"
                    />
                  ) : (
                    <i
                      className="fa-2x fas fa-file-excel"
                      style={{
                        color: "#66bb6a",
                        cursor: "pointer",
                        fontSize: "25px",
                        marginTop: "3px",
                      }}
                      title={"download_excel"}
                      onClick={() => generateInvoiceExcel()}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <div
              class="col-xs-3 col-md-3 divContenEnd row"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
            ></div>

            <div
              class="col-xs-3 col-md-2 divContenCenter row"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <div className="col-12 row divContenCenter">
                <label className="labelTitleAvancSearc">
                  Orders require attention
                </label>
              </div>
              <div className="col-12 row divContenCenter">
                <img width={22} height={22} src={IMG_ATTENTION} alt="xml"></img>
                <div className="mr-2 ml-2">
                  <Switch
                    defaultChecked
                    checked={requiresAttention}
                    onChange={(evt) => { setRequiresAttention(evt); get_opports_function(1, evt) }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Collapse isOpen={isOpen}>
              <div className="row" style={{ marginBottom: "5px" }}>
                {filtros}
              </div>
              <hr />
            </Collapse>
          </div>

          <Sidebar.Pushable as={Segment}>
            <Sidebar
              direction="right"
              animation="overlay"
              icon="labeled"
              visible={visible}
              width="very wide"
              id={"sidebarComentsHeight"}
            >
              <Col xs={12} md={12} lg={12} className="d-flex row">
                <Col xs={12} md={12} lg={12} className="pr-0 mt-1">
                  <div className="d-flex justify-content-between">
                    <div className="mt-2">PO No.# {PONumber}</div>
                    <div
                      className="mt-1"
                      onClick={() => {
                        setVisible(false);
                        setIsSearchComments(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </Col>
              </Col>
              <Comments
                _customer={_customerSelect}
                POId={POId}
                _user={_user}
                _rolId={_rolId}
                isSearchComments={isSearchComments}
                resetTable={resetTable}
              />
            </Sidebar>
            <Sidebar.Pusher>
              <Segment basic size="mini">
                <TableComponent
                  columns={headers}
                  data={dataPOs}
                  totalPages={totalPages}
                  activePague={numberPage}
                  onPageChange={(evt, data) => {
                    get_opports_function(data.activePage, requiresAttention);
                    setNumberPage(data.activePage);
                    // setPagRow(data.activePage)
                  }}
                ></TableComponent>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Card>
      </ContentWrapper>
    </>
  );
}

export default withRouter(VendorManagement);
