import React, { Component } from 'react';
import restService from '../../../../services/restService';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Card, CardBody, Button } from 'reactstrap';
import { Input } from 'reactstrap';
import AttachedOrder from './components/AttachedOrder/index';
import BookingDetail from './components/booking-details/index';
import Container from './components/Container/index';
import Packages from './components/Packages/index';
import CarrierDetail from './components/Carrier-detail/index';
import Document from './components/Booking-documents/index';
import Comments from './components/Comments/index';
import Errors from '../../../template/Errors';
import Header from '../../../template/Layout/Header';
import checked from '../../../../resources/checked.png';
import cancel from '../../../../resources/x-button.png';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {withRouter} from 'react-router-dom';
const rest = new restService();

class FormExtended extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      list: [],
      modal: false,
      comment : '',
      deliveriesArray: [],
      containersOpackages: '',
      CommenRejectd: '',
      error: {
        open: false,
        message: '',
        type: 'error'
      },
    };

  }

    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    buildArraydeliveries = () => {
      const { list } = this.state;
      let array = [];
      list.forEach((e) => array.push({ value: e.PONumber, label: e.PONumber }));
      this.setState({ deliveriesArray: array });
    }

    getOrdersList = () => {
      rest.EXEC({ _function: 'GetOrdersList', params: '' }, fetch)
        .then(success => this.setState({ list: success.data }, () => this.buildArraydeliveries()))
        .catch(error => { });
    }



    componentDidMount = () => {
      this.getOneOrder();
      this.getOrdersList();
    }

    handleClickOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false, CommenRejectd: '' });
    };

    commentCapture = name => event => {
      var elem = document.getElementById('pruebas');
      elem.style.color = 'white';
      this.setState({
        CommenRejectd: event.target.value
      });
    }

    validate = () => {
      const { CommenRejectd } = this.state;
      if (CommenRejectd === '') {
        this.setState({
          error: {
            message: 'Comment is required',
            open: true,
            type: 'warning'
          }
        });
        var elem = document.getElementById('pruebas');
        elem.style.color = 'red';
      }
      else {
        this.sendMail();
      }
    }



    getOneOrder = () => {
      rest.EXEC({ _function: 'GetOneBooking', params: this.props.match.params.id + '?type=shipment' }, fetch).then(success => {
        if (success.data) {
          this.setState({
            data: success.data
          });
        }
      }).catch(error => {
      });
    }


    toggle = evt => {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }


    handleChange = name => evt =>{
      this.setState({
        [name]:evt.target.value
      });
    }

    updateData = StatusId => Event =>{
      let {BookingId} = this.state.data;
      let {comment} = this.state;

      rest.EXEC({
        _function : 'PutBooking',
        data : {
          BookingId,
          comments:comment,
          StatusId
        },
        params : ''
      },fetch).then(success=>{
        this.setState(prevState => ({
          modal: !prevState.modal
        }),()=>{
          this.getOneOrder();
        });
      }).catch(error=>{
        console.error(error);
      });
    }


    render() {
      const {BookingNumber} = this.state.data;
      return (
            <>
                <Header />
                {this.state.data.StatusId === '21' ?
                  <ContentWrapper>
                    <div>
                      <div className="content-heading">
                        <div className="row">
                          <em className="fas fa-thumbs-up fa-1x mr-2"></em>
                                    Booking Confirmation
                        </div>
                      </div>
                    </div>
                    <Card className="myCard">
                      <CardBody>
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-3">
                                <legend>Booking Number: {BookingNumber}</legend>
                              </div>
                              <div className="col-2">
                              </div>
                              <div className="col-12">
                                <AttachedOrder data={this.state.data}></AttachedOrder>
                              </div>

                              <div className="col-12">
                                <BookingDetail data={this.state.data}></BookingDetail>
                              </div>
                              {this.state.data.TransportMode === 'FCL' ?
                                <div className="col-12">
                                  <Container data={this.state.data}></Container>
                                </div> :

                                <div className="col-12">
                                  <Packages data={this.state.data}></Packages>
                                </div>
                              }
                              <div className="col-12">
                                <CarrierDetail data={this.state.data}></CarrierDetail>
                              </div>
                              <div className="col-12">
                                <Document id={this.props.match.params.id}></Document>
                              </div>
                              <div className="col-12">
                                <Comments></Comments>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6 col-lg-8 col-sm-12"></div>
                                  <div className="col-md-3 col-lg-2 col-sm-6">
                                    <Button variant="contained" color="danger" className="button" onClick={this.toggle} >
                                                            Booking rejected
                                    </Button>
                                  </div>
                                  <div className="col-md-3 col-lg-1 col-sm-6">
                                    <Button variant="contained" color="primary" className="button" onClick={this.updateData(22)}>
                                                            Booking approval
                                    </Button>
                                  </div>
                                  <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
                                  <div>
                                    <Modal isOpen={this.state.modal} toggle={this.toggle} >
                                      <ModalHeader toggle={this.toggle}>Add a comment with the reason for the rejection.</ModalHeader>
                                      <ModalBody>
                                        <div className="row">
                                          <div className='col-12'>
                                            <label >{'Comment'}</label>
                                            <Input
                                              required
                                              type="textarea"
                                              name='comment'
                                              id='comment'
                                              value={this.state.comment}
                                              onChange={this.handleChange('comment')}
                                            >
                                            </Input>
                                          </div>
                                          <div className="col-md-12 col-lg-12 col-sm-12">
                                            <hr></hr>
                                          </div>
                                          <div className="col-md-3 col-lg-1 col-sm-6">
                                            <Button variant="contained" color="primary" className="button" onClick={this.updateData(23)} size="lg">
                                                                        Reject
                                            </Button>
                                          </div>
                                        </div>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>

                  </ContentWrapper>
                  : ''}
                {this.state.data.StatusId === '22' ?
                  <Card style={{ height: '800px' }}>
                    <CardBody >
                      <div className="row">
                        <div className="col-12">
                          <center><span>{`BN ${this.state.data.BookingNumber} was confirmed`}</span></center>
                        </div>
                        <div className="col-12">
                          <hr></hr>
                        </div>
                        <div className="col-12" style={{ paddingTop: '10%' }}>
                          <center><img alt="image" src={checked} height={200} width={200} ></img></center>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  :
                  ' '
                }
                {this.state.data.StatusId === '23' ?
                  <Card style={{ height: '800px' }}>
                    <CardBody >
                      <div className="row">
                        <div className="col-12">
                          <center><span>{`BN ${this.state.data.BookingNumber} was rejected`}</span></center>
                        </div>
                        <div className="col-12">
                          <hr></hr>
                        </div>
                        <div className="col-12" style={{ paddingTop: '10%' }}>
                          <center><img alt="image-cancel" src={cancel} height={200} width={200} ></img></center>
                        </div>
                      </div>
                    </CardBody>
                  </Card> : ''}
            </>
      );
    }
}
export default withRouter(FormExtended);