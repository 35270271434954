import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Popover from './../../Global/Popup/';
// import {IMAGE, IconChat, IconNewComment} from './components/img';
// import './styles.scss';

const mapColor = {
  12: 'danger',
  13: 'secondary'
};

/*=============================================
=            order List Comments              =
=============================================*/

export const columns = [
  {
    label: 'Poseidon Code', key: 'Code', minWidth: 180, custom: (value) => (
      <Col md={12} xl={12}>
        <Row>
          <Col md={1}>
            <center>
              <em style={{ color: value.flat === 1 ? '#35c677' : 'red', marginTop: '5px' }} className="fa-1x mr-1 fas fa-circle"></em>
            </center></Col>
          <Col md={7}>
            {value.Code}
          </Col>
        </Row>
      </Col>
    )
  },
  { label: 'Name', key: 'Name', minWidth: 180},
  { label: 'Poseidon Address', key: 'Address', minWidth: 180 },
  { label: 'WPS Code', key: 'WPSCode', minWidth: 180 },
  { label: 'Name', key: 'WPSName', minWidth: 180 },
  { label: 'WPS Address', key: 'WPSAddress', minWidth: 100 },
];

export const columnsWps = [
  { label: 'Entity Code', key: 'EntryCode', minWidth: 180},
  { label: 'Company Code', key: 'CompanyCode', minWidth: 180 },
  { label: 'Entity No', key: 'EntityNo', minWidth: 180 },
  { label: 'Entity Name', key: 'EntityName', minWidth: 180 },
  { label: 'CdcTS', key: 'CdcTS', minWidth: 100 },
  { label: 'Address', key: 'Address', minWidth: 100 },
  { label: 'Location', key: 'Location', minWidth: 100 },
];

export const CloseIcon = () => (
  <svg height="24px" id="Layer_1_close" version="1.1" viewBox="0 0 90 90" width="24px" xmlns="http://www.w3.org/2000/svg">
    <circle className="st73" cx="45" cy="45" r="45" /><g><g><rect className="st7" height="50.9116898" transform="matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -19.3467178 45.2928925)" width="7.999836" x="41.0000801" y="20.5441551" /></g></g><g><g><rect className="st7" height="7.999836" transform="matrix(0.7071678 -0.7070457 0.7070457 0.7071678 -19.3466549 45.2873383)" width="50.9116898" x="19.5441551" y="42.0000801" /></g></g>
  </svg>
);

export const TrashIcon = () => (
  <svg enable-background="new 0 0 500 500" height="18px" id="Layer_1_trash" version="1.1" viewBox="0 0 500 500" width="18px">
    <path clip-rule="evenodd" d="M170,43h23v46h-23V43z" fill="#EF4823" fill-rule="evenodd" /><path clip-rule="evenodd" d="M308,43h23v46h-23V43z" fill="#EF4823" fill-rule="evenodd" />
    <path clip-rule="evenodd" d="M170,20h161v23H170V20z" fill="#EF4823" fill-rule="evenodd" /><path clip-rule="evenodd" d="M78,66h345v69H78V66z" fill="#EF4823" fill-rule="evenodd" />
    <path clip-rule="evenodd" d="M101,89v391h299V89H101z M193,411h-23V135h23V411z M262,411h-23  V135h23V411z M331,411h-23V135h23V411z" fill="#EF4823" fill-rule="evenodd" />
  </svg>
);

