
/*eslint-disable*/
import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Col, Row, Container, Label } from "reactstrap";
import ContentWrapper from '../../template/Layout/ContentWrapper';
import { HorizontalBar } from 'react-chartjs-2'
import Filters from "./components/filters";
import Swal from "sweetalert2";
import { Tab, Popup, Sidebar, Segment, Menu, Dropdown } from "semantic-ui-react";
import { FormComponent } from './detail/components/Form'
import Table from '../../template/TableComponents/Table'
import CardBody from "reactstrap/lib/CardBody";
import Axios from "axios";
import { Model_New_Build } from "./detail/components/Model";
import { Services } from './Services'
import { IconMessages, Warning } from './components/img'
import Courier from './components/svg_img/courier.svg'
import { Emoji } from './config';
import { CloseIcon } from "../Order/order-list/config";
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import Cards from "./components/Card"
import AddMasterShip from './components/addMasterShip'
import AddNewPO from './components/newPO'
import FormCreator from "../../Components/FormBuilder";
import TableFilterComponents from '../../Components/TableFilters'
// import Table2 from "../../../components/template/TableComponents/Table";
import { ValidatorService, ValidatorSchema, ValidatorSchema2 } from "../../Components/Helpers";


import "chartjs-plugin-datalabels";

// import ReactTable from "react-table";


// import IconModule from './components/svg_img/iconModule.svg'
import Loader from "../../Global/Loader";
const PurchaseOrders = lazy(() => (import('./Purchase_Orders')))
const Shipments = lazy(() => (import('./shipments')))
// const TableFilterComponents = React.lazy(() => import("../../Components/TableFilters"));
const DetailPO = lazy(() => (import('./detail/detailPO')))
const DetailMS = lazy(() => (import('./detail/detailMS')))
const TableFilter = React.lazy(() => import("../../Components/TableFilters/tableFilter"));


const headers = ['Po No.', 'Logistic PO (FCA)', 'Logistic PO (FOB)', 'Line', 'Status', 'Project', 'Department', 'Supplier', 'POL', 'Type of delivery', 'Ready Date (MSC)', 'Ready Date (IGQ)', 'Cargo readiness', 'ETD', 'ETA', 'Delivery Date (MSC)', 'Delivery Date (IGQ)', 'New planed delivery', 'On time delivery', 'Combined', 'Master Shipment', 'Operator', 'Interglobo File'];
const keys = ['PONo', 'CustomerReference1Flat', 'CustomerReference2Flat', 'NoLine', 'Status', 'NewBuild', 'DepartmentFlat', 'Supplier', 'POLFlat', 'TypeDelivery', 'PromiseCargoReadyFlat', 'ConfirmedCargoReadyFlat', 'CargoReadyAlert', 'ETDFlat', 'ETAFlat', 'DateDeliveryFlat', 'ActualDeliveryDateFlat', 'NewPlannedDeliveryDateFlat', 'DeliveryAlert', 'CombinedLabel', 'MasterShipmentFlat', 'OperatorFlat', 'IntergloboFileFlat'];
const config = [
	// { title: '', xls: true, first: false, foot: { show: false, style: { fontSize: '12px', fontWeight: 'bold' } }, header: { link: false, hide: true, icon: false }, data: { link: false, progress: false, icon: true, status: false, }, id: 'tiempo', link: '/shipmentsDetail/', className: 'col-md-3 col-lg-2 col-sm-6' },
	{ title: 'PO Number', id: 'PONo', xls: true, link: '/visibility-detail-po/' },
	{ title: 'Status', id: 'Status', xls: true },
	{ title: 'Supplier', id: 'Supplier', xls: true },
	{ title: 'Project', xls: true, id: 'NewBuild' },
	{ title: 'Department', xls: true, type: 7, id: 'Department' },
	{ title: 'Container No', id: 'Containers', xls: true },
	{ title: 'Buyer', xls: true, id: 'Buyer' },
	{ title: 'Value', id: 'Value', xls: true },
	{ title: 'Creation Date', id: 'CreationDate', xls: true },
]

const Color = { 0: '#FF0000', 1: '#7E7E7E', 2: '#000000', 'On Time': '#32CB2F', 'Out of Time': '#FF0000', 'Out of Project Cut-off': '#000000' }
// const Message = {
// 	CargoReadyAlert: { '#FF0000': 'Cargo readiness - Out of Time', '#32CB2F': 'Cargo readiness - On Time', '#B5B2B2': 'Cargo readiness' },
// 	DeliveryAlert: { '#FF0000': 'Delivery - Out of Time', '#32CB2F': 'Delivery - On Time', '#B5B2B2': 'Delivery', '#000000': 'Delivery - Out of Project Cut-off' }
// }


const TransportMode = {
	1: 'fas fa-ship fa-lg mr-1 ml-1',
	2: 'fas fa-ship fa-lg mr-1 ml-1',
	3: 'fas fa-plane fa-lg mr-1 ml-1',
	4: 'fas fa-truck fa-lg mr-1 ml-1',
	5: 'fas fa-truck fa-lg mr-1 ml-1',
	6: <img src={Courier} />
}


function NewBuild(props) {
	const [customerSelect, setCustomerSelect] = useState(undefined)
	const [userId, setUserId] = useState(undefined)
	const [shipLineIdSelected, setShipLineIdSelected] = useState(undefined)
	const [poLineIdSelected, setPoLineIdSelected] = useState(undefined)
	const [dataPO, setDataPO] = useState([])
	const [dataShipments, setDataShipments] = useState([])
	const [dataShipments2, setDataShipments2] = useState([])
	const [filtros, setFiltros] = useState({})
	const [modelNewBuild, setModelNewBuild] = useState({ ...Model_New_Build() })
	const [Catalogs, setCatalogs] = useState({ ...Model_New_Build().Catalogs });
	const [comments, setComments] = useState([])
	const [dataCharts, setDataCharts] = useState({ shipStatus: { labels: [], data: [] } })
	const [fullLoader, setFullLoader] = useState(false)
	const [shipmentsChart, setShipmentsChart] = useState({})
	const [activeTab, setActiveTab] = useState(0)
	const [flat, setFlat] = useState(false)
	const [dataByPague, setDataByPague] = useState({ PO: { activePage: 1, limit: 5 }, Shipments: { activePage: 1, limit: 10 } })

	const [dataCard, setDataCard] = useState([])
	const [activeDetailPO, setActiveDetailPO] = useState(false)
	const [activeDetailMS, setActiveDetailMS] = useState(false)
	const [poId, setPoId] = useState(undefined)
	const [noLine, setNoLine] = useState(undefined)
	const [activePagePO, setActivePagePO] = useState(1)
	const [valueSearchPO, setValueSearchPO] = useState('')
	const [itemsForPage, setItemsForPage] = useState(5)
	const [totalPagesPO, setTotalPagesPO] = useState(0)
	const [newDataFilter, setNewDataFilter] = useState([])
	const [loaderExcel, setLoaderExcel] = useState(false)
	const [queryFilter, setQueryFilter] = useState(undefined)


	//variables shipments
	const [activePageShip, setActivePageShip] = useState(1)
	const [totalPageShip, setTotalPageShip] = useState(0)
	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [tableRows, setTableRows] = useState([])
	const [tableColums, setTableColums] = useState([])
	const [activeFilterTableShip, setActiveFilterTableShip] = useState([])
	const [MS, setMS] = useState('')

	//Variables newPO
	const [modal, setModal] = useState(false)


	const [chosenEmoji, setChosenEmoji] = React.useState(false);

	const [newComments, setNewComments] = useState({
		ComTypeId: { id: 51 },
		Comment: { value: '' },

	})
	const [columnsAlertsNA, setColumnsAlertsNA] = useState([
		{ key: 'text', align: 'left', label: '', class: 'headerTextShipList', custom: (value) => <Col><span className='tableId'>{value.text}</span> </Col> },
		{ key: 'Impact', label: 'Impact', class: 'headerTextShipList', custom: (value) => <Col > <span style={{ color: Color[value.OrdenColor] }} className='columTableStyle'>{value.Impact} </span></Col> },
		{ key: 'Total', label: 'Total', class: 'headerTextShipList', custom: (value) => <Col ><span className='columTableStyle'>{value.Total}</span></Col> },
		{ key: 'Delete', label: '', class: 'headerTextShipList' }
	])

	const [columnsAlertsA, setColumnsAlertsA] = useState([
		{ key: 'text', align: 'left', label: '', class: 'headerTextShipList', custom: (value) => <Col style={{ cursor: 'pointer' }} onClick={() => { filterShipAlert(value) }}><span className='tableId'>{value.text}</span> </Col> },
		{ key: 'Impact', label: 'Impact', class: 'headerTextShipList', custom: (value) => <Col style={{ cursor: 'pointer' }} onClick={() => { filterShipAlert(value) }}> <span style={{ color: Color[value.OrdenColor] }} className='columTableStyle'>{value.Impact} </span></Col> },
		{ key: 'Total', label: 'Total', class: 'headerTextShipList', custom: (value) => <Col style={{ cursor: 'pointer' }} onClick={() => { filterShipAlert(value) }}><span className='columTableStyle'>{value.Total}</span></Col> },
		{ key: 'Delete', label: '', class: 'headerTextShipList', custom: (value) => <Col><Popup inverted content='Clean filter' basic trigger={<i className="fas fa-eraser fa-lg" style={{ color: '#3695ec', cursor: 'pointer' }} hidden={value.Selected === 1 ? false : true} onClick={() => { filterShipAlert(null) }} />} /></Col> },
	])
	const [dataAlerts, setDataAlerts] = useState([
		{ text: 'Cargo ready out of time', key: 'CargoReadyAlert', idFilter: 'Out of Time', colorFilter: '#FF0000', name: 'CargoReadyOutOfTime', Impact: 'Medium', Total: 0, OrdenColor: 0 },
		{ text: 'Delivery out of time', key: 'DeliveryAlert', name: 'DeliveryOutOfTime', idFilter: 'Out of Time', colorFilter: '#FF0000', Impact: 'High', Total: 0, OrdenColor: 1 },
		{ text: 'Out of project cut-off', key: 'DeliveryAlert', name: 'OutOfProjectCutOff', idFilter: 'Out of Project Cut-off', colorFilter: '#000000', Impact: 'High', Total: 0, OrdenColor: 2 },
	])
	const [columnShip, setColumnShip] = useState([
		{
			key: '', label: '', maxWidth: '4em', minWidth: '6em', class: 'headerTextShipList', custom: (value) => <>
				<div
				// className="d-flex align-items-center"
				>
					<Popup
						inverted
						content='Show messages'
						trigger={
							<div style={{ cursor: 'pointer' }}>
								<i onClick={() => { getComments(value.ShipmentNewBuildId), setPoLineIdSelected(value.PONo), setShipLineIdSelected(value.ShipmentNewBuildId) }} className='mr-1 ml-1'>
									<IconMessages />
								</i>
							</div>
						} />

					{/* <Popup
						inverted
						content={Message.CargoReadyAlert[value.CargoReadyAlert]}
						trigger={
							<i className="fas fa-circle fa-lg mr-1 ml-1"
								// style={{ color: Color[Math.round(Math.random() * 1)] }}
								style={{ color: value.CargoReadyAlert }}
							></i>
						} />

					<Popup
						inverted
						content={Message.DeliveryAlert[value.DeliveryAlert]}
						trigger={
							<i className="fas fa-circle fa-lg mr-1 ml-1"
								//  style={{ color: Color[Math.round(Math.random() * 1)] }}
								style={{ color: value.DeliveryAlert }}
							></i>
						} /> */}

					{/* <Popup
						inverted
						content='Transport mode'
						trigger={
							<div >
								{value.TransportModeId !== 6
									?
									<i className={TransportMode[value.TransportModeId]} style={{ color: '#7E7E7E' }} />
									:
									TransportMode[value.TransportModeId]
								}
							</div>
							// <i className={TransportMode[value.TransportModeId]} style={{ color: '#7E7E7E' }} />
						} /> */}
				</div>
			</>
		},
		{ key: 'PONo', maxWidth: '10em', minWidth: '10em', label: 'Po No.', class: 'headerTextShipList', custom: (value) => <Label className={'linkStyle'} onClick={() => { getPONewBuildId(value.PONewBuildId, value.ShipmentNewBuildId) }}>{value.PONo} </Label> },
		// { key: 'PONo', maxWidth: '10em', minWidth: '10em', label: 'Po No.', class: 'headerTextShipList', custom: (value) => <Link to={`/visibility-detail-po/${value.PONewBuildId}/${value.ShipmentNewBuildId}`}>{value.PONo} </Link> },
		{ key: 'CustomerReference1Flat', maxWidth: '13em', minWidth: '13em', label: 'Logistic PO (FCA)', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.CustomerReference1 ? value.CustomerReference1 : '---'}</span> },
		{ key: 'CustomerReference2Flat', maxWidth: '10em', minWidth: '13em', label: 'Logistic PO (FOB)', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.CustomerReference2 ? value.CustomerReference2 : '---'}</span> },
		{ key: 'NoLine', maxWidth: '8em', minWidth: '7em', label: 'Line', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{parseInt(value.NoLine)}</span> },
		// { key: 'Status', label: 'Status', class: 'headerTextShipList', custom: (value) => <Badge style={{ background: '#5D9CEC', padding: '0.4em' }} pill>&nbsp;&nbsp;&nbsp;&nbsp;{value.Status}&nbsp;&nbsp;&nbsp;&nbsp;</Badge> },
		// { key: 'Status', label: 'Status', class: 'headerTextShipList', custom: (value) => <Button className={'btn-oval'} color={'primary'} >{value.Status}</Button> },
		{ key: 'Status', maxWidth: '12em', minWidth: '12em', label: 'Status', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle' style={{ color: '#09b1e5', fontWeight: 'bold' }}>{value.Status} </span> },
		{ key: 'NewBuild', maxWidth: '8em', minWidth: '10em', label: 'Project', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.NewBuild}</span> },
		{ key: 'DepartmentFlat', maxWidth: '9em', minWidth: '10em', label: 'Department', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.Department ? value.Department : '---'}</span> },
		{ key: 'Supplier', maxWidth: '13em', minWidth: '13em', label: 'Supplier', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.Supplier}</span> },
		{ key: 'POLFlat', maxWidth: '11em', minWidth: '11em', label: 'POL', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.POL ? value.POL : '---'}</span> },
		{ key: 'TypeDelivery', maxWidth: '13em', minWidth: '13em', label: 'Type of delivery', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.TypeDelivery}</span> },
		{ key: 'PromiseCargoReadyFlat', maxWidth: '13em', minWidth: '13em', label: 'Ready Date (MSC)', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.PromiseCargoReady ? value.PromiseCargoReady : '---'}</span> },
		{ key: 'ConfirmedCargoReadyFlat', maxWidth: '13em', minWidth: '13em', label: 'Ready Date (IGQ)', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.ConfirmedCargoReady ? value.ConfirmedCargoReady : '---'}</span> },


		{ key: 'CargoReadyAlert', label: 'Cargo readiness', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle' style={{ color: Color[value.CargoReadyAlert], fontWeight: 'bold' }}>{value.CargoReadyAlert}</span> },
		{ key: 'ETDFlat', label: 'ETD', maxWidth: '13em', minWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.ETD ? value.ETD : '---'}</span> },
		{ key: 'ETAFlat', label: 'ETA', maxWidth: '13em', minWidth: '10em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.ETA ? value.ETA : '---'}</span> },
		{ key: 'DateDeliveryFlat', label: 'Delivery Date (MSC)', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.DateDelivery ? value.DateDelivery : '---'}</span> },
		{ key: 'ActualDeliveryDateFlat', label: 'Delivery Date (IGQ)', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.ActualDeliveryDate ? value.ActualDeliveryDate : '---'}</span> },

		// { key: 'DateDelivery', label: 'Date of delivery', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.DateDelivery}</span> },
		{ key: 'NewPlannedDeliveryDateFlat', label: 'New planed delivery', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.NewPlannedDeliveryDate ? value.NewPlannedDeliveryDate : '---'}</span> },
		{ key: 'DeliveryAlert', label: 'On time delivery', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle' style={{ color: Color[value.DeliveryAlert], fontWeight: 'bold' }}>{value.DeliveryAlert}</span> },


		// { key: 'ContainerNo', maxWidth: '11em', minWidth: '11em', label: 'Container/ Plate', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.ContainerNo ? value.ContainerNo : value.BL_AWB}</span> },
		// { key: 'POD', maxWidth: '11em', minWidth: '10em', label: 'POD', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.POD}</span> },
		// { key: 'PromiseCargoReady', maxWidth: '13em', minWidth: '13em', label: 'Promise cargo ready', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.PromiseCargoReady ? moment(new Date(value.PromiseCargoReady)).format('YYYY/MM/DD') : '-'}</span> },
		// { key: 'PromiseCargoReady', maxWidth: '13em', minWidth: '13em', label: 'Promise cargo ready', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.PromiseCargoReady}</span> },
		// { key: 'ActualCargoReadyDate', maxWidth: '13em', minWidth: '13em', label: 'Actual cargo ready', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.ActualCargoReadyDate}</span> },
		// {key: 'ReadyDateIGQ', label: 'Ready Date (IGQ)', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.ReadyDateIGQ}</span> },
		// {key: 'ConfirmedCargoReady', label: 'Confirmed cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ConfirmedCargoReady}</span> },
		// { key: 'ConfirmedCargoReady', label: 'Confirmed cargo ready', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.ConfirmedCargoReady ? moment(new Date(value.ConfirmedCargoReady)).format('L') : '-'}</span> },
		// { key: 'ActualDeliveryDate', label: 'Confirmed delivery', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle'>{value.ActualDeliveryDate ? moment(new Date(value.ActualDeliveryDate)).format('YYYY/MM/DD') : '-'}</span> },
		// { key: 'DeliveryAlert', label: 'On time delivery', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', sort: true, custom: (value) => <span className='columTableStyle' style={{ color: Color[value.DeliveryAlert], fontWeight: 'bold' }}>{value.DeliveryAlert}</span> },
		// { key: 'PONo', maxWidth: '10em', minWidth: '10em', label: 'Po No.', class: 'headerTextShipList', custom: (value) => <Link onClick={() => { getPONewBuildId(value.PONewBuildId, value.ShipmentNewBuildId) }}>{value.PONo} </Link> },

		{
			key: 'MasterShipmentFlat', maxWidth: '13em', minWidth: '13em', label: 'Master Shipment', class: 'headerTextShipList', custom: (value) => <Label className={'linkStyle'} onClick={() => { getMSID(value.MasterShipment, value.PONewBuildId, value.ShipmentNewBuildId) }} >{value.MasterShipment ? value.MasterShipment : '---'}</Label>
		},
		{ key: 'OperatorFlat', maxWidth: '13em', minWidth: '13em', label: 'Operator', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.OperatorNB ? value.OperatorNB : '---'}</span> },
		// {
		// 	key: 'MasterShipment', maxWidth: '11em', minWidth: '11em', label: 'Master Shipment', class: 'headerTextShipList', custom: (value) => <Link to={`/visibility-detail-ms/${value.MasterShipment}/${value.PONewBuildId}/${value.ShipmentNewBuildId}`}>
		// 		{value.MasterShipment}
		// 	</Link>
		// },
		{ key: 'IntergloboFileFlat', maxWidth: '13em', minWidth: '13em', label: 'Interglobo File', class: 'headerTextShipList', custom: (value) => <span className='columTableStyle'>{value.IntergloboFile ? value.IntergloboFile : '---'}</span> },
		{
			key: 'CombinedLabel', label: 'Combined', maxWidth: '13em', minWidth: '13em', class: 'headerTextShipList', custom: (value) => <>
				{/* <div style={{ cursor: 'pointer' }}> */}
				{value.Combined !== '0' ?
					<i className="fas fa-check" style={{ color: '#13B0F0' }}></i>
					: ''
				}
				{/* </div> */}
			</>
		},

	])
	const [visible, setVisible] = React.useState(false)
	const [updateDataShip, setUpdateShip] = React.useState({})

	// const getPONewBuilds = useCallback(async (customer_ID) => {
	// 	let _CustomSelected = customer_ID ? customer_ID : customerSelect
	// 	try {
	// 		let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/poNewBuilds/?customer=${_CustomSelected}`;
	// 		let data = await Axios.get(url)
	// 		data.data.data.Shipments.map(e => {
	// 			// if (e.CustomerReference1 === '22SC-00003' || e.CustomerReference1 === '22EU-00363') {
	// 			// 	console.log('e--->', e);
	// 			// }
	// 			// if (e.CustomerReference1 === '22SC-00003' || e.CustomerReference1 === '22EU-00363') {
	// 			// 	console.log('e--->', e.CustomerReference2, Boolean(e.CustomerReference2), e.CustomerReference2);
	// 			// }
	// 			e.NoLine = e.NoLine ? parseInt(e.NoLine) : 0
	// 			e.CombinedLabel = (e.Combined !== '0' ? 'Combined' : 'Not combined')
	// 			// e.CustomerReference1 = (e.CustomerReference1 ? e.CustomerReference1 : '---')
	// 			e.CustomerReference1Flat = (e.CustomerReference1 ? e.CustomerReference1 : '(No data)')
	// 			e.CustomerReference2Flat = (e.CustomerReference2 ? e.CustomerReference2 : '(No data)')
	// 			e.DepartmentFlat = (e.Department ? e.Department : '(No data)')
	// 			e.POLFlat = (e.POL ? e.POL : '(No data)')
	// 			e.PromiseCargoReadyFlat = (e.PromiseCargoReady ? e.PromiseCargoReady : '(No data)')
	// 			e.ConfirmedCargoReadyFlat = (e.ConfirmedCargoReady ? e.ConfirmedCargoReady : '(No data)')
	// 			e.ETDFlat = (e.ETD ? e.ETD : '(No data)')
	// 			e.ETAFlat = (e.ETA ? e.ETA : '(No data)')
	// 			e.DateDeliveryFlat = (e.DateDelivery ? e.DateDelivery : '(No data)')
	// 			e.ActualDeliveryDateFlat = (e.ActualDeliveryDate ? e.ActualDeliveryDate : '(No data)')
	// 			e.NewPlannedDeliveryDateFlat = (e.NewPlannedDeliveryDate ? e.NewPlannedDeliveryDate : '(No data)')
	// 			e.MasterShipmentFlat = (e.MasterShipment ? e.MasterShipment : '(No data)')
	// 			e.OperatorFlat = (e.OperatorNB ? e.OperatorNB : '(No data)')
	// 			e.IntergloboFileFlat = (e.IntergloboFile ? e.IntergloboFile : '(No data)')



	// 			return e
	// 		})

	// 		setDataPO(data.data.data.PO)
	// 		setDataShipments(data.data.data.Shipments)
	// 		setDataShipments2(data.data.data.Shipments)

	// 		initPages(data.data.data.PO, activePage)
	// 		resultsByPage(data.data.data.Shipments, activePageShip)
	// 		calculatePagesShip(data.data.data.Shipments.length, itemsPerPage)

	// 		setFlat(true)
	// 		setTimeout(() => {
	// 			setFullLoader(true)
	// 		}, 2000);

	// 	} catch (error) {
	// 		console.log('error get PO-->', error.message);
	// 	}
	// }, [customerSelect, activePageShip, itemsPerPage])

	const getPONewBuilds = useCallback(async (customer_ID) => {
		let _CustomSelected = customer_ID ? customer_ID : customerSelect

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/po-and-shipments/?customer=${_CustomSelected}`;
			let response = await Axios.get(url)

			let data_purcharse_orders = response.data.data.Purcharse_Orders
			let data_shipments = response.data.data.Shipments

			data_shipments.data.map(e => {
				e.NoLine = e.NoLine ? parseInt(e.NoLine) : 0
				e.CombinedLabel = (e.Combined !== '0' ? 'Combined' : 'Not combined')
				// e.CustomerReference1 = (e.CustomerReference1 ? e.CustomerReference1 : '---')
				e.CustomerReference1Flat = (e.CustomerReference1 ? e.CustomerReference1 : '(No data)')
				e.CustomerReference2Flat = (e.CustomerReference2 ? e.CustomerReference2 : '(No data)')
				e.DepartmentFlat = (e.Department ? e.Department : '(No data)')
				e.POLFlat = (e.POL ? e.POL : '(No data)')
				e.PromiseCargoReadyFlat = (e.PromiseCargoReady ? e.PromiseCargoReady : '(No data)')
				e.ConfirmedCargoReadyFlat = (e.ConfirmedCargoReady ? e.ConfirmedCargoReady : '(No data)')
				e.ETDFlat = (e.ETD ? e.ETD : '(No data)')
				e.ETAFlat = (e.ETA ? e.ETA : '(No data)')
				e.DateDeliveryFlat = (e.DateDelivery ? e.DateDelivery : '(No data)')
				e.ActualDeliveryDateFlat = (e.ActualDeliveryDate ? e.ActualDeliveryDate : '(No data)')
				e.NewPlannedDeliveryDateFlat = (e.NewPlannedDeliveryDate ? e.NewPlannedDeliveryDate : '(No data)')
				e.MasterShipmentFlat = (e.MasterShipment ? e.MasterShipment : '(No data)')
				e.OperatorFlat = (e.OperatorNB ? e.OperatorNB : '(No data)')
				e.IntergloboFileFlat = (e.IntergloboFile ? e.IntergloboFile : '(No data)')

				return e
			})

			setDataCard(data_purcharse_orders.data)


			setDataShipments(data_shipments.data)
			setDataShipments2(data_shipments.data)

			// initPages(data.data.data.PO, activePage)
			resultsByPage(data_shipments.data, activePageShip)
			calculatePagesShip(data_shipments.data.length, itemsPerPage)
			setTotalPagesPO(data_purcharse_orders.totalPage)


			setFlat(true)
			setTimeout(() => {
				setFullLoader(true)
			}, 2000);

		} catch (error) {
			console.log('error get PO-->', error.message);
		}
	}, [customerSelect, activePageShip, itemsPerPage])

	const getGraphic = useCallback(async (customer_ID) => {
		let _CustomSelected = customer_ID ? customer_ID : customerSelect
		dataCharts.shipStatus.data = []
		dataCharts.shipStatus.labels = []

		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/graphics/?customer=${_CustomSelected}`;
			let dataGraphic = await Axios.get(url)

			let totalAlerts = dataGraphic.data.data.shipAlerts[0]
			dataAlerts.map(e => {
				e.Total = totalAlerts[e.name]
				return e
			})

			let data = dataGraphic.data.data.shipByStatus
			data.map(e => {
				dataCharts.shipStatus.data.push(e.Total)
				dataCharts.shipStatus.labels.push(e.Status)
				return e
			})

			setShipmentsChart(
				{
					labels: dataCharts.shipStatus.labels,
					datasets: [
						{
							label: 'Shipments',
							data: dataCharts.shipStatus.data,
							backgroundColor: ['rgba(255, 242, 89, 0.7)', 'rgba(3, 150, 255, 0.7)', 'rgba(35, 163, 255, 0.7)', 'rgba(66, 176, 255, 0.7)', 'rgba(98, 189, 255, 0.7)', 'rgba(129, 203, 255, 0.7)'],
						},
					],
				}
			)

		} catch (error) {
			console.log('error get getGraphic-->', error.message);
		}
	}, [customerSelect])

	const filterShipAlert = useCallback(async (item) => {
		let CustomerId = props._customerSelect
		setFullLoader(false)
		let FiltersQuery = `SELECT * FROM public."vwShipmentsNewBuilds"`
		if (item) {
			FiltersQuery += ' WHERE ' + '"' + item.key + '"' + ' = ' + `'${item.idFilter}' AND "CustomerId" =${CustomerId}`
		} else {
			FiltersQuery += ` WHERE "CustomerId" =${CustomerId}`
		}

		const encoded = Buffer.from(FiltersQuery).toString('base64')

		let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/newBuild_Filter?query=${encoded}`;
		let data_filters = await Axios.get(url)
		setDataShipments(data_filters.data.data)
		if (item) {
			dataAlerts.map(e => {
				e.Selected = item.name === e.name ? 1 : 0
			})
		} else {
			dataAlerts.map(e => {
				e.Selected = 0
			})
		}
		setDataAlerts(dataAlerts)

		setTimeout(() => {
			setFullLoader(true)
		}, 1000);
	}, [props])

	// async function filterShipAlert(item) {
	// 	console.log('customerSelect-->', customerSelect);
	// 	setFullLoader(false)
	// 	let FiltersQuery = `SELECT * FROM public."vwShipmentsNewBuilds"`
	// 	if (item) {
	// 		FiltersQuery += ' WHERE ' + '"' + item.key + '"' + ' = ' + `'${item.idFilter}'` + 'AND "CustomerId" =195'
	// 	}
	// 	console.log("🚀 ~ file: index.js ~ line 275 ~ filterShipAlert ~ FiltersQuery", FiltersQuery)

	// 	const encoded = Buffer.from(FiltersQuery).toString('base64')

	// 	let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/newBuild_Filter?query=${encoded}`;
	// 	let data_filters = await Axios.get(url)
	// 	setDataShipments(data_filters.data.data)
	// 	if (item) {
	// 		dataAlerts.map(e => {
	// 			e.Selected = item.name === e.name ? 1 : 0
	// 		})
	// 	} else {
	// 		dataAlerts.map(e => {
	// 			e.Selected = 0
	// 		})
	// 	}
	// 	setDataAlerts(dataAlerts)

	// 	setTimeout(() => {
	// 		setFullLoader(true)
	// 	}, 1000);
	// }


	function handleChange(type, evt) {
		let model = { ...newComments }
		if (type === 'input') {
			model['Comment'].value = evt.target.value
		}
		if (type === 'emoji') {
			model['Comment'].value = model['Comment'].value + evt.emoji

		}
		if (type === 'combo') {
			model['ComTypeId'].id = evt.value
		}
		setNewComments({ ...newComments, ...newComments })
	}

	const addComment = useCallback(async () => {
		let model = { ...newComments }
		let IdShipLine = shipLineIdSelected
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/addCommentShipLine/${IdShipLine}?usr=${userId}`;
			let data = await Axios.post(url, model)
			if (data.status === 200) {
				Swal.fire({
					// position: 'top-end',
					type: 'success',
					title: 'New Comment added',
					showConfirmButton: false,
					timer: 1500
				})
				model['Comment'].value = ''
				model['ComTypeId'].id = 51


				setNewComments({ ...newComments, ...newComments })
				getComments(IdShipLine)
			}

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ addComment ~ error", error)
		}
	}, [shipLineIdSelected, userId])


	const getComments = useCallback(async (idline) => {
		setFlat(false)

		let IdShipLine = idline
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/getComments/${IdShipLine}`;
			let data = await Axios.get(url)
			setComments(data.data.data.dataComments)
			setVisible(true)

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ getComments ~ error", error)
		}
	}, [])

	const getCatalogs = useCallback(async () => {
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/newBuild_Cat`;
			let data = await Axios.get(url)
			let dataCat = data.data.data
			// console.log("🚀 ~ file: index.js ~ getCatalogs ~ dataCat", dataCat)

			Catalogs.StatusId = dataCat.catStatus
			Catalogs.Supplier = dataCat.catSupplier
			Catalogs.ProjectCutOffId = dataCat.catProject
			Catalogs.Buyer = dataCat.catBuyer
			Catalogs.StatusShip = dataCat.Cat_StatusShip
			Catalogs.Department = dataCat.Cat_Department
			Catalogs.ComTypeId = dataCat.Cat_ComType
			Catalogs.CodIncoterm = dataCat.Cat_Incoterms

			// Catalogs.PONo = dataCat.Cat_PoNumber
			// Catalogs.ProductDescription = dataCat.Cat_ProducDes
			Catalogs.Mesures = dataCat.Cat_Mesure
			Catalogs.Weight = dataCat.Cat_Weight
			Catalogs.ChargeableWeight = dataCat.Cat_Chargeable
			Catalogs.From = dataCat.Cat_From
			Catalogs.To = dataCat.Cat_To
			Catalogs.TypeDelivery = dataCat.Cat_TypeDelivery
			Catalogs.Value = dataCat.Cat_ValueEuro
			Catalogs.CustomerReference1 = dataCat.Cat_FCA
			Catalogs.CustomerReference2 = dataCat.Cat_FOB

			setCatalogs({ Catalogs, ...Catalogs })

		} catch (error) {
			console.log('The Error-->', error);
		}
	})

	// async function funtionFilter(evt, data) {
	// 	console.log("🚀 ~ file: index.js:570 ~ funtionFilter ~ data", data)
	// 	setFullLoader(false)
	// 	let NewFilters = {}

	// 	if (data) {
	// 		let key = data.tabIndex

	// 		if (data.value.length !== 0) {
	// 			let idValues = data.value
	// 			filtros[key] = idValues
	// 			NewFilters = filtros
	// 		} else {
	// 			let itemDelete = key
	// 			let FiltersDelete = Object.keys(filtros).reduce((object, key) => {
	// 				if (key !== itemDelete) {
	// 					object[key] = filtros[key]
	// 				}
	// 				return object
	// 			}, {})
	// 			NewFilters = FiltersDelete
	// 		}
	// 	}
	// 	let keys = Object.keys(NewFilters)
	// 	let limit = keys.length - 1
	// 	// let FiltersQuery = `SELECT * FROM public."vwPONewBuilds" ${keys.length !== 0 ? ' WHERE ' : ''}`
	// 	let FiltersQuery = `FROM public."vwPONewBuilds"${keys.length !== 0 ? ' WHERE ' : ''}`
	// 	Object.entries(NewFilters).map(([key, value], index) => {
	// 		let values2 = []
	// 		value.map(e => {
	// 			values2.push(`'${e}'`)
	// 		})
	// 		FiltersQuery += '"' + key + '"' + ' in ' + '(' + values2 + ')' + (index < limit ? ` AND ` : '')
	// 	});

	// 	let queryFinal = `SELECT *, (SELECT COUNT(*) ${FiltersQuery}) as "Total" ${FiltersQuery}`

	// 	const encoded = Buffer.from(queryFinal).toString('base64')
	// 	let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/newBuild_Filter?query=${encoded}`;
	// 	let data_filters = await Axios.get(url)
	// 	setFiltros(NewFilters)
	// 	// setDataPO(data_filters.data.data)
	// 	setDataPO(data_filters.data.data)
	// 	setValueSearchPO('')
	// 	// setActivePage(1)
	// 	// initPages(data_filters.data.data, 1)

	// 	setTimeout(() => {
	// 		setFullLoader(true)
	// 	}, 1000);
	// }

	async function funtionFilterShipments(evt, data, item) {
		setFullLoader(false)
		setFlat(false)

		let NewFilters = {}
		if (item.type === 'multiSelect') {
			if (data) {
				let key = data.tabIndex
				if (data.value.length !== 0) {
					let idValues = data.value
					filtros[key] = idValues
					NewFilters = filtros
				}
				else {
					let itemDelete = key
					let FiltersDelete = Object.keys(filtros).reduce((object, key) => {
						if (key !== itemDelete) {
							object[key] = filtros[key]
						}
						return object
					}, {})
					NewFilters = FiltersDelete
				}
			}
		} else if (item.type === 'date') {
			if (evt !== null) {
				let start = moment(evt[0]).format('YYYY/MM/DD')
				let end = moment(evt[1]).format("YYYY/MM/DD");
				let Dates = { Init: start, End: end }
				filtros[item.id] = Dates
				NewFilters = filtros
			} else {
				let itemDelete = item.id
				let FiltersDelete = Object.keys(filtros).reduce((object, key) => {
					if (key !== itemDelete) {
						object[key] = filtros[key]
					}
					return object
				}, {})
				NewFilters = FiltersDelete
			}
		}

		let keys = Object.keys(NewFilters)
		let limit = keys.length - 1
		let FiltersQuery = `SELECT * FROM public."vwShipmentsNewBuilds" ${keys.length !== 0 ? ' WHERE ' : ''}`
		Object.entries(NewFilters).map(([key, value], index) => {
			let values2 = []
			if (value.Init) {
				FiltersQuery += '"' + key + '"' + ' BETWEEN ' + `'${value.Init}'` + ' AND ' + `'${value.End}'` + (index < limit ? ` AND ` : '')
			} else {
				value.map(e => {
					values2.push(`'${e}'`)
				})
				FiltersQuery += '"' + key + '"' + ' in ' + '(' + values2 + ')' + (index < limit ? ` AND ` : '')
			}

		});
		// console.log("🚀 ~ file: index.js ~ line 449 ~ funtionFilterShipments ~ FiltersQuery", FiltersQuery)

		const encoded = Buffer.from(FiltersQuery).toString('base64')

		let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/newBuild_Filter?query=${encoded}`;
		let data_filters = await Axios.get(url)
		setFiltros(NewFilters)
		setDataShipments(data_filters.data.data)
		setTimeout(() => {
			setFullLoader(true)
		}, 1000);
	}

	// function getPONewBuildId(poId, line) {
	// 	console.log("🚀 ~ file: index.js:540 ~ getPONewBuildId ~ piId", poId, line)
	// 	if (poId) {
	// 		setPoId(poId)
	// 		setNoLine(line)
	// 	}
	// 	setActiveDetail(!activeDetail)
	// }


	const getPONewBuildId = useCallback(async (poId, line) => {
		if (poId) {
			setPoId(poId)
			setNoLine(line)
		}
		setActiveDetailPO(!activeDetailPO)
		// if (activeDetailPO) {
		// 	initPages(newDataFilter.length !== 0 ? newDataFilter : dataPO, activePagePO)
		// }
	}, [activeDetailPO])

	const getMSID = useCallback(async (MSID, poId, line) => {
		// console.log('dataShipments--->', updateDataShip[dataShipments]);

		if (MSID) {
			setPoId(poId)
			setNoLine(line)
			setMS(MSID)
		}
		setActiveDetailMS(!activeDetailMS)

		if (Object.keys(updateDataShip).length !== 0) {
			console.log('entre');
			dataShipments.filter((item) => {
				if (item.MasterShipment === MS) {
					item.MasterShipment = updateDataShip[item.MasterShipment]
				}
			})
			resultsByPage(dataShipments, activePageShip)
		}


	}, [activeDetailMS, dataShipments, updateDataShip, MS, activePageShip])

	// function initPages(data, pagueActive) {
	// 	let pages = parseInt(Math.ceil(data.length / itemsForPage));
	// 	if (pages === 0) {
	// 		pages = 1
	// 	}
	// 	// setActivePage(1)
	// 	setTotalPagesPO(pages)
	// 	buildPageResults(pagueActive, data, pages)
	// }

	// const buildPageResults = useCallback(async (pag, dataCard, total) => {
	// 	let data = setResultsByPage(pag, dataCard, total, itemsForPage);
	// 	if (data) {
	// 		setDataCard(data)
	// 	}
	// }, [itemsForPage])

	// function setResultsByPage(page, allData, pages, itemsByPage) {
	// 	let array = [];
	// 	if (page === 1)
	// 		for (let i = 0; i < (allData.length < itemsByPage ? allData.length : itemsByPage); i++)
	// 			array.push(allData[i]);
	// 	else if (page !== 1 && page !== pages)
	// 		for (let i = (page - 1) * itemsByPage; i < (page) * itemsByPage; i++)
	// 			array.push(allData[i]);
	// 	else
	// 		for (let i = (page - 1) * itemsByPage; i < allData.length; i++)
	// 			array.push(allData[i]);
	// 	return array;
	// }

	const changePage = useCallback(async (page) => {
		dataByPague.PO.activePage = page
		setDataByPague({ dataByPague, ...dataByPague })
		if (valueSearchPO) {
			handleChangeSearch(valueSearchPO, true)
		} else if (Object.keys(filtros).length !== 0) {
			funtionFilter()
		} else {
			get_po_by_page()
		}
	}, [dataPO, totalPagesPO, newDataFilter, dataByPague, valueSearchPO, filtros])

	const get_po_by_page = useCallback(async () => {
		let pageInformation = dataByPague.PO
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/data-by-page`;
			let body = { customerSelect, pageInformation }
			let response = await Axios.post(url, body)
			setDataCard(response.data.data)
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ constget_po_by_page=useCallback ~ error", error.message)
		}
	}, [dataByPague, customerSelect])

	// const handleChangeSearch = useCallback(async (data) => {
	// 	let dataSearch = dataPO
	// 	let results = [];
	// 	setValueSearch(data)
	// 	dataSearch.forEach(e => {
	// 		let PONo = e.PONo ? e.PONo.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Status = e.Status ? e.Status.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Supplier = e.Supplier ? e.Supplier.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let NewBuild = e.NewBuild ? e.NewBuild.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Department = e.Department ? e.Department.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Containers = e.Containers ? e.Containers.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Buyer = e.Buyer ? e.Buyer.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let Value = e.Value ? e.Value.toUpperCase().indexOf(data.toUpperCase()) : -1;
	// 		let CreationDate = e.CreationDate ? e.CreationDate.toUpperCase().indexOf(data.toUpperCase()) : -1;

	// 		if (PONo >= 0 || Status >= 0 || Supplier >= 0 || NewBuild >= 0 || Department >= 0 || Containers >= 0 || Buyer >= 0 || Value >= 0 || CreationDate >= 0) {
	// 			results.push(e);
	// 		}
	// 	})
	// 	setNewDataFilter(Boolean(data) ? results : [])
	// 	setActivePage(1)
	// 	initPages(results, 1)
	// }, [dataPO])

	const handleChangeSearch = useCallback(async (valueSearch, flat) => {
		// setValueSearchPO(valueSearch.trimStart())
		setFullLoader(false)
		dataByPague.PO.activePage = flat ? dataByPague.PO.activePage : 1
		// setDataByPague({ dataByPague, ...dataByPague })
		let pageInformation = dataByPague.PO
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/data-search`;
			let body = { customerSelect, pageInformation, valueSearch }
			let response = await Axios.post(url, body)
			let data_purcharse_orders = response.data.data
			setDataCard(data_purcharse_orders.data)
			setTotalPagesPO(data_purcharse_orders.totalPage)
			setTimeout(() => {
				setFullLoader(true)
			}, 1000);

		} catch (error) {
			console.log("🚀 ~ file: index.js ~ handleChangeSearch ~ error", error.message)
			setFullLoader(true)
		}
	}, [dataByPague, customerSelect])

	const funtionFilter = useCallback(async (evt, data, flat) => {
		if (data !== undefined) {
			dataByPague.PO.activePage = 1
			let pageInformation = dataByPague.PO
			// console.log("🚀 ~ file: index.js:828 ~ funtionFilter ~ pageInformation", pageInformation)

			setFullLoader(false)
			let NewFilters = {}

			if (data) {
				let key = data.tabIndex

				if (data.value.length !== 0) {
					let idValues = data.value
					filtros[key] = idValues
					NewFilters = filtros
				} else {
					let itemDelete = key
					let FiltersDelete = Object.keys(filtros).reduce((object, key) => {
						if (key !== itemDelete) {
							object[key] = filtros[key]
						}
						return object
					}, {})
					NewFilters = FiltersDelete
				}
			}
			let keys = Object.keys(NewFilters)
			let limit = keys.length - 1
			// let FiltersQuery = `SELECT * FROM public."vwPONewBuilds" ${keys.length !== 0 ? ' WHERE ' : ''}`
			// let FiltersQuery = `FROM public."vwPONewBuilds"${keys.length !== 0 ? ' WHERE ' : ''}`
			let FiltersQuery = `FROM public."vwPONewBuilds" WHERE "CustomerId"=${customerSelect}${keys.length !== 0 ? ' AND ' : ''}`
			Object.entries(NewFilters).map(([key, value], index) => {
				let values2 = []
				value.map(e => {
					values2.push(`'${e}'`)
				})
				FiltersQuery += '"' + key + '"' + ' in ' + '(' + values2 + ')' + (index < limit ? ` AND ` : '')
			});

			let queryFinal = `SELECT *, (SELECT COUNT(*) ${FiltersQuery}) as "Total" ${FiltersQuery}`
			const encoded_query = Buffer.from(queryFinal).toString('base64')
			let body = { customerSelect, pageInformation, encoded_query }
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/new-build-filters/`;
			let data_filters = await Axios.post(url, body)
			let data_purcharse_orders = data_filters.data.data

			setQueryFilter(encoded_query)
			setDataCard(data_purcharse_orders.data)
			setTotalPagesPO(data_purcharse_orders.totalPage)
			setFiltros(NewFilters)

		} else {
			// dataByPague.PO.activePage = evt === true ? dataByPague.PO.activePage : 1
			let pageInformation = dataByPague.PO
			let encoded_query = queryFilter

			let body = { customerSelect, pageInformation, encoded_query }
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/new-build-filters/`;
			let data_filters = await Axios.post(url, body)
			let data_purcharse_orders = data_filters.data.data

			setDataCard(data_purcharse_orders.data)
			setTotalPagesPO(data_purcharse_orders.totalPage)
		}
		// setDataPO(data_filters.data.data)
		setValueSearchPO('')

		setTimeout(() => {
			setFullLoader(true)
		}, 1000);
	}, [dataByPague, customerSelect, queryFilter, filtros])


	const generateExcel = useCallback(async () => {
		setLoaderExcel(true)
		let data = { customerSelect }

		try {
			let response = await Axios.post(`${Services.GET_CONSOLPLAN_SERVICE.path}new-build/xls-po/`, data);
			let data_po_xls = response.data.data
			let headers = []
			let keys = []

			config.map(e => {
				if (e.xls) {
					headers.push(e.title)
					keys.push(e.id)
				}
				return e
			})

			let dataxls = { data: data_po_xls, options: { headers: headers, keys: keys, image: { name: 'poseidon' } } }
			const url = `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`;

			const getXlsxFile = await Axios.post(url, dataxls);
			const fileName = `New Build-${moment(new Date()).format('DD-MM-YYYY')}.xlsx`
			const linkSource = "data:application/xlsx;base64," + getXlsxFile.data.base64;
			saveAs(linkSource, fileName);
			setLoaderExcel(false)
		} catch (error) {
			console.log('Error dowload file xls-->', error.message);
			setLoaderExcel(false)
		}
	}, [config, customerSelect])



	//Funciones Tab Shipments
	function calculatePagesShip(total, itemsPerPage) {
		const total_pages = total / itemsPerPage;
		setTotalPageShip(total_pages % 1 === 0 ? total_pages : Math.ceil(total_pages))
	}

	const handleChangePageShip = useCallback(async (evt, { activePage }) => {
		setActivePageShip(activePage)
		resultsByPage(dataShipments, activePage)

	}, [dataShipments])

	const resultsByPage = useCallback(async (columns, activePage) => {

		let active_page2 = activePage - 1
		const ROWS = []
		columns.slice(active_page2 * itemsPerPage, active_page2 * itemsPerPage + itemsPerPage).map((row, indexrow) => {
			ROWS.push(row)
		})

		buildRows(ROWS, columnShip)
		buildColumns(columnShip)
	}, [columnShip])

	function buildRows(items, columns) {
		let ___rows = items.map((e, _index) => {
			let tds = [];
			if (e.hidden) {
				return <></>;
			} else {
				if (e) {
					columns.forEach((column, index_) => {
						if (column.custom) {
							tds.push(
								<td
									key={index_}
									className={
										column.styletd ? column.styletd : "styledTdFilterTable"
									}
									id={
										e[column.key]
											? column.idTd
												? column.idTd
												: e[column.key]
											: e[column.key]
									}
								>
									{column.custom(e)}
								</td >
							);
						} else if (column.index) {
							tds.push(
								<td
									key={index_}
									className={
										this.state.styletd === true ? "table-td2" : "table-td"
									}
									id={
										e[column.key]
											? column.idTd
												? column.idTd
												: e[column.key]
											: e[column.key]
									}
									style={{
										fontSize: "12px",
										textAlign: "center",
										minWidth: "150px",
										maxWidth: "100px",
									}}
								>
									{_index + 1}
								</td>
							);
						} else if (column.shiwchColor) {
							tds.push(
								<td
									key={index_}
									className={
										this.state.styletd === true ? "table-td2" : "table-td"
									}
									id={
										e[column.key]
											? column.idTd
												? column.idTd
												: e[column.key]
											: e[column.key]
									}
									style={{
										fontSize: "12px",
										cursor: "pointer",
										textAlign: "center",
										minWidth: "150px",
										maxWidth: "100px",
										color: e[column.key] === "Paid" ? "#35c677" : "red",
									}}
								>
									{e[column.key] ? e[column.key] : ""}
								</td>
							);
						} else if (column.separator) {
							tds.push(
								<td
									key={index_}
									className={
										this.state.styletd === true ? "table-td2" : "table-td"
									}
									id={
										e[column.key]
											? column.idTd
												? column.idTd
												: e[column.key]
											: e[column.key]
									}
									style={{
										fontSize: "12px",
										cursor: "pointer",
										textAlign: "center",
										minWidth: "150px",
										maxWidth: "100px",
									}}
								>
									{e[column.key]
										? "$" + Number(e[column.key]).toLocaleString("En-us")
										: ""}
								</td>
							);
						}

						else {
							tds.push(
								<td className="cell td_table">
									{e[column.key] ? e[column.key] : ""}
								</td>
							);
						}
					});
				}
			}

			return (
				<>
					<tr className="hoverStyle" key={"row_" + e.key}>
						{tds}
					</tr>
				</>
			);
		});
		// return ___rows;
		setTableRows(___rows)
	}

	function buildColumns(columns) {
		let ___columns = [];

		columns.forEach((e, index) => {
			___columns.push(
				<th
					key={e.key}
					filterkey={e.key}
					className={e.class ? e.class : "cell td_head"}
					style={{
						minWidth: e.minWidth ? e.minWidth : '10eṃ̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣̣',
						maxWidth: e.maxWidth ? e.maxWidth : '12em'
					}}
				// style={{ width: e.width ? e.width : '20px' }}
				// casesensitive={"true"}
				// showsearch={"true"}
				>
					{e.label}
				</th>
			);
		});
		// return ___columns;
		setTableColums(___columns)
		// setKey(Math.random())
	}

	const filterUpdated = useCallback(async (newData, filtros) => {
		// console.log("🚀 ~ file: index.js:908 ~ filterUpdated ~ newData", newData, filtros)
		setDataShipments(newData)
		resultsByPage(newData, 1)
		calculatePagesShip(newData.length, itemsPerPage)
		setActiveFilterTableShip(filtros)
		setActivePageShip(1)
	}, [itemsPerPage])



	//Funciones Creacion de PO

	const toggle_new_po = (flat) => {
		if (!flat) {
			setModal(!modal);
			setModelNewBuild({ ...Model_New_Build() })
		}
	}


	function handleChangeNewPO(val, data, item) {
		let ModelSend = { ...modelNewBuild.New_PO };
		let id = item.id
		let dataLabel = data ? data : '';
		// console.log("🚀 ~ file: index.js:951 ~ handleChangeNewPO ~ dataLabel", dataLabel, id)
		if (item.type === 'text_without_spaces') {
			let value = val;
			if (value.trim() !== "") {
				// let res = ValidatorService(ModelSend[id].type, value);
				// if (res) {
				ModelSend[id].value = value.trim().replace(/\s+/g, '')
				// ModelSend[id].value = value.trim()
				// }
			} else {
				ModelSend[id].value = undefined
			}
			ValidatorSchema2(ModelSend, [id])
		}
		if (item.type === 'text') {
			let value = val;
			let res = ValidatorService(ModelSend[id].type, value);
			if (res) {
				ModelSend[id].value = value
			}

			ValidatorSchema2(ModelSend, [id])
		}
		if (item.type === 'date') {
			let value = dataLabel;
			ModelSend[id].value = value
			ValidatorSchema2(ModelSend, [id])
		}
		if (item.type === 'check') {
			let value = data.checked;
			ModelSend[id].check = value
		}
		if (item.type === 'combo') {
			let valueSelected = data.value;
			const resulValue = data.options.find(({ value }) => value === valueSelected)
			// console.log("🚀 ~ file: index.js:973 ~ handleChangeNewPO ~ data", data.options.find(({ value }) => value === valueSelected))
			// let res = ValidatorService(ModelSend[id].type, value);

			// if (!res) {
			// 	ModelSend[id].valid = res
			// } else {
			// 	ModelSend[id].valid = res
			// }
			// let flag = ValidatorSchema(ModelSend, ['ProjectCutOffId'])
			ModelSend[id].id = valueSelected
			ModelSend[id].value = resulValue ? resulValue.text : undefined
			ValidatorSchema2(ModelSend, [id])
		}
		if (item.type === 'autocomplete') {
			let value = val;
			ModelSend[id].value = value
			ValidatorSchema2(ModelSend, [id])
		}
		if (item.type === 'text-area') {
			let value = data.value;
			// let res = ValidatorService(ModelSend[id].type, value);
			// if (res) {
			ModelSend[id].value = value
			// }
		}
		setModelNewBuild({ modelNewBuild, ...modelNewBuild })
	}

	const newPO = useCallback(async () => {
		let ModelSend = { ...modelNewBuild.New_PO };

		ModelSend.PONo.value ? (ModelSend.PONo.value = ModelSend.PONo.value.trim()) : undefined
		// ModelSend.PONo ? (ModelSend.PONo.value = ModelSend.PONo.value.trim()) : undefined
		try {
			let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/new-po`;
			let data = await Axios.post(url, ModelSend)

			let newPoId = data.data.data ? data.data.data[0].PONewBuildId : undefined
			let newPoNumber = data.data.data ? data.data.data[0].PONo : undefined
			if (data.data.status !== 100) {
				getPONewBuilds()
				setModal(false)
				Swal.fire({
					title: `The PO #${newPoNumber} has been created succesfully`,
					type: 'success',
					showConfirmButton: false,
					timer: 3000
				})
				setTimeout(() => {
					getPONewBuildId(newPoId, '0')
				}, 3000);
			} else {
				// console.log(data.data.message);
				Swal.fire({
					title: 'Oops...',
					text: data.data.message,
					type: 'info',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ok',
					// preConfirm: (result) => {
					// 	if (result) {
					// 		deleteShipLine(params)
					// 	}
					// }
				})
			}

		} catch (error) {
			console.log('error newPO-->', error.message);
			let requeridos = ['PONo', 'Supplier', 'Department', 'ProjectCutOffId', 'TypeDelivery', 'DateReadiness', 'DeliveryDate', 'CodIncoterm']
			let flag = ValidatorSchema(ModelSend, requeridos)
			if (!flag) {
				ValidatorSchema2(ModelSend, requeridos)
				setModelNewBuild({ modelNewBuild, ...modelNewBuild })
			}
		}
	}, [modelNewBuild])


	// function toggle_new_po() {
	// 	//setea el model a valores inciales
	// 	setModelNewBuild({ ...Model_New_Build() })
	// }



	useEffect(() => {
		setCustomerSelect(props._customerSelect)
		getCatalogs()
		getPONewBuilds(props._customerSelect)
		getGraphic(props._customerSelect)
		setUserId(props._user)
	}, [])

	return (
		<>
			{/* {console.log('modelNewBuild--->', modelNewBuild.New_PO)} */}
			<Loader hidden={fullLoader} />
			{
				(!activeDetailPO && !activeDetailMS) ?
					<ContentWrapper>
						<div className="content-heading">
							<i className='fas fa-map-marker-altfas fa-ship fa-1x mr-2'></i>
							Visibility
						</div>
						<Container fluid>

							<Row>
								{/* <Col xs={12} md={6} className="ml-auto mr-auto">
							<div className=" equalsWidth2">
								<CardBody style={{ borderRadius: '22px' }} className="graphcardbody">
									<CardTitle>Shipments by Status</CardTitle>
									<HorizontalBar
										data={shipmentsChart}
										width={100}
										height={28}
										options={{
											legend: false,
											scales: {
												xAxes: [
													{
														ticks: { precision: 0 },
														stacked: true
													}
												],
												yAxes: [{
													stacked: true
												}]
											},

											indexAxis: 'y',
											responsive: true
											// scales: {
											// 	xAxes: {
											// 		precision: 0

											// 	}
											// }
										}}
									/>
								</CardBody>
							</div>
						</Col>
						<Col xs={12} md={6} className="ml-auto mr-auto">
							<div className=" equalsWidth2">
								<CardBody style={{ borderRadius: '22px' }} className="graphcardbody">
									<CardTitle>Shipments by Status</CardTitle>
									<Table
										columns={activeTab === 1 ? columnsAlertsA : columnsAlertsNA}
										data={dataAlerts}
										options={
											{
												// borderless: false,
												hover: true,
												pagination: false,
												card: false,
												search: false,
												placeholder: 'Search ...',
												selectItems: false,
												xlsx: false,
												Accordion: { Active: false, Title: '' },
												// Filter: { filters: params.Filters, form: params.form }
											}
										}
									>
									</Table>
								</CardBody>
							</div>
						</Col> */}
								<Col xs={12} md={6} className="ml-auto mr-auto">
									<Card className="myCard card-chart">
										<CardHeader>
											<Row>
												<Col xs={12} className=' mr-auto d-flex align-items-center'>
													<CardTitle>Shipments by Status</CardTitle>
												</Col>
											</Row>
										</CardHeader>
										<CardBody >
											<div style={{ height: '11em' }}>
												<HorizontalBar
													data={shipmentsChart}
													// width={100}
													// height={28}
													options={{
														// animation:{
														// 	easing:'linear'
														// },
														plugins: {
															datalabels: {
																display: true,
																color: "black",
																// align: "center",
																align: "end",
																// anchor: "end",
																anchor: "center",
																// font: { size: "14" }
															}
														},
														tooltips: {
															enabled: false
														},
														responsive: true,
														maintainAspectRatio: false,
														legend: false,
														scales: {
															xAxes: [
																{
																	ticks: { precision: 0 },
																	stacked: true
																}
															],
															yAxes: [{
																stacked: true
															}]
														},

														indexAxis: 'y',
														responsive: true
														// scales: {
														// 	xAxes: {
														// 		precision: 0

														// 	}
														// }
													}}

												/>
											</div>
										</CardBody>
									</Card>
								</Col>
								<Col xs={12} md={6} className="ml-auto mr-auto">
									<Card className="myCard card-chart">
										<CardHeader>
											<Row>
												<Col xs={12} className=' mr-auto d-flex align-items-center'>
													<CardTitle className={'mr-2'}>Shipments Alerts </CardTitle> <Warning />
												</Col>
											</Row>
										</CardHeader>
										<div style={{ height: '13em' }}>
											<Table
												columns={activeTab === 1 ? columnsAlertsA : columnsAlertsNA}
												data={dataAlerts}
												options={
													{
														// borderless: false,
														hover: true,
														pagination: false,
														card: false,
														search: false,
														placeholder: 'Search ...',
														selectItems: false,
														xlsx: false,
														Accordion: { Active: false, Title: '' },
														// Filter: { filters: params.Filters, form: params.form }
													}
												}
											>
											</Table>
										</div>
									</Card>
								</Col>
								<Col xs={12}>
									<Tab
										activeIndex={activeTab}
										// defaultActiveIndex={0}
										menu={{ secondary: true }}
										onTabChange={(evt, data) => {
											setActiveTab(data.activeIndex)
										}}
										panes={[
											{
												menuItem: 'Purchase Orders',
												render: () => <Suspense fallback={<div>Loading...</div>}>
													{/* <PurchaseOrders dataPurchase={dataPO} configPurchase={config} Filters={Filters} funtionFilter={funtionFilter} form={FormComponent.FiltersPurchase} Catalogs={Catalogs} getPONewBuildId={getPONewBuildId} setActivePage={setActivePage} activePage={activePage} setValueSearch={setValueSearch} valueSearch={valueSearch} /> */}
													<>
														<div className="content">

															<Segment basic size="mini">
																<AddNewPO formcreator={FormCreator} form={FormComponent.form_new_po} model={modelNewBuild.New_PO} handle_change_new_po={handleChangeNewPO} catalogs={Catalogs} function_new_po={newPO} toggle={toggle_new_po} modal_new_po={modal} />
																<Cards
																	Catalogs={Catalogs}
																	funtionFilter={funtionFilter}
																	Filters={Filters}
																	form={FormComponent.FiltersPurchase}
																	config={config}
																	data={dataCard}
																	getPONewBuildId={getPONewBuildId}
																	poId={poId}
																	noLine={noLine}
																	// setActivePage={setActivePagePO}
																	activePage={dataByPague.PO.activePage}
																	// setValueSearch={setValueSearch}
																	valueSearch={valueSearchPO}
																	totalPages={totalPagesPO}
																	changePage={changePage}
																	handleChangeSearch={handleChangeSearch}
																	setValueSearchPO={setValueSearchPO}
																	generateExcel={generateExcel}
																	loaderExcel={loaderExcel}
																/>
															</Segment>
														</div>
													</>
												</Suspense>
											},
											{
												menuItem: 'Shipments',
												render: () => <Suspense fallback={<div>Loading...</div>}>
													{/* <Shipments columnShip={columnShip} dataShipments={dataShipments} Filters={Filters} funtionFilter={funtionFilterShipments} form={FormComponent.FiltersShipments} Catalogs={Catalogs} visibleSide={visible} setVisible={setVisible} comments={comments} poLineIdSelected={poLineIdSelected} handleChange={handleChange} newComments={newComments} addComment={addComment} getPONewBuilds={getPONewBuilds} customerSelect={customerSelect} flat={flat} userId={userId} poId={poId} noLine={noLine} /> */}
													<div className="content">
														<Col md={12} xl={12}>
															<AddMasterShip getPONewBuilds={getPONewBuilds} customerSelect={customerSelect} userId={userId} />
															<Card className={"myCard"}>
																<Sidebar.Pushable as={Segment}>
																	<Sidebar
																		direction="right"
																		as={Menu}
																		animation='overlay'
																		icon='labeled'
																		// inverted
																		// onHide={() => params.setVisible(false)}
																		// vertical
																		visible={visible}
																		width='very wide'
																		id={'sidebarComentsHeight'}
																	>
																		<Col xs={12} md={12} lg={12} className='d-flex row'>
																			<Col xs={12} md={12} lg={12} className='pr-0 mt-1'>
																				<div className='d-flex justify-content-between'>
																					<div className="mt-2" >PO No.# {poLineIdSelected}</div>
																					<div className="mt-1" onClick={() => setVisible(false)} style={{ cursor: 'pointer' }}>
																						<CloseIcon />
																					</div>
																				</div>
																			</Col>

																			<Col md={12} xl={12}>
																				<Timeline lineColor={'#fff'}>
																					{
																						comments.map((e) => {
																							return (
																								<TimelineItem
																									key={e.CommentId}
																									dateText={`${e.Date}`}
																									dateInnerStyle={{ background: `${e.Color}`, fontWeight: 'bold', fontSize: '11px' }}
																								>
																									<Card className={'myCard'}>
																										<Col xs={12} md={12} lg={12}>
																											<Row>
																												<Col xs={12} md={12} lg={12}>
																													<span className='comment_type'>{e.ComType}</span>
																													{
																														e.NewComment ?
																															<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" className={'svg-inline--fa fa-circle fa-w-16 text-success new-comment'} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
																																<path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
																															</svg> : ''
																													}
																												</Col>
																												<Col xs={12} md={12} lg={12}>
																													<span className='comment_content'>{e.Comment}</span>
																												</Col>
																												<Col xs={12} md={12} lg={12} >
																													<div className="user_footer footer_comments">
																														{e.User ? e.User : ''}
																													</div>
																												</Col>
																											</Row>
																										</Col>
																									</Card>

																								</TimelineItem>
																							);
																						})
																					}
																				</Timeline>
																			</Col>
																			<Col xs={12} md={12} lg={12} className='mt-auto'>
																				<div style={{ textAlign: 'left' }} className="mb-2">
																					<Dropdown
																						// text='Comment type'
																						options={Catalogs.ComTypeId}
																						onChange={(evt, dta) => {
																							handleChange('combo', dta)
																						}}
																						// defaultValue={51}
																						value={newComments.ComTypeId.id ? newComments.ComTypeId.id : 51}
																					/>
																				</div>
																				<Row>
																					<Col xs={12} md={12} lg={12} className='mb-3'>
																						<div id='fakeboxComments'>
																							<input id='fakebox-comments' onChange={(evt) => (handleChange('input', evt))} value={newComments.Comment.value}
																								onKeyPress={event => {
																									if (event.key === 'Enter') {
																										addComment()
																									}
																								}}
																							/>
																							<div onClick={() => { setChosenEmoji(!chosenEmoji) }}>
																								<Emoji />
																							</div>
																							<div>
																								{chosenEmoji ?
																									<Picker
																										pickerStyle={{ width: '100%' }}
																										onEmojiClick={(evt, emoji) => (handleChange('emoji', emoji))}
																									/>
																									: ''
																								}
																							</div>
																						</div>
																					</Col>
																				</Row>
																			</Col>
																		</Col>
																	</Sidebar>
																	<Sidebar.Pusher>
																		<Segment basic size="mini" >
																			<CardBody>
																				{/* <Col xs={12}> */}
																				<TableFilter
																					data={dataShipments2}
																					dataXls={dataShipments}
																					// columns={columnShip}

																					activePage={activePageShip}
																					handleChangePage={handleChangePageShip}
																					totalPages={totalPageShip}
																					calculatePages={calculatePagesShip}
																					tableRows={tableRows}
																					tableColums={tableColums}
																					filterUpdated={filterUpdated}
																					activeFilterTableShip={activeFilterTableShip}

																					options={
																						{
																							xlsx: {
																								show: true,
																								headers: headers ? headers : [],
																								keys: keys ? keys : [],
																								filName: `New Build Shipments-${moment(new Date()).format('DD-MM-YYYY')}`,
																								projectName: 'poseidon',
																								sheetName: "Shipment list",
																								URL: `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`
																							},


																							// addLine: { visible: true, addLineFunction: addShipLine },
																						}
																					}
																				// key={key}
																				// options={
																				// 	{
																				// 		pagination: true,
																				// 		itemsPerPage: 10,
																				// 		xlsx: {
																				// 			show: true,
																				// 			headers: headers ? headers : [],
																				// 			keys: keys ? keys : [],
																				// 			filName: `New Build Shipments-${moment(new Date()).format('DD-MM-YYYY')}`,
																				// 			projectName: 'poseidon',
																				// 			sheetName: "Shipment list",
																				// 			URL: `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`
																				// 		},
																				// 		Filter: { filters: Filters, form: FormComponent.FiltersShipments, funtionFilter: funtionFilterShipments, Catalogs: Catalogs }
																				// 		// addLine: { visible: true, addLineFunction: addShipLine },
																				// 	}
																				// }
																				/>
																				{/* <Table
																					columns={columnShip}
																					data={dataShipments}
																					headers={headers}
																					keys={keys}
																					// itemsByPage={10}
																					// styles={styles}
																					// configSearch={{ fieldsToFilter: ItemsToFilter }}
																					options={
																						{
																							pagination: true,
																							card: true,
																							search: true,
																							placeholder: 'Search ...',
																							selectItems: false,
																							xlsx: true,
																							Accordion: { Active: true, Title: 'Shipments List' },
																							addShip: { visible: false, addShipLine: false },
																							// Filter: { filters: params.Filters, form: params.form, funtionFilter: params.funtionFilter, Catalogs: params.Catalogs }
																						}
																					}
																				/> */}
																			</CardBody>
																		</Segment>
																	</Sidebar.Pusher>
																</Sidebar.Pushable>
															</Card>

														</Col>

													</div>
												</Suspense>,
											},
										]}
									/>
								</Col>
							</Row>

						</Container>

						<div className="margin-footer-6" />
					</ContentWrapper>
					:
					<Suspense fallback={<div>Loading... </div>}>
						{
							activeDetailPO ?
								<DetailPO _user={userId} _rolId={props._rolId} poId={poId} noLine={noLine} back={getPONewBuildId} />
								: activeDetailMS ?
									<DetailMS _user={userId} _rolId={props._rolId} poId={poId} noLine={noLine} MS={MS} back={getMSID} _customerSelect={customerSelect} updateDataShip={updateDataShip} />
									: ''
						}
					</Suspense>
			}
		</>
	);
}

export default withRouter(NewBuild);

