import React from 'react';
import Paper from '@material-ui/core/Paper';
import ContentWrapper from './../../template/Layout/ContentWrapper';
import './styles.scss';


/*=============================================
=            FullLoader Screen                =
=============================================*/

function Loader(props){
  return (
    <Paper elevation={0} className="__loader_container" hidden={props.hidden}>
      <ContentWrapper className="__center">
        <div className="col-md-12 __loader">
          <div className="abs-center wd-xl">
            <div className="text-center mb-4">
              <svg version="1.1" width="85px"  height="85px" id="Poseidon_Spinner" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 85 85">
                <path className="st0" d="M60.7,45.5v-9.6l2.5,0.5l-4.5-8.3l-4.3,8.3l2.3-0.5v9.7c0,0,0,4.6-3.6,8.3c-2.4,2.4-6,5.5-8.7,7.6v-3.3V27.8
                l2.4,0.5L42.6,20l-4.5,8.3l2.4-0.5v30.4v3.3c-2.6-2.1-6.2-5.2-8.7-7.6c-3.6-3.7-3.6-8.3-3.6-8.3v-9.6l2.3,0.5l-4.3-8.3l-4.5,8.3
                l2.5-0.5v9.6c0,0.2-0.1,6.2,4.7,11c3.6,3.6,9.5,8.3,11.5,10v4v3.7v0.7c0.5,0.3,0.9,0.5,1,0.6c0.3,0.2,0.6,0.3,1,0.3
                c0.3,0,0.7-0.1,1-0.3c0.1,0,0.4-0.2,1-0.6v-0.7v-3.7v-4c2.1-1.6,8-6.4,11.5-10C60.8,51.7,60.7,45.8,60.7,45.5z">
                  <animate  accumulate="none" additive="replace" attributeName="stroke-dasharray" calcMode="linear" dur="4s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="480 100;480 110">
                  </animate>
                </path>
                <animate  accumulate="none" additive="replace" attributeName="stroke-dashoffset" calcMode="linear" dur="4s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="0;-1180">
                </animate>
                <path className="st0" d="M72,47.8V27.5c0-0.5-0.2-0.9-0.5-1.2c-1.2-0.1-3-0.9-4.5-1.5c-0.7-0.3-1.4-0.6-2.2-1.1
                c-0.2-0.1-0.3-0.2-0.3-0.2l0,0c-6-3.1-14.6-9.2-20.7-13.9c-0.7-0.5-1.8-0.5-2.5,0c-6.1,4.7-14.7,10.8-20.7,13.9l0,0
                c0,0,0,0-0.1,0.1c-0.9,0.5-1.8,0.9-2.5,1.2c-1.5,0.7-3.3,1.4-4.4,1.5c-0.3,0.3-0.5,0.8-0.5,1.2l0,20.3c0,0.7-0.1,7.3,7.3,13.6
                c4.5,3.9,11.7,8.4,16.4,11.2v-1.4c0-0.7-0.3-3.2-2.5-4.7c-3.9-2.5-8.3-5.4-11.3-8c-3.2-2.8-4.7-5.5-5.3-7.3C16.9,49.3,17,48,17,48
                l0-18.8c7.1-1.8,20.9-12,25.5-15.6c4.6,3.5,18.4,13.7,25.5,15.6v18.7l0,0.1c0,0,0,1.3-0.6,3.3c-0.6,1.8-2.1,4.6-5.3,7.3
                c-3,2.6-7.3,5.5-11.2,8c-2.4,1.6-2.6,4.4-2.6,4.8v1.3c4.7-2.9,11.9-7.4,16.4-11.2C72,55.1,72,48.6,72,47.8z">
                  <animate  accumulate="none" additive="replace" attributeName="stroke-dasharray" calcMode="linear" dur="4s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="480 100;480 110">
                  </animate>
              
                  <animate  accumulate="none" additive="replace" attributeName="stroke-dashoffset" calcMode="linear" dur="4s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="0;-1180">
                  </animate>
                </path>
              </svg>                    
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Paper>  
  );
}
export default Loader;