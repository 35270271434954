import React from 'react';
import propTypes from 'prop-types';
import { Form, Icon, Popup, TextArea, Checkbox } from 'semantic-ui-react';
import { DatePicker, AutoComplete } from 'antd';
import { Col } from 'reactstrap';
import Label from 'reactstrap/lib/Label';

// import SemanticDatepicker from 'react-semantic-ui-datepickers';
// import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// import Datetime from 'react-datetime';

import './styles.scss';
import { Typography } from '@material-ui/core';

const moment = require('moment')

const severityColors = {
  'Critical Impact': 'red',
  'Moderate Impact': 'orange',
  'Minimal Impact': 'yellow'
}

function errorValidate(item) {
  let content = false;
  content = item['valid'] ? false : true;
  // console.log('validacion', content);
  return content;
}

function handleRequired(Model, id) {
  let required = false;
  if (Model && Model[id] && Model[id].required) {
    required = true;
  }
  return required;
}

function FormCreator({ dataForm, onChange, Catalog, Model, TabName, placeholders }) {
  const FORM_ITEMS = dataForm.map((item, key) => {
    if (item.type === 'combo')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup className={'tooltip-inner'} content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Form size='tiny'>
            <Form.Select
              id={item.id}
              name={item.name}
              size='small'
              clearable
              onChange={(evt, data) => { onChange(evt.target.value, data, item, TabName); }}
              fluid
              search
              required={handleRequired(Model, item.id)}
              // defaultValue={Model && Model[item.id].value ? Model[item.id].value: false }
              value={Model && Model[item.id].id ? Model[item.id].id : ''}
              options={Catalog && Catalog[item.id] ? Catalog[item.id] : []}
              placeholder={placeholders ? placeholders[item.id] : item.placeholder}
              disabled={item.disabled}
              error={Model && Model[item.id].valid ? false : true}
            />
          </Form>
        </Col>
      );
    else if (item.type === 'text' || item.type === 'password' || item.type === 'text_without_spaces')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Form size='tiny'>
            <Form.Input
              id={item.id}
              required={handleRequired(Model, item.id)}
              // icon={item.iconAction ? <Icon id={`icon-password-${item.id}`} name={item.iconAction} onClick={() => inptIconHandler(item.id)} link></Icon> : item.icon ? <Icon id={`icon-password-${item.id}`} style={{ cursor: 'pointer' }} name={item.icon}></Icon> : ''}
              name={item.name}
              // type={item.type}
              size='small'
              onChange={(evt, data) => { onChange(evt.target.value, data, item, TabName); }}
              fluid
              placeholder={placeholders ? placeholders[item.id] : item.placeholder || (Model && Model[item.id] ? Model[item.id].placeholder : '')}
              disabled={item.disabled}
              // error={errorValidate(Model && Model[item.id] ? Model[item.id] : undefined)}
              value={Model && Model[item.id] && Model[item.id].value ? Model[item.id].value : ''}
              error={Model && Model[item.id].valid ? false : true}
            />
          </Form>
        </Col>
      );
    else if (item.type === 'date')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          {/* <Form size='tiny'> */}
          {/* <DatePicker
              isClearable
              selected={new Date()} 
              onChange={(date) => date}
              placeholderText="I have been cleared!"
            /> */}

          {/* <Datetime/> */}

          {/* <SemanticDatepicker
              showToday={false}
              onChange={onChange}
              
            /> */}
          <DatePicker
            onChange={(date, dateString) => { onChange(date, dateString, item, TabName) }}
            defaultValue={Model && Model[item.id].value ? (moment(Model[item.id].value, 'DD/MM/YYYY')) : ''}
            format={'DD/MM/YYYY'}
            // style={{ width: '100%', borderRadius: '0.28571429rem', }}
            className={Model && Model[item.id].valid ? 'data_picker_style_semantic' : 'error_data_picker_style_semantic'}
          // onChange={(date, dateString) => { console.log('date-->', date);
          // console.log('dateString-->', dateString);}}
          // onChange={(evt, data) => { onChange(evt.target.value, 'input', item.id); }}

          />
          {/* </Form> */}
        </Col>
      );
    else if (item.type === 'autocomplete')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <AutoComplete
            // value={Model && Model[item.id].value ? Model[item.id].value : ''}
            defaultValue={Model && Model[item.id].value ? Model[item.id].value : ''}
            options={Catalog && Catalog[item.id] ? Catalog[item.id] : []}
            // onSelect={onSelect}
            // onSearch={onSearch}
            // onChange={onChange}
            onChange={(data) => { onChange(data, data, item, TabName); }}
            className={Model && Model[item.id].valid ? 'autocomplete_style_semantic' : 'error_autocomplete_style_semantic'}
            // status='error'
            // placeholder="control mode"
            style={{ width: '100%' }}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Col>
      );
    else if (item.type === 'text-area')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class}>
          {/* <Form> */}
          <label className='input__label'>
            {item.description ? <Popup content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Form size='tiny'>
            <TextArea
              id={item.id}
              placeholder={placeholders ? placeholders[item.id] : item.placeholder || (Model && Model[item.id] ? Model[item.id].placeholder : '')}
              onChange={(evt, data) => { onChange(evt.target.value, data, item, TabName); }}
              value={Model && Model[item.id] && Model[item.id].value ? Model[item.id].value : ''}
            />
          </Form>
        </Col>
      );
    else if (item.type === 'title')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} >
          {
            <div>
              <Typography className='title-card-po'>{item.label}</Typography>
              <br></br>
            </div>
          }

        </Col>
      );

    else if (item.type === 'check')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup className={'tooltip-inner'} content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Checkbox
            onClick={(evt, data) => { onChange(evt.target.value, data, item, TabName); }}
            checked={Model[item.id].check}
          // value={Model && Model[item.id].id ? Model[item.id].id : ''}
          // label='Make my profile visible'
          />
        </Col>
      )

    else if (item.type === 'info')
      return (
        <Col key={key} md={item.colm} xl={item.colm} className={item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>{item.label}</label>
          <p className='_value_'>{Model && Model[item.id].label ? Model[item.id].label : Model && Model[item.id].value ? Model[item.id].value : ''}</p>
        </Col>
      );
    else return '';
  });
  return (
    <React.Fragment>
      {FORM_ITEMS}
    </React.Fragment>
  );
};

FormCreator.propTypes = {
  dataForm: propTypes.array,
  Catalog: propTypes.object,
  config: propTypes.object,
  inptIconHandler: propTypes.func,
  Model: propTypes.object,
  onChange: propTypes.func,
  placeholders: propTypes.object,
  type: propTypes.string,
};

FormCreator.defaultProps = {
  dataForm: [],
  Catalog: {},
  config: undefined,
  inptIconHandler: () => { },
  Model: {},
  onChange: () => { },
  placeholder: undefined,
  // type: '---'
};

export default FormCreator;