import React, { Component } from "react";
import Table from "../../../../../template/Table";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Modal, Icon } from "semantic-ui-react";
import { Button } from "reactstrap";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import { Input } from "reactstrap";
import { Checkbox } from "@material-ui/core";
import swal from "sweetalert";
import Errors from "../../../../../template/Errors";
import axios from "axios";
import { Services } from "../../../Services";
import ReportAsing from "../reportAssig";
import { validateIdParams } from "../../../../../utils/regularExpressionValidation";

const styles = {
  background: "red",
};

const config = [
  { label: "Name", id: "Name", type: "simple" },
  { label: "LastName", id: "LastName", type: "simple" },
  { label: "Position", id: "Position", type: "simple" },
  { label: "Email", id: "Email", type: "simple" },
  { label: "Password", id: "Password", type: "simple" },
  { label: "Role", id: "Rol", type: "simple" },
  { label: "Telephone", id: "Telephone", type: "simple" },
];
class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Changedocument: false,
      Changereport: false,
      dataReport: {},
      reportsId: [],
      statusDocuemtens: [],
      userId: "",
      idDefault: "",
      table1: [],
      table2: [],
      clientes: [],
      allClientes: [],
      checkbox: false,
      check: [],
      Roles: [],
      clientesADD: [],
      newClientesDefault: [],
      rows: [],
      edit: false,
      error: {
        open: false,
        message: "",
        type: "admin",
      },
      columnsContact: [
        { label: "ID", key: "CustomerId" },
        { label: "Customer Name", key: "CustomerName" },
        { label: "Customer Code", key: "CustomerCode" },
        { label: "Tax Id", key: "TaxId" },
        { label: "Telephone", key: "Telephone" },
        { label: "Country", key: "Country" },
        { label: "Customer Logo", key: "URLLogo" },
      ],
      itemsContact: [],
      columnsUser: [
        { label: "ID", key: "ID" },
        { label: "Name", key: "Name" },
        { label: "last Name", key: "lastname" },
        { label: "Email", key: "Email" },
        { label: "Role", key: "Role" },
      ],
      itemsUser: [
        {
          ID: 1,
          Name: "carlos rene ",
          lastname: "34kl",
          Email: "12rd",
          Role: "7713241142",
        },
      ],
      country: [],
      user: {
        Name: "",
        LastName: "",
        Position: "",
        Email: "",
        Password: "",
        Rol: "",
        Telephone: "",
      },
      items: [],
      contact: {
        Name: "",
        LastName: "",
        Position: "",
        Telephone: "",
        Email: "",
      },
      refres: false,
      CreateFormData: [],
      CreateForAdd: [],
      Form: [],
      active: false,
      orders: [],
      data: [],
      columns: [
        {
          label: "ID",
          key: "UserId",
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ active: !this.state.active }, () => {
                  this.modalCustomer(value);
                })
              }
            >
              {" "}
              <label style={{ color: "#349ced" }}>{value.UserId}</label>{" "}
            </em>
          ),
        },
        { label: "Name", key: "Name" },
        { label: "Last Name", key: "LastName" },
        { label: "Position", key: "Position" },
        { label: "Email", key: "Email" },
        { label: "Role", key: "Rol" },
        { label: "Telephone", key: "Telephone" },
        { label: "Creation Date", key: "CreateDate" },
        { label: "Associated Customer", key: "AssociatedCustomer" },
        { label: "Action", key: "", custom: (value) => this.custom1(value) },
      ],
      columnsSetrings: [
        { label: "ID", key: "UserId" },
        { label: "Name", key: "Name" },
        { label: "Last Name", key: "LastName" },
      ],
    };
  }

  custom1 = (value) => (
    <em
      onClick={() => this.validateDeleteUser(value)}
      className="fa-2x mr-2 far fa-trash-alt"
      style={{ color: "#297AB9" }}
    ></em>
  );
  custom3 = (value) => (
    <em
      onClick={() => this.deleteCcontact(value)}
      className={this.state.edit ? "fa-2x mr-2 far fa-trash-alt" : ""}
      style={{ color: "#297AB9" }}
    ></em>
  );

  deleteCcontact = (name) => {
    let data = name.ContactId;
    axios
      .delete(
        `${Services.DeleteContact.path}admin/deleteContact` + "/" + `${data}`
      )
      .then(() => {
        this.modalCustomer();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  validateDeleteUser = (value) => {
    let id = `${value.Name}`;
    swal({
      title: "Are You Sure Want To Delete User?",
      text: id,
      icon: "warning",
      buttons: true,
    }).then((yeah) => {
      if (yeah) this.props.deleteUser(value);
    });
  };

  cheked = (name) => {
    let newClientes = this.state.clientes.map((e) => {
      if (e.CustomerId == name.target.id) {
        e.checked = !e.checked;
      }
      return e;
    });
    this.setState(
      {
        clientes: newClientes,
      },
      () => {
        this.table1();
        this.table2();
      }
    );
  };
  modalCustomer = (name) => {
    let id = name.UserId;
    this.setState(
      {
        idDefault: id,
      },
      () => this.getRelacionDocuementsReports()
    );
    let user = {
      Name: name.Name,
      LastName: name.LastName,
      Position: name.Position,
      Email: name.Email,
      Password: "",
      Rol: name.Rol,
      Telephone: name.Telephone,
      RoleId: name.RolId,
      UserId: name.UserId,
    };
    let ids = name.CustomerIds.split(",");
    let newCliente = [];
    let newclientesADD = [];
    let newClientesDefault = [];
    var findDefault = false;
    if (
      this.state.newClientesDefault.length > 0 &&
      id == this.state.idDefault2
    ) {
      findDefault = true;
    }

    this.state.allClientes.map((e) => {
      var findCustomer = false;
      ids.map((f) => {
        if (f == e.CustomerId) {
          findCustomer = true;
        }
      });
      if (!findCustomer) {
        e.checked = false;

        newCliente.push(e);
      } else {
        e.checked = true;
        if (!findDefault) {
          newclientesADD.push(e);
          newClientesDefault.push(e);
        }
      }
    });

    if (findDefault) {
      newclientesADD = this.state.newClientesDefault;
    }
    let idValidate = validateIdParams(id);
    if (!idValidate) {
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/admin");
      }, 2000);
      return;
    }
    axios
      .get(`${Services.GetInfoUser.path}admin/infoUser/` + `${id}`, {})
      .then((response) => {
        this.setState(
          {
            itemsContact: response.data.resp,
            user,
            edit: false,
            clientes: newCliente,
            clientesADD: newclientesADD,
            newClientesDefault: newClientesDefault,
          },
          () => {
            this.buildRows();
            this.table2();
            this.table1();
            this.getRelacionDocuementsReports();
          }
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/admin");
        }, 2000);
        return;
      });
  };
  componentDidMount = () => {
    this.setState({
      userId: this.props.userId,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.errorValidate) {
      this.setState({
        error: {
          message: "Incorrect data, please verify",
          open: true,
          type: "error",
        },
        active: true,
      });
      if (nextProps.modal) {
        this.setState({
          // active: true,
          edit: true,
        });
      } else {
        this.setState({
          // active: false,
          edit: true,
        });
      }
      let dataArray = nextProps.customerList.map((f) => {
        f.checked = false;
        return f;
      });

      this.setState(
        {
          userId: nextProps.userId,
          data: nextProps.UserList,
          CreateFormData: nextProps.CreateFormData,
          Form: nextProps.Form,
          Roles: nextProps.Roles,
          clientes: dataArray,
          allClientes: dataArray,
        },
        () => {
          this.buildFormCreate();
          this.table1();
          this.table2();
        }
      );
    } else {
      if (nextProps.modal) {
        this.setState({
          active: true,
          edit: true,
        });
      } else {
        this.setState({
          active: false,
          edit: false,
        });
      }
      let dataArray = nextProps.customerList.map((f) => {
        f.checked = false;
        return f;
      });

      this.setState(
        {
          userId: nextProps.userId,
          data: nextProps.UserList,
          CreateFormData: nextProps.CreateFormData,
          Form: nextProps.Form,
          Roles: nextProps.Roles,
          clientes: dataArray,
          allClientes: dataArray,
        },
        () => {
          this.buildFormCreate();
          this.table1();
          this.table2();
        }
      );
    }
  };

  getRelacionDocuementsReports = () => {
    let id = this.state.idDefault;
    let idValidate = validateIdParams(id);
    if (!idValidate) {
      this.setState({
        error: {
          message: "Data entered is invalid",
          open: true,
          type: "admin",
        },
      });
      setTimeout(() => {
        this.props.history.push("/admin");
      }, 2000);
      return;
    }
    axios
      .get(
        `${Services.GET_DOCUMENTS_RELATION.path}documents/relation` +
          "/" +
          `${id}`,
        {}
      )
      .then((response) => {
        axios
          .get(
            `${Services.GET_REPORTS_RELATION.path}report/relation` +
              "/" +
              `${id}`,
            {}
          )
          .then((response) => {
            let newArr = [];
            response.data.data.map((e) => {
              newArr.push(e.ReportId);
            });
            this.setState({
              reportsId: newArr,
            });
          })
          .catch((error) => {
            console.error(error);
          });
        this.setState({
          statusDocuemtens: response.data.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: {
            message: "Data entered is invalid",
            open: true,
            type: "admin",
          },
        });
        setTimeout(() => {
          this.props.history.push("/admin");
        }, 2000);
        return;
        console.error(error);
      });
  };

  buildRows = () => {
    let { user } = this.state;
    config.map((e) => {});
    let rows = config.map((e) => {
      return (
        <div
          className={
            e.id === "CustomerName"
              ? "col-md-4 col-lg-3 col-sm-6"
              : "col-md-4 col-lg-2 col-sm-6"
          }
          style={{ paddingTop: "1%" }}
        >
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span style={{ color: "#545350", fontWeight: "bold" }}>
                {e.label}
              </span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span>
                {user[e.id]
                  ? user[e.id]
                  : e.label == "Password"
                  ? "********"
                  : ""}
              </span>
            </div>
          </div>
        </div>
      );
    });
    this.setState({
      rows,
    });
  };

  handleChangeAdd = (name) => (event) => {
    let { contact } = this.state;
    contact[name] = event.target.value;
    this.setState({
      contact,
    });
  };

  handleChange = (name) => (event) => {
    let { user } = this.state;
    user[name] = event.target.value;
    this.setState(
      {
        user,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  handleChangeSelect = (selected) => (event) => {
    if (event && event.value) {
      let { user } = this.state;
      user[selected] = event.value;

      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    } else {
      let { user } = this.state;
      user[selected] = "";
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
  };

  buildFormCreate = () => {
    const { Form, user, Roles } = this.state;
    let CreateFormData = Form.editUser.map((e) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              id={e.id}
              disabled={this.state.edit === false ? true : false}
              required
              type={e.datatype}
              placeholder={e.id === "Password" ? "Add new password" : ""}
              name={e.id}
              value={user[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Select")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}>
                {" "}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Select
              id={e.id}
              disabled={this.state.edit === false ? true : false}
              required
              type={e.datatype}
              name={e.name}
              value={user[e.id]}
              onChange={this.handleChangeSelect(e.id)}
              options={Roles}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else return "";
    });
    this.setState({
      CreateFormData,
    });
  };

  handleOnClose = () => {
    this.setState(
      {
        active: false,
        edit: false,
        contact: {
          Name: "",
          LastName: "",
          Position: "",
          Telephone: "",
          Email: "",
        },
        idDefault2: this.state.idDefault,
      },
      () => {
        this.table2();
        this.table1();
      }
    );
  };

  addAll = () => {
    let newClientes = this.state.allClientes.map((e) => {
      e.checked = false;
      return e;
    });
    this.setState({ clientes: [], clientesADD: newClientes }, () => {
      this.table2();
      this.table1();
    });
  };

  add = () => {
    let { clientes, clientesADD } = this.state;
    let allCliente = clientesADD;
    let allCliente2 = clientes.filter((e) => {
      if (!e.checked) {
        return e;
      } else {
        allCliente.push(e);
      }
    });
    this.setState(
      {
        clientesADD: allCliente,
        clientes: allCliente2,
      },
      () => {
        this.table2();
        this.table1();
        this.setState({
          error: {
            message: "Customers added successfully",
            open: true,
            type: "admin",
          },
        });
      }
    );
  };

  deletAdd = () => {
    let newClientes = this.state.allClientes.map((e) => {
      e.checked = false;
      return e;
    });
    this.setState({ clientes: newClientes, clientesADD: [] }, () => {
      this.table2();
      this.table1();
    });
  };

  putUser = () => {
    let dataReport2 = {
      data: this.state.reportsId,
      userId: this.state.idDefault,
    };
    let data = {
      dataUser: this.state.user,
      itemsCUstomer: this.state.clientesADD,
      report: this.state.Changereport ? this.state.dataReport : dataReport2,
      document: this.state.Changedocument
        ? this.state.dataDocuments
        : this.state.statusDocuemtens,
    };
    this.props.saveUser(data);
    this.setState({
      newClientesDefault: this.state.clientesADD,
      dataReport: [],
      dataDocuments: [],
      Changedocument: false,
      Changereport: false,
    });
  };

  deleteCustomer = (id) => (event) => {
    let { clientes, clientesADD, allClientes } = this.state;
    let newClientes = clientes;
    allClientes.map((e) => {
      if (e.CustomerId === id) {
        newClientes.push(e);
      }
    });
    let newClientesADD = clientesADD.filter((e) => {
      if (e.CustomerId !== id) {
        return e;
      }
    });
    this.setState(
      { clientes: newClientes, clientesADD: newClientesADD },
      () => {
        this.table2();
        this.table1();
      }
    );
  };

  table2 = () => {
    let tableADD = [];
    this.state.clientesADD.map((e, index) => {
      tableADD.push(
        <tr key={index}>
          <td>{e.CustomerId}</td>
          <td>{e.CustomerName}</td>
          <td>{e.CustomerCode}</td>
          <em
            onClick={this.deleteCustomer(e.CustomerId)}
            className="fa-1x mr-1 far fa-trash-alt"
            style={{ color: "#297AB9", marginTop: "15px" }}
          ></em>
        </tr>
      );
    });
    let taleAD2 = [];
    taleAD2.push(
      <Col md="6">
        <div className="table-wrapper-scroll-y">
          <table className="table">
            <thead>
              <tr>
                <th className="eventsTableHeader">ID</th>
                <th className="eventsTableHeader">Customer Name</th>
                <th className="eventsTableHeader">Customer code</th>
                <th className="eventsTableHeader"></th>
              </tr>
            </thead>
            <tbody className="eventsTableBody">{tableADD}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      table2: taleAD2,
    });
  };

  table1 = () => {
    let table = [];
    this.state.clientes.map((e, index) => {
      table.push(
        <tr key={index}>
          <th>
            <Checkbox
              checked={e.checked}
              onChange={this.cheked}
              value={e.checked}
              id={e.CustomerId}
            />
          </th>
          <td>{e.CustomerId}</td>
          <td>{e.CustomerName}</td>
          <td>{e.CustomerCode}</td>
        </tr>
      );
    });

    let tale2 = [];
    tale2.push(
      <Col md="6">
        <div className="table-wrapper-scroll-y">
          <table className="table">
            <thead>
              <tr>
                <th className="eventsTableHeader"></th>
                <th className="eventsTableHeader">ID</th>
                <th className="eventsTableHeader">Customer Name</th>
                <th className="eventsTableHeader">Customer code</th>
                <th className="eventsTableHeader"></th>
              </tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      table1: tale2,
    });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  dataReport = (data, edit) => {
    let dataReport = { data: data, userId: this.state.idDefault };
    this.setState({
      dataReport,
      reportsId: data,
      Changereport: true,
    });
  };

  dataDocuments = (dataPo, dataship, edit) => {
    let array = [];
    dataPo.map((e) => {
      if (e.check) {
        let data = {
          DocCategoryId: e.DocCategoryId,
          ModuleId: e.ModuleId,
          UserId: this.state.idDefault,
        };
        array.push(data);
      }
    });
    dataship.map((e) => {
      if (e.check) {
        let data = {
          DocCategoryId: e.DocCategoryId,
          ModuleId: e.ModuleId,
          UserId: this.state.idDefault,
        };
        array.push(data);
      }
    });
    this.setState({
      dataDocuments: array,
      statusDocuemtens: array,
      Changedocument: true,
    });
  };

  render() {
    const { data, columns } = this.state;
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <div style={{ textAlign: "center" }} className="col-md-12">
              <strong>User</strong>
            </div>
            <Table
              columns={columns}
              data={data}
              itemsByPage={10}
              styles={styles}
              options={{
                pagination: true,
                search: true,
                downloads: {
                  xlsx: false,
                  pdf: false,
                },
              }}
            />
          </CardBody>
        </Card>
        <Modal
          open={this.state.active}
          size={"large"}
          dimmer={"inverted"}
          centered={false}
          scrolling
        >
          <Modal.Header className="headerModal">
            {this.state.edit == false ? "View User" : "Edit User"}
            <div className="iconHeader">
              <Icon
                name="pencil"
                size="small"
                disabled=""
                onClick={() => {
                  this.setState(
                    { edit: !this.state.edit, refres: true },
                    () => {
                      this.buildFormCreate();
                    }
                  );
                }}
              />
            </div>
          </Modal.Header>
          <Errors
            open={this.state.error.open}
            type={this.state.error.type}
            message={this.state.error.message}
            setProps={this.recieveProps}
          ></Errors>
          <Modal.Content scrolling>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px" }}>User Information</strong>
            </div>
            <br></br>
            <div className="row" style={{ width: "100%", marginLeft: "20px" }}>
              {this.state.edit == false ? (
                <div style={{ marginTop: "13px" }} className="col-md-12">
                  <div className="row">{this.state.rows}</div>
                </div>
              ) : (
                <div className="col-md-10">
                  <div className="row">{this.state.CreateFormData}</div>
                </div>
              )}
            </div>
            <br></br>
            <div hidden={this.state.edit == true ? true : false}>
              <div className="col-md-12">
                <strong style={{ marginLeft: "-12px" }}>Customer List</strong>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-12">
                  <Table
                    data={this.state.itemsContact}
                    columns={this.state.columnsContact}
                    options={{
                      pagination: false,
                      search: false,
                      downloads: { xlsx: false, pdf: false },
                    }}
                  ></Table>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <div
              hidden={this.state.edit == true ? false : true}
              className="row"
            >
              <div className="col-md-4 col-lg-3 col-sm-12"></div>
              <div
                style={{ textAlign: "right" }}
                className="col-md-3 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={this.addAll}
                >
                  Add all
                </Button>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="col-md-2 col-lg-1 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={this.add}
                >
                  Add
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button
                  variant="contained"
                  color="danger"
                  className="button"
                  onClick={this.deletAdd}
                >
                  Delete all
                </Button>
              </div>
            </div>
            <br></br>
            <br></br>
            <Row hidden={this.state.edit == true ? false : true}>
              {this.state.table1}
              {this.state.table2}
            </Row>
            <br></br>
            <br></br>
            <div
              hidden={this.state.edit == true ? false : true}
              className="col-md-12"
            >
              <ReportAsing
                refres={this.state.refres}
                reportsId={this.state.reportsId}
                statusDocuemtens={this.state.statusDocuemtens}
                edit={true}
                dataDocuments={this.dataDocuments}
                dataReport={this.dataReport}
                newSelected={this.state.clientesADD}
              ></ReportAsing>
            </div>
          </Modal.Content>
          <Modal.Actions className="actions_modalSelect">
            <div
              hidden={this.state.edit == true ? true : false}
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-12 col-sm-4"
            >
              <Button
                variant="contained"
                color="danger"
                className="button"
                onClick={this.handleOnClose}
              >
                Close
              </Button>
            </div>
            <div
              hidden={this.state.edit == true ? false : true}
              className="row"
            >
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: "center" }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  className="button"
                  onClick={this.handleOnClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={this.putUser}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
export default ListUser;
