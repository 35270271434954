export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_NO = 'SET_NO';
export const SET_CONTAINER_REFERENCE = 'SET_CONTAINER_REFERENCE';
export const PUSH_HISTORI = 'PUSH_HISTORI';
export const SAVE_PROCEDURE = 'SAVE_PROCEDURE';
export const ASSIGN_PROCEDURE = 'ASSIGN_PROCEDURE';









