
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row, Card, CardBody, Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import AddIcon from '@material-ui/icons/Add';
import { Form, Popup } from 'semantic-ui-react'
// import Axios from "axios";
// import { Services } from "../../Services";



function Comments(props) {
	// console.log("🚀 ~ file: comments.js ~ line 16 ~ Comments ~ props", props.Model)
	const [loader, setLoader] = useState(false)
	const [comments, setComments] = useState([])
	// const [modal, setModal] = React.useState(false);

	// function toggleModal() {
	// 	setModal(!modal);
	// }


	function changueLoader(params) {
		setTimeout(() => {
			setLoader(true)
		}, 3000);
	}


	useEffect(() => {
		setComments(props.comments)
	}, [props])

	return (
		<>
			{/* <Col xs={12} md={12}>
				<Row>
					<props.FormCreator dataForm={props.dataForm} Model={props.Model} onChange={props.onChange} TabName={props.TabName} Catalog={props.Catalogs} />
				</Row>
			</Col> */}
			<Col xs={12} md={6} lg={6} >
				<Row>
					<Col xs={12} md={12} lg={12}>
						{props.shipmentLine.name}
					</Col >
				</Row>
			</Col>
			<Col xs={12} md={8} lg={8} className='mr-auto ml-auto' id="scrollComments">
				<CardBody>
					<div style={{ textAlign: 'right' }} >
						<Popup
							inverted
							content='New comment'
							size='mini'
							trigger={
								<Fab size="small" color="primary" aria-label="add" onClick={() => { props.toggleModalComments() }}>
									<AddIcon />
								</Fab>
							} />
					</div>
					<Timeline lineColor={'#fff'}>
						{
							comments.map((e) => {
								return (
									<TimelineItem
										key={e.CommentId}
										dateText={`${e.Date}`}
										dateInnerStyle={{ background: `${e.Color}`, fontWeight: 'bold' }}
									>
										<Card className="myCard">
											<CardBody>
												{/* <Col> */}
												<div className="row">
													<div className="col-12">
														<span style={{ fontWeight: 'bold' }}>{e.ComType}</span>
													</div>
													<div className="col-12">
														<span>{e.Comment}</span>
													</div>
													<Col md={12} className="footer_comments">
														<div className="user_footer">
															{e.User ? e.User : ''}
														</div>
													</Col>
												</div>
												{/* </Col> */}
											</CardBody>
										</Card>
									</TimelineItem>
								)
							})
						}
					</Timeline>
				</CardBody>


				{/* <div className="row">
					<div style={{ position: 'fixed', bottom: '334px', right: '0px' }} className="col-1">
						<Fab color="primary" size="small" aria-label="add" onClick={toggleModal}>
							<AddIcon />
						</Fab>
					</div>
				</div> */}
				<Modal isOpen={props.modalComment} toggle={() => { props.toggleModalComments() }} >
					<ModalHeader toggle={() => { props.toggleModalComments() }}>Create new comment</ModalHeader>
					<props.FormCreator dataForm={props.dataForm} Model={props.Model} Catalog={props.Catalogs} onChange={props.onChange} TabName={props.TabName} />
					{
						// 		<ModalBody>
						// 		<Form size="tiny">
						// 			<Form.Select
						// 				// fluid
						// 				id={'ComTypes'}
						// 				label='Comment Type'
						// 				clearable
						// 				options={props.Catalogs.ComType}
						// 				placeholder='Comment Type'
						// 				onChange={(evt, data) => { props.onChange(evt.target.value, data); }}
						// 			/>
						// 			<Form.TextArea label='Comment' placeholder='Add Comment...' />
						// 		</Form>
						// </ModalBody>
					}
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							className="button"
							onClick={() => { props.addComment() }}
							size="lg"
							// disabled={true}
							hidden={props.Model.hiddenButtonSave.hidden}
						>
							Save and complete
						</Button>
					</ModalFooter>
				</Modal>

			</Col>
		</>
	);
}

export default withRouter(Comments);

