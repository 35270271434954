import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import queryString from 'query-string';
import { Services } from './Services';
import axios from 'axios';
import Authentication from '../../../../src/services/Authentication.js';
const auth = new Authentication();

const Customers = [
  { id: 1, text: 'SEAR' },
  { id: 2, text: 'LIVERPOOL' },
  { id: 3, text: 'GALERIAS' },
  { id: 4, text: 'PULI YOPER' },
  { id: 5, text: 'SUPLAZA' },
  { id: 6, text: 'INTERlENGUA' },
];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: '',
      Toke: '',
      userData: '',
      RoleId: undefined,
      flat: false,
      Customers: [],
      customerSelect: '',
      CreateFormData: [],
      login: {
        email: props.email ? props.email : '',
        password: ''
      },
      fakeLogin: {
        email: 'info@magic-log.com',
        password: '123456'
      }
    };
  }


  componentDidMount = () => {
    this.selectCustomer();

  }


  validatorData = name => event => {
    let { login } = this.state;

    login.password = event.target.value;

    this.setState({ login });

  }
  handleLogin = () => {
    let { login } = this.state;
    login.password = document.getElementById('input_password').value;

    const values = queryString.parse(this.props.location.search);

    let haveClient = false;
    let havePO = false;
    let newCs = '';
    let newPO = '';
    if (values) {
      if (values.cs) {
        newCs = values.cs.replace(/\s/g, '+');
        haveClient = true;
      }
      if (values.po) {
        havePO = true;
        newPO = values.po.replace(/\s/g, '+');
      }
    }
    if (!login.email) {


      this.props.onError({
        error: {
          message: 'Email is required',
          open: true,
          type: 'warning'
        }
      });
    }
    else if (!login.password) {
      this.props.onError({
        error: {
          message: 'Password is required',
          open: true,
          type: 'warning'
        }
      });
    }
    else {
      let data = {
        email: login.email,
        password: login.password,
        client: haveClient ? newCs : false,
        po: havePO ? newPO : false
      };


      axios.post(`${Services.PostOauth.path}oauth/setAuthentication`, data).then(response => {
        let id = response.data.data[0].UserId;
        let rolId = response.data.data[0].RolId;

        this.setState({
          Token: response.data.token,
          userData: response.data.data,
          RoleId: rolId
        });

        if (haveClient) {
          auth.setAuthentication(this.state.Token, this.state.userData, Number(response.data.payload.client), this.state.customerName);
          if (values.to) {
            switch (values.to) {
              case 'po':
                this.props.history.push('/order-list');
                break;
              case 'podetail':
                if (response.data.payload.po) {
                  this.props.history.push('/po-detail-view/' + response.data.payload.po);
                }
                else {
                  this.props.history.push('/order-list');
                }
                break;
              default:
                this.props.history.push('/dashboard');
            }
          }
          else {
            this.props.history.push('/dashboard');
          }
        }
        else {
          axios.get(`${Services.GetCustomerForUserId.path}admin/customerForId` + '/' + `${id}`, {
          }).then(response => {
            if (response.data.resp.length == 1) {
              this.setState({
                flat: false
              });
            }
            let data = [];
            response.data.resp.forEach((e) => {
              data.push({ value: e.CustomerId, label: e.CustomerName });
            });

            if (data.length === 1) {
              this.setState({
                customerSelect: data[0].value,
                customerName: data[0].label
              }, () => {
                auth.setAuthentication(this.state.Token, this.state.userData, this.state.customerSelect, this.state.customerName);
                if (this.state.RoleId === 8 || this.state.RoleId === 9) {
                  this.props.history.push("/New-Build");
                } else if (this.state.RoleId === 10) {
                  this.props.history.push("/vendor-management");
                } else if (this.state.RoleId === 11) {
                  this.props.history.push("/order-list");
                } else {
                  this.props.history.push("/dashboard");
                }
                this.logingSesion();
              });
            }
            else {
              this.setState({
                Customers: data,
                flat: true,
              });
            }
          }).catch(error => {
            console.error(error);
          });
        }
      }
      ).catch(() => {
        document.getElementById('error_alert').removeAttribute('hidden');
      });
    }
  }

  selectCustomer = () => {

    let CreateFormData = Customers.map((option, i) => {
      return (
        <select className="_input" form="carform">
          <option key={option.id} value={option.id}> {option.text}</option>
        </select>
      );
    });
    this.setState({
      CreateFormData
    });
  }

  validateOnChange = name => event => {

    if (event && event.value) {
      let customerName = '';
      for (let index = 0; index < this.state.Customers.length; index++) {
        const e = this.state.Customers[index];
        if (e.value === event.value) {
          customerName = e.label;
        }
      }

      let id = event.value;
      this.setState({
        customerSelect: id,
        customerName: customerName
      }, () => {
        auth.setAuthentication(this.state.Token, this.state.userData, this.state.customerSelect, customerName);
        if (this.state.RoleId === 8 || this.state.RoleId === 9) {
          this.props.history.push('/New-Build');
        } else if(this.state.RoleId === 10 ) {
          this.props.history.push('/vendor-management');
        } else if(this.state.RoleId === 11 ) {
          this.props.history.push('/order-list');
        } else {
          this.props.history.push('/dashboard');
        }
        this.logingSesion();
      });
    } else {
      let id = '';
      this.setState({
        customerSelect: id
      }, () => { this.logingSesion() });
    }
  }

  logingSesion = () => {
    let { customerSelect, userData, Token } = this.state;

    const createOffsetValue = date => {
      let sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
      let offset = Math.abs(date.getTimezoneOffset());
      let hours = Math.floor(offset / 60);
      let minutes = (offset % 60) / 60 * 10;
      return sign + hours + '.' + minutes;
    };

    let sendToCli = {
      'UserId': userData[0].UserId,
      'CustomerId': customerSelect,
      'Logout': '0',
      'SessionId': Token,
      'Origin': 'web',
      'timezone': createOffsetValue(new Date())
    };

    fetch(`${Services.SESSIONRECORD.path}/user/login/`, {
      method: 'POST',
      body: JSON.stringify(sendToCli),
      keepalive: true
    });
  }


  render() {
    return (
      <div className="col-md-12 _containerLogin">
        <input hidden={this.state.flat ? true : false} type="text" className="_input" placeholder="Email Address" defaultValue={this.state.login.email} disabled></input>
        <input hidden={this.state.flat ? true : false} type="password" className="_input" placeholder="Password" id="input_password" value={this.state.login.password} onChange={this.validatorData()}></input>
        {this.state.flat ?
          <div className="col-md-8">
            <Select
              id=""
              placeholder="Select Customer"
              required
              type=""
              name=""
              value={this.state.customerSelect}
              onChange={this.validateOnChange()}
              options={this.state.Customers}
            /></div>
          : <></>}
        {this.state.flat ? <></> : <div className="_alert" id="error_alert" hidden>Looks like you have the wrong email or password</div>}
        <button hidden={this.state.flat ? true : false} className="_loginButton" onClick={this.handleLogin}>Sign In</button>
      </div>
    );
  }
}

export default withRouter(Login);
