/*eslint-disable*/
import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Link, withRouter, useParams } from "react-router-dom";
import moment from "moment";
// import faker from 'faker';
// import "react-table-filter/lib/styles.css";

import { Button, Card, CardBody, Col, Row } from "reactstrap";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import FormCreator from "../../../Components/FormBuilder";
import { FormComponent } from "./components/Form";
import { Model_New_Build } from "./components/Model";
// import Table from '../../../../components/template/TableComponents/Table'
import TableFilterComponents from "../../../Components/TableFilters";
import { Popup, Tab, Form, Input } from "semantic-ui-react";
import { Services } from "../Services";
import Axios from "axios";
import Swal from "sweetalert2";
import {
  ValidatorService,
  ValidatorSchema,
  ValidatorSchema2,
} from "../../../Components/Helpers";
import Courier from "../components/svg_img/courier.svg";
import Loader from "../../../Global/Loader";
import path from "path";

import Production from "./Tabs/production";
import Warehousing from "./Tabs/warehousing";
import Transportation from "./Tabs/transportation";
import Events from "./Tabs/events";
import Cost from "./Tabs/Cost";
import Comments from "./Tabs/comments";
import Documents from "./Tabs/documents";
import { Accordion, Typography, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { message } from "antd";
import { validateIdParams } from "../../../utils/regularExpressionValidation";

// const TableFilterComponents = React.lazy(() => import("../../../Components/TableFilters"));

const TransportMode = {
  1: "fas fa-ship fa-lg",
  2: "fas fa-ship fa-lg",
  3: "fas fa-plane fa-lg",
  4: "fas fa-truck fa-lg",
  5: "fas fa-truck fa-lg",
  6: <img src={Courier} />,
  // 6: <Warning />
};

// const Message = {
// 	CargoReadyAlert: { '#FF0000': 'Cargo readiness - Out of Time', '#32CB2F': 'Cargo readiness - On Time', '#B5B2B2': 'Cargo readiness' },
// 	DeliveryAlert: { '#FF0000': 'Delivery - Out of Time', '#32CB2F': 'Delivery - On Time', '#B5B2B2': 'Delivery', '#000000': 'Delivery - Out of Project Cut-off' }
// }

const Color = {
  0: "#FF0000",
  1: "#7E7E7E",
  2: "#000000",
  "On Time": "#32CB2F",
  "Out of Time": "#FF0000",
  "Out of Project Cut-off": "#000000",
};

function BuildDetail(props) {
  // const { id, line } = useParams();
  const [id, setId] = useState(props.poId);
  const [line, setLine] = useState(props.noLine);

  const [fullLoader, setFullLoader] = useState(false);
  const [form, setForm] = useState(FormComponent.Order_Summary);
  const [modelNewBuild, setModelNewBuild] = useState({ ...Model_New_Build() });
  const [Catalogs, setCatalogs] = useState({ ...Model_New_Build().Cat_Detail });
  const [shipmentLine, setShipmentLine] = useState([]);
  const [shipLineIdSelected, setShipLineIdSelected] = useState(undefined);
  const [editPO, setEditPO] = useState(false);
  const [editShip, setEditShip] = useState(false);
  const [headerInfo, setHeaderInfo] = useState({});
  const [key, setKey] = useState(1);
  const [modalComment, setModalCommet] = React.useState(false);
  const [groupShipments, setGroupShipments] = useState({});
  const [comments, setComments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [dataShipLine, setDataShipLine] = useState([]);
  const [userId, setUserId] = useState(undefined);
  const [rolId, setRolId] = useState(undefined);
  const [groupsEvents, setGroupsEvents] = useState([]);
  // const [columns, setColumns] = useState([
  // 	{
  // 		key: '', label: '', width: '5px', custom: (value) => <>
  // 			<Popup
  // 				basic
  // 				inverted
  // 				content={Message.CargoReadyAlert[value.CargoReadyAlert]}
  // 				// content='Cargo readiness'
  // 				size='mini'
  // 				trigger={
  // 					<i className="fas fa-circle fa-lg mr-1 ml-1"
  // 						// style={{ color: Color[Math.round(Math.random() * 1)] }}
  // 						style={{ color: value.CargoReadyAlert }}
  // 					></i>
  // 				} />

  // 			<Popup
  // 				basic
  // 				inverted
  // 				content={Message.DeliveryAlert[value.DeliveryAlert]}
  // 				// content='Delivery'
  // 				size='mini'
  // 				trigger={
  // 					<i className="fas fa-circle fa-lg mr-1 ml-1"
  // 						//  style={{ color: Color[Math.round(Math.random() * 1)] }}
  // 						style={{ color: value.DeliveryAlert }}
  // 					></i>
  // 				} />
  // 		</>
  // 	},
  // 	{
  // 		key: 'NoLine', label: 'No. Line', class: 'headerText', custom: (value) =>
  // 			<Col style={{ cursor: editShip ? 'not-allowed' : 'pointer' }}
  // 				onClick={() => { setShipmentLine({ name: value.NoLine, status: value.Status }), shipLineId(value.ShipmentNewBuildId) }}>
  // 				<span style={{ color: '#5D9CEC' }}>{value.NoLine}</span>
  // 			</Col>
  // 	},
  // 	{
  // 		key: 'TransportMode', label: 'Transport mode', class: 'headerText', custom: (value) =>
  // 			<div >
  // 				{value.TransportModeId !== 6
  // 					?
  // 					<i className={TransportMode[value.TransportModeId]} style={{ color: '#7E7E7E' }} />
  // 					:
  // 					TransportMode[value.TransportModeId]
  // 				}
  // 			</div>
  // 	},
  // 	// { key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Badge style={{ background: '#5D9CEC', padding: '0.4em' }} pill>&nbsp;&nbsp;&nbsp;&nbsp;{value.Status}&nbsp;&nbsp;&nbsp;&nbsp;</Badge> },
  // 	{ key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Button className={'btn-oval'} color={'primary'} >{value.Status}</Button> },
  // 	{ key: 'ContainerNo', label: 'Container No.', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ContainerNo}</span> },
  // 	{ key: 'POL', label: 'POL', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.POL}</span> },
  // 	{ key: 'POD', label: 'POD', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.POD}</span> },
  // 	// { key: 'ReadyDateMSC', label: 'Type of delivery', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ReadyDateMSC}</span> },
  // 	{ key: 'PromiseCargoReady', label: 'Promise cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.PromiseCargoReady}</span> },
  // 	// { key: 'ActualCargoReadyDate', label: 'Actual cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ActualCargoReadyDate}</span> },
  // 	{ key: 'ConfirmedCargoReady', label: 'Confirmed cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ConfirmedCargoReady}</span> },
  // 	{ key: 'ETD', label: 'ETD', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ETD}</span> },
  // 	{ key: 'ETA', label: 'ETA', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ETA}</span> },
  // 	{ key: 'ActualDeliveryDate', label: 'Confirmed delivery', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ActualDeliveryDate}</span> },

  // ])
  const [columnsShipLines, setColumnsShipLines] = useState([
    // {
    // 	key: '', label: '', width: '5px', custom: (value) => <>
    // 		<Popup
    // 			basic
    // 			inverted
    // 			content={Message.CargoReadyAlert[value.CargoReadyAlert]}
    // 			// content='Cargo readiness'
    // 			size='mini'
    // 			trigger={
    // 				<i className="fas fa-circle fa-lg mr-1 ml-1"
    // 					// style={{ color: Color[Math.round(Math.random() * 1)] }}
    // 					style={{ color: value.CargoReadyAlert }}
    // 				></i>
    // 			} />

    // 		<Popup
    // 			basic
    // 			inverted
    // 			content={Message.DeliveryAlert[value.DeliveryAlert]}
    // 			// content='Delivery'
    // 			size='mini'
    // 			trigger={
    // 				<i className="fas fa-circle fa-lg mr-1 ml-1"
    // 					//  style={{ color: Color[Math.round(Math.random() * 1)] }}
    // 					style={{ color: value.DeliveryAlert }}
    // 				></i>
    // 			} />
    // 	</>
    // },
    {
      key: "NoLine",
      label: "No. Line",
      maxWidth: "6em",
      minWidth: "10em",
      class: "headerTextShipList",
      custom: (value) => (
        <Col
          style={{
            cursor:
              value.Selected !== undefined
                ? value.Selected
                  ? "pointer"
                  : "not-allowed"
                : "pointer",
          }}
          onClick={
            value.Selected === undefined
              ? () => {
                  shipLineId(value.ShipmentNewBuildId);
                }
              : ""
          }
        >
          <span
            className={
              value.Selected !== undefined
                ? value.Selected
                  ? "lineSelectedOn"
                  : "lineSelectedOff"
                : "lineSelected"
            }
          >
            {" "}
            {value.NoLine}
          </span>
        </Col>
      ),
    },
    {
      key: "TransportMode",
      label: "Transport Mode",
      maxWidth: "6em",
      minWidth: "10em",
      class: "headerTextShipList",
      custom: (value) => (
        <div>
          {value.TransportModeId !== 6 ? (
            <i
              className={TransportMode[value.TransportModeId]}
              style={{ color: "#7E7E7E" }}
            />
          ) : (
            TransportMode[value.TransportModeId]
          )}
        </div>
      ),
    },
    {
      key: "PONo",
      label: "Po.",
      maxWidth: "6em",
      minWidth: "10em",
      class: "headerTextShipList",
      custom: (value) => <span className="columTableStyle">{value.PONo}</span>,
    },
    // { key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Button className={'btn-oval'} color={'primary'} >{value.Status}</Button> },
    // { key: 'Status', label: 'Status', class: 'headerText', custom: (value) => <Badge style={{ background: '#5D9CEC', padding: '0.4em' }} pill>&nbsp;&nbsp;&nbsp;&nbsp;{value.Status}&nbsp;&nbsp;&nbsp;&nbsp;</Badge> },
    {
      key: "Status",
      label: "Status",
      maxWidth: "11em",
      minWidth: "11em",
      class: "headerTextShipList",
      custom: (value) => (
        <span
          className="columTableStyle"
          style={{ color: "#09b1e5", fontWeight: "bold" }}
        >
          {value.Status}{" "}
        </span>
      ),
    },
    // { key: 'ContainerNo', label: 'Container No.', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ContainerNo}</span> },
    {
      key: "NewBuild",
      label: "Project",
      maxWidth: "8em",
      minWidth: "10em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">{value.NewBuild}</span>
      ),
    },
    {
      key: "Department",
      label: "Department",
      maxWidth: "9em",
      minWidth: "10em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">{value.Department}</span>
      ),
    },
    {
      key: "Supplier",
      label: "Supplier",
      maxWidth: "10em",
      minWidth: "10em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">{value.Supplier}</span>
      ),
    },
    {
      key: "POL",
      label: "POL",
      maxWidth: "11em",
      minWidth: "11em",
      class: "headerText",
      custom: (value) => <span className="columTableStyle">{value.POL}</span>,
    },
    // { key: 'POD', label: 'POD', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.POD}</span> },
    // { key: 'ReadyDateMSC', label: 'Type of delivery', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ReadyDateMSC}</span> },
    {
      key: "TypeDelivery",
      label: "Type of delivery",
      maxWidth: "11em",
      minWidth: "11em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">{value.TypeDelivery}</span>
      ),
    },
    {
      key: "ContainerNo",
      label: "Container/ Plate",
      maxWidth: "11em",
      minWidth: "11em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">
          {value.ContainerNo ? value.ContainerNo : value.BL_AWB}
        </span>
      ),
    },
    {
      key: "PromiseCargoReady",
      label: "Promise cargo ready",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">{value.PromiseCargoReady}</span>
      ),
    },
    // { key: 'ActualCargoReadyDate', label: 'Actual cargo ready', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ActualCargoReadyDate}</span> },
    {
      key: "ConfirmedCargoReady",
      label: "Confirmed cargo ready",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">{value.ConfirmedCargoReady}</span>
      ),
    },
    {
      key: "CargoReadyAlert",
      label: "Cargo readiness",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => (
        <span
          className="columTableStyle"
          style={{ color: Color[value.CargoReadyAlert], fontWeight: "bold" }}
        >
          {value.CargoReadyAlert}
        </span>
      ),
    },
    {
      key: "ETD",
      label: "ETD",
      maxWidth: "7em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => <span className="columTableStyle">{value.ETD}</span>,
    },
    {
      key: "ETA",
      label: "ETA",
      maxWidth: "7em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => <span className="columTableStyle">{value.ETA}</span>,
    },
    {
      key: "DateDelivery",
      label: "Date of delivery",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">
          {value.DateDelivery
            ? moment(new Date(value.DateDelivery)).format("DD/MM/YYYY")
            : "---"}
        </span>
      ),
    },
    {
      key: "NewPlannedDeliveryDate",
      label: "New planed delivery",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => (
        <span className="columTableStyle">
          {value.NewPlannedDeliveryDate
            ? moment(new Date(value.NewPlannedDeliveryDate)).format(
                "DD/MM/YYYY"
              )
            : "---"}
        </span>
      ),
    },
    {
      key: "ActualDeliveryDate",
      label: "Confirmed delivery",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerText",
      custom: (value) => (
        <span className="columTableStyle">{value.ActualDeliveryDate}</span>
      ),
    },
    {
      key: "DeliveryAlert",
      label: "On time delivery",
      maxWidth: "13em",
      minWidth: "13em",
      class: "headerTextShipList",
      custom: (value) => (
        <span
          className="columTableStyle"
          style={{ color: Color[value.DeliveryAlert], fontWeight: "bold" }}
        >
          {value.DeliveryAlert}
        </span>
      ),
    },
    {
      key: "CombinedLabel",
      label: "Combined",
      maxWidth: "8em",
      minWidth: "8em",
      class: "headerTextShipList",
      custom: (value) => (
        <>
          {/* <div style={{ cursor: 'pointer' }}> */}
          {value.Combined !== "0" ? (
            <i className="fas fa-check" style={{ color: "#13B0F0" }}></i>
          ) : (
            ""
          )}
          {/* </div> */}
        </>
      ),
    },
    {
      key: "",
      label: "",
      class: "headerTextShipList",
      custom: (value) => (
        <div className="d-flex align-items-center">
          {/* <Tooltip placement={'sdsd'}>
						<i style={{ cursor: 'pointer' }} onClick={() => { copy(value.ShipmentNewBuildId) }}>
							<Copy />
						</i>
					</Tooltip> */}
          {/* 
					<i style={{ cursor: 'pointer' }} onClick={() => { copy(value.ShipmentNewBuildId) }}>
						<Copy />
					</i>
					<i className="mr-2 ml-2" style={{ cursor: 'pointer' }} onClick={() => { ConfirmDeleteShip(value.ShipmentNewBuildId) }}>
						<Delete />
					</i> */}

          <Popup
            inverted
            // basic
            trigger={
              // <i className="mr-2 ml-2" style={{ cursor: 'pointer' }} onClick={() => { copy(value.ShipmentNewBuildId) }}>
              // 	<Copy />
              // </i>
              // <img src={CopyPNG} alt="copy image" width={'17%'}></img>
              // <div style={{ fontSize: '0.7rem' }}>
              <i
                className="far fa-copy fa-lg mr-2 ml-2"
                style={{ cursor: "pointer", color: "#5D9CEC" }}
                onClick={() => {
                  copy(value.ShipmentNewBuildId);
                }}
              />
              // < i className="fas fa-copy fa-2x mr-2 ml-2" style={{ cursor: 'pointer', color: '#5D9CEC' }} onClick={() => { copy(value.ShipmentNewBuildId) }} />
              // </div>
            }
            content="Duplicate"
            size="mini"
          />
          <Popup
            inverted
            // basic
            trigger={
              // <i className="mr-2 ml-2" style={{ cursor: 'pointer' }} onClick={() => { ConfirmDeleteShip(value.ShipmentNewBuildId) }}>
              // 	<Delete />
              // </i>
              // <div style={{ fontSize: '0.7rem' }}>
              <i
                className="far fa-trash-alt fa-lg mr-2 ml-2"
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => {
                  ConfirmDeleteShip(value.ShipmentNewBuildId);
                }}
              />
              // <i className="fas fa-trash-alt fa-2x mr-2 ml-2" style={{ cursor: 'pointer', color: 'red' }} onClick={() => { ConfirmDeleteShip(value.ShipmentNewBuildId) }} />
              // </div>
            }
            content="Delete"
            size="mini"
          />
        </div>
      ),
    },
  ]);

  const [events, setEvents] = useState({
    152: {
      label: "Supplier contacted",
      value: undefined,
      completed: false,
      orden: 1,
    },
    153: {
      label: "Confirmed cargo ready",
      value: undefined,
      completed: false,
      orden: 2,
    },
    159: { label: "In transit", value: undefined, completed: false, orden: 3 },
    162: {
      label: "Confirmed delivery",
      value: undefined,
      completed: false,
      orden: 4,
    },
  });

  function changueLoader(params) {
    setTimeout(() => {
      // setLoader(true)
    }, 3000);
  }

  function EditBuild(flat, form) {
    let flatMS = { ...modelNewBuild.flat };

    if (flat) {
      if (form === "PO") {
        // Model.Order_Summary.CutOffDate.hidden = true
        FormComponent.Order_Summary.find((e) => {
          if (
            e.id === "Supplier" ||
            e.id === "Buyer" ||
            e.id === "Department" ||
            e.id === "ProductDescription" ||
            e.id === "Mesures" ||
            e.id === "Weight" ||
            e.id === "From" ||
            e.id === "To" ||
            e.id === "Value" ||
            e.id === "ChargeableWeight" ||
            e.id === "DeliveryConditions"
          ) {
            e.type = "text";
          } else if (e.id === "DateDelivery") {
            e.type = "date";
          } else if (e.id === "Note") {
            e.type = "text-area";
          } else if (e.id === "CodIncoterm" || e.id === "ProjectCutOffId") {
            e.type = "combo";
          } else if (e.id === "TypeDelivery") {
            e.type = "autocomplete";
          }
        });
      } else if (form === "Ship") {
        // Model.Transportation.NewPlannedDeliveryDate.hidden = true
        // Model.Transportation.EstDeliveryDate.hidden = true

        FormComponent.Production.find((e) => {
          if (
            e.id === "PromiseCargoReady" ||
            e.id === "ConfirmedCargoReady" ||
            e.id === "ActualCargoReadyDate" ||
            e.id === "EstPickupReceiveDate" ||
            e.id === "ActualPickupReceiveDate" ||
            e.id === "DateDelivery"
          ) {
            e.type = "date";
          } else if (
            e.id === "CustomerReference1" ||
            e.id === "CustomerReference2" ||
            e.id === "ProductDescription" ||
            e.id === "Mesures" ||
            e.id === "Weight" ||
            e.id === "From"
          ) {
            e.type = "text";
          } else if (e.id === "Note") {
            e.type = "text-area";
          } else if (
            e.id === "SupplierContacted" ||
            e.id === "OutTimePlanProcurement"
          ) {
            e.type = "check";
          } else if (e.id === "CodIncoterm" || e.id === "Operator") {
            e.type = "combo";
          } else if (e.id === "TypeDelivery") {
            e.type = "autocomplete";
          }
        });

        FormComponent.Warehousing.find((e) => {
          if (
            e.id === "ActualArrivalConsolWH" ||
            e.id === "EstArrivalConsolWH"
          ) {
            e.type = "date";
          } else if (e.id === "WMSReference") {
            e.type = "text";
          }
        });

        FormComponent.Transportation.find((e) => {
          console.log("latMS.MasterShipment--->", flatMS.MasterShipment);
          if (!flatMS.MasterShipment) {
            if (
              e.id === "ETD" ||
              e.id === "ETA" ||
              e.id === "ActualDeliveryDate"
            ) {
              e.type = "date";
            } else if (
              e.id === "BookingNo" ||
              e.id === "Vessel" ||
              e.id === "VoyageFlightNo" ||
              e.id === "Carrier" ||
              e.id === "POL" ||
              e.id === "POD" ||
              e.id === "BL_AWB" ||
              e.id === "ContainerNo" ||
              e.id === "IntergloboFile" ||
              e.id === "RealWeigth" ||
              e.id === "ChargeableWeigth" ||
              e.id === "Chargeable" ||
              e.id === "Logistivcs"
            ) {
              e.type = "text";
            } else if (e.id === "TransportModeId") {
              e.type = "combo";
            } else if (e.id === "OutTimePlanShipyard") {
              e.type = "check";
            }
          } else {
            if (e.id === "ETD") {
              e.type = "date";
            } else if (
              e.id === "VoyageFlightNo" ||
              e.id === "Carrier" ||
              e.id === "POL" ||
              e.id === "IntergloboFile" ||
              e.id === "ChargeableWeigth" ||
              e.id === "Chargeable" ||
              e.id === "Logistivcs"
            ) {
              e.type = "text";
            } else if (e.id === "TransportModeId") {
              e.type = "combo";
            } else if (e.id === "OutTimePlanShipyard") {
              e.type = "check";
            }
          }
        });
      }
    } else {
      if (form === "PO") {
        // Model.Order_Summary.CutOffDate.hidden = false
        FormComponent.Order_Summary.find((e) => {
          if (e.id) {
            e.type = "info";
          }
        });
      } else if (form === "Ship") {
        // Model.Transportation.NewPlannedDeliveryDate.hidden = false
        // Model.Transportation.EstDeliveryDate.hidden = false

        FormComponent.Production.find((e) => {
          e.type = "info";
        });
        FormComponent.Warehousing.find((e) => {
          e.type = "info";
        });
        FormComponent.Transportation.find((e) => {
          e.type = "info";
        });
      }
    }

    if (flat && form === "Ship") {
      dataShipLine.map((e) => {
        e.Selected =
          e.ShipmentNewBuildId.toString() === shipLineIdSelected.toString()
            ? true
            : false;
      });
      setDataShipLine(dataShipLine);
    } else if (!flat && form === "Ship") {
      dataShipLine.map((e) => {
        e.Selected = undefined;
      });
      setDataShipLine(dataShipLine);
    }
  }

  function ConfirmDeleteShip(params) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      preConfirm: (result) => {
        if (result) {
          deleteShipLine(params);
        }
      },
    });
  }

  const deleteShipLine = useCallback(async (NoLine) => {
    let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/delete_ship_line/${NoLine}`;

    try {
      let response = await Axios.put(url);
      if (response.data.status === 200) {
        Swal.fire({
          // 'Deleted!',
          // 'Your file has been deleted.',
          // 'success'
          type: "success",
          title: "Deleted",
          text: "Shipment line has been deleted.",
          showConfirmButton: false,
          timer: 1500,
        });
        getPOId();
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ deleteShipLine ~ error", error);
    }
  }, []);

  const copy = useCallback(
    async (NoLine) => {
      try {
        let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/copy_ship_line/${NoLine}`;
        let response = await Axios.put(url);
        if (response.data.status === 200) {
          getPOId();
          Swal.fire({
            // position: 'top-end',
            type: "success",
            title: "Shipment line copy Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.log("🚀 ~ file: index.js ~ copy ~ error", error);
      }
    },
    [id]
  );

  const updatePO = useCallback(async () => {
    let idPO = id;
    try {
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/updatePO/${idPO}`;
      let response = await Axios.put(url, modelNewBuild.Order_Summary);

      if (response.data.status !== 100) {
        if (response.data.status === 200) {
          EditBuild(false, "PO");
          setEditPO(false);
          Swal.fire({
            // position: 'top-end',
            type: "success",
            title: "PO Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          }),
            getPOId();
          // if (idShipLine) {
          // 	shipLineId(idShipLine)
          // }
        }
      } else {
        Swal.fire({
          title: "Oops...",
          text: response.data.message,
          type: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ updatePO ~ error", error);
      let requeridos = ["ProjectCutOffId", "CodIncoterm", "PONo"];
      let flag = ValidatorSchema(modelNewBuild.Order_Summary, requeridos);
      if (!flag) {
        ValidatorSchema2(modelNewBuild.Order_Summary, requeridos);
        setModelNewBuild({ modelNewBuild, ...modelNewBuild });
      }
    }
  }, [id, shipLineIdSelected]);

  const updateShipLine = useCallback(async () => {
    let idPO = id;
    let idShipLine = shipLineIdSelected;

    try {
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/updateShip/${idShipLine}?idPO=${idPO}`;
      let response = await Axios.put(url, modelNewBuild);

      if (response.data.status === 200) {
        EditBuild(false, "Ship");
        setEditShip(false);

        Swal.fire({
          // position: 'top-end',
          type: "success",
          title: "Shipment line updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        getPOId();
        // shipLineId(idShipLine)
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ updateShipLine ~ error", error);
    }
  }, [id, shipLineIdSelected]);

  const shipLineId = useCallback(
    async (NoLine) => {
      setShipLineIdSelected(NoLine);
      try {
        let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/Shimpent_line_id/${NoLine}`;
        let response = await Axios.get(url);
        let dataShipSelected = response.data.data.dataShip[0];
        console.log(
          "🚀 ~ file: detailPO.js:535 ~ shipLineId ~ dataShipSelected:",
          dataShipSelected
        );
        let dataEvt = {
          Groups: response.data.data.groups,
          Steps: response.data.data.dataEvents,
        };

        modelNewBuild.flat.MasterShipment = dataShipSelected.MasterShipment
          ? true
          : false;

        modelNewBuild.Production["PromiseCargoReady"].value =
          dataShipSelected.PromiseCargoReady;
        modelNewBuild.Production[
          "SupplierContacted"
        ].value = dataShipSelected.SupplierContacted
          ? dataShipSelected.SupplierContacted === "1"
            ? "Yes"
            : "No"
          : "";
        modelNewBuild.Production[
          "SupplierContacted"
        ].check = dataShipSelected.SupplierContacted
          ? dataShipSelected.SupplierContacted === "1"
            ? true
            : false
          : "";
        modelNewBuild.Production["ConfirmedCargoReady"].value =
          dataShipSelected.ConfirmedCargoReady;
        modelNewBuild.Production["ActualCargoReadyDate"].value =
          dataShipSelected.ActualCargoReadyDate;
        // modelNewBuild.Production['ConfirmedReadinessDate'].value = dataShipSelected.ConfirmedReadinessDate
        modelNewBuild.Production["EstPickupReceiveDate"].value =
          dataShipSelected.EstPickupReceiveDate;
        modelNewBuild.Production["ActualPickupReceiveDate"].value =
          dataShipSelected.ActualPickupReceiveDate;
        modelNewBuild.Production["CustomerReference1"].value =
          dataShipSelected.CustomerReference1;
        modelNewBuild.Production["CustomerReference2"].value =
          dataShipSelected.CustomerReference2;
        modelNewBuild.Production["ProductDescription"].value =
          dataShipSelected.ProductDescription;
        modelNewBuild.Production["Mesures"].value = dataShipSelected.Mesures;
        modelNewBuild.Production["Weight"].value = dataShipSelected.Weight;
        modelNewBuild.Production["From"].value = dataShipSelected.From;
        modelNewBuild.Production["TypeDelivery"].value =
          dataShipSelected.TypeDelivery;
        modelNewBuild.Production["CodIncoterm"].value =
          dataShipSelected.CodIncoterm;
        modelNewBuild.Production["CodIncoterm"].id =
          dataShipSelected.CodIncoterm;
        // modelNewBuild.Production['CodIncoterm'].valid = dataShipSelected.CodIncoterm ? true : false
        modelNewBuild.Production["Operator"].value =
          dataShipSelected.OperatorNB;
        modelNewBuild.Production["Operator"].id = dataShipSelected.OperatorId;

        modelNewBuild.Production[
          "OutTimePlanProcurement"
        ].value = dataShipSelected.OutTimePlanProcurement
          ? dataShipSelected.OutTimePlanProcurement === "1"
            ? "Yes"
            : "No"
          : "";
        modelNewBuild.Production[
          "OutTimePlanProcurement"
        ].check = dataShipSelected.OutTimePlanProcurement
          ? dataShipSelected.OutTimePlanProcurement === "1"
            ? true
            : false
          : "";

        modelNewBuild.Production["DateDelivery"].value =
          dataShipSelected.DateDelivery;
        modelNewBuild.Production["Note"].value = dataShipSelected.Note;

        modelNewBuild.Warehousing["WMSReference"].value =
          dataShipSelected.WMSReference;
        modelNewBuild.Warehousing["EstArrivalConsolWH"].value =
          dataShipSelected.EstArrivalConsolWH;
        modelNewBuild.Warehousing["ActualArrivalConsolWH"].value =
          dataShipSelected.ActualArrivalConsolWH;

        modelNewBuild.Transportation["TransportModeId"].value =
          dataShipSelected.TransportMode;
        modelNewBuild.Transportation["TransportModeId"].id =
          dataShipSelected.TransportModeId;
        modelNewBuild.Transportation["BookingNo"].value =
          dataShipSelected.BookingNo;
        modelNewBuild.Transportation["Vessel"].value = dataShipSelected.Vessel;
        modelNewBuild.Transportation["VoyageFlightNo"].value =
          dataShipSelected.VoyageFlightNo;
        modelNewBuild.Transportation["Carrier"].value =
          dataShipSelected.Carrier;
        modelNewBuild.Transportation["POL"].value = dataShipSelected.POL;
        modelNewBuild.Transportation["POD"].value = dataShipSelected.POD;
        modelNewBuild.Transportation["BL_AWB"].value = dataShipSelected.BL_AWB;
        modelNewBuild.Transportation["ContainerNo"].value =
          dataShipSelected.ContainerNo;
        modelNewBuild.Transportation["IntergloboFile"].value =
          dataShipSelected.IntergloboFile;
        modelNewBuild.Transportation[
          "RealWeigth"
        ].value = new Intl.NumberFormat("en-US").format(
          dataShipSelected.RealWeigth
        );
        modelNewBuild.Transportation[
          "ChargeableWeigth"
        ].value = new Intl.NumberFormat("en-US").format(
          dataShipSelected.ChargeableWeigth
        );
        modelNewBuild.Transportation["ETD"].value = dataShipSelected.ETD;
        modelNewBuild.Transportation["NewPlannedDeliveryDate"].value =
          dataShipSelected.NewPlannedDeliveryDate;
        modelNewBuild.Transportation["ETA"].value = dataShipSelected.ETA;
        modelNewBuild.Transportation["ActualDeliveryDate"].value =
          dataShipSelected.ActualDeliveryDate;
        modelNewBuild.Transportation["EstDeliveryDate"].value =
          dataShipSelected.EstDeliveryDate;

        modelNewBuild.Transportation[
          "OutTimePlanShipyard"
        ].value = dataShipSelected.OutTimePlanShipyard
          ? dataShipSelected.OutTimePlanShipyard === "1"
            ? "Yes"
            : "No"
          : "";
        modelNewBuild.Transportation[
          "OutTimePlanShipyard"
        ].check = dataShipSelected.OutTimePlanShipyard
          ? dataShipSelected.OutTimePlanShipyard === "1"
            ? true
            : false
          : "";

        setModelNewBuild({ modelNewBuild, ...modelNewBuild });
        setShipmentLine({
          name: (
            <span className="shipment-line">
              Shipment line {dataShipSelected.NoLine}
            </span>
          ),
          status: dataShipSelected.Status,
        });
        buildEvents(dataEvt);
        getComments(NoLine);
        getDocs(NoLine);
        setKey(Math.random());
      } catch (error) {
        console.log("🚀 ~ file: index.js ~  shipLineId ~ error", error);
      }
    },
    [id]
  );

  function buildEvents(params) {
    Object.entries(events).map(([key, data]) => {
      data.completed = false;
      data.value = undefined;
    });

    setGroupsEvents(params.Groups);
    params.Steps.forEach((element) => {
      if (events[element.EventDescriptionId]) {
        events[element.EventDescriptionId].value = element.EventDate;
        events[element.EventDescriptionId].completed = true;
      }
    });
  }

  function handleChange(val, data, item, TabName) {
    if (TabName === "Production") {
      let ModelSend = { ...modelNewBuild.Production };
      let id = item.id;
      let dataLabel = data ? data : "";

      if (item.type === "text") {
        let value = val;
        let res = ValidatorService(ModelSend[id].type, value);
        if (res) {
          ModelSend[id].value = value;
        }
      }
      if (item.type === "date") {
        let value = dataLabel;
        ModelSend[id].value = value;
      }
      if (item.type === "check") {
        let value = data.checked;
        ModelSend[id].check = value;
      }
      if (item.type === "combo") {
        let value = data.value;
        let res = ValidatorService(ModelSend[id].type, value);

        if (!res) {
          ModelSend[id].valid = res;
        } else {
          ModelSend[id].valid = res;
        }
        // let flag = ValidatorSchema(ModelSend, ['ProjectCutOffId'])
        ModelSend[id].id = value;
      }
      if (item.type === "autocomplete") {
        let value = val;
        ModelSend[id].value = value;
      }
      if (item.type === "text-area") {
        let value = data.value;
        let res = ValidatorService(ModelSend[id].type, value);
        if (res) {
          ModelSend[id].value = value;
        }
      }
    }

    if (TabName === "Warehousing") {
      let ModelSend = { ...modelNewBuild.Warehousing };

      let id = item.id;
      let dataLabel = data ? data : "";

      if (item.type === "text") {
        let value = val;
        ModelSend[id].value = value;
      }
      if (item.type === "date") {
        let value = dataLabel;
        ModelSend[id].value = value;
      }
    }

    if (TabName === "Transportation") {
      let ModelSend = { ...modelNewBuild.Transportation };

      let id = item.id;
      let dataLabel = data ? data : "";
      if (item.type === "text") {
        let value = val;
        let res = ValidatorService(ModelSend[id].type, value);
        if (res) {
          ModelSend[id].value = value;
        }
        // ModelSend[id].value = value
      }
      if (item.type === "date") {
        let value = dataLabel;
        ModelSend[id].value = value;
      }
      if (item.type === "combo") {
        let value = data.value;
        ModelSend[id].id = value;
      }
      if (item.type === "check") {
        let value = data.checked;
        ModelSend[id].check = value;
      }
    }

    if (TabName === "Comments") {
      let ModelSend = { ...modelNewBuild.Comments };

      let id = item.id;
      if (item.type === "combo") {
        let value = data.value;
        ModelSend[id].id = value;
        ModelSend[id].value = value;
      }
      if (item.type === "text-area") {
        let value = data.value;
        ModelSend[id].value = value;
      }
      let flag = ValidatorSchema(ModelSend, ["ComTypeId", "Comment"]);
      if (flag) {
        ModelSend["hiddenButtonSave"].hidden = false;
      } else {
        ModelSend["hiddenButtonSave"].hidden = true;
      }
    }

    if (TabName === "Documments") {
      let ModelSend = { ...modelNewBuild.Documments };
      let id = item.id;
      if (item.type === "combo") {
        let value = data.value;
        ModelSend[id].id = value;
      }
      if (item.type === "text") {
        let value = val;
        ModelSend[id].value = value;
      }
    }

    if (TabName === "Summary") {
      let ModelSend = { ...modelNewBuild.Order_Summary };

      let id = item.id;
      let dataLabel = data ? data : "";

      if (item.type === "text") {
        let value = val;
        let res = ValidatorService(ModelSend[id].type, value);
        if (res) {
          ModelSend[id].value = value;
        }
      }
      if (item.type === "text_without_spaces") {
        let value = val;
        if (value.trim() !== "") {
          ModelSend[id].value = value.trim().replace(/\s+/g, "");
          ModelSend[id].edit = true;
        } else {
          ModelSend[id].value = undefined;
        }
        ValidatorSchema2(ModelSend, [id]);
      }
      if (item.type === "date") {
        let value = dataLabel;
        ModelSend[id].value = value;
      }
      if (item.type === "text-area") {
        let value = data.value;
        let res = ValidatorService(ModelSend[id].type, value);
        if (res) {
          ModelSend[id].value = value;
        }
      }
      if (item.type === "combo") {
        let value = data.value;
        let res = ValidatorService(ModelSend[id].type, value);
        if (!res) {
          ModelSend[id].valid = res;
        } else {
          ModelSend[id].valid = res;
        }
        // let flag = ValidatorSchema(ModelSend, ['ProjectCutOffId'])
        ModelSend[id].id = value;
        ModelSend[id].value = value;
      }
      if (item.type === "autocomplete") {
        let value = val;
        ModelSend[id].value = value;
      }
    }

    setModelNewBuild({ modelNewBuild, ...modelNewBuild });
  }

  // function handleChange(val, data, item, TabName) {
  // 	if (TabName === 'Production') {
  // 		let ModelSend = { ...modelNewBuild.Production };
  // 		let id = item.id
  // 		let dataLabel = data ? data : '';

  // 		if (item.type === 'text') {
  // 			let value = val;
  // 			ModelSend[id].value = value

  // 			if (groupShipments[shipLineIdSelected]) {
  // 				groupShipments[shipLineIdSelected][id] = value
  // 			} else {
  // 				groupShipments[shipLineIdSelected] = {}
  // 				groupShipments[shipLineIdSelected][id] = value
  // 			}
  // 		}
  // 		if (item.type === 'date') {
  // 			let value = dataLabel;
  // 			ModelSend[id].value = value

  // 			if (groupShipments[shipLineIdSelected]) {
  // 				groupShipments[shipLineIdSelected][id] = value
  // 			} else {
  // 				groupShipments[shipLineIdSelected] = {}
  // 				groupShipments[shipLineIdSelected][id] = value
  // 			}
  // 		}
  // 		if (item.type === 'check') {
  // 			let value = data.checked;
  // 			ModelSend[id].check = value

  // 			if (groupShipments[shipLineIdSelected]) {
  // 				groupShipments[shipLineIdSelected][id] = value
  // 			} else {
  // 				groupShipments[shipLineIdSelected] = {}
  // 				groupShipments[shipLineIdSelected][id] = value
  // 			}
  // 		}
  // 	}
  // 	setModelNewBuild({ modelNewBuild, ...modelNewBuild })
  // }

  async function addShipLine(params) {
    let idPO = id;
    let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/new_ship_line/${idPO}`;
    let data = await Axios.post(url);
    if (data.status === 200) {
      Swal.fire({
        // position: 'top-end',
        type: "success",
        title: "New shipment added",
        showConfirmButton: false,
        timer: 1500,
      });

      getPOId();
    }
  }

  const addComment = useCallback(async () => {
    let ModelComments = { ...modelNewBuild.Comments };
    let IdShipLine = shipLineIdSelected;
    try {
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/addCommentShipLine/${IdShipLine}?usr=${userId}`;
      let data = await Axios.post(url, ModelComments);
      if (data.status === 200) {
        setModalCommet(false);

        Swal.fire({
          // position: 'top-end',
          type: "success",
          title: "New Comment added",
          showConfirmButton: false,
          timer: 1500,
        });
        getComments(IdShipLine);
      }
      ModelComments["Comment"].value = undefined;
      ModelComments["ComTypeId"].id = undefined;
      ModelComments["ComTypeId"].value = undefined;
      ModelSend["hiddenButtonSave"].hidden = true;
      // setKey(Math.random())
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ addComment ~ error", error);
    }
  }, [shipLineIdSelected, userId]);

  const getComments = useCallback(async (idline) => {
    let IdShipLine = idline;
    try {
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/getComments/${IdShipLine}`;
      let data = await Axios.get(url);
      setComments(data.data.data.dataComments);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ getComments ~ error", error);
    }
  }, []);

  function toggleModalComments() {
    let ModelComments = { ...modelNewBuild.Comments };

    setModalCommet(!modalComment);
    ModelComments["Comment"].value = undefined;
    ModelComments["ComTypeId"].id = undefined;
    ModelComments["ComTypeId"].value = undefined;
    ModelComments["hiddenButtonSave"].hidden = true;
  }

  const callFile = (file) => {
    let ModelSend = { ...modelNewBuild.Documments };
    ModelSend["File"].data = file[0].file;
    setModelNewBuild({ modelNewBuild, ...modelNewBuild });
  };

  const addNewDocumment = useCallback(async () => {
    let modelDocumments = { ...modelNewBuild.Documments };
    let idShipLine = shipLineIdSelected;

    // evt.preventDefault();
    // let { Document, id } = this.state
    const formData = new FormData();
    if (
      modelDocumments.File.data &&
      modelDocumments.Name.value &&
      modelDocumments.CategoryId.id
    ) {
      formData.append("file", modelDocumments.File.data);
      formData.append("Name", modelDocumments.Name.value);
      formData.append("DocCategoryId", parseInt(modelDocumments.CategoryId.id));
      formData.append("Type", path.extname(modelDocumments.File.data.name));
      formData.append("RowId", idShipLine);
      formData.append("ModuleId", 9);
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}documents/upload_doc/`;

      // this.setState({ disable: true })
      fetch(`${url}`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((success) => {
          Swal.fire({
            // position: 'top-end',
            type: "success",
            title: "New document added",
            showConfirmButton: false,
            timer: 1500,
          });
          getDocs(idShipLine);
          // setModelNewBuild({ ...Model_New_Build().Documments })
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: index.js ~ line 600 ~ addNewDocumment ~ error",
            error
          );
          // this.props.onError(error)
        });
    }
  }, [shipLineIdSelected]);

  const getDocs = useCallback(async (idline) => {
    let IdShipLine = idline;
    let module = 9;

    try {
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}documents/get_docs/${IdShipLine}?module=${module}`;
      let data = await Axios.get(url);
      setDocuments(data.data.data.dataDocs);
      setKey(Math.random());
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ getDocs ~ error", error.message);
    }
  }, []);

  const rmDocument = useCallback(
    async (params) => {
      try {
        let url = `${Services.GET_CONSOLPLAN_SERVICE.path}documents/delete_doc/${params.DocumentId}`;
        let data = await Axios.delete(url);
        if (data.data.status === 200) {
          Swal.fire({
            // position: 'top-end',
            type: "success",
            title: "deleted document",
            showConfirmButton: false,
            timer: 1500,
          });
          getDocs(shipLineIdSelected);
        }
      } catch (error) {
        console.log("🚀 ~ file: index.js ~ rmDocument ~ error", error);
      }
    },
    [shipLineIdSelected]
  );

  const getPOId = useCallback(async () => {
    let idPO = id;
    let idValidate = validateIdParams(id);
    if (!idValidate) {
      message.error("Data entered is invalid", 2);
      setTimeout(() => {
        this.props.history.push("/dashboard");
      }, 2000);
      return;
    }
    try {
      let url = `${Services.GET_CONSOLPLAN_SERVICE.path}new-build/POid/${idPO}`;
      let data = await Axios.get(url);
      // console.log("🚀 ~ file: detailPO.js:956 ~ getPOId ~ data", data)

      let dataPO = data.data.data.dataPO[0];
      let dataShip = data.data.data.dataShip;
      modelNewBuild.Order_Summary["PONo"].value = dataPO.PONo;
      modelNewBuild.Order_Summary["Supplier"].value = dataPO.Supplier;
      modelNewBuild.Order_Summary["Buyer"].value = dataPO.Buyer;
      modelNewBuild.Order_Summary["Department"].value = dataPO.Department;
      modelNewBuild.Order_Summary["ProjectCutOffId"].value = dataPO.NewBuild;
      modelNewBuild.Order_Summary["ProjectCutOffId"].id =
        dataPO.ProjectCutOffId;
      modelNewBuild.Order_Summary[
        "ProjectCutOffId"
      ].valid = dataPO.ProjectCutOffId ? true : false;
      modelNewBuild.Order_Summary["CutOffDate"].value = dataPO.CutOffDate;
      modelNewBuild.Order_Summary["CodIncoterm"].value = dataPO.CodIncoterm;
      modelNewBuild.Order_Summary["CodIncoterm"].id = dataPO.CodIncoterm;
      // modelNewBuild.Order_Summary['CodIncoterm'].valid = dataPO.CodIncoterm ? true : false
      modelNewBuild.Order_Summary["ProductDescription"].value =
        dataPO.ProductDescription;
      modelNewBuild.Order_Summary["Mesures"].value = dataPO.Mesures;
      // modelNewBuild.Order_Summary['Weight'].value = new Intl.NumberFormat('en-US').format(dataPO.Weight)
      modelNewBuild.Order_Summary["Weight"].value = dataPO.Weight;
      modelNewBuild.Order_Summary["From"].value = dataPO.From;
      modelNewBuild.Order_Summary["To"].value = dataPO.To;
      modelNewBuild.Order_Summary["TypeDelivery"].value = dataPO.TypeDelivery;

      modelNewBuild.Order_Summary["Value"].value = dataPO.Value
        ? dataPO.Value
        : 0;
      // modelNewBuild.Order_Summary['Value'].value = new Intl.NumberFormat('en-US').format(dataPO.Value)
      modelNewBuild.Order_Summary[
        "ChargeableWeight"
      ].value = dataPO.ChargeableWeight ? dataPO.ChargeableWeight : 0;
      // modelNewBuild.Order_Summary['ChargeableWeight'].value = new Intl.NumberFormat('en-US').format(dataPO.ChargeableWeight)
      modelNewBuild.Order_Summary["DeliveryConditions"].value =
        dataPO.DeliveryConditions;
      modelNewBuild.Order_Summary["DateDelivery"].value = dataPO.DateDelivery;
      modelNewBuild.Order_Summary["Note"].value = dataPO.Note;
      headerInfo.PONo = dataPO.PONo;
      headerInfo.Status = dataPO.Status;
      headerInfo.CreationDate = dataPO.CreationDate;
      headerInfo.LastUpdate = dataPO.LastUpdate;

      Catalogs.TransportModeId = data.data.data.Cat_TransportMode;
      Catalogs.ComTypeId = data.data.data.Cat_ComType;
      Catalogs.CodIncoterm = data.data.data.Cat_Incoterms;
      Catalogs.ProjectCutOffId = data.data.data.Cat_Projects;
      Catalogs.CategoryId = data.data.data.Cat_DocType;
      Catalogs.Operator = data.data.data.Cat_Operators;
      Catalogs.TypeDelivery = data.data.data.Cat_TypeDelivery;

      setCatalogs({ Catalogs, ...Catalogs });

      dataShip.map((e) => {
        e.Selected = undefined;
      });
      setDataShipLine(dataShip);
      if (line !== "0") {
        shipLineId(shipLineIdSelected ? shipLineIdSelected : line);
      } else {
        if (dataShip.length !== 0) {
          shipLineId(dataShip[0].ShipmentNewBuildId);
        }
      }
      setModelNewBuild({ modelNewBuild, ...modelNewBuild });
      setTimeout(() => {
        setFullLoader(true);
      }, 2000);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ getPOId ~ error", error);
    }
  }, [id, line, shipLineIdSelected]);

  // const createData = amount => {
  // 	let data = [];
  // 	for (let i = 0; i < amount; i++) {
  // 		data.push({
  // 			firstName: 'name',
  // 			middleName: 'apellido',
  // 			lastName: 'hola'
  // 		});
  // 	}
  // 	return data;
  // };

  useEffect(() => {
    // setEdit(false)
    setUserId(props._user);
    setRolId(props._rolId);
    EditBuild(false, "PO");
    EditBuild(false, "Ship");
    setKey(Math.random());
    getPOId();
  }, []);

  return (
    <>
      <Loader hidden={fullLoader} />
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between ">
          <div>
            <i className="fas fa-map-marker-altfas fa-ship fa-1x mr-2"></i>
            Visibility Details
          </div>
          <div
            onClick={props.back}
            className="backButton d-flex align-items-center"
          >
            <i className="fas fa-chevron-left" />
            <span>&nbsp;Back</span>
          </div>
        </div>
        {/* <div className="content-heading">
					<i className='fas fa-map-marker-altfas fa-ship fa-1x mr-2'></i>
					Visibility Details
				</div>
				<Col className='d-flex align-items-center'
					onClick={props.back}
					style={{ cursor: 'pointer', padding: 0 }} >
					<i className='fas fa-arrow-circle-left fa-3x mr-2' style={{ color: '#00558c' }} />
					<spam style={{ color: '#00558c' }}><h4><b>Back</b></h4></spam>
				</Col> */}
        <Row>
          <Col xs={12} md={12}>
            <CardBody style={{ textAlign: "right" }}>
              <div hidden={rolId !== "9" ? false : true}>
                <Button
                  className="btn-width"
                  color="success"
                  size="sm"
                  hidden={editPO || editShip}
                  onClick={() => {
                    EditBuild(!editPO, "PO"), setEditPO(!editPO);
                  }}
                >
                  Edit
                </Button>
              </div>
              <Button
                className="btn-width"
                color="danger"
                size="sm"
                hidden={!editPO}
                onClick={() => {
                  EditBuild(!editPO, "PO"), setEditPO(!editPO), getPOId();
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-width mr-2 ml-2"
                color="primary"
                size="sm"
                hidden={!editPO}
                onClick={() => {
                  updatePO();
                }}
              >
                Save
              </Button>
            </CardBody>
            <Card className="myCard">
              {/* <Card className="myCard card-border-new-build"> */}
              <CardBody>
                <Row>
                  {!editPO ? (
                    <Col
                      xs={6}
                      md={3}
                      lg={3}
                      className="d-flex align-items-center"
                    >
                      <span className="headerCard">
                        Order# {headerInfo.PONo}{" "}
                      </span>
                    </Col>
                  ) : (
                    <Col xs={6} md={3} lg={3}>
                      <Input
                        // icon='tags'
                        // iconPosition='left'
                        label={{ tag: true, content: "Order #" }}
                        labelPosition="left"
                        // size='small'
                        onChange={(evt, data) => {
                          handleChange(
                            evt.target.value,
                            data,
                            { id: "PONo", type: "text_without_spaces" },
                            "Summary"
                          );
                        }}
                        value={
                          modelNewBuild.Order_Summary["PONo"].value
                            ? modelNewBuild.Order_Summary["PONo"].value
                            : ""
                        }
                        error={
                          modelNewBuild.Order_Summary["PONo"].valid
                            ? false
                            : true
                        }
                      />
                      {/* <Input
													// icon='tags'
													// iconPosition='left'
													label='Order#'
													// labelPosition='right'
													size='small'
													onChange={(evt, data) => { handleChange(evt.target.value, data, { id: 'PONo', type: 'text_without_spaces' }, 'Summary'); }}
													value={modelNewBuild.Order_Summary['PONo'].value ? modelNewBuild.Order_Summary['PONo'].value : ''}
												/> */}
                      {/* <Row>
													<Col>
														<label className="input__label">Order#</label>
														<Input
															// id={item.id}
															// required={handleRequired(Model, item.id)}
															// name={item.name}
															// type={item.type}
															size='small'
															onChange={(evt, data) => { handleChange(evt.target.value, data, { id: 'PONo', type: 'text_without_spaces' }, 'Summary'); }}
															fluid
															// placeholder={placeholders ? placeholders[item.id] : item.placeholder || (Model && Model[item.id] ? Model[item.id].placeholder : '')}
															// disabled={item.disabled}
															// value={headerInfo.PONo ? headerInfo.PONo : ''}
															value={modelNewBuild.Order_Summary['PONo'].value ? modelNewBuild.Order_Summary['PONo'].value : ''}
														// error={Model && Model[item.id].valid ? false : true}
														/>


													</Col>
												</Row> */}
                      {/* <FormCreator dataForm={form} Model={modelNewBuild.Order_Summary} onChange={handleChange} TabName={'Summary'} Catalog={Catalogs} /> */}
                    </Col>
                  )}

                  <Col
                    xs={6}
                    md={2}
                    lg={2}
                    className="d-flex align-items-center"
                  >
                    <Button className={"btn-oval"} color={"primary"}>
                      {headerInfo.Status}
                    </Button>
                  </Col>
                  <Col xs={6} md={7} lg={7} style={{ textAlign: "right" }}>
                    {/* <Row> */}
                    <span className="datesPO">
                      PO Creation: <label>{headerInfo.CreationDate}</label>
                    </span>
                    <span className="datesPO">
                      Last Update: <label>{headerInfo.LastUpdate}</label>
                    </span>
                    {/* </Row> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="myCard">
              {/* <Card className="myCard card-border-new-build"> */}
              <Accordion defaultExpanded="true">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="title-card-po">
                    Order summary
                  </Typography>
                  {/* <h4 >Order summary</h4> */}
                </AccordionSummary>
                <CardBody>
                  <Row>
                    <FormCreator
                      dataForm={form}
                      Model={modelNewBuild.Order_Summary}
                      onChange={handleChange}
                      TabName={"Summary"}
                      Catalog={Catalogs}
                    />
                  </Row>
                </CardBody>
              </Accordion>
            </Card>
          </Col>
        </Row>

        {/* <Table
					// columns={editShip ? columns2 : columns}
					columns={columnsShipLines}
					data={dataShipLine}
					// itemsByPage={10}
					// styles={styles}
					// configSearch={{ fieldsToFilter: ItemsToFilter }}
					options={
						{
							card: true,
							pagination: dataShipLine.length > 10 ? true : false,
							search: false,
							placeholder: 'Search ...',
							selectItems: false,
							xlsx: false,
							Accordion: { Active: true, Title: 'Shipment lines' },
							addShip: { visible: true, addShipLine: addShipLine }
							// addShip: { visible: editShip, addShipLine: addShipLine }
						}
					}
				/> */}
        <Card className={"myCard"}>
          <CardBody>
            <TableFilterComponents
              data={dataShipLine}
              columns={columnsShipLines}
              options={{
                filter: false,
                pagination: true,
                itemsPerPage: 5,
                addLine: { visible: true, addLineFunction: addShipLine },
              }}
            />
          </CardBody>
        </Card>
        {dataShipLine.length !== 0 ? (
          <Row>
            <Col xs={12} md={12}>
              <Card className="myCard">
                {/* <Card className="myCard card-border-new-build"> */}
                <CardBody>
                  <Row>
                    <Col xs={12} md={12}>
                      <div hidden={rolId !== "9" ? false : true}>
                        <CardBody style={{ textAlign: "right" }}>
                          <Button
                            className="btn-width"
                            color="success"
                            size="sm"
                            hidden={editShip || editPO}
                            onClick={() => {
                              EditBuild(!editShip, "Ship");
                              setEditShip(!editShip);
                            }}
                          >
                            Edit
                          </Button>
                        </CardBody>
                      </div>
                    </Col>
                  </Row>
                  <Tab
                    defaultActiveIndex={0}
                    menu={{ secondary: true }}
                    panes={[
                      {
                        menuItem: "Production",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <Production
                              key={key}
                              FormCreator={FormCreator}
                              dataForm={FormComponent.Production}
                              Model={modelNewBuild.Production}
                              onChange={handleChange}
                              TabName={"Production"}
                              shipmentLine={shipmentLine}
                              steps={events}
                              Catalogs={Catalogs}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        menuItem: "Warehousing",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <Warehousing
                              key={key}
                              FormCreator={FormCreator}
                              dataForm={FormComponent.Warehousing}
                              Model={modelNewBuild.Warehousing}
                              onChange={handleChange}
                              TabName={"Warehousing"}
                              shipmentLine={shipmentLine}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        menuItem: "Transportation",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <Transportation
                              key={key}
                              FormCreator={FormCreator}
                              dataForm={FormComponent.Transportation}
                              Model={modelNewBuild.Transportation}
                              onChange={handleChange}
                              TabName={"Transportation"}
                              shipmentLine={shipmentLine}
                              Catalogs={Catalogs}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        menuItem: "Events",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <Events
                              key={key}
                              FormCreator={FormCreator}
                              dataForm={FormComponent.Production}
                              Model={Model_New_Build().Production}
                              Groups={groupsEvents}
                              shipmentLine={shipmentLine}
                            />
                          </Suspense>
                        ),
                      },
                      // {
                      // 	menuItem: 'Cost',
                      // 	render: () => <Suspense fallback={<div>Loading...</div>}><Cost FormCreator={FormCreator} dataForm={FormComponent.Production} Model={Model_New_Build().Production} /></Suspense>
                      // },
                      {
                        menuItem: "Comments",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <Comments
                              key={key}
                              FormCreator={FormCreator}
                              dataForm={FormComponent.Comments}
                              Model={modelNewBuild.Comments}
                              onChange={handleChange}
                              TabName={"Comments"}
                              Catalogs={Catalogs}
                              addComment={addComment}
                              comments={comments}
                              toggleModalComments={toggleModalComments}
                              modalComment={modalComment}
                              shipmentLine={shipmentLine}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        menuItem: "Documents",
                        render: () => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <Documents
                              key={key}
                              FormCreator={FormCreator}
                              dataForm={FormComponent.Documments}
                              Model={modelNewBuild.Documments}
                              onChange={handleChange}
                              TabName={"Documments"}
                              Catalogs={Catalogs}
                              callFile={callFile}
                              addNewDocumment={addNewDocumment}
                              documents={documents}
                              rmDocument={rmDocument}
                              shipmentLine={shipmentLine}
                            />
                          </Suspense>
                        ),
                      },
                    ]}
                  />
                  <div style={{ textAlign: "right" }} hidden={!editShip}>
                    <CardBody>
                      <Row>
                        <Col xs={6} md={10} lg={10}></Col>
                        <Col xs={6} md={1} lg={1}>
                          <Button
                            color="danger"
                            size="sm"
                            block
                            onClick={() => {
                              EditBuild(false, "Ship"),
                                setEditShip(!editShip),
                                getPOId();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col xs={6} md={1} lg={1}>
                          <Button
                            color="success"
                            size="sm"
                            block
                            onClick={() => {
                              updateShipLine();
                            }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <div className="margin-footer-6" />
      </ContentWrapper>
    </>
  );
}

export default withRouter(BuildDetail);
