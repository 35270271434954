import React, { Component } from "react";
import "react-select/dist/react-select.css";
import "react-datetime/css/react-datetime.css";
import { Card, CardBody, Button, Col, Row } from "reactstrap";
import Errors from "../../../../../template/Errors";
import Tables from "../../../../../template/Table";
import { Link } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import "./styles.scss";

const mapColor = {
  12: "danger",
  13: "secondary",
};

class BackupPolist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.view ? this.props : false,
      PONumber: this.props.PONumber ? this.props.PONumber : 0,
      items: this.props.items ? this.props.items : [],
      SelectedIsLoaded: false,
      columns: [
        {
          label: "PO  No",
          key: "PONumber",
          minWidth: 80,
          custom: (value) => (
            <Col md={12} xl={12}>
              <Row>
                <Col md={12}>
                  <Link to={"/po-detail-view/" + value.POId}>
                    {value.PONumber}
                  </Link>
                </Col>
              </Row>
            </Col>
          ),
        },
        {
          label: "Status",
          key: "StatusDesc",
          minWidth: 80,
          custom: (value) => (
            <Button
              className={
                value.StatusId === 13 ? "btn-oval _gray_button" : "btn-oval"
              }
              color={
                mapColor[value.StatusId] ? mapColor[value.StatusId] : "primary"
              }
              onClick={() => {}}
            >
              {value.StatusDesc}
            </Button>
          ),
        },
        { label: "Shipper", key: "SupplierName", edit: true, type: "Input" },
        { label: "Ship To", key: "DeliveryName" },
        { label: "Ship to Ref.", key: "DistributorPO" },
        { label: "Country", key: "OriginCountry" },
        { label: "POA", key: "POPOD" },
        {
          label: "Ready Date",
          key: "ActualCargoReadyDate",
          edit: true,
          type: "datatime",
        },
        { label: "Pickup Mode", key: "PickupMode", edit: true, type: "Input" },
        {
          label: "Pickup Date",
          key: "ActualCargoPickup",
          edit: true,
          type: "datatime",
        },
        {
          label: "Arrival at WH",
          key: "ActualArrivalConsolWH",
          edit: true,
          type: "datatime",
        },
        { label: "Pallets", key: "NoPallets", edit: true, type: "Input" },
        { label: "QTY", key: "TotalQtyOrdered", edit: true, type: "Input" },
        { label: "Weight", key: "GrossWeight", edit: true, type: "Input" },
        // { label: 'Actions', key: '', custom: (value) => this.custom1(value) }
      ],
      POLineComponent: "",
      error: {
        open: false,
        message: "",
        type: "error",
      },
      errors: {},
      styleLabelItems: {},
    };
  }

  /*=============================================
    =            BUtton delete Item               =
    =============================================*/

  // custom1 = (value) => (
  //   <Fab color="secondary" size="small" aria-label="remove" onClick={this.deleteLine(value)}>
  //     <RemoveIcon className="iconRemove" />
  //   </Fab>
  // );

  /*=============================================
  =            Set Items For Table              =
  =============================================*/

  setItems = (item) => {
    this.setState(
      {
        items: [...this.state.items, item],
      },
      () => {
        this.props.sendItems(this.state.items);
      }
    );
  };

  removeLine = (index, items) => {
    items.splice(index, 1);
    this.setState(
      {
        error: {
          message: "A line item has been removed correctly",
          open: true,
          type: "warning",
        },
      },
      () => {
        this.props.sendItems(this.state.items);
      }
    );
  };
  componentDidMount = () => {};

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      items: nextProps.items,
      view: nextProps.view,
    });
  };

  parseItems = (items) => {
    return items.map((e) => {
      return {
        ItemDescription: e.ItemDescription,
        ItemNumber: e.ItemNumber,
        OriginCountryId: e.CountryOrigin,
        QtyOrdered: e.QtyOrdered,
        UnitMeasure: e.UnitsMeasure,
      };
    });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });
  render() {
    let { items, view } = this.state;
    return (
      <Card className="myCard">
        <CardBody>
          <ExpansionPanel elevation={0} defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <legend id="items" style={this.state.styleLabelItems}>
                <em className="fas fa-dolly-flatbed fa-1x mr-2"></em>Backup PO
                List
              </legend>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="row col-md-12" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-12 col-sm-12 margin_bottom">
                  <div
                    className="col-md-12 row"
                    style={{ marginBottom: "-42px", textAlign: "end" }}
                  >
                    <div className="col-md-10"></div>
                    <div style={{ textAlign: "center" }} className="col-md-2">
                      {view ? (
                        <></>
                      ) : (
                        <>
                          <Fab
                            color="primary"
                            size="small"
                            aria-label="add"
                            onClick={() => this.props.activeModal(true)}
                          >
                            <AddIcon />
                          </Fab>
                          <label
                            style={{ fontSize: "13px", marginLeft: "1em" }}
                          >
                            Add/Remove POs
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <hr></hr>
                </div>
                <div
                  style={{ overflowY: "auto", maxHeight: "30em" }}
                  className="col-md-12"
                >
                  <Tables
                    data={items}
                    columns={this.state.columns}
                    options={{
                      pagination: false,
                      search: false,
                      downloads: { xlsx: false, pdf: false },
                    }}
                  ></Tables>
                </div>
                <Errors
                  open={this.state.error.open}
                  type={this.state.error.type}
                  message={this.state.error.message}
                  setProps={this.recieveProps}
                ></Errors>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </CardBody>
      </Card>
    );
  }
}
export default BackupPolist;
