import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Input, Row, Col } from 'reactstrap';
// import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import Datetime from 'react-datetime';
// import restService from '../../../../../../services/restService';
import './style.scss';
import Fade from 'react-reveal/Fade';
import { Card, CardBody } from 'reactstrap';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from 'axios';
import { Services } from '../../../Services';


class generalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      selectedOption: '',
      collapse: true,
      order: this.props.order ? this.props.order : {},
      isEdit: this.props.isEdit ? this.props.isEdit : false,
      errors: {},
      selects: this.props.selectsgeneral ? this.props.selectsgeneral : { EQUIPMENT: [] },
      CreateOrderFormData: [],
      Form: {},
      icon: 'fas fa-angle-down',
      selected: [],
      orderSelected: [],
      firtsOrderLoader: false,
      view: this.props.view ? this.props : false,
      CustomerName: props.CustomerName ? props.CustomerName : '',
      idCustomer: props.idCustomer ? props.idCustomer : '',
      userId: props.userId ? props.userId : ''
    };
  }

  componentDidMount = () => {
    this.setState({
      Form: this.props.Form
    }, () => {
      this.setFirstOrder();
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      Form: nextProps.Form,
      order: nextProps.order,
      view: nextProps.view,
      selects: nextProps.selectsgeneral,
      isEdit: nextProps.isEdit ? nextProps.isEdit : false,
    }, () => {
      this.setFirstOrder();
    });
  }

  setFirstOrder = () => {
    let { order } = this.state;
    let { firtsOrderLoader, CustomerName, idCustomer, userId } = this.state;
    if (!firtsOrderLoader) {
      let newOrder = order;

      newOrder.ImporteName = CustomerName;
      newOrder.idCustomer = idCustomer;
      newOrder.userId = userId;
      this.setState({
        order: newOrder,
      }, () => {
        this.buildFormCreateOrder();
      });
    }
  }

  handleChange = name => event => {
    let order = this.state.order;
    order[name] = event.target.value;
    this.setState({ order }, () => {
      this.buildFormCreateOrder();
      this.props.sendOrderField(this.state.order);
    });
  }
  handleChangeSelect = (selected) => event => this.setState({ selected }, () => {
    let { order, Form, isEdit } = this.state;
    if (event && event.value) {
      order[selected] = event.value;
      if (selected === 'ContainerTypeCode' & event.value ==='20R' || selected === 'ContainerTypeCode' &  event.value ==='40R') {
        Form[ isEdit ? 'createConsolEdit' :'CreateConsol'].map((e) => {
          if (e.id === 'RequestedTemp') {
            e.required = true;
          }
          return e;
        });
        this.setState({ order, Form }, () => {
          this.buildFormCreateOrder();
          this.props.sendOrderField(this.state.order);
        });
      }
      else {
        Form[isEdit ? 'createConsolEdit' :'CreateConsol'].map((e) => {
          if (e.id === 'RequestedTemp') {
            e.required = false;
            e.error = '';
            e.style = { color: '#616161' };
          }
          return e;
        });
        this.setState({ order,Form }, () => {
          this.buildFormCreateOrder();
          this.props.sendOrderField(this.state.order);
        });
      }

    } else {
      order[selected] = '';
      this.setState({ order }, () => {
        this.buildFormCreateOrder();
        this.props.sendOrderField(this.state.order);
      });
    }
  });

  handleChangeDate = name => event => {
    let order = this.state.order;
    try {
      order[name] = event.format('YYYY-MM-DD');
      this.setState({ order }, () => {
        this.buildFormCreateOrder();
        this.props.sendOrderField(this.state.order);
      });
    }
    catch (ex) {
      order[name] = '';
      this.setState({ order }
      );
    }
  }

  buildFormCreateOrder = () => {
    const { Form, order, selects, view, isEdit } = this.state;
    let styletexatera = {
      minHeight: '6em'
    };
    let CreateOrderFormData = Form[view ? 'CreateConsolView' : (isEdit ? 'createConsolEdit' : 'CreateConsol')].map((e, i) => {
      if (e.type === 'Input') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite> <span id={e.id} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
          <Input

            id={e.id}
            required
            type={e.datatype}
            placeholder={e.placeHolder}
            name={e.id}
            value={order[e.id]}
            maxlength={e.maxlength}
            minlength={e.minlength}
            autoComplete="off"
            onChange={this.handleChange(e.id)}
            max={e.max} />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>

        </div>
      );
      else if (e.type === 'label') return (
        <div className={e.class} key={e.id}>
          <Col>
            <Row>
              <Col md={12} lg={12} sm={6}>
                <Fade left opposite> <span id={e.id} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
              </Col>
              <Col md={12} lg={12} sm={6}>
                <Fade left opposite> <span id={'user'}><label>{order[e.id]}</label></span></Fade>
              </Col>
            </Row>
          </Col>
        </div >
      );
      else if (e.type === 'Select') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite><span id={e.id} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <Select
            autoComplete="off"
            id={e.id}
            required
            type={e.datatype}
            name={e.name}
            value={order[e.id]}
            onChange={this.handleChangeSelect(e.id)}
            options={selects[e.options]}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
          {/* <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label> */}

        </div>);
      else if (e.type === 'Datetime') return (
        <div className={e.class} key={e.id} >
          <Fade left opposite>
            <span id={e.label} style={e.style}> <label  >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <Datetime
            required
            autocapitalize="off"
            type={e.datatype}
            dateFormat={'YYYY MM DD'}
            inputProps={{ className: 'form-control', id: e.id, autoComplete: 'off' }}
            value={order[e.id]}
            onChange={this.handleChangeDate(e.id)} />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
          {/* <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label> */}

        </div>
      );
      else if (e.type === 'textarea') return (
        <div style={{ marginBottom: '1em' }} className={e.class} key={e.id}>
          <Fade left opposite>
            <span id={e.label} style={e.style}> <label >{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
          <Input
            style={styletexatera}
            required
            type={e.datatype}
            name={e.id}
            value={order[e.id]}
            id={e.id}
            autoComplete="off"
            onChange={this.handleChange(e.id)}
          >
            <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
          </Input>
        </div>
      );
      else return '';
    });
    this.setState({
      CreateOrderFormData
    });
  }
  render() {
    let { CreateOrderFormData } = this.state;
    return (
      <Card className="myCard">
        <CardBody>
          <ExpansionPanel elevation={0} defaultExpanded>
            <ExpansionPanelSummary id="mytext" expandIcon={
              <ExpandMoreIcon />}>
              <legend ><em className="fas fa-th-list fa-1x mr-2"></em>General Information</legend>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="row col-md-12">
                {CreateOrderFormData}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </CardBody>
      </Card>
    );
  }
}

export default generalInformation;
