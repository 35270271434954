import React, {Component} from 'react';
import ContentWrapper from '../../template/Layout/ContentWrapper';

class CustomerService extends Component {
  constructor(props){
    super(props);

    this.state = {

    };
  }

  render (){
    return (
      <ContentWrapper>
        <h1>Customer Service</h1>
      </ContentWrapper>
    );
  }
}

export default CustomerService;