import React from 'react';
import propTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { Icon } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import {blue} from '@material-ui/core/colors';

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  check: {
    color: blue[800],
    '&$checked': {
      color: blue[900],
    },    
  }
}));

function TableBody({rows, columns, page, rowsPerPage, cellType, selectItems, onSelectItem, isSelected, keyCheck}){
  // let newRows = [];
  const classes = useStyles();
  const ROWS = rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( (row, indexrow) => {
    const isSelectedItem = isSelected(row[keyCheck]);
    const labelId = `table-checkbox-${row[keyCheck]}`;
    return (
      <tr key={indexrow}>
        {
          selectItems ? 
            <td
              className={`table-td ${cellType}`}
              style={{ fontSize: '12px', textAlign: 'center', minWidth: 80}}
            >
              <Checkbox
                checkedIcon={<Icon>check_circle</Icon>}
                color='none'
                className={classes.check}
                size='small'
                onClick={event => onSelectItem(event, row[keyCheck])}
                checked={isSelectedItem}
                inputProps={{ 'aria-labelledby': labelId }}
              /> 
            </td>
            :
            ''
        }

        {
          columns.map((column,indxcol) => {
            return (
              <React.Fragment>
                {
                  column.custom ? 
                    <td
                      key={indxcol}
                      className={`table-td ${cellType} ${column.fixed ? 'col-fixed' : ''}`} id={row[column.key] ? (column.idTd ? column.idTd : row[column.key]) : row[column.key]}
                      style={{ fontSize: '12px', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80 }}>
                      {column.custom(row)}
                    </td>          
                    :
                    <td
                      key={indxcol}
                      className={`table-td ${cellType} ${column.fixed ? 'col-fixed' : ''}`}
                      id={row[column.key] ? (column.idTd ? column.idTd : row[column.key]) : row[column.key]}
                      style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80 }}>
                      {row[column.key] ? row[column.key] : ''}
                    </td>            
                }
              </React.Fragment>
            );
          })
        }
      </tr>
    );
  });
  
  return (
    <React.Fragment>
      {ROWS}
    </React.Fragment>
  );
}

TableBody.propTypes = {
  rows: propTypes.array,
  page: propTypes.number,
  rowsPerPage: propTypes.number,
  cellType: propTypes.string,
  selectItems: propTypes.bool,
  onSelectItem: propTypes.func,
  isSelected: propTypes.func,
  keyCheck: propTypes.string
};

TableBody.defaultProps = {
  rows: [],
  page: 0,
  rowsPerPage: 10,
  cellType: 'thick-rows',
  selectItems: false,
  onSelectItem: () => {},
  isSelected: () =>{},
  keyCheck: ''
};

export default TableBody;
