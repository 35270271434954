import React from 'react';
import './styles.scss';
// import imagenLogin from './../../../resources/Textura_Verde_NoLogo.png';
import { Label, Input, CardBody } from 'reactstrap';
import {Services} from './Services';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const BackgroundHead = {
  height: '100%',
  // background:'red',
  backgroundImage: 'linear-gradient(to bottom, #0097dc 0%, #00558c 100%)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 'calc(100vw + 48px)',
  margin: -24,
  padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Recover(props) {
  const [redirectlogin, setRedirectlogin] = React.useState(
    {
      login: {
        newcontrasena: '',
        valid: false,
        confirmarcontrasena: '',
        check: false,
        email: ''
      }
    });
  let [Mostrar, setMostrar] = React.useState(false);
  let [exito, setexito] = React.useState(false);
  let [TokenValido, setTokenValido] = React.useState(true);

  const getAutentication = React.useCallback(() => {
    axios.get(`${Services.ADMIN.path}/admin/recovery?token=${props.match.params.token}`, {
    // axios.get(`http://localhost:3022/admin/recovery?token=${props.match.params.token}`, {

    }).then(response => {
      redirectlogin.login.email = response.data.email;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
      setTokenValido(true);
    }).catch(error => {
      console.error('getAutentication -- ', error);
      setTokenValido(false);
    });
  }, [props.match]);

  React.useEffect(() => {
    getAutentication();
  }, [getAutentication]);

  function change(event) {
    document.getElementById('email_sin').setAttribute('hidden', 'hidden');
    document.getElementById('pass_sin').setAttribute('hidden', 'hidden');
    if (event.target.name === 'confirmarcontrasena') {
      let value = event.target.value;
      if (redirectlogin.login.newcontrasena === value) {
        document.getElementById('pass_diferentes').setAttribute('hidden', 'hidden');
        document.getElementById('se_cumple').removeAttribute('hidden');

        // document.getElementById('input_correo').className = 'input100';
      }
      else {
        document.getElementById('pass_diferentes').removeAttribute('hidden');
        document.getElementById('se_cumple').setAttribute('hidden', 'hidden');
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    }
    else {
      let value = event.target.value;

      const emailRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/);
      if (emailRegex.test(value)) {
        // document.getElementById('input_email').className = 'input100';
        document.getElementById('123456').setAttribute('hidden', 'hidden');
      }
      else {
        // document.getElementById('input_email').className = 'invalidInput';
        document.getElementById('123456').removeAttribute('hidden');
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    }
  }

  function handleLoginId() {
    if (!redirectlogin.login.newcontrasena) {
      document.getElementById('email_sin').removeAttribute('hidden');
    }
    else if (!redirectlogin.login.confirmarcontrasena) {
      document.getElementById('pass_sin').removeAttribute('hidden');
    }
    else {
      document.getElementById('email_sin').setAttribute('hidden', 'hidden');
      document.getElementById('pass_sin').setAttribute('hidden', 'hidden');
      let data = {
        email: redirectlogin.login.email,
        password: redirectlogin.login.newcontrasena
      };
      axios.put(`${Services.ADMIN.path}/admin/changePass`, data).then(() => {
        document.getElementById('pass_changue').removeAttribute('hidden');
        setexito(true);
      }).catch(error => {
        console.error('handleLoginId: ', error);
      });
    }
  }

  return (
    <div className="limiter" style={BackgroundHead}>
      <div className="container-login100">
        {TokenValido ?
          <div className="wrap-login100 p-t-0 p-b-0" style={{ background: 'transparent' }}>
            <CardBody style={{ textAlign: 'center' }}>
              <div className="logo-container">
                <img className='img_logo' style={{ width: '100%' }} src={'https://fsposeidon.blob.core.windows.net/general/interglobo-logo-psdn-white-payoff.png'} alt='poseidon-logo'></img>
              </div>
            </CardBody>
            <div>
              
              {exito ?
                <CardBody style={{ textAlign: 'center' }}>
                  <div className="col-12" >
                    <label style={{ color: 'white', fontSize: '27px' }}>Password updated correctly</label>
                  </div>
                </CardBody>

                :
                  <>
                  <div className="col-12" >
                    <label style={{ color: 'white', fontSize: '30px' }}>Password reset</label>
                    <div style={{ height: 15 }} />
                    <label style={{ color: 'white', fontSize: '16px' }}>The password must be composed of:</label>
                    <label style={{ color: 'white', fontSize: '16px' }}>* Minimum 8 digits and up to maximum 15 digits.</label>
                    <label style={{ color: 'white', fontSize: '16px' }}>* At least one uppercase and one lowercase letter.</label>
                    <label style={{ color: 'white', fontSize: '16px' }}>* A special character (*! $% &? _-).</label>
                  </div>
                    </>
              }
              
            </div>
            <div style={{ height: 25 }} />
            {exito ? <></> : <div className="col-12" style={{ textAlign: 'center' }}>
              <div className="wrap-input100 validate-input m-b-12 " data-validate="Usuario es Requerido">
                <Input
                  type={Mostrar ? 'text' : 'password'}
                  id="input_email"
                  name="newcontrasena"
                  placeholder="New Password"
                  onChange={change}
                  value={redirectlogin.login.newcontrasena}
                />
                <span className="focus-input100"></span>
              </div>
              <div className="wrap-input100 validate-input m-b-12" data-validate="Contraseña es Requerida">
                <Input
                  type={Mostrar ? 'text' : 'password'}
                  id="input_correo"
                  name="confirmarcontrasena"
                  placeholder="Confirm Password"
                  onChange={change}
                  value={redirectlogin.login.confirmarcontrasena}
                />
                <span className="focus-input100"></span>
              </div>
              <div style={{ padding: '3px', marginTop: '-14px', color: 'white', fontSize: '15px',textAlign:'initial' }} className="_alert" id="email_sin" hidden>Password is required</div>
              <div style={{ padding: '3px', marginTop: '-14px', color: '0FFFF00', fontSize: '15px',textAlign:'initial' }} className="_alert" id="pass_sin" hidden>Confirm Password</div>
              <div style={{ padding: '3px', marginTop: '-14px', color: '0FFFF00', fontSize: '15px',textAlign:'initial' }} className="_alert" id="pass_diferentes" hidden>Passwords must be the same</div>
              <div style={{ padding: '3px', marginTop: '-14px', color: '0FFFF00', fontSize: '15px',textAlign:'initial' }} className="_alert" id="pass_changue" hidden>Your password has been successfully changed</div>
              <div style={{ padding: '3px', marginTop: '-14px', color: '0FFFF00', fontSize: '15px',textAlign:'initial' }} className="_alert" id="123456" hidden>Invalid password</div>
            </div>
            }
            {exito ? <></> : <div className=" col-6">
              <div style={{ fontSize: '11px !important' }} className="col-12">
                <Label style={{ color: 'white' }}  >
                  <Input type="checkbox" onChange={() => {
                    setMostrar(!Mostrar);
                  }} checked={Mostrar} />View
                </Label>
              </div>
            </div>}
            <div style={{ height: 5 }} />
            {exito ? <></> : <div className="container-login100-form-btn">
              <div className="col-6">
              </div>
              <div className="col-6" style={{ textAlign: '-webkit-right' }}>
                <button id="se_cumple" className="_loginButton" onClick={handleLoginId}>Change</button>
              </div>
            </div>}
          </div>
          :
          <div>
            <div style={{ height: '10em' }} className="col-12" ></div>
            <div className="col-12" >
              <label style={{ color: 'white', fontSize: '30px' }}>Error: "The token has already been used or has expired" </label>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
export default withRouter(Recover);
