import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import './../../../FlotVendors';

const Headers = ({data}) => {
  let HEADER = data.map((e, key) => {
    return (
      <div className='d-flex align-items-center ml-2' key={key}>
        {e.colorNone === true ? '' : <div className='align-self-center' style={{backgroundColor: e.color,  width:'.8em', height:'.8em'}}> 
        </div>}
        <div className='align-self-center ml-1' style={{fontSize: '.9em'}}>
          {e.label}
        </div>
      </div>
    );
  });

  return (
    <div className='col-12 col-sm-12 d-flex justify-content-end'>
      {HEADER}
    </div>
  );
};
Headers.propTypes = {
  data: PropTypes.array
};
Headers.defaultProps = {
  data: []
};
class FlotChart extends Component {
    state = {
      data: this.props.data
    }

    static propTypes = {
      data: PropTypes.array.isRequired,
      options: PropTypes.object.isRequired,
      height: PropTypes.string,
      width: PropTypes.string
    }

    static defaultProps = {
      height: '100%',
      width: '100%'
    }

    componentWillMount() {
      if(typeof $.plot === 'undefined')
        throw new Error('Flot plugin not present.');
    }

    componentDidMount() {
      this.dreawChart();
    }

    componentWillReceiveProps(nextProps) {
      if (!deepEqual(nextProps.data, this.props.data) || !deepEqual(nextProps.options, this.props.options)) {
        this.setState({data: nextProps.data});
        this.dreawChart(nextProps);
      }
    }

    componentWillUnmount() {
      $(this.flotElement).data('plot').shutdown();
    }

    dreawChart(nextProps) {
      const data = (nextProps && nextProps.data) || this.props.data;
      const options = (nextProps && nextProps.options) || this.props.options;
      $.plot(this.flotElement, data, options);
      // $(window).resize(function() {$.plot($('#graph_div'), data, options);});
    }

    setRef = node => {
      this.flotElement = node;
    }

    render() {
      const style = {
        height: this.props.height,
        width: this.props.width
      };

      return (
        <React.Fragment>
          <Headers data={this.state.data}></Headers>
          <div ref={this.setRef} style={style}/>
        </React.Fragment>
      );
    }
}

export default FlotChart;