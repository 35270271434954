export const configOrder = [
  {
    id: 'POTransportMode',
    type: 'img',
    width: 20
  },
  {
    id: 'PONumber',
    label: 'PO#',
    type: 'link',
    linkVal: 'POId',
    link: '/po-detail-view/',
    width: 20
  },
  {
    id: 'SplitNo',
    label: 'Split No.',
    type: 'text',
    width: 20
  },
  {
    id: 'POStatus',
    label: 'Status',
    type: 'status',
    width: 20
  },
  {
    id: 'POProjectName',
    label: 'Project',
    type: 'text',
    width: 20
  },
  {
    id: 'POSupplier',
    label: 'Supplier',
    type: 'text',
    width: 20
  },
  {
    id: 'Division',
    label: 'PO Division',
    type: 'text',
    width: 20
  },
  {
    id: 'POCreateDate',
    label: 'PO Creation Date',
    type: 'text',
    width: 20
  },
  {
    id: 'RequestedExWorksDate',
    label: 'Requested Ex Works Date',
    type: 'text',
    width: 20
  },
  {
    id: 'RequiredDestination',
    label: 'Required at Destination',
    type: 'text',
    width: 20
  }
];

export const configBooking = [
  {
    id: 'BOTransportMode',
    type: 'img',
  },
  {
    id: 'BookingNumber',
    label: 'Booking#',
    type: 'text'
  },
  {
    id: 'BOStatus',
    label: 'Status',
    type: 'status'
  },
  {
    id: 'BOSupplier',
    label: 'Supplier',
    type: 'text'
  },
  {
    id: 'BOConsignee',
    label: 'Consignee',
    type: 'text'
  },
  {
    id: 'BOOrigin',
    label: 'Origin',
    type: 'text'
  },
  {
    id: 'BookingDate',
    label: 'Booking Date',
    type: 'text'
  },
  {
    id: 'BOETD',
    label: 'ETD',
    type: 'text'
  },
  {
    id: 'BOETA',
    label: 'ETA',
    type: 'text'
  },
  {
    id: 'POs',
    label: 'POs',
    type: 'popup',
    title: 'POs'
  }
];

export const configShipment = [
  {
    id: 'TransportMode',
    type: 'img',
  },
  {
    id: 'ShipmentNumber',
    label: 'Shipment#',
    type: 'link',
    linkVal: 'ShipmentNumber',
    link: '/tracking/'
  },
  {
    id: 'Status',
    label: 'Status',
    type: 'status'
  },
  {
    id: 'Supplier',
    label: 'Supplier',
    type: 'text'
  },
  {
    id: 'POS',
    label: 'POs',
    type: 'popup',
    title: 'POs'
  },
  {
    id: 'Origin',
    label: 'From',
    type: 'text'
  },
  {
    id: 'Destination',
    label: 'To',
    type: 'text'
  },
  {
    id: 'ETD',
    label: 'ETD',
    type: 'text'
  },
  {
    id: 'ETA',
    label: 'ETA',
    type: 'text'
  },
  {
    id: 'Progress',
    label: 'On Time',
    type: 'progress'
  }
];

export const configContainer = [
  {
    id: 'Image',
    type: 'img',
  },
  {
    id: 'ContainerNumber',
    label: 'Container No.',
    type: 'link',
    linkVal: 'BookingContainerId',
    link: '/container-detail/'
  },
  {
    id: 'StatusDesc',
    label: 'Status',
    type: 'status'
  },
  {
    id: 'ShipmentNumber',
    label: 'Shipment No.',
    type: 'text'
  },
  {
    id: 'SupplierName',
    label: 'Supplier',
    type: 'text'
  },
  {
    id: 'POs',
    label: 'POs',
    type: 'popup',
    title: 'POs'
  },
  {
    id: 'POD',
    label: 'POD',
    type: 'text'
  },
  {
    id: 'DeliveryTown',
    label: 'Delivery',
    type: 'text'
  },
  {
    id: 'ETD',
    label: 'ETD',
    type: 'text'
  },
  {
    id: 'ETA',
    label: 'ETA',
    type: 'text'
  },
  {
    id: 'DeliveryMode',
    label: 'Delivery Mode',
    type: 'text'
  }
];

export const configReceipt = [
  {
    id: "Status",
    type: 'icon',
    id2: "Status",
  },
  {
    id: 'DocumentNo',
    label: '',
    type: 'link',
    linkVal: 'W_ReceiptId',
    link: '/receipts-detail/'
  },
  {
    id: 'ContainerNumber',
    label: 'Container No.',
    type: 'link',
    linkVal: 'BookingContainerId',
    link: '/container-detail/'
  },
  {
    id: 'POsNo',
    label: 'PO No.',
    type: 'text'
  },
  {
    id: 'VendorName',
    label: 'Buyer',
    type: 'text'
  },
  {
    id: 'WarehouseCode',
    label: 'Warehouse',
    type: 'text'
  },
  {
    id: 'Ref1',
    label: 'Ref 1',
    type: 'text'
  },
  {
    id: 'Ref2',
    label: 'Ref 2',
    type: 'text'
  },
  {
    id: 'ReceiptDate',
    label: 'Receipt Date',
    type: 'text'
  },
];

export const configShipmentBurgo = [
  {
    id: "Status",
    type: 'icon',
    id2: "Status",
  },
  {
    id: 'DocumentNo',
    label: '',
    type: 'link',
    linkVal: 'W_ShipmentId',
    link: '/shipments-detail/'
  },
  {
    id: 'Consignee',
    label: 'Consignee',
    type: 'text'
  },
  {
    id: 'WarehouseCode',
    label: 'Warehouse',
    type: 'text'
  },
  {
    id: 'ReleaseNo',
    label: 'Release No.',
    type: 'text'
  },
  {
    id: 'LoadNo',
    label: 'Load No.',
    type: 'link',
    linkVal: 'W_TruckingId',
    link: '/trucking-detail/'
  },
  {
    id: 'Ref1',
    label: 'Ref 1',
    type: 'text'
  },
  {
    id: 'Ref2',
    label: 'Ref 2',
    type: 'text'
  },
  {
    id: 'DateShip',
    label: 'Date to Ship',
    type: 'text'
  },
  {
    id: 'ShipDate',
    label: 'Ship Date',
    type: 'text'
  },
];

export const configTrucking = [
  {
    id: "Status",
    type: 'icon',
    id2: "Status",
  },
  {
    id: 'LoadNo',
    label: '',
    type: 'link',
    linkVal: 'W_TruckingId',
    link: '/trucking-detail/'
  },

  {
    id: 'WarehouseCode',
    label: 'Warehouse',
    type: 'text'
  },
  {
    id: 'FreightType',
    label: 'Freight Type',
    type: 'text'
  },
  {
    id: 'DestinationName',
    label: 'Destination',
    type: 'text'
  },

  {
    id: 'City',
    label: 'DestinationCity',
    type: 'text'
  },
  {
    id: 'ATD',
    label: 'ATD',
    type: 'text'
  },
  {
    id: 'ETA',
    label: 'ETA',
    type: 'text'
  },
  {
    id: 'ATA',
    label: 'ATA',
    type: 'text'
  },
];

export const configInventory = [


  {
    id: 'Lot',
    label: 'PO No.',
    type: 'text'
  },
  {
    id: 'WarehouseCode',
    label: 'Warehouse',
    type: 'text'
  },
  {
    id: 'Item',
    label: 'Item',
    type: 'text'
  },
  {
    id: 'Description',
    label: 'Description',
    type: 'text'
  },
  {
    id: 'CountQty',
    label: 'QTY',
    type: 'text'
  },
  {
    id: 'UOM',
    label: 'UOM',
    type: 'text'
  },
  {
    id: 'Ref1',
    label: 'Ref1',
    type: 'text'
  },
  {
    id: 'Size1',
    label: 'Size 1',
    type: 'text'
  },
  {
    id: 'Weight',
    label: 'Weight',
    type: 'text'
  },
];

