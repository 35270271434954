export const Model_New_Build = () => {
	return {
		flat: {
			MasterShipment: undefined
		},
		Order_Summary: {
			PONo: { value: undefined, type: 'text', required: true, valid: true },
			Supplier: { value: undefined, type: 'text', valid: true },
			Buyer: { value: undefined, type: 'text', valid: true },
			Department: { value: undefined, type: 'text', valid: true },
			ProjectCutOffId: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			CutOffDate: { value: undefined, hidden: false },
			CodIncoterm: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			ProductDescription: { value: undefined, type: 'text', valid: true },
			Mesures: { value: undefined, type: 'text', valid: true },
			Weight: { value: undefined, type: 'text', valid: true },
			From: { value: undefined, type: 'text', valid: true },
			To: { value: undefined, type: 'text', valid: true },
			TypeDelivery: { value: undefined, type: 'text', required: true, valid: true },
			Value: { value: undefined, type: 'numberDecimal', valid: true },
			ChargeableWeight: { value: undefined, type: 'numberDecimal', valid: true },
			DeliveryConditions: { value: undefined, type: 'text', valid: true },
			DateDelivery: { value: undefined, valid: true },
			Note: { value: undefined, type: 'text' },
		},
		PO: {
			Supplier: { value: undefined, type: 'text', valid: true },
			Buyer: { value: undefined, type: 'text', valid: true },
			Department: { value: undefined, type: 'text', valid: true },
			ProjectCutOffId: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			CutOffDate: { value: undefined, },
			CodIncoterm: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			ProductDescription: { value: undefined, type: 'text', valid: true },
			Mesures: { value: undefined, type: 'text', valid: true },
			Weight: { value: undefined, type: 'text', valid: true },
			From: { value: undefined, type: 'text', valid: true },
			To: { value: undefined, type: 'text', valid: true },
			TypeDelivery: { value: undefined, type: 'text', required: true, valid: true },
			Value: { value: undefined, type: 'numberDecimal', valid: true },
			ChargeableWeight: { value: undefined, type: 'numberDecimal', valid: true },
			DeliveryConditions: { value: undefined, type: 'text', valid: true },
			DateDelivery: { value: undefined, valid: true },
			Note: { value: undefined, type: 'text' },
		},

		Master_Shipment: {
			User: { id: undefined, rol: undefined },
			POS: { ids: [] },
			MasterShipment: { value: undefined, type: 'text', required: true, valid: true },
			ETA: { value: undefined, valid: true },
			ActualDeliveryDate: { value: undefined, valid: true },
			BookingNo: { value: undefined, type: 'text', valid: true },
			// Operator: { value: undefined, type: 'text' },
			Vessel: { value: undefined, type: 'text', valid: true },
			POD: { value: undefined, type: 'text', valid: true },
			ContainerNo: { value: undefined, type: 'text', valid: true },
			BL_AWB: { value: undefined, type: 'text', valid: true },
			ShipmentCost: { value: undefined, type: 'numberDecimal', valid: true },
			Weight: { value: undefined, type: 'numberEx', valid: true },
			// Volume: { value: undefined, type: 'numberEx' },
			Operator: { value: undefined, id: undefined, required: false, valid: true, type: 'text' },
		},
		ProductionSM: {
			PromiseCargoReady: { value: undefined, valid: true },
			SupplierContacted: { value: undefined, check: undefined },
			ConfirmedCargoReady: { value: undefined, valid: true },
			ActualCargoReadyDate: { value: undefined, valid: true },
			EstPickupReceiveDate: { value: undefined, valid: true },
			// ConfirmedReadinessDate: { value: undefined, },
			ActualPickupReceiveDate: { value: undefined, valid: true },
			CustomerReference1: { value: undefined, type: 'text', valid: true },
			CustomerReference2: { value: undefined, type: 'text', valid: true },
			ProductDescription: { value: undefined, type: 'text', valid: true },
		},
		Production: {
			PromiseCargoReady: { value: undefined, valid: true },
			SupplierContacted: { value: undefined, check: undefined, },
			ConfirmedCargoReady: { value: undefined, valid: true },
			ActualCargoReadyDate: { value: undefined, valid: true },
			EstPickupReceiveDate: { value: undefined, valid: true },
			// ConfirmedReadinessDate: { value: undefined, },
			ActualPickupReceiveDate: { value: undefined, valid: true },
			CustomerReference1: { value: undefined, type: 'text', valid: true },
			CustomerReference2: { value: undefined, type: 'text', valid: true },
			ProductDescription: { value: undefined, type: 'text', valid: true },
			Mesures: { value: undefined, type: 'text', valid: true },
			Weight: { value: undefined, type: 'text', valid: true },
			From: { value: undefined, type: 'text', valid: true },
			TypeDelivery: { value: undefined, type: 'text', required: true, valid: true },
			CodIncoterm: { value: undefined, id: undefined, required: false, valid: true, type: 'text' },
			DateDelivery: { value: undefined, type: 'text', valid: true },
			Operator: { value: undefined, id: undefined, required: false, valid: true, type: 'text' },
			OutTimePlanProcurement: { value: undefined, check: undefined },
			Note: { value: undefined, type: 'text' },
		},
		Warehousing: {
			WMSReference: { value: undefined, valid: true },
			EstArrivalConsolWH: { value: undefined, valid: true },
			ActualArrivalConsolWH: { value: undefined, valid: true },
		},
		Transportation: {
			TransportModeId: { value: undefined, id: undefined, required: false, valid: true, type: 'text' },
			BookingNo: { value: undefined, type: 'text', valid: true },
			Vessel: { value: undefined, type: 'text', valid: true },
			VoyageFlightNo: { value: undefined, type: 'text', valid: true },
			Carrier: { value: undefined, type: 'text', valid: true },
			POL: { value: undefined, type: 'text', valid: true },
			POD: { value: undefined, type: 'text', valid: true },
			BL_AWB: { value: undefined, type: 'text', valid: true },
			ContainerNo: { value: undefined, type: 'text', valid: true },
			IntergloboFile: { value: undefined, type: 'text', valid: true },
			RealWeigth: { value: undefined, type: 'milesAndDecimales', valid: true },
			ChargeableWeigth: { value: undefined, type: 'numberDecimal', valid: true },
			// Logistivcs: { value: 'Garbage Bins', hidden: false, valid: true, required: true },
			ETD: { value: undefined, valid: true },
			NewPlannedDeliveryDate: { value: undefined, hidden: false, valid: true },
			ETA: { value: undefined, valid: true },
			ActualDeliveryDate: { value: undefined, valid: true },
			EstDeliveryDate: { value: undefined, hidden: false, valid: true },
			OutTimePlanShipyard: { value: undefined, check: undefined },
		},
		TransportationSM: {
			ETD: { value: undefined, valid: true },
			POL: { value: undefined, type: 'text', valid: true },
			ActualDeliveryDate: { value: undefined, valid: true },
			IntergloboFile: { value: undefined, type: 'text', valid: true },
			RealWeigth: { value: undefined, type: 'milesAndDecimales', valid: true },
			ChargeableWeigth: { value: undefined, type: 'numberDecimal', valid: true },
			// ActualDeliveryDate: { value: undefined },
			NewPlannedDeliveryDate: { value: undefined, hidden: false, valid: true },
			Combined: { value: undefined, check: undefined },
		},
		Comments: {
			ComTypeId: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			Comment: { value: undefined, required: true },
			hiddenButtonSave: { hidden: true }
		},
		Documments: {
			CategoryId: { value: undefined, id: undefined, valid: true, type: 'noVacio' },
			Name: { value: undefined, valid: true },
			File: { data: undefined, valid: true }
		},
		New_PO: {
			PONo: { value: undefined, type: 'text', required: true, valid: true },
			Supplier: { value: undefined, type: 'text', required: true, valid: true, },
			Buyer: { value: undefined, type: 'text', valid: true, },
			Department: { value: undefined, type: 'text', required: true, valid: true, },
			ProjectCutOffId: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			ProductDescription: { value: undefined, type: 'text', valid: true },
			Mesures: { value: undefined, type: 'text', valid: true, },
			Weight: { value: undefined, type: 'text', valid: true, },
			ChargeableWeight: { value: undefined, type: 'numberDecimal', valid: true, },
			From: { value: undefined, type: 'text', valid: true },
			To: { value: undefined, type: 'text', valid: true },
			TypeDelivery: { value: undefined, type: 'text', required: true, valid: true },
			CodIncoterm: { value: undefined, id: undefined, required: true, valid: true, type: 'noVacio' },
			Value: { value: undefined, type: 'numberDecimal', valid: true },
			DateReadiness: { value: undefined, required: true, valid: true },
			DeliveryDate: { value: undefined, required: true, valid: true },
			CustomerReference1: { value: undefined, type: 'text', valid: true },
			CustomerReference2: { value: undefined, type: 'text', valid: true },
			Note: { value: undefined, type: 'text' },
			// StatusId: { value: 101 },
		},

		Catalogs: {
			StatusId: [],
			Supplier: [],
			Buyer: [],

			StatusShip: [],
			Department: [],
			ComTypeId: [],
			ProjectCutOffId: [],
			CodIncoterm: [],

			PONo: [],
			ProductDescription: [],
			Mesures: [],
			Weight: [],
			ChargeableWeight: [],
			From: [],
			To: [],
			TypeDelivery: [],
			Value: [],
			CustomerReference1: [],
			CustomerReference2: [],
		},
		Cat_Detail: {
			TransportModeId: [],
			ComTypeId: [],
			CodIncoterm: [],
			ProjectCutOffId: [],
			CategoryId: [],
			Operator: [],
			TypeDelivery: []
		},
		Update_Shipments: {
		},
	};
};

// export const Model_Production = () => {
// 	return {

// 	};
// };