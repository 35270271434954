import React from 'react';
import { Card } from 'semantic-ui-react';
import { WarningOutlined } from '@ant-design/icons';
import { Label } from 'reactstrap';

function NoEdiEvents() {
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <center>
            <WarningOutlined style={{ fontSize: '70px', color: '#faad14' }} />
          </center>
        </Card.Header>
        <Card.Description>
          <center>
            <Label style={{ fontWeight: 'bold' }}>No EDI Events Found.</Label>
          </center>
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

export default NoEdiEvents;
