import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Step, StepContent, StepLabel, Stepper, StepButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import { PendingConfirm, Readiness, Transit, Delivered } from "../../components/img";
import Pending from '../components/../../components/svg_img/pending.svg'
import Ready from '../components/../../components/svg_img/confirmed_cargo.svg'
import ETD from '../components/../../components/svg_img/ETD.svg'
import Delivery from '../components/../../components/svg_img/delivery.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));



function StepperComponent(params) {
    // console.log("🚀 ~ file: Stepper.js ~ line 27 ~ StepperComponent ~ params", params)
    const classes = useStyles();

    const [events, setEvents] = useState([
        // { label: "Pending Confirmation", value: "30/11/1993", completed: true, orden: 1 },
        { label: "Supplier Contacted", value: "30/11/93", completed: true, orden: 1 },
        { label: "Readiness Confirmed", value: "30/11/1993", completed: true, orden: 2 },
        { label: "In Transit", value: "", completed: true, orden: 3 },
        { label: "Delivered", value: "", completed: false, orden: 4 },
    ])

    useEffect(() => {
        setEvents(params.steps)
    }, [])

    function ColorlibStepIcon(props) {
        // const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            '1': <img src={Pending} alt="SVG logo image" />,
            '2': <img src={Ready} alt="SVG logo image" />,
            '3': <img src={ETD} alt="SVG logo image" />,
            '4': <img src={Delivery} alt="SVG logo image" />,
            // 5: <Delivered />,
        };

        return (
            <div
            // className={clsx(classes.root, {
            //     [classes.active]: active,
            //     [classes.completed]: completed,
            // })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={true}>
                {
                    Object.entries(events).map(([key, data]) => {
                        // events.map((label) => {
                        const stepProps = {};
                        return (
                            <Step key={data.label} {...stepProps}>
                                <StepLabel
                                    completed={data.completed}
                                    StepIconComponent={ColorlibStepIcon}
                                >
                                    <spam style={{ color: '#7E7E7E' }}>{data.label}</spam><br/><Typography variant="caption" style={{ color: '#9E9E9E' }}>{data.value}</Typography>
                                </StepLabel>
                                {/* <StepButton
                                completed={label.completed}
                                {...buttonProps}
                            >
                                {label.label}
                            </StepButton> */}
                            </Step>
                        )
                    })}
            </Stepper>
        </div>
    )
}

export default withRouter(StepperComponent)

