import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import axios from 'axios';
import {Services} from '../../Services';
import Table from '../../../../template/Table';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { label: 'Name', key: 'Name' },
        { label: 'Last Name', key: 'LastName' },
        { label: 'Role', key: 'Rol' },
        { label: 'Module', key: 'Module' },
        { label: 'Date', key: 'Date' },
      ],
      data:[],
      loaderTab: true,
      customer: 0
    };
  }

  componentDidMount = () => {
    this.getDetails();
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      customer: nextProps.customer
    }, () => {this.getDetails();});
  }

  getDetails = () => {
    //let id = auth.getAuthentication('customerSelect');
    let id = this.state.customer;
    axios
      .get(`${Services.STATISTICS.path}/statistics/details?id=${id}`)
      .then(success => {        
        this.setState({ data: success.data.data, loaderTab: false });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Card className="myCard">
          <CardBody>
            {
              this.state.loaderTab ?
              <></>
                :
                <Table
                  columns={this.state.columns}
                  data={this.state.data}
                  itemsByPage={10}
                  options={{
                    downloads: {
                      pdf: false,
                      xlsx: true,
                      disableConfig: true
                    },
                    pagination: true,
                    search: true
                  }}
                />
            }
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Details;
