import React from "react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";

/*=============================================
=            Catalogs  By Year          =
=============================================*/
/**
 * @typedef GradientProps
 * @property {string} yearselected  Año seleccionado
 * @property {number} yearsPrevious Años anteriores
 * @param {Function} onChangeYear Funcion que se ejecuta al cambiar el año
 */

/** @param {CatYearsProps} */



function CatYears({yearselected, yearsPrevious, onChangeYear }) {
  
  const [CatalogYears, setCatalogYears] = React.useState([]);
  const [date, setDate] = React.useState(yearselected ? yearselected : moment(new Date()).format("YYYY"));
  const GetCatalog = React.useCallback(async () => {
    try {
      let catalogYear = getTwoPreviousYears(yearsPrevious);
      setCatalogYears(catalogYear);
    } catch (error) {
      console.error(error);
    }
  }, []);


  function onChangeDate(evt, data) {
    setDate(data.value);
    onChangeYear(data);
  }

  function getTwoPreviousYears(yearsPrevious) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const previousYears = [];
  
    // Agregar el año actual a la lista
    previousYears.push({
      key: currentYear,
      value: currentYear,
      flag: currentYear,
      text: currentYear,
    });
  
    for (let i = 0; i < yearsPrevious; i++) {
      const year = currentYear - i - 1;
      previousYears.push({
        key: year,
        value: year,
        flag: year,
        text: year,
      });
    }
    return previousYears;
  }

  React.useEffect(() => {
    GetCatalog();
  }, [GetCatalog]);

  return (
    <div>
      <Dropdown
        className="catalog-year-container"
        text={date}
        scrolling
        icon={'angle down'}
        placeholder="Year"
        onChange={onChangeDate}
        defaultValue={date}
        options={CatalogYears}
      />
    </div>
  );
}

export default CatYears;
