import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Row, Col, Card, CardBody } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import './styles/main.scss';
import { ModelData } from './models/FiltersModel';
import DataTable from './DataTable';
import Filters from './FiltersData';
import Graphics from './graphics/graphics';
import IMAGEN from './imagen64/file';
import {Services} from './../Services';
import axios from 'axios';
import { Search } from 'semantic-ui-react';
import Axios from 'axios';
import {saveAs} from 'file-saver';
import { Icon } from 'semantic-ui-react';


const headers = ['Invoice No.', 'Interglobo Office','Customer', 'Invoice Date', 'Type', 'Due Date', 'Currency', 'Total Amount', 'Transport Mode', 'Shipment No.', 'Equipment Count', 'Equipment No.','Code' ,'Concept','Amount','Status'];
const keys = [ 'InvoiceNo', 'IntergloboOffice','CustomerName', 'InvoiceDate', 'Type', 'DueDate', 'Currency', 'TotalAmount', 'TransportMode', 'ShipmentNo', 'EquipmentCount', 'EquipmentNo','Code','Concept','Amount', 'Status'];

class CostManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      pages: 0,
      search: '',
      Amoun: '0',
      toltlAmoun: '0',
      yearCat: [],
      ModelData: ModelData,
      form: '',
      filters: '',
      data: [],
      catalogCombo: {
        EquipmentNo: [],
        Code: [],
        Concept: [],
        Status: [],
        Invoice: [],
        TransportMode: [],
        ShipmentNo: []
      },
      dataTable: [],
      dataReport:[],
      tercerChart: [],
      cuartaChar: [],
      columnsTable: [
        { label: '', key: '', CollapseTable: true, flatColor: true, keydatavalidate: 'Status' },
        { label: 'Invoice No.', key: 'InvoiceNo' },
        { label: 'Interglobo Office', key: 'IntergloboOffice' },
        { label: 'Customer Name', key: 'CustomerName' },
        { label: 'Invoice Date', key: 'InvoiceDate' },
        { label: 'Type', key: 'Type' },
        { label: 'Due Date', key: 'DueDate' },
        { label: 'Currency', key: 'Currency' },
        { label: 'Total Amount', key: 'TotalAmount',separator:true  },
        { label: 'Transport Mode', key: 'TransportMode' },
        { label: 'Shipment No.', key: 'ShipmentNo' },
        { label: 'Equipment Count', key: 'EquipmentCount' },
        { label: 'Equipment No.', key: 'EquipmentNo'},
        { label: 'Status', key: 'Status', shiwchColor: true },
        { label: '', key: '', custom: (value) => this.custom(value) }
      ],
      subTable: [
        { label: 'Code', key: 'Code' },
        { label: 'Concept', key: 'Concept' },
        { label: 'Currency', key: 'Currency' },
        { label: 'Amount', key: 'Amount',separator:true},
      ],
    };
  }

  custom = (value) => <em onClick={() => this.downloadPdf(value.ShipmentNo)} style={{ color: '#bf360c' }} className={'download fa-2x fas fa-file-pdf mr-2'}></em>

  downloadPdf =  async (val) => {
    try {
      let success = await axios.get(`${Services.GET_DOC_INVOICING.path}invoicing/getdoc/${val}`);
      for (let index = 0; index < success.data.data.length; index++) {
        const e = success.data.data[index];
        const url = `https://poseidonbooking.azurewebsites.net/documents/downloadFile/${e.URL}`;
        try {
          let success = await axios.get(`${url}`);
          const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.pdf';
          const linkSource = `data:application/pdf;base64,${success.data.data}`;
          saveAs(linkSource, fileName);
        }
        catch (err) {
          console.error(err);
        }
      }
    }
    catch (err) {
      console.error(err);
    }
  }
  /*=============================================
  =            Function for formCreate          =
  =============================================*/
  getCat = async () => {
    let { catalogCombo } = this.state;
    try {
      let success = await axios.get(`${Services.GET_CATALOG_INVOICING.path}invoicing/getcatalog/${this.props._customer}`);
      let InvoiceNo = success.data.data.InvoiceNo.map(e => { return { value: e.InvoiceNo, label: e.InvoiceNo ? e.InvoiceNo : 'Sin Valor' }; });
      let TransportMode = success.data.data.TransportMode.map(e => { return { value: e.TransportMode, label: e.TransportMode ? e.TransportMode : 'Sin Valor' }; });
      let ShipmentNo = success.data.data.ShipmentNo.map(e => { return { value: e.ShipmentNo, label: e.ShipmentNo ? e.ShipmentNo : 'Sin Valor' }; });
      let EquipmentNo = success.data.data.EquipmentNo.map(e => { return { value: e.EquipmentNo, label: e.EquipmentNo ? e.EquipmentNo : 'Sin Valor' }; });
      let Code = success.data.data.Code.map(e => { return { value: e.Code, label: e.Code ? e.Code : 'Sin Valor' }; });
      let Category = success.data.data.Category.map(e => { return { value: e.Category, label: e.Category ? e.Category : 'Sin Valor' }; });
      let Status = success.data.data.Status.map(e => { return { value: e.Status, label: e.Status ? e.Status : 'Sin Valor' }; });
      let newcatalogCombo = catalogCombo;

      newcatalogCombo.InvoiceNo = InvoiceNo;
      newcatalogCombo.TransportMode = TransportMode;
      newcatalogCombo.ShipmentNo = ShipmentNo;
      newcatalogCombo.EquipmentNo = EquipmentNo;
      newcatalogCombo.Code = Code;
      newcatalogCombo.Category = Category;
      newcatalogCombo.Status = Status;

      this.setState({
        catalogCombo: newcatalogCombo
      });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  };

  /*=============================================
  =            LifeCycle            =
  =============================================*/
  componentDidMount = () => {
    this.getCat();
    this.getResults();
  }

  refreshTable = (results) => {
    this.setState({
      data: results
    });
  }

  downloadExcel = async ()  => {
    this.setState({
      loading:true
    });
    let {dataReport,Amoun,toltlAmoun} = this.state;
    let data =dataReport;
    let Info = {
      Amoun:Amoun,
      toltlAmoun:toltlAmoun
    };
    let success = await Axios.post('https://poseidonreports.azurewebsites.net/reports/dinamic',{Info, data, headers, keys});
    if (success.data.base64) {
      const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';
      const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
      saveAs(linkSource, fileName);
      this.setState({
        loading:false
      });
    } else console.warn('Error downloading file', success);
  }

  changeFilter = (filters) => {
    let FiltersQuery = '';
    let keys = Object.keys(filters);
    keys.map((e) => {
      if (filters[e]) FiltersQuery += e + '=' + filters[e] + '&';
    });
    this.setState({
      filters: FiltersQuery
    }, () => {
      this.getResults();
    });
  }

  handleSearch = name => event => this.setState({ [name]: event.target.value }, () => this.setSearch())
  setSearch = () => {
    let search = this.state.search;
    let cliente = [...this.state.dataTable];
    let haveSearch = false;
    if (search.length > 0) {
      haveSearch = true;
    }
    let all = cliente;
    let newAll = all.map(item => {
      const InvoiceNo = item.InvoiceNo ? item.InvoiceNo.toUpperCase() : ''.toUpperCase();
      const InvoiceDate = item.InvoiceDate ? item.InvoiceDate.toUpperCase() : ''.toUpperCase();
      const Type = item.Type ? item.Type.toUpperCase() : ''.toUpperCase();
      const DueDate = item.DueDate ? item.DueDate.toUpperCase() : ''.toUpperCase();
      const Currency = item.Currency ? item.Currency.toUpperCase() : ''.toUpperCase();
      const TotalAmount = item.TotalAmount ? item.TotalAmount.toUpperCase() : ''.toUpperCase();
      const TransportMode = item.TransportMode ? item.TransportMode.toUpperCase() : ''.toUpperCase();
      const ShipmentNo = item.ShipmentNo ? item.ShipmentNo.toString().toUpperCase() : ''.toString().toUpperCase();
      const EquipmentCount = item.EquipmentCount ? item.EquipmentCount.toString().toUpperCase() : ''.toString().toUpperCase();
      const EquipmentNo = item.EquipmentNo ? item.EquipmentNo.toUpperCase() : ''.toUpperCase();
      const Status = item.Status ? item.Status.toUpperCase() : ''.toUpperCase();
      const IntergloboOffice = item.IntergloboOffice ? item.IntergloboOffice.toUpperCase() : ''.toUpperCase();
      const textData = search.toUpperCase();

      if ((InvoiceNo.indexOf(textData) > -1) || (InvoiceDate.indexOf(textData) > -1) || (IntergloboOffice.indexOf(textData) > -1) || (Type.indexOf(textData) > -1) || (DueDate.indexOf(textData) > -1) || (Currency.indexOf(textData) > -1) || (TotalAmount.indexOf(textData) > -1) || (TransportMode.indexOf(textData) > -1) || (ShipmentNo.indexOf(textData) > -1) || (EquipmentCount.indexOf(textData) > -1) || (EquipmentNo.indexOf(textData) > -1) || (Status.indexOf(textData) > -1)) {
        item.hidden = false;
      }
      else {
        item.hidden = haveSearch ? true : false;
      }
      return item;
    });

    let toltlAmoun = 0;
    let Amoun = 0;
    newAll.map((e)=>{
      if (e.hidden === false){
        Amoun=Amoun +1;
        toltlAmoun = toltlAmoun + Number(e.TotalAmount);      }
    });
    let pages = (newAll.length / 10) + 1;
    this.setState({ dataTable: newAll, pages: pages, toltlAmoun: '$' + (Number(toltlAmoun).toLocaleString('En-us')), Amoun  });
  }

  getResults = async () => {
    try {
      let yearCat = [];
      let success = await axios.get(`${Services.GET_DATA_INVOICING.path}invoicing/getdataall/${this.props._customer}?${this.state.filters}`);
      let toltlAmoun = 0;
      if (success.data && success.data.data) {
        let findGraph = false;
        success.data.data.map((e) => {
          toltlAmoun = toltlAmoun + Number(e.TotalAmount);
          e.hidden = false;
          yearCat.map((f) => {
            if (e.year === f.key) {
              findGraph = true;
            }
            else {
              findGraph = false;
            }
            return f;
          });
          if (!findGraph) {
            let year = {
              'value': e.year,
              'key': e.year,
              'flag': e.year,
              'text': e.year
            };
            yearCat.push(year);
          }
        });
        this.setState({
          dataReport:success.data.dataReport ? success.data.dataReport : [],
          dataTable: success.data.data ? success.data.data : [],
          yearCat: yearCat,
          cuartaChar: success.data.secondsChart ? success.data.secondsChart : [],
          tercerChart: success.data.colChartFourth ? success.data.colChartFourth : [],
          Amoun: success.data.data ? success.data.data.length : '0',
          toltlAmoun: toltlAmoun ? '$' + (Number(toltlAmoun).toLocaleString('En-us')) : '0'
        });
      }
    }
    catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }

  render() {
    let { form, loading, tercerChart, cuartaChar, catalogCombo, columnsTable, subTable, yearCat, Amoun, toltlAmoun, search, pages } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas icon-chart fa-1x mr-2"></em>
            Cost Management
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            <Col md={12} xl={12}>
              <Row>
                <Col md={12} xl={12}>
                  <div className="row">
                    <img className="imggggg" src={IMAGEN} alt='invoicing'/>
                    <span className="Invoicing" >Invoicing</span>
                    <br></br>
                  </div>
                </Col>
                <Col md={12} xl={12}>
                  <Graphics yearCat={yearCat} cuartaChar={cuartaChar} tercerChart={tercerChart} data={this.state.dataTable}></Graphics>
                </Col>
                <div style={{ height: 800 }}></div>
                <Col md={12} xl={12}>
                  <center><h4 className="mb-3 py-2">Filters</h4></center>
                  <Row>
                    <Col md={12} xl={12}>
                      <Filters catalogCombo={catalogCombo} sendQuery={this.changeFilter} refreshTable={this.refreshTable} data={this.state.data}></Filters>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <div style={{ height: 67 }}></div>
            <div className="row">
              <div className="col-6">
                <Search
                  input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                  placeholder="..."
                  value={search}
                  open={false}
                  name="search"
                  id="search"
                  onSearchChange={this.handleSearch('search')}
                />
              </div>
              <div className="col-6">
                <Row>
                  <Col lg={12} sm={12} md={12}>
                    <div className="row">
                      <div style={{ textAlign: 'right' }} className="col-5">
                        <p>Total Invoices Amount</p>
                        <div className="h1">{toltlAmoun}</div>
                      </div>
                      <div className=" col-5 text-center ">
                        <p>Total Invoices</p>
                        <div className="h1">{Amoun}</div>
                      </div>
                      <div className=" col-2 text-center ">
                        <Row>
                          {loading ?  <center> <Icon loading={loading} style={{marginTop:'35px' }} name='circle notch' size="large"/></center>
                            : <center><em style={{ color: '#43a047', marginTop:'35px' }} onClick={this.downloadExcel} className="download fa-2x fas fa-file-excel mr-2"></em></center>}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <DataTable
              data={this.state.dataTable}
              columnsTable={columnsTable}
              subTable={subTable}
              pages={pages}
            ></DataTable>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default CostManagement;