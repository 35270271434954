import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { GoogleApiWrapper } from 'google-maps-react';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Cards from './components/cards';
import DrawerMap from './components/Drawer/index';
import axios from 'axios';


// import restService from '../../../../services/restService';
// import Authentication from '../../../../services/Authentication.js';
import {Services} from './../Services';

// const auth = new Authentication();       
// const rest = new restService();

/*=============================================
=            Loader Component For Tracking    =
=============================================*/
const Loader = () => {
  let _rows = [];

  for (let index = 0; index < 8; index++) {
    _rows.push(<Skeleton height={30}/>); 
  }
  
  return (
    <React.Fragment>
      <Skeleton height={50}/>
      {_rows}
    </React.Fragment>
  );
};
class Shipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDrawer: '',
      visible: false,
      selected: '',
      polyline: [],
      data: [],
      dataAux: [],
      dataOut:[],
      labelStatus: 'closed',
      loading: true
    };
  }

  componentDidMount = async()=>{
    try{
      const success = await axios.get(`${Services.GET_ONE_SHIPMENT.path}/shipment/search/all?id=${this.props._customer}`);
      // const success = await axios.get(`http://localhost:3001/shipment/search/all?id=${this.props._customer}`);
      this.setState({
        data: success.data.data,
        dataAux:success.data.data,
        dataOut:success.data.data,
        loading: false
      });

      // let id  = auth.getAuthentication('customerSelect');  
      // rest.EXEC({
      //   _function : 'GetAllShipments',
      //   params: '?id='+id
      // },fetch).then(success=>{

      // }).catch(error=>{
      //   console.warn('---->',error);
      //   this.setState({loading: false});
      // });
    }catch(error){
      console.error('Shipment DidMount:', error);
    }
  }

  backres = (backResponse) => {
    this.setState({
      data: backResponse,
      dataAux:backResponse,
      dataOut:backResponse,
    });
  }

  redirectTo = id => evt => {
    evt.preventDefault();
    this.props.history.push('/tracking/'+id);
  }

  handleSelectedFile = (id) => {
    let {selected} = this.state;
    try { 
      if (selected) {
        let selectedItem = document.getElementById(selected);
        selectedItem.classList.remove('_cardSelected');
      }
      let out = this.searchSelected(id);
      this.setState({selected: id, dataOut: [out], labelStatus: id});
    } catch(e){
    }
  }
  


  searchSelected = (ID) => {
    let { dataAux } = this.state;
    let FOUND = dataAux.find(o => o.id === ID);
    return  FOUND;
  }
  
  resetTableCards = evt => {
    evt.preventDefault();
    let {data} = this.state;
    this.setState({dataOut: data, labelStatus: 'closed'});
  };

  
  toggleDrawerMap = data => evt => {
    if (evt) evt.preventDefault();
    this.handleSelectedFile(data.id);
    this.setState({visible: true, dataDrawer: data, polyline: this.formatPolyLine(data.From, data.To)});

  };
  
  toggleDrawMap = data => {
    this.setState({visible: true, dataDrawer: data, polyline: this.formatPolyLine(data.From, data.To)}, () =>{ this.handleSelectedFile(data.id); });
  }

  formatPolyLine = (from, to) => {
    let out = [
      {lat:parseFloat(from.Location.Latitude) , lng: parseFloat(from.Location.Longitude)},
      {lat:parseFloat(to.Location.Latitude) , lng:parseFloat(to.Location.Longitude)}
    ];
    return out;
  }

  render() {
    let { visible, dataDrawer, polyline, dataOut, labelStatus, data, loading} = this.state;
    
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas fa-map-marker-alt fa-1x mr-2"></em>
              Shipments Tracking
          </div>
        </div>
        <Row> 
          <div className="col-12">
            <DrawerMap dataDrawer={dataDrawer} toggleDrawer={this.toggleDrawMap} visible={visible} polyline={polyline} _redirect={this.redirectTo} data = {this.state.data} backRes={this.backres}></DrawerMap>
          </div>
          <div className="col-12" style={{ paddingTop: '2%' }}>
            {
              loading ? 
                <Loader/>
                :
                <Cards toggleDrawer={this.toggleDrawerMap} visible={visible} data={dataOut} dataSearch={data} reset={this.resetTableCards} labelStatus={labelStatus} backRes={this.backres} customer={this.props._customer}></Cards>
            }
          </div>
        </Row>
      </ContentWrapper>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAl_10ZKSqnrviAV-KSQ6c8i4Sa_gEhEmA'
})(withRouter(Shipment));


