import React from 'react';
import { columns } from './config';
import Table from '../../../../../../template/Table';
import './styles.scss';
/**
 * 
 * @param {*} props 
 */
function TablaPo(props) {
  let [data, setData] = React.useState([]);
  // let [dataaLL, setDataALL] = React.useState([]);
  let [origin, setOrigin] = React.useState([]);
  let [supplier, setSupplier] = React.useState([]);
  let [customerC, setCustomerC] = React.useState([]);

  /**
   * Get Shipment PO List
   */
  const getShipmentList = React.useCallback(async () => {
    let newOriginCountry = [];
    let newSupplier = [];
    let newCustomerContainer = [];
    let dataArray = props.data.map((f) => {
      
      f.checked = false;
      f.hidden = false;
      f.edit = false;
      let findcantalog = false;
      let findcantalogSupplier = false;
      let findCustomerContainer = false;
      if (f.OriginCountryId == null) {
        f.OriginCountryId = 0;
        f.OriginCountry = 'Null';
      }
      newOriginCountry.map((e) => {
        if (f.OriginCountryId === e.value) {
          findcantalog = true;
        }
        return e;
      });
      if (!findcantalog) {
        let obj = {
          value: f.OriginCountryId, label: f.OriginCountry
        };
        newOriginCountry.push(obj);
      };

      if (f.SupplierCode == null) {
        f.SupplierCode = 0;
        f.SupplierName = 'Null';
      }
      newSupplier.map((e) => {
        if (f.SupplierCode === e.value) {
          findcantalogSupplier = true;
        }
        return e;
      });
      if (!findcantalogSupplier) {
        let obj = {
          value: f.SupplierCode, label: f.SupplierName
        };
        newSupplier.push(obj);
      };

      if (f.CustomerContainerID === null || f.CustomerContainerID === '') {
        f.CustomerContainerID = 'Null';
      }
      newCustomerContainer.map((e) => {
        if (f.CustomerContainerID === e.value) {
          findCustomerContainer = true;
        }
        return e;
      });
      if (!findCustomerContainer) {
        // if (f.CustomerContainerID){
          let obj = {
            value: f.CustomerContainerID, label: f.CustomerContainerID
          };
          newCustomerContainer.push(obj);
        // }
      };
      return f;
    });
    setOrigin(newOriginCountry);
    setSupplier(newSupplier);
    setCustomerC(newCustomerContainer);
    setData(dataArray);
    // setDataALL(dataArray);
  }, [props]);

  React.useEffect(() => {
    getShipmentList();
  }, [getShipmentList]);

  function checkAll(allLines) {
    props.putOrdersPo(allLines);
  }

  return (
    <React.Fragment>
      <Table
        columns={columns(getShipmentList)}
        data={data}
        data2={data}
        optionSelected={props.optionSelected}
        catOrigin={origin}
        catSupplier={supplier}
        catCustomer={customerC}
        itemsByPage={20}
        checkAll={checkAll}
        options={{
          pagination: true,
          search: true,
        }}
        cellType={'thin-rows'}
        fixed
        MultiUpdate={true}
      />
    </React.Fragment>
  );
};

export default TablaPo;