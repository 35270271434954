
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row } from "reactstrap";
// import Table from '../../../../template/TableComponents/Table'
// import EventsDetails from "../../../Order/order-po-detail-view/components/Events/components/EventsDetails";
import Table from '../../../../template/Table'


function Events(props) {
	const [loader, setLoader] = useState(false)
	// const [data, setData] = useState([])
	const [tables, setTables] = useState([])
	const [columns, setColumns] = useState([
		{ key: 'EventDescription', label: 'Event description', position: 'left', idTh: 'firstTh', idTd: 'firstTd', widht: '30px' },
		{ key: 'EventDate', label: 'Event date', position: 'left', idTh: 'firstTh', idTd: 'firstTd' },
		{ key: 'CategoryResponsable', label: 'Category responsable', position: 'left', idTh: 'firstTh', idTd: 'firstTd' },
		// { key: 'ReadyDateMSC', label: 'Type of delivery', class: 'headerText', custom: (value) => <span className='columTableStyle'>{value.ReadyDateMSC}</span> },
		{ key: 'Name', label: 'Name', position: 'left', idTh: 'firstTh', idTd: 'firstTd' },
		{ key: 'UpdateDate', label: 'Update date', class: 'headerText', position: 'left', idTh: 'firstTh', idTd: 'firstTd' },
	])

	function changueLoader(params) {
		setTimeout(() => {
			setLoader(true)
		}, 3000);
	}


	function buildTable(params) {

		// let keys = Object.keys(params)
		// console.log("🚀 ~ file: events.js ~ line 29 ~ buildTable ~ keys", keys)
		let Bocina = Object.entries(params).map(([key, data]) => {
			return (
				<>
					{/* <legend>{key}</legend> */}
					<Col>
						<span className="shipment-line">{key}</span>
					</Col>
					<Row>
						<Table
							// className="_tablePO"
							columns={columns ? columns : []}
							data={data ? data : []}
							options={{
								styleColumn: true,
								large: true,
								downloads: {
									pdf: false,
									xlsx: false
								}
							}}
						/>
					</Row>
					<hr></hr>
				</>
				// <EventsDetails data={data} title={key} columns={columns} />


				// <Col xs={12} md={12} style={{ textAlign: 'center' }}>
				// 	<CardBody >

				// 		<Label>{key}</Label>
				// 		<Table
				// 			columns={columns}
				// 			data={data}
				// 			// itemsByPage={10}
				// 			// styles={styles}
				// 			// configSearch={{ fieldsToFilter: ItemsToFilter }}
				// 			options={
				// 				{
				// 					card: false,
				// 					pagination: false,
				// 					search: false,
				// 					placeholder: 'Search ...',
				// 					selectItems: false,
				// 					xlsx: false,
				// 					Accordion: { Active: false, Title: 'Shipment Lines' },
				// 				}
				// 			}
				// 		/>
				// 	</CardBody>
				// </Col>

			)


		});
		setTables(Bocina)

	}

	useEffect(() => {
		buildTable(props.Groups)
		// setData(props.Groups)
	}, [])

	return (
		<>
			<Row>
				<Col xs={12} md={6} lg={6} >
					{/* <Row> */}
					<Col xs={12} md={12} lg={12}>
						{props.shipmentLine.name}
					</Col >
					{/* </Row> */}
				</Col>
				<div className="margin-footer-3" />
				<Col xs={12} md={12}>
					{tables}
				</Col>
			</Row>
		</>
	);
}

export default withRouter(Events);

